import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CreateImage } from "@models/image";
import { ImageService } from "@services/image.service";
import { AppConfig } from "../../config";

interface IImageUploadProps {
	match?: any;
	site?: string;
	aspectRatio: number;
	onCancel?: () => void;
	onUpload: (id: number) => void;
	onReset: () => void;
	onSelect?: (item: any) => void;
	item?: any;
	items?: any[];
	maxWidth?: number;
	maxHeight?: number;
	width?: number;
	height?: number;
	imageId?: number;
	reset?: boolean;
	multiple?: boolean;
	disabled?: boolean;
}

interface IImageUploadState {
	loading: boolean;
	nextPathname?: any;
	dropped?: string | ArrayBuffer;
	data?: string;
	image?: CreateImage;
}

export class ImageUpload extends React.Component<IImageUploadProps, IImageUploadState> {
	private cropper: any;

	setEditorRef = (cropper) => this.cropper = cropper;

	constructor(props) {
		super(props);

		this.state = { loading: true };
	}

	componentDidMount() {
	}

	handleCreate() {
	}

	handleCancel() {
		this.props.onCancel();
	}

	handleSubmit(e: any) {
		e.preventDefault();
	}

	readFile(file): Promise<string | ArrayBuffer> {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.addEventListener("load", () => resolve(reader.result), false);
			reader.readAsDataURL(file);
		});
	}

	async handleDrop(dropped: any) {
		//
		const file = dropped[0];
		const image = new CreateImage();
		image.name = file.name;
		image.size = file.size;
		image.type = file.type;
		image.base64 = (await this.readFile(file)).toString();

		this.setState({ image, data: image.base64, dropped: file });
	}

	upload() {
		let image = this.cropper.getCroppedCanvas({
			width: this.props.width,
			height: this.props.height,
			maxWidth: this.props.maxWidth,
			maxHeight: this.props.maxHeight,
			imageSmoothingQuality: "high",
			imageSmoothingEnabled: true,
		}).toDataURL(this.state.image.type, 0.75);
		image = image.replace(/^data:image\/[a-z]+;base64,/, "");

		const upload = this.state.image;
		upload.base64 = image;

		ImageService.create(upload).then(result => {
			this.props.onUpload(result);
			this.setState({ data: null, image: null });
		});
	}

	reset() {
		this.setState({ data: null }, () => {
			this.props.onReset();
		});
	}

	select(item: any) {
		this.setState({}, () => {
			if(this.props.onSelect) {
				this.props.onSelect(item);
			}
		});
	}

	public render() {
		return (
			<div>
				{
					this.props.item || this.props.items ?
						<div>
							{
								this.props.item ?
									<div className="image-preview">
										<button className="btn p-0" onClick={this.select.bind(this, this.props.item)}><img src={`${AppConfig.baseUrl}image/${this.props.item.imageId}`} /></button>
									</div> :
									<div className="image-previews mg-b-20">
										<div className="image-preview">
											<div className="row">
												{
													this.props.items.map(i => {
														return (<div className="col-4 p-0"><button className="btn p-0" onClick={this.select.bind(this, i)}><img src={`${AppConfig.baseUrl}image/${i.imageId}`} /></button></div>);
													})
												}
											</div>
										</div>
									</div>
							}
						</div> : undefined
				}
				<div className="image-preview">
					{
						this.props.imageId ? <img src={`${AppConfig.baseUrl}image/${this.props.imageId}`} /> : undefined
					}
				</div>
				{
					this.props.item || this.props.imageId ? undefined :
						this.state.data ?
							<div className="row mg-b-25">
								<div className="col-lg-12">
									<Cropper
										ref={this.setEditorRef}
										src={this.state.data}
										width={250}
										height={250}
										style={{ height: 250, width: "100%" }}
										aspectRatio={this.props.aspectRatio}
										viewMode={2} />
									<div className="mg-t-10">
										<button className="btn btn-primary btn-rounded btn-outline bd-0 mg-r-5" onClick={this.upload.bind(this)}>Upload</button>
										<button onClick={this.reset.bind(this)} className="btn btn-outline btn-secondary btn-md btn-rounded mr-2" title="edit">Cancel</button>
									</div>
								</div>
							</div> :
							!this.props.disabled && <Dropzone
								onDrop={this.handleDrop.bind(this)}
								style={{ width: "100%", height: "100%", cursor: "pointer" }}>
								<div className="dropzone">Drag &amp; Drop your files or click to browse </div>
							</Dropzone>
				}
				{
					this.props.imageId && this.props.reset && !this.props.disabled ? <button onClick={this.reset.bind(this)} className="btn btn-outline btn-secondary btn-md btn-rounded mr-2 mg-t-20" title="Reset">Reset</button> : undefined
				}
			</div>
		);
	}
}
