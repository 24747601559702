import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { LoginService } from "./login.service";

import RestHelper from "@helpers/rest.helper";

import store from "store2";

import * as moment from "moment";
import { Point } from "@/models/point";
import { Group, CreateGroup, GroupMember, GroupContact } from "@models/group";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { RestException } from "@models/exceptions";

export class GroupService {

	constructor() {
		//
	}

	public static data(query: DatatableQuery): Promise<DatatableResponse<Group>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({ query }),
				success: (data:DatatableResponse<Group>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByIds(groupIds: number[]): Promise<Group[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/groupids`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(groupIds),
				success: (data: Group[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static search(term: string): Promise<Group[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: { search: term, take: 20 },
				success: (data: Group[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static searchAll(term: string): Promise<Group[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/fromall`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: { search: term, take: 20 },
				success: (data: Group[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(member: CreateGroup): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/create`,
				cache: false,
				method: "POST",
				data: JSON.stringify(member),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(member: CreateGroup): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/update`,
				cache: false,
				method: "POST",
				data: JSON.stringify(member),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static add(groupId: number, userId: string): Promise<GroupMember> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/members/add`,
				cache: false,
				method: "POST",
				data: JSON.stringify({groupId, userId}),
				contentType: "application/json",
				success: (data: GroupMember, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static addContact(groupId: number, contactId: number): Promise<GroupContact> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/contacts/add`,
				cache: false,
				method: "POST",
				data: JSON.stringify({groupId, contactId}),
				contentType: "application/json",
				success: (data: GroupContact, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static remove(groupId: number, userId: string): Promise<GroupMember> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/members/remove`,
				cache: false,
				method: "POST",
				data: JSON.stringify({groupId, userId}),
				contentType: "application/json",
				success: (data: GroupMember, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static removeContact(groupId: number, contactId: number): Promise<GroupContact> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/contacts/remove`,
				cache: false,
				method: "POST",
				data: JSON.stringify({groupId, contactId}),
				contentType: "application/json",
				success: (data: GroupContact, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getMembers(groupId: number): Promise<GroupMember[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/${groupId}/members`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: GroupMember[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getContacts(groupId: number): Promise<GroupContact[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/${groupId}/contacts`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: GroupContact[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(groupId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `groups/${groupId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByMember(userId: string): Promise<Group[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`groups/members/${userId}/groups`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getByContact(contactId: number): Promise<Group[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`groups/contacts/${contactId}/groups`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
