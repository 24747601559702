import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Button, Modal, ModalBody } from "reactstrap";

import { Draggable } from "react-page-maker";

import {
	Canvas,
	Palette,
	state,
	Trash,
	core,
	Preview,
	registerPaletteElements
} from "react-page-maker";

import { elements, palette } from "./elements";

interface IPagePreview {
	content?: string;
}

const PagePreview = (props: IPagePreview) => {
	const [content, setContent] = useState(props.content);

	const [currentState, setCurrentState] = useState();

	registerPaletteElements(palette);

	const stateChange = async (s) => {
		const newState = state.getStorableState();
		setCurrentState(newState);
		setContent(newState);
	};

	state.addEventListener("change", stateChange);

	return (
		<>
			<Row className="d-none">
				<Col>
					<Canvas initialElements={content} />
				</Col>
			</Row>
			<Preview>
				{
					({ children }) => (
						<div className="pagemaker">
							{children}
						</div>
					)
				}
			</Preview>
		</>
	);
};

export default PagePreview;
