import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { Row, Col } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { MatchGrouping, MatchGroupRange, Match, MatchGroupType, MatchFilters, TemplateAccess, MatchGroup } from "@models/match";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { MatchService } from "@services/match.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AddToCalendar } from "@components/controls/AddToCalendar";
import { AppCache } from "@cache";

import * as queryString from "query-string";
import { SelectOption } from "@models/forms";
import { CompetitionFilterOptions } from "@models/filters";
import { Options } from "@data";
import { confirmAlert } from "react-confirm-alert";
import { Tenant } from "@models/tenant";
import { WebsiteCompetitionData } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";
import { WebsiteService } from "@services/website.service";
import { FreeSlots } from "@models/week";
import { Sidebar } from "rsuite";
import { Toggle } from "@components/controls/Toggle";
import { MatchCardTemplatePage } from "../divisions/MatchCardTemplatePage";

const dataFormat = ["table", "results", "averages", "potms", "rankings"];

interface IFixturesPageProps {
	site?: string;
	match?: any;
	type: MatchGroupType;
}

interface IFixturesPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	matches?: MatchGrouping;
	slots?: FreeSlots[];
	options?: CompetitionFilterOptions;
	dateOptions?: SelectOption<Date>[];
	roundOptions?: SelectOption<number>[];
	showFilters: boolean;
	filtering: boolean;
	individualResults: boolean;
	selecting: boolean;
	selected?: TemplateAccess[];
}
export class FixturesPage extends React.Component<IFixturesPageProps, IFixturesPageState> {

	private wizard: any;
	private divisionId?: number;
	private competitionId?: number;
	private typeId?: MatchGroupType;

	private clubId?: number;
	private venueId?: number;
	private teamId?: number;

	private dateValue?: Date;
	private roundValue?: number;
	private statusValue?: boolean;
	private conditionsValue?: number;

	private division?: SelectOption<number>;
	private venue?: SelectOption<number>;
	private club?: SelectOption<number>;
	private team?: SelectOption<number>;
	private type?: SelectOption<number>;

	private date?: SelectOption<Date>;
	private round?: SelectOption<number>;
	private status?: SelectOption<boolean>;
	private conditions?: SelectOption<number>;

	private navigationIds: WebsiteCompetitionData[];
	private compIndex: number;
	private divIndex: number;
	private formIndex: number;


	constructor(props) {
		super(props);

		const qs = queryString.parse(location.search);
		this.venueId = parseInt(qs.venueId as string, 0);
		this.clubId = parseInt(qs.clubId as string, 0);
		this.teamId = parseInt(qs.teamId as string, 0);
		this.statusValue = (qs.approved as string)?.length > 0 ? qs.approved as string == "true" ? true : false : null;
		this.dateValue = qs.dateString as string ? moment(qs.dateString as string, "DD-MM-YYYY").toDate() : null;
		this.roundValue = parseInt(qs.round as string, 0);
		this.conditionsValue = parseInt(qs.conditions as string, null);
		const filtering = !(!this.venueId && !this.clubId && !this.teamId && !this.dateValue && !this.statusValue && !this.conditionsValue);

		this.state = { loading: true, showFilters: false, filtering, individualResults: false, selecting: false };

		this.typeId = this.props.type;
	}

	componentDidMount() {
		this.divisionId = this.props.match.params.divisionId as number;
		this.competitionId = this.props.match.params.competitionId as number;

		WebsiteService.updateActivity("Fixtures");

		if (this.competitionId || this.divisionId) {	
			var filtersQuery = sessionStorage.getItem(`PFixtureListFilters${this.competitionId}${this.typeId}`)
			var filters = filtersQuery?.length > 0 ? queryString.parse(filtersQuery) : null;
			if (filters != null) {
				this.venueId = parseInt(filters.venueId as string, 0);
				this.clubId = parseInt(filters.clubId as string, 0);
				this.teamId = parseInt(filters.teamId as string, 0);
				this.statusValue = (filters.approved as string)?.length > 0 ? filters.approved as string == "true" ? true : false : null;
				this.dateValue = filters.dateString as string ? moment(filters.dateString as string, "DD-MM-YYYY").toDate() : null;
				this.roundValue =  parseInt(filters.round as string, 0);
				this.conditionsValue = parseInt(filters.conditions as string, null);
				const filtering = !(!this.venueId && !this.clubId && !this.teamId && !this.dateValue && !this.statusValue && !this.conditionsValue);
				this.setState({filtering: filtering});
					
				if (this.divisionId) {
					History.replace(`/league/${this.competitionId}/division/${this.divisionId}/${this.typeId == MatchGroupType.Results ? "results" : this.typeId == MatchGroupType.All ? "fixtures-results" : "fixtures"}?${queryString.stringify(filters)}`);
				} else {
					History.replace(`/league/${this.competitionId}/${this.typeId == MatchGroupType.Results ? "results" : this.typeId == MatchGroupType.All ? "fixtures-results" : "fixtures"}?${queryString.stringify(filters)}`);
				}
			}

			this.load();
		} else {
			this.loadMembers();
		}
	}

	componentDidUpdate(props: IFixturesPageProps, state: IFixturesPageState) {
		const divisionId = this.props.match.params.divisionId as number;
		const competitionId = this.props.match.params.competitionId as number;
		const typeId = this.props.type as MatchGroupType;

		if (!location.search && this.state.filtering) {
			this.teamId = null;
			this.venueId = null;
			this.clubId = null;
			this.dateValue = null;
			this.roundValue = null;
			this.typeId = this.props.type;
			this.status = null;
			this.conditions = null;
			this.setState({ showFilters: false, filtering: false, selected: null });

			if (divisionId || competitionId) {
				this.load();
			} else {
				this.loadMembers();
			}
		}

		if (props.match.params.competitionId !== competitionId ||
			props.match.params.divisionId !== divisionId ||
			props.type !== typeId) {

			this.typeId = this.props.type;
			this.divisionId = divisionId;
			this.competitionId = competitionId;

			if (divisionId || competitionId) {
				var filtersQuery = sessionStorage.getItem(`PFixtureListFilters${this.competitionId}${this.typeId}`)
				var filters = filtersQuery?.length > 0 ? queryString.parse(filtersQuery) : null;
				if (filters != null) {
					this.venueId = parseInt(filters.venueId as string, 0);
					this.clubId = parseInt(filters.clubId as string, 0);
					this.teamId = parseInt(filters.teamId as string, 0);
					this.statusValue = (filters.approved as string)?.length > 0 ? filters.approved as string == "true" ? true : false : null;
					this.dateValue = filters.dateString as string ? moment(filters.dateString as string, "DD-MM-YYYY").toDate() : null;
					this.roundValue = parseInt(filters.round as string, 0);
					this.conditionsValue = parseInt(filters.conditions as string, null);
					const filtering = !(!this.venueId && !this.clubId && !this.teamId && !this.dateValue && !this.statusValue && !this.conditionsValue);
					this.setState({filtering: filtering});
						
					if (this.divisionId) {
						History.replace(`/league/${this.competitionId}/division/${this.divisionId}/${this.typeId == MatchGroupType.Results ? "results" : this.typeId == MatchGroupType.All ? "fixtures-results" : "fixtures"}?${queryString.stringify(filters)}`);
					} else {
						History.replace(`/league/${this.competitionId}/${this.typeId == MatchGroupType.Results ? "results" : this.typeId == MatchGroupType.All ? "fixtures-results" : "fixtures"}?${queryString.stringify(filters)}`);
					}
				}

				this.load();
			} else {
				this.loadMembers();
			}
		}
	}

	load() {
		this.setState({ loading: true });
		

		NProgress.start();
		const p1 = CompetitionService.getById(this.competitionId).then(competition => {
			this.setState({ competition }, () => {

			});
		});

		const p2 = CompetitionService.getFilters(this.competitionId).then(options => {
			this.setState({ options }, () => {
				this.division = this.getDivisionOptions().find(d => d.value == this.divisionId);
				this.type = Options.matchGroupTypes.find(o => o.value == this.typeId);
				this.club = this.getClubOptions().find(c => c.value == this.clubId);
				this.team = this.getTeamOptions().find(d => d.value == this.teamId);
				this.venue = this.getVenueOptions().find(v => v.value == this.venueId);
				this.status = Options.approvalStatus.find(s => s.value == this.statusValue)
				this.conditions = Options.matchConditions.find(c => c.value == this.conditionsValue);
			});
		});

		Promise.all([p1, p2]).then(result => {
			const filters = {
				type: this.typeId ? this.typeId : null,
				competitionId: this.competitionId ? this.competitionId : null,
				divisionId: this.divisionId ? this.divisionId : null,
				clubId: this.clubId ? this.clubId : null,
				teamId: this.teamId ? this.teamId : null,
				venueId: this.venueId ? this.venueId : null,
				dateString: this.dateValue ? moment(this.dateValue).format("DD-MM-YYYY") : null,
				round: this.roundValue? this.roundValue : null,
				approved: this.statusValue != null ? this.statusValue : null,
				conditions: this.conditionsValue ? this.conditionsValue : null
			};

			if (this.state.filtering) {
				const query = {
					type: this.typeId,
					club: this.clubId,
					team: this.teamId,
					venue: this.venueId,
					dateString: this.dateValue ? moment(this.dateValue).format("DD-MM-YYYY") : null,
					round: this.roundValue ? this.roundValue : null,
					status: this.statusValue,
					conditions: this.conditionsValue,
				};
		
				const qs = query ? queryString.stringify(filters) : "";

				sessionStorage.setItem(`PFixtureListFilters${this.competitionId}${this.typeId}`, qs);
			}
			else {
				sessionStorage.removeItem(`PFixtureListFilters${this.competitionId}${this.typeId}`)
			}
			

			let p3;

			let p4;

			if (this.divisionId != null) {
				p3 = DivisionService.getById(this.divisionId).then(division => {
					this.setState({ division }, () => {
					});
				});
				p4 = MatchService.slots(this.divisionId).then(result => {
					this.setState({ slots: result });
				})
			} else {
				this.setState({ division: null });
			}

			let p5 = MatchService.filter(filters).then(matches => {
				if (this.props.type == MatchGroupType.Upcoming) {
					const groups = matches.groups.filter(g => g.week != null);
					const unscheduled = matches.groups.filter(g => g.week == null);

					if (unscheduled) {
						unscheduled.forEach(u => groups.push(u));
					}

					matches.groups = groups;
				}
				var dateOptions = [];
				var roundOptions = [];
				matches.matches.forEach(m => {
					if (m.date != null && !dateOptions.some(d => this.getDate(d.value).isSame(this.getDate(m.date)))) {
						dateOptions.push({ label: moment(m.date).format("DD-MM-YYYY"), value: m.date });
					}
					if (m.round != null && !roundOptions.some(r => m.round == r.value)) {
						roundOptions.push({ label: `Round ${m.round}`, value: m.round });
					}
				});
				roundOptions.sort((a, b) => {return a.value - b.value})
				this.setState({ matches, dateOptions, roundOptions }, () => {
					this.date = dateOptions.find(d => this.getDate(d.value).isSame(this.getDate(this.dateValue)));
					this.round = roundOptions.find(r => r.value == this.roundValue);
				});
			});

			Promise.all([p3, p4, p5]).then(result => {
				// var competitions = [];
				// AppCache.website.menu.competitions.forEach(competition => {
				// 	var divisions = []
				// 	AppCache.website.menu.divisions.forEach(division => {
				// 		division.parentId == competition.id ? divisions.push(division.id) : undefined;
				// 	})
				// 	var item = new WebsiteCompetitionData();
				// 	item.id = competition.id;
				// 	item.divisionIds = divisions;
				// 	competitions.push(item);
				// })
				// this.navigationIds = competitions;
				// this.compIndex = competitions.findIndex(item => {return item.id == this.competitionId.toString()});
				// this.divIndex = this.divisionId ? competitions[this.compIndex].divisionIds.findIndex(item => {return item == this.divisionId.toString()}) : null;
				// var path = window.location.pathname.split("/");
				// var form = path[path.length - 1];
				// this.formIndex = dataFormat.findIndex(item => item == form);
				this.setState({ loading: false }, () => {
					NProgress.done();
					if (this.state.filtering) {
						document.getElementById("filters").scrollIntoView();
					} else {
						window.scrollTo(0, 0);
					}
				});
			});
		});
	}

	loadMembers() {
		if (!LoginService.isAuthenticated)
			History.push("/login");

		this.setState({ loading: true });
		NProgress.start();

		if (this.props.type === MatchGroupType.Upcoming) {
			MatchService.memberUpcoming().then(matches => {
				const groups = matches.groups.filter(g => g.week != null);

				const unscheduled = matches.groups.find(g => g.week == null);
				if (unscheduled) {
					groups.push(unscheduled);
				}

				matches.groups = groups;

				this.setState({ loading: false, matches }, () => {
					NProgress.done();
				});
			});
		}
		else {
			MatchService.memberResults().then(matches => {
				this.setState({ loading: false, matches }, () => {
					NProgress.done();
				});
			});
		}
	}

	select(match: Match) {
		if(match.away.teamId != null && match.home.teamId != null)
			History.push(`/league/${match.competitionId}/division/${match.divisionId}/match/${match.id}`);
	}

	filter() {
		this.divisionId = this.division ? this.division.value : null;
		this.typeId = this.type ? this.type.value : null;
		this.clubId = this.club ? this.club.value : null;
		this.venueId = this.venue ? this.venue.value : null;
		this.teamId = this.team ? this.team.value : null;
		this.dateValue = this.date ? this.date.value : null;
		this.statusValue = this.statusValue != null ? this.status.value : null;
		this.conditionsValue = this.conditions ? this.conditions.value : null;

		this.setState({ filtering: true });

		const filters = {
			type: this.typeId,
			club: this.clubId,
			team: this.teamId,
			venue: this.venueId,
			dateString: this.dateValue ? moment(this.dateValue).format("DD-MM-YYYY") : null,
			status: this.statusValue,
			conditions: this.conditionsValue,
		};

		const query = filters ? queryString.stringify(filters) : "";

		if (this.divisionId) {
			History.replace(`/league/${this.competitionId}/division/${this.divisionId}/${this.typeId == MatchGroupType.Results ? "results" : this.typeId == MatchGroupType.All ? "fixtures-results" : "fixtures"}?${query}`);
		} else {
			History.replace(`/league/${this.competitionId}/${this.typeId == MatchGroupType.Results ? "results" : this.typeId == MatchGroupType.All ? "fixtures-results" : "fixtures"}?${query}`);
		}

		this.load();
	}

	export() {
		confirmAlert({
			customUI: ({ onClose }) => {
				if (this.divisionId || this.competitionId) {
					const filters = {
						type: !isNaN(this.typeId) ? this.typeId : undefined,
						clubId: !isNaN(this.clubId) ? this.clubId : undefined,
						divisionId: !isNaN(this.divisionId) ? this.divisionId : undefined,
						competitionId: !isNaN(this.competitionId) ? this.competitionId : undefined,
						teamId: !isNaN(this.teamId) ? this.teamId : undefined,
						venueId: !isNaN(this.venueId) ? this.venueId : undefined
					};
					const qs = filters ? queryString.stringify(filters) : "";
					return (
						<div className="react-confirm-alert--default">
							<h1>Download Fixtures</h1>
							<p>Do you wish to download these filtered fixtures</p>
							<a href={`${AppConfig.apiUrl}export/fixtures/public/?${qs}`} target="_blank" className="btn btn-success btn-rounded text-white mr-2"
								onClick={() => {
									onClose();
								}}>
								Download
							</a>
							<a className="btn btn-secondary btn-rounded text-white" onClick={onClose}>Cancel</a>
						</div>
					);
				}
				else {
					var details = LoginService.getDetails();
					var type = "";
					if (this.props.type === MatchGroupType.Upcoming) {
						type = "upcoming";
					}
					else {
						type = "results";
					}
					const query = {
						refreshToken: details.refreshToken,
						leagueId: AppCache.tenant.leagueId
					};
					const qs = queryString.stringify(query);
					return (
						<div className="react-confirm-alert--default">
							<h1>Download Fixtures</h1>
							<p>Do you wish to download all your fixtures</p>
							<a href={`${AppConfig.apiUrl}export/fixtures/member/${type}/?${qs}`} target="_blank" className="btn btn-success text-white mr-2"
								onClick={() => {
									onClose();
								}}>
								Download
							</a>
							<a className="btn btn-secondary text-white" onClick={onClose}>Cancel</a>
						</div>
					);
				}
			}
		});
	}

	getDivisionOptions(): SelectOption<number>[] {
		return this.state.options.divisions.map(d => ({ label: d.value, value: d.key }));
	}

	getVenueOptions(): SelectOption<number>[] {
		return this.state.options.venues.map(d => ({ label: d.value, value: d.key }));
	}

	getClubOptions(): SelectOption<number>[] {
		return this.state.options.clubs.map(d => ({ label: d.value, value: d.key }));
	}

	getTeamOptions(): SelectOption<number>[] {
		return this.state.options.teams
			.filter(t => (!this.clubId || t.clubId == this.clubId) && (!this.divisionId || t.divisionId == this.divisionId))
			.map(d => ({ label: d.name, value: d.id }));
	}

	handleChangeSelect = (p1: Function, p2: Function) => (e: SelectOption<any>) => {
		const name = /\.([^\.;]+);?\s*\}$/.exec(p1.toString())[1];
		this[name] = e;

		const value = /\.([^\.;]+);?\s*\}$/.exec(p2.toString())[1];
		this[value] = e != null ? e.value : null;

		this.filter();
	}

	reset = () => (e: any) => {
		this.clubId = null;
		this.teamId = null;
		this.venueId = null;
		this.dateValue = null;
		this.divisionId = this.props.match.params.divisionId as number;

		if (this.divisionId) {
			History.replace(`/league/${this.competitionId}/division/${this.divisionId}/fixtures`);
		} else {
			History.replace(`/league/${this.competitionId}/${this.typeId == MatchGroupType.Results ? "results" : "fixtures"}`);
		}

		this.setState({ showFilters: false, filtering: false }, () => {
			this.load();
		});
	}

	toggleFilters = () => (e: any) => {
		this.setState({ showFilters: !this.state.showFilters });
	}

	groupBy(array, f) {
		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		});
	}

	getDate(date: Date) {
		return moment(moment(date).format("MM/DD/YYYY"))
	}

	drawEmptyRounds(date?: Date) {
		var slots: FreeSlots[];
		if (this.state.slots) {
			if (date) {
				slots = this.state.slots.filter(s => this.getDate(s.date).isSame(this.getDate(date)))
				return (slots.map(s => {
					return (<div className="d-inline">
						<h6 className="text-center">
							<div className="d-inline">
								<span className="tx-medium">Round {s.roundNo}</span>
								<div className="description">
									To Be Decided
								</div>
							</div>
						</h6>
					</div>)
				}))
			}
			else {
				switch (this.state.matches.type) {
					case MatchGroupType.Upcoming:
						slots = this.state.slots.filter(s => this.getDate(s.date).isAfter(moment()))
						break;
					case MatchGroupType.Results:
						slots = this.state.slots.filter(s => this.getDate(s.date).isSameOrBefore(moment()))
						break;
				}
				if (slots) {
					slots = slots.filter(s => !this.state.matches.groups.some(g => this.getDate(s.date).isSame(this.getDate(g.date))))

					return this.groupBy(slots, (g) => g.date).map((mg, index) => {
						return (<div className="d-inline">
							<h6 className="text-center">
								<div className="d-inline">
									<span className="mg-r-2 tx-medium">{moment(mg[0].date).format("dddd")}</span>
									<span className="tx-medium">{moment(mg[0].date).format("Do MMMM")}</span>
								</div>
							</h6>
							{mg.map(g => {
								return (<div className="d-inline">
									<h6 className="text-center">
										<div className="d-inline">
											<span className="tx-medium">Round {g.roundNo}</span>
											<div className="description">
												To Be Decided
											</div>
										</div>
									</h6>
								</div>)
							})}
						</div>)
					});
				}
			}
		}
	}

	addRemoveCard(match: Match, add: boolean) {
		var selected = this.state.selected;
		if (add) {
			selected.push({ matchId: match.id, competitionId: match.competitionId, divisionId: match.divisionId })
		}
		else {
			var index = selected.findIndex(s => s.matchId == match.id);
			selected.splice(index, 1);
		}
		this.setState({ selected });
	}

	addRemoveRound(matchGroup: any) {
		var selected = this.state.selected;
		if (!matchGroup.every(m => selected.some(s => m .id == s.matchId))) {
			matchGroup.forEach(m => {
				if(!selected.some(s => m.id == s.matchId)){
					selected.push({ matchId: m.id, competitionId: m.competitionId, divisionId: m.divisionId });
				}
			});
		}
		else {
			matchGroup.forEach(m => {
				var index = selected.findIndex(s => s.matchId == m.id);
				selected.splice(index, 1);
			});
		}
		this.setState({ selected });
	}

	public render() {

		let sidebar;

		sidebar = (<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Week Event</h6>
			</div>
			<div className="sidebar--content mg-t-30">
				<form>
					<div className="form-layout">
						<div className="row mg-b-25">
							<div className="col-lg-12">
								<div className="form-group">
									<label className="form-control-label">Matches <span className="tx-danger">*</span></label>
								</div>
							</div>
						</div>
						<div className="form-layout-footer">
							<div className="row">
								<div className="col-6">
									<a className="btn btn-primary btn-rounded btn-outline bd-0 d-block w-100" type="button" href="">Export</a>
								</div>
								<div className="col-6">
									<button className="btn btn-secondary btn-rounded btn-outline bd-0 d-block w-100" type="reset" onClick={() => this.setState({ selecting: false, selected: null })}>Cancel</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className="sidebar--footer">

			</div>
		</div>);

		return (
			<PublicLayout
				className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout"
				print={
					this.state.selecting ? () => this.state.selected.map(m => { return (<MatchCardTemplatePage match={{ params: { matchId: m.matchId, divisionId: m.divisionId, competitionId: m.competitionId } }} />) }) : () => (
						!this.state.loading && this.state.matches != null ?
							<div className="fixture-list">
								<h6>
									{
										this.state.competition != null ?
											`${this.state.competition.name} - ${this.state.division ? this.state.division.name : "All Divisions"}` :
											this.props.type === MatchGroupType.Upcoming ? "My Fixtures" : "My Results"
									}
								</h6>
								{
									this.state.matches.groups.map(g => {
										return (
											<div key={`matches-week-${g.week}-${g.type}-${this.props.type}`} className="fixture-week">
												<table className="w-100">
													<tbody>
														<tr className="date">
															{
																g.range === MatchGroupRange.Weekly && this.state.competition && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) < 3 ?
																	<td colSpan={5} className="text-left">
																		{
																			g.date ?
																				<div><span className="tx-medium">Week {g.week}</span></div> :
																				<span>Postponed</span>
																		}
																	</td>
																	:
																	g.range === MatchGroupRange.Weekly && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) > 4 && g.type != 4 ?
																	<td colSpan={5} className="text-left">
																		{
																			g.matches[0].roundModel.endDate != null ?
																				<div><span className="tx-medium">{g.matches[0].roundModel.name}</span> - <span className="mg-r-2">Ends {moment(g.date).format("Do MMMM")}</span></div> :
																			g.matches[0].roundModel.startDate != null ?
																				<div><span className="tx-medium">{g.matches[0].roundModel.name}</span> - <span className="mg-r-2">{moment(g.date).format("Do MMMM")}</span></div> :
																				<div><span className="tx-medium">{g.matches[0].roundModel.name}</span>{/*  - <span className="tx-medium">Postponed</span> */}</div>
																		}
																	</td>
																	:
																	g.range === MatchGroupRange.Monthly && this.state.competition && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) < 3 ?
																		<td colSpan={5} className="text-left">
																			<span>{moment(g.date).format("MMMM")}</span>
																		</td> :
																		<td colSpan={5} className="text-left">
																			{
																				g.date ?
																					<>
																						<span className="mg-r-2 tx-medium">{moment(g.date).format("dddd")}</span>
																						<span className="tx-medium">{moment(g.date).format("Do MMMM")}</span>
																					</> :
																					<span className="mg-r-2 tx-medium">Postponed</span>
																			}
																		</td>
															}
														</tr>
														{
															g.type === MatchGroupType.Skipped ?
																<tr>
																	<td colSpan={5}>
																		<p className="text-left text-bold m-0">{g.title}</p>
																	</td>
																</tr> : undefined
														}
														{
															this.groupBy(g.matches, (f) => this.state.competition && ((this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat === 3) || (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat === 4)) ? f.round : f.week).map((mg, index) => {
																return (
																	<>
																		{
																			mg[0].round &&
																			<tr key={`matches-print-${mg[0].round}`} className="fixture">
																				<span>Round {mg[0].round} {mg[0].time ? moment(mg[0].time).format("H:mm") : undefined}</span>
																			</tr>
																		}
																		{
																			mg.map((m, index) => {
																				return (
																					<tr key={`matches-print-${m.id}`} className="fixture">
																						<td className="date">
																							{
																								m.date ?
																									<time className="sp-event-date" dateTime={moment(m.date).format("Do MMM")}>{moment(m.date).format("Do MMM")}</time> :
																									<span className="tbd">TBD</span>
																							}
																						</td>
																						<td className="day">
																							{
																								m.date ?
																									<time className="sp-event-date" dateTime={moment(m.date).format("dddd")}>{moment(m.date).format("dddd")}</time> :
																									<span className="tbd">TBD</span>
																							}
																						</td>
																						<td className="day">
																							{
																								m.time ?
																									<time className="sp-event-date" dateTime={moment(m.time).format(moment(m.time).hour() < 12 ? "H:mma" : "H:mm")}>
																										{moment(m.time).format(moment(m.time).hour() < 12 ? "H:mma" : "H:mm")}
																									</time> : <span className="tbd">TBD</span>
																							}
																						</td>
																						<td className="teama">
																							<strong className="team-name">{m.home?.displayName ?? m.homeName}</strong>
																						</td>
																						<td className="teamb">
																							<strong className="team-name">{m.away?.displayName ?? m.awayName}</strong>
																						</td>
																						<td className="venue">
																							<span className="venue-name">{m.home?.type === 3 || m.away?.type === 3 ? "" : m.venue}</span>
																						</td>
																						<td className="score text-center">
																							{
																								m.approved && m.home?.score != null && m.away?.score != null ?
																									<span>
																										{m.home.score} - {m.away.score}
																									</span> :
																									<span>
																										-
																									</span>
																							}
																						</td>
																					</tr>
																				)
																			})
																		}
																	</>
																);
															})
														}
													</tbody>
												</table>
											</div>
										);
									})
								}
							</div>
							: undefined
					)
				}>
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			{
																				this.competitionId ?
																					<div>
																						<h1 className="sc_layouts_title_caption">{this.typeId == MatchGroupType.Results ? "Results" : this.typeId == MatchGroupType.All ? "Fixtures & Results" : "Fixtures"}</h1>
																						<p className="sc_layouts_title_breadcrumbs">{this.state.competition ? this.state.competition.name : ""} <span className="separator"> - </span> {this.state.division ? <span>{this.state.division.name}</span> : <span>All Divisions</span>}</p>
																					</div> :
																					<div>
																						{this.props.type == MatchGroupType.Upcoming ? <h1>My Fixtures</h1> : <h1>My Results</h1>}
																					</div>
																			}
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										{this.state.competition != null && <CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />}
									</div> : undefined
							}

							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										{
											AppCache.website.config.subHeader ? undefined :
												<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div id="trx_sc_title_789087444" className="sc_title sc_title_default mb-5">
															{
																this.state.division != null || this.state.competition != null ?
																	<div>
																		<h1 className=" m-0 sc_item_subtitle sc_align_center">
																			{this.divisionId ?
																				<div>
																					<span className="sc_item_title_text">{this.props.type === MatchGroupType.Upcoming ? "Fixtures" : this.typeId == MatchGroupType.All ? "Fixtures & Results" : "Results"}</span>
																				</div>
																				:
																				<span className="sc_item_title_text">{this.props.type === MatchGroupType.Upcoming ? "Fixtures" : this.typeId == MatchGroupType.All ? "Fixtures & Results" : "Results"}</span>
																			}
																		</h1>
																		<h2 className=" m-0 sc_item_title sc_align_center">
																			<span className="sc_item_title_text">{this.state.division ? this.state.division.name : ""}</span>
																		</h2>
																		<p className="text-center">
																			{this.state.competition ? this.state.competition.name : ""}
																		</p>
																	</div> :
																	<div>
																		{this.props.type == MatchGroupType.Upcoming ? <h1 className="m-0">My Fixtures</h1> : <h1 className="m-0">My Results</h1>}
																	</div>
															}
														</div>
													</div>
												</div>
										}
										<section className="">
											{!AppCache.website.config.subHeader && this.state.competition != null && <CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />}
											<div className="fixture-list">
												<Row>
													<Col className="text-right">
														{
															(this.competitionId || this.divisionId) &&
															<a id="filters" className="clickable mr-2" onClick={this.toggleFilters()}><i className={`fal fa-filter tx-24 ${this.state.filtering && "text-success"}`}></i></a>
														}
														{
															this.props.type === MatchGroupType.Results &&
															<a className="clickable" title="Individual Results" onClick={() => this.setState({ individualResults: !this.state.individualResults })}><i className={`fal fa-user tx-24 ${this.state.individualResults && "text-success"}`}></i></a>
														}
													</Col>
												</Row>
												{
													this.state.showFilters ?
														<div>
															<div className="row">
																<div className="col-4 mb-3">
																	<label>Type</label>
																	<Select
																		options={Options.matchGroupTypes}
																		value={this.type}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.type, () => this.typeId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Division</label>
																	<Select
																		isClearable
																		options={this.getDivisionOptions()}
																		value={this.division}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.division, () => this.divisionId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Venue</label>
																	<Select
																		isClearable
																		options={this.getVenueOptions()}
																		value={this.venue}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.venue, () => this.venueId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Clubs</label>
																	<Select
																		isClearable
																		options={this.getClubOptions()}
																		value={this.club}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.club, () => this.clubId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Teams</label>
																	<Select
																		isClearable
																		options={this.getTeamOptions()}
																		value={this.team}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.team, () => this.teamId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Date</label>
																	<Select
																		isClearable
																		options={this.state.dateOptions}
																		value={this.date}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.date, () => this.dateValue)}
																		closeMenuOnSelect={true} />
																</div>
																{this.state.roundOptions?.length > 0 && <div className="col-4 mb-3">
																	<label>Round</label>
																	<Select
																		isClearable
																		options={this.state.roundOptions}
																		value={this.round}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.round, () => this.roundValue)}
																		closeMenuOnSelect={true} />
																</div>}
																<div className="col-4 mb-3">
																	<label>Status</label>
																	<Select
																		isClearable
																		options={Options.approvalStatus}
																		value={this.status}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.status, () => this.statusValue)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Scratch/Forfeit</label>
																	<Select
																		isClearable
																		options={Options.matchConditions}
																		value={this.conditions}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.conditions, () => this.conditionsValue)}
																		closeMenuOnSelect={true} />
																</div>
															</div>
															<div className="row mt-3 mb-5">
																<div className="col-12 text-center">
																	<a className="btn btn-primary btn-rounded text-white clickable" onClick={this.reset()}>Reset</a>
																</div>
															</div>
														</div> : undefined
												}
												{
													this.state.matches.groups.map(g => {
														return (
															<div key={`matches-week-${g.week}-${g.type}-${this.props.type}`} className="fixture-week mb-5">
																<div className="date">
																	{
																		g.range === MatchGroupRange.Weekly && this.state.competition && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) < 3 ?
																			<div className="d-inline">
																				<h5 className="text-center">
																					<div className="d-inline">
																						{
																							g.date ?
																								<div><span className="tx-medium">Week {g.week}</span> - <span className="mg-r-2">{moment(g.date).format("Do MMMM")}</span></div> :
																								<span>Postponed</span>
																						}
																					</div>
																				</h5>
																			</div> :
																			g.range === MatchGroupRange.Weekly && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) > 4 && g.type != 4 ?
																			<div className="d-inline">
																				<h5 className="text-center">
																					<div className="d-inline">
																					{
																						g.matches[0].roundModel.endDate != null ?
																							<div><span className="tx-medium">{g.matches[0].roundModel.name}</span> - <span className="mg-r-2">Ends {moment(g.date).format("Do MMMM")}</span></div> :
																						g.matches[0].roundModel.startDate != null ?
																							<div><span className="tx-medium">{g.matches[0].roundModel.name}</span> - <span className="mg-r-2">{moment(g.date).format("Do MMMM")}</span></div> :
																							<div><span className="tx-medium">{g.matches[0].roundModel.name}</span>{/*  - <span className="tx-medium">Postponed</span> */}</div>
																					}
																					</div>
																				</h5>
																			</div> :
																			g.range === MatchGroupRange.Monthly && this.state.competition && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) !== 3 && (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat) !== 4 ?
																				<div className="d-inline">
																					<h5 className="text-center">
																						<span>{moment(g.date).format("MMMM")}</span>
																					</h5>
																				</div> :
																				<div className="d-inline">
																					<h5 className="text-center">
																						<div className="d-inline">
																							{
																								g.date ?
																									<>
																										<span className="mg-r-2 tx-medium">{moment(g.date).format("dddd")}</span>
																										<span className="tx-medium">{moment(g.date).format("Do MMMM")}</span>
																										{g.matches && g.matches.length > 0 && g.matches.every(m => m.venueId == g.matches[0].venueId) && <p className="m-0">
																											{g.matches[0].venue}
																										</p>}
																									</> :
																									<span className="mg-r-2 tx-medium">Postponed</span>
																							}
																						</div>
																					</h5>
																				</div>
																	}
																</div>
																{
																	g.type === MatchGroupType.Skipped ?
																		<div className="card p-4">
																			<p className="text-center text-bold m-0">{g.title}</p>
																		</div> : undefined
																}
																{
																	this.groupBy(g.matches, (f) => this.state.competition && ((this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat === 3) || (this.state.division?.fixtureFormat ?? this.state.competition.fixtureFormat === 4)) ? f.round : f.week).map((mg, index) => {
																		return (
																			<>
																				{mg[0].round &&
																					<div className="row col-12">
																						{this.state.selecting && <div className="col-1">
																							<button onClick={() => this.addRemoveRound(mg)} className="btn btn-primary btn-circle text-white clickable mr-2">
																								<div className="tx-20">{mg.every(m => this.state.selected?.some(s => m .id == s.matchId)) && <i className="icon fal fa-check"></i>}</div>
																							</button>
																						</div>}
																						<div className={`${this.state.selecting ? "col-11" : "col-12"} d-inline`}>
																							<h6 className="text-center">
																								<div className="d-inline">
																									<span className="tx-medium">
																										Round {mg[0].round} {mg[0].time && !this.state.competition.fastFormat && moment(mg[0].time).format("H:mm")}
																									</span>
																								</div>
																								{mg.every(m => m.venueId == mg[0].venueId) && !g.matches.every(m => m.venueId == g.matches[0].venueId) && <p className="m-0">
																									{mg[0].venue}
																								</p>}
																							</h6>
																						</div>
																					</div>
																				}
																				{
																					mg.sort((a, b) => this.props.type === MatchGroupType.Results ? (a.date > b.date) ? -1 : 1 : 0).map((m, index) => {
																						let event;
																						if (!this.competitionId) {
																							const date = moment.utc(m.date).format("YYYYMMDD");
																							const time = moment.utc(m.time).format("HH:mm");
																							const datetime = moment.utc(`${date} ${time}`, "YYYYMMDD HH:mm");

																							event = {
																								title: `${m.home?.displayName ?? m.homeName} vs ${m.away?.displayName ?? m.awayName}`,
																								description: `${m.name} - ${m.competition}`,
																								location: m.venue,
																								duration: "1",
																								startDateTime: datetime.format("YYYYMMDDTHH:mm:ssZ"),
																								endDateTime: datetime.add(1, "hours").format("YYYYMMDDTHH:mm:ssZ")
																							};
																						}

																						return (
																							<div className="row col-12">
																								{this.state.selecting && <div className="col-1" style={{paddingTop: "40px"}}>
																									<button onClick={() => this.addRemoveCard(m, !this.state.selected?.some(s => s.matchId == m.id))} className="btn btn-primary btn-circle text-white clickable mr-2">
																										<div className="tx-20">{this.state.selected?.some(s => s.matchId == m.id) && <i className="icon fal fa-check"></i>}</div>
																									</button>
																								</div>}
																								<div className={this.state.selecting ? "col-11" : "col-12"}>
																									<div key={`matches-${m.id}`} className="card fixture mb-3" onClick={this.select.bind(this, m)}>
																										<div className="row">
																											<div className="col-12 text-center">
																												{
																													m.date ?
																														<time className="sp-event-date" dateTime={moment(m.date).format("dddd Do")}>{moment(m.date).format("dddd Do")}</time> :
																														<span>TBD</span>
																												}
																												{
																													m.time ?
																														<time className="time d-md-none">
																															<span className="tx-14 d-block">{moment(m.time).format(moment(m.time).hour() < 12 ? "H:mma" : "H:mm")}</span>
																														</time> : undefined
																												}
																											</div>
																										</div>
																										<div className="row">
																											<div className="col-12 text-center">
																												{
																													m.number &&
																														<span>Match #{m.number}</span>
																												}
																											</div>
																										</div>
																										<div className="row">
																											<div className="col-6 col-md-5 text-center team">
																												<a className="team-logo logo-odd" href="" title={m.home?.displayName ?? m.homeName}>
																													{/* <img width="400" height="400" src="http://fc-united.axiomthemes.com/wp-content/uploads/2018/11/go-4-copyright-400x400.png" className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image" alt="" sizes="(max-width: 400px) 100vw, 400px" /> */}
																													<strong className="team-name">{m.home?.displayName ?? m.homeName}</strong>
																												</a>
																											</div>
																											<div className="d-none d-md-block col-md-2 text-center">
																												<div className="d-none d-md-block">
																													{
																														m.approved && m.home?.score != null && m.away?.score != null ?
																															<div>
																																<h3 className="sp-event-results">
																																	<span className="sp-result">{m.home?.score}</span> - <span className="sp-result">{m.away?.score}</span>
																																</h3>
																															</div> :
																															<div>
																																<h4 className="time">
																																	{m.time ? <span>{moment(m.time).format(moment(m.time).hour() < 12 ? "H:mma" : "H:mm")}</span> : <span>vs</span>}
																																</h4>
																															</div>
																													}
																												</div>
																											</div>
																											<div className="col-6 col-md-5 text-center team">
																												<a className="team-logo logo-even" href="" title={m.away?.displayName ?? m.awayName}>
																													{/* <img width="400" height="400" src="http://fc-united.axiomthemes.com/wp-content/uploads/2018/11/go-2-copyright-400x400.png" className="attachment-sportspress-fit-icon size-sportspress-fit-icon wp-post-image" alt="" sizes="(max-width: 400px) 100vw, 400px" /> */}
																													<strong className="team-name">{m.away?.displayName ?? m.awayName}</strong>
																												</a>
																											</div>
																										</div>
																										<div className="row d-md-none">
																											<div className="col-6 text-center score">
																												{
																													m.approved && m.home?.score != null && m.away?.score != null ?
																														<div>
																															<h3 className="sp-event-results">
																																<span className="sp-result">{m.home?.score}</span>
																															</h3>
																														</div> : undefined
																												}
																											</div>
																											<div className="col-6 text-center score">
																												{
																													m.approved && m.home?.score != null && m.away?.score != null ?
																														<div>
																															<h3 className="sp-event-results">
																																<span className="sp-result">{m.away?.score}</span>
																															</h3>
																														</div> : undefined
																												}
																											</div>
																										</div>
																										<div className="row">
																											<div className="col-12 text-center">{m.home?.type == 3 || m.away?.type == 3 ? "" : m.venue}</div>
																											<div className="col-12 text-center tx-12">{m.name}</div>
																										</div>
																										{this.state.individualResults ? <div className="row">
																											<div className="col-6 col-md-5 text-center team-players">
																												{m.homeScores && m.homeScores.map(p => (<p style={{ margin: "2px" }}>{p.name}: {p.score}</p>))}
																											</div>
																											<div className="d-none d-md-block col-md-2 text-center">
																												<div className="d-none d-md-block">
																												</div>
																											</div>
																											<div className="col-6 col-md-5 text-center team-players">
																												{m.awayScores && m.awayScores.map(p => (<p style={{ margin: "2px" }}>{p.name}: {p.score}</p>))}
																											</div>
																										</div> : undefined}
																										{
																											m.hasReport ?
																												<div className="row">
																													<div className="col-12 col-md-6 offset-md-3 text-center">
																														<Link to={`/league/${this.competitionId}/division/${this.divisionId}/match/${m.id}/report`} onClick={e => e.stopPropagation()}>Match Report</Link>
																													</div>
																												</div> : undefined
																										}
																										{
																											this.competitionId ? undefined :
																												<div className="row">
																													<div className="col-12 text-center">
																														<AddToCalendar event={event} />
																													</div>
																												</div>
																										}
																									</div>
																								</div>
																							</div>
																						);
																					})
																				}
																			</>
																		)
																	})
																}
																{this.state.competition && this.state.slots && this.drawEmptyRounds(g.date)}
																{g.matches.length > 0 && this.state.competition && this.state.division && (this.state.division.days.length > 0 || this.state.competition.days.length > 0) && (this.state.division.days.length > 0 ? this.state.division.days : this.state.competition.days).find(d => moment(moment(d.date).format("MM-DD-YYYY")).isSame(moment(g.date).format("MM-DD-YYYY"))) ?
																	<div className="d-inline">
																		<h6 className="text-center">
																			<div className="d-inline">
																				<span className="tx-medium">End {g.matches[g.matches.length - 1].time != null ? moment(g.matches[g.matches.length - 1].time).add((this.state.division.days.length > 0 ? this.state.division.days : this.state.competition.days).find(d => moment(moment(d.date).format("MM-DD-YYYY")).isSame(moment(g.date).format("MM-DD-YYYY"))).matchTime, "minutes").format("H:mm") : undefined}</span>
																			</div>
																		</h6>
																	</div>
																	: undefined}
															</div>
														);
													})
												}
												{this.state.competition && this.state.slots && this.drawEmptyRounds()}
												{
													this.state.matches.matches.length == 0 ? <p className="mb-0">No fixtures available</p> :
														<div>
															<div className="row" id="filters">
																{
																	this.state.division ?
																		<div className="col-12 text-left">
																			<span className="tx-bold">Last Updated</span> <span title={moment(this.state.division.updated).format("Do MMMM YYYY")}>{moment(this.state.division.updated).fromNow()}</span>
																		</div> : undefined
																}
																<div className="col-12 text-center">
																	{/* this.state.selecting && this.state.selected?.length > 0 && <a href={`${AppConfig.apiUrl}matches/matchcards/batch/?${queryString.stringify({access: JSON.stringify(this.state.selected)})}`} target="_blank" className="btn btn-primary btn-rounded text-white clickable mr-2">
																			Matchcard Printouts
																		</a> */}
																	{this.state.selecting && <button onClick={() => this.setState({ selecting: false, selected: null })} className="btn btn-primary btn-rounded text-white clickable mr-2">
																		Cancel
																	</button>}
																	{!this.state.selecting && <button onClick={() => this.setState({ selecting: true, selected: [] })} className="btn btn-primary btn-circle text-white clickable mr-2">
																		<div className="tx-20"><i className="icon fal fa-print"></i></div>
																	</button>}
																	{!this.state.selecting && <button onClick={this.export.bind(this)} title="Download Fixtures" className="btn btn-primary btn-circle text-white clickable mr-2">
																		<div className="tx-20"><i className="icon fal fa-file-csv"></i></div>
																	</button>}
																	{this.state.matches && !this.state.selecting && <AddToCalendar matches={this.state.matches.matches} />}
																</div>
															</div>
														</div>
												}
											</div>
										</section>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout >
		);
	}
}
