import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from "react-html-parser";
import {
	TwitterTimelineEmbed,
	TwitterShareButton,
	TwitterFollowButton,
	TwitterHashtagButton,
	TwitterMentionButton,
	TwitterTweetEmbed,
	TwitterMomentShare,
	TwitterDMButton,
	TwitterVideoEmbed,
	TwitterOnAirButton,
} from "react-twitter-embed";
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";

import { WebsiteImage, WebsiteImageType, Website } from "@models/website";
import { WebsiteService } from "@services/website.service";

import { SelectOption } from "@models/forms";
import { TenantService } from "@services/tenant.service";
import { ArticleFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

interface IArticleListPageProps {
	site?: string;
	match?: any;
}

interface IArticleListPageState {
	loading: boolean;
	nextPathname?: any;
	latest: Article[];
	articles?: PagedList<Article>;
	tenants: SelectOption<number>[];
	maxArticleValue: number;
	options: ArticleFilterOptions;
}

export class ArticleListPage extends React.Component<IArticleListPageProps, IArticleListPageState> {
	private pageNo: number = 1;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			latest: [],
			tenants: [],
			maxArticleValue: null,
			options: { count: AppCache.settings.pageSize, page: 1 }
		};
	}

	componentDidMount() {
		WebsiteService.updateActivity("Articles");

		this.load();
	}

	componentDidUpdate(
		props: IArticleListPageProps,
		state: IArticleListPageState
	) { }

	load() {
		const p1 = ArticleService.get({ ...this.state.options }).then(articles => {
			this.setState({ articles });
		});

		const p2 = TenantService.tenantOptions().then((result) => {
			const tenants = result.map(tenant => {
				const option = new SelectOption<number>();
				option.label = tenant.name;
				option.value = tenant.id;
				return option;
			});

			this.setState({ tenants });
		});

		Promise.all([p1]).then((result) => {
			this.setState({ loading: false });
		});
	}

	next = () => async () => {
		NProgress.start();

		const page = this.state.options.page + 1;
		const result = await ArticleService.get({ ...this.state.options, page });

		const articles = this.state.articles;
		articles.page = page;
		result.items.forEach(e => articles.items.push(e));

		this.setState({ articles, options: { ...this.state.options, page } });

		NProgress.done();
	}

	public render() {
		const newsImage: WebsiteImage = AppCache.website.images.find(
			(i) => i.type === WebsiteImageType.News
		);

		return (
			<PublicLayout
				theme='scheme_default'
				className='post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout'
			>
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{AppCache.website.config.subHeader ? (
								<div className='elementor elementor-6'>
									<section className='elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section'>
										<div className='elementor-container elementor-column-gap-extended'>
											<div className='elementor-row'>
												<div className='elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column'>
													<div className='elementor-column-wrap  elementor-element-populated'>
														<div className='elementor-widget-wrap'>
															<div
																className='sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='6850b4b'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer'>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn'
																data-id='612f306'
																data-element_type='widget'
																data-settings='{"_animation":"fadeIn"}'
																data-widget_type='trx_sc_layouts_title.default'
															>
																<div className='elementor-widget-container'>
																	<div
																		id='trx_sc_layouts_title_1841591666'
																		className='sc_layouts_title with_content without_image without_tint'
																	>
																		<div className='sc_layouts_title_content'>
																			<div className='sc_layouts_title_title'>
																				<h1 className='sc_layouts_title_caption'>
																					News
																				</h1>
																				<p className='header--description'></p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='01551c2'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div>
							) : (
								<div className='header_content_wrap mt-5'>
									<div className='container'>
										<div className='row'>
											<div className='col-12'>
												<h1 className='sc_layouts_title_caption'>News</h1>
											</div>
										</div>
									</div>
								</div>
							)}

							<div className='page_content_wrap'>
								<div className='container'>
									<div className='row'>
										<div className='col-lg col-md-12 mb-4'>
											<div className='content'>
												<div className='posts_container'>
													{/* <div>
														<Select
															value={this.tenant}
															options={this.state.tenants}
															defaultOptions
															onChange={this.handleChange.bind(this)}
															closeMenuOnSelect={true}
														/>
														<br />
													</div> */}

													<div className='row'>
														{this.state.articles && this.state.articles.items.map((a) => {
															return (
																<div
																	className="col-12 col-md-4 col-lg-3 d-flex mb-4"
																	key={`article-${a.id}`}
																>
																	<article className='post_item post_format_standard post_layout_classic post_layout_classic_3 post-174 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-blog tag-champions tag-competition'>
																		<div className='post_featured with_thumb hover_simple'>
																			<img
																				style={{ width: "100%" }}
																				src={
																					a.previewId
																						? `${AppConfig.baseUrl}image/${a.previewId}`
																						: newsImage
																							? `${AppConfig.baseUrl}image/${newsImage.imageId}`
																							: require("@assets/images/placeholders/tte-square.png")
																				}
																				className='attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image'
																			/>{" "}
																			<div className='mask'></div>
																			<Link
																				to={`/article/${a.slug}`}
																				className='icons'
																			></Link>
																		</div>
																		<div className='post_layout_classic_wrap'>
																			<div className='post_header entry-header'>
																				<div className='post_meta'>
																					<span className='post_meta_item post_date'>
																						<Link to={`/article/${a.slug}`}>
																							{moment(a.published).format(
																								"Do MMMM"
																							)}
																						</Link>
																					</span>{" "}
																				</div>
																				<h4 className='post_title entry-title'>
																					<Link
																						to={`/article/${a.slug}`}
																						className='icons'
																					>
																						{a.title}
																					</Link>
																				</h4>
																			</div>
																			<div className='post_content entry-content'>
																				<div className='post_content_inner'>
																					{a.summary}
																				</div>
																			</div>
																		</div>
																	</article>
																</div>
															);
														})}
													</div>
												</div>
												{
													this.state.articles.items.length < this.state.articles.total && (
														<div className='sp-view-all-link'>
															<a className='' onClick={this.next()}>
																Load More
															</a>
														</div>
													)
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout>
		);
	}
}
