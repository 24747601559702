import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Competition, CompetitionDay, CompetitionGrouping, CompetitionMember, CompetitionPreset, CreateCompetition, SetupCompetition } from "@models/competition";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { StandingGroup } from "@models/standing";
import { Reserve } from "@models/reserve";
import { PlayerStats } from "@models/player";
import { Division } from "@models/division";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { CompetitionFilterOptions } from "@models/filters";
import { TeamStats } from "@models/team";

export class CompetitionService {

	constructor() {
		//
	}

	public static search(term: string = "", take: number = 10): Promise<Competition[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/?search=${term}&take=${take}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Competition[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(competitionId: number): Promise<Competition> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Competition, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getReserves(competitionId: number): Promise<Reserve[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/reserves`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Reserve[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static data(query: DatatableQuery, archive: boolean, deleted: boolean = null, hidden: boolean = null, fastFormat: boolean = null): Promise<DatatableResponse<Competition>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(deleted ? { query, archive, deleted, hidden, fastFormat } : { query, archive, hidden, fastFormat }),
				success: (data: DatatableResponse<Competition>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByCompetitionRoles(query: DatatableQuery, archive: boolean, deleted: boolean = null, hidden: boolean = null): Promise<DatatableResponse<Competition>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/member/roles`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(deleted ? { query, archive, deleted, hidden } : { query, archive, hidden }),
				success: (data: DatatableResponse<Competition>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(competition: CreateCompetition): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(competition),
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static setup(competition: SetupCompetition): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`competitions/setup`, competition).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static update(competition: Competition): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(competition),
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateRounds(competitionId: number, weeks: CompetitionDay[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/update/rounds`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(weeks),
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(competitionId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getStandings(competitionId: number): Promise<StandingGroup[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competition/${competitionId}/standings`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: StandingGroup[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static publish(competitionId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/publish`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static archive(competitionId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/archive`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static hide(competitionId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/${competitionId}/hide`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static byeTeamsExists(competitionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/byeteamsexist`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getPlayerStats(competitionId: number, userId: string): Promise<PlayerStats> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PlayerStats>(`competitions/${competitionId}/player/${userId}/stats`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getTeamStats(competitionId: number, teamId: number): Promise<TeamStats> {
		return new Promise((resolve, reject) => {
			RestHelper.get<TeamStats>(`competitions/${competitionId}/team/${teamId}/stats`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getFilters(competitionId: number): Promise<CompetitionFilterOptions> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/${competitionId}/filter/options`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getArchives(): Promise<Competition[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/archives`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static get(fastFormat?:boolean): Promise<Competition[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/all${fastFormat ? "/fastformat": ""}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static RepairTeamLinks(competitionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`maintenance/${competitionId}/repair/teams`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static CheckTeamLinks(competitionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`maintenance/${competitionId}/check/teams`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static RepairTeamOrdering(competitionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`maintenance/${competitionId}/repair/teams/ordering`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getGrouping(groupId: number): Promise<CompetitionGrouping> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/grouping/${groupId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getGroupingCompetitions(groupId: number): Promise<Competition[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/grouping/${groupId}/competitions`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getGroupings(): Promise<CompetitionGrouping[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/groupings`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static createGrouping(name: string, groupType: number, startingCompetitionId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`competitions/grouping/create`, {name, groupType, startingCompetitionId}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static updateGrouping(group: CompetitionGrouping): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`competitions/grouping/update`, group).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static deleteGrouping(groupId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/grouping/${groupId}/delete`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static removeFromGrouping(competitionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/grouping/delete/${competitionId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static addToGrouping(groupId: number, competitionIds: number[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`competitions/grouping/${groupId}/add`, competitionIds).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static restore(competitionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`competitions/${competitionId}/restore`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getPresets(manage?: boolean, search?: string): Promise<CompetitionPreset[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post<CompetitionPreset[]>(`competitions/presets`, {search, manage}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getPresetById(id: number): Promise<CompetitionPreset> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CompetitionPreset>(`competitions/presets/${id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static removePreset(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`competitions/presets/${id}/remove`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsertPreset(model: CompetitionPreset): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`competitions/presets/upsert`, model).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCompetitionMembers(competitionId: number): Promise<CompetitionMember[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CompetitionMember[]>(`competitions/${competitionId}/roles`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static assignMember(member: CompetitionMember): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`competitions/${member.competitionId}/roles/assign`, member).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static removeMember(userId: string, competitionId: number): Promise<CompetitionMember[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CompetitionMember[]>(`competitions/${competitionId}/roles/${userId}/remove`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}

