import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { LoginService } from "./login.service";

import store from "store2";

import * as moment from "moment";
import * as qs from "query-string";

import RestHelper from "@helpers/rest.helper";
import { FieldArea, FieldGroup, FieldValue } from "@models/field";

export class FieldService {

	constructor() {
		//
	}

	public static getGroups(area: FieldArea): Promise<FieldGroup[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`fields/groups`, {
				params: {area},
				paramsSerializer: params => qs.stringify(params)
			}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getValues(itemId): Promise<FieldValue[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`fields/${itemId}/values`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static save(data: FieldValue[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`fields/values/update`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}
}
