import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { AppConfig } from "@config";

interface IPersonal {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IPersonal) => {
	const [contact, setContact] = useState<Contact>(props.contact);
	const [agree, setAgree] = useState<boolean>(false);

	const [groups, setGroups] = useState<FieldGroup[]>([]);
	const [values, setValues] = useState<FieldValue[]>([]);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {

	};

	const update = () => async (e: any) => {
		e.preventDefault();

		NProgress.start();

		await ContactService.upsert(contact);
		NProgress.done();

		toast.success("Personal details updated", {});

		props.onConfirm();
	};

	return (
		<>

			<Card className="mb-4 p-5">
				<h6>Personal Details</h6>
				<Row>
					<Col lg={6}>
						<FormGroup>
							<Label>Display Name</Label>
							<Input type="text" value={contact.name} onChange={e => setContact({ ...contact, name: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
			</Card>
			{
				contact.user &&
				<p><strong>Please Note:</strong> Personal information needs updating within <a href={AppConfig.sport80} target="_blank">TT Members</a></p>
			}
			<Card className="mb-4 p-5">
				<h6>Personal Details</h6>
				<Row>
					<Col>
						<FormGroup>
							<Label>First Name</Label>
							<Input type="text" value={contact.user?.firstName ?? contact.firstName} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, firstName: e.currentTarget.value })} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Last Name</Label>
							<Input type="text" value={contact.user?.lastName ?? contact.lastName} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, lastName: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
			</Card>
			<Card className="mb-4 p-5">
				<Row>
					<Col>
						<FormGroup>
							<Label>Email</Label>
							<Input type="text" value={contact.user?.email ?? contact.email} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, email: e.currentTarget.value })} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Phone</Label>
							<Input type="text" value={contact.user?.phoneNumber ?? contact.phoneNumber} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, phoneNumber: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
			</Card>
			<Card className="mb-4 p-5">
				<Row>
					<Col>
						<FormGroup>
							<Label>Address Line 1</Label>
							<Input type="text" value={contact.user?.addressLine1 ?? contact.addressLine1} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, addressLine1: e.currentTarget.value })} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Address Line 2</Label>
							<Input type="text" value={contact.user?.addressLine2 ?? contact.addressLine2} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, addressLine2: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>Town</Label>
							<Input type="text" value={contact.user?.city ?? contact.town} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, town: e.currentTarget.value })} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Postcode</Label>
							<Input type="text" value={contact.user?.postcode ?? contact.postcode} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, postcode: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>County</Label>
							<Input type="text" value={contact.user?.county ?? contact.county} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, county: e.currentTarget.value })} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Country</Label>
							<Input type="text" value={contact.user?.country ?? contact.country} disabled={contact.user !== undefined} onChange={e => setContact({ ...contact, country: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
			</Card>
			<Row>
				<Col>
					<div className="sp-view-all-link">
						<a onClick={update()}>
							Save
						</a>
					</div>
				</Col>
			</Row>
		</>
	);
};