import { DayOfWeek, EventSessionStatus, EventType } from "@models/event";
import { InvoiceDueDate, InvoicePaymentType, InvoiceStatus, TransactionType } from "@models/invoice";
import { RegistrationCompetitionType } from "@models/registration";
import { TenantType } from "@models/tenant";

export interface FilterOptions {
	page?: number;
	count?: number;
}

export interface ContactFilterOptions extends FilterOptions {
	search?: string;
	membersOnly?: boolean;
}

export interface MembershipFilterOptions extends FilterOptions {
	public?: boolean;
	available?: boolean;
}

export interface MembershipUserFilterOptions extends FilterOptions {
	membershipId?: number;
	search?: string;
	expired?: boolean;
	membershipUserId?: number;
}

export interface InvoiceFilterOptions extends FilterOptions {
	status?: InvoiceStatus;
	paymentType?: InvoicePaymentType;
	dueDate?: InvoiceDueDate;
	userId?: string;
	search?: string;

	//new filters
	name?: string;
	transactionType?: TransactionType;
	start?: string;
	end?: string;
	amountSelector?: NumericControlOptions;
	amount?: number;
	clubId?: number;
	competitionTypeId?: number;

	sorting: SortingProperty[];
	orderingQuery?: string;
}

export interface ConsentAgreementsFilterOptions extends FilterOptions {
	consentId?: string;
	search?: string;
}

export interface ConsentAssociationFilterOptions extends FilterOptions {
	membershipId?: number;
	spaceId?: number;
	eventId?: number;

	validOnly?: boolean;
}

export interface EventSessionFilterOptions extends FilterOptions {
	eventId?: number;
	start?: Date;
	end?: Date;
	cancelled?: boolean;
	status?: EventSessionStatus;
	types?: EventType[];
	published?: boolean;
	public?: boolean;
	contactId?: number;
	excludeDeletedEvents?: boolean;
}

export interface DateFilterOptions {
	start?: Date;
	end?: Date;
}

export interface EventFilterOptions extends FilterOptions, DateFilterOptions {
	types: EventType[];
	public?: boolean;
}

export interface BookingFilterOptions extends DateFilterOptions {
	search?: string;
	cancelled?: boolean;
}

export interface BookingSlotFilterOptions {
	spaceId: number;
	date: Date;
	resourceId?: number;
	public?: boolean;
}

export interface ResourcesFilterOptions extends DateFilterOptions {
	spaceId: number;
	sessionId?: number;
	daysOfWeek?: DayOfWeek[];
}

export interface TenantFilterOptions extends FilterOptions {
	type?: TenantType;
	search?: string;
}

export interface ClubFilterOptions extends FilterOptions {
	search?: string;
}

export interface LeagueFilterOptions extends FilterOptions {
	search?: string;
}

export interface CountyFilterOptions extends FilterOptions {
	search?: string;
}

export interface MediaFilterOptions extends FilterOptions {
	search?: string;
}

export interface ArticleFilterOptions extends FilterOptions {
	search?: string;
	tenantId?: number;
	featured?: boolean;
	hidden?: boolean;
}

export interface MemberFilterOptions extends FilterOptions {
	search?: string;
	leagueId?: number;
	clubId?: number;
	countyId?: number;
	roles?:string[];
}

export interface RegistrationFilterOptions extends FilterOptions {
	deleted?: boolean;
	published?: boolean;
	active?: boolean;
	sharedTenant?: boolean;
}

export interface RegistrationEntryFilterOptions extends FilterOptions {
	registrationId?: number;
	clubId?: number;
	categoryId?: number;
	status?: EntryFilterStatus;
}

export enum EntryFilterStatus {
	Paid = 1,
	Unpaid = 2
}

export interface EntryFilterOptions extends FilterOptions {
	search?: string;
	deleted?: boolean;
	published?: boolean;
	registrationId?: number;
	clubId?: number;
	entryId?: number;
	teamId?: number;
	paid?: boolean;
	categoryId?: number;
	competitionTypeId?: number;
	competition?: boolean;
}

export interface CostAdjustmentFilterOptions extends FilterOptions {
	registrationId?: number;
	clubId?: number;
	teamId?: number;
	playerId?: number;
	processed?: boolean;
	balance: boolean;
	invoiced?: boolean;
}

export enum SortingType {
	Ascending = 1,
	Descending = 2
}

export enum InvoiceSortingFields {
	Items = "Items",
	//Name = "Contact.FirstName, Contact.LastName", <-- Didn't work for descending order
	Name = "Contact.FirstName",
	Date = "Date",
	Due = "Due",
	Total = "Total"
}

export class SortingProperty {
	type: SortingType;
	name: InvoiceSortingFields;
}

export interface SiteReportFilterOptions extends FilterOptions {
	tenants?: number[];
	affiliationTypes?: number[];
}

export interface ClubReportFilterOptions {
	clubId: number;
	timePeriod?: number;
}

export enum NumericControlOptions {
	Equals = 1,
	Different = 2,
	Greater = 3,
	Smaller = 4,
}
