import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import History from "@helpers/history.helper";

import { loadStripe } from "@stripe/stripe-js";
import { StripeService } from "@services/stripe.service";
import PublicLayout from "@components/layouts/PublicLayout";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { MembershipService } from "@services/membership.service";
import { LoginService } from "@services/login.service";
import { Invoice, InvoiceType } from "@models/invoice";
import { InvoiceService } from "@services/invoice.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";

interface IConfirmationPageProps {
	site?: string;
	match?: any;
}

interface IConfirmationPageState {
	loading: boolean;
	nextPathname?: any;
	invoice?: Invoice;
}

export class ConfirmationPage extends React.Component<IConfirmationPageProps, IConfirmationPageState> {

	private reference?: string;

	constructor(props) {
		super(props);

		this.reference = (this.props.match.params as any).reference as string;
		this.state = { loading: true };
	}

	async componentDidMount() {
		const invoice = await InvoiceService.getByReference(this.reference);
		this.setState({ invoice, loading: false });
	}

	public render() {
		return (
			<PublicLayout className="sub-page">
				<Helmet />
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">
																				{this.state.invoice.type === InvoiceType.Event && "Booking Confirmation"}
																				{this.state.invoice.type === InvoiceType.Booking && "Booking Confirmation"}
																				{this.state.invoice.type === InvoiceType.Membership && "Membership Confirmation"}
																				{this.state.invoice.type === InvoiceType.General && "Payment Confirmation"}
																			</h1>
																			<p className="header--description">
																				{this.state.invoice.type === InvoiceType.Event && "Thank you for your booking"}
																				{this.state.invoice.type === InvoiceType.Booking && "Thank you for your booking"}
																				{this.state.invoice.type === InvoiceType.Membership && "Thank you for joining"}
																				{this.state.invoice.type === InvoiceType.General && "Thank you for your payment"}
																			</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">
														{this.state.invoice.type === InvoiceType.Event && "Booking Confirmation"}
														{this.state.invoice.type === InvoiceType.Booking && "Booking Confirmation"}
														{this.state.invoice.type === InvoiceType.Membership && "Membership Confirmation"}
														{this.state.invoice.type === InvoiceType.General && "Payment Confirmation"}
													</h1>
													<p className="header--description">
														{this.state.invoice.type === InvoiceType.Event && "Thank you for your booking"}
														{this.state.invoice.type === InvoiceType.Booking && "Thank you for your booking"}
														{this.state.invoice.type === InvoiceType.Membership && "Thank you for joining"}
														{this.state.invoice.type === InvoiceType.General && "Thank you for your payment"}
													</p>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="membership mg-t-20">

										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>);
	}
}
