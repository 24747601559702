import { Club } from "@models/club";
import { Registration, RegistrationCategory } from "@models/registration";
import { Member } from "./member";
import { UserBasic } from "./user";

export class Entry {
	public id: number;

	public registrationId: number;
	public registration: Registration;

	public clubId: number;
	public club: Club;

	public applicantId: string;
	public applicant: UserBasic;

	public status: EntryStatus;

	public affiliated: boolean;

	public termsAccepted: boolean;

	public allowInvoicing: boolean;

	public contactName?: string;
	public contactTelephone?: string;
	public contactEmail?: string;

	public categories: EntryCategory[];
}

export class EntryStats {
	public entryId: number;

	public teams: number;
	public players: number;
	public pending: number;
	public paid: number;
}

export class EntryCategory {
	public id: number;
	public entryId: number;
	public entry: Entry;

	public categoryId: number;
	public category: RegistrationCategory;

	public teams: EntryTeam[];

	public players: EntryPlayer[];
}

export class EntryTeam {
	public id: number;
	public title: string;
	public name: string;

	public categoryId: number;
	public category: EntryCategory;
	public registrationGroupId: number;
	public registrationGroup: RegistrationCategory;
	public paid?: Date;
	public cost: number;
	public players: EntryPlayer[];

	// team details
	public contact: string;
	public captainId: string;
	public captain: Member;
	public shortName: string;
	public abbreviation: string;
	public description: string;
	public badgeId?: number;
	public genderComposition?: number;

	// team colors
	public shirtColors?: string;
	public shortColors?: string;

	// team contact
	public addressLine1: string;
	public addressLine2: string;
	public addressLine3: string;
	public town: string;
	public county: string;
	public country: string;
	public postcode: string;
	public telephone: string;
	public email: string;

	public deleted?: Date;
	public entryId?: number;
	public retentionId: number;

	public ready?: Date;
	public approved?: Date;
	public invoiceItemId?: number;

	public venuePreference: VenuePreference[];

	public lateEntry: boolean;
}

export class EntryPlayer {
	public id: number;
	public categoryId: number;
	public category: EntryCategory;
	public teamId: number;
	public team: EntryTeam;
	public memberId: string;
	public firstName: string;
	public lastName: string;
	public displayName: string;
	public membershipNo: number;
	public played: boolean;
	public paid?: Date;
	public expired: boolean;
	public cost: number;
	public invoiceItemId?: number;
	public lateEntry: boolean;
}

export enum EntryStatus {
	New = 1,
}

export class EntryCosts {
	public pending: CostFeedback[];
	public paid: CostFeedback[];
	public invoiced: CostFeedback[];
	public unfinished: CostFeedback[];
	public balanceBreakdown: BalanceFeedback[];
	public balance: number;
}

export class CostFeedback {
	public paid: boolean;
	public categoryId: number;
	public category: EntryCategory;
	public total: number;
	public teams: number;
	public players: number;

	public entryPlayers: EntryPlayer[];
	public entryTeams: EntryTeam[];
}

export class BalanceFeedback {
	public competitionTypeId?: number;
	public categoryId?: number;
	public category: EntryCategory;
	public total: number;
}

export class RegisterEntryTeam {
	public id: number;

	public entryId: number;

	public registrationGroupId: number;

	public teamId: number;

	public entryTeam: EntryTeam;

	public players: EntryPlayer[];
}

export class RegisterEntryPlayer {
	public id: number;
	public entryId?: number;
	public categoryId?: number;
	public teamId?: number;
	public memberId: string;
	public displayName: string;
}

export class CostAdjustment {
	public id: number;
	public teamId?: number;
	public playerId?: number;
	public value: number;
	public reason: string;
	public processed?: Date;
	public invoiceId?: number;

	public clubId?: number;
	public club: Club;

	public competitionTypeId?: number;

	public registrationId?: number;
}

export class VenuePreference {
	public entryId: number;

	public teamId: number;

	public tenantId: number;

	public position: number;
}