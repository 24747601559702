import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, ModalHeader, ModalFooter } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import History from "@helpers/history.helper";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Booking } from "@models/booking";
import { EventMember } from "@models/event";
import { BookingService } from "@services/booking.service";
import { PagedList } from "@models/paging";
import { MembershipStatus, MembershipUser } from "@models/membership";
import { Invoice, InvoiceStatus } from "@models/invoice";
import LocaleHelper from "@helpers/locale.helper";
import { Consent, ConsentAgreement } from "@models/consent";
import { ConsentService } from "@services/consent.service";
import { confirmAlert } from "react-confirm-alert";
import ConsentsModal from "@components/modals/ConsentsModal";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";

interface IConsents {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IConsents) => {
	const [contact, setContact] = useState<Contact>(props.contact);
	const [agreements, setAgreements] = useState<PagedList<ConsentAgreement>>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const agreements = await ContactService.getAgreements(contact.id, {});
		setAgreements(agreements);
	};

	const handleConsentClick = (agreement: ConsentAgreement) => (e) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>Accept Terms?</p>
						<a className="btn btn-success text-white mr-2"
							onClick={async () => {
								await ConsentService.renew(agreement.id);
								toast.success("Successfully accepted");

								await load();
								onClose();
							}}
						>
							Yes, accept!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	const handleDeleteClick = (agreement: ConsentAgreement) => async (e) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>Remove agreement?</p>
						<a className="btn btn-danger text-white mr-2"
							onClick={async () => {
								await ConsentService.deleteAgreement(agreement.id);
								toast.success("Successfully removed");

								await load();
								onClose();
							}}
						>
							Yes, remove it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	};

	return (
		<>
			<div className="mb-4 memberships">
				<h6>Consents</h6>
				<Row>
					{
						agreements && agreements.items.map(i => (
							<Col xs={12}>
								<Card className="mb-3 p-3 clickable">
									<Row>
										<Col lg={4} className="d-flex align-items-center align-self-center">
											<div>
												<h6 className="small m-0">Title</h6>
												<p className="m-0" title={i.consent.title}>{i.consent.title}</p>
											</div>
										</Col>
										<Col lg={2} className="d-flex justify-content-center align-self-center">
											<div>
												<h6 className="small m-0">Agreed Date</h6>
												{i.accepted ? moment(i.accepted).format("Do MMM YYYY") : <p className="m-0" title={"Not Accepted"}>Not Accepted</p>}
											</div>
										</Col>
										<Col lg={2} className="d-flex justify-content-center align-self-center">
											<div>
												<h6 className="small m-0">Renewal</h6>
												{i.renew ? moment(i.renew).format("Do MMM YYYY") : (i.consent?.validUntil ? moment(i.consent?.validUntil).format("Do MMM YYYY") :  "N/A")}
											</div>
										</Col>
										<Col lg={1} className="d-flex justify-content-center align-self-center text-right">
											{(!i.accepted) && <span className="status bg-danger text-white m-0"></span>}
											{i.accepted && <span className="status bg-success mg-r-5 text-white"></span>}
										</Col>
										<Col lg={3} className="d-flex justify-content-end align-self-center text-right">
											{
												(!i.accepted && (!i.renew || moment(i.renew) <= moment())) &&
												<ExtendedButton tooltip="Accept Agreement" id={`accept_agreement_${i.id}`} onClick={handleConsentClick(i)} className="btn btn--circle text-white bg-success mr-2">
													<i className="far fa-check" />
												</ExtendedButton>
											}
											<ExtendedButton tooltip="Remove Agreement" id={`remove_agreement_${i.id}`} onClick={handleDeleteClick(i)} className="btn btn--circle text-white bg-danger mr-2">
												<i className="far fa-times" />
											</ExtendedButton>
											{
												i.consent.url &&
												<ExtendedLink tooltip="Agreement Document" id={`agreement_document_${i.id}`} target={"_blank"} href={i.consent.url} className="btn btn--circle text-white">
													<i className="far fa-file-contract" />
												</ExtendedLink>
											}
										</Col>
									</Row>
								</Card>
							</Col>
						))
					}
				</Row>
			</div>
		</>
	);
};