import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Card, CardBody, CardHeader, UncontrolledCollapse, Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalFooter, ModalHeader } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DelayInput } from "react-delay-input";
import { Options } from "@data";
import * as Queue from "promise-queue";
import History from "@helpers/history.helper";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import * as moment from "moment";
import { SelectOption } from "@models/forms";
import ObjectHelper from "@helpers/object.helper";
import { Format } from "@models/format";
import { ArrayHelper } from "@helpers/array.helper";
import { FormatService } from "@services/format.service";
import { ActionEntrant, ActionType, AddComment, MatchAction } from "@models/action";
import { Match, UpdateMatch } from "@models/match";
import { MatchService } from "@services/match.service";
import { LoginService } from "@services/login.service";
import { BookingService } from "@services/booking.service";
import { ConsentAssociations, CreateConsentAccept } from "@models/consent";
import { toast } from "react-toastify";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { ConsentService } from "@services/consent.service";
import { ProgressButton } from "@components/controls/ProgressButton";

interface IConsentsModal {
	spaceId?: number;
	membershipId?: number;
	eventId?: number;
	show?: boolean;
	autoCheck?: boolean;
	required?: ConsentAssociations[];
	onClose?: () => void;
	onConfirm?: () => void;
}

const ConsentsModal = (props: IConsentsModal) => {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(props.show);
	const [required, setRequired] = useState<ConsentAssociations[]>(props.required ?? []);
	const [checked, setChecked] = useState<ConsentAssociations[]>([]);

	useEffect(() => {
	}, [props.spaceId]);

	useEffect(() => {
		if (!props.required && props.autoCheck) {
			loadAgreements();
		} else {
			setRequired(props.required);
		}
	}, [props.required]);

	useEffect(() => {
		loadAgreements();
	}, [props.membershipId, props.eventId, props.spaceId]);

	useEffect(() => {
		if (props.show) {
			setChecked([]);
		}
		setShow(props.show);
	}, [props.show]);

	const loadAgreements = () => {
		if (LoginService.isAuthenticated && (props.spaceId || props.membershipId || props.eventId)) {
			ConsentService.getAssociations({ spaceId: props.spaceId, membershipId: props.membershipId, eventId: props.eventId }).then(associations => {
				const required: ConsentAssociations[] = [];
				ConsentService.getAgreementsByContact().then(agreements => {
					associations.forEach(association => {
						let exist = false;
						agreements.forEach(accepted => {
							if (accepted.consent.id === association.consentId && (moment(accepted.renew).isSameOrAfter(moment()) || !accepted.renew)) {
								if (accepted.accepted || !association.required) {
									exist = true;
								}
							}
						});

						if (!exist) {
							required.push(association);
						}
					});

					setRequired(required);

					if (required.length > 0) {
						setShow(true);
					}
				});
			});
		}
	};

	const acceptConsent = () => async (e: any) => {
		let accept = true;

		const opts: CreateConsentAccept[] = [];
		required.forEach((element, index) => {
			const accepted = checked.find(c => c.id === element.id);

			if (!accepted && element.required) {
				toast.warn(`${element.consent.title} is required`);
				accept = false;
				return;
			} else {
				opts.push({ consentId: element.consentId, accepted: accepted != null });
			}
		});

		if (accept) {
			setSubmitting(true);

			await ConsentService.accept(opts);
			toast.success("Consents updated");
			props.onConfirm();
			setShow(false);
		}
	};

	const handleConsentChange = (consent: ConsentAssociations) => (e) => {
		let _checked = checked;

		if (e?.currentTarget?.checked) {
			_checked.push(consent);
		} else {
			_checked = _checked.filter(c => c.id !== consent.id);
		}

		setChecked([..._checked]);
	};

	const handleCloseClick = () => (e) => {
		props.onClose();
		setShow(false);
	};

	return (
		<Modal size="lg" isOpen={show} style={{ zIndex: 99999 }}>
			<ModalBody className="p-4">
				<h4 className="text-primary mb-1">Agreements</h4>
				<p>Please read and accept the appropriate agreements before you continue</p>
				<div className="consents mb-5">
					{
						required && required.map((s, index) => {
							return (
								<div className="mb-3 p-3 consent">
									<h6 className="text-secondary mt-0">{s.consent.title}</h6>
									<Row>
										<Col>
											{s.consent.summary}
										</Col>
									</Row>
									<Row>
										<Col xs={12} lg={9}>
											<FormGroup className="mb-0">
												<label className=" mb-0">
													<div className="switch">
														<input type="checkbox" onChange={handleConsentChange(s)} />
														<span className="sliderch round"></span>
													</div>
													<span className="text ml-2 mt-1">I accept agreement</span>
												</label>
											</FormGroup>
											{s.required && <span className="required">* Required</span>}
											{!s.required && <span className="required text-success">Optional</span>}
										</Col>
										{
											(s.consent.url) &&
											<Col xs={12} lg={3}>
												<a className="btn btn-primary btn-rounded w-100 p-3" href={s.consent.url} target="_blank">View Agreement</a>
											</Col>
										}
									</Row>
								</div>
							);
						})
					}
				</div>
				<Row>
					<Col>
						<ProgressButton loading={submitting} className="btn btn-primary btn-rounded w-100" onClick={acceptConsent()}>Confirm</ProgressButton>
					</Col>
					<Col>
						<a tabIndex={0} className="btn btn-gray-200 btn-rounded w-100" onClick={handleCloseClick()}>Cancel</a>
					</Col>
				</Row>
			</ModalBody>
		</Modal>
	);
};

export default ConsentsModal;
