import ReactGA from "react-ga4";
import { AppConfig, ReleaseType, EntryType } from "@config";

import * as history from "history";
import { AppCache } from "@cache";

let basename = "";

if (AppConfig.entry === EntryType.manage) {
	basename = "/manage";
} else if (AppConfig.entry === EntryType.admin) {
	basename = "/admin";
} else if (AppConfig.entry === EntryType.public) {
	basename = "/";
}

const browserHistory = history.createBrowserHistory({ basename });

if (AppConfig.release !== ReleaseType.development) {
	browserHistory.listen((location, done) => ReactGA.send({ hitType: "pageview", page: browserHistory.location.pathname + browserHistory.location.search }));
}

export default browserHistory;
