import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";
import { Row, Col } from "reactstrap";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition, ReserveFilter } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { Helmet } from "react-helmet";
import { WebsiteCompetitionData } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";
import { Reserve } from "@models/reserve";
import { MatchService } from "@services/match.service";
import { Entrant } from "@models/entrant";
import { MemberService } from "@services/member.service";
import { SelectOption } from "@models/forms";
import { LoginService } from "@services/login.service";

interface IReservesPageProps {
	site?: string;
	match?: any;
}

interface IReservesPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	reserves: Reserve[];
	filter: ReserveFilter;
	entrantId?: number;
	entrants: Entrant[];
	token?: string;

}
export class ReservesPage extends React.Component<IReservesPageProps, IReservesPageState> {

	private competitionId: number;

	private checkExpiry: boolean;

	private entrant?: SelectOption<number>;

	private tokenTimer: any;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			reserves: [],
			filter: new ReserveFilter(),
			entrants: []
		};
	}

	componentDidMount() {
		//
		this.competitionId = this.props.match.params.competitionId as number;

		this.tokenTimer = setInterval(()=> {
			this.generateToken();
		}, 240000);

		this.generateToken();

		this.load();
	}

	componentDidUpdate(props: IReservesPageProps, state: IReservesPageState) {
		this.competitionId = this.props.match.params.competitionId as number;

		if (props.match.params.competitionId != this.competitionId || (AppCache.tenant.isTesting && AppCache.tenant.checkExpiry != this.checkExpiry)) {
			this.load();
		}
	}

	load() {
		const p1 = MatchService.reserveOptions(this.competitionId, this.state.filter).then(result => {
			this.setState({ reserves: result });
		});

		const p2 = CompetitionService.getById(this.competitionId).then(result => {
			this.setState({ competition: result });
		});

		const p3 = TeamService.getEntrants(this.competitionId).then(result => {
			this.setState({ entrants: result });
		})
		Promise.all([p1, p2, p3]).then(result => {
			this.checkExpiry = AppCache.tenant.checkExpiry;
			this.setState({ loading: false })
		});
	}

	getTeamOptions(): SelectOption<number>[] {
		const entrants = this.state.entrants.filter(e => e.teamId != null).sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
		return entrants.map(d => ({ label: d.name, value: d.id }));
	}

	handleChangeSelect = () => (e: SelectOption<number>) => {
		this.entrant = e;
		const filter = this.state.filter;
		filter.entrantId = e != null ? e.value : null;
		this.setState({ filter });
		this.load();
	}

	handleSwitchAvailable() {
		var filter = this.state.filter;
		filter.available = !filter.available;
		this.setState({ filter: filter })
		this.load();
	}

	generateToken() {
		LoginService.getDownloadToken().then(token => {
			this.setState({ loading: false, token });
		});
	}

	public render() {
		return (
			<PublicLayout className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{this.state.competition?.reserves.type == 6 ? "Pools" : "Reserves"}</h1>
																			<p className="sc_layouts_title_breadcrumbs">{this.state.competition?.name}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> : undefined
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										{
											AppCache.website.config.subHeader ? undefined :
												<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div className="sc_title sc_title_default">
															<h1 className=" m-0 sc_item_subtitle sc_align_center">
																<span className="sc_item_title_text">{this.state.competition?.reserves.type == 6 ? "Pools" : "Reserves"}</span>
															</h1>
															<h2 className=" m-0 sc_item_title sc_align_center">
																<span className="sc_item_title_text">{this.state.competition ? this.state.competition.name : ""}</span>
															</h2>
														</div>
													</div>
												</div>
										}
										<div className="row">
											<div className="col-6 mb-3">
												<label>Team</label>
												<Row>
													<Col xs={8}>
														<Select
															options={this.getTeamOptions()}
															isClearable
															value={this.entrant}
															defaultOptions
															onChange={this.handleChangeSelect()}
															closeMenuOnSelect={true} />
													</Col>
													<Col>
														<button onClick={() => this.handleSwitchAvailable()} className="btn btn-circle btn-icon mb-2">
															<div className="tx-20"><i className={`icon fal fa-user${this.state.filter.available ? "" : "-check"}`}></i></div>
														</button>
													</Col>
													<Col>
														<a className="btn btn-circle btn-icon mb-2" target="_blank" href={`${AppConfig.apiUrl}export/reserves/${this.competitionId}?token=${encodeURI(this.state.token)}&tenant=${AppCache.tenant.host}&query=${JSON.stringify(this.state.filter)}`}><div className="tx-20"><i className="icon far fa-download"></i></div></a>
													</Col>
												</Row>
											</div>
											<div className="col-4 mb-3">
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div>
													<div className="standings">
														<div>
															<div className="table-responsive">
																<div>
																	<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Name">Name</th>
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team">Team</th>
																				<th className="data-pts sorting text-center" rowSpan={1} colSpan={1} aria-label="Step Ups ">Total</th>
																				{this.entrant ? <th className="data-pts sorting text-center" rowSpan={1} colSpan={1} aria-label="Step Ups ">For Team</th> : undefined}
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.reserves.map(r => {
																					return (
																						<tr key={`reserves-${r.id}-${r.team.id}`} className="sp-row-no-0 odd" role="row">
																							<td className="data-name" data-label="Name">{r.name}</td>
																							<td className="data-name nowrap" data-label="Team"><Link to={`/league/${this.competitionId}/team/${r.team.id}`}>{r.team.displayName}</Link></td>
																							{
																								this.state.competition?.reserves.maxStepUp > 0 ?
																									<td className={`data-pts text-center ${r.total > this.state.competition.reserves.maxStepUp ? "text-danger" : ""}`} data-label="Step Ups">{r.total}/{this.state.competition.reserves.maxStepUp}</td> :
																									<td className="data-pts text-center" data-label="Step Ups">{r.total}</td>
																							}
																							{r.teams && this.entrant ? 
																								this.state.competition?.reserves.maxTeamStepUp ? 
																									<td className={`data-pts text-center ${r.total > this.state.competition.reserves.maxStepUp ? "text-danger" : ""}`} data-label="Step Ups">{r.teams[0].teamStepUp}/{this.state.competition.reserves.maxTeamStepUp}</td> 
																									: <td className="data-pts text-center" data-label="Step Ups">{r.teams[0].teamStepUp}</td>
																								:undefined
																							} 
																						</tr>
																					);
																				})
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
