import * as React from "react";
import * as ReactDOM from "react-dom";

class ScrollToTop extends React.Component<any, any> {
    constructor(props) {
		super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (<div></div>)
    }
  }
  
  export default ScrollToTop;