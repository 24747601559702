import * as React from "react";
import { Redirect } from "react-router";

import { LoginService } from "@services/login.service";

import { AppCache } from "@cache";
import { ModuleType, TenantType } from "@models/tenant";
import { MatchCardEntryLevel } from "@models/match";
import { AppConfig, EntryType } from "@config";

export interface ICanProps {
	yes: void,
	no: void,
	rules?: string[],
	entryRules?: MatchCardEntryLevel[],
	entry?: MatchCardEntryLevel,
	unauthorised?: any;
	tenants:TenantType[];
	modules?: ModuleType[];
	render?: any;
	children?: any;
}

export class Can extends React.Component<ICanProps, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		yes: () => null,
		no: () => null,
		rules: [],
		entryRules: [],
		entry: MatchCardEntryLevel.None,
		tenants: []
	};

	componentDidMount() {
		//
	}

	isAllowed(): boolean {
		if(this.props.modules) {
			let enabled = false;

			for (let i = 0; i < this.props.modules.length; i++) {
				const module = this.props.modules[i];

				if(AppCache.tenant.config.bookingsModule && module === ModuleType.Bookings) {
					enabled = true;
				}

				if(AppCache.tenant.config.competitionsModule && module === ModuleType.Competitions) {
					enabled = true;
				}

				if(AppCache.tenant.config.registrationsModule && module === ModuleType.Registrations) {
					enabled = true;
				}
			}

			if(!enabled)
				return false;
		}

		if(this.props.entryRules.length > 0 && AppConfig.entry === EntryType.public) {
			if (!LoginService.isAuthenticated) {
				return false;
			}

			for (let index = 0; index < this.props.entryRules.length; index++) {
				const element = this.props.entryRules[index];

				if(this.props.entry === element){
					return true;
				}
			}
		} else if(this.props.rules.length > 0) {
			if (!LoginService.isAuthenticated) {
				return false;
			}
			for (let index = 0; index < this.props.rules.length; index++) {
				const element = this.props.rules[index];
				
				const details = LoginService.getDetails();
				const rules = details.rules;
				const rule = element.replace("?", AppCache.tenant.code);
				if (rules.indexOf(rule) >= 0) {
					return true;
				}
			}
		} else {
			return true;
		}

		return false;
	}

	isTenant() {
		if(this.props.tenants.length > 0) {
			if((this.props.tenants.indexOf(TenantType.Club) >= 0 && AppCache.tenant.clubId != null) ||
			(this.props.tenants.indexOf(TenantType.County) >= 0 && AppCache.tenant.countyId != null) ||
			(this.props.tenants.indexOf(TenantType.League) >= 0 && AppCache.tenant.leagueId != null)) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	render() {
		return this.isTenant() ? 
			(this.isAllowed() ? (this.props.render ? (typeof(this.props.render) == "function" ? this.props.render() : this.props.render) : (this.props.children ? this.props.children : false) ) : (this.props.unauthorised ? this.props.unauthorised : false)) : false;
	}
}