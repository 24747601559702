import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership } from "@models/membership";
import { PagedList } from "@models/paging";
import { EventService } from "@services/event.service";
import { Event, EventSession, EventSessionExtension, EventType } from "@models/event";
import LocaleHelper from "@helpers/locale.helper";
import { Website, WebsiteCustomMenuItem, WebsiteMenuPreset } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";
import { Options } from "@data";

interface ISessionListProps {
	site?: string;

}

interface ISessionListState {
	loading: boolean;
	sessions?: PagedList<EventSession>;
	website?: Website;
	websiteMenu?: WebsiteCustomMenuItem;
	start: Date;
	search?: string;
}
export class SessionListPage extends React.Component<ISessionListProps, ISessionListState> {

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			start: moment().toDate()
		};
	}

	componentDidMount() {
		WebsiteService.updateActivity("Sessions");
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		EventService.getSessions({ start: this.state.start, types: [EventType.Sessions], public: true }).then(sessions => {
			WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Sessions).then(result => {
				this.setState({
					websiteMenu: result,
					sessions,
					loading: false
				}, () => {
					MiscHelper.scrollToTop();
				});
			});
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Sessions")}</h1>
																			<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available sessions")}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Sessions")}</h1>
													<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available sessions")}</p>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="pre-content">
										{
											this.state.websiteMenu?.body &&
											<Row>
												<Col>
													{ReactHtmlParser(this.state.websiteMenu.body)}
												</Col>
											</Row>
										}
									</div>
									<div className="content">
										<div className="sessions-list mg-t-20">
											{
												this.state.sessions.items.map(e => {
													const session = Object.assign(new EventSessionExtension(), e);
													return (
														<Card className="mb-4">
															<CardBody>
																<Row>
																	<Col md={4} className="my-auto pr-0">
																		<h5 className="m-0">{session.event.title}</h5>
																		<p className="mb-0">{session.event.summary}</p>
																	</Col>
																	<Col md={e.memberPrice == null ? 4 : 3} className="my-auto p-0">
																		{e.space && <div><i className="far fa-table-tennis mr-1"></i>{e.space.name}</div>}
																		<div><i className="far fa-calendar fa-fw mr-1"></i>{moment(e.startDate).format("dddd Do MMMM")}</div>
																		<div><i className="far fa-clock fa-fw mr-1"></i>{moment(e.startDate).format("HH:mm")}-{moment(e.endDate).format("HH:mm")}</div>
																		{!e.cancelDateTime && e.available != null && <div><i className="far fa-users mr-1"></i>Available: {e.available}/{e.event.limit}</div>}
																		{e.cancelDateTime && e.cancelReason && <div><i className="far fa-bell-slash fa-fw mr-1"></i>{e.cancelReason}</div>}
																		{
																			(session.bookableUntil) &&
																			<span className="min-period"><i className="far fa-stopwatch fa-fw mr-1" /> <span>Online bookings will close {Options.minTimeAdvanced.find(m => m.value === e.minAdvancedPeriod).label} before the start of the session</span></span>
																		}
																	</Col>
																	{e.memberPrice == null && <Col md={1}></Col>}
																	<Col md={1} className={`m-0 p-0 my-auto text-center event-price public-price ${e.price != null && e.price < e.event.cost && "disabled-price"}`}>
																		{
																			e.event.cost != null &&
																			<>
																				<span className="small">&nbsp;</span>
																				{e.event.cost > 0 && <h4 className="membership-price m-0 text-nowrap">{LocaleHelper.toPrice(e.event.cost)}</h4>}
																				{e.event.cost === 0 && <h4 className="membership-price m-0">FREE</h4>}
																				{((e.price == null || e.memberPrice != null) || (e.price != null && e.price !== e.event.cost)) && <span className="small">Public</span>}
																			</>
																		}
																	</Col>
																	{
																		e.memberPrice != null &&
																		<Col md={2} className={`m-0 p-0 my-auto text-center event-price member-price ${((e.price != null && e.price === e.event.cost) || (LoginService.isAuthenticated && e.price == null)) && "disabled-price"}`}>
																			{
																				(e.price == null || e.price === e.event.cost) && e.memberPrice != null && <>
																					<span className="small">From</span>
																					{e.memberPrice === 0 && <h4 className="membership-price m-0">FREE</h4>}
																					{e.memberPrice > 0 && <h4 className="m-0 text-nowrap">{LocaleHelper.toPrice(e.memberPrice)}</h4>}
																					<span className="small">
																						{e.event.cost == null ? "Members Only" : "Membership Cost"}
																					</span>
																				</>
																			}
																			{
																				(e.price != null && (e.event.cost == null || e.price < e.event.cost)) &&
																				<>
																					<span className="small">&nbsp;</span>
																					{(e.price === 0 || e.usage) && <h4 className="membership-price m-0">FREE</h4>}
																					{(e.price > 0 && !e.usage) && <h4 className="m-0 text-nowrap">{LocaleHelper.toPrice(e.price)}</h4>}
																					<span className="small">Membership</span>
																				</>
																			}
																		</Col>
																	}
																	<Col md={2} className="my-auto">
																		{
																			((e.available >= 1 || e.available == null) && !e.cancelDateTime && session.bookable) &&
																			<>
																				{
																					(!LoginService.isAuthenticated || e.price != null) &&
																					<Link to={`/sessions/${e.id}/join`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																						<span className="sc_button_text">
																							<span className="sc_button_title">Book</span>
																						</span>
																					</Link>
																				}
																				{
																					(LoginService.isAuthenticated && e.price == null) &&
																					<Link to={`/memberships`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																						<span className="sc_button_text">
																							<span className="sc_button_title">Book</span>
																						</span>
																					</Link>
																				}
																			</>
																		}
																		{
																			session.available === 0 &&
																			<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																				<a className="bg-primary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																					<span className="sc_button_text">
																						<span className="sc_button_title">Full</span>
																					</span>
																				</a>
																			</div>
																		}
																		{
																			session.cancelDateTime &&
																			<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																				<a className="bg-primary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																					<span className="sc_button_text">
																						<span className="sc_button_title">Cancelled</span>
																					</span>
																				</a>
																			</div>
																		}
																		{
																			(session.available > 0 && !session.bookable) &&
																			<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																				<a className="bg-warning text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																					<span className="sc_button_text">
																						<span className="sc_button_title">Closed</span>
																					</span>
																				</a>
																			</div>
																		}
																	</Col>
																</Row>
															</CardBody>
														</Card>
													);
												})
											}
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
