import { SelectOption } from "@models/forms";
import { PotmType, MatchGroupType } from "@models/match";
import { AverageSort } from "@models/average";
import { IStringKeyValuePair } from "@models/interfaces";
import { Gender } from "@models/user";
import { EventType } from "@models/event";
import { WebsiteMenu, WebsiteMenuPreset, WebsiteVisibility } from "@models/website";
import { TenantType } from "@models/tenant";
import { RegistrationCompetitionType, RegistrationEntrantType } from "@models/registration";
import { NumericControlOptions } from "@models/filters/filterOptions";

export class Options {

	public static get matchCardSubmission(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Disabled", value: 0 },
			{ label: "Team Captains", value: 1 },
			{ label: "Team Members", value: 2 },
			{ label: "League Members", value: 3 }
		];
		return options;
	}

	public static get competitionMenuDisplays(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Full", value: 1 },
			{ label: "Division Only", value: 2 },
			{ label: "Competition Only", value: 3 }
		];
		return options;
	}

	public static get competitionPreferences(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "League", value: 1 },
			{ label: "Club", value: 2 },
			{ label: "Both", value: 3 }
		];
		return options;
	}

	public static get matchGroupTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: MatchGroupType.All },
			{ label: "Fixtures", value: MatchGroupType.Upcoming },
			{ label: "Results", value: MatchGroupType.Results }
		];

		return options;
	}

	public static get approvalStatus(): SelectOption<boolean>[] {
		const options: SelectOption<boolean>[] = [
			{ label: "Unapproved", value: false },
			{ label: "Approved", value: true }
		];

		return options;
	}

	public static get matchConditions(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Forfeited Match", value: 1 },
			{ label: "Forfeited Players", value: 2 },
			{ label: "Scratched Sets", value: 3 },
			{ label: "Matches with Missing Players", value: 4 },
			{ label: "Games with 0-11", value: 5 }
		];

		return options;
	}

	public static get matchCardApproval(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Disabled", value: 0 },
			{ label: "Opposing Captain", value: 4 },
			{ label: "Opposing Members", value: 5 },
			{ label: "Team Captains", value: 1 },
			{ label: "Team Members", value: 2 },
			{ label: "League Members", value: 3 }
		];
		return options;
	}

	public static get gamesPerSet(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "2", value: 2 },
			{ label: "3", value: 3 },
			{ label: "4", value: 4 },
			{ label: "5", value: 5 },
			{ label: "7", value: 7 }
		];
		return options;
	}

	public static get gameFormats(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "11 up", value: 1 },
			{ label: "21 up", value: 2 },
		];
		return options;
	}

	public static get tableColumns(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Won/Lost/Drawn", value: 1 },
			{ label: "Sets For/Against", value: 2 },
			{ label: "Games For/Against", value: 3 },
			{ label: "Points For/Against", value: 4 },
			{ label: "Point Adjustments", value: 5 },
			{ label: "Sets Ratio", value: 6 },
			{ label: "Games Ratio", value: 7 },
		];
		return options;
	}

	public static get tableOrderTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Played - Sets For - Sets Against - Won - Draw", value: 1 },
			{ label: "Sets For - Games For - Won - Drawn", value: 2 },
			{ label: "Sets For - Won - Drawn - Games For", value: 3 },
			{ label: "Won - Drawn", value: 4 },
			{ label: "Won - Drawn - Sets For - Games For", value: 5 },
			{ label: "Sets For - Sets Against - Games For - Games Against - Points Awarded For - Points Awarded Against - Won - Drawn", value: 6 },
			{ label: "Won - Sets For - Games For - Drawn", value: 7 },
			//{ label: "Won - Lost - Head to Head - Sets For - Sets Against - Drawn", value: 8 },
			{ label: "Won - Sets Difference - Game Difference - Points Difference - Name", value: 16 },
			{ label: "Sets Ratio - Games Ratio - Points Awarded Ratio", value: 17 }
		];
		return options;
	}

	public static get matchCardLayouts(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Default-Horizontal", value: 1 },
			{ label: "Default-Vertical", value: 2 },
		];
		return options;
	}

	public static get playerLabels(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Numerical", value: 1 },
			{ label: "Alphabetical", value: 2 },
		];
		return options;
	}


	public static get printoutLayouts(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Default", value: 0 },
			{ label: "Senior BCL", value: 1 },
			{ label: "Youth Boy's/Girl's BCL", value: 2 },
			{ label: "Youth Cadet/Junior BCL", value: 3 },
			{ label: "Women's BCL", value: 4 },
			{ label: "Veterans BCL", value: 5 },
		];
		return options;
	}

	public static get printoutSubLayouts(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Youth Boy's", value: 1, group: 2 },
			{ label: "Youth Girl's", value: 2, group: 2 },
			{ label: "Youth Cadets", value: 1, group: 3 },
			{ label: "Youth Juniors", value: 2, group: 3 },
			{ label: "Men's", value: 1, group: 5 },
			{ label: "Women's", value: 2, group: 5 },
		];
		return options;
	}

	public static get daysOfWeek(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Monday", value: 1 },
			{ label: "Tuesday", value: 2 },
			{ label: "Wednesday", value: 3 },
			{ label: "Thursday", value: 4 },
			{ label: "Friday", value: 5 },
			{ label: "Saturday", value: 6 },
			{ label: "Sunday", value: 0 },
		];
		return options;
	}

	public static get playTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Home and Away", value: 1 },
			{ label: "Home", value: 2 },
			{ label: "Away", value: 3 },
		];
		return options;
	}

	public static get resultDisplay(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Sets", value: 1 },
			{ label: "Games", value: 2 },
			{ label: "Points", value: 3 },
			{ label: "Points Awarded", value: 4 },
		];
		return options;
	}

	public static get mediaCategories(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "General", value: 0 },
			{ label: "Slider", value: 1 },
			{ label: "Header", value: 2 },
			{ label: "News", value: 3 }
		];
		return options;
	}

	public static get fixtureFormats(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Standard Chart", value: 1 },
			{ label: "Standard Chart Rounds", value: 4 },
			{ label: "Standard Chart Knockout", value: 5},
			{ label: "Round Robin", value: 2 },
			{ label: "Round Robin Rounds", value: 3 }
		];
		return options;
	}

	public static get calculationTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Sets", value: 1 },
			{ label: "Games", value: 2 }
		];
		return options;
	}

	public static get averageOrderTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Win Percentage", value: 1 },
			{ label: "Most Wins", value: 2 }
		];
		return options;
	}

	public static get captainTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Club Members", value: 1 },
			{ label: "Team Members", value: 2 }
		];
		return options;
	}

	public static get playerTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "League + Club Members", value: 1 },
			{ label: "League Members only", value: 2 }
		];
		return options;
	}

	public static get reserveTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "None", value: 0 },
			{ label: "Any League Player", value: 1 },
			{ label: "Any Club Player", value: 2 },
			{ label: "Same or Lower Ranking Club Player", value: 3 },
			{ label: "Lower Ranking Club Player", value: 4 },
			{ label: "Lower Ranking Team Player", value: 5 },
			{ label: "SBL Reserves", value: 6 },
			{ label: "BL Reserves", value: 7 },
			{ label: "Same or Lower Ranking League Player", value: 8 },
			{ label: "Lower Ranking League Player", value: 9 },
		];
		return options;
	}

	public static get stepUpTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All Matches", value: 1 },
			{ label: "Wins Only", value: 2 }
		];
		return options;
	}

	public static get messageCategoryType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All TTE Members", value: 1 },
			{ label: "All Players", value: 2 },
			{ label: "All Captains", value: 3 },
			{ label: "All Club Secretaries", value: 4 },
			{ label: "All Contacts", value: 5 },
			{ label: "All Current TTE Members", value: 7 },
			{ label: "All Current Players", value: 8 },
			{ label: "All Team Members", value: 9 },
		];
		return options;
	}

	public static get pageLayouts(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Member Group", value: 1 },
			{ label: "Captains List", value: 2 },
			{ label: "Secretaries List", value: 3 }
		];
		return options;
	}

	public static get potmTypes(): SelectOption<PotmType>[] {
		const options: SelectOption<number>[] = [
			{ label: "Manual", value: PotmType.Manual },
			{ label: "Referee", value: PotmType.Referee },
			{ label: "Peer", value: PotmType.Peer },
			{ label: "Captain", value: PotmType.Captain },
		];
		return options;
	}

	public static get averageSort(): SelectOption<AverageSort>[] {
		const options: SelectOption<number>[] = [
			{ label: "Player", value: AverageSort.Name },
			{ label: "Team", value: AverageSort.Team },
			{ label: "Club", value: AverageSort.Club }
		];
		return options;
	}

	public static get chartDisplay(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Days", value: 1 },
			{ label: "Months", value: 2 },
			//{ label: "Seasons", value: 3 },
			{ label: "Totals", value: 4 },
		];
		return options;
	}

	public static get exportDateGrouping(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "None", value: 0 },
			{ label: "Days", value: 1 },
			{ label: "Months", value: 2 },
		];
		return options;
	}

	public static get widgetTypes(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "Table", value: 1 },
			{ label: "Fixture", value: 2 },
			{ label: "Results", value: 3 },
			{ label: "Averages", value: 4 },
			{ label: "Rankings", value: 5 },
		];
		return options;
	}

	public static get membershipTypes(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "Associate Member", value: 1 },
			{ label: "U11 Compete", value: 15 },
			{ label: "U11 Compete Plus", value: 19 },
			{ label: "U19 Compete", value: 14 },
			{ label: "U19 Compete Plus", value: 18 },
			/* { label: "Cadet & Junior Compete", value: 2 },
			{ label: "Cadet & Junior Compete Plus", value: 3 }, */
			{ label: "Senior Compete", value: 4 },
			{ label: "Senior CompetePlus", value: 5 },
			{ label: "Honourary Member", value: 6 },
			{ label: "Home Countries Member", value: 7 },
			/* { label: "ClubPlayerSenior", value: 8 },
			{ label: "ClubPlayerCadet", value: 9 },
			{ label: "TTKidz", value: 10 }, */
			{ label: "International Upgrade", value: 11 },
			{ label: "Junior International Upgrade", value: 12 },
			{ label: "International Membership", value: 13 },
			
			/* { label: "U11 ClubPlay", value: 16 },
			{ label: "U19 ClubPlay", value: 17 }, */
			
			
		];
		return options;
	}

	public static get membershipTypesLabels(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "Associate Member", value: 1 },
			{ label: "Cadet & Junior Compete", value: 2 },
			{ label: "Cadet & Junior Compete Plus", value: 3 },
			{ label: "Senior Compete", value: 4 },
			{ label: "Senior CompetePlus", value: 5 },
			{ label: "Honourary Member", value: 6 },
			{ label: "Home Countries Member", value: 7 },
			{ label: "Senior Club Player", value: 8 },
			{ label: "Cadet Club Player", value: 9 },
			{ label: "TTKidz", value: 10 },
			{ label: "International Upgrade", value: 11 },
			{ label: "Junior International Upgrade", value: 12 },
			{ label: "International Membership", value: 13 },
			{ label: "U19 Compete", value: 14},
			{ label: "U11 Compete", value: 15},
			{ label: "U11 Club Player", value: 16},
			{ label: "U19 Club Player", value: 17},
			{ label: "U19 CompetePlus", value: 18},
			{ label: "U11 CompetePlus", value: 19},			
		];
		return options;
	}

	public static get gender(): SelectOption<number>[] {
		const options: SelectOption<Gender>[] = [
			{ label: "Male", value: 1 },
			{ label: "Female", value: 2 }
		];
		return options;
	}

	public static get teamGender(): SelectOption<number>[] {
		const options: SelectOption<Gender>[] = [
			{ label: "Male", value: 1 },
			{ label: "Female", value: 2 },
			{ label: "Mixed", value: null }
		];
		return options;
	}

	public static get accessType(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "TTE Members", value: 1 },
			{ label: "League Members", value: 2 },
			{ label: "Selected Members", value: 3 },
			{ label: "Member Groups", value: 4 }
		];
		return options;
	}

	public static get competitionGroupType(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "Split Competition", value: 1 },
			{ label: "Split Season", value: 2 },
			{ label: "Event", value: 3 }
		];
		return options;
	}

	public static get splitPreference(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "Equal", value: 1 },
			{ label: "Top", value: 2 },
			{ label: "Bottom", value: 3 }
		];
		return options;
	}

	public static get ageGroup(): Array<SelectOption<number>> {
		const options: Array<SelectOption<number>> = [
			{ label: "Cadet", value: 1 },
			{ label: "Junior", value: 2 },
			{ label: "Senior", value: 3 },
			{ label: "Veteran", value: 4 },
			{ label: "Over60", value: 5 }
		];
		return options;
	}

	public static get paymentTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "One Off", value: 1 },
			{ label: "Week(s)", value: 2 },
			{ label: "Month(s)", value: 3 },
			{ label: "Year(s)", value: 4 },
			{ label: "Day(s)", value: 5 }
		];
		return options;
	}

	public static get durationTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Days", value: 1 },
			{ label: "Weeks", value: 2 },
			{ label: "Months", value: 3 },
			{ label: "Years", value: 4 },
			{ label: "Fixed Term", value: 5 }
		];
		return options;
	}

	public static get usageTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Weekly", value: 1 },
			{ label: "Monthly", value: 2 },
			{ label: "Never", value: 3 }
		];
		return options;
	}

	public static get contactType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Prospect", value: 1 },
			{ label: "Supplier", value: 2 },
			{ label: "Other", value: 3 }
		];
		return options;
	}

	public static get contactLinkType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Full", value: 1 },
			{ label: "Guest", value: 2 },
		];
		return options;
	}

	public static get invoiceStatus(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: 0 },
			{ label: "Pending", value: 1 },
			{ label: "Failed", value: 3 },
			{ label: "Paid", value: 4 },
			{ label: "Partial", value: 5 },
			{ label: "Refunded", value: 6 },
			{ label: "Void", value: 7 },
			{ label: "Outstanding", value: 2 },
		];
		return options;
	}

	public static get invoiceDueDate(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: null},
			{ label: "Overdue", value: 1 },
			{ label: "Not Yet Due", value: 2 },			
		];
		return options;
	}

	public static get transactionTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: null},
			{ label: "Card", value: 1 },
			{ label: "Cash", value: 2 },			
			// { label: "Balance", value: 3 },
			{ label: "Stripe", value: 4 },
			{ label: "BACS", value: 5 },
			{ label: "Direct Debit", value: 6 },
			{ label: "Cheque", value: 7 },
			{ label: "Usage", value: 8 }
		];
		return options;
	}

	public static get invoiceTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			// { label: "Product", value: 1 },
			// { label: "Balance", value: 2 },
			// { label: "Credits", value: 3 },
			{ label: "General", value: 6 }

		];
		return options;
	}

	public static get menuPreset(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Memberships", value: 1 },
			{ label: "Bookings", value: 2 },
			{ label: "Leagues", value: 3 },
			{ label: "Articles", value: 4 },
			{ label: "Events", value: 5 },
			{ label: "Sessions", value: 6 },
			{ label: "Gallery", value: 7 },
			{ label: "Content", value: 8 }
		];
		return options;
	}

	public static get costType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "None", value: 0 },
			{ label: "Person", value: 1 },
			{ label: "Resource", value: 2 },
		];
		return options;
	}

	public static get minTimeAdvanced(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "None", value: 0 },
			{ label: "15 mins", value: 15 },
			{ label: "30 mins", value: 30 },
			{ label: "1 hour", value: 60 },
			{ label: "4 hour", value: 240 },
			{ label: "24 hours", value: 1440 },
			{ label: "48 hours", value: 2880 },
			{ label: "1 week", value: 10080 }
		];
		return options;
	}

	public static get timeFrameOption(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: 6 },
			{ label: "Today", value: 1 },
			{ label: "Next 7 Days", value: 2 },
			{ label: "Next 30 Days", value: 3 },
			{ label: "Last 7 Days", value: 4 },
			{ label: "Last 30 Days", value: 5 },
		];
		return options;
	}

	public static get cancellationTimePolicy(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Cancel Any Time", value: 0 },
			{ label: "No Cancellations", value: 1 },
			{ label: "Up to 1 Hour", value: 2 },
			{ label: "Up to 2 Hours", value: 3 },
			{ label: "Up to 6 Hours", value: 4 },
			{ label: "Up to 24 Hours", value: 5 },
			{ label: "Up to 48 Hours", value: 6 },
			{ label: "Up to 1 Week", value: 7 }
		];
		return options;
	}
	
	public static get confirmationEmails(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Use Default Text", value: 0 },
			{ label: "Add to Default Text", value: 1 },
			{ label: "Replace Default Text", value: 2 }
		];
		return options;
	}

	public static get eventCostType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: 1 },
			{ label: "Members", value: 2 },
			{ label: "Memberships", value: 3 },
		];
		return options;
	}

	public static get eventTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "One Off", value: 1 },
			{ label: "Multi Date", value: 4 }
		];
		return options;
	}

	public static get membershipStatus(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Active", value: 1 },
			{ label: "Paused", value: 2 },
			{ label: "Cancelled", value: 3 },
			{ label: "Overdue", value: 4 },
			{ label: "Pending", value: 5 },
			{ label: "Warning", value: 6 },
			{ label: "Expired", value: 7 }
		];
		return options;
	}

	public static get defaultMenu(): WebsiteMenuPreset[] {
		const options: WebsiteMenuPreset[] = [
			WebsiteMenuPreset.Home,
			WebsiteMenuPreset.Gallery,
			WebsiteMenuPreset.Articles,
			WebsiteMenuPreset.Content,
			WebsiteMenuPreset.Venues
		];
		return options;
	}

	public static get bookingsMenu(): WebsiteMenuPreset[] {
		const options: WebsiteMenuPreset[] = [
			WebsiteMenuPreset.Bookings,
			WebsiteMenuPreset.Events,
			WebsiteMenuPreset.Memberships,
			WebsiteMenuPreset.Sessions
		];
		return options;
	}

	public static get competitionMenu(): WebsiteMenuPreset[] {
		const options: WebsiteMenuPreset[] = [
			WebsiteMenuPreset.Fixtures,
			WebsiteMenuPreset.Leagues,
			WebsiteMenuPreset.Rankings,
			WebsiteMenuPreset.Reserves,
		];
		return options;
	}

	public static get registrationMenu(): WebsiteMenuPreset[] {
		const options: WebsiteMenuPreset[] = [
			WebsiteMenuPreset.Registrations
		];
		return options;
	}
	
	public static get imagePositions(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Left", value: 1 },
			{ label: "Right", value: 2 }
		];
		return options;
	}

	public static get visibility(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Published", value: WebsiteVisibility.Published },
			{ label: "Coming Soon", value: WebsiteVisibility.ComingSoon },
			{ label: "Redirect", value: WebsiteVisibility.Redirect },
			{ label: "Unavailable", value: WebsiteVisibility.Unavailable }
		];
		return options;
	}

	public static get registrationType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Default", value: 1 },
			{ label: "Competition", value: 2 }
		];
		return options;
	}

	public static get registrationCompetitionType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Local", value: 1 },
			{ label: "SBL", value: 2 },
			{ label: "WBL", value: 3 },
			{ label: "JBLB", value: 4 },
			{ label: "JBLG", value: 5 },
			{ label: "VBLM", value: 6 },
			{ label: "VBLW", value: 7 }
		];
		return options;
	}

	public static get registrationSharing(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "None", value: 1 },
			{ label: "Mulitple", value: 2 }
		];
		return options;
	}

	public static get registrationEntrantType(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Default", value: 1 },
			{ label: "Teams", value: 2 },
			{ label: "Players", value: 3 }
		];
		return options;
	}

	public static get entryStatus(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "New", value: 1 },
			{ label: "Complete", value: 2 }
		];
		return options;
	}

	public static get entryFilterSatus(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "All", value: null },
			{ label: "Paid", value: 1 },
			{ label: "Unpaid", value: 2 }
		];
		return options;
	}
            
	public static get memberRoles(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Owner", value: 0 },
			{ label: "Manager", value: 1 }
		];
		return options;
	}

	public static get affiliationTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Registered", value: 4 },
			{ label: "Unregistered", value: 1 },
			{ label: "Associate", value: 2 },
			{ label: "Premier", value: 3 }
		];
		return options;
	}
}

export class Charts {
	public static get labels(): IStringKeyValuePair[] {
		const options: IStringKeyValuePair[] = [
			{ key: "female", value: "Female" },
			{ key: "male", value: "Male" },

			// engagement
			{ key: "e1", value: "0-3" },
			{ key: "e2", value: "4-9" },
			{ key: "e3", value: "10-19" },
			{ key: "e4", value: "20+" },

			// age bands
			{ key: "u13", value: "Under 13" },
			{ key: "u15", value: "Under 15" },
			{ key: "u18", value: "Under 18" },
			{ key: "u20", value: "Under 20" },
			{ key: "u30", value: "Under 30" },
			{ key: "u50", value: "Under 50" },
			{ key: "o50", value: "50+" },

			// memberships
			{ key: "associate", value: "Associate" },
			{ key: "cadet", value: "Cadet" },
			{ key: "senior", value: "Senior" },
			{ key: "other", value: "Other" },

			{ key: "cadetPlayer", value: "Cadet Player" },
			{ key: "cadetCompete", value: "Cadet Compete" },
			{ key: "cadetCompetePlus", value: "Cadet Compete Plus" },

			{ key: "seniorPlayer", value: "Senior Player" },
			{ key: "seniorCompete", value: "Senior Compete" },
			{ key: "seniorCompetePlus", value: "Senior Compete Plus" },

			// retention types
			{ key: "retained", value: "Retained" },
			{ key: "newbie", value: "Newbie" },
			{ key: "comeback", value: "Comeback" },

			// match types
			{ key: "approved", value: "Approved" },
			{ key: "unapproved", value: "Unapproved" },
			{ key: "abandonned", value: "Abandonned" },
			{ key: "forfeit", value: "Forfeit" },
			{ key: "unscheduled", value: "Unscheduled" },

			//message types
			{ key: "recipients", value: "Recipients" },

		];
		return options;
	}
}

export class Display {
	public static get eventSessions(): SelectOption<EventType>[] {
		const options: SelectOption<EventType>[] = [
			{ label: "Dates", value: EventType.OneOff },
			{ label: "Slots", value: EventType.Sessions },
		];
		return options;
	}

	public static get paymentTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "One Off", value: 1 },
			{ label: "Weekly", value: 2 },
			{ label: "Monthly", value: 3 },
			{ label: "Yearly", value: 4 },
			{ label: "Daily", value: 5 }
		];
		return options;
	}

	public static get tenantTypes(): SelectOption<TenantType>[] {
		const options: SelectOption<TenantType>[] = [
			{ label: "League", value: 1 },
			{ label: "Club", value: 2 },
			{ label: "County", value: 3 },
		];
		return options;
	}

	public static get timeOfDay(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Monday", value: 1 },
			{ label: "Tuesday", value: 2 },
			{ label: "Wednesday", value: 3 },
			{ label: "Thursday", value: 4 },
			{ label: "Friday", value: 5 },
			{ label: "Saturday", value: 6 },
			{ label: "Sunday", value: 0 }
		];
		return options;
	}

	public static get invoicePaymentTypes(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Card", value: 1 },
			{ label: "Cash", value: 2 },
			{ label: "Stripe", value: 4 },
			{ label: "BACS", value: 5 },
			{ label: "Direct Debit", value: 6 },
			{ label: "Cheque", value: 7 },
			{ label: "Usage", value: 8 },
			{ label: "Mixed", value: 9 },
		];
		return options;
	}

	public static get invoiceStatus(): SelectOption<number>[] {
		const options: SelectOption<number>[] = [
			{ label: "Paid", value: 4 },
			{ label: "Pending", value: 1 },
			{ label: "Failed", value: 3 },
			{ label: "Void", value: 7 },
			{ label: "Refunded", value: 6 },
			{ label: "Overdue", value: 99 }
		];
		return options;
	}

	public static get numericControlOptions(): SelectOption<NumericControlOptions>[] {
		const options: SelectOption<NumericControlOptions>[] = [
			{ label: "Equals to", value: NumericControlOptions.Equals },
			{ label: "Different from", value: NumericControlOptions.Different },
			{ label: "Greater than", value: NumericControlOptions.Greater },
			{ label: "Smaller than", value: NumericControlOptions.Smaller }
		];
		return options;
	}
}