import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import History from "@helpers/history.helper";

import { Row, Col } from "reactstrap";

import { loadStripe } from "@stripe/stripe-js";
import { StripeService } from "@services/stripe.service";
import PublicLayout from "@components/layouts/PublicLayout";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { MembershipService } from "@services/membership.service";
import { LoginService } from "@services/login.service";
import { Invoice, InvoiceType } from "@models/invoice";
import { InvoiceService } from "@services/invoice.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";

interface IEntryConfirmationPageProps {
	site?: string;
	match?: any;
}

interface IEntryConfirmationPageState {
	loading: boolean;
	nextPathname?: any;
	invoice?: Invoice;
}

export class EntryConfirmationPage extends React.Component<IEntryConfirmationPageProps, IEntryConfirmationPageState> {

	private reference?: string;
	private entryId?: string;

	constructor(props) {
		super(props);

		this.reference = (this.props.match.params as any).reference as string;
		this.entryId = (this.props.match.params as any).entryId as string;
		this.state = { loading: true };
	}

	async componentDidMount() {
		const invoice = await InvoiceService.getByReference(this.reference);
		this.setState({ invoice, loading: false });
	}

	public render() {
		return (
			<PublicLayout className="sub-page">
				<Helmet />
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							<div className="header_content_wrap mt-5">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<h1 className="sc_layouts_title_caption">
												Entry Confirmation
											</h1>
											<p className="header--description">
												Thanks you for your payment
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<Row>
											<Col md={{ size: 6, offset: 3 }}>
												<Link to={`/member/entries/${this.entryId}`} className="btn btn-primary btn-rounded w-100">
													Back to Entry Overview
												</Link>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>);
	}
}
