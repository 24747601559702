import React from "react";

import { Can } from "@components/controls/Authentication";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { LoginService } from "../../services/login.service";

import { AppConfig, ReleaseType } from "@config";
import { Website, WebsiteImageType } from "@models/website";

import { ToastContainer, ToastOptions, ToastPosition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "../controls/ScrollToTop";

import "popper.js";
import moment from "moment"; 
import History from "@helpers/history.helper";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { AppCache } from "@cache";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TenantType } from "@models/tenant";

interface IEmbedLayoutProps {
	//
}

interface IEmbedLayoutState {
	//
	loading: boolean;
}

export class EmbedLayout extends React.Component<IEmbedLayoutProps, IEmbedLayoutState> {

	constructor(props) {
		super(props);

		this.state = { loading: false };
	}

	static defaultProps = {
		showNav: true,
		overlayFooter: false,
		showLogin: true,
		hideScroll: false,
		showFooter: true,
		className: "",
		theme: "scheme_default"
	};

	componentDidMount() {
		//
	}

	componentDidUpdate(nextProps, nextState) {
		//
	}

	logout() {
		LoginService.logout();
	}

	public render() {

		const Close = () => <a className="close-button"><i className="la la-times"></i></a>;

		return (
			<div className={`scheme_default`}>
				<div>
					<Helmet titleTemplate={`%s | TT Leagues`}>
						<title>Home</title>
						<meta name="description" content={`The official website for TT Leagues`} />
						<link rel="canonical" href={`${window.location.href}`} />
					</Helmet>
					<ScrollToTop />
					<ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
					<header />
					<div className="body_wrap">
						<div className="page_wrap">
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
