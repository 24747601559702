import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import * as store from "store2";

import History from "@helpers/history.helper";
import { LoginMode } from "@models/tenant";
import { AppCache } from "@cache";

import RestHelper from "@helpers/rest.helper";
import { Constants } from "@consts";

export class LoginService {

	public static _storageKey: string = "access_token";

	constructor() {
		//
	}

	public static get isAuthenticated(): boolean {
		if (this.getDetails() != null && this.getDetails() !== undefined) {
			// more checks required
			return true;
		}

		return false;
	}

	public static allow(required: string[]): boolean {
		const details = LoginService.getDetails();
		const rules = details.rules;

		if (AppCache.tenant == null) {
			return false;
		}

		if (required.length === 0) {
			return true;
		}

		for (let index = 0; index < required.length; index++) {
			const element = required[index];
			let rule;

			if (element.indexOf("??") >= 0) {
				rule = element.replace("??", AppCache.tenant.organisationId.toString());
			} else {
				rule = element.replace("?", AppCache.tenant.code);
			}

			if (rules.indexOf(rule) >= 0) {
				return true;
			}
		}

		return false;
	}

	public static login(email: string, password: string, mode: LoginMode): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
				},
				url: AppConfig.apiUrl + "login",
				method: "POST",
				data: JSON.stringify({ email, password, mode }),
				contentType: "application/json",
				success(data: TokenApiModel, status) {
					LoginService.store(data, true);
					resolve(data);
				},
				error(xhr, status, errorThrown) {
					reject(xhr.responseJSON);
				},
				complete(xhr) {
					resolve();
				}
			});
		});
	}

	public static refresh(refreshToken?: string): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			const details = LoginService.getDetails();

			if (details == null && !refreshToken) {
				resolve(); // resolve without saving the token
			} else {
				const token = refreshToken ?? details.refreshToken;

				$.ajax({
					beforeSend: (request) => {
						request.setRequestHeader("Tenant", window.location.hostname);
					},
					url: AppConfig.apiUrl + "auth/refresh",
					method: "POST",
					data: JSON.stringify({ token }),
					contentType: "application/json",
					success(data: TokenApiModel, status) {
						LoginService.store(data, true);
						resolve(data);
					},
					error(xhr, status, errorThrown) {
						LoginService.check(xhr);

						if (xhr.status === 400) {
							LoginService.logout();
						}

						reject(xhr.responseJSON);
					},
					complete(xhr) {
						resolve();
					}
				});
			}
		});
	}

	public static startTimer() {
		setInterval(() => this.refresh(), 300000);
	}

	public static getTokenHeader(): string {
		if (this.getDetails() != null) {
			return "Bearer " + this.getDetails().accessToken;
		}

		return "";
	}

	public static clear() {
		store.remove(LoginService._storageKey);
	}

	public static async logout() {
		store.remove(LoginService._storageKey);
		if (AppCache.tenant.clubId) {
			const logout = await RestHelper.post("saml/logout", {}, { baseURL: AppConfig.baseUrl });
			if (logout) {
				window.location.href = logout.data;
			}
		} else {
			History.push("/");
		}
	}

	public static store(data: TokenApiModel, persist: boolean) {
		RestHelper.defaults.headers.common[Constants.authHeaderKey] = `Bearer ${data.accessToken}`;
		store.set(LoginService._storageKey, data);
	}

	public static getDetails(): TokenApiModel {
		return store.get(LoginService._storageKey);
	}

	public static getDownloadToken(): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`auth/download`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static check(xhr) {
		if (xhr.status === 401) {
			if (LoginService.isAuthenticated) {
				LoginService.logout();
			}
			History.push("/login", { prevPath: location.pathname });
		}
		if (xhr.status === 403) {
			History.push("/forbidden", { prevPath: location.pathname });
		}
	}

	public static validate(token: string): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post<TokenApiModel>("validate", { token }, {
					headers: { Authorization: `Bearer ${token}` }
				})
				.then(result => {
					LoginService.store(result.data, true);
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}

	public static switch(userId: string): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post<TokenApiModel>(`auth/${userId}/switch`, {})
				.then(result => {
					LoginService.store(result.data, true);
					resolve(result.data);
				}).catch(error => {

					if (error.status === 400) {
						LoginService.logout();
					}

					reject(error);
				});
		});
	}

	public static forgotPassword(email: string, sms: boolean): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post<TokenApiModel>("auth/forgotpassword", { email, sms })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}

	public static confirmCode(email: string, code: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post<boolean>("auth/confirmcode", { email, code })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}

	public static changePassword(email: string, code: string, password: string): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post<TokenApiModel>("auth/changepassword", { email, code, password })
				.then(result => {
					LoginService.store(result.data, true);
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}
}
