import { playingPositions } from "@constants/rankings_constants";

export class PlayingPositionHelper {

	private static get PP () { return playingPositions; }

	public static convertToDBPlayingPos(pos:string | string):number {
		if (pos === undefined || (typeof(pos) === "string" && pos.length === 0))
			return undefined;

		if (typeof(pos) === "number") {
			return pos;
		}

		const posAsNum = parseInt(pos, 10);
		if (!isNaN(posAsNum)) {
			return posAsNum;
		}

		if (pos.length > 1)
			throw "Position must only contain a single letter";

		const _pos = pos?.toUpperCase();
		const home = this.PP.substring(0, (this.PP.length / 2)).split("");
		const away = this.PP.substring((this.PP.length / 2) , this.PP.length).split("").reverse();

		if (home.some(x => x === _pos))
		{
			return home.findIndex(x => x === _pos) + 1;
		}
		else if (away.some(x => x === _pos))
		{
			return away.findIndex(x => x === _pos) + 1;
		}

		throw "Something went wrong";
	}

	public static getPlayingPosIdentifiers(position: number):string[] {
		if (position === undefined || position === null)
			return ["", ""];

		const _halfLength = playingPositions.length / 2;
		const _pos = position < 1 ? 1 : (position > (_halfLength) ? _halfLength : position);
		return [
			playingPositions.substring(_pos - 1, _pos),
			playingPositions.substring(playingPositions.length - _pos, (playingPositions.length - _pos) + 1)
		];
	}

	public static getPlayingPosIdentifiersV2(position: number, short:boolean = false):string {
		if (position === undefined || position === null)
			return "";

		/*
		const _halfLength = playingPositions.length / 2;
		const _pos = position < 1 ? 1 : (position > (_halfLength) ? _halfLength : position);
		return [
			playingPositions.substring(_pos - 1, _pos),
			playingPositions.substring(playingPositions.length - _pos, (playingPositions.length - _pos) + 1)
		];*/
		return (short ? "P" : "Pos ") + position.toString();
	}
}