import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Division, CreateDivision, splitDivision, DivisionOrdering } from "@models/division";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Standing, StandingGroup } from "@models/standing";
import { Entrant, CreateEntrant } from "@models/entrant";
import { Adjustment, CreateAdjustment } from "@models/adjustment";
import { AverageGroup, Average, DoublesAverage } from "@models/average";
import { Reserve } from "@models/reserve";
import { MatchFilters } from "@models/match";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { POTM } from "@models/potm";
import { DivisionDayTables, DivisionTable } from "@models/table";

export class DivisionService {

	constructor() {
		//
	}

	public static get(competitionId: number): Promise<Division[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/divisions`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Division[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getInGroup(competitionId: number): Promise<Division[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`competitions/${competitionId}/divisions/group`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getById(divisionId: number): Promise<Division> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`divisions/${divisionId}`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static create(division: CreateDivision): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(division),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(division: Division): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(division),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateOrder(divisions: DivisionOrdering[]): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/updateOrder`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(divisions),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateTables(divisionId: number, tables: DivisionDayTables[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/update/${divisionId}/tables`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(tables),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(divisionId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static standings(divisionId: number): Promise<Standing[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/standings`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Standing[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getAverages(divisionId: number): Promise<Average[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/averages`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Average[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getAveragesAll(divisionId: number): Promise<Average[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/averages/all`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Average[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getDoublesAverages(divisionId: number): Promise<DoublesAverage[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/doublesaverages`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:DoublesAverage[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getDoublesAveragesAll(divisionId: number): Promise<DoublesAverage[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/doublesaverages/all`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:DoublesAverage[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getPOTMs(divisionId: number): Promise<POTM[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/potms`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:POTM[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getPOTMsAll(divisionId: number): Promise<POTM[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/potms/all`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:POTM[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static assign(entrant: CreateEntrant): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/assign`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(entrant),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static unassign(entrantId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/unassign`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({entrantId}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static unassignAll(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`divisions/${id}/unassign/all`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static order(entrants: Entrant[]): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/order`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(entrants),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static entrants(divisionId: number): Promise<Entrant[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/entrants`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Entrant[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static winningEntrants(divisionId: number, matchId: number): Promise<Entrant[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/entrants/${matchId}/winnners`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Entrant[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static adjustments(divisionId: number): Promise<Adjustment[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/adjustments`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Adjustment[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static entrantsAdjustments(divisionId: number, entrantId): Promise<Adjustment[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/adjustments/${entrantId}/entrant`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Adjustment[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static adjustPoints(adjustment: CreateAdjustment): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/adjustments`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(adjustment),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static resetPoints(adjustmentId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/adjustment/${adjustmentId}/remove`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getGroupStandings(): Promise<StandingGroup[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/standings/published`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:StandingGroup[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getGroupAverages(): Promise<AverageGroup[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/averages/published`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:AverageGroup[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static splitDivision(data: splitDivision): Promise<Division[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`divisions/split`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static OrderStanding(data: Standing[]): Promise<boolean[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`divisions/standings/order`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static ResetStanding(divisionId: number): Promise<boolean[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`divisions/${divisionId}/standings/reset`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}
}
