import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import { LoginService } from "@services/login.service";

import PublicLayout from "@components/layouts/PublicLayout";

import * as moment from "moment";
import * as NProgress from "nprogress";
import { ProgressButton } from "@components/controls/ProgressButton";

import { ToastContainer, ToastOptions, ToastPosition, toast } from "react-toastify";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import history from "@helpers/history.helper";
import { AppCache } from "@cache";
import { LoginMode, Tenant } from "@models/tenant";
import { LeagueService } from "@services/league.service";
import { TenantService } from "@services/tenant.service";
import { League } from "@models/league";
import { EmptyLayout } from "@components/layouts/EmptyLayout";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { PagedList } from "@models/paging";

interface IWelcomePageProps {
	site?: string;
}

interface IWelcomePageState {
	nextPathname?: any;
	loading: boolean;
	tenants?: PagedList<Tenant>;
	clubs: Tenant[];
	leagues: Tenant[];
	counties: Tenant[];
}
export class WelcomePage extends React.Component<IWelcomePageProps, IWelcomePageState> {

	private tenantId: number;

	constructor(props) {
		super(props);

		this.state = { loading: true, leagues: [], clubs: [], counties: [] };
	}

	componentDidMount() {
		//
		TenantService.get({}).then(tenants => {
			const leagues = tenants.items.filter(t => t.leagueId);
			const clubs = tenants.items.filter(t => t.clubId);
			const counties = tenants.items.filter(t => t.countyId);

			this.setState({ loading: false, tenants, leagues, clubs, counties });
		});
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	public render() {
		return (
			<EmptyLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (<div>
						<div className="elementor elementor-6">
							<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
								<div className="elementor-container elementor-column-gap-extended">
									<div className="elementor-row">
										<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
											<div className="elementor-column-wrap  elementor-element-populated">
												<div className="elementor-widget-wrap">
													<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
														<div className="elementor-widget-container">
															<div className="elementor-spacer">
																<div className="elementor-spacer-inner"></div>
															</div>
														</div>
													</div>
													<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
														<div className="elementor-widget-container">
															<div className="elementor-spacer">
																<div className="elementor-spacer-inner"></div>
															</div>
														</div>
													</div>
													<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
														<div className="elementor-widget-container">
															<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																<h1 className="sc_layouts_title_caption">Sites</h1>
																<p className="header--description">Find your league, club or county</p>
															</div>
															</div>
															</div>
														</div>
													</div>
													<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
														<div className="elementor-widget-container">
															<div className="elementor-spacer">
																<div className="elementor-spacer-inner"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
						<div className="page_content_wrap">
							<div className="container">
								<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Leagues</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Clubs</a>
									</li>
									<li className="nav-item">
										<a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Counties</a>
									</li>
								</ul>
								<div className="clear"></div>
								<div className="tab-content" id="pills-tabContent">
									<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
										<div className="row">
											<div className="col-lg col-md-12 mb-4">
												<div className="content">
													<section className="section">
														<h5>Select from the leagues below</h5>
														<div className="row mg-b-20">
															<div className="col-12">
																<div className="row">
																	{
																		this.state.leagues.map(l => {
																			return (
																				<div key={`t enant_${l.id}`} className="col-12 col-md-6">
																					<a href={`https://${l.host}/`}>{l.name}</a>
																				</div>);
																		})
																	}
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
									<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
										<div className="row">
											<div className="col-lg col-md-12 mb-4">
												<div className="content">
													<section className="section">
														<h5>Select from the clubs below</h5>
														<div className="row mg-b-20">
															<div className="col-12">
																<div className="row">
																	{
																		this.state.clubs.map(l => {
																			return (
																				<div key={`t enant_${l.id}`} className="col-12 col-md-6">
																					<a href={`https://${l.host}/`}>{l.name}</a>
																				</div>);
																		})
																	}
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
									<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
										<div className="row">
											<div className="col-lg col-md-12 mb-4">
												<div className="content">
													<section className="section">
														<h5>Select from the counties below</h5>
														<div className="row mg-b-20">
															<div className="col-12">
																<div className="row">
																	{
																		this.state.counties.map(l => {
																			return (
																				<div key={`t enant_${l.id}`} className="col-12 col-md-6">
																					<a href={`https://${l.host}/`}>{l.name}</a>
																				</div>);
																		})
																	}
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>)
					} />
			</EmptyLayout>);
	}
}
