import { ProgressButton } from "@components/controls/ProgressButton";
import PublicLayout from "@components/layouts/PublicLayout";
import { Basket, BasketItem } from "@models/basket";
import { BasketService } from "@services/basket.service";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState, useEffect, FC, useContext } from "react";
import { toast } from "react-toastify";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import History from "@helpers/history.helper";
import { loadStripe } from "@stripe/stripe-js";
import { AppConfig } from "@config";
import { AppCache } from "@cache";
import { InvoiceStatus } from "@models/invoice";
import { InvoiceService } from "@services/invoice.service";
import Countdown from "react-countdown";
import LocaleHelper from "@helpers/locale.helper";
import { format, parseISO } from "date-fns";
import { WebsiteContext } from "@context/BasketContext";
import { CountdownTimeDelta } from "react-countdown/dist/utils";

const StripeContainer = (props) => {
	const [stripePromise] = useState(() => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId }));

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm onSubmit={props.onSubmit} onComplete={props.onComplete} onError={props.onError} submitting={props.submitting} />
		</Elements>
	);
};

interface ICheckoutFormProps {
	onSubmit?: () => void;
	onComplete?: () => void;
	onError?: () => void;
	submitting: boolean;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const stripe = useStripe();
	const elements = useElements();

	const { setBasket } = useContext(WebsiteContext);

	const handleSubmit = () => async (event: any) => {
		event.preventDefault();

		try {
			validate();

			props.onSubmit();

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement)
			});

			if (paymentMethod) {
				try {
					const basket = await BasketService.get();
					basket.paymentMethodId = paymentMethod.id;

					const invoice = await InvoiceService.payBasket(basket);

					if ((invoice?.status ?? InvoiceStatus.Paid) === InvoiceStatus.Failed) {
						await toast.error("Payment failed. Please retry");
						History.push(`/pay/${invoice.reference}`);

					} else {
						BasketService.clear(false);
						setBasket(null);

						toast.success("Successfully Booked");
						History.push(`/pay/confirmation/${invoice.reference}`);
					}
					props.onComplete();

				} catch (error) {
					toast.error(error.message);
					props.onError();
				}

				props.onComplete();
			} else {
				toast.error("Payment details missing");
				props.onError();
			}
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const validate = () => {
		return true;
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<div className="stripe">
			<div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>
			<ProgressButton onClick={handleSubmit()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
				Confirm and Pay
			</ProgressButton>
		</div>
	);
};

interface IHomePageProps {
	data: any;
	mainMenu: any;
	secondaryMenu: any;
	footerMenu: any;
}

export default (props: IHomePageProps) => {
	const { basket, setBasket } = useContext(WebsiteContext);

	const [loading, setLoading] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const basket = await BasketService.get();
		setBasket(basket);
	}

	const handleClearClick = async () => {
		BasketService.clear(true);
		await load();
	}

	const handleCountdownComplete = (item: BasketItem) => async (timeDelta: CountdownTimeDelta, completedOnStart: boolean) => {
		const _basket = await BasketService.remove(basket, item);
		setBasket({..._basket});
	}

	const handleRemoveClick = (item: BasketItem) => async () => {
		await BasketService.remove(basket, item);
		await load();
	}

	return (
		<div>
			<Container>
				<h1 className="text-primary mt-5">Basket</h1>
				<p className="header--description"></p>
			</Container>
			<div className="page_content_wrap">
				<div className="container">
					<div className="basket mg-t-20">
						{
							basket?.items.length > 0 &&
							<Row>
								<Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
									<Card>
										<CardBody className="px-3 py-0">
											{
												basket && basket.items.map(i => (
													<Row className="item">
														<Col>
															<i className="far fa-clock mr-2" />
															<Countdown
																onComplete={handleCountdownComplete(i)}
																date={i.expires}
																renderer={props => <span>{props.minutes}:{props.seconds}</span>}
															/>
															<p className="mb-0">{format(parseISO(i.date.toString()), "do MMMM yyyy")}</p>
															<p className="mb-0 text-primary">{i.name}</p>
														</Col>
														<Col className="d-flex align-items-center justify-content-end">
															<a onClick={handleRemoveClick(i)} className="mr-3 clickable"><i className="far fa-times" /></a>
															<span className="price">{i.total && LocaleHelper.toPrice(i.total)}</span>
														</Col>
													</Row>
												))
											}
										</CardBody>
									</Card>
									<Row className=" py-4 px-5">
										<Col className="my-auto text-left">
											<span>Total</span>
										</Col>
										<Col className="my-auto text-right">
											{basket.total > 0 && <h4 className="price m-0">{LocaleHelper.toPrice(basket.total)}</h4>}
										</Col>
									</Row>
									<StripeContainer
										onSubmit={e => setSubmitting(true)}
										onComplete={e => { }}
										onError={e => setSubmitting(false)}
										submitting={submitting}
									/>
									<Row className="mt-3">
										<Col className="text-center">
											<a onClick={handleClearClick} className="btn btn-primary btn-rounded btn-outline bd-0">Clear Basket</a>
										</Col>
									</Row>
								</Col>
							</Row>
						}
						{
							basket?.items.length === 0 &&
							<p>Basket Empty</p>
						}
					</div>
				</div>
			</div>
		</div>
	);
}