interface IListeners {
	[eventName: string]: ((...args: any) => void)[];
}

export class EventDispatcher {
	_events: IListeners;

	constructor() {
		this._events = {};
	}

	/**
	 * Create an listener for the event name that fires the callback when dispatched.
	 *
	 * @param {string} eventName
	 * @param {(...args:any) => void} callback
	 * @returns
	 * @memberof EventDispatcher
	 */
	public on(eventName: string, callback: (...args: any) => void) {
		if (!eventName || !callback)
			return;

		// Check if the callback is not a function
		if (typeof callback !== 'function') {
			console.error(`The listener callback must be a function, the given type is ${typeof callback}`);
			return;
		}

		// Check if the event is not a string
		if (typeof eventName !== 'string') {
			console.error(`The event name must be a string, the given type is ${typeof eventName}`);
			return;
		}

		eventName = eventName.toLowerCase();

		// Check if this event not exists
		if (this._events[eventName] === undefined) {
			this._events[eventName] = [];
		}

		this._events[eventName].push(callback);
	}

	/**
	 * Remove the listener in question where the callback meets the relevant criteria, OR all listeners if no callback is supplied
	 *
	 * @param {string} eventName
	 * @param {(...args:any) => void} callback
	 * @returns
	 * @memberof EventDispatcher
	 */
	public off(eventName: string, callback?: (...args: any) => void) {
		if(!eventName)
			return;

		eventName = eventName.toLowerCase();

		// Check if this event not exists
		if (this._events[eventName] === undefined) {
			console.error(`This event: ${eventName} does not exist`);
			return false;
		}

		const handlers = this._events[eventName];
		if (!handlers)
			return;

		if (!callback) {
			delete this._events[eventName];
		} else {
			const listenerIndex = this._events[eventName].findIndex(x => x.toString() === callback.toString());
			if (listenerIndex !== -1) {
				handlers.splice(listenerIndex, 1);
				if (handlers.length === 0) {
					delete this._events[eventName];
				}
			}
		}
	}

	/**
	 * Dispatch event to all listeners based on the event name, and pass parameters as needed.
	 *
	 * @param {string} eventName
	 * @param {*} details
	 * @returns
	 * @memberof EventDispatcher
	 */
	public dispatch(eventName: string, ...details:any) {
		if (!eventName)
			return;

		eventName = eventName.toLowerCase();

		// Check if this event not exists
		if (this._events[eventName] === undefined) {
			// console.error(`This event: ${eventName} does not exist`);
			return;
		}

		this._events[eventName].forEach((listener) => {
			listener(...details);
		});
	}
}

export default EventDispatcher;