import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link, match } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import classnames from "classnames";

import { AppConfig, EntryType } from "@config";

import history from "@helpers/history.helper";
import * as moment from "moment";
import * as NProgress from "nprogress";

import { ManageLayout } from "@components/layouts/ManageLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { Toggle } from "@components/controls/Toggle";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import { ProgressButton, CircularProgressButton } from "@components/controls/ProgressButton";

import History from "@helpers/history.helper";

import { Member } from "@models/member";
import { Match, MatchGroup, MatchGrouping, MatchCard, MatchSet, MatchPlayer, MatchDoubles, MatchGame, MatchStatus, PotmType, MatchCardEntryLevel, MatchReport, UpdateMatch, CurrentMatchStatus, MatchGameExpedite } from "@models/match";
import { Standing } from "@models/standing";

import { DatatableQuery, DatatableMeta } from "@models/query";
import { MemberService } from "@services/member.service";

import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { LeagueService } from "@services/league.service";
import { CompetitionService } from "@services/competition.service";
import { MatchService } from "@services/match.service";
import { VenueService } from "@services/venue.service";
import { TeamService } from "@services/team.service";
import { Competition, CreateCompetition, PlayerLabels, ResultDisplay } from "@models/competition";

import Sidebar from "react-sidebar";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { SelectOption } from "@models/forms";
import { Venue } from "@models/venue";

import { confirmAlert } from "react-confirm-alert"; // Import
import { DivisionService } from "@services/division.service";
import { CreateDivision, Division } from "@models/division";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { TeamMember } from "@models/team";
import { number, elementType } from "prop-types";
import { MatchEntrant, MatchMember } from "@models/match";

import * as Queue from "promise-queue";
import ObjectHelper from "@helpers/object.helper";
import { Can } from "@components/controls/Authentication";
import { Options } from "@data";
import { TenantType } from "@models/tenant";
import { VisualEditor } from "@components/controls/VisualEditor";
import { MiscHelper } from "@helpers/misc.helper";

import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { AppCache } from "@cache";
import { DateHelper } from "@helpers/date.helper";

import * as signalR from "@microsoft/signalr";
import * as QueryString from "query-string";
import { message } from "gulp-typescript/release/utils";
import { ScoringHubService } from "@services/hubs/scoringhub.service";
import { SignalRConnectionStatus } from "@models/enums/signalrconnectionstatus";
import { data } from "jquery";
import { IDispatcher } from "@interfaces/scoring/idispatcher";
import { dictionary } from "@components/dataTypes/dictionary";
import { error } from "console";

interface IMatchCardPageProps {
	site?: string;
	match?: any;
	mode?: number;
}

interface IMatchCardPageState {
	loading: boolean;
	updating: boolean;
	nextPathname?: any;
	competition?: Competition;
	division?: Division;
	matchCard?: MatchCard;
	activeTab: string;
	set?: MatchSet;
	submitting: boolean;
	forfeitModal: boolean;
	forfeitReason?: string;
	forfeitPlayer?: number;
	forfeitEntrant?: number;
	abandonedModal: boolean;
	abandonedReason?: string;
	scratchReason?: string;
	scratchType?: number;
	scratchEntrant?: number;
	potmModal: boolean;
	matchReportModal: boolean;
	matchEditModal: boolean;
	potmId?: string;
	potmType?: PotmType;
	report?: MatchReport;

	updateMatch?: UpdateMatch;

	editHandicaps?: boolean;

	liveGame?: MatchGame;
	liveSet?: MatchSet;
	matchGameModal: boolean;
	liveEntry: boolean;
	liveEntryModal: boolean;
	hubConnection?: signalR.HubConnection;
	groupNames?: dictionary<string,number>[];
	channelId?: string;
	connecting: boolean;
	groupName?: string;
	matchStatus?: CurrentMatchStatus;
	expediteMatch: boolean;
	expediteDetails?: MatchGameExpedite;
	ignoreServer: boolean;
}

export class MatchCardBase extends React.Component<IMatchCardPageProps, IMatchCardPageState> {
	public name: string;
	public matchId: number;
	public matchCard: MatchCard;
	public queue: Queue;
	public potmId: SelectOption<string>;
	public potmType: SelectOption<PotmType>;
	public homeHandicap?: number;
	public awayHandicap?: number;
	public currentServer?: SelectOption<string>;

	interval: NodeJS.Timer;

	public get homePlayers(): MatchPlayer[] {
		return this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3);
	}

	public get awayPlayers(): MatchPlayer[] {
		return this.state.matchCard.playing.away.filter(m => m.type == 1 || m.type == 3);
	}

	public get homeDoublePlayers(): any[] {
		return this.groupBy(this.state.matchCard.playing.home.filter(m => m.type == 2), (g) => g.ordering);
	}

	public get awayDoublePlayers(): any[] {
		return this.groupBy(this.state.matchCard.playing.away.filter(m => m.type == 2), (g) => g.ordering);
	}

	public get homePlayerOptions(): any[] {
		const homePlayerOptions = [
			{
				label: "Team Members",
				options: [] = this.state.matchCard.match.home.members.filter(m => !m.transferId && ((this.state.division.singlesAny ?? this.state.competition.singlesAny) || !m.playing || this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined) && this.state.matchCard.playing.away.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined).map(m => ({ user: m, label: `${m.name}`, value: m.memberId }))
			},
			{
				label: (this.state.division?.reserves?.type ?? this.state.competition?.reserves?.type) == 6 ? "Pool" : "Reserves",
				options: [] = this.state.matchCard.match.home.reserves.filter(m => (!m.playing || this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined) && this.state.matchCard.playing.away.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId === m.memberId) === undefined).map(m => ({ user: m, reserve: true, label: `${m.team} - ${m.name}`, value: m.memberId, disabled: m.disabled }))
			},
			{
				label: "Other",
				options: [] = []
			}
		];

		return homePlayerOptions;
	}

	public get awayPlayerOptions(): any[] {
		const awayPlayerOptions = [
			{
				label: "Team Members",
				options: [] = this.state.matchCard.match.away.members.filter(m => !m.transferId && ((this.state.division.singlesAny ?? this.state.competition.singlesAny) || !m.playing || this.state.matchCard.playing.away.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined) && this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined).map(m => ({ user: m, label: `${m.name}`, value: m.memberId }))
			},
			{
				label: (this.state.division?.reserves?.type ?? this.state.competition?.reserves?.type) == 6 ? "Pool" : "Reserves",
				options: [] = this.state.matchCard.match.away.reserves.filter(m => (!m.playing || this.state.matchCard.playing.away.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined) && this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3).find(mp => mp.userId == m.memberId) == undefined).map(m => ({ user: m, reserve: true, label: `${m.team} - ${m.name}`, value: m.memberId, disabled: m.disabled }))
			},
			{
				label: "Other",
				options: [] = []
			}
		];

		return awayPlayerOptions;
	}

	private signalREvents: IDispatcher[];

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			activeTab: "1",
			submitting: false,
			forfeitModal: false,
			abandonedModal: false,
			updating: false,
			potmModal: false,
			matchReportModal: false,
			matchEditModal: false,
			matchGameModal: false,
			liveEntry: false,
			liveEntryModal: false,
			expediteMatch: false,
			connecting: false,
			ignoreServer: false,
		};

		this.queue = new Queue(1);
	}

	componentDidMount() {
		this.load();

		var channelId = sessionStorage.getItem("channelId");

		if (channelId != null){
			ScoringHubService.instance.Start(channelId).then(() => {
				this.signalREvents = [
					ScoringHubService.instance.subscribeSignalR("checkConnectionsRecieved", () => {
						ScoringHubService.instance.ConfirmConnection();
					}),
					ScoringHubService.instance.subscribeSignalR("statusRequestReceived", () => {
						ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);
					}),
					ScoringHubService.instance.subscribeSignalR("gameRequestReceived", () => {
						if(this.state.liveGame != null){
							ScoringHubService.instance.sendGameData(this.state.liveGame);
						}
					}),
				];
				//ScoringHubService.instance.CheckConnections();
				var matchStatus = new CurrentMatchStatus();
				matchStatus.gameEnd = false;
				matchStatus.matchEnd = false;
				matchStatus.started = false;
				this.setState({matchStatus: matchStatus});
			});
		};
		this.setState({channelId: channelId, liveEntry: channelId != null})
	}

	componentDidUpdate(props: any, state: any) {
		//
		var channelId = sessionStorage.getItem("channelId");
		if (channelId != this.state.channelId) {
			ScoringHubService.instance.Stop()
			.then(() => {
				if (channelId != null){
					ScoringHubService.instance.Start(channelId).then(() => {
						this.signalREvents = [
							ScoringHubService.instance.subscribeSignalR("checkConnectionsRecieved", () => {
								ScoringHubService.instance.ConfirmConnection();
							}),
							ScoringHubService.instance.subscribeSignalR("statusRequestReceived", () => {
								ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);
							}),
							ScoringHubService.instance.subscribeSignalR("gameRequestReceived", () => {
								if(this.state.liveGame != null){
									ScoringHubService.instance.sendGameData(this.state.liveGame);
								}
							}),
						];
						if (this.state.matchStatus == null){
							var matchStatus = new CurrentMatchStatus();
							matchStatus.gameEnd = false;
							matchStatus.matchEnd = false;
							matchStatus.started = false;
							this.setState({matchStatus: matchStatus});
						}
						//ScoringHubService.instance.CheckConnections();
					});
					
				}
				else if (this.state.liveEntryModal){
					this.liveEntryModal(false);
				}
				
				this.setState({channelId: channelId, liveEntry: channelId != null, connecting: false})
			});
		}
	}
	componentWillUnmount(): void {
		this.unload();
		var status = this.state.matchStatus;
		if(status != null){
			status.gameEnd = false;
			status.matchEnd = true;
			status.started = false;
			status.gameId = null; 
			ScoringHubService.instance.sendMatchStatus(status);
		}
		ScoringHubService.instance.Stop();
	}

	handleChangeName(e: any) {
		this.name = e.currentTarget.value;
		this.setState({ division: { ...this.state.division, name: this.name } });
	}

	cancel() {
		this.setState({ set: null, editHandicaps: null });
	}

	playNow(matchId, competitionId, divisionId){
		MatchService.play(this.matchId).then(result => {
			window.location.href = window.location.origin + `/league/${competitionId}/division/${divisionId}/match/${matchId}/card`
		});
	}

	load() {

		NProgress.start();
		this.matchId = this.props.match.params.matchId as number;
		const divisionId = this.props.match.params.divisionId as number;
		const competitionId = this.props.match.params.competitionId as number;
		var entry = MatchCardEntryLevel.None; 
		const p1 = DivisionService.getById(divisionId).then(division => {
			this.setState({ division }, () => {

			});
		});

		const p2 = CompetitionService.getById(competitionId).then(competition => {
			this.setState({ competition }, () => {

			});
		});

		const p3 = MatchService.matchCard(this.matchId).then(matchCard => {

			const updateMatch = {
				id: matchCard.match.id,
				date: matchCard.match.date,
				time: matchCard.match.time
			}

			this.setState({ matchCard, updateMatch }, () => {
				this.matchCard = matchCard;

				this.homeHandicap = this.matchCard.match.homeHandicap;
				this.awayHandicap = this.matchCard.match.awayHandicap;
			});
			entry = matchCard.match.entry;
		});

		this.loadReport();

		Promise.all([p1, p2, p3]).then(result => {
			if(this.props.mode == 2 && entry >= MatchCardEntryLevel.Approve){
				this.playNow(this.matchId, competitionId, divisionId);
			}
			else{
				if(this.props.mode == 1 && entry >= MatchCardEntryLevel.Approve){
					this.setState({matchEditModal: true});
				}

				this.setState({ loading: false }, () => {
					if (!this.state.matchCard.match.home.score && !this.state.matchCard.match.away.score) {
						if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.PointsAwarded && this.state.matchCard.match.submitted && !this.state.matchCard.match.approved) {
							this.matchCard.match.home.score = this.matchCard.match.home.points;
							this.matchCard.match.away.score = this.matchCard.match.away.points;
						}
						else if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Points){
							this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.home.score += g.home));
							this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.away.score += g.away));		
						}
						else {
							this.matchCard.sets.map(s => this.matchCard.match.home.score += (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.homeScore : (s.homeScore > s.awayScore && s.completed ? 1 : 0));
							this.matchCard.sets.map(s => this.matchCard.match.away.score += (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.awayScore :  (s.awayScore > s.homeScore && s.completed ? 1 : 0));		
						}
						if(this.matchCard.match.homeHandicap != null && this.matchCard.match.awayHandicap != null) {
							if(this.matchCard.match.homeHandicap > this.matchCard.match.awayHandicap){
								this.matchCard.match.home.score += ((this.matchCard.match.homeHandicap - this.matchCard.match.awayHandicap));
							}
							else{
								this.matchCard.match.away.score += ((this.matchCard.match.awayHandicap - this.matchCard.match.homeHandicap));
							}
						}

						this.setState({ matchCard: this.matchCard });
					}
					NProgress.done();
				});
			}
		});
	}

	loadReport(){
		MatchService.getReport(this.matchId).then(report => {
			if (!report) {
				report = new MatchReport();
				report.matchId = this.matchId;
				report.updated = moment().toDate();
			}

			this.setState({ report }, () => {

			});
		});
	}

	reload() {
		this.updating();

		MatchService.matchCard(this.matchId).then(matchCard => {
			this.matchCard = matchCard;
			this.homeHandicap = this.matchCard.match.homeHandicap;
			this.awayHandicap = this.matchCard.match.awayHandicap;
			this.setState({ matchCard, updating: false }, () => {
				if (!this.state.matchCard.match.home.score && !this.state.matchCard.match.away.score) {
					if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.PointsAwarded && this.state.matchCard.match.submitted && !this.state.matchCard.match.approved) {
						this.matchCard.match.home.score = this.matchCard.match.home.points;
						this.matchCard.match.away.score = this.matchCard.match.away.points;
					}
					else if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Points){
						this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.home.score += g.home));
						this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.away.score += g.away));		
					}
					else {
						this.matchCard.sets.map(s => this.matchCard.match.home.score += (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.homeScore : (s.homeScore > s.awayScore && s.completed ? 1 : 0));
						this.matchCard.sets.map(s => this.matchCard.match.away.score += (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.awayScore :  (s.awayScore > s.homeScore && s.completed ? 1 : 0));		
					}
					if(this.matchCard.match.homeHandicap != null && this.matchCard.match.awayHandicap != null) {
						if(this.matchCard.match.homeHandicap > this.matchCard.match.awayHandicap){
							this.matchCard.match.home.score += ((this.matchCard.match.homeHandicap - this.matchCard.match.awayHandicap));
						}
						else{
							this.matchCard.match.away.score += ((this.matchCard.match.awayHandicap - this.matchCard.match.homeHandicap));
						}
					}

					this.setState({ matchCard: this.matchCard });
				}
				NProgress.done();
			});
		});
	}

	unload() {
		this.signalREvents?.forEach(dispatcher => dispatcher.off());
	};

	handleChangeMatchPlayer(player: MatchPlayer, option: SelectOption<string>) {
		this.updateMatchPlayer(player.id, option.value);
	}

	updateMatchPlayer(playerId: number, member: string) {
		this.updating();

		MatchService.updateMatchPlayer(playerId, member).then(result => {
			ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);
			this.reload();
		});
	}

	updateSetPlayer(playerId: number, member: string) {
		this.updating();

		MatchService.updateSetPlayer(playerId, member).then(result => {
			this.reload();
		});
	}

	submit() {
		this.updating();

		MatchService.status(this.matchId, MatchStatus.Submitted).then(result => {
			MiscHelper.scrollToTop();
			this.reload();
		});
	}

	approve() {
		this.updating();

		MatchService.status(this.matchId, MatchStatus.Approved).then(result => {
			this.reload();
		});
	}

	updating(updating: boolean = true) {
		if (updating) {
			NProgress.start();
		} else {
			NProgress.done();
		}

		this.setState({ updating });
	}

	veto() {
		this.updating();
		MatchService.status(this.matchId, MatchStatus.Veto).then(result => {
			this.reload();
		});
	}

	reject() {
		this.updating();
		MatchService.status(this.matchId, MatchStatus.Rejected).then(result => {
			this.reload();
		});
	}

	override() {
		this.updating();
		MatchService.status(this.matchId, MatchStatus.Override).then(result => {
			this.reload();
		});
	}

	handleChangeDoubles(player: MatchPlayer, option: SelectOption<string>) {
		this.updateMatchPlayer(player.id, option.value);
	}

	handleScratchTypeChange(option: SelectOption<number>) {
		this.setState({
			scratchType: option.value
		});
	}

	handleScratchEntrantChange(option: SelectOption<number>) {
		this.setState({
			scratchEntrant: option.value
		});
	}

	handleForfeitEntrantChange(option: SelectOption<number>) {
		this.setState({
			forfeitEntrant: option.value
		});
	}

	forfeitEntrantModal(playerId: number) {
		this.setState({ forfeitPlayer: null, forfeitEntrant: null }, () => {
			this.forfeitModal();
		});
	}

	forfeitPlayerModal(playerId: number) {
		this.setState({ forfeitPlayer: playerId, forfeitEntrant: null }, () => {
			this.forfeitModal();
		});
	}

	forfeitModal() {
		this.setState({ forfeitModal: !this.state.forfeitModal });
	}

	forfeitEntrant() {
		this.updating();

		MatchService.forfeitMatch(this.matchId, this.state.forfeitEntrant, this.state.forfeitReason).then(result => {
			this.reload();
			this.forfeitModal();
		});
	}

	forfeitPlayer() {
		this.updating();

		MatchService.forfeitPlayer(this.matchId, this.state.forfeitPlayer, this.state.forfeitReason).then(result => {
			this.reload();
			this.forfeitModal();
		});
	}

	abandonedModal() {
		this.setState({ abandonedModal: !this.state.abandonedModal });
	}

	AbandonMatch() {
		this.updating();

		MatchService.abandonMatch(this.matchId, this.state.abandonedReason).then(result => {
			MatchService.status(this.matchId, MatchStatus.Submitted).then(result => {
				MatchService.status(this.matchId, MatchStatus.Approved).then(result => {
					this.reload();
				});
			});
			this.abandonedModal();
		});
	}

	potmModal() {
		this.setState({ potmModal: !this.state.potmModal });
	}

	updatePotm() {
		if (this.state.potmId && this.state.potmType) {
			this.updating();

			MatchService.updatePotm(this.matchId, this.state.potmId, this.state.potmType).then(result => {
				this.reload();
				this.potmModal();
			});
		}
		else {
			toast.error("Player and Type required");
		}
	}

	matchReportModal() {
		this.setState({ matchReportModal: !this.state.matchReportModal });
	}

	matchGameModal(game?: MatchGame, set?: MatchSet, next: boolean = false) {
		var liveGame = new MatchGame;
		if (game != null) {
			if (game.home == null && game.away == null) {
				liveGame.home = 0;
				liveGame.away = 0;
			}
			else{
				liveGame.home = game.home;
				liveGame.away = game.away;
			}
			liveGame.id = game.id;
			liveGame.ordering = game.ordering
		}
		else{
			//this.clearGameData();
			liveGame = null;
		}
		if(next){
			this.setState({expediteMatch: false, ignoreServer: false, liveGame: liveGame, liveSet: set, matchStatus: {...this.state.matchStatus, gameEnd: false, gameId: liveGame.id} }, () => {ScoringHubService.instance.sendMatchStatus(this.state.matchStatus); ScoringHubService.instance.sendGameData(this.state.liveGame);});
		}
		else{
			this.setState({ expediteMatch: false, ignoreServer: false, matchGameModal: !this.state.matchGameModal, liveGame: liveGame, liveSet: set, matchStatus: {...this.state.matchStatus, gameEnd: false, gameId: liveGame?.id, timeout: false, hadHomeTimeout: set?.homeTimeout ?? false, hadAwayTimeout: set?.awayTimeout ?? false} }, () => {ScoringHubService.instance.sendMatchStatus(this.state.matchStatus); liveGame == null ? ScoringHubService.instance.clearGameData() : ScoringHubService.instance.sendGameData(this.state.liveGame)});
		}
	}

	liveEntryModal(modal: boolean = this.state.liveEntryModal) {
		var channelId = sessionStorage.getItem("channelId");
		var groupsRecieved = ScoringHubService.instance.subscribeSignalR("groupsRecieved", (data: dictionary<string,number>[]) => {
			if(this.state.groupNames != data){
				this.setState({groupNames: data});
			}
		});
		if (!modal) {
			if(channelId == null){
				console.log("no channel")
				var hubConnection = new signalR.HubConnectionBuilder()
					.withUrl(`${AppConfig.baseUrl}scoreboardhub`, {withCredentials: false})
					.configureLogging(signalR.LogLevel.Information)
					.build();
				this.setState({hubConnection}, () => {
					this.state.hubConnection.start().then(() => {
						console.log(signalR.HubConnectionState[this.state.hubConnection.state])
						if (this.state.hubConnection.state == signalR.HubConnectionState.Connected){
							this.state.hubConnection.invoke("CheckConnections");
							this.state.hubConnection.on("groupsRecieved", (data: dictionary<string,number>[]) => {
								if(this.state.groupNames != data){
									this.setState({groupNames: data});
								}
							});
							this.interval = setInterval(() => 
							{
								this.state.hubConnection.invoke("GetGroups", this.state.division.id)
								.catch(err => console.error(err.toString()));
							}, 1500);
						}
					}).catch(error => {
						console.log(`[SIGNALR]: Error`);
					});
				});
			}
			else{
				ScoringHubService.instance.CheckConnections();
				this.interval = setInterval(() => 
				{
					ScoringHubService.instance.getGroups(this.state.division.id);
				}, 1500);
			}

			this.setState({groupName: channelId != null ? channelId?.split("~")[1] : null});
		}
		else{
			if(this.state.hubConnection != null){
				this.state.hubConnection.off("groupsRecieved")
				this.state.hubConnection.stop();
				this.setState({hubConnection: null});
			}
			clearInterval(this.interval);
			groupsRecieved.off();
			this.setState({groupName: null})
		}
		this.setState({liveEntryModal: !modal})
	}

	updateMatchReport() {
		this.updating();

		MatchService.updateReport(this.state.report).then(result => {
			this.matchReportModal();
			this.loadReport();
			this.updating(false);
		});
	}

	removeMatchReport() {
		this.updating();

		MatchService.removeReport(this.state.report).then(result => {
			this.matchReportModal();
			this.loadReport();
			this.updating(false);
		});
	}

	hideMatchReport() {
		this.updating();

		MatchService.hideReport(this.state.report).then(result => {
			this.loadReport();
			this.updating(false);
		});
	}

	handleStateChange = (state: Function, local: Function = null) => (e: any) => {
		let stripped: string = state.toString().replace(/(\r\n|\n|\r|;| )/gm, "");

		const prop = /function\(\){return(.*)}/.exec(stripped.toString())[1];
		const name = /\.([^\.;]+);?\s*\}$/.exec(stripped.toString())[1];
		let value = null;

		const parent: string = prop.toString().split(".")[2];
		const child: string = prop.toString().split(".")[3];

		if (e == null) {
			value = null;
			this[name] = e;
		} else if (e.value != null) {
			value = e.value;
			this[name] = e;
		} else if (e.currentTarget) {
			value = e.currentTarget.value;
		} else {
			value = e;
		}

		if (child) {
			const p = this.state[parent];
			const n = p[child];
			const model = child == name ? value : { ...n, [name]: value };

			this.setState({ ...this.state, [parent]: { ...p, [child]: model } }, () => {
			});
		}
		else {
			this.setState({ ...this.state, [parent]: value }, () => {

			});
		}
	}

	checkReserveSettings(){
		if((this.state.division.reserves?.type ?? this.state.competition.reserves.type) === 1){
			return true;
		}
		if((this.state.division.reserves?.overrideEnabled ?? this.state.competition.reserves.overrideEnabled)){
			return true;
		}
		return false;
	}

	loadPlayerOptions(home: boolean, value: string): Promise<any[]> {
		return new Promise(resolve => {
			if (this.checkReserveSettings() && value) {
				MemberService.search({
					search: value,
					competitionId: this.state.competition.id,
					email: false,
					player: true
				}).then(members => {
					const options = members.filter(m => this.matchCard.playing.home.every(p => p.userId !== m.id) && this.matchCard.playing.away.every(p => p.userId !== m.id)).map((t) => ({ label: t.name, value: t.id }));
					resolve(options);
				});
			} else {
				var players = home ? this.homePlayerOptions : this.awayPlayerOptions;
				var members: SelectOption<String>[];
				var reserves: SelectOption<string>[];
				members = players[0].options;
				reserves = players[1].options;
				reserves = reserves.filter(o => {if(o.label.split("-")[1].toLowerCase().indexOf(value.toLowerCase()) >= 0) return o});
				members = members.filter(o => {if(o.label.split("-")[1].toLowerCase().indexOf(value.toLowerCase()) >= 0) return o});
				players[0].options = members;
				players[1].options = reserves;
				const options = players;
				resolve(options);
			}
		});
	}

	remove() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>Remove club from your current league?</p>
						<a className="btn btn-danger text-white mr-2"
							onClick={() => {
								onClose();
							}}
						>
							Yes, Remove it!
						</a>
						<a className="btn btn-secondary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	scratchSet(e: any) {
		e.preventDefault();

		MatchService.scratch(this.state.set.id, this.state.scratchEntrant, this.state.scratchType, this.state.scratchReason).then(result => {
			this.reload();
			this.cancel();
		});
	}

	reset() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will completely clear the match card and start again?</p>
						<a className="btn btn-danger text-white mr-2"
							onClick={() => {
								MatchService.resetMatch(this.matchId).then(result => {
									this.reload();
									onClose();
								})
							}}
						>
							Yes, reset it!
						</a>
						<a className="btn btn-secondary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	resetSet(set: MatchSet) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will reset the selected set?</p>
						<a className="btn btn-danger text-white mr-2"
							onClick={() => {
								MatchService.resetSet(set.id).then(result => {
									this.reload();
									onClose();
								})
							}}
						>
							Yes, reset it!
						</a>
						<a className="btn btn-secondary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	allowUpdate(game: MatchGame, set: MatchSet) {
		let previous: MatchGame;

		if (game.ordering > 0) {
			previous = set.games[game.ordering - 1];
		}

		if ((!previous && game.ordering == 0) || (!this.isValid(previous, set) && !ObjectHelper.isNull(game.home) && !ObjectHelper.isNull(game.away)) || (previous && !ObjectHelper.isNull(previous.home) && !ObjectHelper.isNull(previous.away)) || (ObjectHelper.isNull(game.home) || ObjectHelper.isNull(game.away))) {
			return true;
		}

		return false;
	}

	updateScore(game: MatchGame, set: MatchSet, value: string, home: boolean) {
		const score = parseInt(value);

		let previous: MatchGame;

		if (game.ordering > 0) {
			previous = set.games[game.ordering - 1];
		}

		var model = new MatchGame();

		model.home = game.home;
		model.away = game.away;
		model.ordering = game.ordering;
		model.id = game.id;

		if (this.allowUpdate(game, set)) {
			if (isNaN(score)) {
				if (home) {
					game.home = null;
				}
				else {
					game.away = null;
				}
				set.completed = null;
			}
			else {
				if (home) {
					game.home = score;
				} else {
					game.away = score;
				}
			}

			if(!this.isValid(model, set) && !this.isOrdered(game, set)){
				game.errored = true;
			}

			this.setState({ matchCard: this.matchCard });
		}
	}

	updateGame(game: MatchGame, set: MatchSet, value: string, home: boolean) {
		const score = parseInt(value);
		const scoreUp = this.state.division.gameFormat ? this.state.division.gameFormat === 2 ? 21 : 11 : this.state.competition.gameFormat === 2 ? 21 : 11;

		if(game.failed){
			this.matchCard.sets.find(s => s.id == set.id).games.find(g => g.id == game.id).failed = false;
		}

		if(game.errored){
			this.matchCard.sets.find(s => s.id == set.id).games.find(g => g.id == game.id).errored = false;
		}

		if (this.allowUpdate(game, set)) {
			if (!(home && ((isNaN(score) && game.home == null)) || !home && ((isNaN(score) && game.away == null)))) {
				if (!this.state.competition.manualScoring) {
					if (score < scoreUp - 1 && !(ObjectHelper.isNull(game.home) && ObjectHelper.isNull(game.away))) {
						game.home = home ? score : scoreUp;
						game.away = home ? scoreUp : score;
					} else {
						if (ObjectHelper.isNull(game.home)) {
							game.home = home ? score : score + 2;
						} else if (ObjectHelper.isNull(game.away)) {
							game.away = home ? score + 2 : score;
						} else if (this.state.liveGame != null && (!home && game.home - game.away != 2 || home && game.away - game.home != 2)) {
							game.home = home ? score : score + 2;
							game.away = home ? score + 2 : score;
						}else {
							game.home = home ? score : game.home;
							game.away = home ? game.away : score;							
						}
					}
				}
				else {
					if (home) {
						game.home = score;
					}
					else {
						game.away = score;
					}
				}
			}

			let homeScore = 0;
			let awayScore = 0;

			let invalid = false;

			for (let i = 0; i < set.games.length; i++) {
				const element = set.games[i];
				if (!invalid) {
					if (!this.isValid(element, set)) {
						invalid = true;
					}
				}
			}

			for (let i = 0; i < set.games.length; i++) {
				const element = set.games[i];
				const gamesPerSet = (this.state.division?.gamesPerSet ? this.state.division?.gamesPerSet : this.state.competition?.gamesPerSet);
				const firstTo = Math.ceil(gamesPerSet / 2);

				if ((element.home != null && element.away != null) && (this.state.competition.manualScoring || element.home >= scoreUp || element.away >= scoreUp)) {
					if (element.home > element.away) {
						homeScore++;
					} else if (element.away > element.home) {
						awayScore++;
					}
					else {
						homeScore++;
						awayScore++;
					}
				}

				if (invalid) {
					set.completed = null;
				}
				else {
					if (this.state.division.playAll ? this.state.division.playAll : this.state.competition.playAll) {
						const completed: number = set.games.filter(s => s.away != null && s.home != null).length;
						if (completed === gamesPerSet) {
							set.completed = moment().toDate();
						} else {
							set.completed = null;
						}
					} else {
						if (homeScore === firstTo || awayScore === firstTo) {
							set.completed = moment().toDate();
						} else {
							set.completed = null;
						}
					}
				}
			}

			set.homeScore = homeScore;
			set.awayScore = awayScore;

			this.matchCard.match.home.score = 0;
			this.matchCard.match.away.score = 0;
			if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Points){
				this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.home.score += g.home));
				this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.away.score += g.away));		
			}
			else{
				this.matchCard.sets.map(s => this.matchCard.match.home.score += s.completed ? (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.homeScore : (s.homeScore > s.awayScore ? 1 : 0 ) : 0);
				this.matchCard.sets.map(s => this.matchCard.match.away.score += s.completed ? (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.awayScore :  (s.awayScore > s.homeScore ? 1 : 0) : 0);
			}
			if(this.matchCard.match.homeHandicap != null && this.matchCard.match.awayHandicap != null) {
				if(this.matchCard.match.homeHandicap > this.matchCard.match.awayHandicap){
					this.matchCard.match.home.score += ((this.matchCard.match.homeHandicap - this.matchCard.match.awayHandicap));
				}
				else{
					this.matchCard.match.away.score += ((this.matchCard.match.awayHandicap - this.matchCard.match.homeHandicap));
				}
			}

			this.setState({ matchCard: this.matchCard });

			this.queue.add(() => {
				NProgress.start();

				if (this.isReady()) {
					this.setState({ updating: true });
				}
				return MatchService.updateGame(game).catch(() => {
					this.matchCard.sets.find(s => s.id == set.id).games.find(g => g.id == game.id).failed = true;
					this.setState({ matchCard: this.matchCard });
				});
			}).then(() => {
				if (this.queue.getPendingLength() === 0) {
					this.setState({ updating: false });
					NProgress.done();
				}
			});
		}
	}

	select(set: MatchSet) {
		if (!set.completed){
			const homeScratch = set.players.find(p => p.scratch != null && p.entrantId == this.state.matchCard.match.home.id);
			const awayScratch = set.players.find(p => p.scratch != null && p.entrantId == this.state.matchCard.match.away.id);

			if (homeScratch && awayScratch) {
				this.resetSet(set);
			} else {
				this.setState({ set });
			}
		}
		
	}

	isReady(): boolean {
		return this.state.matchCard.sets.find(s => !s.completed) == null && this.checkPlayersAssigned();
	}

	groupBy(array, f) {
		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		})
	}

	getDoubleOptions(home: boolean, existing: string[]) {
		const members = home ? this.state.matchCard.match.home.members : this.state.matchCard.match.away.members;
		const reserves = home ? this.state.matchCard.match.home.reserves : this.state.matchCard.match.away.reserves;

		const options = [
			{
				label: "Playing",
				options: [] = members.filter(m => m.playing && existing.indexOf(m.memberId) < 0).map(m => ({ label: `${m.name}`, value: m.memberId }))
			},
			{
				label: "Team Members",
				options: [] = members.filter(m => !m.playing && existing.indexOf(m.memberId) < 0).map(m => ({ label: `${m.name}`, value: m.memberId }))
			},
			{
				label: "Reserves",
				options: [] = reserves.map(m => ({ label: m.name, value: m.memberId }))
			},
			{
				label: "Other",
				options: [] = []
			}
		];

		return options;
	}

	isValid(game: MatchGame, set: MatchSet) {
		const gamesPerSet = (this.state.division?.gamesPerSet ? this.state.division?.gamesPerSet : this.state.competition?.gamesPerSet);
		const firstTo = Math.ceil(gamesPerSet / 2);
		const scoreUp = this.state.division.gameFormat ? this.state.division.gameFormat === 2 ? 21 : 11 : this.state.competition.gameFormat == 2 ? 21 : 11;

		if (game.home === 0 && game.away === 0 && set.players.filter(p => p.forfeit).length === set.players.length) {
			return true;
		}
		if(game.home < 0 || game.away < 0){
			return false;
		}
		if(game.home > 100 || game.away > 100){
			return false;
		}
		if (!this.state.competition.manualScoring) {
			if (game.home != null && game.away != null && game.home < scoreUp && game.away < scoreUp) {
				return false;
			}

			if (game.home != null && game.away != null) {
				if (game.home === game.away)
					return false;

				if (game.home > game.away && (((game.home - game.away) < 2) || (game.home > scoreUp && (game.home - game.away) > 2)))
					return false;

				if (game.away > game.home && (((game.away - game.home) < 2) || (game.away > scoreUp && (game.away - game.home) > 2)))
					return false;
			}
		}

		const next = set.games.filter(g => g.ordering > game.ordering && g.home != null && g.away != null);

		const previous = set.games.filter(g => g.ordering < game.ordering && g.home != null && g.away != null);

		if ((game.home == null || game.away == null) && next.length > 0)
			return false;

		if (!(this.state.division.playAll ? this.state.division.playAll : this.state.competition.playAll)){
			if (set.homeScore > firstTo || set.awayScore > firstTo)
				return false;
			if ((game.away != null || game.home != null) && previous.filter(g => g.away > g.home && (g.away >= scoreUp && (g.away - g.home) >= 2)).length == firstTo)
				return false;
			if ((game.away != null || game.home != null) && previous.filter(g => g.home > g.away && (g.home >= scoreUp && (g.home - g.away) >= 2)).length == firstTo)
				return false;
			if ((game.away != null || game.home != null) && previous.length < game.ordering)
				return false;
		}
		if(game.failed){
			return false;
		}

		return true;
	}

	isOrdered(game: MatchGame, set: MatchSet){
		const previous = set.games.filter(g => g.ordering < game.ordering && (this.isValid(g,set) && !(g.home == null && g.away == null)));
		const index = set.games.findIndex(g => g == game);
		if (previous.length != index && game.home == null && game.away == null && !game.errored)
			return false;

		return true;
	}

	toggleEditModal = () => (e: any) => {
		this.setState({ matchEditModal: !this.state.matchEditModal });
	}

	handleChangeMatch(e: any, property: Function) {
		const name = /\.([^\.;]+);?\s*\}$/.exec(property.toString())[1];
		this.setState({ updateMatch: { ...this.state.updateMatch, [name]: e } });
	}

	async updateMatch() {

		var match = this.state.updateMatch;
		var date = moment(match.date);
		var time = moment(match.time);
		var dateTime = new Date(date.year(), date.month(), date.date(), time.hour(), time.minute());
		if (match.date && match.time)
			match.time = dateTime;
		match.tableNo = this.state.matchCard.match.tableNo;
		match.venueId = this.state.matchCard.match.venueId;
		match.round = this.state.matchCard.match.round;
		
		this.setState({updateMatch: match});
		await MatchService.update(match);

		const matchCard = this.state.matchCard;
		matchCard.match.date = this.state.updateMatch.date;
		matchCard.match.time = this.state.updateMatch.time;

		this.matchCard = matchCard;
		this.setState({ matchCard }, () => {
			this.setState({ matchEditModal: !this.state.matchEditModal });
			if(window.location.pathname != `/league/${this.state.competition.id}/division/${this.state.division.id}/match/${this.matchId}/card`){
				window.location.href = window.location.origin + `/league/${this.state.competition.id}/division/${this.state.division.id}/match/${this.matchId}/card`
			}
		});
	}

	checkEntry() {
		if (!this.state.matchCard.match.submitted) {
			switch (this.state.matchCard.match.entry) {
				case MatchCardEntryLevel.Submit:
				case MatchCardEntryLevel.Approve:
				case MatchCardEntryLevel.Full:
					return true;
				case MatchCardEntryLevel.Report:
				case MatchCardEntryLevel.None:
				default:
					return false;

			}
		}
		else if (!this.state.matchCard.match.approved) {
			switch (this.state.matchCard.match.entry) {
				case MatchCardEntryLevel.Approve:
				case MatchCardEntryLevel.Full:
					return true;
				case MatchCardEntryLevel.Submit:
				case MatchCardEntryLevel.None:
				default:
					return false;
			}
		}
		else {
			switch (this.state.matchCard.match.entry) {
				case MatchCardEntryLevel.Full:
				case MatchCardEntryLevel.Approve:
					return true;
				case MatchCardEntryLevel.Submit:
				case MatchCardEntryLevel.None:
				default:
					return false;
			}
		}

	}

	checkPlayersAssigned() : boolean{
		if(this.state.competition.requirePlayers)
			return (this.state.matchCard.playing.home.every(p => p.forfeit || p.userId.length > 0 ) && this.state.matchCard.playing.away.every(p => p.forfeit || p.userId.length > 0) || this.state.matchCard.match.forfeit != null)
		else
			return true;
	}

	label(ordering: number, home: boolean, playerCount: number) {
		var alpha = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
		var index = 0;
		if (this.state.competition.matchCard.playerLabels == PlayerLabels.Alphabetical) {
			if (home)
				index = ordering;
			else
				index = (26 - playerCount) + ordering;
			return alpha[index];
		}
		else {
			return ordering + 1;
		}
	}

	scratchInfo(set: MatchSet){
		if(set.players.some(p => p.scratch != null)){
			var player = set.players.find(p => p.scratch != null);
			var type = "";
			switch(player.scratchType){
				case 1:
					type = "Walkover";
					break;
				case 2:
					type = "Conceded";
					break;
			}
			return `Scratch Type: ${type}\nPlayer: ${player.printoutName}\nReason: ${player.scratchReason}`
		}
	}

	updateTeamHandicaps(e: any) {
		e.preventDefault();
		if (this.homeHandicap != null && this.awayHandicap != null || this.homeHandicap == null && this.awayHandicap == null){
			MatchService.updateTeamHandicap({matchId: this.state.matchCard.match.id, homeHandicap: this.homeHandicap, awayHandicap: this.awayHandicap}).then(result => {
				this.reload();
				this.cancel();
			});
		}
	}

	changeScoreLiveEntry(home: boolean, direction: number){
		if(!(this.state.liveSet.homePlayers.length > 1 && this.state.liveGame.initialServer != null && this.state.liveGame.secondaryIntitalServer == null)){
			if(home){
				this.setState({liveGame: { ...this.state.liveGame, home: (this.state.liveGame.home + direction)}}, async () => {
					this.changeServerLiveEntry();
				});
			}
			else{
				this.setState({liveGame: { ...this.state.liveGame, away: (this.state.liveGame.away + direction)}}, async () => {
					this.changeServerLiveEntry();
				});
			}
		}
	}

	changeServerLiveEntry(){
		var current = this.state.liveGame.currentServer;
		var secondary = this.state.liveGame.secondaryServer;
		var game = this.state.liveGame;
		var set = this.state.liveSet;
		if(this.state.liveGame.initialServer !=  null){
			if(((this.state.liveGame.home >= 10 && this.state.liveGame.away > 10 || this.state.liveGame.home > 10 && this.state.liveGame.away >= 10) || this.state.expediteMatch) && this.state.expediteDetails != null){
				var details = this.state.expediteDetails;
				var score = game.home + game.away - details.home - details.away;
				if(set.homePlayers.some(p => p.userId == details.initialServer?.userId)){
					if(set.homePlayers.length > 1){
						var away = Math.floor((score)) % 2 == 1;
						var homePlayers = set.homePlayers.map(h => h);
						var awayPlayers = set.awayPlayers.map(a => a);
						if(set.homePlayers.findIndex(p => p.userId == details.initialServer?.userId) == 1){
							homePlayers = homePlayers.reverse();
						}
						if(set.awayPlayers.findIndex(p => p.userId == details.secondaryIntitalServer?.userId) == 1){
							awayPlayers = awayPlayers.reverse();
						}
						var index = Math.floor(((Math.floor(score)) % 4)/2);
						var sIndex = Math.floor(((Math.floor(score + 1)) % 4)/2);
						current = away ? awayPlayers[index] : homePlayers[index];
						secondary = !away ? awayPlayers[sIndex] : homePlayers[sIndex];
					}
					else{
						current = Math.floor((score)) % 2 == 1 ? set.awayPlayers[0] : set.homePlayers[0];
					}
				}
				else{
					if(set.awayPlayers.length > 1){
						var away = Math.floor((score)) % 2 == 0;
						var homePlayers = set.homePlayers.map(h => h);
						var awayPlayers = set.awayPlayers.map(a => a);
						if(set.homePlayers.findIndex(p => p.userId == details.secondaryIntitalServer?.userId) == 1){
							homePlayers = homePlayers.reverse();
						}
						if(set.awayPlayers.findIndex(p => p.userId == details.initialServer?.userId) == 1){
							awayPlayers = awayPlayers.reverse();
						}
						var index = Math.floor(((Math.floor(score)) % 4)/2);
						var sIndex = Math.floor(((Math.floor(score + 1)) % 4)/2);
						current = away ? awayPlayers[index] : homePlayers[index];
						secondary = !away ? awayPlayers[sIndex] : homePlayers[sIndex];
					}
					else{
						current = Math.floor((score)) % 2 == 0 ? set.awayPlayers[0] : set.homePlayers[0];
					}
				}
			}
			else{
				if(set.homePlayers.some(p => p.userId == game.initialServer?.userId)){
					if(set.homePlayers.length > 1){
						var away = Math.floor((game.home + game.away)/2) % 2 == 1;
						var homePlayers = set.homePlayers.map(h => h);
						var awayPlayers = set.awayPlayers.map(a => a);
						if(set.homePlayers.findIndex(p => p.userId == game.initialServer?.userId) == 1){
							homePlayers = homePlayers.reverse();
						}
						if(set.awayPlayers.findIndex(p => p.userId == game.secondaryIntitalServer?.userId) == 1){
							awayPlayers = awayPlayers.reverse();
						}
						var index = Math.floor(((Math.floor(game.home + game.away)/2) % 4)/2);
						var sIndex = Math.floor(((Math.floor(game.home + game.away + 2)/2) % 4)/2);
						current = away ? awayPlayers[index] : homePlayers[index];
						secondary = !away ? awayPlayers[sIndex] : homePlayers[sIndex];
					}
					else{
						current = Math.floor((game.home + game.away)/2) % 2 == 1 ? set.awayPlayers[0] : set.homePlayers[0];
					}
				}
				else{
					if(set.awayPlayers.length > 1){
						var away = Math.floor((game.home + game.away)/2) % 2 == 0;
						var homePlayers = set.homePlayers.map(h => h);
						var awayPlayers = set.awayPlayers.map(a => a);
						if(set.homePlayers.findIndex(p => p.userId == game.secondaryIntitalServer?.userId) == 1){
							homePlayers = homePlayers.reverse();
						}
						if(set.awayPlayers.findIndex(p => p.userId == game.initialServer?.userId) == 1){
							awayPlayers = awayPlayers.reverse();
						}
						var index = Math.floor(((Math.floor(game.home + game.away)/2) % 4)/2);
						var sIndex = Math.floor(((Math.floor(game.home + game.away + 2)/2) % 4)/2);
						current = away ? awayPlayers[index] : homePlayers[index];
						secondary = !away ? awayPlayers[sIndex] : homePlayers[sIndex];
					}
					else{
						current = Math.floor((game.home + game.away)/2) % 2 == 0 ? set.awayPlayers[0] : set.homePlayers[0];
					}
				}
			}
		}
		this.setState({ liveGame: {...this.state.liveGame, currentServer: current, secondaryServer: secondary }}, () => {
			if(this.state.liveGame.home >= 10 && this.state.liveGame.away >= 10 && !this.state.expediteMatch){
				this.expoditeMatch(true, true);
			}
			else if(!this.state.expediteMatch){
				this.expoditeMatch(false, true);
			}
			ScoringHubService.instance.sendGameData(this.state.liveGame);
		});
	}

	changeIntialServerLiveEntry(e: MatchPlayer){
		if(this.state.liveGame.home == 0 && this.state.liveGame.away == 0) {
			if(this.state.liveGame.initialServer == null){
				var current = this.state.liveSet.homePlayers.find(p => p.userId == e?.userId) ?? this.state.liveSet.awayPlayers.find(p => p.userId == e?.userId);
				this.setState({liveGame: {...this.state.liveGame, initialServer: current, currentServer: current}}, () => {
					ScoringHubService.instance.sendGameData(this.state.liveGame);
				});
			}
			else if(this.state.liveGame.initialServer.userId == e?.userId && this.state.liveGame.secondaryIntitalServer == null){
				this.setState({liveGame: {...this.state.liveGame, initialServer: null, currentServer: null}}, () => {
					ScoringHubService.instance.sendGameData(this.state.liveGame);
				});
			}
			else if (this.state.liveSet.homePlayers.length > 1 && this.state.liveGame.secondaryIntitalServer == null){
				var players = this.state.liveSet.homePlayers.some(p => p.userId == e?.userId) ? this.state.liveSet.homePlayers : this.state.liveSet.awayPlayers;
				if(players.every(x => x.userId != this.state.liveGame.initialServer.userId)){
					var current = this.state.liveSet.homePlayers.find(p => p.userId == e?.userId) ?? this.state.liveSet.awayPlayers.find(p => p.userId == e?.userId);
					this.setState({liveGame: {...this.state.liveGame, secondaryIntitalServer: current, secondaryServer: current}}, () => {
						ScoringHubService.instance.sendGameData(this.state.liveGame);
					});
				}
			}
			else if(this.state.liveSet.homePlayers.length > 1 && this.state.liveGame.secondaryIntitalServer.userId == e?.userId){
				this.setState({liveGame: {...this.state.liveGame, secondaryIntitalServer: null, secondaryServer: null}}, () => {
					ScoringHubService.instance.sendGameData(this.state.liveGame);
				});
			}
		}
	}

	async expoditeMatch(e: boolean, auto: boolean = false){
		var details = this.state.expediteDetails;
		if(e){
			if(details == null){
				details = new MatchGameExpedite();
				details.home = this.state.liveGame.home;
				details.away = this.state.liveGame.away;
				details.initialServer = this.state.liveGame.currentServer;
				details.secondaryIntitalServer = this.state.liveGame.secondaryServer;
			}
		}
		else{
			details = null;
		}

		if(!auto){
			this.setState({expediteMatch: e, expediteDetails: details})
		}
		else{
			this.setState({expediteDetails: details})
		}
	}

	updateGameLiveEntry(e: any, next: boolean = false){
		if(e != null)
			e.preventDefault();
		const scoreUp = this.state.division.gameFormat ? this.state.division.gameFormat === 2 ? 21 : 11 : this.state.competition.gameFormat === 2 ? 21 : 11;
		if (this.state.competition.manualScoring){
			this.updateScore(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.home.toString(), true)
			this.updateScore(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.away.toString(), false)
			this.updateGame(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.home.toString(), true)
			this.updateGame(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.away.toString(), false);
		}
		else if (this.state.liveGame.home >= scoreUp || this.state.liveGame.away >= scoreUp) {
			if ((this.state.liveGame.home - this.state.liveGame.away) >= 2) {
				this.updateScore(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.away.toString(), false)
				this.updateGame(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.away.toString(), false)
			}
			else if ((this.state.liveGame.away - this.state.liveGame.home) >= 2) {
				this.updateScore(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.home.toString(), true)
				this.updateGame(this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id).games.find(g => g.id == this.state.liveGame.id), this.state.matchCard.sets.find(s => s.id == this.state.liveSet.id), this.state.liveGame.home.toString(), true);
			}
		}
		if(next){
			this.setState({matchStatus: {...this.state.matchStatus, gameEnd: true}}, () => {
				ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);
			});
		}
		else{
			this.matchGameModal();
		}
	}

	enableLiveEntry(){
		var channelId = this.state.division.id + "~" + this.state.groupName;
		sessionStorage.setItem("channelId", channelId);
		this.setState({connecting: true});
	}

	disableLiveEntry(){
		var channelId = null;
		sessionStorage.removeItem("channelId");
		clearInterval(this.interval);
		this.setState({connecting: true});
		this.unload();
	}

	handleTimeout(home: boolean){
		var sets = this.state.matchCard.sets;
		var set = sets.find(s => s.id == this.state.liveSet.id);
		var index = sets.findIndex(s => s.id == this.state.liveSet.id);

		if (home) {
			set.homeTimeout = true;
			sets[index] = set;
			this.setState({matchStatus: {...this.state.matchStatus, timeout: true, hadHomeTimeout: true, timeoutHome: true}, matchCard: {...this.state.matchCard, sets: sets}}, () => {ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);})
		}
		else {
			set.awayTimeout = true;
			sets[index] = set;
			this.setState({matchStatus: {...this.state.matchStatus, timeout: true, hadAwayTimeout: true, timeoutHome: false}, matchCard: {...this.state.matchCard, sets: sets}}, () => {ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);})
		}
	}

	public Modals = () => {

		let limit = moment(this.state.competition?.startDate).subtract(1, "days").toDate();

		let end = moment(this.state.matchCard?.match.roundModel?.endDate).subtract(1, "days").toDate();

		return (
			<div>
				<Modal className="modal--default" isOpen={this.state.forfeitModal} toggle={this.forfeitModal.bind(this)}>
					<ModalHeader toggle={this.forfeitModal.bind(this)}>{this.state.forfeitPlayer != null ? "Forfeit Player" : "Forfeit Match"}</ModalHeader>
					<ModalBody>
						{
							this.state.matchCard != null ?
								<form>
									{
										this.state.forfeitPlayer ? undefined :
											<div className="col-lg-12">
												<div className="form-group">
													<label className="form-control-label">Team <span className="tx-danger">*</span></label>
													<Select
														options={[
															{ label: this.state.matchCard.match.home.displayName, value: this.state.matchCard.match.home.id },
															{ label: this.state.matchCard.match.away.displayName, value: this.state.matchCard.match.away.id }
														]}
														closeMenuOnSelect={true}
														onChange={this.handleForfeitEntrantChange.bind(this)} />
												</div>
											</div>
									}
									<div className="col-lg-12">
										<div className="form-group">
											<label className="form-control-label">Reason</label>
											<input className="form-control" type="text" name="name" onChange={e => this.setState({ forfeitReason: e.currentTarget.value })} placeholder="Enter forfeit reason" />
										</div>
									</div>
								</form> : undefined
						}
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-md btn-primary btn-rounded" onClick={this.state.forfeitPlayer ? this.forfeitPlayer.bind(this) : this.forfeitEntrant.bind(this)}>Confirm</button>
						<button className="btn btn-md btn-secondary btn-rounded" onClick={this.forfeitModal.bind(this)}>Close</button>
					</ModalFooter>
				</Modal>
				<Modal className="modal--default" isOpen={this.state.abandonedModal} toggle={this.abandonedModal.bind(this)}>
					<ModalHeader toggle={this.abandonedModal.bind(this)}>Abandon Match</ModalHeader>
					<ModalBody>
						{
							this.state.matchCard != null ?
								<form>
									<div className="col-lg-12">
										<div className="form-group">
											<label className="form-control-label">Reason</label>
											<input className="form-control" type="text" name="name" onChange={e => this.setState({ abandonedReason: e.currentTarget.value })} placeholder="Enter abandoned reason" />
										</div>
									</div>
								</form> : undefined
						}
					</ModalBody>
					<ModalFooter>
						<button className="btn btn-md btn-primary btn-rounded" onClick={this.AbandonMatch.bind(this)}>Confirm</button>
						<button className="btn btn-md btn-secondary btn-rounded" onClick={this.abandonedModal.bind(this)}>Close</button>
					</ModalFooter>
				</Modal>
				{
					this.state.matchCard ?
						<Modal className="modal--default" isOpen={this.state.potmModal} toggle={this.potmModal.bind(this)}>
							<ModalHeader toggle={this.potmModal.bind(this)}>Player of the Match</ModalHeader>
							<ModalBody>
								<form>
									<div className="col-lg-12">
										<div className="form-group">
											<Select
												placeholder="Select player"
												options={[
													{
														label: this.state.matchCard.match.home.displayName,
														options: [] = this.state.matchCard.playing.home.filter(p => p.type == 1 && p.userId != "").map(m => ({ label: `${m.name}`, value: m.userId }))
													},
													{
														label: this.state.matchCard.match.away.displayName,
														options: [] = this.state.matchCard.playing.away.filter(p => p.type == 1 && p.userId != "").map(m => ({ label: `${m.name}`, value: m.userId }))
													}
												]}
												closeMenuOnSelect={true}
												onChange={this.handleStateChange(() => this.state.potmId, () => this.potmId)} />
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<Select
												placeholder="Select potm type"
												options={Options.potmTypes}
												closeMenuOnSelect={true}
												onChange={this.handleStateChange(() => this.state.potmType, () => this.potmType)} />
										</div>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-md btn-primary btn-rounded" onClick={this.updatePotm.bind(this)}>Confirm</button>
								<button className="btn btn-md btn-secondary btn-rounded" onClick={this.potmModal.bind(this)}>Close</button>
							</ModalFooter>
						</Modal> : undefined
				}
				{
					this.state.matchCard ?
						<Modal className="modal--large" isOpen={this.state.matchReportModal} toggle={this.matchReportModal.bind(this)}>
							<ModalHeader toggle={this.matchReportModal.bind(this)}>Match Report</ModalHeader>
							<ModalBody>
								<form>
									<div className="col-lg-12">
										<div className="form-group">
											<VisualEditor
												content={this.state.report.body}
												onChange={this.handleStateChange(() => this.state.report.body)}
											/>
										</div>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-md btn-primary btn-rounded" onClick={this.updateMatchReport.bind(this)}>Confirm</button>
								<Can rules={["manage:full:?", "manage:leagues:?", "manage:results:?"]} entryRules={[MatchCardEntryLevel.Full]} entry={this.state.matchCard.match.entry}>
									{this.state.report.createdBy != null && <button className="btn btn-md btn-primary btn-rounded" onClick={this.hideMatchReport.bind(this)}>{this.state.report?.hidden ? "Show" : "Hide"}</button>}
									{this.state.report.createdBy != null && <button className="btn btn-md btn-primary btn-rounded" onClick={this.removeMatchReport.bind(this)}>Delete</button>}
								</Can>
								<button className="btn btn-md btn-secondary btn-rounded" onClick={this.matchReportModal.bind(this)}>Close</button>
							</ModalFooter>
						</Modal> : undefined
				}
				{
					this.state.matchCard ?
						<Modal className="modal--large" isOpen={this.state.matchEditModal} toggle={this.toggleEditModal()}>
							<ModalHeader toggle={this.toggleEditModal()}>Edit Match</ModalHeader>
							<ModalBody>
								<form>
									{/* <div className="col-lg-12">
                                        <div className="form-group">
                                            <label className="form-control-label">Venue</label>
                                            <AsyncSelect
                                                isClearable
                                                value={this.venueId}
                                                defaultOptions
                                                loadOptions={this.loadVenueOptions.bind(this)}
                                                closeMenuOnSelect={true}
                                                onChange={this.handleChangeVenue.bind(this)} />
                                        </div>
                                    </div> */}
									<div className="col-lg-12">
										<div className="form-group">
											<label className="form-control-label">Date</label>
											<DayPicker
												firstDayOfWeek={1}
												month={this.state.updateMatch && this.state.updateMatch.date ? moment(this.state.updateMatch.date).toDate() : (this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) == 5 ? end : moment().toDate()}
												selectedDays={this.state.updateMatch && this.state.updateMatch.date ? [moment(this.state.updateMatch.date).toDate()] : undefined}
												disabledDays={[(this.state.division?.fixtureFormat ?? this.state.competition?.fixtureFormat) == 5 ? { after: end } : {before: limit}, limit]}
												onDayClick={day => this.handleChangeMatch(day, () => this.state.updateMatch.date)}
												className="mt-0" />
											{/* <DayPickerInput
												dayPickerProps={{
													firstDayOfWeek: 1,
													selectedDays: this.state.updateMatch && this.state.updateMatch.date ? [moment(this.state.updateMatch.date).toDate()] : undefined,
													month: this.state.updateMatch && this.state.updateMatch.date ? moment(this.state.updateMatch.date).toDate() : moment().toDate(),
													disabledDays: {[{ before: limit, after:end }, limit]}
												}}
												
												classNames={{ container: "form-control", overlayWrapper: "", overlay: "" }}
												format="DD-MM-YYYY"
												placeholder="DD-MM-YYYY"
												value={ObjectHelper.ifNotNull(this.state.updateMatch, () => this.state.updateMatch.date ? moment(this.state.updateMatch.date).format("DD-MM-YYYY") : undefined)}
												formatDate={(d, f) => moment(d).format(f)}
												parseDate={(d) => DateHelper.parseDate(d)}
												onDayChange={day => this.handleChangeMatch(day, () => this.state.updateMatch.date)} /> */}
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<label className="form-control-label">Time</label>
											<div className="form-control">
												<TimePicker showSecond={false} minuteStep={15} value={ObjectHelper.ifNotNull(this.state.updateMatch, () => this.state.updateMatch.time ? moment(this.state.updateMatch.time) : undefined)} onChange={e => this.handleChangeMatch(e, () => this.state.updateMatch.time)} />
											</div>
										</div>
									</div>
								</form>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-md btn-primary btn-rounded" onClick={this.updateMatch.bind(this)}>Confirm</button>
								<button className="btn btn-md btn-secondary btn-rounded" onClick={this.toggleEditModal()}>Close</button>
							</ModalFooter>
						</Modal> : undefined
				}
				{
					this.state.matchCard ?
						<Modal className="modal--large" isOpen={this.state.matchGameModal} toggle={this.matchGameModal.bind(this, null)}>
							<ModalHeader toggle={this.matchGameModal.bind(this, null)}>Game Score</ModalHeader>
							<ModalBody>
								<div style={{textAlign:"center"}}>
									<Row>
										<Col>
											<h1>Home</h1>
										</Col>
										<Col>
											<h2>Set #{this.state.liveSet?.ordering + 1}</h2>
											<h1>Game #{this.state.liveGame?.ordering + 1}</h1>
										</Col>
										<Col>
											<h1>Away</h1>
										</Col>
									</Row>
								</div>
								<Row>
									<Col>
									<div style={{textAlign:"left"}}>
											{this.state.liveSet?.homePlayers?.map(s => {
												return <a onClick={() => this.changeIntialServerLiveEntry(s)}><h3>
													{s.name}
													{this.state.liveGame?.currentServer?.userId == s.userId ? <span className="tx-success tx-20 mg-10"><i className="fa fa-circle"></i></span>
													: this.state.liveGame?.secondaryServer?.userId == s.userId ? <span className="tx-warning tx-20 mg-10"><i className="fa fa-circle"></i></span>
													: this.state.liveGame.home == 0 && this.state.liveGame.away == 0 && this.state.liveSet?.homePlayers?.every(x => x.userId != this.state.liveGame.initialServer?.userId) && <span className="tx-black tx-20 mg-10"><i className="far fa-circle"></i></span>}
												</h3></a>
											})}
										</div>
									</Col>
									<Col>
										<div style={{textAlign:"right"}}>
											{this.state.liveSet?.awayPlayers?.map(s => {
												return <a onClick={() => this.changeIntialServerLiveEntry(s)}><h3>
													{this.state.liveGame?.currentServer?.userId == s.userId ? <span className="tx-success tx-20 mg-10"><i className="fa fa-circle"></i></span>
													: this.state.liveGame?.secondaryServer?.userId == s.userId ? <span className="tx-warning tx-20 mg-10"><i className="fa fa-circle"></i></span>
													: this.state.liveGame.home == 0 && this.state.liveGame.away == 0 && this.state.liveSet?.awayPlayers?.every(x => x.userId != this.state.liveGame.initialServer?.userId) && <span className="tx-black tx-20 mg-10"><i className="far fa-circle"></i></span>}
													{s.name}
												</h3></a>
											})}
										</div>
									</Col>
								</Row>
								{!this.state.matchStatus?.timeout && ((this.state.liveGame?.initialServer != null && (this.state.liveSet?.homePlayers?.length == 1 || this.state.liveGame.secondaryIntitalServer != null)) || this.state.ignoreServer) && <Row>
									<Col>
										{this.state.liveGame?.initialServer != null && ((!this.state.expediteMatch && (this.state.liveGame?.home < 10 || this.state.liveGame?.away < 10)) || (this.state.expediteMatch && this.state.liveGame?.home == this.state.expediteDetails?.home && this.state.liveGame?.away == this.state.expediteDetails?.away)) && <div className="form-group" style={{textAlign: "center"}}>
											{/* <label className="form-control-label">Expedite Match</label>
											<Toggle checked={this.state.expediteMatch} onChange={e => this.expoditeMatch(e)} /> */}
											<button className="btn btn-md btn-secondary btn-rounded btn-block" onClick={() => this.expoditeMatch(!this.state.expediteMatch)}><div className="tx-30"><i className={`icon far fa-chevron${this.state.expediteMatch ? "-double" : ""}-right`}></i></div></button>
										</div>}
									</Col>
									<Col>
										{(!this.state.matchStatus?.hadHomeTimeout || !this.state.matchStatus?.hadAwayTimeout)&& <div style={{textAlign: "center"}}>
											<Row>
												<Col>
													{!this.state.matchStatus?.hadHomeTimeout && <button className="btn btn-md btn-tertiary btn-rounded btn-block" onClick={() => this.handleTimeout(true)}><div className="tx-30"><i className="icon far fa-stopwatch"></i></div></button>}
												</Col>
												<Col>
													{!this.state.matchStatus?.hadAwayTimeout && <button className="btn btn-md btn-primary btn-rounded btn-block" onClick={() => this.handleTimeout(false)}><div className="tx-30"><i className="icon far fa-stopwatch"></i></div></button>}
												</Col>
											</Row>
										</div>}
									</Col>
								</Row>}
								
								{!this.state.matchStatus?.timeout ? 
									((this.state.liveGame?.initialServer != null && (this.state.liveSet?.homePlayers?.length == 1 || this.state.liveGame.secondaryIntitalServer != null)) || this.state.ignoreServer ?
									<Row>
										<Col>
											<div style={{textAlign:"center"}}>
												<button onClick={() => this.state.liveGame.home < 100 &&
													this.changeScoreLiveEntry(true, 1)
													} title="Home Increase" className="btn btn-circle text-secondary btn-icon mr-2">
													<div className="tx-60"><i className="icon far fa-chevron-up"></i></div>
												</button>
												<h1 style={{border: "1px solid black", borderRadius:"15px", fontSize:"60pt", height: "150px", padding: "25px", margin: "10px"}}>
													{this.state.liveGame?.home}
												</h1>
												<button onClick={() => this.state.liveGame.home > 0 && (!this.state.expediteMatch || this.state.expediteDetails?.home < this.state.liveGame.home) &&
													this.changeScoreLiveEntry(true, -1)
													} title="Home Decrease" className="btn btn-circle text-secondary btn-icon mr-2">
													<div className="tx-60"><i className="icon far fa-chevron-down"></i></div>
												</button>
											</div>
										</Col>
										<Col>
											<div style={{textAlign:"center"}}>
												<button onClick={() => this.state.liveGame.away < 100 && 
													this.changeScoreLiveEntry(false, 1)
													} title="Away Increase" className="btn btn-circle text-secondary btn-icon mr-2">
													<div className="tx-60"><i className="icon far fa-chevron-up"></i></div>
												</button>
												<h1 style={{border: "1px solid black", borderRadius:"15px", fontSize:"60pt", height: "150px", padding: "25px", margin: "10px"}}>
													{this.state.liveGame?.away}
												</h1>
												<button onClick={() => this.state.liveGame.away > 0 && (!this.state.expediteMatch || this.state.expediteDetails?.away < this.state.liveGame.away) &&
													this.changeScoreLiveEntry(false, -1)
													} title="Away Decrease" className="btn btn-circle text-secondary btn-icon mr-2">
													<div className="tx-60"><i className="icon far fa-chevron-down"></i></div>
												</button>
											</div>
										</Col>
									</Row>
									:
									<Row>
										<div style={{margin: "5px"}}>
											<div className="alert alert-warning" role="alert">
												<p><strong>Warning:</strong> Initial Server{this.state.liveSet?.homePlayers?.length > 1 && "s"} have not been selected, to continue the initial server{this.state.liveSet?.homePlayers?.length > 1 && "s"} must be selected or confirm that you wish to preceed without.</p>
												<button className="btn btn-md btn-primary btn-rounded" onClick={() => this.setState({ignoreServer: true})}>Confirm</button>
											</div>
										</div>
									</Row>)
								:
								<Row style={{justifyContent: "center"}}>
									<div style={{margin: "5px"}}>
										<div className="alert alert-info" role="alert" style={{textAlign: "center"}}>
											<p><strong>Timeout:</strong> This game is currently in timeout.</p>
											<button className="btn btn-md btn-primary btn-rounded" onClick={() => this.setState({matchStatus: {...this.state.matchStatus, timeout: false}}, () => {ScoringHubService.instance.sendMatchStatus(this.state.matchStatus);})}>Continue Game</button>
										</div>
									</div>
								</Row>
								}
							</ModalBody>
							<ModalFooter>
								{this.state.matchStatus?.gameEnd && !this.state.liveSet?.completed && <button className="btn btn-md btn-primary btn-rounded" onClick={() => { this.matchGameModal(this.state.liveSet?.games.find(g => g.ordering == this.state.liveSet?.games.findIndex(x => x.id == this.state.liveGame?.id) +  1),this.state.liveSet, true)}} >Next</button>}
								{!this.state.matchStatus?.gameEnd && <button className="btn btn-md btn-primary btn-rounded" onClick={() => this.updateGameLiveEntry(null, true)}>End</button>}
								{!this.state.matchStatus?.gameEnd && <button className="btn btn-md btn-primary btn-rounded" onClick={() => this.updateGameLiveEntry(null, false)}>End and Close</button>}
								<button className="btn btn-md btn-secondary btn-rounded" onClick={this.matchGameModal.bind(this, null)}>Close</button>
							</ModalFooter>
						</Modal> : undefined
				}
				{
					this.state.matchCard ?
						<Modal className="modal--default" isOpen={this.state.liveEntryModal} toggle={this.liveEntryModal.bind(this)}>
							<ModalHeader toggle={this.liveEntryModal.bind(this)}>Live Entry</ModalHeader>
							<ModalBody>
								{
									this.state.matchCard != null ?
										<div>
											<div className="col-lg-12">
												<div className="form-group">
													{this.state.channelId?.split("~")[1] != this.state.groupName && this.state.groupName?.length > 0 && this.state.groupName?.length <= 20 && <button onClick={() => this.enableLiveEntry()} title="Join Channel" className="btn btn-circle text-secondary btn-icon mr-2 float-right">
														<div className="tx-20"><i className="icon far fa-link"></i></div>
													</button>}
													<label className="form-control-label">Connection Name <span className={`${this.state.groupName?.length > 20 && "tx-error"}`}>{this.state.groupName?.length}/20</span></label>
													<input className="form-control" type="text" name="name" value={this.state.groupName ?? ""} onChange={e => this.setState({ groupName: e.currentTarget.value})} placeholder="Enter connection group name" />
												</div>
												<br/>
												<div className="form-group">
													<div className="list-group list-group-default">
														{
															this.state.groupNames && this.state.groupNames?.map(t => {
																return (
																	<div key={`league_${t.key}`} className="list-group-item">
																		<div className="list-title">
																			<p>{t.key?.split("~")[1]}</p>
																			<span>Active Connection: {t.value}</span>
																		</div>
																		<div className="list-status-wrapper mr-3">
																		</div>
																		<div className="list-btn-wrapper">
																			{this.state.channelId != t.key && <button onClick={() => this.setState({groupName: t.key?.split("~")[1]}, () => {this.enableLiveEntry()})} title="Join Channel" className="btn btn-circle text-secondary btn-icon mr-2">
																				<div className="tx-20"><i className="icon far fa-link"></i></div>
																			</button>}
																			{this.state.channelId == t.key && <button onClick={() => this.disableLiveEntry()} title="Leave Channel" className="btn btn-circle text-secondary btn-icon mr-2">
																				<div className="tx-20"><i className="icon far fa-unlink"></i></div>
																			</button>}
																		</div>
																	</div>
																);
															})
														}
													</div>
												</div>
											</div>
										</div> : undefined
								}
							</ModalBody>
							<ModalFooter>
								{this.state.channelId != null && <button className="btn btn-md btn-primary btn-rounded" onClick={() => this.setState({matchStatus: {...this.state.matchStatus, started: true, id: this.matchId}}, () => {ScoringHubService.instance.sendMatchStatus(this.state.matchStatus); this.liveEntryModal();})}>Start Match</button>}
								<a href={`/league/${this.state.matchCard.match.competitionId}/division/${this.state.matchCard.match.divisionId}/scoreboard`} target="_blank" className="btn btn-md btn-secondary btn-rounded">Scoreboard</a>
								<button className="btn btn-md btn-secondary btn-rounded" onClick={this.liveEntryModal.bind(this)}>Close</button>
							</ModalFooter>
						</Modal>
						:undefined
				}
			</div>
		)
	}

	public Entry = () => {
		const editable = (this.checkEntry() || AppConfig.entry === EntryType.manage) && !this.state.competition.archived;
		return (
			<div className="container matchcard">
				<div className="slim-pageheader">
					{<div className="slim-pageactions">
						<button onClick={History.goBack} title="Back" className="btn btn-circle text-secondary btn-icon mr-2">
							<div className="tx-20"><i className="icon fas fa-chevron-left"></i></div>
						</button>
						<ShowMe entry={EntryType.manage}>
							<a href={`/league/${this.state.matchCard.match.competitionId}/division/${this.state.matchCard.match.divisionId}/match/${this.state.matchCard.match.id}`} target="_blank" className="btn btn-circle btn-icon mr-2">
								<div className="tx-20"><i className="icon fal fa-rocket"></i></div>
							</a>
						</ShowMe>
						{
							this.state.matchCard.match.approved && this.state.matchCard.match.entry !== MatchCardEntryLevel.None ?
								<button onClick={this.matchReportModal.bind(this)} title="Match Report" className="btn btn-circle btn-primary btn-icon mr-2">
									<div className="tx-20"><i className="icon far fa-clipboard"></i></div>
								</button> : undefined
						}
						{editable ?
							<>
								{
									(!this.state.matchCard.match.submitted && this.isReady()) ?
										<button onClick={this.submit.bind(this)} title="Submit" className="btn btn-circle bg-success text-white btn-icon mr-2">
											<div className="tx-20"><i className="icon fas fa-file-upload"></i></div>
										</button> : undefined
								}

								<Can rules={["manage:full:?", "manage:leagues:?", "manage:results:?", "manage:approve:?"]} entryRules={[MatchCardEntryLevel.Approve, MatchCardEntryLevel.Full]} entry={this.state.matchCard.match.entry}>
									{
										this.state.matchCard.match.submitted && !this.state.matchCard.match.approved ?
											<button onClick={this.approve.bind(this)} title="Approve" className="btn btn-circle bg-success text-white btn-icon mr-2">
												<div className="tx-20"><i className="icon fas fa-check"></i></div>
											</button> : undefined
									}
								</Can>
								<Can rules={["manage:full:?", "manage:leagues:?", "manage:results:?"]} entryRules={[MatchCardEntryLevel.Approve, MatchCardEntryLevel.Full]} entry={this.state.matchCard.match.entry}>
									{
										!this.state.matchCard.match.approved &&
										<button onClick={this.toggleEditModal()} title="Edit" className="btn btn-circle text-gray-800 btn-icon mr-2">
											<div className="tx-20"><i className="icon far fa-pencil"></i></div>
										</button>
									}
									{
										this.state.matchCard.match.submitted && !this.state.matchCard.match.approved && !this.state.matchCard.match.overridden ?
											<button onClick={this.override.bind(this)} title="Unlock" className="btn btn-circle bg-gray-300 text-gray-800 btn-icon mr-2">
												<div className="tx-20"><i className="icon far fa-unlock-alt"></i></div>
											</button> : undefined
									}
									{
										this.state.matchCard.match.approved ?
											<button onClick={this.potmModal.bind(this)} title="Player of the Match" className="btn btn-circle btn-success btn-icon mr-2">
												<div className="tx-20"><i className="icon far fa-crown"></i></div>
											</button> : undefined
									}
								</Can>
								{
									this.state.matchCard.match.submitted && this.state.matchCard.match.overridden ?
										<button onClick={this.override.bind(this)} title="Lock" className="btn btn-circle bg-gray-300 text-gray-800 btn-icon mr-2">
											<div className="tx-20"><i className="icon far fa-lock-alt"></i></div>
										</button> : undefined
								}
								{/* <Link to={`/leagues/${this.state.competition.id}/edit`} className="btn btn-circle btn-icon mr-2" title="Edit"><div className="tx-20"><i className="icon far fa-edit"></i></div></Link> */}

								{
									this.state.matchCard.match.forfeit || this.state.matchCard.match.submitted || this.state.matchCard.match.abandoned ? undefined :
										<button className="btn btn-circle btn-icon mr-2" title="Forfeit Match" onClick={this.forfeitEntrantModal.bind(this)}><div className="tx-20"><i className="icon far fa-ghost"></i></div></button>

								}
								<Can rules={["manage:full:?", "manage:leagues:?", "manage:results:?"]}>
									<div className="dropdown d-inline">
										<a href="#" className="btn btn-circle bg-white btn-icon" data-toggle="dropdown">
											<div className="tx-20"><i className="icon far fa-ellipsis-v"></i></div>
										</a>
										<div className="dropdown-menu dropdown-menu-right">

											{
												this.state.matchCard.match.submitted ? <a className="dropdown-item" onClick={this.reject.bind(this)}>Reject</a> : undefined
											}
											{
								 				this.state.matchCard.match.submitted ? <a className="dropdown-item" onClick={this.veto.bind(this)}>Veto</a> : undefined
											}
											{
												this.state.matchCard.match.forfeit || this.state.matchCard.match.submitted || this.state.matchCard.match.abandoned ? undefined :
												<Can rules={["manage:full:?", "manage:leagues:?"]} entryRules={[MatchCardEntryLevel.Full]} entry={this.state.matchCard.match.entry}>
													<a className="dropdown-item clickable" onClick={this.abandonedModal.bind(this)}>Abandon</a>
												</Can>
											}
											<a className="dropdown-item clickable" onClick={this.reset.bind(this)}>Reset</a>
											<a className="dropdown-item clickable" onClick={() => this.liveEntryModal()}>Live Entry {this.state.liveEntry && <i className="icon far fa-check"></i>}</a>
										</div>
									</div>
								</Can>
								{
									this.state.matchCard.match.submitted ?
										<Can rules={["manage:approve:?", "manage:submit:?"]}>
											<div className="dropdown d-inline">
												<a href="#" className="btn btn-circle bg-white btn-icon" data-toggle="dropdown">
													<div className="tx-20"><i className="icon far fa-ellipsis-v"></i></div>
												</a>
												<div className="dropdown-menu dropdown-menu-right">
													{
														this.state.matchCard.match.submitted ? <a className="dropdown-item btn" onClick={this.veto.bind(this)}>Undo</a> : null
													}
												</div>
											</div>
										</Can> : undefined

								}
							</> : undefined}
					</div>
					}

					<div>
						<h6 className="slim-pagetitle">Match Card</h6>
					</div>
				</div>
				<div>
					<div className="row">
						<div className="col-12">
							{
								this.state.matchCard.match.approved ?
									this.state.matchCard.match.abandoned ?
										<div className="alert alert-success" role="alert">
											<strong>Match Abandoned!</strong> Matchcard abandoned by <span>{this.state.matchCard.match.approvedBy?.name}</span>.
										</div> :
										<div className="alert alert-success" role="alert">
											<strong>Match Completed!</strong> Matchcard approved by <span>{this.state.matchCard.match.approvedBy?.name}</span>.
										</div> : undefined
							}
							{
								this.state.matchCard.match.rejected ?
									<div className="alert alert-danger" role="alert">
										<strong>Match Disputed!</strong> Matchcard rejected by <span>{this.state.matchCard.match.rejectedBy?.name}</span>.
									</div> : undefined
							}
							{
								this.state.matchCard.match.submitted && !this.state.matchCard.match.approved && !this.state.matchCard.match.rejected ?
									<div className="alert alert-warning" role="alert">
										<strong>Awaiting Approval!</strong> Matchcard submitted by <span>{this.state.matchCard.match.submittedBy?.name}</span>.
									</div> : undefined
							}
							{
								!this.state.matchCard.match.submitted && this.isReady() ?
									<div className="alert alert-info" role="alert">
										<strong>Results Entered!</strong> Matchcard ready to submit.
									</div> : undefined
							}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="card pd-25">
								<div className="slim-card-title">Match Overview</div>
								<div className="media-list mg-t-25">
									<div className="row">
										<div className="col-md-4">
											<div className="media">
												<div><i className="icon fal fa-users tx-24 lh-0"></i></div>
												<div className="media-body mg-l-15 mg-t-4">
													<h6 className="tx-14 tx-gray-700">Entrants</h6>
													<ShowMe entry={EntryType.manage}>
														<Link to={`/leagues/${this.state.competition.id}/teams/${this.state.matchCard.match.home.teamId}/members`} className="d-inline">{this.state.matchCard.match.home.displayName}</Link> <span>vs</span> <Link to={`/leagues/${this.state.competition.id}/teams/${this.state.matchCard.match.away.teamId}/members`} className="d-inline">{this.state.matchCard.match.away.displayName}</Link>
													</ShowMe>
													<ShowMe entry={EntryType.public}>
														<span className="d-inline">{this.state.matchCard.match.home.displayName}</span> <span>vs</span> <span className="d-inline">{this.state.matchCard.match.away.displayName}</span>
													</ShowMe>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="media">
												<div><i className="icon fal fa-table-tennis tx-24 lh-0"></i></div>
												<div className="media-body mg-l-15 mg-t-4">
													<h6 className="tx-14 tx-gray-700">Competition</h6>
													<span>{this.state.competition.name} - {this.state.division.name}</span>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="media">
												<div><i className="icon fal fa-calendar-alt tx-24 lh-0"></i></div>
												<div className="media-body mg-l-15 mg-t-4">
													<h6 className="tx-14 tx-gray-700">Date</h6>
													{
														moment(this.state.matchCard.match.date).isValid() ?
															<span className="d-block">{moment(this.state.matchCard.match.date).format("Do MMMM YYYY")} {this.state.matchCard.match.time ? `at ${moment(this.state.matchCard.match.time).format("H:mm")}` : ""}</span> :
															<span className="d-block">Awaiting Date</span>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{
						this.state.matchCard.match.home.score > 0 || this.state.matchCard.match.away.score > 0 ?
							<div className="card mg-t-20 pd-25">
								<div className="slim-card-title">Match Result</div>
								<div className="row">
									<div className="col-lg-6 text-center">
										<h2>{this.state.matchCard.match.home.score}</h2>
										{this.state.matchCard.match.submitted && (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.PointsAwarded ? <p className="m-0">points</p> : undefined}
										<p className="m-0">{this.state.matchCard.match.home.points && this.state.matchCard.match.submitted && (this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) != ResultDisplay.PointsAwarded ? `${this.state.matchCard.match.home.points} points` : undefined}</p>
									</div>
									<div className="col-lg-6 text-center">
										<h2>{this.state.matchCard.match.away.score}</h2>
										{this.state.matchCard.match.submitted && (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.PointsAwarded ? <p className="m-0">points</p> : undefined}
										<p className="m-0"> {this.state.matchCard.match.away.points && this.state.matchCard.match.submitted && (this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) != ResultDisplay.PointsAwarded ? `${this.state.matchCard.match.away.points} points` : undefined}</p>
									</div>
								</div>
								{
									this.state.matchCard.match.potm ? <div className="row">
										<div className="col-6 offset-3 text-center">
											<p className="m-0">Player of the Match</p>
											<h5>{this.state.matchCard.match.potm.name}</h5>
										</div>
									</div> : undefined
								}
							</div> : undefined
					}
					<div>
						<div className="card mg-t-20" style={{ overflow: "visible" }}>
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										{
											this.homePlayers.map(p => {
												if (p.userId || p.forfeit) {
													return (
														<div key={`homeplayer_${p.id}`} className="form-group">
															<h6 className="tx-14 tx-gray-700">Player {this.label(p.ordering, true, this.homePlayers.length)}</h6>
															<p className="tx-medium">
																{
																	p.userId ?
																		<span>{p.name} {this.state.competition.showReserves && !this.state.matchCard.match.home.members.some(m => m.memberId === p.userId) ? <i className="far fa-arrow-circle-up text-info"></i> : undefined}</span> :
																		<span>{p.forfeitReason ? p.forfeitReason : "Forfeit"}</span>
																}
																{
																	this.state.matchCard.match.approved || this.state.competition.archived != null || !this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null) ? undefined :
																		<button className="btn btn-icon text-primary float-right" type="button" title="Clear" onClick={this.updateMatchPlayer.bind(this, p.id, "")}><i className="fas fa-times"></i></button>
																}
															</p>
														</div>
													);
												} else {
													return (
														<div key={`homeplayers_${p.id}`} className="form-group">
															<h6 className="tx-14 tx-gray-700">Player {this.label(p.ordering, true, this.homePlayers.length)}</h6>
															{!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null) || this.state.competition.archived != null || !editable ?
																<p className="tx-medium">No Player Selected</p>
																:
																<div className="row">
																	<div className="col-11 pd-r-0">
																		<AsyncSelect
																			formatOptionLabel={(option) => {
																				return (<p>{option.label}{option.reserve && <span className="float-right"><i className="far fa-arrow-circle-up mr-1"></i>{option.user.totalStepUp}</span>}</p>);
																			}}
																			defaultOptions={this.homePlayerOptions}
																			loadOptions={this.loadPlayerOptions.bind(this, true)}
																			isOptionDisabled={option => { return option.disabled; }}
																			closeMenuOnSelect={true}
																			onChange={this.handleChangeMatchPlayer.bind(this, p)} />
																	</div>
																	<div className="col-1 p-0 text-center">
																		<button className="btn p-1" title="Forfeit Player" onClick={this.forfeitPlayerModal.bind(this, p.id)}><span className="tx-20"><i className="far fa-ghost"></i></span></button>
																	</div>
																</div>
															}
														</div>
													);
												}
											})
										}
									</div>
									<div className="col-md-6">
										{
											this.awayPlayers.map(p => {
												if (p.userId || p.forfeit) {
													return (
														<div key={`homeplayer_${p.id}`} className="form-group">
															<h6 className="tx-14 tx-gray-700">Player {this.label(p.ordering, false, this.awayPlayers.length)}</h6>
															<p className="tx-medium">
																{
																	p.userId ?
																		<span>{p.name} {this.state.competition.showReserves && !this.state.matchCard.match.away.members.some(m => m.memberId === p.userId) && <i className="far fa-arrow-circle-up text-info"></i>}</span> :
																		<span>{p.forfeitReason ? p.forfeitReason : "Forfeit"}</span>
																}
																{
																	this.state.matchCard.match.approved || this.state.competition.archived != null || !this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null) ? undefined :
																		<button className="btn btn-icon text-primary float-right" type="button" title="Clear" onClick={this.updateMatchPlayer.bind(this, p.id, "")}><i className="fas fa-times"></i></button>
																}
															</p>
														</div>
													);
												} else {
													return (
														<div key={`homeplayers_${p.id}`} className="form-group">
															<h6 className="tx-14 tx-gray-700">Player {this.label(p.ordering, false, this.awayPlayers.length)}</h6>
															{!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null) || this.state.competition.archived != null || !editable ?
																<p className="tx-medium">No Player Selected</p>
																:
																<div className="row">
																	<div className="col-11 pd-r-0" >
																		<AsyncSelect
																			formatOptionLabel={(option) => {
																				return (<p>{option.label}{option.reserve && <span className="float-right"><i className="far fa-arrow-circle-up mr-1"></i>{option.user.totalStepUp}</span>}</p>);
																			}}
																			defaultOptions={this.awayPlayerOptions}
																			loadOptions={this.loadPlayerOptions.bind(this, false)}
																			isOptionDisabled={option => { return option.disabled; }}
																			closeMenuOnSelect={true}
																			onChange={this.handleChangeMatchPlayer.bind(this, p)} />
																	</div>
																	<div className="col-1 p-0 text-center">
																		<button className="btn p-1" title="Forfeit Player" onClick={this.forfeitPlayerModal.bind(this, p.id)}><span className="tx-20"><i className="far fa-ghost"></i></span></button>
																	</div>
																</div>
															}
														</div>
													);
												}
											})
										}
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<Doubles
											match={this.state.matchCard.match}
											competition={this.state.competition}
											division={this.state.division}
											onChange={this.handleChangeDoubles.bind(this)}
											onForfeit={this.forfeitPlayerModal.bind(this)}
											onUpdate={this.updateMatchPlayer.bind(this)}
											players={this.homeDoublePlayers}
											playing={this.state.matchCard.playing.home}
											entrant={this.state.matchCard.match.home}
											home={true}
											editable={editable}
											playerLabels={this.state.competition.matchCard.playerLabels} />
									</div>
									<div className="col-md-6">
										<Doubles
											match={this.state.matchCard.match}
											competition={this.state.competition}
											division={this.state.division}
											onChange={this.handleChangeDoubles.bind(this)}
											onForfeit={this.forfeitPlayerModal.bind(this)}
											onUpdate={this.updateMatchPlayer.bind(this)}
											players={this.awayDoublePlayers}
											playing={this.state.matchCard.playing.away}
											entrant={this.state.matchCard.match.away}
											home={false}
											editable={editable}
											playerLabels={this.state.competition.matchCard.playerLabels} />
									</div>
								</div>
								{this.state.competition.teamHandicap && <div>
									<div className="slim-card-title">Handicaps</div>
									<div className="row" onClick={() => {editable && !this.state.matchCard.match.submitted && this.setState({editHandicaps: true})}}>
										<div className="col-lg-6 text-center">
											<h2>{this.state.matchCard.match.homeHandicap != null ? this.state.matchCard.match.homeHandicap : '-'}</h2>
										</div>
										<div className="col-lg-6 text-center">
											<h2>{this.state.matchCard.match.awayHandicap != null ? this.state.matchCard.match.awayHandicap : '-'}</h2>
										</div>
									</div>
								</div>}
							</div>
						</div>
						<div className="mg-t-20 mg-b-20">
							<h6 className="slim-pagetitle">Sets</h6>
						</div>
						<div>
							{
								this.matchCard.sets.map(s => {
									const home = s.players.filter(p => p.entrantId === this.matchCard.match.home.id);
									const away = s.players.filter(p => p.entrantId === this.matchCard.match.away.id);

									return (
										<div className={`card mg-t-20 layout-${this.state.competition.matchCard.layout}`}>
											<div className="card-body">
												<div className="row">
													{s.players.some(p => p.scratch != null) && <span><i className="icon far fa-claw-marks"></i></span>}
													{editable ? <div className={`col-lg-3 col-md-8 name`} title={this.scratchInfo(s)}>
														<div className="row">
															<div className="col">
																<button className="btn w-100" onClick={this.select.bind(this, s)}>
																	{home.length === 1 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={this.homePlayers.length} set={s} player={home[0]} home={true} /> : undefined}
																	{home.length === 2 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={home.every(h => h.type == 3) ? this.homePlayers.length : this.homeDoublePlayers.length} set={s} players={home} home={true} /> : undefined}
																</button>
															</div>
														</div>
														<div className="row">
															<div className="col">
																<button className="btn w-100" onClick={this.select.bind(this, s)}>
																	{away.length === 1 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={this.awayPlayers.length} set={s} player={away[0]} home={false} /> : undefined}
																	{away.length === 2 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={away.every(a => a.type == 3) ? this.awayPlayers.length : this.awayDoublePlayers.length} set={s} players={away} home={false} /> : undefined}
																</button>
															</div>
														</div>
													</div>
													:
													<div className={`col-lg-3 col-md-8 name`} title={this.scratchInfo(s)}>
														<div className="row">
															<div className="col">
																<div className="btn w-100">
																	{home.length === 1 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={this.homePlayers.length} set={s} player={home[0]} home={true} /> : undefined}
																	{home.length === 2 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={home.every(h => h.type == 3) ? this.homePlayers.length : this.homeDoublePlayers.length} set={s} players={home} home={true} /> : undefined}
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col">
																<div className="btn w-100">
																	{away.length === 1 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={this.awayPlayers.length} set={s} player={away[0]} home={false} /> : undefined}
																	{away.length === 2 ? <Player playerLabels={this.state.competition.matchCard.playerLabels} playerCount={away.every(a => a.type == 3) ? this.awayPlayers.length : this.awayDoublePlayers.length} set={s} players={away} home={false} /> : undefined}
																</div>
															</div>
														</div>
													</div>}
													<div className="col-lg-7 games">
														<div className="games--container row">
															{
																s.games.map(g => {
																	if (this.state.competition.matchCard.layout == 2) {
																		return (!s.completed || (s.completed && !ObjectHelper.isNull(g.home) && !ObjectHelper.isNull(g.away)) ?
																			<div className="col-md-1 game-vertical">
																				<div className="row">
																					<input tabIndex={s.games.findIndex(t => t == g) * 2 + this.matchCard.sets.findIndex(f => f == s) * this.state.competition?.gamesPerSet * 2 + 1} type="number" className={`d-inline form-control ${this.isValid(g, s) ? "" : !g.failed ? "invalid" : "failed"}`} title={`Game ${g.ordering + 1} - Home`} value={g.home != null ? g.home.toString() : ""} onChange={e => this.updateScore(g, s, e.currentTarget.value, true)} onBlur={e => this.updateGame(g, s, e.currentTarget.value, true)} disabled={!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null || s.locked) || this.state.competition.archived != null || !editable || !this.isOrdered(g, s)} />
																				</div>
																				<div className="row">
																					<input tabIndex={s.games.findIndex(t => t == g) * 2 + this.matchCard.sets.findIndex(f => f == s) * this.state.competition?.gamesPerSet * 2 + 2} type="number" className={`d-inline form-control ${this.isValid(g, s) ? "" : !g.failed ? "invalid" : "failed"}`} title={`Game ${g.ordering + 1} - Away`} value={g.away != null ? g.away.toString() : ""} onChange={e => this.updateScore(g, s, e.currentTarget.value, false)} onBlur={e => this.updateGame(g, s, e.currentTarget.value, false)} disabled={!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null || s.locked) || this.state.competition.archived != null || !editable || !this.isOrdered(g, s)} />
																				</div>
																			</div>
																			: undefined);
																	}
																	else /* if(this.state.competition.matchCard.layout == 1) */ {
																		return (!s.completed || (s.completed && !ObjectHelper.isNull(g.home) && !ObjectHelper.isNull(g.away)) ?
																			(!this.state.liveEntry ? 
																			<div className="d-inline game">
																				<input tabIndex={s.games.findIndex(t => t == g) * 2 + this.matchCard.sets.findIndex(f => f == s) * this.state.competition?.gamesPerSet * 2 + 1} type="number" className={`d-inline form-control ${this.isValid(g, s) ? "" : !g.failed ? "invalid" : "failed"}`} title={`Game ${g.ordering + 1} - Home`} value={g.home != null ? g.home.toString() : ""} onChange={e => this.updateScore(g, s, e.currentTarget.value, true)} onBlur={e => this.updateGame(g, s, e.currentTarget.value, true)} disabled={!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null || s.locked) || this.state.competition.archived != null || !editable || !this.isOrdered(g, s)} />
																				<input tabIndex={s.games.findIndex(t => t == g) * 2 + this.matchCard.sets.findIndex(f => f == s) * this.state.competition?.gamesPerSet * 2 + 2} type="number" className={`d-inline form-control ${this.isValid(g, s) ? "" : !g.failed ? "invalid" : "failed"}`} title={`Game ${g.ordering + 1} - Away`} value={g.away != null ? g.away.toString() : ""} onChange={e => this.updateScore(g, s, e.currentTarget.value, false)} onBlur={e => this.updateGame(g, s, e.currentTarget.value, false)} disabled={!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null || s.locked) || this.state.competition.archived != null || !editable || !this.isOrdered(g, s)} />
																			</div>
																			:
																			<div className="d-inline game">
																				<input tabIndex={s.games.findIndex(t => t == g) * 2 + this.matchCard.sets.findIndex(f => f == s) * this.state.competition?.gamesPerSet * 2 + 1} type="number" className={`d-inline form-control ${this.isValid(g, s) ? "" : !g.failed ? "invalid" : "failed"}`} title={`Game ${g.ordering + 1} - Home`} value={g.home != null ? g.home.toString() : ""} onClick={() => {this.matchGameModal(g,s)}} disabled={!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null || s.locked) || this.state.competition.archived != null || !editable || !this.isOrdered(g, s)} />
																				<input tabIndex={s.games.findIndex(t => t == g) * 2 + this.matchCard.sets.findIndex(f => f == s) * this.state.competition?.gamesPerSet * 2 + 2} type="number" className={`d-inline form-control ${this.isValid(g, s) ? "" : !g.failed ? "invalid" : "failed"}`} title={`Game ${g.ordering + 1} - Away`} value={g.away != null ? g.away.toString() : ""} onClick={() => {this.matchGameModal(g,s)}} disabled={!this.state.matchCard.match.overridden && !this.state.matchCard.match.rejected && (this.state.matchCard.match.submitted != null || s.locked) || this.state.competition.archived != null || !editable || !this.isOrdered(g, s)} />
																			</div>)
																			: undefined);
																	}
																})
															}
														</div>
													</div>
													<div className="col-lg-1 col-md-12 text-center score">
														{(this.state.division.resultDisplay ?? this.state.competition.resultDisplay) !== ResultDisplay.Points ? <p>
															{s.homeScore != null && s.awayScore != null ? <span>{s.homeScore} - {s.awayScore}</span> : <span>-</span>}
														</p>
														:
														<p>
															{s.games.filter(g => g.home != null && g.away != null).length > 0 ? <span>{s.games.reduce((a, b) => {a += b.home; return a}, 0)} - {s.games.reduce((a, b) => {a += b.away; return a}, 0)}</span> : <span>-</span>}
														</p>}
													</div>
												</div>
											</div>
										</div>);
								})
							}
							{this.state.competition.teamHandicap && <div className={`card mg-t-20`}>
								<div className="card-body">
									<div className="row">
										<div className="col-lg-10 games">
											<p className="tx-medium">Net Handicap</p>
										</div>
										<div className="col-lg-1 col-md-12 text-center score">
											<p>
												{this.matchCard.match.homeHandicap != null && this.matchCard.match.awayHandicap != null ? (this.matchCard.match.homeHandicap > this.matchCard.match.awayHandicap ? <span>{this.matchCard.match.homeHandicap - this.matchCard.match.awayHandicap} - 0</span> : <span>0 - {this.matchCard.match.awayHandicap - this.matchCard.match.homeHandicap}</span>) : <span>-</span>}
											</p>
										</div>
									</div>
								</div>
							</div>}
							{
								(this.state.matchCard.match.home.score > 0 || this.state.matchCard.match.away.score > 0) && !this.state.matchCard.match.submitted ?
									<div className="card mg-t-20 pd-25">
										<div className="slim-card-title">Match Result</div>
										<div className="row">
											<div className="col-lg-6 text-center">
												<h2>{this.state.matchCard.match.home.score}</h2>
											</div>
											<div className="col-lg-6 text-center">
												<h2>{this.state.matchCard.match.away.score}</h2>
											</div>
										</div>
									</div> : undefined
							}
						</div>
						{editable ?
							<div className="section-footer mg-t-20 text-center">
								{
									!this.state.matchCard.match.submitted && this.isReady() ?
										<ProgressButton loading={this.state.submitting} className="btn btn-outline btn-primary btn-md btn-rounded mr-2" onClick={this.submit.bind(this)}>Submit</ProgressButton> : undefined
								}
								<Can rules={["manage:full:?", "manage:leagues:?", "manage:results:?", "manage:approve:?"]}>
									{
										this.state.matchCard.match.submitted && !this.state.matchCard.match.approved && this.state.matchCard.match.entry >= MatchCardEntryLevel.Approve ?
											<ProgressButton loading={this.state.submitting} className="btn btn-outline btn-primary btn-md btn-rounded mr-2" onClick={this.approve.bind(this)}>Approve</ProgressButton> : null
									}
								</Can>
								{/* <ProgressButton loading={false} className="btn btn-outline btn-primary btn-md btn-rounded mr-2" onClick={() => {MiscHelper.scrollToTop()}}>Back To Top</ProgressButton> */}
							</div>
							: undefined
						}
					</div>
				</div>
			</div>
		)
	}
}

interface IPlayerProps {
	playerLabels: PlayerLabels;
	playerCount: number;
	set: MatchSet;
	player?: MatchPlayer;
	players?: MatchPlayer[];
	home: boolean;
}

export class Player extends React.Component<IPlayerProps, any> {

	private won: boolean;

	constructor(props) {
		super(props);

		this.won = this.hasWon();
	}

	componentDidMount() {

	}

	componentDidUpdate(props: IPlayerProps, state: any) {
		this.update();
	}

	update() {
		if (this.props.set.completed) {
			const won = this.hasWon();
			if (won != this.won) {
				this.won = won;
				this.setState({});
			}
		}
		else {
			if (this.won) {
				this.won = false;
				this.setState({});
			}
		}
	}

	hasWon(): boolean {
		const won = this.props.set.completed && ((this.props.home && (this.props.set.homeScore > this.props.set.awayScore)) ||
			(!this.props.home && (this.props.set.awayScore > this.props.set.homeScore)));

		return won;
	}

	label(ordering) {
		var alpha = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
		var index = 0;
		if (this.props.playerLabels == PlayerLabels.Alphabetical) {
			if (this.props.home)
				index = ordering;
			else
				index = (26 - this.props.playerCount) + ordering;
			return alpha[index];
		}
		else {
			return ordering + 1;
		}
	}

	render() {
		return (
			<p>
				{this.props.player ? <span>Player {this.label(this.props.player.ordering)} - <span className={`${this.won ? "tx-bold" : ""}`}>{this.props.player.name}</span></span> : undefined}
				{this.props.players ? <span>{this.props.players[0].userId && this.props.players[1].userId ? <span className={`${this.won ? "tx-bold" : ""}`}>{this.props.players[0].name} & {this.props.players[1].name}</span> : <span className={`${this.won ? "tx-bold" : ""}`}>Doubles {this.label(this.props.players[0].ordering)}{this.props.players[1].ordering != this.props.players[0].ordering && this.label(this.props.players[1].ordering)} - {this.props.players[0].name}</span>}</span> : undefined}
				{this.won ? <span className="tx-success tx-10 mg-l-5"><i className="fa fa-circle"></i></span> : undefined}
			</p>
		);
	}
}

interface IDoubleProps {
	match: Match;
	competition: Competition;
	division: Division;
	entrant: MatchEntrant;
	players: any[];
	playing: MatchPlayer[];
	playerLabels: PlayerLabels;
	home: boolean;
	editable: boolean;
	onChange: (member: MatchMember) => void;
	onUpdate: (playerId: number, member: string) => void;
	onForfeit: (playerId: number) => void;
}

export class Doubles extends React.Component<IDoubleProps, any> {

	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	componentDidUpdate(props: IDoubleProps, state: any) {
	}

	loadPlayerOptions(players: SelectOption<string>[], value: string): Promise<SelectOption<string>[]> {
		return new Promise(resolve => {
			if ((this.props.competition.reserves.type === 1 || (this.props.division.reserves ? this.props.division.reserves.overrideEnabled : this.props.competition.reserves.overrideEnabled)) && value) {
				MemberService.search({
					search: value,
					competitionId: this.props.competition.id,
					email: false,
					player: false
				}).then(members => {
					const options = members.map((t) => ({ label: t.name, value: t.id }));
					resolve(options);
				});
			} else {
				const options = players.filter(p => p.label.indexOf(value) >= 0);
				resolve(options);
			}
		});
	}

	getOptions(existing: string[]) {
		const playing = this.props.playing.filter(p => (p.type == 1 || p.type == 3) && p.userId);
		const members = this.props.entrant.members;
		const reserves = this.props.entrant.reserves;

		const options = [
			{
				label: "Playing",
				options: [] = playing.filter(m => existing.indexOf(m.userId) < 0).map(m => ({ label: `${m.name}`, value: m.userId }))
			},
			{
				label: "Team Members",
				options: [] = members.filter(m => existing.indexOf(m.memberId) < 0).map(m => ({ label: `${m.name}`, value: m.memberId }))
			},
			{
				label: "Reserves",
				options: [] = reserves.map(m => ({ reserve: true, label: `${m.team} - ${m.name}  (${m.totalStepUp})`, value: m.memberId, diabled: m.disabled }))
			},
			{
				label: "Other",
				options: [] = []
			}
		];

		return options;
	}

	label(ordering) {
		return ordering + 1;
	}

	render() {
		return (
			<div>
				{
					this.props.players.map(d => {
						const ordering = (d[0].ordering);
						const memberIds = d.filter(p => p.userId !== "").map(p => p.userId);
						return (
							<div key={`doubles-${d[0].id}`} className="mb-3">
								<h6 className="tx-14 tx-gray-700">Doubles {this.label(ordering)}</h6>
								{
									d.map(p => {
										if (p.userId || p.forfeit) {
											return (
												<p key={`doubles-player-${p.id}`} className="tx-medium mb-1">
													{
														p.userId ?
															<span>{p.name} {this.props.competition.showReserves && !this.props.entrant.members.some(m => m.memberId === p.userId) && <i className="far fa-arrow-circle-up text-info"></i>}</span> :
															<span>{p.forfeitReason ? p.forfeitReason : "Forfeit"}</span>
													}
													{
														this.props.match.approved || this.props.competition.archived != null || !this.props.match.overridden && !this.props.match.rejected && (this.props.match.submitted != null) ? undefined :
															<button className="btn btn-icon text-primary float-right" title="Clear" type="button" onClick={this.props.onUpdate.bind(this, p.id, "")}><i className="fas fa-times"></i></button>
													}
												</p>
											);
										} else if (p.fixed) {
											return (<p>Player {p.ordering}</p>);
										} else {
											return (
												<div className="form-group">
													{!this.props.match.overridden && !this.props.match.rejected && (this.props.match.submitted != null) || this.props.competition.archived != null || !this.props.editable ?
														<p className="tx-medium">No Player Selected</p>
														:
														<div className="row">
															<div className="col-11 pd-r-0">
																<AsyncSelect
																	defaultOptions={this.getOptions(memberIds)}
																	closeMenuOnSelect={true}
																	loadOptions={this.loadPlayerOptions.bind(this, this.getOptions(memberIds))}
																	isOptionDisabled={option => { return option.disabled; }}
																	onChange={this.props.onChange.bind(this, p)} />
															</div>
															<div className="col-1 p-0 text-center">
																<button className="btn p-1" title="Forfeit Player" onClick={this.props.onForfeit.bind(this, p.id)}><span className="tx-20"><i className="far fa-ghost"></i></span></button>
															</div>
														</div>
													}
												</div>
											);
										}
									})
								}
							</div>
						);
					})
				}
			</div>
		);
	}
}
