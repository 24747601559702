import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DelayInput } from "react-delay-input";
import { Options } from "@data";
import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import * as moment from "moment";
import { SelectOption } from "@models/forms";
import ObjectHelper from "@helpers/object.helper";
import { Format } from "@models/format";
import { ArrayHelper } from "@helpers/array.helper";
import { FormatService } from "@services/format.service";
import { Competition, CompetitionDay } from "@models/competition";
import DayPickerInput from "react-day-picker/DayPickerInput";
import TimePicker from "rc-time-picker";
import { Division } from "@models/division";
import { BookingService } from "@services/booking.service";
import { Booking, BookingResource, BookingSpace } from "@models/booking";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { Standing } from "@models/standing";
import { Link } from "react-router-dom";


interface IDivisionStandings {
	standings: Standing[];
	division: Division;
	competition: Competition;
	onAjustmentClick?: (entrantId: number) => void;
}

export default (props: IDivisionStandings) => {
	const [competition, setCompetition] = useState<Competition>(props.competition);
	const [division, setDivision] = useState<Division>(props.division);
	const [standings, setStandings] = useState<Standing[]>(props.standings);

	if (standings.some(s => props.standings.every(p=> p.entrantId != s.entrantId ))){
		setStandings(props.standings)
	}
	
	return (
		<div className="standings w-100">
			<div>
				<div className="table-responsive">
					<div>
						<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
							<thead>
								<tr role="row">
									<th className="data-rank sorting" rowSpan={1} colSpan={1} aria-label="Pos">#</th>
									<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Club">Team</th>
									{competition.table.winLossDraw ? <th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="P">P</th> : ""}
									{competition.table.winLossDraw ? <th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="W">W</th> : ""}
									{competition.table.winLossDraw ? <th className="data-d sorting" rowSpan={1} colSpan={1} aria-label="D">D</th> : ""}
									{competition.table.winLossDraw ? <th className="data-l sorting" rowSpan={1} colSpan={1} aria-label="L">L</th> : ""}
									{competition.table.setsForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="SF">{competition.table.setAlternative ? "MF" : "SF"}</th> : ""}
									{competition.table.setsForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="SA">{competition.table.setAlternative ? "MA" : "SA"}</th> : ""}
									{competition.table.setsRatio ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="SR">{competition.table.setAlternative ? "MR" : "SR"}</th> : ""}
									{competition.table.gamesForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="GF">GF</th> : ""}
									{competition.table.gamesForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="GA">GA</th> : ""}
									{competition.table.gamesRatio ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="SR">GR</th> : ""}
									{competition.table.pointsForAgainst ? <th className="d-none d-md-table-cell data-a sorting" rowSpan={1} colSpan={1} aria-label="PF">PF</th> : ""}
									{competition.table.pointsForAgainst ? <th className="d-none d-md-table-cell data-a sorting" rowSpan={1} colSpan={1} aria-label="PA">PA</th> : ""}
									{competition.table.pointsRatio ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="PR">PR</th> : ""}
									{competition.table.pointsAdjustments ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="ADJ">ADJ</th> : ""}
									<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">Pts</th>
								</tr>
							</thead>
							<tbody>
								{
									standings.map(s => {
										return (
											<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
												<td className="data-rank" data-label="Pos">{s.position}</td>
												<td className="data-name nowrap" data-label="Team"><Link to={`/league/${competition.id}/team/${s.teamId}`}>{s.name}</Link></td>
												{competition.table.winLossDraw ? <td className="data-p" data-label="P">{s.played}</td> : ""}
												{competition.table.winLossDraw ? <td className="data-w" data-label="W">{s.won}</td> : ""}
												{competition.table.winLossDraw ? <td className="data-d" data-label="D">{s.drawn}</td> : ""}
												{competition.table.winLossDraw ? <td className="data-l" data-label="L">{s.lost}</td> : ""}
												{competition.table.setsForAgainst ? <td className="d-none d-md-table-cell data-s" data-label="SF">{s.setsFor}</td> : ""}
												{competition.table.setsForAgainst ? <td className="d-none d-md-table-cell data-s" data-label="SA">{s.setsAgainst}</td> : ""}
												{competition.table.setsRatio ? <td className="d-none d-md-table-cell data-g" data-label="SR">{s.setsAgainst > 0 ? (s.setsFor / s.setsAgainst).toFixed(2) : s.setsFor.toFixed(2)}</td> : ""}
												{competition.table.gamesForAgainst ? <td className="d-none d-md-table-cell data-g" data-label="GF">{s.gamesFor}</td> : ""}
												{competition.table.gamesForAgainst ? <td className="d-none d-md-table-cell data-g" data-label="GA">{s.gamesAgainst}</td> : ""}
												{competition.table.gamesRatio ? <td className="d-none d-md-table-cell data-g" data-label="GR">{s.gamesAgainst > 0 ? (s.gamesFor / s.gamesAgainst).toFixed(2) : s.gamesFor.toFixed(2)}</td> : ""}
												{competition.table.pointsForAgainst ? <td className="d-none d-md-table-cell data-a" data-label="PF">{s.gamePointsFor}{/* s.points - s.adjustment */}</td> : ""}
												{competition.table.pointsForAgainst ? <td className="d-none d-md-table-cell data-a" data-label="PA">{s.gamePointsAgainst}{/* s.pointsAgainst */}</td> : ""}
												{competition.table.pointsRatio ? <td className="d-none d-md-table-cell data-g" data-label="PR">{s.gamePointsAgainst > 0 ? (s.gamePointsFor / s.gamePointsAgainst).toFixed(2) : (s.gamePointsFor).toFixed(2)}{/* s.pointsAgainst > 0 ? ((s.points - s.adjustment) / s.pointsAgainst).toFixed(2) : (s.points - s.adjustment).toFixed(2) */}</td> : ""}
												{competition.table.pointsAdjustments ? <td className="d-none d-md-table-cell data-a" data-label="ADJ"><a className="clickable" title="Click for details" onClick={() => props.onAjustmentClick(s.entrantId)}>{s.adjustment}</a></td> : ""}
												<td className="data-pts" data-label="PTS">{s.points}</td>
											</tr>
										);
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};