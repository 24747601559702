import { Editor } from 'tinymce';
import * as React from 'react';
import { Modal, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const AccordionPlugin = (props: { editor: Editor }) => {
  const [modalStauts, setModalStatus] = React.useState(true);
  const [numberOfAccordions, setNumberOfAccordions] = React.useState<number>();
  const { editor } = props;

  const addAccordion = () => {
    var accordionSet = [];
    var curAccordion = Date.now();
    var accordionCount = numberOfAccordions;
    for (var i = 0; i < accordionCount; i++) {
      var panel = '\n<div class="panel panel-default">\n<div class="panel-heading mceNonEditable productAccordion" role="tab" id="heading' +
        (curAccordion + i) + '">\n<h4 class="panel-title">\n<a role="button" data-toggle="collapse" class="mceEditable accordionHeader collapsed" data-parent="#accordion' +
        curAccordion + '" href="#collapse' + (curAccordion + i) + '" aria-expanded="true" aria-controls="collapse' + (curAccordion + i) +
        '">\nChange this header!\n</a>\n<a role="button" data-toggle="collapse" class="mceEditable collapsed" data-parent="#accordion' +
        curAccordion + '" href="#collapse' + (curAccordion + i) + '" aria-expanded="true" aria-controls="collapse' + (curAccordion + i) +
        '">\n<i class="fa fa-chevron-down">\n</i>\n<i class="fa fa-chevron-up">\n</i>\n</a>\n</h4>\n</div>\n<div id="collapse' + (curAccordion + i) +
        '" class="panel-collapse collapse mceNonEditable" role="tabpanel" aria-labelledby="heading' + (curAccordion + i) +
        '">\n<div class="panel-body mceEditable">\n<p>Change this content</p>\n</div>\n</div>\n</div>\n';
      accordionSet.push(panel);
    }

    var accordion = '\n<div class="panel-group accordion" id="accordion' + curAccordion +
      '" role="tablist" aria-multiselectable="true">' +
      '\n<span class="view-all">\nExpand All\n</span>' + 
      accordionSet.join('') + '\n</div>';
    editor.insertContent(accordion);
    setModalStatus(false);
  }

  const close = () => {
    setModalStatus(false);
  }

  return (
    <Modal isOpen={modalStauts} style={{ width: "400px" }}>
      <ModalHeader>Accordion</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Number of accordions</Label>
          <Input placeholder="Enter a number" type="number" onChange={e => setNumberOfAccordions(e.target.value)} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button className='btn btn-default' type="button" onClick={close}>Cancel</button>
        <button className='btn btn-primary' type="button" disabled={!numberOfAccordions || numberOfAccordions < 1} onClick={addAccordion}>Add</button>
      </ModalFooter>


    </Modal>
  );
};


export default AccordionPlugin;