import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { CreateTeam, Team, TeamMember } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CreateDivision, Division, DivisionProgress, DivisionStatus } from "@models/division";
import { Competition, CompetitionMember, CreateCompetition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { confirmAlert } from "react-confirm-alert";
import { MatchService } from "@services/match.service";

interface IDivisionItemProps {
    site?: string;
    match?: any;
    location?: any;
    division: Division;
    competition: Competition;
    loadDivisions?: () => void;
    manageEntrants?: (division: Division) => void;
}

export default (props: IDivisionItemProps) => {
    const [division, setDivision] = useState<Division>(props.division);
    const [progress, setProgress] = useState<number>();

    var interval: NodeJS.Timer;

    useEffect(() => {
        load();
        return () => {
            clearInterval(interval);
        }
    }, []);

    const load = async () => {
        if (props.competition != null) {
            if (division.status == DivisionStatus.Generating || division.status == DivisionStatus.GeneratingSecond) {
                interval = setInterval(() => {
                    updateProgress();
                }, 1000);
            }
        }

    };

    const handleCancelDivisionClick = () => (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>This will permanently delete this Division.</p>
                        <a className="btn btn-secondary text-white mr-2"
                            onClick={() => {
                                DivisionService.delete(division.id).then(() => {
                                    props.loadDivisions();
                                    onClose();
                                    toast.success("Division Removed");
                                });
                            }}
                        >
                            Yes, delete it!
                        </a>
                        <a className="btn btn-primary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const handleManageEntrants = () => () => {
        props.manageEntrants(division);
    };

    const generate = () => async () => {
        division.updated = new Date();
        MatchService.generate(division.id).then(result => { });
        if (division.status == DivisionStatus.New)
            division.status = DivisionStatus.Generating;
        else if (division.status == DivisionStatus.HalfGenerated)
            division.status = DivisionStatus.GeneratingSecond;
        setDivision(division);
        interval = setInterval(() => {
            updateProgress();
        }, 1000);
    };

    const updateProgress = () => {
        var tDivision = division;
        if (tDivision.status == DivisionStatus.Generating || tDivision.status == DivisionStatus.GeneratingSecond) {
            MatchService.progress(tDivision.id).then(result => {
                const current = progress;
                if ((current != null && current < 100 && result.percent === 100) || (result.status != DivisionStatus.Generating && result.status != DivisionStatus.GeneratingSecond)) {
                    tDivision.status = result.status;
                    setDivision(tDivision);
                    setProgress(null);
                    clearInterval(interval);
                }
                else {
                    setProgress(result.percent);
                }
            });
        }
    }

    return (
        <Col xs={12}>
            <Card className="mb-3 p-3">
                <Row>
                    <Col lg={5} className="d-flex align-self-center">
                        <div className="col">
                            <h6 className="m-0">{division.name}</h6>
                            <p className="m-0 small">{division.description}</p>
                            {progress && <Progress animated value={progress}>{progress}%</Progress>}
                        </div>
                    </Col>
                    <Col lg={(division.status == DivisionStatus.New || division.status == DivisionStatus.HalfGenerated) ? 1 : 3} className="d-flex justify-content-center align-self-center">

                    </Col>
                    <Col lg={(division.status == DivisionStatus.New || division.status == DivisionStatus.HalfGenerated) ? 6 : 4} className="d-flex justify-content-end align-self-center">
                        {division.hasEntrants && (division.status == DivisionStatus.New || division.status == DivisionStatus.HalfGenerated) && <a className="btn btn--circle text-white mr-2" onClick={generate()}><i className="fas fa-calendar-alt" /></a>}
                        <a className="btn btn--circle text-white mr-2" onClick={handleManageEntrants()}><i className="far fa-users-class" /></a>
                        <a className="btn btn--circle bg-danger text-white" onClick={handleCancelDivisionClick()}><i className="far fa-times" /></a>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
};