import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { MemberRegistration, Member, MemberSetup, MemberAccess, MemberDetails, Platform, CreateMember, SearchMember, MemberIdentity, MemberRoles } from "../models/member";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { SelectOption } from "@models/forms";

import browser from "browser-detect";

import RestHelper from "@helpers/rest.helper";
import { TeamCaptain } from "@models/team";
import { ConsentAgreementsFilterOptions, ContactFilterOptions, FilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

import * as qs from "query-string";
import { ContactLinkResult, Contact, ContactAccount, ContactBasic, ContactGuardianType, ContactLink, ContactLinkType, CreateContact, ContactCredits } from "@models/contact";
import { MembershipUser } from "@models/membership";
import { Booking, CalendarConflict, ContactCalendarTimeframe } from "@models/booking";
import { EventMember } from "@models/event";
import { Invoice } from "@models/invoice";
import { Consent, ConsentAgreement, CreateConsentAccept } from "@models/consent";

export class ContactService {

	constructor() {
		//
	}

	public static get(query: ContactFilterOptions): Promise<PagedList<Contact>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts`, {
				params: query,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getById(id: number): Promise<Contact> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getByIdsOld(contactIds: number[]): Promise<ContactBasic[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/contactIds`, contactIds).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getByIds(contactIds: number[]): Promise<ContactBasic[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `contacts/contactIds`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(contactIds),
				success: (data: ContactBasic[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByUserId(userId: string): Promise<Contact> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/user/${userId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getByUser(): Promise<Contact> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/user`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static delete(id: number): Promise<Contact> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`contacts/${id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static upsert(contact: Contact): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts`, contact).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static create(contact: CreateContact): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/create`, contact).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data);
			});
		});
	}

	public static register(contact: CreateContact): Promise<TokenApiModel> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/register`, contact).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject(error?.response?.data);
			});
		});
	}

	public static search(term: string, competitionId: number = null, email: boolean = false): Promise<ContactBasic[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/search`,
			{
				params: { search: term, competitionId, take: 20, email },
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getMemberships(contactId: number, options: FilterOptions): Promise<PagedList<MembershipUser>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/memberships`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getBookings(contactId: number, options: FilterOptions): Promise<PagedList<Booking>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/bookings`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getSessions(contactId: number, options: FilterOptions): Promise<PagedList<EventMember>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/sessions`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getInvoices(contactId: number, options: FilterOptions): Promise<PagedList<Invoice>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/invoices`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getConsents(userId: string, options: FilterOptions): Promise<PagedList<any>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${userId}/consents`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getLinked(contactId: number): Promise<ContactLink[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/linked`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static importFile(base64:string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/import`, {base64}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static registerExisting(contactId: number): Promise<Contact> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/${contactId}/register`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject(error.response.data);
			});
		});
	}

	public static ConfirmEmail(code: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`contacts/confirm/${code}/email`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static ConfirmGuardian(code: string, type: ContactGuardianType): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`contacts/confirm/${code}/guardian/${type}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static ConfirmLink(code: string, accept: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`contacts/confirm/${code}/link/${accept}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static ConfirmMember(code: string, accept: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`contacts/confirm/${code}/member/${accept}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static Confirm(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`contacts/confirm`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getAccount(contactId: number): Promise<ContactAccount> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/account`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static createLink(contactId: number, contact: CreateContact): Promise<ContactLinkResult> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/${contactId}/link`, contact).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data);
			});
		});
	}

	public static link(contactId: number, linkId: number, type: ContactLinkType): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/link`, {contactId, linkId, type}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject(error?.response?.data);
			});
		});
	}

	public static unlink(linkId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/${linkId}/unlink`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject(error?.response?.data);
			});
		});
	}

	public static getAgreements(contactId: number, options: ConsentAgreementsFilterOptions): Promise<PagedList<ConsentAgreement>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/agreements`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getCredits(contactId: number): Promise<ContactCredits> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/${contactId}/credits`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static uspertCredits(contactId: number, model: ContactCredits): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/${contactId}/credits`, model).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static checkAgendaConflicts(toCheck: ContactCalendarTimeframe[]): Promise<CalendarConflict[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/conflicts`, toCheck).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}
}
