import { Member, MemberIdentity } from "./member";
import { Division } from "./division";
import { Venue } from "./venue";
import { MatchCardEntryType } from "./match";
import { County } from "./county";
import { Club } from "./club";
import { League } from "./league";
import { DocumentGrouping } from "./document";
import { RegistrationCompetitionType } from "./registration";
import { Format } from "./format";
import { Point } from "./point";

export class Competition {
	public id: number;
	public tenantId?: number;
	public name: string;
	public menuName: string;
	public description: string;
	public shortDescription: string;
	public gamesPerSet?: number;
	public gameFormat?: number;
	public formatId?: number;
	public format: Format;
	public singlesAny: boolean;
	public pointId?: number;
	public point: Point;
	public playAll: boolean;
	public playEachOther: number;
	public maxPlayers?: number;
	public matchCard: CompetitionMatchCard;
	public table: CompetitionTable;
	public averages: CompetitionAverages;
	public reserves: CompetitionReserves;
	public organiser?: MemberIdentity;
	public startDate?: Date;
	public startTime?: Date;
	public ready?: Date;
	public published?: Date;
	public archived?: Date;
	public hidden?: Date;
	public fixtureFormat?: number;
	public resultDisplay: ResultDisplay;
	public manualScoring: boolean;
	public divisions: Division[] = [];
	public night?: number;
	public ranking: boolean;
	public tableCount?: number;
	public days: CompetitionDay[];
	public submissionPeriods: SubmissionPeriod[];
	public splitRounds: boolean;
	public captainType: number;
	public playerType: number;
	public groupId: number;
	public group: CompetitionGrouping;
	public groupOrdering: number;
	public halfGeneration: boolean;
	public advancedOptions: boolean;
	public tableAllocation: boolean;
	public showReserves: boolean;
	public requirePlayers: boolean;
	public ageEligibility : number[];
    public membershipEligibility: number[];
    public genderEligibility?: number;

	public competitionTypeId?: number;
	public registrationId?: number;

	public rankingSubmission: boolean;
	public doublesPOTM: boolean;

	public fastFormat: boolean; 

	public exportNationalRankings: boolean;
	public nationalRankingCategory: string;
	public altNationalRankingCategory: string;

	public teamHandicap: boolean;

	public expiryStart: boolean;

	public includeClubPlay: boolean;

	public showHomepage: boolean;
}

export class CompetitionDay {
	public id: number;
	public competitionId: number;
	public divisionId?: number;
	public weekNo: number;
	public date: Date;
	public venueId?: number;
	public venue: Venue;
	public rounds?: number;
	public title: string;
	public description: string;
	public created: Date;

	public startTime?: Date;
	public matchTime?: number;
	public matchOffset?:number;
	public roundOffset?: number;
	public totalTables?: number;
	public tablesPerMatch?: number;
}

export class SubmissionPeriod{
	public id: number;
	public competitionId: number;
	public startDate: Date;
	public endDate?: Date;
}

export class CompetitionMatchCard {
	public competitionId: number;
	public layout?: MatchCardLayout;
	public playerLabels?:  PlayerLabels;
	public approval?: number;
	public printoutLayout: ScoreSheetVersion;
	public printoutSubLayout: number;
}

export class CompetitionTable {
	public competitionId: number;
	public gamesForAgainst: boolean;
	public pointsAdjustments: boolean;
	public pointsForAgainst: boolean;
	public setsForAgainst: boolean;
	public winLossDraw: boolean;
	public setsRatio: boolean;
	public gamesRatio: boolean;
	public pointsRatio: boolean;
	public orderType: number;
	public setAlternative: boolean;
}

export class CompetitionAverages {
	public competitionId: number;
	public singleForfeit: boolean;
	public singleConcede: boolean;
	public singleWalkover: boolean;
	public doublesForfeit: boolean;
	public doublesConcede: boolean;
	public doublesWalkover: boolean;
	public calculationType: ReserveCalculation;
	public excludeReserves: boolean;
	public excludeNoWins: boolean;
	public orderType: number;
	public minimumPlayed: number;
	public consolidatedAverages: boolean;
}

export class CompetitionReserves {
	public competitionId: number;
	public type: number;
	public stepUpType: number;
	public multipleTeams: boolean;
	public transferPlayer: boolean;
	public maxStepUp: number;
	public overrideEnabled: boolean;
	public maxTeamStepUp?: number;
}

export class CreateCompetition {
	public name: string;
	public competitionId?: number;
	public continuation?: boolean;
	public groupingId?: number;
	public registrationId?: number;
	public fastFormat?: boolean;
	public prefabId?: number;
}

export class SetupCompetition {
	public name: string;
	public description: string;

	public fastFormat?: boolean;

	public prefabId?: number;

	public divisions: number;
	public teams: number;
}

export class CompetitionGrouping {
	public id: number;

	public name: string;

	public startingCompetitionId?: number;

	public groupType: number;

	public leagueId: number;
}

export class ReserveFilter {
	public entrantId?: number;
	public available: boolean;
}

export class ExportOptions{
	public rounds: number[];
	public dates: Date[];
	public divisionIds: number[];
	public exportRounds: boolean;
}

export class CompetitionPreset{
	public id: number;
	public name: string;
	public fixtureFormat: number;
	public formatId: number;
	public gamesPerSet: number;
	public gamesFormat: number;
	public pointId: number;
	public playEachOther: number;
	public tenantId?: number;
}

export class CompetitionMember{
	public competitionId: number;
	public userId: string;
	public name: string;
	public membershipNo: number;
	public role: number;
	public constructor (competitionId: number){
		this.competitionId = competitionId;
	}
}

export enum ReserveCalculation {
	Sets = 1,
	Games = 2
}

export enum ResultDisplay {
	Sets = 1,
	Games = 2,
	Points = 3,
	PointsAwarded = 4,
}

export enum MatchCardLayout {
	DefaultHorizontal = 1,
	DefaultVertical = 2
}

export enum PlayerLabels {
	Numerical = 1,
	Alphabetical = 2
}

export enum ScoreSheetVersion {
	Default = 0,
	SBCL = 1,
	YBGBCL = 2,
	YCJBCL = 3,
	WBCL = 4,
	VBCL = 5,
}