export class ArrayHelper {
    public static arrayMove(array, old_index, new_index) {
        if (array.length === 0) {
            return array;
        }
        while (old_index < 0) {
            old_index += array.length;
        }
        while (new_index < 0) {
            new_index += array.length;
        }
        if (new_index >= array.length) {
            var k = new_index - array.length;
            while ((k--) + 1) {
                array.push(undefined);
            }
        }
        array.splice(new_index, 0, array.splice(old_index, 1)[0]);
        return array; // for testing purposes
    }

    public static groupBy(array, f) {
        var groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    public static groupByAlt = <T, K extends keyof any> (list: T[], getKey: (item: T) => K) => {
		const output:KeyValuePair<K, T[]>[] = [];
		list.forEach((item) => {
			 const key = getKey(item);
			 const collection = output.find(x => x.key === key);
			 if (!collection) {
				 output.push({ key, value: [item] });
			 } else {
				 collection.value.push(item);
			 }
		});
		return output;
	}

    public static flatten<T>(arr: any[]): T[] {
        return arr.reduce(function (flat, toFlatten) {
            return flat.concat(Array.isArray(toFlatten) ? ArrayHelper.flatten(toFlatten) : toFlatten);
        }, []);
    }

    public static distinct<T>(arr: T[]): T[] {
        return arr.filter((value, index, self) => self.indexOf(value) === index);
    }
}

export class KeyValuePair<TKey, TValue> {
	public key:TKey;
	public value:TValue;
}