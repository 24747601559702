import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { MemberRegistration, Member, MemberSetup, MemberAccess, MemberDetails, Platform, CreateMember, SearchMember, MemberIdentity, MemberRoles } from "../models/member";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import { DatatableQuery, DatatableResponse } from "@models/query";
import { SelectOption } from "@models/forms";

import browser from "browser-detect";

import RestHelper from "@helpers/rest.helper";
import { TeamCaptain } from "@models/team";
import { FilterOptions, MembershipFilterOptions, MembershipUserFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

import * as qs from "query-string";
import { Contact } from "@models/contact";
import { AssignMembership, Membership, MembershipStatus, MembershipUser } from "@models/membership";
import { Invoice } from "@models/invoice";
import { ConsentAccepted, ConsentAssociations, ConsentMembershipAssociation } from "@models/consent";

export class MembershipService {

	constructor() {
		//
	}

	public static get(query: MembershipFilterOptions): Promise<PagedList<Membership>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships`, {
				params: query,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getMembers(options: MembershipUserFilterOptions): Promise<PagedList<MembershipUser>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships/members`, {
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getById(id: number): Promise<Membership> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships/${id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getAssociations(membershipId: number): Promise<ConsentAssociations[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships/activeAssociations/${membershipId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getAccepted(userId: string): Promise<ConsentAccepted[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships/activeAcceptances/${userId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getByIds(membershipIds: number[]): Promise<Membership[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `memberships/membershipids`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(membershipIds),
				success: (data: Membership[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getAll(): Promise<Membership[]> {
		return new Promise((resolve, reject) => {					
			RestHelper.get(`memberships/memberships`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static assign(assign: AssignMembership): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/assign`, assign).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static link(membershipUserId: number, linked: number[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/link`, {membershipUserId, linked}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static join(membershipId: number, upfront: boolean, paymentMethodId: string, contactId: number, linked?: number[]): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`memberships/join`, { membershipId, upfront, paymentMethodId, contactId, linked })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsert(membership: Membership): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships`, membership).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static delete(membershipId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			// RestHelper.delete(`memberships/${membershipId}`).then(result => {
			RestHelper.get(`memberships/${membershipId}/delete`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static deleteMembershipUser(membershipUserId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships/member/${membershipUserId}/delete`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static approve(membershipUserId: number, approve: boolean): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/user/${membershipUserId}/approve`, {approve}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static status(membershipUserId: number, status: MembershipStatus): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/user/${membershipUserId}/status`, {status}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static cancel(membershipUserId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`memberships/user/${membershipUserId}/cancel`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static renew(membershipUserId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/user/${membershipUserId}/renew`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static renewAll(membershipId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/${membershipId}/renew`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static publish(membershipId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/${membershipId}/publish`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static order(memberships: Membership[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/order`, memberships).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static check(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`memberships/contact/${id}/check`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static dates(membershipUser: MembershipUser): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/user/${membershipUser.id}/dates`, { due: membershipUser.due, end: membershipUser.end }).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static associateConsents(associations: ConsentMembershipAssociation[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`memberships/associations`, associations).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
