import * as React from "react";
import { useContext, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Form, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import Select from "react-select";
import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership } from "@models/membership";
import { PagedList } from "@models/paging";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ProgressButton } from "@components/controls/ProgressButton";
import { User } from "@models/user";
import { MemberService } from "@services/member.service";
import { StripeService } from "@services/stripe.service";
import { Event, EventSchedule, EventSession, EventSessionExtension, EventSessionStatus, EventType } from "@models/event";
import { EventService } from "@services/event.service";
import { Contact, ContactLink } from "@models/contact";
import { ContactService } from "@services/contact.service";
import LocaleHelper from "@helpers/locale.helper";
import { InvoiceService } from "@services/invoice.service";
import { InvoiceStatus } from "@models/invoice";
import { WebsiteContext } from "@context/BasketContext";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ResourceHelper } from "@helpers/resource.helper";
import ConsentsModal from "@components/modals/ConsentsModal";
import { BasketService } from "@services/basket.service";
import { CalendarConflict, ContactCalendarTimeframe } from "@models/booking";
import { AgendaConflictAlert } from "@components/controls/AgendaConflictAlert";

const StripeContainer = (props) => {
	const [stripePromise] = useState(() => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId }));

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm schedule={props.schedule} eventId={props.eventId} upfront={props.upfront} payLater={props.payLater} sessionId={props.sessionId} contact={props.contact} bookee={props.bookee} onSubmit={props.onSubmit} onComplete={props.onComplete} onError={props.onError} useBasket={props.useBasket} submitting={props.submitting} />
		</Elements>
	);
};

interface ICheckoutFormProps {
	eventId: number;
	sessionId: number[];
	contact: Contact;
	bookee: Contact;
	schedule: EventSchedule;
	onSubmit?: () => void;
	onComplete?: () => void;
	onError?: () => void;
	useBasket?: () => void;
	submitting: boolean;
	payLater: boolean;
	upfront: boolean;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleBookAndPayClick = () => async (event: any) => {
		event.preventDefault();

		try {
			validate();

			props.onSubmit();

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement)
			});

			if (paymentMethod) {
				try {
					const invoice = await EventService.book(props.eventId, props.sessionId, props.contact.id, props.bookee.id, [], props.upfront, false, null, paymentMethod.id);

					if (invoice.status === InvoiceStatus.Failed) {
						await toast.error("Payment failed. Please retry");
						History.push(`/pay/${invoice.reference}`);
					}
					else
						History.push(`/events/confirmation/${invoice.reference}`);

				} catch (error) {
					toast.error(error.message ?? "Something went wrong");
					props.onError();
				}

				props.onComplete();
			} else {
				toast.error("Payment details missing");
				props.onError();
			}
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const handleBookClick = () => async (event: any) => {
		event.preventDefault();

		try {
			const invoice = await EventService.book(props.eventId, props.sessionId, props.contact.id, props.bookee.id, [], props.upfront, false, null);
			History.push(`/events/confirmation/${invoice.reference}`);
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const validate = () => {
		return true;
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<div className="stripe">
			<div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>
			<Row>
				<Col>
					<ProgressButton onClick={handleBookAndPayClick()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
						Book and Pay Now
					</ProgressButton>
				</Col>
				{
					props.payLater &&
					<Col>
						<ProgressButton onClick={handleBookClick()} className="btn btn-primary btn-rounded w-100" loading={props.submitting}>
							Book and Pay Later
						</ProgressButton>
					</Col>
				}
				<Col>
					<Button onClick={() => props.useBasket()} className="mt-1 btn btn-primary btn-rounded w-100">
						Book Later
					</Button>
				</Col>
			</Row>
		</div>
	);
};

interface IEventJoinProps {
	site?: string;
	match?: any;

}

const EventJoinPage = (props: IEventJoinProps) => {
	const websiteContext = useContext(WebsiteContext);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [event, setEvent] = React.useState<Event>();
	const [contact, setContact] = React.useState<Contact>();
	const [bookee, setBookee] = React.useState<Contact>();
	const [submitting, setSubmitting] = React.useState<boolean>(false);
	const [active, setActive] = React.useState<boolean>(true);
	const [upfront, setUpfront] = React.useState<boolean>();
	const [total, setTotal] = React.useState<number>();
	const [selected, setSelected] = React.useState<EventSession[]>([]);
	const [sessions, setSessions] = React.useState<EventSession[]>([]);
	const [usage, setUsage] = React.useState<number>(0);
	const [links, setLinks] = React.useState<ContactLink[]>([]);
	const [member, setMember] = React.useState<boolean>(false);
	const [consentValid, setConsentValid] = React.useState<boolean>(false);
	const [uncompleted, setUncompleted] = React.useState<boolean>();
	const [payingWithStripe, setPayingWithStripe] = React.useState<boolean>(false);
	const [eventId, setEventId] = React.useState<number>(parseInt(props.match.params.eventId, 0));
	const [sessionId, setSessionId] = React.useState<number>(parseInt(props.match.params.sessionId, 0));
	const [conflicts, setConflicts] = React.useState<CalendarConflict[]>([]);

	useEffect(() => {
		if (!LoginService.isAuthenticated) {
			History.replace(`/register?returnUrl=/events/${eventId}/join`);
		}
		load();
	}, []);

	useEffect(() => {
		if (contact)
			loadEvents();
	}, [contact]);

	useEffect(() => {
		if (selected.length > 0)
			checkAgendaConflicts(selected.map(m => ({ contactId: contact.id, start: m.startDate, end: m.endDate })));
		else
			setConflicts([]);
	}, [selected]);

	const load = async () => {
		const bookee = await ContactService.getByUser();

		if (!bookee) {
			History.push("/member/profile/confirm");
		} else {
			const links = await ContactService.getLinked(bookee.id);

			const paymentFailedForContactEvent = await InvoiceService.getFailedByEventContact(eventId, bookee.id);
			if (paymentFailedForContactEvent !== null && paymentFailedForContactEvent !== undefined) {
				toast.warn("Please complete your payment");
				History.push(`/pay/${paymentFailedForContactEvent.reference}`);
			}

			setContact(bookee);
			setBookee(bookee);
			setLinks(links);
			setUncompleted(((!bookee.completed || !bookee.medicalAgreement) && AppCache.tenant.config.booking.completeProfileRequired));
		}
	}

	const loadEvents = async () => {
		const member = await MembershipService.check(contact.id);
		const event = await EventService.getById(eventId, contact.id);
		const sessions = await EventService.getSessions({ status: EventSessionStatus.Upcoming, public: true, contactId: contact.id }, eventId);
		const usage = await EventService.getContactUsage(event.id, contact.id);
		const bookee = await ContactService.getByUser();

		// check for failed payment
		const paymentFailedForContactEvent = await InvoiceService.getFailedByEventContact(eventId, bookee.id);
		if (paymentFailedForContactEvent !== null && paymentFailedForContactEvent !== undefined) {
			toast.warn("Please complete your payment");
			History.push(`/pay/${paymentFailedForContactEvent.reference}`);
		}

		const selected = [];

		if (sessions.items.length === 1) {
			const session = Object.assign(new EventSessionExtension(), event.nextSession ?? sessions.items[0]);
			if (session.bookableUntil && moment(session.bookableUntil) <= moment()) {
				toast.warn("Online Bookings have now closed for this session.");
				history.back();
			} else {
				selected.push(session);
			}
		}

		if (sessionId) {
			selected.push(sessions.items.find(s => s.id === sessionId));
		}

		let total: number = 0;

		if (usage > 0) {
			if (!event.bookableDates && selected.length > usage) {
				total = event.price * (selected.length - usage);
			}
		} else {
			if (event.bookableDates) {
				total = event.price * selected.length;
			} else {
				total = event.price;
			}
		}

		setEvent(event);
		setSelected(selected);
		setSessions(sessions.items);
		setLoading(false);
		setTotal(total);
		setUsage(usage);
		setMember(member);
	}

	const checkAgendaConflicts = async (toCheck: ContactCalendarTimeframe[]) => {
		NProgress.start();
		ContactService.checkAgendaConflicts(toCheck).then((result) => {
			setConflicts(result);
		}).finally(() => NProgress.done());
	}

	const handleSessionClick = (session: EventSession) => (e: any) => {
		if (event.bookableDates) {
			let sel = [];
			const selectedIndexFound = selected.findIndex(f => f.id === session.id);
			if (selectedIndexFound != -1) { //removing
				sel = [...selected.slice(0, selectedIndexFound), ...selected.slice(selectedIndexFound + 1)];
				setSelected([...selected.slice(0, selectedIndexFound), ...selected.slice(selectedIndexFound + 1)]);
			} else { //adding
				sel = [...selected, session];
				setSelected([...selected, session]);
			}

			let total = 0;

			if (usage > 0) {
				if (selected.length > usage) {
					total = event.price * (sel.length - usage);
				}
			} else {
				total = event.price * sel.length;
			}

			setUpfront(false);
			setTotal(total);
		}
	}

	const handleUpfrontClick = () => (e: any) => {
		if (!(upfront == true))
			checkAgendaConflicts(sessions.map(m => ({ contactId: contact.id, start: m.startDate, end: m.endDate })));

		const tt = event.upfrontPrice;
		setUpfront(!upfront);
		setSelected([]);
		setTotal(tt);
	}

	const handleBookClick = () => async (e: any) => {
		e.preventDefault();

		NProgress.start();
		setSubmitting(true);

		try {
			const invoice = await EventService.book(event.id, selected.map(s => s.id), contact.id, bookee.id, null, upfront, false, null);
			NProgress.done();
			History.push(`/events/confirmation/${invoice.reference}`);
		} catch (error) {
			toast.error(error.message);
		} finally {
			setSubmitting(false);
			NProgress.done();
		}
	}

	const handleReserveClick = () => async (e: any) => {
		e.preventDefault();

		NProgress.start();
		setSubmitting(true);

		try {
			const basketItems = await EventService.reserve(event.id, selected.map(s => s.id), contact.id, bookee.id, null, upfront, false, null);

			for (const basketItem of basketItems) {
				const bsk = await BasketService.add(basketItem);
				websiteContext.setBasket(bsk);
			}

			toast.success("Added to Basket - Expires in 30 minutes", {
				onClick: () => History.push("/basket")
			});
		} catch (error) {
			toast.error(error.message);
		} finally {
			setSubmitting(false);
			NProgress.done();
		}
	}

	const handleContactChange = () => async (contact: Contact) => {
		setContact(contact);
		setLoading(true);
		//loadEvents();
	}


	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																		<h1 className="sc_layouts_title_caption">{event.title}</h1>
																		<p className="header--description">{event.summary}</p>
																	</div>
																	</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{event.title}</h1>
												<p className="header--description">{event.summary}</p>
											</div>
										</div>
									</div>
								</div>
						}
						<div className="page_content_wrap">
							<div className="container">
								<div className="content">
									<div className="membership mg-t-20">
										{
											(event.cancellationType != null && !uncompleted) &&
											<Row className="mt-3">
												<Col md={{ size: 6, offset: 3 }}>
													<div className="alert alert-info alert-rounded">
														<span>{ResourceHelper.getCancellationMessage(event.cancellationType)}</span>
													</div>
												</Col>
											</Row>
										}
										{
											(links.length > 0 && !uncompleted) &&
											<Row className="mb-3">
												<Col md={{ size: 6, offset: 3 }}>
													<Select
														options={[...links.map(l => l.contact), bookee]}
														value={contact}
														closeMenuOnSelect={true}
														getOptionLabel={e => e.name !== "" ? e.name : e.fullName}
														getOptionValue={e => e.id}
														onChange={handleContactChange()} />
												</Col>
											</Row>
										}
										{
											uncompleted &&
											<Row className="mb-5">
												<Col lg={{ size: 6, offset: 3 }}>
													<p className="text-center">Emergency contact details and medical agreement must be completed</p>
													<Link tabIndex={0} to={`/member/profile/${bookee.id !== contact.id ? contact.id + "/" : ""}tab/5`} className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block text-white">Complete Profile</Link>
												</Col>
											</Row>
										}
										{
											!uncompleted &&
											<Row>
												<Col md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
													<Form>
														{
															sessions.length > 1 &&
															<div>
																{event.bookableDates && <p>Please select dates below</p>}
																{
																	sessions.map(s => {
																		const session = Object.assign(new EventSessionExtension(), s);
																		return (
																			<Row className="mb-3">
																				<Col>
																					<Card className={`option clickable ${selected.find(x => x.id === s.id) && "selected"}`} onClick={handleSessionClick(s)}>
																						<Row>
																							<Col>
																								{s.space && <div><i className="far fa-table-tennis mr-1"></i>{s.space.name}</div>}
																								<div><i className="far fa-calendar mr-1"></i>{moment(s.startDate).format("dddd Do MMMM")}</div>
																								<div><i className="far fa-clock mr-1"></i>{moment(s.startDate).format("HH:mm")}-{moment(s.endDate).format("HH:mm")}</div>
																								{
																									event.bookableDates && s.available >= 0 && <div><i className="far fa-tally fa-fw mr-1"></i>{s.available}</div>
																								}
																								{
																									(session.bookableUntil && moment(session.bookableUntil) >= moment()) &&
																									<div><i className="far fa-stopwatch mr-1" />Book until {moment(session.bookableUntil).format("DD/MM/YY H:mm a")}</div>
																								}
																							</Col>
																							<Col className="my-auto text-right">
																								{
																									event.bookableDates && <>
																										{s.price === 0 && <h4 className="membership-price m-0">FREE</h4>}
																										{s.price > 0 && <h4 className="membership-price m-0">{LocaleHelper.toPrice(s.price)}</h4>}
																									</>
																								}
																							</Col>
																						</Row>

																					</Card>
																				</Col>
																			</Row>
																		);
																	})
																}
																{
																	event.bookableUpfront && (!event.upfrontExpiry || moment(event.upfrontExpiry) > moment()) &&
																	<Row className="mb-3">
																		<Col>
																			<Card className={`option text-center p-3 clickable ${upfront === true && "selected"}`} onClick={handleUpfrontClick()}>
																				<span>All Dates</span>
																				<h3 className="membership-price m-0 text-center">
																					{LocaleHelper.toPrice(event.upfrontPrice)}
																				</h3>
																				Upfront
																			</Card>
																		</Col>
																	</Row>
																}
															</div>
														}
														<Row>
															<Col>
																{
																	sessions.length === 1 ?
																		sessions.map(s => {
																			const session = Object.assign(new EventSessionExtension(), s);
																			return (
																				<>
																					<div><i className="far fa-calendar mr-1"></i>{moment(event.nextSession?.startDate).format("dddd Do MMMM")}</div>
																					<div><i className="far fa-clock mr-1"></i>{moment(event.nextSession?.startDate).format("HH:mm")}-{moment(event.nextSession?.endDate).format("HH:mm")}</div>
																					{
																						(session.bookableUntil && moment(session.bookableUntil) >= moment()) &&
																						<div><i className="far fa-stopwatch mr-1" />Book until {moment(session.bookableUntil).format("DD/MM/YY H:mm a")}</div>
																					}
																				</>)
																		}) : <></>
																}
															</Col>
															<Col className="my-auto text-right">
																{
																	(sessions.length === 1 || selected.length > 0) &&
																	<>
																		{total === 0 && <h4 className="membership-price m-0">FREE</h4>}
																		{total > 0 && <h4 className="membership-price m-0">{LocaleHelper.toPrice(total)}</h4>}
																	</>
																}
															</Col>
														</Row>
														{
															conflicts.length > 0 &&
															<Row className="mt-3">
																<Col>
																	<AgendaConflictAlert conflicts={conflicts} />
																</Col>
															</Row>
														}
														{
															(total > 0) &&
															<>
																{!payingWithStripe &&
																	<Row>
																		<Col>
																			<ProgressButton disabled={!selected} onClick={handleReserveClick()} className="mt-5 btn btn-primary btn-rounded w-100" loading={submitting}>
																				Add to Basket
																			</ProgressButton>
																			<Button onClick={() => {setPayingWithStripe(true)}} className="mt-1 btn btn-primary btn-rounded w-100">
																				Book Now
																			</Button>
																		</Col>
																	</Row>
																}
																{
																	payingWithStripe &&
																	<>
																		<Row>
																			<Col>
																				<StripeContainer
																					eventId={event.id}
																					contact={contact}
																					bookee={bookee}
																					schedule={sessions[0].schedule}
																					sessionId={selected.map(s => s.id)}
																					upfront={upfront}
																					onSubmit={e => setSubmitting(true)}
																					onComplete={e => setSubmitting(false)}
																					onError={e => setSubmitting(false)}
																					useBasket={e => setPayingWithStripe(false)}
																					submitting={submitting}
																					payLater={event.payLater} />
																			</Col>
																		</Row>
																		<Row className="mt-4 text-center">
																			<Col>
																				<img title="Powered by Stripe" width={"200px"} src={require("../../../../assets/images/misc/stripe-plurple.png")} />
																			</Col>
																		</Row>
																	</>
																}
															</>
														}
														{
															(event.price === 0 || (upfront && total === 0)) &&
															<Row className="mt-3">
																<Col>
																	<ProgressButton onClick={handleBookClick()} className="btn btn-primary btn-rounded w-100" loading={submitting}>
																		Book
																	</ProgressButton>
																</Col>
															</Row>
														}
														{
															(usage > 0 && total === 0 && selected.length > 0) &&
															<Row className="mt-3">
																<Col>
																	<ProgressButton onClick={handleBookClick()} className="btn btn-primary btn-rounded w-100" loading={submitting}>
																		Confirm Usage
																	</ProgressButton>
																</Col>
															</Row>
														}
														{
															!(event.cost === 0 || event.cost > 0) &&
															<>
																{
																	(event.memberCost || event.memberships.length > 0) &&
																	<>
																		<h4 className="mb-0 mt-3">Memberships</h4>
																		<p>Valid memberships required to book onto session</p>
																		{
																			event.memberCost &&
																			<Row className="my-3">
																				<Col>
																					<p className="m-0 font-weight-semibold">Any Valid Memberships</p>
																				</Col>
																				<Col>
																					<h4 className="m-0 text-right">{LocaleHelper.toPrice(event.memberCost)}</h4>
																				</Col>
																			</Row>
																		}
																		{event.memberships.map(m => (
																			<Row className="my-3">
																				<Col className="my-auto">
																					<p className="m-0 font-weight-semibold">{m.membership.name}</p>
																				</Col>
																				<Col className="text-right">
																					<h4 className="m-0">£{m.cost}</h4>
																				</Col>
																			</Row>
																		))}
																	</>
																}
															</>
														}
													</Form>
												</Col>
											</Row>
										}
									</div>
								</div>
							</div>
						</div>
					</div>)}
			/>
			<ConsentsModal autoCheck={true} eventId={eventId} onClose={() => History.goBack()} onConfirm={() => setConsentValid(true)} />
		</PublicLayout>
	);
}

export default EventJoinPage;
