export class Constants {
	public static authHeaderKey: string = "Authorization";
	public static tenantKey: string = "Tenant";
}

export class GlobalRoles {
	public static full: string[] = [
		"global:full:??"
	];
	public static master: string[] = [
		...GlobalRoles.full,
		"global:master:??"
	];
}

export class AdminRoles {
	public static master: string[] = [
		...GlobalRoles.master,
		"admin:master:?"
	];

	public static full: string[] = [
		...AdminRoles.master,
		"admin:full:?"
	];

	public static primary: string[] = [
		...AdminRoles.full,
		"admin:primary:?"
	];
}

export class Roles {
	public static admin: string[] = [
		"manage:full:?",
		"manage:read_only:?"
	];

	public static full: string[] = [
		"manage:full:?"
	];

	public static members: string[] = [
		...Roles.full,
		"manage:members:?",
	];

	public static bookings: string[] = [
		...Roles.full,
		"manage:bookings:?",
	];

	public static invoices: string[] = [
		...Roles.full,
		"manage:invoices:?",
	];

	public static messages: string[] = [
		...Roles.full,
		"manage:messages:?",
	];
}