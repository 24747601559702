import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CreateImage } from "@models/image";
import { ImageService } from "@services/image.service";
import { AppConfig } from "../../config";
import { StaticMarker } from "./StaticMarker";


export class LatLng {
	public lat: number;
	public lng: number;
}

export class Size {
	public lat: number;
	public lng: number;
}

const IMAGE_FORMATS = {
	// png8 or png (default) specifies the 8-bit PNG format.
	PNG: 'png',
  
	// png32 specifies the 32-bit PNG format.
	PNG32: 'png32',
  
	// gif specifies the GIF format.
	GIF: 'gif',
  
	// jpg specifies the JPEG compression format.
	JPG: 'jpg',
  
	// jpg-baseline specifies a non-progressive JPEG compression format.
	JPG_BASELINE: 'jpg-baseline',
  };
  
  const MAP_TYPES = {
	// roadmap (default) specifies a standard roadmap image, as is normally shown on the Google Maps website.
	ROADMAP: 'roadmap',
  
	// satellite specifies a satellite image.
	SATELLITE: 'satellite',
  
	// terrain specifies a physical relief map image, showing terrain and vegetation.
	TERRAIN: 'terrain',
  
	// hybrid specifies a hybrid of the satellite and roadmap image,
	// showing a transparent layer of major streets and place names on the satellite image.
	HYBRID: 'hybrid',
  };

interface IStaticMapProps {
	match?: any;
	mapContainerStyle?: React.CSSProperties | undefined
	center?: LatLng | undefined
	zoom?: number | undefined;
	hasCenterMarker?: boolean | undefined;
	scale?: number | undefined;
	googleMapsApiKey: string | "";
	children?: React.ReactElement<StaticMarker>[] | React.ReactElement<StaticMarker>
	googleLink?: boolean;
}

interface IStaticMapState {
	loading: boolean;
	visible: boolean;
	nextPathname?: any;
	markers: LatLng[];
	src: string;
	googleLink: boolean;
}

export class StaticMap extends React.Component<IStaticMapProps, IStaticMapState> {

	static RootUrl = 'https://maps.googleapis.com/maps/api/staticmap';
	static format = IMAGE_FORMATS.PNG;
	static mapType = MAP_TYPES.ROADMAP;
	private ref: React.RefObject<HTMLDivElement>;
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.state = { loading: true, visible: false, src: "", markers: [], googleLink: this.props.googleLink ?? false};
	}	

	staticMapUrl() {
		const {
			center,
			zoom,
			scale,
		} = this.props;
			
		var width = this.ref.current?.offsetWidth;
		var height = this.ref.current?.offsetHeight;
		const rootUrl = StaticMap.RootUrl;
	
		return `${rootUrl}?${center ? `center=${center.lat},${center.lng}`: ""}${zoom ? `&zoom=${zoom}` : ""}${scale ? `&scale=${scale}` : ""}&size=${width}x${height}&maptype=${StaticMap.mapType}&format=${StaticMap.format}${this.markerParams()}&${this.apiKeyParam()}`;
	}

	markerParams() {
		const markerParams = `&markers=shadow:true${this.state.markers?.map(c => {
			return `%7C${c.lat},${c.lng}`
		}).join("")}`;
		return this.state.markers?.length > 0 ? markerParams : '';
	}

	apiKeyParam() {
		const apiKey = this.props.googleMapsApiKey;
		return apiKey ? `key=${apiKey}` : '';
	}

	onScroll() {
		var bounding = this.ref.current.getBoundingClientRect();
		var top = bounding.top;
		var bottom = bounding.bottom;
		if(top <= window.innerHeight && bottom >= 0){
			if(!this.state.visible){
				console.log("show")
				this.setState({visible: true})
			}
			else{
				console.log("shown");
			}
		}
		else{
			
			if(this.state.visible){
				console.log("hide")
				this.setState({visible: false})
			}
			else{
				console.log("hidden")
			}
		}
	}

	componentDidMount() {
		//window.addEventListener("scroll", () => this.onScroll());
		this.setState({src: this.staticMapUrl()});
	}

	componentWillUnmount() {
		//window.removeEventListener("scroll", () => this.onScroll());
	}

	DispatchPropsToChildren({ propsToDispatch, children }) {
		try{
			return (
			<>{children.map(child => React.cloneElement(child, { ...propsToDispatch }))}</>
			);
		}
		catch{
			return (
				<>{React.cloneElement(children, { ...propsToDispatch })}</>
			);
		}
	}
		

	public render() {
		return (
			<div ref={this.ref}
				style={this.props.mapContainerStyle}>
				{this.state.googleLink ? 
				<a href={`https://www.google.com/maps/place/${this.props.center.lat},${this.props.center.lng}`}>
					<img
						style={{objectFit: "cover", width: "100%"}}
						src={this.state.src}
					/>
					<this.DispatchPropsToChildren propsToDispatch={{ sendLocation: (pos: LatLng) => {var markers = this.state.markers; markers.push(pos); this.setState({markers})} }}>
						{this.props.children}
					</this.DispatchPropsToChildren>
				</a>
				:
				<>
					<img
						style={{objectFit: "cover", width: "100%", height: "100%"}}
						src={this.state.src}
					/>
					<this.DispatchPropsToChildren propsToDispatch={{ sendLocation: (pos: LatLng) => {var markers = this.state.markers; markers.push(pos); this.setState({markers})} }}>
						{this.props.children}
					</this.DispatchPropsToChildren>
				</>
				}
			</div>
		);
	}
}
