import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition, ResultDisplay } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe } from "@components/controls/ShowMe";
import { MatchService } from "@services/match.service";
import { Match, MatchSet, MatchResults, MatchCardEntryLevel } from "@models/match";
import { AppCache } from "@cache";
import { Can } from "@components/controls/Authentication";
import { LoginService } from "@services/login.service";
import { stringify } from "querystring";


interface IMatchPageProps {
	site?: string;
	match?: any;
}

interface IMatchPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	standings: Standing[];
	match?: Match;
	sets: MatchSet[];
	results?: MatchResults;
	entry: MatchCardEntryLevel
}
export class MatchPage extends React.Component<IMatchPageProps, IMatchPageState> {

	private divisionId: number;
	private competitionId: number;
	private matchId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			standings: [],
			sets: [],
			entry: MatchCardEntryLevel.None
		};
	}

	componentDidMount() {
		//
		this.divisionId = this.props.match.params.divisionId as number;
		this.competitionId = this.props.match.params.competitionId as number;
		this.matchId = this.props.match.params.matchId as number;

		this.load();
	}

	load() {
		const p1 = MatchService.getMatch(this.matchId).then(match => {
			this.setState({ match });
		});

		const p2 = MatchService.getSets(this.matchId).then(sets => {
			this.setState({ sets });
		});

		const p3 = DivisionService.getById(this.divisionId).then(division => {
			this.setState({ division });
		});

		const p4 = MatchService.getResults(this.matchId).then(results => {
			this.setState({ results });
		});

		const requests = [p1, p2, p3, p4];
		Promise.all([p1]).then(result => {
			if (LoginService.isAuthenticated && this.state.match.home != null && this.state.match.away != null) {
				const p5 = MatchService.getEntryLevel(this.matchId).then(entry => {
					this.setState({ entry });
				});

				requests.push(p5);
			}
		});

		Promise.all(requests).then(result => {

			const p3 = CompetitionService.getById(this.state.division.competitionId).then(competition => {
				this.setState({ competition });
			});

			Promise.all([p3]).then(result => {
				this.setState({ loading: false });
			});
		});
	}

	scratchInfo(set: MatchSet){
		if(set.players.some(p => p.scratch != null)){
			var player = set.players.find(p => p.scratch != null);
			var type = "";
			switch(player.scratchType){
				case 1:
					type = "Walkover";
					break;
				case 2:
					type = "Conceded";
					break;
			}
			return `Scratch Type: ${type}\nPlayer: ${player.printoutName}\nReason: ${player.scratchReason}`
		}
	}

	public render() {
		return (
			<PublicLayout className="page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Match Card</h1>
																			<p className="sc_layouts_title_breadcrumbs"><span>{this.state.competition.name}</span> <span className="separator">/</span> <Link to={`/league/${this.competitionId}/division/${this.state.division.id}/results`}>{this.state.division.name}</Link> <span className="separator">/</span> {this.state.match.home?.displayName ?? this.state.match.homeName} vs {this.state.match.away?.displayName ?? this.state.match.awayName}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> : undefined
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
											<div className="elementor-widget-container">
												<div id="trx_sc_title_789087444" className="sc_title sc_title_default mb-5 text-center">
													<h1 className=" m-0 sc_item_subtitle sc_align_center">
														<span className="sc_item_title_text">Match Card</span>
													</h1>
													<h2 className=" m-0 sc_item_title sc_align_center">
														<span className="sc_item_title_text">{this.state.division.name}</span>
													</h2>
													<p className="text-center">{this.state.competition.name}</p>
												</div>
											</div>
										</div>
										<div className="match-details">
											<h3 className="">Details</h3>
											<div className="row">
												<div className="col-6 col-md-3 offset-md-3">
													<h5 className="text-center sub-title">Date</h5>
													<p className="text-center">
														<span>{this.state.match.date != null ? moment(this.state.match.date).format("Do MMMM YYYY") : "TBD"}</span>
														{this.state.match.time ? <span className="d-block">{moment(this.state.match.time).format("HH:mm")}</span> : undefined}
													</p>
												</div>
												<div className="col-6 col-md-3">
													<h5 className="text-center sub-title">Venue</h5>
													<p className="text-center">{this.state.match.venue}</p>
												</div>
											</div>
											<div className="row">
												<div className="col-5">
													<h2 className="text-center">{this.state.match.home?.score}</h2>
													{this.state.match.homeHandicap && <div>
														<h6 className="text-center">Handicap</h6>
														<h5 className="text-center">{this.state.match.homeHandicap}</h5>
													</div>}
													<h4 className="text-center"><Link to={`/league/${this.competitionId}/team/${this.state.match.home?.teamId}`}>{this.state.match.home?.displayName ?? this.state.match.homeName}</Link></h4>
													<div>
														<p className="text-center">
															{
																this.state.results ?
																	<div>
																		{
																			this.state.results.home.map(s => {
																				return (<span key={`result-${s.userId}`} className="d-block"><Link to={`/league/${this.competitionId}/player/${s.userId}`}>{s.name}</Link> ({s.score}) {!this.state.match.home.members.some(m => m.memberId === s.userId) && this.state.competition.showReserves && <i title="Reserve" className="far fa-arrow-circle-up text-info" />}</span>)
																			})
																		}
																	</div> : undefined
															}
														</p>
													</div>
												</div>
												<div className="col-2 d-flex justify-content-center flex-column">
													<h3 className="text-center">vs</h3>
												</div>
												<div className="col-5">
													<h2 className="text-center">{this.state.match.away?.score}</h2>
													{this.state.match.awayHandicap && <div>
														<h6 className="text-center">Handicap</h6>
														<h5 className="text-center">{this.state.match.awayHandicap}</h5>
													</div>}
													<h4 className="text-center"><Link to={`/league/${this.competitionId}/team/${this.state.match.away?.teamId}`}>{this.state.match.away?.displayName ?? this.state.match.awayName}</Link></h4>
													<div>
														<p className="text-center">
															{
																this.state.results ?
																	<div>
																		{
																			this.state.results.away.map(s => {
																				return (<span key={`result-${s.userId}`} className="d-block"><Link to={`/league/${this.competitionId}/player/${s.userId}`}>{s.name}</Link> ({s.score}) {!this.state.match.away.members.some(m => m.memberId === s.userId) && this.state.competition.showReserves && <i title="Reserve" className="far fa-arrow-circle-up text-info" />}</span>)
																			})
																		}
																	</div> : undefined
															}
														</p>
													</div>
												</div>
											</div>
											{
												this.state.match.potm ?
													<div className="row">
														<div className="col-12 col-md-6 offset-md-3 text-center">
															<p className="m-0">Player of the Match</p>
															<h5 className="m-0 text-center">{this.state.match.potm.name}</h5>
														</div>
													</div> : undefined
											}
											{
												this.state.match.hasReport ?
													<div className="row">
														<div className="col-12 col-md-6 offset-md-3 text-center">
															<Link to={`/league/${this.competitionId}/division/${this.divisionId}/match/${this.matchId}/report`}>Match Report</Link>
														</div>
													</div> : undefined
											}
										</div>
										{
											this.state.match.approved &&
												<div>
													<div className="match-sets">
														<h3 className="">Sets</h3>
														<div className="sp-template sp-template-event-performance sp-template-event-performance-values d-none d-md-block">
															<div className="sp-table-wrapper">
																<div className="sp-scrollable-table-wrapper"><div id="DataTables_Table_1_wrapper" className="dataTables_wrapper">
																	<table className="sp-event-performance sp-data-table sp-scrollable-table sp-sortable-table dataTable" id="DataTables_Table_1" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-number sorting" tabIndex={0} rowSpan={1} colSpan={1}>#</th>
																				<th></th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																					Home Team
																				</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																					Away Team
																				</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Games</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Score</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.sets.map(s => {
																					return (
																						<tr className="lineup odd" role="row">
																							<td className="data-number">{s.ordering + 1}</td>
																							<td title={this.scratchInfo(s)}>{s.players.some(p => p.scratch != null) && <span><i className="icon far fa-claw-marks"></i></span>}</td>
																							<td className="data-player" title={this.scratchInfo(s)}>
																								<p>{
																									s.homePlayers.map(p => {
																										return (
																											<div>
																												{p.forfeit && <p className="m-0 text-danger">{p.forfeitReason.length > 0 ? p.forfeitReason : "Forfeit"}</p>}
																												{p.userId && <Link to={`/league/${this.state.competition.id}/player/${p.userId}`} className={`d-block ${s.homeScore > s.awayScore ? "tx-medium" : ""}`}>{p.name} {(p.userId && !this.state.match.home.members.some(m => m.memberId === p.userId)) && this.state.competition.showReserves && <i title="Reserve" className="far fa-arrow-circle-up text-info" />}</Link>}
																												{!p.userId && <p className="m-0 tx-medium">{p.name}</p>}
																											</div>
																										);
																									})
																								}</p>
																							</td>
																							<td className="data-player" title={this.scratchInfo(s)}>
																								{
																									s.awayPlayers.map(p => {
																										return (
																											<div>
																												{p.forfeit && <p className="m-0 text-danger">{p.forfeitReason.length > 0 ? p.forfeitReason : "Forfeit"}</p>}
																												{p.userId && <Link to={`/league/${this.state.competition.id}/player/${p.userId}`} className={`d-block ${s.awayScore > s.homeScore ? "tx-medium" : ""}`}>{p.name} {!this.state.match.away.members.some(m => m.memberId === p.userId) && this.state.competition.showReserves && <i title="Reserve" className="far fa-arrow-circle-up text-info" />}</Link>}
																												{!p.userId && <p className="m-0 tx-medium">{p.name}</p>}
																											</div>
																										);
																									})
																								}
																							</td>
																							<td className="data-games">
																								<p className="m-0">
																									{
																										s.games.map(g => {
																											return (g.home != null && g.away != null ? <span className="mr-3">{g.home} - {g.away}</span> : undefined);
																										})
																									}
																								</p>

																								
																							</td>
																							<td className="data-player">
																								{(this.state.division.resultDisplay ?? this.state.competition.resultDisplay) !== ResultDisplay.Points ? <p>
																									<span>{s.homeScore} - {s.awayScore}</span>
																								</p>
																								:
																								<p>
																									{s.games.filter(g => g.home != null && g.away != null).length > 0 ? <span>{s.games.reduce((a, b) => {a += b.home; return a}, 0)} - {s.games.reduce((a, b) => {a += b.away; return a}, 0)}</span> : <span>-</span>}
																								</p>}
																							</td>
																						</tr>
																					);
																				})
																			}
																			{this.state.competition.teamHandicap && <tr className="lineup odd" role="row">
																				<td className="data-number"></td>
																				<td className="data-player">
																					<p>Net Handicap</p>
																				</td>
																				<td className="data-player"></td>
																				<td className="data-player"></td>
																				<td className="data-games"></td>
																				<td className="data-player">
																					<p>
																						{this.state.match.homeHandicap != null && this.state.match.awayHandicap != null ? (this.state.match.homeHandicap > this.state.match.awayHandicap ? <span>{this.state.match.homeHandicap - this.state.match.awayHandicap} - 0</span> : <span>0 - {this.state.match.awayHandicap - this.state.match.homeHandicap}</span>) : <span>-</span>}
																					</p>
																				</td>
																			</tr>}
																		</tbody>
																	</table>
																</div>
																</div>
															</div>
														</div>
														<div className="d-md-none">
															{
																this.state.sets.map(s => {
																	return (
																		<div className="set card mb-3">
																			<div className="row">
																				<div className="col-12 text-center">
																					<h6>Set {s.ordering + 1}</h6>
																				</div>
																			</div>
																			<div className="row">
																				<div className={`col-lg-3 col-md-8 name`} title={this.scratchInfo(s)}>
																					<div className="row">
																						<div className="col-2 text-center">
																							{s.players.some(p => p.scratch != null) && <span><i className="icon far fa-claw-marks"></i></span>}
																						</div>
																						<div className="col-6 text-center">
																							<p>{
																								s.homePlayers.map(p => {
																									return (
																										<span className={`d-block ${s.homeScore > s.awayScore ? "tx-medium" : ""}`}>{p.name}</span>
																									);
																								})
																							}</p>
																						</div>
																						<div className="col-6 text-center">
																							<p>{
																								s.awayPlayers.map(p => {
																									return (
																										<span className={`d-block ${s.awayScore > s.homeScore ? "tx-medium" : ""}`}>{p.name}</span>
																									);
																								})
																							}</p>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-6 text-center">
																					<h3 className="mt-0">
																					{(this.state.division.resultDisplay ?? this.state.competition.resultDisplay) !== ResultDisplay.Points ? <span>{s.homeScore}</span>
																					:
																					<span>{s.games.reduce((a, b) => {a += b.home; return a}, 0)}</span>}
																					</h3>
																				</div>
																				<div className="col-6 text-center">
																					<h3 className="mt-0">
																					{(this.state.division.resultDisplay ?? this.state.competition.resultDisplay) !== ResultDisplay.Points ? <span>{s.awayScore}</span> 
																					:
																					<span>{s.games.reduce((a, b) => {a += b.away; return a}, 0)}</span>}
																					</h3>
																				</div>
																			</div>
																			<div className="row">
																				<div className="col-12 text-center">
																					<p>
																						{
																							s.games.map(g => {
																								return (g.home != null && g.away != null ? <span className="mr-3">{g.home} - {g.away}</span> : undefined);
																							})
																						}
																					</p>
																				</div>
																			</div>

																		</div>
																	);
																})
															}
														</div>
													</div>
													<div className="match-approval">
														<div className="row">
															<div className="col-12">
																<span className="tx-bold">Submitted</span> {this.state.match.submittedBy.name}
															</div>
															<div className="col-12">
																<span className="tx-bold">Approved</span> {this.state.match.approvedBy.name}
															</div>
															<div className="col-12">
																<span className="tx-bold">Last Updated</span> <span title={moment(this.state.match.approved).format("Do MMMM YYYY")}>{moment(this.state.match.approved).fromNow()}</span>
															</div>
														</div>
													</div>
												</div>
												//:
												}
												{this.state.entry !== MatchCardEntryLevel.None && <div className="text-center">
													{(this.state.competition.fastFormat || (this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) == 5) && this.state.match.date == null ?
														<div>
															<div className="text-center">
																<Link to={`/league/${this.state.competition.id}/division/${this.state.division.id}/match/${this.state.match.id}/card/schedule`} className="btn btn-rounded btn-primary">Schedule</Link>
															</div>
															<div className="text-center">
																<Link to={`/league/${this.state.competition.id}/division/${this.state.division.id}/match/${this.state.match.id}/card/play`} className="btn btn-rounded btn-primary">Play Now</Link>
															</div>
														</div>
														:
														<div>
														{moment(this.state.match.date) >= moment() || this.state.match.approved || this.state.entry == 4 ?
															<div className="text-center">
																<Link to={`/league/${this.state.competition.id}/division/${this.state.division.id}/match/${this.state.match.id}/card`} className="btn btn-rounded btn-primary">View Sheet</Link>
															</div> :
															<div className="text-center">
																{
																	this.state.match.date != null &&
																	<Link to={`/league/${this.state.competition.id}/division/${this.state.division.id}/match/${this.state.match.id}/card`} className="btn btn-rounded btn-primary">Enter Scores</Link>
																}
															</div>}
														</div>
													}
												</div>}
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
