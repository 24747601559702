import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { CreateTeam, Team, TeamMember } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CreateDivision, Division, DivisionProgress, DivisionStatus } from "@models/division";
import { Competition, CompetitionMember, CreateCompetition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { Entry, EntryStatus } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { confirmAlert } from "react-confirm-alert";
import { CompetitionService } from "@services/competition.service";
import { DatatableMeta, DatatableQuery, DatatableResponse } from "@models/query";
import { Paging } from "@components/controls/Paging";
import ObjectHelper from "@helpers/object.helper";
import { HelpPopover } from "@components/controls/Popover";
import { Options } from "@data";
import { FormatService } from "@services/format.service";
import { PointService } from "@services/point.service";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CreateEntrant, Entrant, EntrantType } from "@models/entrant";
import { MatchService } from "@services/match.service";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ArrayHelper } from "@helpers/array.helper";
import CreateCompetitionSidebar from "@components/sidebar/public/competition/CreateCompetitionSidebar";
import CreateDivisionSidebar from "@components/sidebar/public/competition/CreateDivisionSidebar";
import CreateTeamSidebar from "@components/sidebar/public/competition/CreateTeamSidebar";
import AssignTeamMembersSidebar from "@components/sidebar/public/competition/AssignTeamMembersSidebar";
import AssignEntrantsSidebar from "@components/sidebar/public/competition/AssignEntrantsSidebar";
import { SelectOption } from "@models/forms";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import CompetitionWizardModal from "@components/modals/CompetitionWizardModal";

interface ICompetitionMemberPageProps {
    site?: string;
    match?: any;
    location?: any;
}

export default (props: ICompetitionMemberPageProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [competitions, setCompetitions] = useState<DatatableResponse<Competition>>();
    const [archived, setArchived] = useState<DatatableResponse<Competition>>();
    const [meta, setMeta] = useState<DatatableMeta>({ paging: { pageSize: AppCache.settings.pageSize, pageNo: 1 } });
    const [archiveMeta, setArchiveMeta] = useState<DatatableMeta>({ paging: { pageSize: AppCache.settings.pageSize, pageNo: 1 } });

    const [competition, setCompetition] = useState<Competition>();
    const [division, setDivision] = useState<Division>();
    const [team, setTeam] = useState<Team>();

    const [editModal, setEditModal] = useState<boolean>();
    const [divisions, setDivisions] = useState<Division[]>();
    const [teamMeta, setTeamMeta] = useState<DatatableMeta>({ paging: { pageSize: 5, pageNo: 1 } });
    const [teams, setTeams] = useState<Team[]>();

    const [createCompetition, setCreateCompetition] = useState<boolean>();
    const [createDivision, setCreateDivision] = useState<boolean>();
    const [createTeam, setCreateTeam] = useState<boolean>();
    const [assignEntrants, setAssignEntrants] = useState<boolean>();
    const [assignTeamMembers, setAssignTeamMembers] = useState<boolean>();

    const [roleMembers, setRolesMembers] = useState<CompetitionMember[]>([]);
    const [member, setMember] = useState<SelectOption<string>>();
    const [memberRole, setMemberRole] = useState<CompetitionMember>();

    const [activeTab, setActiveTab] = useState<string>("Competitions");
    const [modalTab, setModalTab] = useState<string>("Divisions");

    const [showWizard, setShowWizard] = useState<boolean>(false);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (competition != null)
            loadTeams(competition)
    }, [teamMeta.paging.pageNo]);

    useEffect(() => {
        loadCompetitions();
    }, [meta.paging.pageNo, meta.paging.pageSize]);

    useEffect(() => {
        loadArchives();
    }, [archiveMeta.paging.pageNo, archiveMeta.paging.pageSize]);

    const load = async () => {
        loadCompetitions();

        loadArchives();

        setLoading(false);
    };

    const loadCompetitions = async () => {
        const query = new DatatableQuery();
        query.paging = meta.paging;

        const competitions = await CompetitionService.getByCompetitionRoles(query, false, null, false);
        setCompetitions(competitions);
        setMeta(competitions.meta);
    };

    const loadArchives = async () => {
        const archivedQuery = new DatatableQuery();
        archivedQuery.paging = archiveMeta.paging;

        const archived = await CompetitionService.getByCompetitionRoles(archivedQuery, true, null, true);
        setArchived(archived);
        setArchiveMeta(archived.meta);
    };

    const toggle = (tab: string) => (e) => {
        setActiveTab(tab);
    }

    const handleCancelClick = (competition: Competition) => (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>This will permanently delete this Competition.</p>
                        <a className="btn btn-secondary text-white mr-2"
                            onClick={() => {
                                CompetitionService.delete(competition.id).then(() => {
                                    load();
                                    onClose();
                                    toast.success("Competition Removed");
                                });
                            }}
                        >
                            Yes, delete it!
                        </a>
                        <a className="btn btn-primary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const handleCancelTeamClick = (team: Team) => (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>This will permanently delete this Division.</p>
                        <a className="btn btn-secondary text-white mr-2"
                            onClick={() => {
                                TeamService.delete(team.id).then(() => {
                                    loadTeams(competition);
                                    onClose();
                                    toast.success("Team Removed");
                                });
                            }}
                        >
                            Yes, delete it!
                        </a>
                        <a className="btn btn-primary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const handleCancelRoleClick = (member: CompetitionMember) => (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>This will permanently delete this Competition Member.</p>
                        <a className="btn btn-secondary text-white mr-2"
                            onClick={() => {
                                CompetitionService.removeMember(member.userId, member.competitionId).then(() => {
                                    loadRoles(competition);
                                    onClose();
                                    toast.success("Competition Member Removed");
                                });
                            }}
                        >
                            Yes, delete it!
                        </a>
                        <a className="btn btn-primary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const handleChangePageSize = () => (pageSize: number) => {
        setMeta({ ...meta, paging: { ...meta.paging, pageSize } });
    }

    const handlePageChange = () => (pageNo: number) => {
        setMeta({ ...meta, paging: { ...meta.paging, pageNo } });
    };

    const handleChangeArchivePageSize = () => (pageSize: number) => {
        setArchiveMeta({ ...archiveMeta, paging: { ...archiveMeta.paging, pageSize } });
    }

    const handleArchivePageChange = () => (pageNo: number) => {
        setArchiveMeta({ ...archiveMeta, paging: { ...archiveMeta.paging, pageNo } });
    };

    const handleTeamPageChange = () => (pageNo: number) => {
        setTeamMeta({ ...teamMeta, paging: { ...teamMeta.paging, pageNo } });
    };

    const loadDivisions = async (competition: Competition) => {
        const p2 = DivisionService.get(competition.id).then((divisions) => {
            setDivisions(divisions);
        });
    };

    const loadTeams = async (competition: Competition) => {
        const query = new DatatableQuery();
        query.paging = teamMeta.paging;

        // if (this.state.search) {
        // 	query.search = { value: this.state.search };
        // }

        TeamService.data(query, competition.id, null, null, false).then(result => {
            setTeams(result.data);
            setTeamMeta(result.meta);
        });
    };

    const loadRoles = async (competition: Competition) => {

        CompetitionService.getCompetitionMembers(competition.id).then(result => {
            setRolesMembers(result);
        });
    };


    const handleEditCompetition = (competition: Competition) => (e) => {
        const p1 = CompetitionService.getById(competition.id).then(result => {
            FormatService.get().then(formats =>
                result.format = formats.find(f => f.id == result.formatId)
            );
            PointService.get().then(points =>
                result.point = points.find(p => p.id == result.pointId))
            setCompetition(result);
        });

        const p2 = loadDivisions(competition);

        const p3 = loadTeams(competition);

        Promise.all([p1, p2, p3]).then(result => {
            setEditModal(true);
        })
    };

    const handleCreateCompetition = () => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateCompetition(true);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
        setRolesMembers(null);
    }

    const handleCreateDivision = () => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateDivision(true);
        setCreateCompetition(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
        setRolesMembers(null);
    }

    const handleCreateTeam = () => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateTeam(true);
        setCreateCompetition(false);
        setCreateDivision(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
        setRolesMembers(null);
    }

    const handleManageEntrants = (division: Division) => {
        setDivision(division);
        setTeam(null);
        setAssignEntrants(true);
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignTeamMembers(false);
        setRolesMembers(null);
    };

    const handleManageTeamMembers = (team: Team) => (e) => {
        setTeam(team);
        setDivision(null);
        setAssignTeamMembers(true);
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setRolesMembers(null);
    };

    const handleManageRoles = (competition: Competition) => (e) => {
        loadRoles(competition);
        setDivision(null);
        setTeam(null);
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
    };

    const handleAssignRole = () => () => {
        if (memberRole.userId?.length > 0 && memberRole.role != null)
            CompetitionService.assignMember(memberRole).then(result => {
                loadRoles(competition);
                setMember(null);
                setMemberRole(null);
            })
    };

    const closeEditModal = () => (e) => {
        setEditModal(false);
        setCompetition(null);
        setTeams(null);
        setDivisions(null);
        setModalTab("Divisions");
        setTeamMeta({ paging: { pageSize: 5, pageNo: 1 } });
        cancel();
    }

    const cancel = () => {
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
        setRolesMembers(null);
    }

    const loadMemberOptions = () => (value: string): Promise<SelectOption<string>[]> => {
        return new Promise(resolve => {
            MemberService.search({
                search: value,
                competitionId: competition.id,
                email: false,
                player: false,
                assignment: false
            }).then(members => {
                const options = members.filter(m => roleMembers.every(r => r.name != m.name)).map(m => ({ label: m.name, value: m.id }));
                resolve(options);
            });
        });
    }

    const handleChangeMember = () => (option: SelectOption<string>) => {
        setMember(option);
        setMemberRole({ ...memberRole, userId: option.value })
    }

    const handleChangeRole = () => (option: SelectOption<number>) => {
        setMemberRole({ ...memberRole, role: option.value })
    }

    const publish = (competition: Competition) => () => {
        if (!LoginService.allow(["manage:full:?", "leagues:?"]))
            return false;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>
                            {competition.published ?
                                <span>Unpublish competition?</span> :
                                <span>Publish competition?</span>
                            }
                        </p>
                        <a className={`btn btn-${competition.published ? "danger" : "success"} text-white mr-2`}
                            onClick={() => {
                                CompetitionService.publish(competition.id).then(result => {
                                    load();
                                });
                                onClose();
                            }}>
                            Yes, {competition.published ? <span>Unpublish</span> : <span>Publish</span>} it!
                        </a>
                        <a className="btn btn-secondary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const sidebar = () => {
        if (createCompetition) {
            return (<CreateCompetitionSidebar onConfirm={() => load()} onClose={() => setCreateCompetition(false)} />);
        }

        if (createDivision) {
            return (<CreateDivisionSidebar competition={competition} onConfirm={() => loadDivisions(competition)} onClose={() => setCreateDivision(false)} />);
        }

        if (createTeam) {
            return (<CreateTeamSidebar competition={competition} divisions={divisions} onConfirm={() => loadTeams(competition)} onClose={() => setCreateTeam(false)} />);
        }

        if (assignEntrants) {
            return (<AssignEntrantsSidebar competition={competition} division={division} onClose={() => { setAssignEntrants(false); setDivision(null); }} />);
        }

        if (assignTeamMembers) {
            return (<AssignTeamMembersSidebar competition={competition} team={team} onClose={() => { setAssignTeamMembers(false); setTeam(null); }} />);
        }

        return <></>;
    }

    const openSidebar = () => {
        if (createCompetition) {
            return true;
        }
        if (createDivision) {
            return true;
        }
        if (createTeam) {
            return true;
        }
        if (assignEntrants) {
            return true;
        }
        if (assignTeamMembers) {
            return true;
        }

        return false;
    }


    return (
        <PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
            <ShowMe
                visible={!loading}
                mode={ShowMeMode.Full}
                progress={true}
                render={() => (
                    <Sidebar
                        sidebar={sidebar()}
                        pullRight={true}
                        open={openSidebar()}
                        sidebarClassName="sidebar-root"
                        styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
                        <div>
                            <div className="page_content_wrap">
                                <div className="container">
                                    <Row>
                                        <Col>
                                            <Card className="mb-5 card--nav">
                                                <Row className="pt-4 px-4">
                                                    <Col md={12}>
                                                        <div className="name">
                                                            <h3 className="m-0">Leagues</h3>
                                                            <div>
                                                                <p className="text-muted d-block m-0">Manage your fast format leagues</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Nav>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === "Competitions" })}
                                                            onClick={toggle("Competitions")}>
                                                            Competitions
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === "Archived" })}
                                                            onClick={toggle("Archived")}>
                                                            Archived
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="ml-auto">
                                                        {
                                                            activeTab === "Competitions" &&
                                                            <>
                                                                <a className="btn btn--circle text-white float-right" onClick={handleCreateCompetition()}><i className="far fa-plus" /></a>
                                                                <a className="btn btn--circle text-white float-right mr-2" onClick={() => setShowWizard(true)}><i className="far fa-wand-magic-sparkles" /></a>
                                                            </>
                                                        }
                                                    </NavItem>
                                                </Nav>
                                            </Card>
                                            <div>

                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId="Competitions">
                                                        {
                                                            competitions && competitions.data.length === 0 &&
                                                            <div className="empty text-center py-5 my-5">
                                                                <i className="fa-light fa-table-tennis-paddle-ball fa-4x text-primary mb-3"></i>
                                                                <p>
                                                                    No competitions available, create your first competition
                                                                </p>
                                                                <a className="btn btn-secondary text-white btn-rounded mr-2" onClick={() => setShowWizard(true)}>Setup Wizard</a>
                                                                <a className="btn btn-primary btn-rounded" onClick={handleCreateCompetition()}>Setup Manually</a>
                                                            </div>
                                                        }
                                                        <Row>
                                                            {
                                                                competitions && competitions.data.map(c => (
                                                                    <Col xs={12}>
                                                                        <Card className="mb-3 p-3">
                                                                            <Row>
                                                                                <Col lg={5} className="d-flex align-self-center">
                                                                                    <div className="col">
                                                                                        <h6 className="m-0">{c.name}</h6>
                                                                                        <p className="m-0 small">{c.description}</p>
                                                                                        <p className="m-0 small">{moment(c.startDate).format("DD/MM/YYYY")}</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={2} className="d-flex justify-content-center align-self-center">

                                                                                </Col>
                                                                                <Col lg={1} className="d-flex justify-content-center align-self-center">

                                                                                </Col>
                                                                                <Col lg={4} className="d-flex justify-content-end align-self-center">
                                                                                    {
                                                                                        c.published ?
                                                                                            <a className="my-auto mr-4 text-success clickable" title={`Published ${moment(c.published).format("Do MMMM YYYY")}`} id={`unpublish-league-${c.id}`} onClick={publish(c)}><i className="far fa-eye fa-lg" /></a> :
                                                                                            <a className="my-auto mr-4 text-primary clickable" title={`Unpublished`} id={`publish-league-${c.id}`} onClick={publish(c)}><i className="far fa-eye-slash fa-lg" /></a>
                                                                                    }
                                                                                    <Link to={`/member/leagues/${c.id}`} className="btn btn--circle text-white mr-2"><i className="far fa-pencil" /></Link>
                                                                                    <Link to={`/league/${c.id}`} className="btn btn--circle text-white mr-2"><i className="far fa-search" /></Link>
                                                                                    <a className="btn btn--circle bg-danger text-white" onClick={handleCancelClick(c)}><i className="far fa-times" /></a>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                        <Paging onChangePageSize={handleChangePageSize()} onChangePage={handlePageChange()} meta={meta} />
                                                    </TabPane>
                                                    <TabPane tabId="Archived">
                                                        <Row>
                                                            {
                                                                archived && archived.data.map(c => (
                                                                    <Col xs={12}>
                                                                        <Card className="mb-3 p-3">
                                                                            <Row>
                                                                                <Col lg={5} className="d-flex align-self-center">
                                                                                    <div className="col">
                                                                                        <h6 className="m-0">{c.name}</h6>
                                                                                        <p className="m-0 small">{c.description}</p>
                                                                                        <p className="m-0 small">{moment(c.startDate).format("dd/MM/yyyy")}</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col lg={2} className="d-flex justify-content-center align-self-center">

                                                                                </Col>
                                                                                <Col lg={2} className="d-flex justify-content-center align-self-center">

                                                                                </Col>
                                                                                <Col lg={1} className="d-flex justify-content-center align-self-center">

                                                                                </Col>
                                                                                <Col lg={2} className="d-flex justify-content-end align-self-center">
                                                                                    <Link to={`/league/${c.id}`} className="btn btn--circle text-white mr-2"><i className="far fa-search" /></Link>
                                                                                    <a className="btn btn--circle text-white" onClick={handleCancelClick(c)}><i className="far fa-ban" /></a>
                                                                                </Col>
                                                                            </Row>
                                                                        </Card>
                                                                    </Col>
                                                                ))
                                                            }
                                                        </Row>
                                                        <Paging onChangePageSize={handleChangeArchivePageSize()} onChangePage={handleArchivePageChange()} meta={archiveMeta} />
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Sidebar>)} />
            <CompetitionWizardModal show={showWizard} fastFormat={true} onClose={() => setShowWizard(false)} onConfirm={load} />
        </PublicLayout>
    );
}