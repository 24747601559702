import { Entry, EntryCategory, EntryPlayer, EntryTeam, RegisterEntryTeam, VenuePreference } from "@models/entry";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, CardBody } from "reactstrap";

import CreatableSelect from "react-select/creatable"
import AsyncSelect from 'react-select/async';
import { Options } from "@data";
import { EntryService } from "@services/entry.service";
import { Registration, RegistrationCategory, RegistrationCompetitionType, RegistrationEntrantType, SharedTenant } from "@models/registration";
import { SelectOption } from "@models/forms";
import { MemberService } from "@services/member.service";
import CategoryEntry from "@pages/manage/registrations/components/CategoryEntry";

import Select from "react-select";
import { te } from "date-fns/locale";

interface IUpdateTeamSidebar {
	team: EntryTeam;
	entryId: number;
	registration: Registration;
	category: RegistrationCategory;
	onClose?: () => void;
	onConfirm?: (team: EntryTeam) => void;
}

export default (props: IUpdateTeamSidebar) => {
	const [team, setTeam] = useState<EntryTeam>(props.team);
	const [sharedTenants, setSharedTenants] = useState<SharedTenant[]>(props.registration.sharedTenants);
	const [existing, setExisting] = useState<EntryTeam[]>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const existing = await EntryService.teams({ entryId: props.entryId, deleted: false });
		setExisting(existing);
	};

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {
		const entryTeam = team;
		entryTeam.players = [];
		entryTeam.category = null;
		await EntryService.upsertTeam(team);

		props.onConfirm(entryTeam);
		props.onClose();
	};

	const handleTeamChange = () => (existing: EntryTeam) => {
		const _team = { ...team };
		_team.addressLine1 = existing.addressLine1;
		_team.addressLine2 = existing.addressLine2;
		_team.addressLine3 = existing.addressLine3;
		_team.town = existing.town;
		_team.county = existing.county;
		_team.postcode = existing.postcode;
		_team.telephone = existing.telephone;
		_team.email = existing.email;
		_team.contact = existing.contact;
		_team.shirtColors = existing.shirtColors;
		_team.shortColors = existing.shortColors;

		setTeam(_team);
	};

	const handlePreferenceChange = (index: number) => (tenant: SharedTenant) => {
		var preference = new VenuePreference();
		preference.entryId = team.entryId;
		preference.teamId = team.id;
		preference.position = index;
		preference.tenantId = tenant.tenantId;

		var preferences = team.venuePreference ?? [];

		preferences[index - 1] = preference;

		setTeam({...team, venuePreference: preferences});
	};

	const bulidPreferencesForm = () => {
		var form = [];
		for (let index = 1; index <= props.registration.noOfPreferences; index++) {
			var item = <FormGroup>
				<label className="form-control-label mb-0">Preference {index} <span className="tx-danger">*</span></label>
				<Select
					className="mb-2"
					options={sharedTenants.filter(t => team.venuePreference.every(p => p.tenantId != t.tenantId))}
					value={sharedTenants.find(t => t.tenantId == team.venuePreference[index - 1]?.tenantId)}
					getOptionLabel={(o) => o.displayName}
					getOptionValue={(o) => o.tenantId}
					placeholder="Select venue"
					onChange={handlePreferenceChange(index)}
				/>
			</FormGroup>
			form.push(item);	
		}

		return form;
	};

	return (
		<div className="sidebar" style={{ width: "600px" }}>
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="m-0">Register Team
					<div className="help-container">
						<button className="btn p-0 tx-20" onClick={props.onClose}>
							<i className="far fa-times"></i>
						</button>
					</div></h6>
			</div>
			<div className="sidebar--content">
				<label>Copy existing team data</label>
				<Select
					className="mb-2"
					options={existing}
					getOptionLabel={(o) => o.title}
					getOptionValue={(o) => o.id}
					placeholder="Select existing team"
					onChange={handleTeamChange()}
				/>
				{
					team &&
					<>
						<h5 className="mt-3 mb-1">Team Details</h5>
						<FormGroup>
							<label className="form-control-label mb-0">Name <span className="tx-danger">*</span></label>
							<input className="form-control" type="text" value={team.name} onChange={e => setTeam({ ...team, name: e.currentTarget.value })} />
						</FormGroup>
						<Row>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Short Name</label>
									<input className="form-control" type="text" value={team.shortName} onChange={e => setTeam({ ...team, shortName: e.currentTarget.value })} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Abbreviation</label>
									<input className="form-control" type="text" value={team.abbreviation} onChange={e => setTeam({ ...team, abbreviation: e.currentTarget.value })} />
								</FormGroup>
							</Col>
						</Row>
						{props.category.requireTeamGender == true && <Row>
							<FormGroup>
								<label className="form-control-label mb-0">Gender Composition <span className="tx-danger">*</span></label>
								<Select
									className="mb-2"
									value={Options.teamGender.find(g => g.value == team.genderComposition)}
									options={Options.teamGender}
									placeholder="Select team Gender Composition"
									onChange={e => setTeam({ ...team, genderComposition: e.value })}
									/>
							</FormGroup>
						</Row>}
						<h5 className="mb-1">Contact Details</h5>
						<FormGroup>
							<label className="form-control-label mb-0">Contact Name <span className="tx-danger">*</span></label>
							<input className="form-control" type="text" value={team.contact} onChange={e => setTeam({ ...team, contact: e.currentTarget.value })} />
						</FormGroup>
						<Row>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Address Line 1 <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" value={team.addressLine1} onChange={e => setTeam({ ...team, addressLine1: e.currentTarget.value })} />
								</FormGroup>

							</Col>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Address Line 2</label>
									<input className="form-control" type="text" value={team.addressLine2} onChange={e => setTeam({ ...team, addressLine2: e.currentTarget.value })} />
								</FormGroup>

							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Address Line 3</label>
									<input className="form-control" type="text" value={team.addressLine3} onChange={e => setTeam({ ...team, addressLine3: e.currentTarget.value })} />
								</FormGroup>

							</Col>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Town/City</label>
									<input className="form-control" type="text" value={team.town} onChange={e => setTeam({ ...team, town: e.currentTarget.value })} />
								</FormGroup>

							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">County</label>
									<input className="form-control" type="text" value={team.county} onChange={e => setTeam({ ...team, county: e.currentTarget.value })} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Postcode <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" value={team.postcode} onChange={e => setTeam({ ...team, postcode: e.currentTarget.value })} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Phone Number <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" value={team.telephone} onChange={e => setTeam({ ...team, telephone: e.currentTarget.value })} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Email Address <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" value={team.email} onChange={e => setTeam({ ...team, email: e.currentTarget.value })} />
								</FormGroup>
							</Col>
						</Row>
						<h5 className="mb-1">Colours</h5>
						<Row>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Shirt Colours {props.registration?.requireKitDetails && <span className="tx-danger">*</span>}</label>
									<input className="form-control" type="text" value={team.shirtColors} onChange={e => setTeam({ ...team, shirtColors: e.currentTarget.value })} />
								</FormGroup>
							</Col>
							<Col>
								<FormGroup>
									<label className="form-control-label mb-0">Short/Skirt/Legging Colours {props.registration?.requireKitDetails && <span className="tx-danger">*</span>}</label>
									<input className="form-control" type="text" value={team.shortColors} onChange={e => setTeam({ ...team, shortColors: e.currentTarget.value })} />
								</FormGroup>
							</Col>
						</Row>
						{props.registration.venuePreference && <div>
							<h5 className="mb-1">Venue Preference</h5>
							{bulidPreferencesForm()}
						</div>}
					</>
				}
			</div>
			<div className="sidebar--footer">
				<Row className="mb-4">
					<Col>
						<button className="btn btn-primary btn-rounded btn-outline bd-0 btn-block" type="button" onClick={handleConfirm()}>Confirm</button>
					</Col>
					<Col>
						<button className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block" type="button" onClick={handleClose()}>Cancel</button>
					</Col>
				</Row>
			</div>
		</div>
	);
};