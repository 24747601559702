import React, { useContext, useEffect, useState } from "react";

import { Can } from "@components/controls/Authentication";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { Row, Col, Container, Nav, NavItem, NavLink, Navbar, NavbarBrand, NavbarToggler, Card, CardHeader, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Input, Label } from "reactstrap";

import { LoginService } from "../../services/login.service";
import { ArticleService } from "@services/article.service";

import { AppConfig, ReleaseType } from "@config";
import { Website, WebsiteImageType, CompetitionMenuDisplay, WebsiteCustomMenuItem, WebsiteMenuType, WebsiteMenuPreset, WebsiteMenuItem, ContactFormDetails } from "@models/website";
import { NewsItem } from "@models/article";

import { ToastContainer, ToastOptions, ToastPosition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "../controls/ScrollToTop";

import "popper.js";
import moment from "moment";
import History from "@helpers/history.helper";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { AppCache } from "@cache";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { ModuleType, TenantType } from "@models/tenant";
import { ShowMe } from "@components/controls/ShowMe";
import ObjectHelper from "@helpers/object.helper";
import { Options } from "@data";
import { Roles } from "@consts";
import { Toggle } from "@components/controls/Toggle";
import RestHelper from "@helpers/rest.helper";
import { WebsiteContext, WebsiteProvider } from "@context/BasketContext";
import { BasketService } from "@services/basket.service";
import { te } from "date-fns/locale";
import { EmailService } from "@services/email.service";
import { CreateMessage } from "@models/message";
import { MessageService } from "@services/message.service";

interface IPublicLayoutProps {
	//
	showNav?: boolean;
	showLogin?: boolean;
	overlayFooter?: boolean;
	hideScroll?: boolean;
	showFooter?: boolean;
	className?: string;
	theme?: string;
	print?: any;
	storageKey?: string;
	children?: any;
}

interface IPublicLayoutState {
	//
	loading: boolean;
	newsFeed?: NewsItem[];
	menuOpen: boolean;
	testing: boolean;
}


export default (props: IPublicLayoutProps) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [menuOpen, setMenuOpen] = useState<boolean>(false);
	const [testing, setTesting] = useState<boolean>(false);
	const [checkExpiry, setCheckExpiry] = useState<boolean>(true);
	const [newFeed, setNewsFeed] = useState<NewsItem[]>([]);
	const [contactForm, setContactForm] = useState<ContactFormDetails>(new ContactFormDetails());

	// defaultProps = {
	// 	showNav: true,
	// 	overlayFooter: false,
	// 	showLogin: true,
	// 	hideScroll: false,
	// 	showFooter: true,
	// 	className: "",
	// 	theme: "scheme_default",
	// 	storageKey: ""
	// };

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		sessionStorage.setItem("testing", testing ? "true" : "false");
	}, [testing]);

	const load = async () => {
		ArticleService.GetFeed().then(result => {
			setNewsFeed(result);
		});

		window.onscroll = detectScroll.bind(this);

		if ((AppCache.tenant.checkExpiry ? "true" : "false") != sessionStorage.getItem("checkExpiry") && AppCache.tenant.isTesting) {
			var tenant = AppCache.tenant;
			tenant.checkExpiry = (sessionStorage.getItem("checkExpiry") == "true" || sessionStorage.getItem("checkExpiry") == null);
			AppCache.tenant = tenant;
			setCheckExpiry(tenant.checkExpiry);
			RestHelper.defaults.headers.common["checkExpiry"] = AppCache.tenant.checkExpiry ? "true" : "false";
		}

		setTesting(sessionStorage.getItem("testing") == "true");
		setLoading(false);
	}

	//
	// if (this.props.storageKey)
	// 	window.scrollTo(0, parseInt(sessionStorage.getItem(this.props.storageKey)));
	// if ((AppCache.tenant.checkExpiry ? "true" : "false") != sessionStorage.getItem("checkExpiry") && this.state.loading && AppCache.tenant.isTesting) {
	// 	var tenant = AppCache.tenant;
	// 	tenant.checkExpiry = (sessionStorage.getItem("checkExpiry") == "true");
	// 	AppCache.tenant = tenant;
	// 	this.setState({ loading: false });
	// }

	const logout = async () => {
		await LoginService.logout();
		History.push("/", { prevPath: location.pathname });
	}

	const isActive = (url: string[]) => {
		for (let i = 0; i < url.length; i++) {
			const element = url[i];
			if ((element !== "/" && window.location.pathname.includes(element)) || window.location.pathname === element) {
				return "active";
			}
		}

		return "";
	}

	const detectScroll = () => {
		var scrollviewFrameHeight = window.innerHeight;
		var scrollviewContentHeight = document.documentElement.scrollHeight;
		var sum = scrollviewContentHeight - scrollviewFrameHeight;
		if (props.storageKey) {
			sessionStorage.setItem(props.storageKey, window.scrollY.toString());
		}
		if (window.scrollY === 0) {
			//the top reached
		} else if (Math.round(window.scrollY) >= sum) {
			//the bottom reached
		}
	}

	const getLink = (cm: WebsiteCustomMenuItem) => {
		let url;
		if (cm.content) {
			if (cm.content.forwardTo) {
				if (!cm.content.forwardTo.startsWith("http"))
					url = `http://${cm.content.forwardTo}`;
				else
					url = cm.content.forwardTo;
			}
			else
				url = `/page/${cm.content.slug}`;
		}
		else if (cm.preset) {
			switch (cm.preset) {
				case 1:
					return "/memberships";
				case 2:
					return "/bookings";
				case 3:
					return "/leagues";
				case 4:
					return "/articles";
				case 14:
					return "/registrations";
			}
		}
		else {
			if (!cm.url) {
				url = "#";
			}
			else if (cm.url.startsWith("/")) {
				url = cm.url;
			}
			else if (!cm.url.startsWith("http"))
				url = `https://${cm.url}`;
			else
				url = cm.url;
		}
		return url;
	}

	const handleSwitchClick = (userId: string) => async (e: any) => {
		if (LoginService.getDetails().memberId !== userId) {
			await LoginService.switch(userId);
			window.location.reload();
		}
	}

	const toggle = () => setMenuOpen(!menuOpen);

	const renderMenuItems = (menuItem: WebsiteCustomMenuItem) => {
		return (
			<>
				{menuItem.items?.length === 0 ?
					<NavItem>
						<NavLink tag={"a"} href={getLink(menuItem)}>{menuItem.name}</NavLink>
					</NavItem> :
					<UncontrolledDropdown direction={menuItem.parentId ? "right" : "down"} key={`dropdown_${menuItem.id}`} nav inNavbar title={menuItem.name}>
						<DropdownToggle nav caret className="nav-link" id={`toggle_${menuItem.id}`} key={`toggle_${menuItem.id}`}>
							{menuItem.name}
						</DropdownToggle>
						<DropdownMenu>
							<Nav>
								{
									menuItem.items.map(sb => (
										<>
											{renderMenuItems(sb)}
										</>
									))
								}
							</Nav>
						</DropdownMenu>
					</UncontrolledDropdown>
				}
			</>
		);
	}

	const submit = () => {
		MessageService.contactForm(contactForm).then(result => {
			clear();
		});
	}

	const clear = () => {
		var form = contactForm;
		form.firstName = "";
		form.lastName = "";
		form.email = "";
		form.details = "";
	}

	const Close = () => <a className="close-button"><i className="la la-times"></i></a>;

	const logo = AppCache.website.images?.find(i => i.type === WebsiteImageType.Logo);
	const footer = AppCache.website.images?.filter(i => i.type === WebsiteImageType.Footer);

	return (
		// <WebsiteProvider>
		<>
			<div className={`${props.theme}`}>
				<div className={`${props.className} ${props.print ? "d-print-none" : ""}`}>
					<Helmet titleTemplate={`%s | ${AppCache.website.title} | ${AppCache.tenant.clubId ? "TT Clubs" : "TT Leagues"}`} />
					<ScrollToTop />
					{
						AppCache.tenant.isTesting && !loading && <div className="bg-primary">
							<div className="container">
								<div className="row">
									<div className="col-11">
										<h6 className="slim-pagetitle" style={{ color: "white" }}>Testing Conditions</h6>
									</div>
									<div className="col-1" style={{ textAlign: "right" }}>
										<button onClick={() => setTesting(!testing)} className="btn btn-circle btn-icon mr-2">
											<i className={`icon text-secondary tx-20 far fa-chevron-${testing ? "up" : "down"}`}></i>
										</button>
									</div>
								</div>
							</div>
							<Collapse isOpen={testing} >
								<div className="container">
									<div className="row">
										<div className="col-3">
											<div className="card text-primary tx-semibold">
												<p className="mb-0">Player Settings</p>
												<hr style={{ backgroundColor: "black" }} />
												<div className="row">
													<div className="col">
														<div className="form-group">
															<label className="form-control-label d-block">Check Player Expiry</label>
															<Toggle checked={checkExpiry} onChange={e => {
																sessionStorage.setItem("checkExpiry", e ? "true" : "false");
																setCheckExpiry(!checkExpiry)
																setLoading(true);
																load();
															}} />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Collapse>
						</div>
					}
					<div className="body_wrap">
						<div className="page_wrap">
							<header className="top_panel top_panel_custom top_panel_custom_header-default-dark without_bg_image">
								<section className="sc_layouts_row scheme_dark">
									<nav className="bg-tertiary py-1">
										<Container>
											<ul className="navbar-nav navbar-social">
												{
													AppCache.website.twitter ?
														<li>
															<a href={`https://twitter.com/${AppCache.website.twitter}`} title="Twitter" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-x-twitter"></i></span></a>
														</li> : undefined
												}
												{
													AppCache.website.facebook ?
														<li>
															<a href={`https://facebook.com/${AppCache.website.facebook}`} title="Facebook" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-facebook"></i></span></a>
														</li> : undefined
												}
												{
													AppCache.website.instagram ?
														<li>
															<a href={`https://instagram.com/${AppCache.website.instagram}`} title="Instagram" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-instagram"></i></span></a>
														</li> : undefined
												}
												{
													AppCache.website.whatsApp ?
														<li>
															<a href={`https://wa.me/${AppCache.website.whatsApp}`} title="WhatsApp" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-whatsapp"></i></span></a>
														</li> : undefined
												}
											</ul>
										</Container>
									</nav>
									<nav className="navbar navbar-menu navbar-expand-lg">
										<div className="container">
											<Link to={"/"} className="navbar-brand">
												{
													logo ?
														<img
															className="logo-image"
															src={`${AppConfig.baseUrl}image/${logo.imageId}`}
															alt={AppCache.website.title}
															width={233}
															height={56} /> :
														<img
															className="logo-image"
															src={
																AppCache.tenant.clubId ?
																	require("../../../assets/images/brand/ttclubs-logo-white.png") :
																	require("../../../assets/images/brand/ttleagues-logo-white.png")
															}
															alt={AppCache.website.title}
															width={233}
															height={56} />
												}
											</Link>

											<div className={`${menuOpen && "show"} collapse navbar-collapse`} id="navbarSupportedContent">
												<ul className="navbar-nav ml-auto">
													{
														AppCache.website.menu.primary.map(m => {
															if ((AppCache.tenant.config.bookingsModule && Options.bookingsMenu.indexOf(m.preset) >= 0) ||
																(AppCache.tenant.config.competitionsModule && Options.competitionMenu.indexOf(m.preset) >= 0) ||
																(AppCache.tenant.config.registrationsModule && Options.registrationMenu.indexOf(m.preset) >= 0) ||
																(Options.defaultMenu.indexOf(m.preset) >= 0)) {

																switch (m.preset) {
																	case WebsiteMenuPreset.Home:
																		return (
																			<li className="nav-item dropdown">
																				<Link className="nav-link" to={"/"}>{m.name}</Link>
																			</li>);
																	case WebsiteMenuPreset.Fixtures:
																		return (
																			<li className="nav-item dropdown">
																				<a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																					{m.name}
																				</a>
																				<div className="mega-menu dropdown-menu" aria-labelledby="navbarDropdown">
																					<div className="container">
																						<div className="row mt-4 mb-3">
																							<div className="col-md-4 d-flex">
																								<div className="justify-content-center align-self-center">
																									<h3 className="text-uppercase">Leagues</h3>
																									<ul className="nav flex-column">
																										<li key={`nav-item-competition-archive`} className="nav-item">
																											<Link to={"/leagues"} className="nav-link mb-3">View All</Link>
																											<Link to={"/league/archives"} className="nav-link">Archives</Link>
																											{AppCache.club == null && AppCache.tenant.config.competition.rankingEnabled ? <Link to={"/rankings"} className="nav-link">Rankings</Link> : undefined}
																											<Link to={"/reserves"} className="nav-link">Reserves</Link>
																											{AppCache.tenant.config.allowTeamChecker && <Link to={"/checker"} className="nav-link">Team Checker</Link>}
																											{AppCache.tenant.config.fastFormatModule && !AppCache.tenant.config.fastOnly && <Link to={"/leagues/fastformat"} className="nav-link">Fast Format</Link>}
																										</li>
																									</ul>
																								</div>
																							</div>
																							{
																								AppCache.website.menu.divisions.length === 0 ? <></> :
																									<>
																										{
																											AppCache.website.config.competitionMenuDisplay === CompetitionMenuDisplay.Full ?
																												<>
																													<div className="col-md-4">
																														<h5 className="text-uppercase">Fixtures</h5>
																														<ul className="nav flex-column">
																															{
																																AppCache.website.menu.divisions.map(d => {
																																	return (
																																		<li key={`nav-item-fixtures-${d.id}`} className="nav-item">
																																			<Link className="nav-link" to={`/league/${d.parentId}/division/${d.id}/fixtures`}>{d.name}</Link>
																																		</li>
																																	);
																																})
																															}
																														</ul>
																														<h5 className="text-uppercase">Tables</h5>
																														<ul className="nav flex-column">
																															{
																																AppCache.website.menu.divisions.map(d => {
																																	return (
																																		!d.limited && <li key={`nav-item-tables-${d.id}`} className="nav-item">
																																			<Link className="nav-link" to={`/league/${d.parentId}/division/${d.id}/table`}>{d.name}</Link>
																																		</li>
																																	);
																																})
																															}
																														</ul>
																													</div>
																													<div className="col-md-4">
																														<h5 className="text-uppercase">Results</h5>
																														<ul className="nav flex-column">
																															{
																																AppCache.website.menu.divisions.map(d => {
																																	return (
																																		<li key={`nav-item-fixtures-${d.id}`} className="nav-item">
																																			<Link className="nav-link" to={`/league/${d.parentId}/division/${d.id}/results`}>{d.name}</Link>
																																		</li>
																																	);
																																})
																															}
																														</ul>
																														<h5 className="text-uppercase">Averages</h5>
																														<ul className="nav flex-column">
																															{
																																AppCache.website.menu.divisions.map(d => {
																																	return (
																																		!d.limited && <li key={`nav-item-averages-${d.id}`} className="nav-item">
																																			<Link className="nav-link" to={`/league/${d.parentId}/division/${d.id}/averages`}>{d.name}</Link>
																																		</li>
																																	);
																																})
																															}
																														</ul>
																													</div>
																												</> : undefined
																										}
																										{
																											AppCache.website.config.competitionMenuDisplay === CompetitionMenuDisplay.Division ?
																												<>
																													{
																														AppCache.website.menu.competitions.map(c => {
																															return (
																																<div className="col-md-4">
																																	<h5 className="text-uppercase">{c.name}</h5>
																																	<ul className="nav flex-column">
																																		{
																																			AppCache.website.menu.divisions.filter(d => d.parentId === c.id).map(d => {
																																				return (
																																					<li key={`nav-item-division-${d.id}`} className="nav-item">
																																						<Link className="nav-link" to={`/league/${d.parentId}/division/${d.id}`}>{d.name}</Link>
																																					</li>
																																				);
																																			})
																																		}
																																	</ul>
																																</div>
																															);
																														})
																													}
																												</> : undefined
																										}
																										{
																											AppCache.website.config.competitionMenuDisplay === CompetitionMenuDisplay.Competition ?
																												<>
																													<div className="col-md-4">
																														<h5 className="text-uppercase">Competitions</h5>
																														<ul className="nav flex-column">
																															{
																																AppCache.website.menu.competitions.map(c => {
																																	return (
																																		<li key={`nav-item-competition-${c.id}`} className="nav-item">
																																			<Link className="nav-link" to={`/league/${c.id}`}>{c.name}</Link>
																																		</li>
																																	);
																																})
																															}
																														</ul>
																													</div>
																													<div className="col-md-4">
																														<h5 className="text-uppercase">Archived</h5>
																														<ul className="nav flex-column">
																															{
																																AppCache.website.menu.archived.map(c => {
																																	return (
																																		<li key={`nav-item-competition-${c.id}`} className="nav-item">
																																			<Link className="nav-link" to={`/league/${c.id}`}>{c.name}</Link>
																																		</li>
																																	);
																																})
																															}
																														</ul>
																													</div>
																												</> : undefined
																										}
																									</>
																							}
																						</div>
																					</div>
																				</div>
																			</li>);
																	case WebsiteMenuPreset.Bookings:
																		return (
																			<li className="nav-item">
																				<Link className="nav-link" to={"/bookings"}>{m.name}</Link>
																			</li>
																		);
																	case WebsiteMenuPreset.Events:
																		return (
																			<li className="nav-item">
																				<Link className="nav-link" to={"/events"}>{m.name}</Link>
																			</li>);
																	case WebsiteMenuPreset.Sessions:
																		return (
																			<li className="nav-item">
																				<Link className="nav-link" to={"/sessions"}>{m.name}</Link>
																			</li>
																		);
																	case WebsiteMenuPreset.Memberships:
																		return (
																			<li className="nav-item">
																				<Link className="nav-link" to={"/memberships"}>{m.name}</Link>
																			</li>
																		);
																	case WebsiteMenuPreset.Registrations:
																		return (
																			<li className="nav-item">
																				<Link className="nav-link" to={"/registrations"}>{m.name}</Link>
																			</li>
																		);
																	case WebsiteMenuPreset.Articles:
																		return (
																			<li className="nav-item dropdown">
																				<a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																					News
																				</a>
																				<div className="mega-menu dropdown-menu" aria-labelledby="navbarDropdown">
																					<div className="container">
																						<div className="row">
																							<div className="col-md-4 d-flex mn-ht-md-200">
																								<div className="justify-content-center align-self-center">
																									<h3 className="text-uppercase">News</h3>
																									<ul className="nav flex-column">
																										<li className="nav-item">
																											<Link className="nav-link" to={`/articles`}>View All</Link>
																										</li>
																									</ul>
																								</div>
																							</div>
																							<div className="col-md-4">
																								<div className="row">
																									<div className="col-12">
																										<h5 className="text-uppercase">Latest</h5>
																										<ul className="nav flex-column">
																											{
																												AppCache.website.menu.news.map(n => {
																													return (
																														<li key={`nav-item-news-${n.id}`} className="nav-item">
																															<Link className="nav-link" to={`/article/${n.slug}`}>{n.name}</Link>
																														</li>
																													);
																												})
																											}
																										</ul>
																									</div>
																								</div>
																							</div>
																							<div className="col-md-4">
																								<Row>
																									<div className="col-12">
																										<h5 className="text-uppercase">National News</h5>
																										<ul className="nav flex-column">
																											{
																												AppCache.website.menu.feed.map(n => {
																													return (
																														<li key={`nav-item-feed-${n.id}`} className="nav-item">
																															<a className="nav-link" target="_blank" href={n.slug}>{n.name}</a>
																														</li>
																													);
																												})
																											}
																										</ul>
																									</div>
																								</Row>
																							</div>
																						</div>
																					</div>
																				</div>
																			</li>
																		);
																	case WebsiteMenuPreset.Content:
																		return (
																			<li className="nav-item dropdown">
																				<a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																					Information
																				</a>
																				<div className="mega-menu dropdown-menu" aria-labelledby="navbarDropdown">
																					<div className="container">
																						<div className="row">
																							<div className="col-md-4 d-flex mn-ht-md-200">
																								<div className="justify-content-center align-self-center">
																									<h3 className="text-uppercase">Useful Information</h3>
																									<Can tenants={[TenantType.League, TenantType.Club]}>
																										<ul className="nav flex-column">
																											<li className="nav-item">
																												<Link className="nav-link" to={`/venues`}>Venues</Link>
																											</li>
																										</ul>
																									</Can>
																								</div>
																							</div>
																							<div className="col-md-8">
																								<div className="row">
																									{
																										AppCache.website.menu.pages.map(p => {
																											return (
																												<div key={`nav-item-groups-${p.name}`} className="col-4">
																													<h5 className="text-uppercase">{p.name}</h5>
																													<ul className="nav flex-column">
																														{
																															p.items.map(i => {
																																return (
																																	<li key={`nav-item-pages-${i.id}`} className="nav-item">
																																		{i.forwardTo ?
																																			<a className="nav-link" target={"_blank"} href={i.forwardTo.startsWith("http") ? i.forwardTo : `https://${i.forwardTo}`}>{i.name}</a> :
																																			<Link className="nav-link" to={`/page/${i.slug}`}>{i.name}</Link>
																																		}
																																	</li>
																																);
																															})
																														}
																													</ul>
																												</div>
																											);
																										})
																									}
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</li>
																		);
																	case WebsiteMenuPreset.Gallery:
																		return (
																			<li className="nav-item">
																				<Link className="nav-link" to={"/gallery"}>{m.name}</Link>
																			</li>
																		);
																	default:
																		break;
																}
															}
															else {
																return <></>;
															}
														})
													}
												</ul>
											</div>
											<ul className="navbar-nav navbar-auth d-flex flex-row">
												<li>
													<button onClick={toggle} className="navbar-toggler collapsed mr-2" type="button">
														<span className="far fa-bars" />
													</button>
												</li>
												{
													AppCache.tenant.config.bookingsModule &&
													<Basket />
												}
												{
													LoginService.isAuthenticated ?
														<li className="nav-item dropdown">
															<a className="nav-link btn btn--circle" href="#" id="navbardrop" data-toggle="dropdown">
																<i className="far fa-user-alt" />
															</a>
															<div className="dropdown-menu">
																<div className="nav-members">
																	{
																		LoginService.getDetails().logins.map(l => (
																			<a tabIndex={0} onClick={handleSwitchClick(l.userId)} className={`dropdown-item ${l.current && "current"}`}>
																				{l.name}
																				{l.membershipNo && <span className="membership">{l.membershipNo}</span>}
																			</a>
																		))
																	}
																</div>
																<div className="dropdown-divider"></div>
																<Can rules={Roles.admin}>
																	{!AppCache.tenant.isDefault && <Link to={"/manage"} className="dropdown-item">Manage</Link>}
																	{AppCache.tenant.isDefault && <Link to={"/admin"} className="dropdown-item">Admin</Link>}
																	<div className="dropdown-divider"></div>
																</Can>
																{
																	(AppCache.tenant.config.bookingsModule || AppCache.tenant.config.enableTTClubsFeatures) &&
																	<>
																		<Link to={"/member/profile"} className="dropdown-item">My Profile</Link>
																	</>
																}
																{
																	AppCache.tenant.config.registrationsModule &&
																	<>
																		<Link to={"/member/entries"} className="dropdown-item">My Entries</Link>
																	</>
																}
																{
																	AppCache.tenant.config.fastFormatModule &&
																	<>
																		<Link to={"/member/leagues"} className="dropdown-item">My Leagues</Link>
																	</>
																}
																{
																	AppCache.tenant.config.registrationsModule &&
																	<>
																		<Link to={"/member/clubs"} className="dropdown-item">My Clubs</Link>
																	</>
																}
																{
																	AppCache.tenant.config.competitionsModule &&
																	<>
																		<Link to={"/fixtures"} className="dropdown-item">My Fixtures</Link>
																		<Link to={"/results"} className="dropdown-item">My Results</Link>
																	</>
																}
																<div className="dropdown-divider"></div>
																<a tabIndex={0} onClick={logout.bind(this)} className="dropdown-item">Logout</a>
															</div>
														</li> : <li className="nav-item">
															<Link to={"/login"} className="nav-link btn btn--circle">
																<i className="far fa-sign-in" />
															</Link>
														</li>
												}
											</ul>
										</div>
									</nav>
								</section>
								{
									AppCache.website.menu.secondary.length > 0 &&
									<section>
										<Navbar className="navbar-custom submenu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_fade inited" expand="lg">
											<Container>
												<Collapse navbar isOpen={menuOpen}>
													<Nav navbar className="mr-auto">
														{
															AppCache.website.menu.secondary.map(cm => {
																return (renderMenuItems(cm));
															})
														}
													</Nav>
												</Collapse>
											</Container>
										</Navbar>
									</section>
								}
							</header>
							{!loading ? props.children : undefined}
							<footer className="pt-3 footer_wrap footer_custom footer_custom_872 footer_custom_footer-home-2 scheme_dark d-print-none">
								<div className="elementor elementor-872 elementor-bc-flex-widget">
									<div className="elementor-inner">
										<div className="elementor-section-wrap">
											{
												footer.length > 0 ?
													<section
														className="py-5 elementor-element elementor-element-1459491d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
														data-id="1459491d"
														data-element_type="section"
														data-settings='{"background_background":"classic","animation":"fadeIn","animation_delay":200}'>
														<div className="container elementor-column-gap-extended">
															<div className="elementor-row">
																<div
																	className="elementor-element elementor-element-49b79c89 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column"
																	data-id="49b79c89"
																	data-element_type="column">
																	<div className="elementor-column-wrap  elementor-element-populated">
																		<div className="elementor-widget-wrap">
																			<div>
																				<div className="sc_layouts_item elementor-element elementor-element-3d78afb1 sc_fly_static elementor-widget elementor-widget-trx_widget_slider">
																					<div className="elementor-widget-container">
																						<div id="trx_widget_slider_365937054" className="widget_area sc_widget_slider">
																							<aside id="trx_widget_slider_365937054_widget" className="widget widget_slider">
																								<div className="row">
																									<div className="col-12">
																										<OwlCarousel options={{
																											loop: false,
																											responsiveClass: true,
																											responsive: { 992: { items: 7 }, 768: { items: 5 }, 320: { items: 3 }, 0: { items: 2 } }
																										}}>
																											{
																												footer.map(i => {
																													return (<div className="slider-slide swiper-slide swiper-slide-active" style={{ width: "148.5px", marginRight: "38px" }}>
																														{
																															i.url ?
																																<a href={`${i.externalUrl ? `//` : ``}${i.url}`} target={`${i.externalUrl && `_blank`}`}><img src={`${AppConfig.baseUrl}image/${i.imageId}`} /></a> :
																																<img src={`${AppConfig.baseUrl}image/${i.imageId}`} />
																														}
																													</div>);
																												})
																											}
																										</OwlCarousel>
																									</div>
																								</div>
																							</aside>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section> : undefined
											}
											{
												!AppCache.tenant.isDefault &&
												<>
													<section className="elementor-element elementor-element-a3d305d elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
														<div className="container">
															<div className="row mb-4">
																<div className="col-12 col-md-6 mb-4">
																	<h4 className="">
																		<span className="sc_item_title_text">
																			About Us
																		</span>
																	</h4>
																	{
																		AppCache.website.about ? ReactHtmlParser(AppCache.website.about) : undefined
																	}
																</div>
																{AppCache.website.email?.length > 0 && <div className="col-12 col-md-6 mb-4 scheme_default">
																	<h4>
																		<span className="sc_item_title_text">
																			Contact Form
																		</span>
																	</h4>
																	<Row>
																		<Col>
																			<FormGroup>
																				<Label>First Name</Label>
																				<Input type="text" value={contactForm?.firstName} onChange={e => setContactForm({ ...contactForm, firstName: e.currentTarget.value })} />
																			</FormGroup>
																		</Col>
																		<Col>
																			<FormGroup>
																				<Label>Last Name</Label>
																				<Input type="text" value={contactForm?.lastName} onChange={e => setContactForm({ ...contactForm, lastName: e.currentTarget.value })} />
																			</FormGroup>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<FormGroup>
																				<Label>Email</Label>
																				<Input type="text" value={contactForm?.email} onChange={e => setContactForm({ ...contactForm, email: e.currentTarget.value })} />
																			</FormGroup>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<FormGroup>
																				<Label>Details</Label>
																				<textarea className="form-control" value={contactForm?.details} onChange={e => setContactForm({ ...contactForm, details: e.currentTarget.value })} />
																			</FormGroup>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="sp-view-all-link">
																				<a onClick={() => submit()}>
																					Submit
																				</a>
																			</div>
																		</Col>
																		{/* <Col>
																			<div className="sp-view-all-link">
																				<a onClick={() => clear()}>
																					Clear
																				</a>
																			</div>
																		</Col> */}
																	</Row>
																</div>}
																<div className="col-12 col-md-4 mb-4">
																	<h4 className="">
																		<span className="sc_item_title_text">
																			Contact Information
																		</span>
																	</h4>
																	{
																		AppCache.website.contact ? ReactHtmlParser(AppCache.website.contact) : undefined
																	}
																</div>
															</div>
															{
																AppCache.website.links.length > 0 ?
																	<div className="row">
																		<div className="col-12">
																			<h4 className="sc_item_title sc_title_title sc_item_title_style_default mb-2">
																				<span className="sc_item_title_text">
																					Useful Links
																				</span>
																			</h4>
																			<div className="useful-links">
																				{
																					AppCache.website.links.map(l => {
																						return (
																							<a href={`${l.url}`}>{l.title}</a>
																						);
																					})
																				}
																			</div>
																		</div>
																	</div> : undefined
															}
														</div>
													</section>
													<section
														className="elementor-element elementor-element-2e7e245e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
														data-id="2e7e245e"
														data-element_type="section">
														<div className="container elementor-column-gap-extended">
															<div className="elementor-row">
																<div
																	className="elementor-element elementor-element-77ff75d0 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column"
																	data-id="77ff75d0"
																	data-element_type="column">
																	<div className="elementor-column-wrap  elementor-element-populated">
																		<div className="elementor-widget-wrap">
																			<div
																				className="sc_layouts_item elementor-element elementor-element-39c2827b sc_fly_static elementor-widget elementor-widget-spacer"
																				data-id="39c2827b"
																				data-element_type="widget"
																				data-widget_type="spacer.default">
																				<div className="elementor-widget-container">
																					<div className="elementor-spacer">
																						<div className="elementor-spacer-inner" />
																					</div>
																				</div>
																			</div>
																			<div
																				className="sc_layouts_item elementor-element elementor-element-73075dae sc_fly_static elementor-widget elementor-widget-divider"
																				data-id="73075dae"
																				data-element_type="widget"
																				data-widget_type="divider.default">
																				<div className="elementor-widget-container">
																					<div className="elementor-divider">
																						<span className="elementor-divider-separator" />
																					</div>
																				</div>
																			</div>
																			<div
																				className="sc_layouts_item elementor-element elementor-element-6a425bd6 sc_fly_static elementor-widget elementor-widget-spacer"
																				data-id="6a425bd6"
																				data-element_type="widget"
																				data-widget_type="spacer.default">
																				<div className="elementor-widget-container">
																					<div className="elementor-spacer">
																						<div className="elementor-spacer-inner" />
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</>
											}
											<section>
												<div className="container">
													<Row className="text-center mb-4">
														<Col>
															<nav
																className="sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_fade hide_on_mobile inited">
																<ul
																	id="sc_layouts_menu_166904521"
																	className="sc_layouts_menu_nav prepared inited sf-js-enabled sf-arrows"
																	style={{ touchAction: "pan-y" }}>
																	{
																		AppCache.website.menu.footer.map(f => {
																			if ((AppCache.tenant.config.bookingsModule && Options.bookingsMenu.indexOf(f.preset) >= 0) ||
																				(AppCache.tenant.config.competitionsModule && Options.competitionMenu.indexOf(f.preset) >= 0) ||
																				(AppCache.tenant.config.registrationsModule && Options.registrationMenu.indexOf(f.preset) >= 0) ||
																				(Options.defaultMenu.indexOf(f.preset) >= 0)) {

																				switch (f.preset) {
																					case WebsiteMenuPreset.Home:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Gallery:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/gallery"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Leagues:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/leagues"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Memberships:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/memberships"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Articles:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/articles"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Venues:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/venues"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Rankings:
																						return (
																							AppCache.tenant.config.competition.rankingEnabled && <li className="nav-item dropdown">
																								<Link className="nav-link" to={"/rankings"}>{f.name}</Link>
																							</li>);
																					case WebsiteMenuPreset.Reserves:
																						return (
																							<li className="nav-item dropdown">
																								<Link className="nav-link" to={"/reserves"}>{f.name}</Link>
																							</li>);
																					default:
																						break;
																				}
																			}
																			if (!f.preset) {
																				return (<li className="nav-item dropdown">
																					<Link className="nav-link" to={f.url}>{f.name}</Link>
																				</li>);
																			}
																		})
																	}
																</ul>
															</nav>
														</Col>
													</Row>
												</div>
											</section>
											<section className="bg-tertiary p-3 mt-3">
												<div className="container">
													<Row>
														<Col>
															<img
																className="logo-image"
																style={{ height: "35px", marginLeft: "-10px" }}
																src={
																	AppCache.tenant.leagueId ?
																		require("../../../assets/images/brand/ttleagues-logo-white.png") :
																		require("../../../assets/images/brand/ttclubs-logo-white.png")
																}
																alt={AppCache.website.title}
																height={25} />
															<p>
																Powered by&nbsp;
																{
																	AppCache.tenant.clubId ? <a href="https://www.ttclubs.co.uk/" rel="noopener">TT Clubs</a> : <a href="https://www.ttleagues.com/" rel="noopener">TT Leagues</a>
																}&nbsp;
																{moment().year()}. All Rights Reserved.
															</p>
														</Col>
														<Col className="text-right">
															<p>
																Copyright © <a href={AppCache.tenant.organisation.website} rel="noopener">{AppCache.tenant.organisation.name}</a> {moment().year()}.
															</p>
															{AppCache.tenant.organisation.terms && <a className="mr-2" href={AppCache.tenant.organisation.terms} rel="noopener">Terms and Conditions</a>}
															{AppCache.tenant.organisation.privacy && <a href={AppCache.tenant.organisation.privacy} rel="noopener">Privacy Policy</a>}
														</Col>
													</Row>
												</div>
											</section>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</div>
					<a
						href="#"
						className="trx_addons_scroll_to_top trx_addons_icon-up inited"
						title="Scroll to top"
					/>
					{/* Instagram Feed JS */}
					{/*[if lte IE 9]>
					<![endif]*/}
					<span id="elementor-device-mode" className="elementor-screen-only" />
				</div>
				{
					props.print ?
						<div className="d-none d-print-block">
							{props.print()}
						</div> : undefined
				}
				<iframe style={{ visibility: "hidden", position: "fixed", left: "10000px" }} src={`https://${AppConfig.hostUrl}?host=${AppCache.tenant.host}`}></iframe>
			</div>

			{/* </WebsiteProvider> */}
		</>
	);
}


const Basket = (props) => {
	const { basket } = useContext(WebsiteContext);

	useEffect(() => {
	}, [basket]);

	return (
		<li className="nav-item dropdown">
			<Link className="nav-link btn btn--circle position-relative mr-2" to={"/basket"} id="navbardrop" data-toggle="dropdown">
				<i className="far fa-basket-shopping" />
				{basket?.items?.length > 0 && <span className="basket-count position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
					<span className="visually-hidden">{basket?.items?.length}</span>
				</span>}
			</Link>
		</li>
	)
}