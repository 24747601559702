import * as React from "react";

export interface IActivitySpinnerProps {
	text?: string;
	image?: string;
	mode?: string;
	className?: string;
	height?: string;
	overlay?:boolean;
}

export class ActivitySpinner extends React.Component<React.PropsWithChildren<IActivitySpinnerProps>, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		mode: "dark",
		className: "",
		height: ""
	};

	componentDidMount() {
		//
	}

	render() {
		const classNames = `indicator--${this.props.mode} ${this.props.height ? `ht-${this.props.height}` : ""}` +
			(!this.props.overlay ? "" : `indicator-overlay position-absolute w-100 h-100`);

		return (
			<div className={`indicator ${this.props.className} ${classNames}`}>
				<div className={`d-flex pos-relative align-items-center h-100`}>
					<div className="spinner">
						<div className="rect1"></div>
						<div className="rect2"></div>
						<div className="rect3"></div>
						<div className="rect4"></div>
						<div className="rect5"></div>
					</div>
				</div>
				<div className="indicator--content text-center">
					{
						this.props.text != null ? <p>{this.props.text}</p> : undefined
					}
					{this.props.children}
				</div>
			</div>
		);
	}
}
