import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { CreateImage } from "@models/image";
import { ImageService } from "@services/image.service";
import { AppConfig } from "../../config";
import { LatLng } from "./StaticMap";

interface IStaticMarkerProps {
	match?: any;
	position: LatLng | undefined
	sendLocation?: (pos: LatLng) => void;
}

interface IStaticMarkerState {
	loading: boolean;
	nextPathname?: any;
}



export class StaticMarker extends React.Component<IStaticMarkerProps, IStaticMarkerState> {
	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		this.props.sendLocation(this.props.position);
	}

	public render() {
		return (
			<>
			</>
		);
	}
}
