import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Venue, CreateVenue, VenueType } from "../models/venue";
import { SelectOption } from "../models/forms";
import RestHelper from "@helpers/rest.helper";

export class VenueService {

	constructor() {
		//
	}

	public static search(search:string = "", take: number = 20): Promise<Venue[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {search, take},
				success: (data:Venue[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(id:number): Promise<Venue> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/${id}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Venue, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getTypes(): Promise<VenueType[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/types`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:VenueType[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(venue: Venue): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(venue),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static data(any: boolean, query:DatatableQuery, deleted: boolean = null): Promise<DatatableResponse<Venue>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(deleted ? {any, query, deleted} : {any, query}),
				success: (data:DatatableResponse<Venue>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(venue: CreateVenue): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(venue),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static link(venueId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/${venueId}/link`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(venueId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `venues/${venueId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static get(): Promise<Venue[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
				},
				url: AppConfig.apiUrl + `venues/all`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Venue[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static restore(venueId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`venues/${venueId}/restore`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static setPrimary(venueId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`venues/${venueId}/setprimary`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
