import { Entry, EntryPlayer, EntryTeam, RegisterEntryTeam } from "@models/entry";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, CardBody } from "reactstrap";

import CreatableSelect from "react-select/creatable"
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Options } from "@data";
import { EntryService } from "@services/entry.service";
import { RegistrationCategory, RegistrationEntrantType } from "@models/registration";
import { SelectOption } from "@models/forms";
import { MemberService } from "@services/member.service";
import CategoryEntry from "@pages/manage/registrations/components/CategoryEntry";
import { ProgressButton } from "@components/controls/ProgressButton";

interface IRegisterTeamSidebar {
	entry: Entry;
	category: RegistrationCategory;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IRegisterTeamSidebar) => {
	const [teams, setTeams] = useState<RegisterEntryTeam[]>();
	const [entryTeams, setEntryTeams] = useState<EntryTeam[]>();
	const [team, setTeam] = useState<RegisterEntryTeam>();
	const [submitting, setSubmitting] = useState<boolean>(false);


	useEffect(() => {
		load();
	}, [])

	const handleClose = () => (e: any) => {
		if(!submitting)
			props.onClose();
	};

	const load = async () => {
		console.log(props.category)
		await EntryService.existingTeams(props.entry.id, props.category.competitionTypeId).then(result => {
			const teams = result.map((r, index) => {
				var team = new RegisterEntryTeam();
				team.id = index;
				team.teamId = r.teamId;
				team.players = [];
				var details = new EntryTeam();
				details.name = r.name;
				details.shortName = r.team?.shortName;
				details.abbreviation = r.team?.abbreviation;
				details.description = r.team?.description;
				details.retentionId = r.retentionId ?? 0;
				team.entryTeam = details

				return team;
			});
			setTeams(teams);
		});

		await EntryService.teams({ entryId: props.entry.id, categoryId: props.category.id, deleted: false }).then(async result => {
				setEntryTeams(result);
		});
	}

	const loadMemberOptions = () => (value: string): Promise<SelectOption<string>[]> => {
		return new Promise(resolve => {
			MemberService.search({
				search: value,
				email: false,
				player: false,
				registration: true,
				categoryId: props.category.id,
				clubId: props.entry.clubId
			}).then(members => {
				const options = members.filter(m => team?.players == null || team?.players.length == 0 || team?.players.every(tm => tm.displayName != m.name)).map(m => ({ label: m.name, value: m.id, disabled: m.disabled }));
				resolve(options);
			});
		});
	}

	const handleConfirm = () => async (e: any) => {
		//(!props.category.requiredPlayers || team?.players?.length >= props.category.requiredPlayers)
		if(team){
			setSubmitting(true);
			var entryTeam = team;
			entryTeam.registrationGroupId = props.category.id;
			entryTeam.entryId = props.entry.id;
			await EntryService.registerTeam(entryTeam);
			setSubmitting(false);
			props.onConfirm();
			props.onClose();
		}
	};

	const handleCreateTeam = async (value: string) => {
		var team = new RegisterEntryTeam();
		team.id = teams.length;
		team.players = [];
		var details = new EntryTeam();
		details.name = value;
		details.players = [];
		details.retentionId = 0;
		team.entryTeam = details
		var tTeams = teams; 
		tTeams.push(team);
		setTeam(team);
		setTeams(tTeams);
	};

	const handleChangeTeam = async (e: any) => {
		var team = teams.find(t => t.id == e.value);

		setTeam(team);
	};

	const handleChangePlayers = () => (e: any) => {
		team.players = e.map(a => {
			var player = new EntryPlayer();
			player.memberId = a.value;
			player.displayName = a.label;
			player.categoryId = 0;
			return player;
		});
		setTeam(Object.assign({}, team));
	};

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="m-0">Register Team
					<div className="help-container">
						<button className="btn p-0 tx-20" onClick={props.onClose}>
							<i className="far fa-times"></i>
						</button>
					</div></h6>
			</div>
			<div className="sidebar--content">
				<label className="form-control-label">Team <span className="tx-danger">*</span></label>
				<CreatableSelect
					value={team ? {label: team.entryTeam.name, value: team.id} : undefined}
					options={teams?.filter(t => entryTeams?.every(e => e.retentionId != t.entryTeam.retentionId)).map(t => ({label: t.entryTeam.name, value: t.id}))}
					defaultOptions
					onChange={handleChangeTeam}
					onCreateOption={handleCreateTeam}
					closeMenuOnSelect={true} />
				{props.category.entrantType !== RegistrationEntrantType.Players && <>
				<label className="form-control-label mt-2">Player <span className="tx-danger">*</span> {props.category.requiredPlayers && <span className="tx-danger">{team?.players?.length}/{props.category.requiredPlayers}</span>}</label>
				<AsyncSelect
					isMulti
					value={team?.players?.map(p => ({ label: p.displayName, value: p.memberId }))}
					loadOptions={loadMemberOptions()}
					isOptionDisabled={option => option.disabled}
					closeMenuOnSelect={true}
					onChange={handleChangePlayers()} />
				</>}
			</div>
			<div className="sidebar--footer">
				<Row className="mt-4">
						<Col>
							{/* disabled={!team || props.category.requiredPlayers && (!team.players || team?.players?.length < props.category.requiredPlayers)} */}
							<ProgressButton className="btn btn-primary btn-rounded btn-outline bd-0 btn-block" loading={submitting} onClick={handleConfirm()}>Confirm</ProgressButton>
						</Col>
					<Col>
						<button className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block" type="button" onClick={handleClose()}>Cancel</button>
					</Col>
				</Row>
			</div>
		</div>
	);
};