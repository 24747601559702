import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Team, TeamMember, CreateTeam } from "@models/team";

import store from "store2";

import * as moment from "moment";
import { SelectOption } from "@models/forms";

import { Article, CreateArticle } from "../models/article";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Content, CreateContent, ContentOrder, ContentAccess } from "../models/content";
import { MenuGroup, CreateMenuGroup, UpdateMenuGroup } from "@models/website";
import RestHelper from "@helpers/rest.helper";

export class ContentService {

	constructor() {
		//
	}

	public static get(page: number, size: number): Promise<Content[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {page, size},
				success: (data:Content[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getFeatured(page: number, size: number): Promise<Content[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/featured`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {page, size},
				success: (data:Content[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(contentId: number): Promise<Content> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/${contentId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Content, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getBySlug(slug: string): Promise<Content> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/${slug}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Content, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static data(query:DatatableQuery, deleted: boolean = null): Promise<DatatableResponse<Content>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(deleted ? {query, deleted} : {query}),
				success: (data:DatatableResponse<Content>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(article: CreateContent): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(article),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(content: Content): Promise<Content> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(content),
				success: (data:Content, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(contentId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/${contentId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static loadMenuGroups(): Promise<MenuGroup[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/menu`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MenuGroup[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static createMenuItem(MenuGroup: CreateMenuGroup): Promise<MenuGroup> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/menu/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(MenuGroup),
				success: (data:MenuGroup, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateMenuGroup(menuGroup: MenuGroup): Promise<MenuGroup> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/menu/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(menuGroup),
				success: (data:MenuGroup, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static orderMenuGroups(menuGroups: MenuGroup[]): Promise<MenuGroup[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/menu/order`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(menuGroups),
				success: (data:MenuGroup[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static deleteMenuGroup(MenuGroupId: number): Promise<MenuGroup> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/menu/delete/${MenuGroupId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MenuGroup, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static orderContent(contentOrder: ContentOrder[]): Promise<ContentOrder[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/order`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(contentOrder),
				success: (data:ContentOrder[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static loadContentOrder(menuGroupId: number): Promise<ContentOrder[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/${menuGroupId}/order`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:ContentOrder[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getUserContentPermissions(id: number): Promise<ContentAccess[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/${id}/permissions/user`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:ContentAccess[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getGroupContentPermissions(id: number): Promise<ContentAccess[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `content/${id}/permissions/group`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:ContentAccess[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static restore(contentId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`content/${contentId}/restore`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
