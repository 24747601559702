import { Basket } from "@models/basket";
import { BasketService } from "@services/basket.service";
import React, { ProviderProps, useEffect, useState, createContext } from "react";

interface IWebsiteContext {
    basket?: Basket;
    setBasket?: (basket: Basket) => void;
}
export const WebsiteContext = createContext<IWebsiteContext>({});

export const WebsiteProvider = ({children}) => {
    const [basket, setBasket] = useState<Basket>({total:1000,items:[]});

    useEffect(()=> {
        load();
    }, []);

    const load = async () => {
        const basket = await BasketService.get();
		setBasket(basket);
    }

    const stateValues = {
		basket,
		setBasket
	}

    return (
        <WebsiteContext.Provider value={stateValues}>
            <>{children}</>
        </WebsiteContext.Provider>
    )
}
