import * as React from "react";

import { UncontrolledPopover,PopoverHeader, PopoverBody  } from "reactstrap";

interface IHelpPopoverProps {
	id: string;
	title: string;
	children?: any;
}

export class HelpPopover extends React.Component<IHelpPopoverProps, any> {

	private id: string;

	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		//
	}

	render() {
		return (
			<div className="help-container">
				<a className="help" id={`help-${this.props.id}`}>
					<i className="far fa-question-circle"></i>
				</a>
				<UncontrolledPopover placement="left" target={`help-${this.props.id}`}>
					<PopoverHeader>{this.props.title}</PopoverHeader>
					<PopoverBody>{this.props.children}</PopoverBody>
				</UncontrolledPopover>
			</div>
		);
	}
}
