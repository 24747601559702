import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact, ContactLink, ContactLinkType } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Booking } from "@models/booking";
import { EventMember } from "@models/event";
import { BookingService } from "@services/booking.service";
import { PagedList } from "@models/paging";
import { MembershipStatus, MembershipUsage, MembershipUser } from "@models/membership";
import { Options } from "@data";
import { Link } from "react-router-dom";

interface ILinked {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: ILinked) => {
	const [contact, setContact] = useState<Contact>(props.contact);
	const [linked, setLinked] = useState<ContactLink[]>([]);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const linked = await ContactService.getLinked(contact.id);
		setLinked(linked);
	};

	const handleUnlinkClick = (link: ContactLink) => async (e: any) => {
		await ContactService.unlink(link.id);
		await load();

		toast.success("Unlinked Contact");
	};

	return (
		<>
			<div className="mb-4 memberships">
				<h6>Contacts</h6>
				<Row>
					{
						linked.map(l => (
							<Col xs={12}>
								<Card className="mb-3 p-3">
									<Row>
										{
											l.confirmed &&
											<>
												<Col lg={4}>
													<h6 className="m-0">{l.contact.name !== "" ? l.contact.name : l.contact.fullName}</h6>
													<p className="small m-0">{l.contact.phoneNumber}</p>
												</Col>
												<Col lg={4} className="justify-content-center align-self-center">
													<h6 className="small m-0">Email</h6>
													<p className="small m-0">{l.contact.email}</p>
												</Col>
												<Col lg={2} className="justify-content-center align-self-center">
													{/* <h6 className="small m-0">Type</h6>
													{Options.contactLinkType.find(t => t.value === l.type)?.label} */}
												</Col>
												<Col lg={2} className="d-flex justify-content-center align-self-center text-right">
													{l.type === ContactLinkType.Full && <Link className="btn btn--circle btn-inline text-white mr-2" to={`/member/profile/${l.contact.id}`}><i className="far fa-pencil" /></Link>}
													<a className="btn btn--circle btn-inline text-white" onClick={handleUnlinkClick(l)}><i className="far fa-unlink" /></a>
												</Col>
											</>
										}
										{
											!l.confirmed &&
											<>
												<Col lg={4}>
													<h6 className="m-0">{l.contact.name !== "" ? l.contact.name : l.contact.fullName}</h6>
												</Col>
												<Col className="text-right">
													<p className="m-0">Awaiting confirmation</p>
												</Col>
												<Col lg={2} className="d-flex justify-content-center align-self-center text-right">
													<a className="btn btn--circle btn-inline text-white" onClick={handleUnlinkClick(l)}><i className="far fa-link" /></a>
												</Col>
											</>
										}
									</Row>
								</Card>
							</Col>
						))
					}
				</Row>
			</div>
		</>
	);
};