import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";
import DivisionStandings from "@components/pages/public/competitions/DivisionStandings";
import CompetitionOverviewMenu from "@components/pages/public/competitions/CompetitionOverviewMenu";

interface ITablesOverviewProps {
	site?: string;
	match?: any;
}

interface ITablesOverviewState {
	loading: boolean;
	competition?: Competition;
	divisions: Division[];
	search?: string;
}

export class TablesOverviewPage extends React.Component<ITablesOverviewProps, ITablesOverviewState> {

	private competitionId: number;

	constructor(props) {
		super(props);
		this.state = { loading: true, divisions: [] };
	}

	componentDidMount() {
		this.competitionId = this.props.match.params.competitionId as number;

		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		this.competitionId = this.props.match.params.competitionId as number;

		if (props.match.params.competitionId !== this.competitionId) {
			this.load();
		}
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	async load() {
		const competition = await CompetitionService.getById(this.competitionId);
		const divisions = await DivisionService.get(competition.id);

		for (let i = 0; i < divisions.length; i++) {
			const division = divisions[i];
			const standings = await DivisionService.standings(division.id);
			division.standings = standings;
		}

		this.setState({ loading: false, competition, divisions });
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div>
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">{this.state.competition.name}</span></h2>
											<p className="text-center">Competition Tables</p>
										</div>
										<div className="competition-list mg-t-20">
											<div className="row clearfix">
												<div className="col-lg-12">
													{/* <CompetitionOverviewMenu competitionId={this.state.competition.id} /> */}
													<div className="list-group list-group-default">
														<div key={`competition-${this.state.competition.id}`} className="competition mb-3">
															{
																this.state.divisions.map(d => (
																	<div className="mb-5" style={{ breakInside: "avoid"}}>
																		<h3 className="text-center">{d.name}</h3>
																		<DivisionStandings competition={this.state.competition} division={d} standings={d.standings} />
																	</div>
																))
															}
														</div>
														{
															this.state.loading ? <ActivitySpinner /> : undefined
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
