import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";

import { ArticleService } from "@services/article.service";
import { PagedList } from "@models/paging";
import { Article, NewsItem } from "@models/article";
import Masonry from "react-masonry-css";
import { AppConfig } from "@config";

import History from "@helpers/history.helper";
import { Link } from "react-router-dom";
import { AppCache } from "@cache";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { WebsiteImage, WebsiteImageType } from "@models/website";

const DraggableArticles = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [info, setInfo] = useState(props.payload.title);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setlayout] = useState(props.payload.layout);

	const [editMode, setEditMode] = useState(false);

	const [articles, setArticles] = useState<PagedList<Article>>();
	const [news, setNews] = useState<NewsItem[]>([]);

	const newsImage: WebsiteImage = AppCache.website.images.find(i => i.type === WebsiteImageType.News);

	useEffect(() => {
		const max = AppCache.website.config.maxArticles;

		ArticleService.get({page: 1, count: max ?? 5}).then(articles => {
			setArticles(articles);
		});

		ArticleService.GetFeed().then(news => {
			setNews(news);
		});
	}, []);

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<section className="post_item_single post_type_page post-598 page type-page status-publish hentry">
				<div className="entry-content">
					<div className="elementor elementor-598">
						<div className="elementor-inner">
							<div className="elementor-section-wrap">
								<section className="">
									<div className="elementor-column-gap-extended">
										<div className="">
											<div className="">
												<div className="elementor-column-wrap elementor-element-populated">
													<div className="elementor-widget-wrap">
														<div className="elementor-element elementor-element-be3d94e sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-id="be3d94e" data-element_type="widget" data-widget_type="trx_sc_title.default">
															<div className="elementor-widget-container">
																<div className="sc_title sc_title_accent">
																	<h2 className="sc_item_title sc_title_title sc_item_title_style_accent">
																		<span className="sc_item_title_text">
																			Local News
																		</span>
																	</h2>
																</div>
															</div>
														</div>
														<div className="elementor-element elementor-element-c793c8d sc_fly_static elementor-widget elementor-widget-trx_sc_recent_news mb-4">
															<div className="elementor-widget-container">
																<div id="trx_sc_recent_news_783420989" className="sc_recent_news sc_recent_news_style_news-excerpt sc_recent_news_with_accented">
																	{articles && articles.total === 0 ? <p className="m-0">No news available</p> : undefined}
																	{articles && articles.items.map(n => {
																		return (
																			<article className="post_item post_layout_news-excerpt post_format_standard post-240 post type-post status-publish format-standard has-post-thumbnail hentry category-training tag-articles tag-blog tag-match tag-soccer tag-topic">
																				<div className="post_featured with_thumb hover_simple">
																					<img width={260} height={240} src={n.previewId ? `${AppConfig.baseUrl}image/${n.previewId}` : newsImage ? `${AppConfig.baseUrl}image/${newsImage.imageId}` : require("@assets/images/placeholders/tte-square.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
																					<div className="mask" />
																					<a href={`/article/${n.slug}`} aria-hidden="true" className="icons" />
																				</div>
																				<div className="post_body">
																					<div className="post_header entry-header">
																						<div className="post_meta">
																							{
																								n.category ?
																									<span className="post_meta_item post_categories">
																										<Link to={`/articles/category/${n.category}`}>{n.category}</Link>
																									</span> : undefined
																							}
																							<span className="post_meta_item post_date">
																								{moment(n.published).format("Do MMMM")}
																							</span>
																						</div>
																						<h4 className="post_title entry-title">
																							<Link
																								to={`/article/${n.slug}`}
																								rel="bookmark">
																								{n.title}
																							</Link>
																						</h4>
																					</div>
																					<div className="post_content entry-content">
																						<p>
																							{n.summary}
																						</p>
																					</div>
																				</div>
																			</article>
																		);
																	})}
																</div>
															</div>
															{
																articles && articles.total < 1 ? undefined :
																	<div className="sp-view-all-link">
																		<Link className="bg-secondary" to={"/articles"}>View all</Link>
																	</div>
															}
														</div>
														<div className="elementor-element elementor-element-fec56ce sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-id="fec56ce" data-element_type="widget" data-widget_type="trx_sc_title.default">
															<div className="elementor-widget-container">
																<div id="trx_sc_title_1288911470" className="sc_title sc_title_accent">
																	<h2 className="sc_item_title sc_title_title sc_item_title_style_accent">
																		<span className="sc_item_title_text">
																			National News
																		</span>
																	</h2>
																</div>
															</div>
														</div>
														<div className="elementor-element elementor-element-b71eb6c scheme_default sc_fly_static elementor-widget elementor-widget-trx_sc_blogger mg-b-5" data-id="b71eb6c" data-element_type="widget" data-widget_type="trx_sc_blogger.default">
															<div className="elementor-widget-container">
																<div className="">
																	<div className="feed">
																		{
																			news.length > 0 ?
																				<OwlCarousel lazyLoad={true} options={{ autoHeight: true, loop: false, dots: false, responsive: { 0: { items: 1 }, 480: { items: 2 } } }}>
																					{news.map(f => {
																						return (
																							<div className="feed--item mr-4">
																								<div className="feed--image" style={{ backgroundImage: `url(${f.imageUri})` }}>
																									<img src={f.imageUri} className="" />{" "}
																									<div className="mask" />
																									<a href={f.uri} target={"_blank"} className="icons" />
																								</div>
																								<div>
																									<div className="post_meta sc_blogger_item_meta post_meta">
																										<span className="post_meta_item post_date">
																											{moment(f.publishDate).fromNow()}
																										</span>
																									</div>
																									<h5 data-item-number={1}>
																										<a href={f.uri} target="_blank">{f.title}</a>
																									</h5>
																									<div>
																										{ReactHtmlParser(f.summary)}
																									</div>
																								</div>
																							</div>);
																					})}
																				</OwlCarousel> : undefined
																		}
																	</div>
																</div>
															</div>
														</div>
														<div className="elementor-element elementor-element-2b5b5cf sc_fly_static elementor-widget elementor-widget-spacer" data-id="2b5b5cf" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	return (
		<>
			<Draggable {...props} >
				<Card className="clickable" onClick={() => setEditMode(true)}>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<span className="u-text-small">List of current new articles</span>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Title</Label>
						<Input value={title} placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Body</Label>
						<Input value={body} placeholder="Enter body" onChange={e => setBody(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Info</Label>
						<Input value={info} placeholder="Enter summary info" onChange={e => setBody(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Link</Label>
						<Input value={link} placeholder="Enter link url" onChange={e => setLink(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Image</Label>
						<Input value={image} placeholder="Enter image url" onChange={e => setImage(e.target.value)} />
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-gray-200 btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableArticles;
