import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Booking } from "@models/booking";
import { EventMember } from "@models/event";
import { BookingService } from "@services/booking.service";
import { PagedList } from "@models/paging";
import { confirmAlert } from "react-confirm-alert";
import { EventService } from "@services/event.service";
import { CreateMessage } from "@models/message";

import { MessageService } from "@services/message.service";
import { Link } from "react-router-dom";

interface IBookings {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IBookings) => {
	const [contact, setContact] = useState<Contact>(props.contact);

	const [bookings, setBookings] = useState<PagedList<Booking>>();
	const [sessions, setSessions] = useState<PagedList<EventMember>>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const bookings = await ContactService.getBookings(contact.id, {});
		const sessions = await ContactService.getSessions(contact.id, {});

		setBookings(bookings);
		setSessions(sessions);
	};

	const hideBookingCancel = (booking: Booking) => {

		let startDate = new Date(booking.startDate.toString());
		let startTime = moment(startDate);
		let diff = startTime.diff(new Date());
		let diffDuration = moment.duration(diff);
		let hours = diffDuration.asHours();

		BookingService.getCategories(booking.spaceId).then(categories => {

			switch (categories[0].rules[0].cancellationType) {
				case 0:
					return false;
					break;
				case 1:
					return true;
					break;
				case 2:
					if((hours <= 1 && hours > 0) || (hours < 0)) {			
						return true;
					}
					break;
				case 3:
					if((hours <= 2 && hours > 0) || (hours < 0)) {			
						return true;
					}
					break;
				case 4:
					if((hours <= 6 && hours > 0) || (hours < 0)) {			
						return true;
					}
				case 5:
					if((hours <= 24 && hours > 0) || (hours < 0)) {			
						return true;
					}
					break;
				case 6:
					if((hours <= 48 && hours > 0) || (hours < 0)) {			
						return true;
					}
					break;
				case 7:
					if((hours <= 168 && hours > 0) || (hours < 0)) {			
						return true;
					}
					break;
					break;
				default:
					break;
			}

		});

		return false;
	};

	const hideSessionCancel = (session: EventMember) => {

		let startDate = new Date(session.session.startDate.toString());
		let startTime = moment(startDate);
		let diff = startTime.diff(new Date());
		let diffDuration = moment.duration(diff);
		let hours = diffDuration.asHours();

		switch (session.session.cancellationPolicy) {
			case 0:
				return false;
				break;
			case 1:
				return true;
				break;
			case 2:
				if((hours <= 1 && hours > 0) || (hours < 0)) {			
					return true;
				}
				break;
			case 3:
				if((hours <= 2 && hours > 0) || (hours < 0)) {			
					return true;
				}
				break;
			case 4:
				if((hours <= 6 && hours > 0) || (hours < 0)) {			
					return true;
				}
				break;
			case 5:
				if((hours <= 24 && hours > 0) || (hours < 0)) {			
					return true;
				}
				break;
			case 6:
				if((hours <= 48 && hours > 0) || (hours < 0)) {			
					return true;
				}
				break;
			case 7:
				if((hours <= 168 && hours > 0) || (hours < 0)) {			
					return true;
				}
				break;
			default:
				break;
		}

		return false;
	};

	const handleCancelSessionClick = (session: EventMember) => (e:any) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will permanently cancel your booking.</p>
						<a className="btn btn-secondary text-white mr-2"
							onClick={() => {
								EventService.remove(session).then(result => {
									load();
									onClose();
									toast.success("Booking Cancelled");
								});
							}}
						>
							Yes, cancel it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	};

	const handleCancelBookingClick = (booking: Booking) => (e:any) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will permanently cancel your booking.</p>
						<a className="btn btn-secondary text-white mr-2"
							onClick={() => {
								BookingService.cancel(booking.id).then(result => {
									load();
									onClose();
									toast.success("Booking Cancelled");
								});
							}}
						>
							Yes, cancel it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	};

	return (
		<>
			<div className="mb-4">
				<h6>Bookings</h6>
				<Row>
					{
						bookings && bookings.items.map(b => (
							<Col lg={6}>
								<Card className="mb-3">
									<Row>
										<Col xs={7}>
											{moment(b.startDate).format("Do MMMM")} - {moment(b.startDate).format("HH:mm")}
											<h6 className="m-0">{b.space.name}</h6>
											<p className="m-0">{b.resource.name}</p>
										</Col>
										<Col className="d-flex justify-content-end align-self-center">
											<Link to={`/bookings/space/${b.spaceId}/slot/${b.id}/attendees`} className="btn btn--circle text-white mr-2"><i className="far fa-users-class" /></Link>
											<a className="btn btn--circle text-white" onClick={handleCancelBookingClick(b)} hidden={hideBookingCancel(b)}><i className="far fa-ban" /></a>
										</Col>
									</Row>
								</Card>
							</Col>
						))
					}
				</Row>
			</div>
			<div className="mb-4">
				<h6>Sessions & Events</h6>
				<Row>
					{
						sessions && sessions.items.map(s => (
							<Col lg={6}>
								<Card className="mb-3">
									<Row>
										<Col xs={7}>
											{moment(s.session.startDate).format("Do MMMM")} - {moment(s.session.startDate).format("HH:mm")}
											<h6 className="m-0">{s.session.event.title}</h6>
										</Col>
										<Col className="d-flex justify-content-end align-self-center">
											<Link to={`/sessions/${s.session?.id}/attendees`} className="btn btn--circle text-white mr-2"><i className="far fa-users-class" /></Link>
											<a className="btn btn--circle text-white" onClick={handleCancelSessionClick(s)} hidden={hideSessionCancel(s)}><i className="far fa-ban" /></a>
										</Col>
									</Row>
								</Card>
							</Col>
						))
					}
				</Row>
			</div>
		</>
	);
};