import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { LoginService } from "./login.service";

import store from "store2";

import * as moment from "moment";
import { Point } from "@/models/point";

export class PointService {

	constructor() {
		//
	}

	public static get(): Promise<Point[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				url: AppConfig.apiUrl + `points`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Point[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}
}
