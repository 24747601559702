import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Division, CreateDivision } from "@models/division";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Standing } from "@models/standing";
import { MatchGrouping, MatchCard, CreateMatch, MatchStatus, Match, MatchGroupRange, MatchGroupType, MatchGroup, MatchSet, PotmType, MatchFilters, MatchResults, MatchCardEntryLevel, MatchReport } from "@/models/match";
import { Entrant } from "@models/entrant";
import { TeamMember } from "@models/team";
import { MatchGame } from "../models/match";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { MemberRanking } from "@models/member";
import * as qs from "query-string";
import { RankingStats } from "@models/rankings";
import { CompetitionType, CostMatrix, Registration, RegistrationCategory, RegistrationCompetitionType, RegistrationDivision, RegistrationTeam } from "@models/registration";
import { CostAdjustmentFilterOptions, RegistrationEntryFilterOptions, RegistrationFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";
import { Invoice } from "@models/invoice";
import { CostAdjustment, EntryPlayer, EntryTeam } from "@models/entry";
import { AffiliationType } from "@models/club";
import { Competition } from "@models/competition";

export class RegistrationService {

	constructor() {
		//
	}

	public static get(options: RegistrationFilterOptions): Promise<PagedList<Registration>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<Registration>>(`registration`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getById(registrationId: number): Promise<Registration> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Registration>(`registration/${registrationId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getTeams(options: RegistrationEntryFilterOptions): Promise<PagedList<EntryTeam>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<EntryTeam>>(`registration/${options.registrationId}/teams`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getPlayers(options: RegistrationEntryFilterOptions): Promise<PagedList<EntryPlayer>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<EntryPlayer>>(`registration/${options.registrationId}/players`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsert(model: Registration): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsertCategory(model: RegistrationCategory): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration/category`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static publish(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${id}/publish`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static delete(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${id}/delete`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getCostMatrices(typeId: number, registrationId?: number, clubType?: AffiliationType): Promise<CostMatrix[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CostMatrix[]>(`registration/pricings`,
				{
					params: { typeId, registrationId, clubType },
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsertCostMatrix(model: CostMatrix): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`registration/pricings`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removeCostMatrices(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/pricings/${id}/remove`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getRegistrationCostAdjustments(registrationId: number, options: CostAdjustmentFilterOptions): Promise<PagedList<CostAdjustment>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<CostAdjustment>>(`registration/${registrationId}/adjustments`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getCostAdjustments(options: CostAdjustmentFilterOptions): Promise<PagedList<CostAdjustment>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<CostAdjustment>>(`registration/adjustments`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsertCostAdjustment(model: CostAdjustment): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration/adjustments`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removeCostAdjustment(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/adjustments/${id}/remove`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getCompetitionTypes(): Promise<CompetitionType[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CompetitionType[]>(`registration/competitiontypes`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getTenantCompetitionTypes(tenantId: number): Promise<CompetitionType[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CompetitionType[]>(`registration/competitiontypes/tenant/${tenantId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getLinkedCompetitionTypes(): Promise<CompetitionType[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CompetitionType[]>(`registration/competitiontypes/linked`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static UpsertCompetitionTypes(competitionType: CompetitionType): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`registration/competitiontypes`, competitionType)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getCompetitions(term: string = "", registrationId: number, competitionTypeId?: number, take: number = 10): Promise<Competition[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Competition[]>(`competitions/?search=${term}&take=${take}&registrationId=${registrationId}&competitionTypeId=${competitionTypeId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static loadCompetitionDivisions(competitionId: number, registrationId: number, competitionTypeId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${registrationId}/category/${competitionTypeId}/divisions/${competitionId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static loadCompetitionTeams(competitionId: number, registrationId: number, competitionTypeId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${registrationId}/category/${competitionTypeId}/teams/${competitionId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static loadEntryTeams(registrationId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${registrationId}/entry/teams`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getRegistrationTeams(registrationId: number): Promise<RegistrationTeam[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<RegistrationTeam[]>(`registration/category/${registrationId}/teams`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getDivisions(registrationId: number): Promise<RegistrationDivision[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<RegistrationDivision[]>(`registration/category/${registrationId}/divisions`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static clearDivisionsAndTeams(registrationId: number, competitionTypeId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${registrationId}/category/${competitionTypeId}/divisions/clear`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static clearTeams(registrationId: number, competitionTypeId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`registration/${registrationId}/category/${competitionTypeId}/teams/clear`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsertTeam(registrationId: number, model: RegistrationTeam): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration/category/${registrationId}/teams`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsertDivision(registrationId: number, model: RegistrationDivision): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration/category/${registrationId}/divisions`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static OrderDivision(registrationId: number, models: RegistrationDivision[]): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration/category/${registrationId}/divisions/order`, models)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removeTeam(registrationId: number, model: RegistrationTeam): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`registration/category/${registrationId}/teams/remove`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removeDivision(registrationId: number, model: RegistrationDivision): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`registration/category/${registrationId}/divisions/remove`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}
}