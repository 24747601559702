import * as React from "react";
import PublicLayout from "@components/layouts/PublicLayout";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { Booking, BookingResource, BookingType } from "@models/booking";
import { BookingService } from "@services/booking.service";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import * as NProgress from "nprogress";
import { toast } from "react-toastify";
import * as moment from "moment";
import { EventService } from "@services/event.service";
import "react-big-calendar/lib/sass/styles";
import "react-big-calendar/lib/addons/dragAndDrop/styles";
import { Calendar, View, momentLocalizer, CalendarProps, Event as BCEvent } from "react-big-calendar";
import * as BigCalendar from "react-big-calendar";
const withDragAndDrop = require("react-big-calendar/lib/addons/dragAndDrop");

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = BigCalendar.momentLocalizer(moment);

interface IBookingSlotAttendeesProps {
	site?: string;
	match?: any;
}

export class CalendarEvent implements BCEvent {
	id: number;
	title?: string;
	start?: Date;
	end?: Date;
	allDay?: boolean;
}

interface IBookingSlotAttendeesState {
	loading: boolean;
	slot?: Booking;
	contact?: Contact;
	overEighteen?: boolean;
	display: any[];
	date?: Date;
	minTime?: Date;
	maxTime?: Date;
	resources: BookingResource[];
	calendarEntries: CalendarEvent[];
}

export class BookingSlotAttendeesPage extends React.Component<IBookingSlotAttendeesProps, IBookingSlotAttendeesState> {

	private spaceId: number;
	private slotId: number;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			slot: {},
			display: [],
			resources: [],
			calendarEntries: []
		};
		this.slotId = this.props.match.params.slotId as number;
		this.spaceId = this.props.match.params.spaceId as number;
	}

	componentDidMount() {
		this.loadComplete(this.slotId);
	}

	componentDidUpdate(props: any, state: any) {

	}

	async loadComplete(slotId: number) {

		await BookingService.getResourcesById(this.spaceId).then(result => {
			this.setState({
				resources: result
			});
		});

		await BookingService.getById(slotId).then(result => {
			this.setState({
				slot: result,
				date: result.startDate,
				minTime: moment(result.startDate).startOf("day").toDate(),
				maxTime: moment(result.startDate).endOf("day").toDate()
			});
		});

		await ContactService.getByUser().then(contact => {
			let overAge = false;

			if (contact.user?.dob != null && contact.user?.dob != undefined) {
				const dob = new Date(contact?.user.dob);
				var ageDifMs = Date.now() - dob.getTime();
				var ageDate = new Date(ageDifMs);
				var age = Math.abs(ageDate.getUTCFullYear() - 1970);
				if (age > 17) {
					overAge = true;
				} else {
					overAge = false;
				}
			}

			this.setState({ contact, overEighteen: overAge });
		});

		const start = moment(this.state.slot?.startDate).startOf("day").toDate();
		const end = moment(this.state.slot?.startDate).endOf("day").toDate();

		const bookings = await BookingService.getBookings(this.spaceId, { start, end, cancelled: false });
		const eventSessions = await EventService.getSessionsBySpace({ start, end }, this.spaceId);

		BookingService.getTimeFrame(this.spaceId).then(result => {
			const start = result.start;
			let end = result.end;
			if (moment(end).hours() === 0) {
				end = moment().endOf("day").toDate();
			}

			this.setState({
				minTime: moment(start).toDate(),
				maxTime: moment(end).toDate()
			}, () => {
				NProgress.done();
			});
		});

		const display = [];

		eventSessions.forEach(b => {
			if (b.event.type === 1 || b.event.type === 4) {
				b.resourceIds.forEach(resourceId => {
					display.push({
						id: b.id,
						title: `${b.event.title}`,
						start: moment(b.startDate).toDate(),
						end: moment(b.endDate).toDate(),
						resourceId,
						type: 'event',
						allDay: false
					});
				});

			} else if (b.event.type === 2) {
				b.resourceIds.forEach(resourceId => {
					display.push({
						id: b.id,
						title: `${b.event.title}`,
						start: moment(b.startDate).toDate(),
						end: moment(b.endDate).toDate(),
						resourceId,
						type: 'session',
						allDay: false
					});
				});

			}

		});

		bookings.filter(b => b.type === BookingType.Contact).forEach(b => {
			display.push({
				id: b.id,
				title: b.contact?.id == this.state.contact?.id ?
				'You'
				:
				(b.contact?.displayNameVisible == true) ? b.contact?.displayName ?? "" : 'Anonymous participant',
				start: moment(b.startDate).toDate(),
				end: moment(b.endDate).toDate(),
				resourceId: b.resourceId,
				type: 'booking'
			});
		});

		bookings.filter(b => b.type === BookingType.Reservation).forEach(b => {
			display.push({
				id: b.id,
				title: `${b.title}`,
				start: moment(b.startDate).toDate(),
				end: moment(b.endDate).toDate(),
				resourceId: b.resourceId,
				type: 'reservation'
			});
		});

		this.setState({ display, loading : false, });
	}

	async dateNavigation (date: Date) {
		this.setState({loading: true});
		var start = moment(date).startOf("day").toDate();
		var end = moment(date).endOf("day").toDate();

		const bookings = await BookingService.getBookings(this.spaceId, { start, end, cancelled: false });
		const eventSessions = await EventService.getSessionsBySpace({ start, end }, this.spaceId);

		BookingService.getTimeFrame(this.spaceId).then(result => {
			const start = result.start;
			let end = result.end;
			if (moment(end).hours() === 0) {
				end = moment().endOf("day").toDate();
			}

			this.setState({
				minTime: moment(start).toDate(),
				maxTime: moment(end).toDate()
			}, () => {
				NProgress.done();
			});
		});

		const display = [];

		eventSessions.forEach(b => {
			if (b.event.type === 1 || b.event.type === 4) {
				b.resourceIds.forEach(resourceId => {
					display.push({
						id: b.id,
						title: `${b.event.title}`,
						start: moment(b.startDate).toDate(),
						end: moment(b.endDate).toDate(),
						resourceId,
						type: 'event',
						allDay: false
					});
				});

			} else if (b.event.type === 2) {
				b.resourceIds.forEach(resourceId => {
					display.push({
						id: b.id,
						title: `${b.event.title}`,
						start: moment(b.startDate).toDate(),
						end: moment(b.endDate).toDate(),
						resourceId,
						type: 'session',
						allDay: false
					});
				});

			}

		});

		bookings.filter(b => b.type === BookingType.Contact).forEach(b => {
			display.push({
				id: b.id,
				title: b.contact?.id == this.state.contact?.id ?
				'You'
				:
				(b.contact?.displayNameVisible == true) ? b.contact?.displayName ?? "" : 'Anonymous participant',
				start: moment(b.startDate).toDate(),
				end: moment(b.endDate).toDate(),
				resourceId: b.resourceId,
				type: 'booking'
			});
		});

		bookings.filter(b => b.type === BookingType.Reservation).forEach(b => {
			display.push({
				id: b.id,
				title: `${b.title}`,
				start: moment(b.startDate).toDate(),
				end: moment(b.endDate).toDate(),
				resourceId: b.resourceId,
				type: 'reservation'
			});
		});

		this.setState({ display, loading : false, date });
	}

	updateContact() {
		NProgress.start();
		ContactService.upsert(this.state.contact).then((result) => {
			toast.success("Your preference has been updated");
		}).catch((err) => {
			toast.error("Something went wrong");
		}).finally(() => NProgress.done());
	}

	public render() {
		const resourceMap = [];
		this.state.resources.map(r => resourceMap.push({ resourceId: r.id, resourceTitle: r.name }));
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="mb-5">
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">Booking Slot Participants</span></h2>
											<p className="text-center">Current participants within your selected date</p>
										</div>
										{
											this.state.overEighteen &&
											<div className="d-flex justify-content-center">
												<Toggle checked={this.state.contact?.displayNameVisible}
													className="mr-3"
													onChange={e => this.setState({ contact: { ...this.state.contact, displayNameVisible: e } }, () => this.updateContact())} />
												Allow my name to be seen by other participants
											</div>
										}
										<div className="competition-list mg-t-5">
											<div className="row clearfix">
												<div className="col-lg-12">
													<div className="list-group list-group-default">
														<Calendar
															localizer={localizer}
															min={this.state.minTime}
															max={this.state.maxTime}
															startAccessor="start"
															endAccessor="end"
															onNavigate={(date) => this.dateNavigation(date)}
															events={this.state.display}
															views={['day']}
															step={15}
															date={this.state.date}
															defaultView={'day'}
															resources={resourceMap}
															resourceIdAccessor="resourceId"
															resourceTitleAccessor="resourceTitle"
															eventPropGetter={
																(event, start, end, isSelected) => {
																	const style = {
																		backgroundColor: "lightgrey",
																		color: 'white',
																		borderRadius: "0px",
																		border: "solid 1px transparent",
																		display: "flex",
																		padding: "20px"
																	};



																	if (event.type === 'booking') {
																		style.backgroundColor = "#E11F3C";
																	} else if (event.type === 'event') {
																		style.backgroundColor = "#1FBDE0";
																	} else if (event.type === 'session') {
																		style.backgroundColor = "#1FE083";
																	} else if (event.type === "table") {
																		style.backgroundColor = event.color;
																	} else if (event.type === "reservation") {
																		style.backgroundColor = "#242331";
																	}

																	return { className: "", style };
																}
															}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
