import * as React from "react";

interface IProgressButton {
	children: any;
	className?: string;
	onClick?: ((e: React.MouseEvent<HTMLAnchorElement>) => void);
	loading?: boolean;
	submitForm?: boolean;
	disabled?: boolean;
}

export class ProgressButton extends React.Component<IProgressButton, any> {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		//
	}

	onClick(e: React.MouseEvent<HTMLAnchorElement>) {
		//
		if (!this.props.loading) {
			this.props.onClick(e);
		}
	}

	generateClassName(): string {
		let className = this.props.className;

		if (this.props.loading) {
			className += " loading";
		}

		return className;
	}

	handleSubmitFormClick = () => (e:any) => {
		if(this.props.loading) {
			e.preventDefault();
			return false;
		}
	}

	handleButtonClick = () => (e:any) => {
		if(this.props.loading) {
			e.preventDefault();
			return false;
		} else {
			this.onClick(e);
		}
	}

	render() {
		return (
			this.props.submitForm ?
				<button disabled={this.props.disabled} className={this.generateClassName()} type="submit" onClick={this.handleSubmitFormClick()}>{this.props.loading ? undefined : this.props.children}<i className="fa fa-spin fa-circle-notch"></i></button> :
				<button disabled={this.props.disabled} className={this.generateClassName()} onClick={this.handleButtonClick()}>{this.props.loading ? undefined : this.props.children}<i className="fa fa-spin fa-circle-notch"></i></button>
		);
	}
}


export class CircularProgressButton extends React.Component<IProgressButton, any> {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		//
	}

	onClick(e: React.MouseEvent<HTMLAnchorElement>) {
		//
		if (!this.props.loading) {
			this.props.onClick(e);
		}
	}

	generateClassName(): string {
		let className = this.props.className;

		if (this.props.loading) {
			className += " disabled";
		}

		return className;
	}

	render() {
		return (<div className={`pb-container ${this.props.loading ? "loading" : ""}`}>
			{/* {
				this.props.submitForm ?
					<button className={this.generateClassName()} type="submit"><span>{this.props.children}</span></button> :
					<a className={this.generateClassName()} onClick={this.onClick.bind(this)}>{this.props.disabled ? undefined : this.props.children}</a>
			} */}
			<button className={'pb-button'} onClick={this.props.onClick.bind(this)}>
			<span>{this.props.children}</span>
			<svg className={'pb-progress-circle'} viewBox='0 0 41 41'>
				<path d='M38,20.5 C38,30.1685093 30.1685093,38 20.5,38' />
			</svg>
			<svg className={'pb-checkmark'} viewBox='0 0 70 70'>
				<path d='m31.5,46.5l15.3,-23.2' />
				<path d='m31.5,46.5l-8.5,-7.1' />
			</svg>
			<svg className={'pb-cross'} viewBox='0 0 70 70'>
				<path d='m35,35l-9.3,-9.3' />
				<path d='m35,35l9.3,9.3' />
				<path d='m35,35l-9.3,9.3' />
				<path d='m35,35l9.3,-9.3' />
			</svg>
			</button>
		</div>
		);
	}
}
