import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Card, CardBody, Label } from "reactstrap";

import ReactHtmlParser, { } from "react-html-parser";
import PublicLayout from "@components/layouts/PublicLayout";

import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { WebsiteCustomMenuItem, WebsiteMenuPreset } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";
import { toast } from "react-toastify";
import { imageUrl } from "@helpers/file.helper";
import { RankingsService } from "@services/rankings/rankings.service";
import { PlayerRanking } from "@models/rankings/playerranking";
import { NumberHelper } from "@helpers/number.helper";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";
import Select from "react-select";
import { SelectOption } from "@models/forms";
import moment from "moment";
import History from "@helpers/history.helper";
import ObjectHelper from "@helpers/object.helper";
import { genderLetter } from "@helpers/rankings/gender.helper";
import { CoreEventType } from "@models/rankings/enums/coreventtype";
import { MatchVerdict } from "@models/rankings/enums/matchverdict";
import { PlayingPositionHelper } from "@helpers/rankings/playingposition.helper";
import { PlayerPointsEloUpdate } from "@models/rankings/playerpoints_eloupdate";
import { MemberService } from "@services/rankings/member.service";
import { RankingMemberIdentity } from "@models/member";
import { PlayerRankingCategoryPosition } from "@models/rankings/playerrankingcategoryposition";
import ScrollToTop from "@components/controls/ScrollToTop";


interface IRankingsIndividualPageProps {
	categoryId?: string;
	instanceId?:string;
	memberId?:string;
	eventId?:string;
}

const playerRankingToSelectOption = (r:PlayerRanking):SelectOption<number> => ({ label: `${moment(r?.event?.start).format("DD MMM YYYY")} - ${r?.event?.name}`, value: r?.eventId });

const PositionItem = (p:PlayerRankingCategoryPosition, active:boolean) => (
	<Card className={`clickable position ${active ? "selected" :""}`}>
		<CardBody className={`d-flex flex-row p-2`}>
			<h2 className="position-number mr-4 my-2 text-danger">{p.position}</h2>
			<div className="d-flex flex-column justify-content-center align-items-start">
				<h4 className="h4 font-weight-bold">{p.category?.name}</h4>
				<p className="m-0">{p.playerRanking?.event?.start}</p>
			</div>
		</CardBody>
	</Card>
)

const EloTableHeadings = () => (
	<tr role="row">
		<th className="data-date sorting" rowSpan={1} colSpan={1} aria-label="Match Date" style={{width: "10em"}}>Date</th>
		<th className="data-home sorting text-right" rowSpan={1} colSpan={1} aria-label="Home Match Info">Home</th>
		<th className="data-p" rowSpan={1} colSpan={1} aria-label="vs" style={{width: "1em"}}></th>
		<th className="data-away sorting" rowSpan={1} colSpan={1} aria-label="Away Match Info">Away</th>
		<th className="data-points sorting" rowSpan={1} colSpan={1} aria-label="ELO Points" style={{width: "5em"}}>Points</th>
		<th className="data-indicator sorting" rowSpan={1} colSpan={1} aria-label="Points Change" style={{width: "1em"}}></th>
	</tr>
);

const EloRowItem = ({index, update, ranking, eventType}:{index: number, update:PlayerPointsEloUpdate, ranking:PlayerRanking, eventType:CoreEventType}) => {
	const p = update.playerPointsElo;
	const match = p?.match;
	const prev = ranking?.playerEloUpdates?.at(index + 1)?.playerPointsElo;
	const prevPoints = prev?.points ?? ranking.eloPrevious;
	const homeColor = match.verdict === MatchVerdict.HomeWin ? "text-danger" : "";
	const awayColor = match.verdict === MatchVerdict.AwayWin ? "text-danger" : "";

	return (
		<tr key={`update_${update.id}`} className="sp-row-no-0 odd bg-white" role="row">
			<td className="data-date sorting" data-label="Match Date">{moment(match.date).format("lll")}</td>
			<td className={`data-home sorting text-right`} data-label="Home Match Info">
				<span className={`mr-2 ${homeColor}`}>{match.homePlayerMembershipNum}</span>
				{eventType == CoreEventType.League &&
					<span className={`font-weight-bold mr-3 ${homeColor}`}>
						{PlayingPositionHelper.getPlayingPosIdentifiersV2(match.homeLeaguePlayingPosition, true)}
					</span>
				}
				<span className={`mr-2 ${homeColor}`}>{match.homePlayer !== undefined ? genderLetter(match.homePlayer?.gender) : "?"}</span>
				<span className={`${p.playerMembershipId === match.homePlayerMembershipNum ? "font-weight-bold" : ""} ${homeColor}`}>{match.homePlayerImportedName}</span>
			</td>
			<td className="data-p" data-label="vs">V</td>
			<td className={`data-away sorting ${awayColor}`} data-label="Away Match Info">
				<span className={`${p.playerMembershipId === match.awayPlayerMembershipNum ? "font-weight-bold" : ""} ${awayColor}`}>{match.awayPlayerImportedName}</span>
				<span className={`ml-2 ${awayColor}`}>{match.awayPlayer !== undefined ? genderLetter(match.awayPlayer?.gender) : "?"}</span>
				{eventType === CoreEventType.League &&
					<span className={`font-weight-bold ml-3 ${awayColor}`}>
						{PlayingPositionHelper.getPlayingPosIdentifiersV2(match.awayLeaguePlayingPosition, true)}
					</span>
				}
				<span className={`ml-2 ${awayColor}`}>{match.awayPlayerMembershipNum}</span>
			</td>
			<td className="data-points sorting text-center" data-label="ELO Points">{NumberHelper.toPrecision(p.points)}</td>
			<td className="data-indicator sorting text-primary" data-label="Points Change">
				<PointsChangeIndicator points={p?.points} prevPoints={prevPoints} />
			</td>
		</tr>
	);
};

const EloInitialPointsItem = ({points}:{points: number}) => (
	<tr key={`update_initial`} className="sp-row-no-0 odd bg-white" role="row">
		<td className="data-date sorting" data-label="Match Date" colSpan={2}>Initial Points before Matches</td>
		<td data-label="spacer" colSpan={2} />
		<td className="data-points sorting text-center" data-label="Initial Points">{NumberHelper.toPrecision(points)}</td>
		<td data-label="spacer"  />
	</tr>
);
const EmptyELOItem = ({ colSpan }:{colSpan: number}) => {
	return (
		<tr className="sp-row-no-0 odd bg-white" role="row">
			<td className="data-empty text-center align-middle" colSpan={colSpan} style={{height: 200}}>
				<p className="m-0">No Matches found for this event.</p>
			</td>
		</tr>
	);
};

const EmptyXinZItem = ({ colSpan }:{colSpan: number}) => {
	return (
		<tr className="sp-row-no-0 odd bg-white" role="row">
			<td className="data-empty text-center align-middle" colSpan={colSpan} style={{height: 200}}>
				<p className="m-0">No other Events found that match the criteria.</p>
			</td>
		</tr>
	);
};

const BestXinZTableHeadings = () => (
	<tr role="row">
		<th className="data-selected sorting" rowSpan={1} colSpan={1} aria-label="Selected" style={{width: "1em"}}></th>
		<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Event Name">Name</th>
		<th className="data-date sorting" rowSpan={1} colSpan={1} aria-label="Date">Date</th>
		<th className="data-elo sorting" rowSpan={1} colSpan={1} aria-label="ELO">ELO</th>
		<th className="data-protected sorting" rowSpan={1} colSpan={1} aria-label="Protected Points">Protected</th>
		<th className="data-bonus sorting" rowSpan={1} colSpan={1} aria-label="Bonus">Bonus</th>
		<th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="Total">Total</th>
	</tr>
);

const bestXinZEventItem = (id:number, date:string, name:string, elo:number, protectedPoints:number, bonus:number, total:number, isLegacy:boolean, selected:boolean) => (
	<tr key={`bonuspoints_${id}_${isLegacy ? 0 : 1}`} className={`sp-row-no-0 odd bg-white ${selected ? "selected" :""}`} role="row">
		<td className={`data-selected`} data-label="Selected">{selected && <i className="fas fa-circle text-danger" />}</td>
		<td className={`data-name ${isLegacy ? "font-italic": ""}`} data-label="Name">{name}</td>
		<td className="data-date" data-label="Date">{moment(date).format("LL")}</td>
		<td className="data-elo text-primary" data-label="ELO">{NumberHelper.toPrecision(elo)}</td>
		<td className="data-p" data-label="Protected Points">{isLegacy ? "-" : NumberHelper.toPrecision(protectedPoints)}</td>
		<td className="data-p" data-label="Bonus Points">{isLegacy ? "-" : NumberHelper.toPrecision(bonus)}</td>
		<td className="d-none d-md-table-cell data-g font-weight-bold text-primary" data-label="Total">{NumberHelper.toPrecision(total)}</td>
	</tr>
)

const PointsChangeIndicator = ({points, prevPoints }:{points:number, prevPoints:number}) => {
	if ((points === undefined || prevPoints === undefined) ||
		(points === prevPoints)) {
		return <></>;
	} else {
		return points > prevPoints ? <i className="fas fa-2x fa-caret-up text-success" /> : <i className="fas fa-2x fa-caret-down text-danger" />;
	}
}

const RankingsIndividualPage = (props: RouteComponentProps<IRankingsIndividualPageProps>) => {
	const storageKey: string = "PublicRankingsIndividualPageNo";
	const [websiteMenu, setWebsiteMenu] = useState<WebsiteCustomMenuItem>(undefined);
	const [loadingMenu, setLoadingMenu] = useState<boolean>(true);

	const [loadingMember, setLoadingMember] = useState<boolean>(true);
	const [loadingRankings, setLoadingRankings] = useState<boolean>(false);
	const [member, setMember] = useState<RankingMemberIdentity>(undefined);
	const [playerRankings, setPlayerRankings] = useState<PlayerRanking[]>(undefined);
	const [playerPositions, setPlayerPositions] = useState<PlayerRankingCategoryPosition[]>(undefined);
	const category = undefined;
	//const [dates, setDates] = useState<string[]>([]);
	//const [bestXinZParent] = useAutoAnimate({ duration: 500, easing:"ease-out" });
	//const [eloChangesParent] = useAutoAnimate({ duration: 500, easing:"ease-out" });

	const { categoryId, instanceId, memberId, eventId } = props.match.params;
	const isLoading = loadingMenu || loadingRankings;

	useEffect(() => {
		WebsiteService.updateActivity("NationalRankingsList");
		menuLoad();
		loadMember();
		//loadDatesAndCategory();
		//loadRankings();
	}, []);

	useEffect(() => {
		const _memberId = parseInt(memberId as any, 10);
		if (!isNaN(_memberId)) {
			loadMemberRankings();
		}
	}, [instanceId, categoryId])

	useEffect(() => {
		MiscHelper.scrollToTop();
	}, [websiteMenu])

	const menuLoad = async () => {
		setLoadingMenu(true);
		try {
			const menu = await WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Rankings);
			setWebsiteMenu(menu);
		}
		catch (err) {
			toast.error(err);
		}
		setLoadingMenu(false);
	};

	const loadMember = async () => {
		setLoadingMember(true);
		try {
			const _memberId = isNaN(memberId as any) ? undefined : parseInt(memberId, 10);
			const member = await MemberService.getByMembershipNumber(_memberId);

			setMember(member);
		}
		catch (err) {
			toast.error(err);
		}
		setLoadingMember(false);
	};

	const loadMemberRankings = async () => {
		setLoadingRankings(true);
		try {
			const _categoryId = isNaN(categoryId as any) ? undefined : parseInt(categoryId, 10);
			const _memberId = isNaN(memberId as any) ? undefined : parseInt(memberId, 10);

			const positions = await RankingsService.getPlayerPositionsFromInstancedEventAndMemberId(_memberId);
			const rankings = await RankingsService.getDetailedResultDataFromInstanceCategoryEventAndMemberId(_categoryId, _memberId);

			const _eventId = isNaN(memberId as any) ? undefined : parseInt(memberId, 10);
			if ((eventId === undefined || eventId.length === 0) && rankings.length > 0 || !rankings.some(r => r.eventId === _eventId)) {
				handleEventSelected(true, playerRankingToSelectOption(rankings?.at(0)));
			}

			setPlayerPositions(positions);
			setPlayerRankings(rankings);
		}
		catch (err) {
			toast.error(err);
		}
		setLoadingRankings(false);
	};


	const handleEventSelected = (replace: boolean, item: SelectOption<number>) => {
		const url = `/rankings/national/category/${categoryId}/${memberId}/${item?.value ?? ""}`;
		if (replace) {
			History.replace(url);
		} else {
			History.push(url);
		}
	};
	const handleCategorySelected = (_categoryId: number, e:React.MouseEvent<HTMLAnchorElement, any>) => {
		e?.preventDefault();

		if (_categoryId.toString() !== categoryId) {
			const url = `/rankings/national/category/${_categoryId}/${memberId}/${eventId}`;
			History.replace(url);
		}
	};

	//const dateOptions = dates.map<SelectOption<string>>(x => dateToSelectOption(x));
	const eventOptions = playerRankings?.map<SelectOption<number>>(x => playerRankingToSelectOption(x));
	const activeRanking = isNaN(parseInt(eventId, 10)) ? undefined : playerRankings?.find(x => x.eventId.toString() === eventId);
	const eventType = activeRanking?.event?.eventType?.type;
	const rankingModel = activeRanking?.instancedEvent?.rankingModel;

	const PositionsList = useMemo(() => (
		<Row className="mb-5">
			{playerPositions && playerPositions?.map((p, index) =>
				<Col md={3} key={p.playerRankingId} onClick={handleCategorySelected.bind(this, p.categoryId)}>
					{PositionItem(p, categoryId === p.categoryId.toString())}
				</Col>
			)}
		</Row>
	), [playerPositions, categoryId]);

	const BestXinZList = useMemo(() => (
		activeRanking && activeRanking?.bestXinZEventItems?.map((link, index) => {

			if (link?.instancedEvent?.playerRankings?.at(0) !== undefined)
				return bestXinZEventItem(
					link?.instancedEvent?.playerRankings?.at(0)?.id,
					link?.instancedEvent?.event?.start,
					link?.instancedEvent?.event?.name,
					link?.instancedEvent?.playerRankings?.at(0)?.elo,
					link?.instancedEvent?.playerRankings?.at(0)?.totalProtected,
					link?.instancedEvent?.playerRankings?.at(0)?.totalBonusScaled,
					link?.instancedEvent?.playerRankings?.at(0)?.total,
					false,
					link?.selected ?? false
				);
			else if (link?.legacyEvent?.playerPoints?.at(0) !== undefined)
				return bestXinZEventItem(
					link?.legacyEvent?.playerPoints?.at(0)?.id,
					link?.legacyEvent?.start,
					link?.legacyEvent?.name,
					link?.legacyEvent?.playerPoints?.at(0)?.elo,
					0,
					link?.legacyEvent?.playerPoints?.at(0)?.bonus,
					link?.legacyEvent?.playerPoints?.at(0)?.total,
					true,
					link?.selected ?? false
				);
			else
				return <></>;
		})
	), [activeRanking, eventType]);

	const EloChangesList = useMemo(() => (
		<>
		{activeRanking && activeRanking?.playerEloUpdates?.map((update, index) =>
			<EloRowItem eventType={eventType} index={index} ranking={activeRanking} update={update} />
		)}
		{activeRanking && <EloInitialPointsItem points={activeRanking?.eloPrevious} />}
		</>
	), [activeRanking, eventType]);

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!isLoading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						<ScrollToTop />
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
										style={category?.imageId && {
											backgroundImage: `url("${imageUrl(category?.imageId)}")`,
											backgroundRepeat: "no-repeat",
											//backgroundAttachment:"fixed",
											backgroundPosition: "center",
											backgroundSize: "cover"
										}}
									>
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint">
																		<div className="sc_layouts_title_content">
																			<div className="sc_layouts_title_title">
																				<h1 className={`sc_layouts_title_caption ${(ObjectHelper.isNull(category?.imageId) || category?.darkImage) ? "text-light" : "text-dark"}`}>
																					<span className="mr-4" style={{fontSize: "200%"}}>{activeRanking?.positions?.at(0)?.position}</span>
																					{member?.name}
																				</h1>
																				<p className={`header--description ${(ObjectHelper.isNull(category?.imageId) || category?.darkImage) ? "text-light" : "text-dark"}`}>
																					{`${member?.membershipNo} ${genderLetter(member?.gender)} - ${moment(activeRanking?.event?.start, "YYYY-MM-DD").format("MMM YYYY")}`}
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(websiteMenu?.title, "Memberships")}</h1>
												<p className="header--description">{StringHelper.ifEmpty(websiteMenu?.subTitle, "Available memberships")}</p>
											</div>
										</div>
									</div>
								</div>
						}

						<div className="page_content_wrap">
							<div className="container">
								<div className="pre-content">
									{
										websiteMenu?.body &&
										<Row>
											<Col>
												{ReactHtmlParser(websiteMenu.body)}
											</Col>
										</Row>
									}
								</div>

								<div className="content national-rankings">
									<div className="categories-list mg-t-20">

										<h3 className="text-center">Categories</h3>
										{PositionsList}
										<Card
											className="mb-4">
											<CardBody className="d-flex">
												<div className="col-12">
													<div>
														<div className="heading mb-5">

															<div className="text-right">
																<Label className="mr-2">Event</Label>
																<Select
																	id="instancepicker"
																	styles={{
																		control: (baseStyles, state) => ({
																			...baseStyles,
																			//textAlign: "right",
																			minWidth: "20em",
																			width:"20em"
																		}),
																		menuList: (baseStyles, props) => ({
																			...baseStyles,
																			textAlign: "left"
																		})
																	}}
																	className="d-inline-block mr-2"
																	placeholder={"Select event..."}
																	options={eventOptions}
																	closeMenuOnSelect={true}
																	value={eventOptions.find(x => x.value.toString() === eventId)}
																	onChange={handleEventSelected.bind(this, false)}
																	/>
															</div>
															<h3 className="text-center">ELO Changes</h3>
														</div>

														<div className="standings position-relative" style={{minHeight: "10rem"}}>
															<div>
																<div className="table-responsive">
																	<div>
																		<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
																			<thead>
																				<EloTableHeadings />
																			</thead>
																			<tbody //ref={eloChangesParent}>
																			>
																				{EloChangesList}
																				{!loadingRankings && activeRanking?.playerEloUpdates?.length === 0 &&
																					<EmptyELOItem colSpan={6} />
																				}

																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
															{loadingRankings && <ActivitySpinner overlay />}
														</div>

													</div>
												</div>

											</CardBody>
										</Card>
										<Card
											className="mb-4">
											<CardBody className="d-flex">
												<div className="col-12">
													<div>
														<div className="heading mb-5">
															<h3 className="text-center">Best {rankingModel?.bestOfCount} Event{rankingModel?.bestOfCount === 1 ?"" :"s"} in {rankingModel?.bestOfDays} Days</h3>
														</div>

														<div className="standings position-relative" style={{minHeight: "10rem"}}>
															<div>
																<div className="table-responsive">
																	<div>
																		<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
																			<thead>
																				<BestXinZTableHeadings />
																			</thead>
																			<tbody //ref={bestXinZParent}>
																			>
																				{BestXinZList}
																				{!loadingRankings && activeRanking?.bestXinZEventItems?.length === 0 &&
																					<EmptyXinZItem colSpan={8} />
																				}
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
															{loadingRankings && <ActivitySpinner overlay />}
														</div>

													</div>
												</div>

											</CardBody>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</div>)}
			/>
		</PublicLayout>
	);
};

export default RankingsIndividualPage;