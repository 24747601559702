import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";
import { ImageUpload } from "@components/controls/ImageUpload";
import { AppConfig } from "@config";
import { VisualEditor } from "@components/controls/VisualEditor";

import Select from "react-select";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactPlayer from "react-player";
import { SketchPicker } from "react-color";
import { ContactService } from "@services/contact.service";
import { Contact, CreateContact, CreateContactType } from "@models/contact";
import { toast } from "react-toastify";

const DraggableMailList = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [subTitle, setSubTitle] = useState(props.payload.subTitle);
	const [info, setInfo] = useState(props.payload.info);
	const [body, setBody] = useState(props.payload.body);
	const [layout, setlayout] = useState(props.payload.layout);

	const [email, setEmail] = useState<string>();
	const [accept, setAccept] = useState<boolean>(false);

	const [editMode, setEditMode] = useState(false);

	const handleSignUp = () => async (e: any) => {
		e.preventDefault();

		if (accept) {
			const contact = new CreateContact();
			contact.email = email;
			contact.name = email;
			contact.type = CreateContactType.Email;
			await ContactService.create(contact);

			setEmail("");
			setAccept(false);

			toast.success("Thank you for joining the mailing list");
		} else {
			toast.error("Please accept our terms and conditions");
		}
	};

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<section className="scheme_dark" style={{ backgroundColor: "#081224", padding: "50px 0 50px 0" }}>
				<div className="container">
					<Row>
						<Col md={3}>
							<h4 className="sc_item_title sc_title_title sc_item_title_style_default">
								<span className="sc_item_title_text">Sign Up Now</span>
							</h4>
						</Col>
						<Col>
							<p>{body}</p>
						</Col>
						<Col>
							<form onSubmit={handleSignUp()} className="mc4wp-form mc4wp-form-838">
								<div className="mc4wp-form-fields">
									<input type="email" name="EMAIL" placeholder="Email" required className="fill_inited" value={email} onChange={e => setEmail(e.target.value)} />
									<input type="submit" defaultValue="Submit" />
									<input id="maillist" name="maillist" type="checkbox" className="inited" checked={accept} onChange={e => setAccept(e.currentTarget.checked)} />
									<label htmlFor="maillist">I've read and accept the <a href="/privacy-policy/" target="_blank">Privacy Policy</a>.</label>
								</div>
							</form>
						</Col>
					</Row>
				</div>
			</section>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, layout } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, layout } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	return (
		<>
			<Draggable {...props} >
				<Card>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<p className="u-text-small">Mail List Sign Up</p>
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-pencil"></i>
							</a>
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Message</Label>
						<Input value={body} placeholder="Enter body text" onChange={e => setBody(e.target.value)} />
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-outline btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-secondary btn-outline btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableMailList;
