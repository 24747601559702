import React from "react";


import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import { ArrayHelper } from "@helpers/array.helper";
import { Team, TeamMember } from "@models/team";
import { TeamService } from "@services/team.service";
import { User } from "@models/user";
import { SelectOption } from "@models/forms";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { Can } from "@components/controls/Authentication";
import { TenantType } from "@models/tenant";
import { AppConfig } from "@config";
import { Competition } from "@models/competition";
import { toast } from "react-toastify";
import { MemberService } from "@services/member.service";


interface IAssignTeamMembersSidebar {
	team: Team;
	competition: Competition;

	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IAssignTeamMembersSidebar) => {
	const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
	const [member, setMember] = useState<SelectOption<string>>();
	const queue = new Queue(1);

	useEffect(() => {
		load();
	}, [, props.team]);

	const load = async () => {
		TeamService.getMembers(props.team.id, false).then(result => {
			setTeamMembers(result);
		});
	};

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {
		props.onConfirm();
		props.onClose();
	};

	const loadMemberOptions = () => (value: string): Promise<SelectOption<string>[]> => {
		return new Promise(resolve => {
			MemberService.search({
				search: value,
				competitionId: props.competition.id,
				email: false,
				player: true,
				assignment: true
			}).then(members => {
				const options = members.filter(m => teamMembers.every(tm => tm.name != m.name)).map(m => ({ label: m.name, value: m.id }));
				resolve(options);
			});
		});
	}

	const handleChangeMember = () => (option: SelectOption<string>) => {
		setMember(option);
		TeamService.assign(props.team.id, option.value).then(result => {
			load();
			setMember(null);
		});
	}

	const onSortEnd = () => (sort) => {
		const members = ArrayHelper.arrayMove(teamMembers, sort.oldIndex, sort.newIndex);

		for (let i = 0; i < members.length; i++) {
			const element = members[i];
			element.ordering = i;
		}

		setTeamMembers(members);

		TeamService.orderMembers(members).then(result => {
			load();
		});
	}

	const remove = (memberId: string) => () => {
		TeamService.unassign(props.team.id, memberId).then(result => {
			toast.success("Player removed");
			load();
		});
	}

	const SortableItem = SortableElement(({ value }) => (
		<Card className="mb-3 p-3">
			<Row>
				<Col xs={8} className="d-flex align-self-center">
					<div className="user-name-address">
						<h6 className="m-0">{value.name}</h6>
						<span>{value.membershipNo}</span>
					</div>
				</Col>
				<Col xs={4} className="d-flex justify-content-end align-self-center">
					{props.competition && props.competition.archived ?
						undefined
						: <button onClick={remove(value.memberId)} title="Remove Member" className="btn btn--circle btn-sm text-white">
							<i className="fas fa-times"></i>
						</button>
					}
				</Col>
			</Row>
		</Card>));

	const SortableList = SortableContainer(({ items }) => {
		return (
			<div className="list-group list-group-user list-cards">
				{items.map((value, index) => (
					<SortableItem key={`item-${index}`} index={index} value={value} />
				))}
			</div>
		);
	});

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Players
					<div className="help-container">
						<a className="help">
							<i className="far fa-info-circle"></i>
						</a>
					</div>
				</h6>
			</div>
			<div className="sidebar--content">
				<div className="form-layout">
					<div className="form-group">
						<label className="form-control-label">Member <span className="tx-danger">*</span></label>
						<AsyncSelect
							value={member}
							placeholder="Enter name or TTE number"
							loadOptions={loadMemberOptions()}
							closeMenuOnSelect={true}
							onChange={handleChangeMember()} />
					</div>
				</div>
				<SortableList distance={1} items={teamMembers} onSortEnd={onSortEnd()} helperClass="list-group list-group-user" />
			</div>
			<div className="form-layout-footer mt-4 text-center">
				<a tabIndex={0} className="btn-link clickable" onClick={handleClose()}>Cancel</a>
			</div>
			<div className="sidebar--footer">

			</div>
		</div>
	);
};