import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";
import { ImageUpload } from "@components/controls/ImageUpload";
import { AppConfig } from "@config";
import { VisualEditor } from "@components/controls/VisualEditor";

import Select from "react-select";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactPlayer from "react-player";
import { SketchPicker } from "react-color";
import { TwitterPicker } from "react-color";
import { Options } from "@data";


const DraggableContent = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [subTitle, setSubTitle] = useState(props.payload.subTitle);
	const [info, setInfo] = useState(props.payload.info);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [linkText, setLinkText] = useState(props.payload.linkText);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setlayout] = useState(props.payload.layout);
	const [imageId, setImageId] = useState<number>(props.payload.imageId);
	const [bgColor, setBgColor] = useState(props.payload.bgColor);

	const [placeholder, setPlaceholder] = useState(props.payload.placeholder);
	const [placeholderId, setPlaceholderId] = useState<number>(props.payload.placeholderId);
	const [videoUrl, setVideoUrl] = useState<string>(props.payload.videoUrl);

	const [imagePosition, setImagePosition] = useState(props.payload.imagePosition);

	// display
	const [showVideo, setShowVideo] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [setupMode, setSetupMode] = useState(props.payload.layout ? false : true);

	const getLayoutName = () => {
		switch (layout) {
			case 1:
				return "Title and text";
			case 2:
				return "Heading";
			case 3:
				return "Image and Text";
			case 4:
				return "Editor";
			case 5:
				return "Image";
			case 6:
				return "Video";
			default:
				break;
		}
	}

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<>
				{
					layout === 1 &&
					<section className="pagemaker-content pagemaker-content--1 theme--clear">
						<Container>
							<div className="sc_title scheme_dark sc_title_default">
								<span className="subtitle sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">
									{subTitle}
								</span>
								<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
									<span className="sc_item_title_text">{title}</span>
								</h1>
								<div className="sc_item_descr sc_title_descr">
									<p>
										{info}
									</p>
								</div>
								<div className="body">
									{ReactHtmlParser(body)}
								</div>
								<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
									{
										link &&
										<a href={link} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
											<span className="sc_button_text"><span className="sc_button_title">{linkText}</span></span>
										</a>
									}
								</div>
							</div>
						</Container>
					</section>
				}
				{
					layout === 2 &&
					<section className="pagemaker-content pagemaker-content--2 theme--clear">
						<div className="sc_title scheme_dark sc_title_default">
							<span className="subtitle sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
								{subTitle}
							</span>
							<h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
								<span className="sc_item_title_text">{title}</span>
							</h1>
							<div className="sc_item_descr sc_title_descr">
								<p>
									{info}
								</p>
							</div>
						</div>
					</section>
				}
				{
					layout === 3 &&
					<section className="pagemaker-content pagemaker-content--3 theme--light">
						<Row>
							{
								imagePosition === 1 &&
								<Col md={6}>
									<div className="bg-image overlay bg-cover" style={{ backgroundImage: `url(${image})` }}></div>
								</Col>
							}
							<Col md={6}>
								<div className="content">
									<div className="sc_title scheme_dark sc_title_default">
										<span className="subtitle sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">{subTitle}</span>
										<h2 className="sc_item_title sc_title_title sc_item_title_style_default">
											<span className="sc_item_title_text">{title}</span>
										</h2>
										<div className="sc_item_descr sc_title_descr">
											<p>
												{body}
											</p>
										</div>
										<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
											{
												link &&
												<a href={link} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
													<span className="sc_button_text"><span className="sc_button_title">{linkText}</span></span>
												</a>
											}
										</div>
									</div>
								</div>
							</Col>
							{
								imagePosition === 2 &&
								<Col md={6}>
									<div className="bg-image overlay bg-cover" style={{ backgroundImage: `url(${image})` }}></div>
								</Col>
							}
						</Row>
					</section>
				}
				{
					layout === 4 &&
					<div className="pagemaker-content--4">
						{ReactHtmlParser(body)}
					</div>
				}
				{
					layout === 5 &&
					<>
						<img src={image} />
					</>
				}
				{
					layout === 6 &&
					<section className="pagemaker-content--6">
						<ReactPlayer className="react-player" url={videoUrl} controls />
					</section>
				}
				{
					layout === 7 &&
					<section className="pagemaker-content-7">
						<div className="spacer"></div>
					</section>
				}
			</>
		);
	}

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, link, linkText, image, placeholder, placeholderId, bgColor, videoUrl, layout, imagePosition } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	const handleRemove = () => (e: any) => {
		state.removeElement(id, dropzoneID, parentID);
	}

	const handleUpload = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleSelect = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handlePlaceholderUpload = () => async (id: number) => {
		setPlaceholderId(id);
		setPlaceholder(`${AppConfig.baseUrl}image/${id}`);
	};

	const handlePlaceholderSelect = () => async (id: number) => {
		setPlaceholderId(id);
		setPlaceholder(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleReset = () => () => {
		setImageId(null);
	};

	const handleLayoutConfirm = (layout: number) => (e: any) => {
		setlayout(layout);
		setSetupMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { layout } });
		setEditMode(true);
	};

	return (
		<>
			<Draggable {...props} >
				<Card className="clickable" onClick={() => setEditMode(true)}>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<span className="u-text-small">{getLayoutName()}</span>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<EditorElement title={"Title"} include={[1, 2, 3]} layout={layout}>
						<Input value={title} placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Sub Title"} include={[1, 2, 3]} layout={layout}>
						<Input value={subTitle} placeholder="Enter sub title" onChange={e => setSubTitle(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Info"} include={[1, 2, 3]} layout={layout}>
						<Input value={info} placeholder="Enter info" onChange={e => setInfo(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Body"} include={[1, 4]} layout={layout}>
						<VisualEditor
							menubar={false}
							height={200}
							content={body}
							onChange={e => setBody(e)}
						/>
					</EditorElement>
					<EditorElement title={"Body"} include={[2, 3]} layout={layout}>
						<Input value={body} placeholder="Enter body text" onChange={e => setBody(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Link"} include={[1, 3]} layout={layout}>
						<Input value={link} placeholder="Enter link url" onChange={e => setLink(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Link Text"} include={[1, 3]} layout={layout}>
						<Input value={linkText} placeholder="Enter link text" onChange={e => setLinkText(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Image"} include={[3, 5]} layout={layout}>
						{
							!imageId && <Input value={image} placeholder="Enter image url" onChange={e => setImage(e.target.value)} />
						}
						<Row className="mt-3">
							<Col>
								<ImageUpload
									reset={true}
									imageId={imageId}
									onSelect={handleSelect()}
									onReset={handleReset()}
									onCancel={() => { }}
									onUpload={handleUpload()}
									aspectRatio={16 / 9} />
							</Col>
						</Row>
					</EditorElement>
					<EditorElement title={"Image Position"} include={[3]} layout={layout}>
						<Select
							options={Options.imagePositions}
							value={Options.imagePositions.find(p => p.value === imagePosition)}
							onChange={e => setImagePosition(e ? e.value : e)}
						/>
					</EditorElement>
					<EditorElement include={[6]} layout={layout} title={"Video Url"}>
						<Input value={videoUrl} placeholder="Enter url e.g. https://www.youtube.com/watch?v=HJAsHR19jDs" onChange={e => setVideoUrl(e.target.value)} />
					</EditorElement>
					<EditorElement include={[6]} layout={layout} title={"Placeholder Image"}>
						{
							!placeholderId && <Input value={placeholder} placeholder="Enter image url" onChange={e => setPlaceholder(e.target.value)} />
						}
						<Row className="mt-3">
							<Col>
								<ImageUpload
									reset={true}
									imageId={placeholderId}
									onSelect={handlePlaceholderSelect()}
									onReset={() => setPlaceholderId(null)}
									onCancel={() => { }}
									onUpload={handlePlaceholderUpload()}
									aspectRatio={16 / 9} />
							</Col>
						</Row>
					</EditorElement>
					<EditorElement include={[6]} layout={layout} title="Background Color">
						<TwitterPicker
							width="100%"
							color={bgColor}
							onChange={e => setBgColor(e.hex)}
							colors={["#8E1730", "#151515", "#1A213B", "#9197AE", "#058ED9", "#20BF55"]} />
					</EditorElement>
					<Row>
						<Col md={6}>
							<a tabIndex={0} className="btn btn-primary btn-outline btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col md={6}>
							<a tabIndex={0} className="btn btn-secondary btn-outline btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
						{/* <Col md={{size: 6, offset: 3}} className="text-center">
							<a tabIndex={0} className="btn text-danger mt-2" onClick={handleRemove()}>Remove</a>
						</Col> */}
					</Row>
				</ModalBody>
			</Modal>
			<Modal className={"element-editor element-editor--setup"} isOpen={setupMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Row>
							<Col md={4}>
								<Card onClick={handleLayoutConfirm(1)}>
									<CardBody>
										<p className="section-title mt-0">Title and Text</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={4}>
								<Card onClick={handleLayoutConfirm(2)}>
									<CardBody>
										<p className="section-title mt-0">Heading</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={4}>
								<Card onClick={handleLayoutConfirm(3)}>
									<CardBody>
										<p className="section-title mt-0">Image and Text</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={4}>
								<Card onClick={handleLayoutConfirm(4)}>
									<CardBody>
										<p className="section-title mt-0">Editor</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={4}>
								<Card onClick={handleLayoutConfirm(5)}>
									<CardBody>
										<p className="section-title mt-0">Image</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={4}>
								<Card onClick={handleLayoutConfirm(6)}>
									<CardBody>
										<p className="section-title mt-0">Video</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</FormGroup>
				</ModalBody>
			</Modal>
		</>
	);
};

const EditorElement = (props) => {
	return <>
		{props.include.includes(props.layout) ?
			<FormGroup>
				<Label>{props.title}</Label>
				{props.children}
			</FormGroup> :
			<></>
		}
	</>
}

export default DraggableContent;
