import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import * as moment from "moment";
import * as NProgress from "nprogress";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { ManageLayout } from "@components/layouts/ManageLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, Card, CardBody, Row, Col } from "reactstrap";

import { toast } from "react-toastify";
import { Toggle } from "@components/controls/Toggle";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import History from "@helpers/history.helper";
import { Member } from "@models/member";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { MemberService } from "@services/member.service";

import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { LeagueService } from "@services/league.service";
import { CompetitionService } from "@services/competition.service";

import { VenueService } from "@services/venue.service";
import { Competition, CompetitionGrouping, CreateCompetition } from "@models/competition";

import Sidebar from "react-sidebar";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { SelectOption } from "@models/forms";
import { Venue } from "@models/venue";

import { confirmAlert } from "react-confirm-alert"; // Import
import { HelpPopover } from "@components/controls/Popover";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { AppCache } from "@cache";
import { Can } from "@components/controls/Authentication";
import { LoginService } from "@services/login.service";
import { Options } from "@data";
import { start } from "repl";
import { throws } from "assert";
import ObjectHelper from "@helpers/object.helper";
import { Registration, RegistrationStatus } from "@models/registration";
import { PagedList } from "@models/paging";
import { RegistrationFilterOptions, FilterOptions } from "@models/filters/filterOptions";
import CreateRegistrationSidebar from "@components/sidebar/CreateRegistrationSidebar";
import { RegistrationService } from "@services/registration.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import PublicLayout from "@components/layouts/PublicLayout";
import { WebsiteService } from "@services/website.service";
import { WebsiteCustomMenuItem, WebsiteMenu, WebsiteMenuPreset } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { DateHelper } from "@helpers/date.helper";



interface IRegistrationListProps {
}

export default (props: IRegistrationListProps) => {
	const [loading, setLoading] = useState<boolean>(true);

	const [registrations, setRegistrations] = useState<PagedList<Registration>>();
	const [options, setOptions] = useState<RegistrationFilterOptions>();

	const [showCreate, setShowCreate] = useState<boolean>(false);

	const [websiteMenu, setWebsiteMenu] = useState<WebsiteCustomMenuItem>();

	useEffect(() => {
		load();
	}, [, options]);

	const load = async () => {
		const registrations = await RegistrationService.get({ published: true, active: true });
		setRegistrations(registrations);

		const websiteMenu = await WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Registrations);
		setWebsiteMenu(websiteMenu);

		setLoading(false);
	}

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																		<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(websiteMenu?.title, "Registrations")}</h1>
																		<p className="header--description">{StringHelper.ifEmpty(websiteMenu?.subTitle, "Available registrations")}</p>
																	</div>
																	</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(websiteMenu?.title, "Registrations")}</h1>
												<p className="header--description">{StringHelper.ifEmpty(websiteMenu?.subTitle, "Available registrations")}</p>
											</div>
										</div>
									</div>
								</div>
						}
						<div className="page_content_wrap">
							<div className="container">
								<div className="pre-content">
									{
										websiteMenu?.body &&
										<Row>
											<Col>
												{ReactHtmlParser(websiteMenu.body)}
											</Col>
										</Row>
									}
								</div>
								<div className="content">
									<div className="memberships-list mg-t-20">
										{
											registrations.items.map(e => (
												<Card className="mb-4">
													<CardBody>
														<Row>
															<Col md={4} className="my-auto">
																<h3 className="mb-0">{e.name}</h3>
																{e.status == RegistrationStatus.LateEntry ? 
																	<p className="mb-0">Late Entry - Closes {moment(e.lateEntryEndDate).format("DD/MM/YYYY")}</p>
																:
																	<p className="mb-0">Closes {moment(e.endDate).format("DD/MM/YYYY")}</p>
																}
																<p className="mb-0">{e.summary}</p>
															</Col>
															<Col md={4} className="my-auto">

															</Col>
															<Col md={2} className={`p-0 my-auto text-center event-price public-price`}>

															</Col>
															<Col md={2}>
																<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																	<Link to={`/registrations/${e.id}/join`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																		<span className="sc_button_text">
																			<span className="sc_button_title">Register</span>
																		</span>
																	</Link>
																</div>
															</Col>
														</Row>
													</CardBody>
												</Card>
											))
										}
									</div>
								</div>
							</div>
						</div>
					</div>)}
			/>
		</PublicLayout>
	);
}