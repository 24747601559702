import * as React from "react";
import { useState } from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Form, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, CardHeader, FormGroup, Input, Label } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from "react-html-parser";
import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership, MembershipPayment } from "@models/membership";
import { PagedList } from "@models/paging";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ProgressButton } from "@components/controls/ProgressButton";
import { User } from "@models/user";
import { MemberService } from "@services/member.service";
import { StripeService } from "@services/stripe.service";
import { ConsentAssociations, CreateConsentAccept } from "@models/consent";
import { ContactService } from "@services/contact.service";
import { Contact } from "@models/contact";
import { Constants } from "@consts";
import LocaleHelper from "@helpers/locale.helper";


interface IMembershipViewProps {
	site?: string;
	match?: any;
}

interface IMembershipViewState {
	loading: boolean;
	membership?: Membership;
	user?: User;
	submitting: boolean;
	contact?: Contact;
}
export class MembershipViewPage extends React.Component<IMembershipViewProps, IMembershipViewState> {

	private membershipId?: number;

	constructor(props) {
		super(props);

		this.state = { loading: true, submitting: false };

		this.membershipId = this.props.match.params.membershipId as number;
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	async load() {

		const details = LoginService.getDetails();
		const membership = await MembershipService.getById(this.membershipId);

		if (LoginService.isAuthenticated) {
			await ContactService.getByUser().then(contact => {
				this.setState({ contact });
			});
		}

		this.setState({ membership, loading: false });
	}

	public render() {
		return (
			<PublicLayout className="sub-page" theme="scheme_default">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="membership-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
											style={this.state.membership.headerId && { backgroundImage: `url('${AppConfig.baseUrl}image/${this.state.membership.headerId}')` }}>
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{this.state.membership.name}</h1>
																			<p className="header--description">{this.state.membership.summary}</p>
																			<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button d-block mb-2">
																				<Link to={`/memberships/${this.state.membership.id}/join`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																					<span className="sc_button_text">
																						<span className="sc_button_title">Join</span>
																					</span>
																				</Link>
																			</div>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{this.state.membership.name}</h1>
													<p className="header--description">{this.state.membership.summary}</p>
													<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button d-block mb-2">
														<Link to={`/memberships/${this.state.membership.id}/join`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
															<span className="sc_button_text">
																<span className="sc_button_title">Join</span>
															</span>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="membership mg-t-20">
										<Row>
											<Col md={8}>
												{
													!AppCache.website.config.subHeader && this.state.membership.headerId &&
													<div className='header_content_wrap header_align_mc'>
														<div className='post_featured'>
															<img
																width='1920'
																height='1080'
																src={`${AppConfig.baseUrl}image/${this.state.membership.headerId}`}
																className='attachment-fcunited-thumb-full size-fcunited-thumb-full wp-post-image'
																alt=''
															/>
														</div>
													</div>
												}
												<div className="content page-content">
													{ReactHtmlParser(this.state.membership.body)}
												</div>
											</Col>
											<Col md={4}>
												<div className="sidebar">
													<div className="sidebar_inner">
														<aside className="widget widget_text mb-5">
															<h5 className="widget_title">Price</h5>
															<div>
																<Row>
																	<Col className="text-center">
																		{this.state.membership.cost === 0 && <h3 className="membership-price m-0">FREE</h3>}
																		{this.state.membership.cost > 0 && <h3 className="membership-price m-0">{LocaleHelper.toPrice(this.state.membership.cost)}</h3>}
																		<span>{this.state.membership.payment === MembershipPayment.Month && "Monthly"}</span>
																		<span>{this.state.membership.payment === MembershipPayment.OneOff && "Upfront"}</span>
																	</Col>
																	{
																		this.state.membership.upfrontCost &&
																		<Col className="text-center">
																			{
																				this.state.membership.upfrontCost &&
																				<>
																					<h3 className="membership-price m-0">{LocaleHelper.toPrice(this.state.membership.upfrontCost)}</h3>
																					<span>Upfront</span>
																				</>
																			}
																		</Col>
																	}
																</Row>
															</div>
														</aside>
														{
															this.state.membership.eligibility &&
															<aside className="widget widget_text">
																<h5 className="widget_title">Eligibility</h5>
																<div>
																	<p>{this.state.membership.eligibility}</p>
																</div>
															</aside>
														}
													</div>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
