import { Entrant, EntrantType } from "./entrant";
import { Team, TeamMember } from "./team";
import { Venue } from "./venue";
import { MemberIdentity } from "./member";
import { match } from "react-router";
import { CompetitionDay } from "./competition";
import { Stage } from "./stage";
import { MatchAction } from "./action";

export class Match {
	public id: number;
	public date: Date;
	public time: Date;
	public home: MatchEntrant;
	public away: MatchEntrant;
	public venue: string;
	public venueId: number;
	public competitionId: number;
	public competition: string;
	public divisionId: number;
	public division: string;
	public name: string;
	public submitted?: Date;
	public approved?: Date;
	public rejected?: Date;
	public overridden?: Date;
	public approvedBy?: MemberIdentity;
	public submittedBy?: MemberIdentity;
	public rejectedBy?: MemberIdentity;
	public overriddenBy?: MemberIdentity;
	public forfeit?: Date;
	public forfeitId?: number;
	public abandoned?: number;
	public abandonedReason: string;
	public updated?: Date;
	public manual: boolean;
	public potm?: MemberIdentity;
	public potmType?: PotmType;
	public entry: MatchCardEntryLevel;
	public hasReport: boolean;
	public hasComments: boolean;
	public hasResults: boolean;

	public homeScores: MatchPlayerResults[];
	public awayScores: MatchPlayerResults[];
	
	public actions: MatchAction[];

	public week?: number;
	public dayId?: number;
	public round?: number;
	public tableNo?: number;

	//Knockout

	public homeId?: number;
	public awayId?: number;
	public homeName?: string;
	public awayName?: string;
	public stage: Stage;
	public roundModel: Round;

	public homeHandicap?: number;
	public awayHandicap?: number;
}

export class CreateMatch {
	public homeId: number;
	public awayId: number;
	public divisionId: number;
	public date?: Date;
	public time?: Date;
	public round?: number;
    public dayId?: number;
}

export class UpdateMatch {
	public id: number;
	public venueId?: number;
	public date?: Date;
	public time?: Date;
	public tableNo?: number;
	public round?: number;
}

export class MatchLatest {

}

export class MatchGrouping {
	public groups: MatchGroup[] = [];
	public matches: Match[] = [];
	public type: MatchGroupType;
}

export class MatchEntrant {
	public id: number;
	public teamId: number;
	public userId: string;
	public name: string;
	public shortName: string;
	public members: MatchMember[] = [];
	public reserves: MatchMember[] = [];
	public score?: number;
	public sets?: number;
	public points?: number;
	public ordering: number;
	public type: EntrantType;
	public captain: string;
	// temporary
	public team: Team;

	public displayName: string;
}

export class MatchMember {
	public memberId: string;
	public name: string;
	public email: string;
	public teamId: number;
	public team: string;
	public ordering: number;
	public playing: boolean;
	public totalStepUp: number;
	public disabled: boolean;
	public transferId?: number;
}

export class MatchGroup {
	public week?: number;
	public date?: Date;
	public range: MatchGroupRange;
	public type: MatchGroupType;
	public matches: Match[];
	public title: string;
}

export class MatchSet {
	public id: number;
	public players: MatchPlayer[] = [];
	public homePlayers: MatchPlayer[] = [];
	public awayPlayers: MatchPlayer[] = [];
	public homeScore?: number;
	public awayScore?: number;
	public fixed: boolean;
	public ordering: number;
	public games: MatchGame[] = [];
	public completed?: Date;
	public locked: boolean;
	public homeTimeout: boolean;
	public awayTimeout: boolean;
}

export class MatchDoubles {
	public id: number;
	public players: MatchPlayer[] = [];
	public fixed: boolean;
	public ordering: number;
	public home: MatchMember[];
	public away: MatchMember[];
}

export class MatchPlayer {
	public id: number;
	public entrantId: number;
	public userId: string;
	public playerId: number;
	public ordering: number;
	public name: string;
	public printoutName: string;
	public fixed: boolean;
	public forfeit?: Date;
	public forfeitReason: string;
	public type: number;
	public scratch?: Date;
	public scratchType?: number;
	public scratchReason: string;
	public membershipNo?: number;
}

export class MatchPlayerCollection
{
	public home: MatchPlayer[] = [];
	public away: MatchPlayer[] = [];
}

export class MatchCard {
	public match: Match;
	public sets: MatchSet[] = [];
	public players: MatchPlayer[] = [];
	public playing: MatchPlayerCollection;
	public doubles: MatchDoubles[] = [];
}

export class MatchReport {
	public matchId: number;
	public body: string;
	public updated: Date;
	public hidden?: Date;
	public createdBy: MemberIdentity;
}

export class MatchGame {
	public id: number;
	public home: number;
	public away: number;
	public ordering: number;
	public updated: Date;
	public hidden: boolean;
	public failed: boolean;
	public errored: boolean;
	public initialServer?: MatchPlayer;
	public secondaryIntitalServer?: MatchPlayer;
	public currentServer?: MatchPlayer;
	public secondaryServer?: MatchPlayer;
}

export class MatchGameExpedite {
	public home: number;
	public away: number;
	public initialServer?: MatchPlayer;
	public secondaryIntitalServer?: MatchPlayer;
}

export class CurrentMatchStatus {
	public id: number;
	public started: boolean;
	public gameEnd: boolean;
	public matchEnd: boolean;
	public gameId: number;
	public timeout: boolean;
	public timeoutHome: boolean;
	public hadHomeTimeout: boolean;
	public hadAwayTimeout: boolean;
}

export class MatchFilters {
	public type?: MatchGroupType;
	public clubId?: number;
	public venueId?: number;
	public teamId?: number;
	public divisionId?: number;
	public approved?: boolean;
	public conditions?: number;
}

export class MatchResults {
	public home: MatchPlayerResults[];
	public away: MatchPlayerResults[];
}

export class MatchPlayerResults {
	public userId: string;
	public name: string;
	public score: number;
}

export class TemplateAccess{
	public matchId: number;
	public divisionId: number;
	public competitionId: number;
}

export class MatchSchedule{
	public date?: Date;

	public time?: Date;

	public day: CompetitionDay;
	
	public round: number; 

	public tableNo: number;
}

export enum MatchGroupRange {
	Weekly = 1,
	Daily = 2,
	Monthly = 3,
	Rounds = 4
}

export enum MatchGroupType {
	All = 1,
	Results = 2,
	Upcoming = 3,
	Skipped = 4,
	Current = 6
}

export enum MatchListType {

}

export enum MatchStatus {
	Updated = 1,
	Submitted = 2,
	Rejected = 3,
	Approved = 4,
	Reset = 5,
	Override = 6,
	Veto = 7,
	Dispute = 8
}


export enum PotmType {
	Calculated = 1,
	Referee = 2,
	Peer = 3,
	Captain = 4,
	Manual = 5
}

export enum MatchCardEntryType {
	Disabled = 0,
	TeamCaptains = 1,
	TeamMembers = 2,
	LeagueMembers = 3,
	OpposingCaptain = 4,
	OpposingMembers = 5,
}

export enum MatchCardEntryLevel {
	None = 0,
	Submit = 1,
	Approve = 2,
	Full = 3,
	Report = 4,
}

export class Round
{
	public id: number;

	public stageId: number;

	public stage: Stage; 

	public name: string;

	public matches: Match[]

	public ordering: number; 

	public parentId?: number;

	public parent: Round;

	public type: RoundType

	public entrants: number;

	public progress?: ProgressType

	public startDate?: Date;

	public endDate?: Date;
}

export enum RoundType
{
	Default = 1,
	PlayOff = 2,
	QuaterFinal = 3,
	ThirdPlace = 4,
	SemiFinal = 5,
	Final = 6
}

export enum ProgressType
{
	Winners = 1,
	Losers = 2,
	Manual = 3
}
