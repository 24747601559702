import { Entry, EntryPlayer, EntryTeam, RegisterEntryPlayer } from "@models/entry";
import { SelectOption } from "@models/forms";
import { RegistrationCategory } from "@models/registration";
import { EntryService } from "@services/entry.service";
import { MemberService } from "@services/member.service";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, CardBody } from "reactstrap";
import AsyncSelect from 'react-select/async';
import { ProgressButton } from "@components/controls/ProgressButton";

interface IRegisterPlayerSidebar {
	entry?: Entry;
	category: RegistrationCategory;
	team?: EntryTeam;
	player?: EntryPlayer;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IRegisterPlayerSidebar) => {
	const [team, setTeam] = useState<EntryTeam>(props.team);
	const [players, setPlayers] = useState<RegisterEntryPlayer[]>([]);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleClose = () => (e: any) => {
		if(!submitting)
			props.onClose();
	};

	const loadMemberOptions = () => (value: string): Promise<SelectOption<string>[]> => {
		return new Promise(resolve => {
			MemberService.search({
				search: value,
				email: false,
				player: false,
				registration: true,
				categoryId: props.category.id,
				clubId: props.entry.clubId
			}).then(members => {
				const options = members.filter(m => team?.players == null || team?.players.length == 0 || team?.players.every(tm => tm.displayName != m.name)).map(m => ({ label: m.name, value: m.id, disabled: m.disabled }));
				resolve(options);
			});
		});
	}

	const handleConfirm = () => async (e: any) => {
		setSubmitting(true);
		const entryPlayers = players;

		for (let i = 0; i < entryPlayers.length; i++) {
			const entryPlayer = entryPlayers[i];

			await EntryService.assignPlayer(entryPlayer);
		}
		setSubmitting(false);
		props.onConfirm();
		props.onClose();
	};

	const handleChangePlayer = () => (e: any) => {
		const players = [];
		let options = [];

		if (props.player) {
			options.push(e);
		} else {
			options = e;
		}

		options.forEach(p => {
			const entryPlayer = new RegisterEntryPlayer();
			entryPlayer.id = props.player?.id;
			entryPlayer.memberId = p.value;
			entryPlayer.displayName = p.label;
			entryPlayer.categoryId = props.category.id;
			if (team)
				entryPlayer.teamId = team.id;
			else {
				entryPlayer.entryId = props.entry.id;
			}
			players.push(entryPlayer);
		});

		setPlayers(players);
	};

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="help-container">
					<div className="sidebar--close">
						<button className="btn p-0 tx-20" onClick={props.onClose}>
							<i className="far fa-times"></i>
						</button>
					</div>
				</div>
				<h5 className="slim-pagetitle">{props.player != null ? "Swap" : "Register"} Player</h5>
				{props.player != null && <h5 className="slim-breadcrumb mt-3">{props.player.displayName}</h5>}
			</div>
			<div className="sidebar--content mt-2">
				<label className="form-control-label">Player <span className="tx-danger">*</span></label>
				<AsyncSelect
					value={players.map(p => ({ label: p.displayName, value: p.memberId }))}
					loadOptions={loadMemberOptions()}
					isOptionDisabled={option => { return option.disabled; }}
					closeMenuOnSelect={true}
					isMulti={props.player == null}
					onChange={handleChangePlayer()} />
			</div>
			<div className="sidebar--footer">
				<Row className="mt-4">
					<Col>
						<ProgressButton className="btn btn-primary btn-rounded btn-outline bd-0 btn-block" loading={submitting} onClick={handleConfirm()}>Confirm</ProgressButton>
					</Col>
					<Col>
						<button className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block" type="button" onClick={handleClose()}>Cancel</button>
					</Col>
				</Row>
			</div>
		</div>
	);
};