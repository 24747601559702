import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club, ClubMember } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { VenueService } from "@services/venue.service";
import { Venue } from "@models/venue";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
export const googleApiKey = AppConfig.googleApi;

import { Row, Col, Card } from "reactstrap";

interface IClubViewPageProps {
	site?: string;
	match?: any;
}

interface IClubViewPageState {
	loading: boolean;
	nextPathname?: any;
	club?: Club;
	members: ClubMember[];
	teams: Team[];
}

export class ClubViewPage extends React.Component<IClubViewPageProps, IClubViewPageState> {

	private clubId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			teams: [],
			members: []
		};
	}

	componentDidMount() {
		//
		this.clubId = this.props.match.params.clubId as number;
		this.load();
	}

	componentDidUpdate(props: IClubViewPageProps, state: IClubViewPageState) {
		this.clubId = this.props.match.params.clubId as number;

		if (props.match.params.clubId != this.clubId) {
			this.load();
		}
	}

	async load() {
		const club = await ClubService.getById(this.clubId);
		const teams = await ClubService.getTeams(this.clubId);
		const members = await ClubService.getMembers(this.clubId);

		this.setState({ club, teams, members, loading: false });
	}

	public render() {
		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" style={{ /* backgroundImage: `url('${AppConfig.baseUrl}image/${this.state.club.badgeId}')` */ }}>
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{this.state.club.name}</h1>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{this.state.club.name}</h1>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="row">
										<div className="col-lg col-md-12 mb-4">
											<div className="content">
												<h5>Teams</h5>
												<Row>
													{this.state.teams.map(item => {
														return (
															<Col md={6}>
																<Card className="mb-3" key={`team-${item.id}`}>
																	<Row className="m-0">
																		<h6 className="m-0">{item.name} </h6>
																		<Col>
																			<p className="m-0"> {item.competition}</p>
																		</Col>
																	</Row>
																	<p className="m-0">{item.captain ? item.captain.name : "Not set"}</p>
																</Card>
															</Col>
														)
													})}
												</Row>
												<h5>Members</h5>
												<Row>
													{this.state.members.map(item => {
														return (
															<Col md={6}>
																<Card className="mb-3" key={`member-${item.id}`}>
																	<h6 className="m-0">{item.displayName}</h6>
																</Card>
															</Col>
														)
													})}
												</Row>
											</div>
										</div>
										<div className="col-lg-4 col-md-12 right mb-4">
											<div className="sidebar widget_area sidebar_below">
												<div className="sidebar_inner">
													<div className="row">
														<div className="col-12 col-md-6 col-lg-12 mb-4">
															<aside className="widget widget_recent_posts">
																<h5 className="widget_title">Contacts</h5>
																{
																	this.state.club.email?.length > 0 && <div className="mb-3">
																		<h6 className="m-0">Club Email</h6>
																		<a href={`mailto:${this.state.club.email}`}>{this.state.club.email}</a>
																	</div>
																}
																{
																	this.state.club.contact && <div className="mb-3">
																		<h6 className="m-0">Primary Contact</h6>
																		<p>{this.state.club.contact.name}</p>
																		<a href={`mailto:${this.state.club.contact.email}`}>{this.state.club.contact.email}</a>
																	</div>
																}
																{
																	!(this.state.club.email?.length > 0) && this.state.members.filter(m => m.secretary).length > 0 && <div className="mb-3">
																		<h6 className="m-0">{this.state.members.filter(m => m.secretary).length > 1 ? "Secretaries" : "Secretary"}</h6>
																		{
																			this.state.members.filter(m => m.secretary).map(s => {
																				return (
																					<div>
																						<p className="m-0">{s.displayName}</p>
																						<a className="m-0" href={`mailto:${s.email}`}>{s.email}</a>
																					</div>
																				);
																			})
																		}
																	</div>
																}
															</aside>
														</div>
														<div className="col-12 col-md-6 col-lg-12 mb-4">
															<aside className="widget widget_recent_posts">
																<h5 className="widget_title">Social</h5>
																<a href={`http://${this.state.club.facebook}`} >{this.state.club.facebook}</a>
																<a href={`http://${this.state.club.twitter}`} >{this.state.club.twitter}</a>
																<a href={`http://${this.state.club.website}`} >{this.state.club.website}</a>
															</aside>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout>);
	}
}
