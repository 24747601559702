import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";
import { ContentService } from "@services/content.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team, TeamCaptain } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";
import { Content, ContentLayout } from "@models/content";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { Group, GroupMember } from "@models/group";
import { GroupService } from "@services/group.service";
import { Member } from "@models/member";
import { MemberService } from "@services/member.service";
import { TenantService } from "@services/tenant.service";
import { MatchService } from "@services/match.service";
import { MatchReport, Match } from "@models/match";


interface IMatchReportPageProps {
	site?: string;
	match?: any;
}

interface IMatchReportPageState {
	loading: boolean;
	nextPathname?: any;
	report?: MatchReport;
	match?: Match;
}

export class MatchReportPage extends React.Component<IMatchReportPageProps, IMatchReportPageState> {

	private divisionId: number;
	private competitionId: number;
	private matchId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	componentDidMount() {
		this.divisionId = this.props.match.params.divisionId as number;
		this.competitionId = this.props.match.params.competitionId as number;
		this.matchId = this.props.match.params.matchId as number;

		this.load();
	}

	componentDidUpdate(props: IMatchReportPageProps, state: IMatchReportPageState) {

	}

	load() {
		const p1 = MatchService.getReport(this.matchId).then(report => {
			this.setState({ report });
		});

		const p2 = MatchService.getMatch(this.matchId).then(match => {
			this.setState({ match });
		});

		Promise.all([p1, p2]).then(result => {
			this.setState({ loading: false });
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>

							<div className="header_content_wrap mt-5 print-none">
								<div className="container">
									<div className="row">
										<div className="col-12 text-center">
											<h1 className="sc_layouts_title_caption">Match Report</h1>
											<p className="header--description">{this.state.match.home.displayName} vs {this.state.match.away.displayName}</p>
											<Link className="btn btn-primary btn-rounded" to={`/league/${this.competitionId}/division/${this.divisionId}/match/${this.matchId}`}>Back to Match</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="page_content_wrap">
								<div className="container">

									<div className="row">
										<div className="col-lg col-md-12 mb-4">
											<div className="content">
												<article id="post-136" className="post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league">
													<div className="post_content post_content_single entry-content">
														<div>
															{ReactHtmlParser(this.state.report.body)}
														</div>
													</div>
												</article>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<span className="tx-bold">Last Updated</span> <span title={moment(this.state.report.updated).format("Do MMMM YYYY")}>{moment(this.state.report.updated).fromNow()}</span>
										</div>
										<div className="col-12">
											<span className="tx-bold">Published By</span> <span>{this.state.report.createdBy.name}</span>
										</div>
									</div>
								</div>
							</div>

						</div>
					)}
				/>
			</PublicLayout>);
	}
}
