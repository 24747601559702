import * as React from "react";
import * as ReactDOM from "react-dom";

import { Router, Route, Redirect } from "react-router";
import { Link, Switch } from "react-router-dom";

import { LoginService } from "../services/login.service";

import { HomePage } from "@pages/public/home/HomePage";

import { TablesPage } from "@pages/public/divisions/TablesPage";
import { TablesOverviewPage } from "@pages/public/competitions/TablesOverviewPage";
import { FixturesPage } from "@pages/public/competitions/FixturesPage";

import History from "@helpers/history.helper";

import { ArticleViewPage } from "../pages/public/articles/ArticleViewPage";
import { ArticleListPage } from "../pages/public/articles/ArticleListPage";

import { ContentViewPage } from "../pages/public/content/ContentViewPage";
import { AveragesPage } from "@pages/public/divisions/AveragesPage";
import { ChangeLogPage } from "@pages/public/home/ChangeLogPage";
import { MatchPage } from "@pages/public/divisions/MatchPage";
import { MatchGroupType } from "@models/match";
import { LoginPage } from "@pages/public/account/LoginPage";
import { VenueListPage } from "@pages/public/venues/VenueListPage";
import { VenueViewPage } from "@pages/public/venues/VenueViewPage";
import { PlayerPage } from "@pages/public/competitions/PlayerPage";
import { WelcomePage } from "@pages/public/home/WelcomePage";
import { MatchCardPage } from "@pages/public/divisions/MatchCardPage";
import { DivisionOverviewPage } from "@pages/public/divisions/DivisionOverviewPage";
import { TeamPage } from "@pages/public/competitions/TeamPage";
import { MatchReportPage } from "@pages/public/divisions/MatchReportPage";
import { ArchivesPage } from "@pages/public/competitions/ArchivesPage";
import { CompetitionListPage } from "@pages/public/competitions/CompetitionListPage";
import { ClubViewPage } from "@pages/public/clubs/ClubViewPage";
import { CompetitionOverviewPage } from "@pages/public/competitions/CompetitionOverviewPage";
import { PotmPage } from "@pages/public/divisions/PotmPage";
import { RankingsTablePage } from "@pages/public/rankings/RankingsTablePage";
import { PlayerRankingsPage } from "@pages/public/rankings/PlayerRankingsPage";
import { TableEmbedPage } from "@pages/public/embed/TableEmbedPage";
import { RankingsEmbedPage } from "@pages/public/embed/RankingsEmbedPage";
import { ReservesPage } from "@pages/public/competitions/ReservesPage";
import { ReservesListPage } from "@pages/public/competitions/ReservesListPage";
import { AveragesEmbedPage } from "@pages/public/embed/AveragesEmbedPage";
import { FixturesEmbedPage } from "@pages/public/embed/FixturesEmbedPage";
import { MembershipListPage } from "@pages/public/memberships/MembershipListPage";
import { MembershipJoinPage } from "@pages/public/memberships/MembershipJoinPage";
import { BookingSlotsPage } from "@pages/public/bookings/BookingSlotsPage";
import { PayPage } from "@pages/public/payment/PayPage";
import { GalleryViewPage } from "@pages/public/gallery/GalleryViewPage";
import { GalleryListPage } from "@pages/public/gallery/GalleryListPage";
import { SessionListPage } from "@pages/public/sessions/SessionListPage";
import { RegisterPage } from "@pages/public/account/RegisterPage";
import { SuccessPage } from "@pages/public/account/SuccessPage";
import { MembershipViewPage } from "@pages/public/memberships/MembershipViewPage";
import { EventListPage } from "@pages/public/events/EventListPage";
import { ConfirmationPage } from "@pages/public/shared/ConfirmationPage";
import { ProfilePage } from "@pages/public/member/profile/ProfilePage";
import { ResetPasswordPage } from "@pages/public/account/ResetPasswordPage";
import { ConfirmEmailPage } from "@pages/public/account/ConfirmEmailPage";
import { MatchCardTemplatePage } from "@pages/public/divisions/MatchCardTemplatePage";
import { ForbiddenPage } from "@pages/public/account/ForbiddenPage";
import { ConfirmGuardianPage } from "@pages/public/account/ConfirmGuardianPage";
import { ConfirmLinkPage } from "@pages/public/account/ConfirmLinkPage";
import { UnauthorizedPage } from "@pages/public/account/UnauthorizedPage";
import { PrivateRoute } from "./PrivateRoute";
import { ConfirmMemberPage } from "@pages/public/account/ConfirmMemberPage";
import { SearchPage } from "@pages/public/home/SearchPage";
import { TenantType } from "@models/tenant";

import RegistrationListPage from "@pages/public/registrations/RegistrationListPage";
import RegistrationJoinPage from "../pages/public/registrations/RegistrationJoinPage";

import EntryMemberPage from "@pages/public/member/entries/EntryMemberPage";
import EntryViewPage from "@pages/public/member/entries/EntryViewPage";
import EntryCategoriesPage from "@pages/public/member/entries/EntryCategoriesPage";
import EntryAgreementsPage from "@pages/public/member/entries/EntryAgreementsPage";
import EntryPaymentsPage from "@pages/public/member/entries/EntryPaymentsPage";
import EntryPayPage from "@pages/public/member/entries/EntryPayPage";
import { EntryConfirmationPage } from "@pages/public/member/entries/EntryConfirmationPage";
import EntryContactPage from "@pages/public/member/entries/EntryContactPage";
import { ClubManagePage } from "@pages/public/member/clubs/ClubManagePage";
import { ClubListPage } from "@pages/public/member/clubs/ClubListPage";
import { ConfirmPage } from "@pages/public/member/profile/ConfirmPage";

import CompetitionMemberPage from "@pages/public/member/competitions/CompetitionMemberPage";
import CompetitionEditPage from "@pages/public/member/competitions/CompetitionEditPage";
import { SessionAttendeesPage } from "@pages/public/sessions/SessionAttendeesPage";
import { BookingSlotAttendeesPage } from "@pages/public/bookings/BookingSlotAttendeesPage";
import BasketPage from "@pages/public/basket/BasketPage";
import PublicLayout from "@components/layouts/PublicLayout";
import EventJoinPage from "@pages/public/events/EventJoinPage";
import { TeamCheckerListPage } from "@pages/public/competitions/TeamCheckerListPage";
import { TeamCheckerPage } from "@pages/public/competitions/TeamCheckerPage";
import { NotFoundPage } from "@pages/public/home/NotFound";
import RankingsCategoriesListPage from "@pages/public/national/RankingsCategoriesListPage";
import RankingsListPage from "@pages/public/national/RankingsListPage";
import RankingsIndividualPage from "@pages/public/national/RankingsIndividualPage";
import { MatchCardScoreBoard } from "@pages/public/divisions/MatchCardScoreBoard";
import SessionJoinPage from "@pages/public/sessions/SessionJoinPage";
import { AveragesOverviewPage } from "@pages/public/competitions/AveragesOverviewPage";

export class Routes extends React.Component<{}, {}> {

	public render() {
		return (
			<Router history={History}>
				<Switch>
					<Route exact path="/" component={HomePage} />

					<Route exact path="/login" component={LoginPage} />
					<Route exact path="/forbidden" component={ForbiddenPage} />
					<Route exact path="/unauthorised" component={UnauthorizedPage} />
					<Route exact path="/register" component={RegisterPage} />
					<Route exact path="/forgot" component={ResetPasswordPage} />
					<Route exact path="/success" component={SuccessPage} />

					<Route exact path="/changelog" component={ChangeLogPage} />

					<Route exact path="/leagues" component={CompetitionListPage} />
					<Route exact path="/leagues/fastformat" render={(props) => <CompetitionListPage {...props} fastFormat={true} />} />

					<Route exact path="/league/archives" component={ArchivesPage} />

					<Route exact path="/member/leagues" component={CompetitionMemberPage} />
					<Route exact path="/member/leagues/:competitionId" component={CompetitionEditPage} />

					<Route exact path="/league/:competitionId" component={CompetitionOverviewPage} />

					<Route exact path="/league/:competitionId/player/:userId" component={PlayerPage} />
					<Route exact path="/league/:competitionId/team/:teamId" component={TeamPage} />

					<Route exact path="/league/:competitionId/fixtures" render={(props) => <FixturesPage {...props} type={MatchGroupType.Upcoming} />} />
					<Route exact path="/league/:competitionId/results" render={(props) => <FixturesPage {...props} type={MatchGroupType.Results} />} />
					<Route exact path="/league/:competitionId/fixtures-results" render={(props) => <FixturesPage {...props} type={MatchGroupType.All} />} />

					<Route exact path="/reserves" component={ReservesListPage} />
					<Route exact path="/league/:competitionId/reserves" component={ReservesPage} />

					<Route exact path="/checker" component={TeamCheckerListPage} />
					<Route exact path="/league/:competitionId/checker" component={TeamCheckerPage} />

					<Route exact path="/league/:competitionId/tables" component={TablesOverviewPage} />
					<Route exact path="/league/:competitionId/averages" component={AveragesOverviewPage} />

					<Route exact path="/league/:competitionId/fixtures" render={(props) => <FixturesPage {...props} type={MatchGroupType.Upcoming} />} />
					<Route exact path="/league/:competitionId/results" render={(props) => <FixturesPage {...props} type={MatchGroupType.Results} />} />
					<Route exact path="/league/:competitionId/fixtures-results" render={(props) => <FixturesPage {...props} type={MatchGroupType.All} />} />

					<Route exact path="/league/:competitionId/division/:divisionId" component={DivisionOverviewPage} />
					<Route exact path="/league/:competitionId/division/:divisionId/fixtures" render={(props) => <FixturesPage {...props} type={MatchGroupType.Upcoming} />} />
					<Route exact path="/league/:competitionId/division/:divisionId/results" render={(props) => <FixturesPage {...props} type={MatchGroupType.Results} />} />
					<Route exact path="/league/:competitionId/division/:divisionId/fixtures-results" render={(props) => <FixturesPage {...props} type={MatchGroupType.All} />} />
					<Route exact path="/league/:competitionId/division/:divisionId/match/:matchId/card" component={MatchCardPage} />

					<Route exact path="/league/:competitionId/division/:divisionId/match/:matchId/card/schedule" render={(props) => <MatchCardPage {...props} mode={1} />} />
					<Route exact path="/league/:competitionId/division/:divisionId/match/:matchId/card/play" render={(props) => <MatchCardPage {...props} mode={2} />} />

					<Route exact path="/league/:competitionId/division/:divisionId/match/:matchId/card/template" component={MatchCardTemplatePage} />
					<Route exact path="/league/:competitionId/division/:divisionId/table" component={TablesPage} />
					<Route exact path="/league/:competitionId/division/:divisionId/match/:matchId" component={MatchPage} />
					<Route exact path="/league/:competitionId/division/:divisionId/match/:matchId/report" component={MatchReportPage} />
					<Route exact path="/league/:competitionId/division/:divisionId/averages" component={AveragesPage} />
					<Route exact path="/league/:competitionId/division/:divisionId/potm" component={PotmPage} />
					<Route exact path="/league/:competitionId/division/:divisionId/scoreboard" component={MatchCardScoreBoard} />

					<Route exact path="/rankings" component={RankingsTablePage} />
					<Route exact path="/rankings/:userId" component={PlayerRankingsPage} />

					<Route exact path="/rankings/national/categories" component={RankingsCategoriesListPage} />
					<Route exact path="/rankings/national/category/:categoryId/:date" component={RankingsListPage} />
					<Route exact path="/rankings/national/category/:categoryId" component={RankingsListPage} />
					<Route exact path="/rankings/national/category/:categoryId/:memberId" component={RankingsIndividualPage} />
					<Route exact path="/rankings/national/category/:categoryId/:memberId/:eventId" component={RankingsIndividualPage} />

					<Route exact path="/fixtures" render={(props) => <FixturesPage {...props} type={MatchGroupType.Upcoming} />} />
					<Route exact path="/results" render={(props) => <FixturesPage {...props} type={MatchGroupType.Results} />} />

					<Route exact path="/article/:slug" component={ArticleViewPage} />
					<Route exact path="/articles" component={ArticleListPage} />
					<Route exact path="/articles/:page" component={ArticleListPage} />

					<Route exact path="/gallery" component={GalleryListPage} />
					<Route exact path="/gallery/:galleryId" component={GalleryViewPage} />

					<Route exact path="/bookings" component={BookingSlotsPage} />
					<Route exact path="/bookings/:spaceId" component={BookingSlotsPage} />
					<Route exact path="/bookings/confirmation/:reference" component={ConfirmationPage} />
					<Route exact path="/bookings/space/:spaceId/slot/:slotId/attendees" component={BookingSlotAttendeesPage} />

					<Route exact path="/memberships" component={MembershipListPage} />
					<Route exact path="/memberships/:membershipId" component={MembershipViewPage} />
					<Route exact path="/memberships/:membershipId/join" component={MembershipJoinPage} />
					<Route exact path="/memberships/confirmation/:reference" component={ConfirmationPage} />

					<Route exact path="/registrations" component={RegistrationListPage} />
					<Route exact path="/registrations/:registrationId/join" component={RegistrationJoinPage} />

					<PrivateRoute exact path="/member/entries" component={EntryMemberPage} />
					<PrivateRoute exact path="/member/entries/:entryId" component={EntryViewPage} />
					<PrivateRoute exact path="/member/entries/:entryId/categories" component={EntryCategoriesPage} />
					<PrivateRoute exact path="/member/entries/:entryId/categories/:categoryId" component={EntryCategoriesPage} />
					<PrivateRoute exact path="/member/entries/:entryId/contact" component={EntryContactPage} />
					<PrivateRoute exact path="/member/entries/:entryId/agreements" component={EntryAgreementsPage} />
					<PrivateRoute exact path="/member/entries/:entryId/payments" component={EntryPaymentsPage} />

					<PrivateRoute exact path="/member/entries/:entryId/pay/all" render={(props) => <EntryPayPage {...props} type={1} />} />
					<PrivateRoute exact path="/member/entries/:entryId/pay/confirmation/:reference" component={EntryConfirmationPage} />
					<PrivateRoute exact path="/member/entries/:entryId/pay/:categoryId" render={(props) => <EntryPayPage {...props} type={1} />} />
					<PrivateRoute exact path="/member/entries/:entryId/pay" render={(props) => <EntryPayPage {...props} type={1} />} />

					<PrivateRoute exact path="/member/entries/:entryId/invoice/all" render={(props) => <EntryPayPage {...props} type={2} />} />
					<PrivateRoute exact path="/member/entries/:entryId/invoice/:categoryId" render={(props) => <EntryPayPage {...props} type={2} />} />
					<PrivateRoute exact path="/member/entries/:entryId/invoice" render={(props) => <EntryPayPage {...props} type={2} />} />

					<PrivateRoute exact path="/member/profile/confirm" component={ConfirmPage} />
					<PrivateRoute exact path="/member/profile/:contactId" component={ProfilePage} />
					<PrivateRoute exact path="/member/profile/(tab)?/:tabId?" component={ProfilePage} />

					<Route exact path="/events" component={EventListPage} />
					<Route exact path="/events/:eventId/join" component={EventJoinPage} />
					<Route exact path="/events/:eventId/join/:sessionId" component={EventJoinPage} />
					<Route exact path="/events/confirmation/:reference" component={ConfirmationPage} />

					<Route exact path="/sessions" component={SessionListPage} />
					<Route exact path="/sessions/:sessionId/join" component={SessionJoinPage} />
					<Route exact path="/sessions/confirmation/:reference" component={ConfirmationPage} />
					<Route exact path="/sessions/:sessionId/attendees" component={SessionAttendeesPage} />


					<Route exact path="/pay/confirmation/:reference" component={ConfirmationPage} />
					<Route exact path="/pay/:reference" component={PayPage} />

					<Route exact path="/venue/:venueId" component={VenueViewPage} />
					<Route exact path="/venues" component={VenueListPage} />

					<Route exact path="/club/:clubId" component={ClubViewPage} />

					<Route exact path="/member/clubs" component={ClubListPage} />
					<Route exact path="/member/clubs/:clubId/manage" component={ClubManagePage} />

					<Route exact path="/page/:slug" component={ContentViewPage} />

					<Route path="/manage" component={() => { window.location.href = "/manage"; return null; }} />
					<Route path="/admin" component={() => { window.location.href = "/admin"; return null; }} />
					<Route path="/welcome" component={WelcomePage} />

					<Route path="/not-found" component={NotFoundPage} />

					<Route path="/search/all" component={SearchPage} />
					<Route path="/search/leagues" render={(props) => <SearchPage {...props} type={TenantType.League} />} />
					<Route path="/search/clubs" render={(props) => <SearchPage {...props} type={TenantType.Club} />} />
					<Route path="/search/counties" render={(props) => <SearchPage {...props} type={TenantType.County} />} />

					<Route path="/confirm/:code/email" component={ConfirmEmailPage} />
					<Route path="/confirm/:code/guardian" component={ConfirmGuardianPage} />
					<Route path="/confirm/:code/link" component={ConfirmLinkPage} />
					<Route path="/confirm/:code/member" component={ConfirmMemberPage} />

					<Route exact path="/embed/table" component={TableEmbedPage} />
					<Route exact path="/embed/averages" component={AveragesEmbedPage} />
					<Route exact path="/embed/rankings" component={RankingsEmbedPage} />
					<Route exact path="/embed/fixtures" component={FixturesEmbedPage} />

					<PublicLayout className="sub-page">
						<Route exact path="/basket" component={BasketPage} />
					</PublicLayout>

					<Route exact path="*">
						<Redirect to="/not-found" />
					</Route>
				</Switch>
			</Router>);
	}
}
