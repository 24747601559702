import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Club, ClubMember } from "@models/club";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Venue } from "@models/venue";
import { League } from "@models/league";

import RestHelper from "@helpers/rest.helper";
import { Team } from "@models/team";
import { PagedList } from "@models/paging";
import { ClubFilterOptions } from "@models/filters/filterOptions";

import * as qs from "qs";
import { Competition } from "@models/competition";

export class ClubService {

	constructor() {
		//
	}

	public static search(term:string = "", take: number): Promise<Club[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/search?term=${term}&take=${take}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Club[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static searchAll(term:string = "", take: number): Promise<Club[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/fromall/?term=${term}&take=${take}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Club[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}
	
	public static getAvailable(clubId: number, competitionId: number, term:string = "", take: number): Promise<ClubMember[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/club/${clubId}/available?search=${term}&take=${take}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:ClubMember[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}


	public static getById(id:number): Promise<Club> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/${id}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Club, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByIds(clubIds: number[]): Promise<Club[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/clubids`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(clubIds),
				success: (data: Club[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByMember(): Promise<Club[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `member/clubs`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Club[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByMemberRegistration(): Promise<Club[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `member/clubs/registration`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Club[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static data(any: boolean, query:DatatableQuery): Promise<DatatableResponse<Club>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({any, query}),
				success: (data:DatatableResponse<Club>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static dataMembers(clubId: number, query:DatatableQuery): Promise<DatatableResponse<ClubMember>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/data/members`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({clubId, query}),
				success: (data:DatatableResponse<ClubMember>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getTeams(clubId: number): Promise<Team[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Team[]>(`/clubs/${clubId}/teams`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getMembers(clubId: number): Promise<ClubMember[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/${clubId}/members`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:ClubMember[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getSecretaries(): Promise<ClubMember[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/secretaries`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:ClubMember[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static ByLeagues(legaueIds: number[]): Promise<Club[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`clubs/leagues`, legaueIds).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static assign(clubId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`clubs/${clubId}/assign`, {clubId, memberId}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static Unassign(clubId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`clubs/${clubId}/unassign`, {clubId, memberId}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static assignVenue(clubId: number, venueId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/${clubId}/assign/venue/${venueId}`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static switchSecretary(clubId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`/clubs/member/secretary`,{clubId, memberId}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static link(clubId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/${clubId}/link`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getVenues(id:number): Promise<Venue[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/${id}/venues`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Venue[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getLeagues(id:number): Promise<League[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `clubs/${id}/leagues`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:League[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static import(clubId: number = null): Promise<void> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + (clubId ? `clubs/${clubId}/import` : `clubs/import`),
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:void, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static get(options: ClubFilterOptions): Promise<PagedList<Club>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`clubs`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsert(club: Club): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`clubs`, club).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCompetitions(clubId: number): Promise<Competition[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`clubs/${clubId}/competitions`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCompetitionTeams(clubId: number, competitionId: number): Promise<Team[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`clubs/${clubId}/competitions/${competitionId}/teams`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
