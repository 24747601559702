import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { Club } from "@models/club";

interface IClubListProps {
	site?: string;

}

interface IClubListState {
	loading: boolean;
	clubs?: Club[];
	search?: string;
}
export class ClubListPage extends React.Component<IClubListProps, IClubListState> {

	constructor(props) {
		super(props);
		this.state = { loading: true, clubs: [] };
	}

	componentDidMount() {
		WebsiteService.updateActivity("Competitions");
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		const p1 =  ClubService.getByMemberRegistration().then(clubs => {
			this.setState({ clubs });
		});

		Promise.all([p1]).then(result => {
			this.setState({ loading: false });
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div>
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">Clubs</span></h2>
											<p className="text-center">A list of clubs available for management</p>
										</div>
										<div className="competition-list mg-t-20">
											<div className="row clearfix">
												<div className="col-lg-12">
													<div className="list-group list-group-default">
														<div className="row">
															{
																this.state.clubs.map(c => {
																	return (
																		<div className="col-4">
																			<div className="card p-3 mb-3">
																				<div className="row">
																					<div className="col-12">
																						<p className="m-0"><Link to={`/member/clubs/${c.id}/manage`}>{c.name} <span><i className="fal fa-cog"></i></span></Link></p>
																					</div>
																					{/* <div className="col-6 text-right">
																						
																					</div> */}
																				</div>
																			</div>
																		</div>
																	);
																})
															}
														</div>
													</div>
													{
														this.state.loading ? <ActivitySpinner /> : <Empty text="No clubs to manage" enabled={this.state.clubs.length === 0} />
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
