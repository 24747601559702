export class Average {
	public competitionId: number;
	public entrantId: number;
	public name: string;
	public teamFullName: string;
	public team: string;
	public teamId: number;
	public club: string;
	public clubId: number;
	public userId: string;
	public played: number;
	public won: number;
	public percentage: number;
	public updated: Date;

	public reserve: boolean;
}

export class DoublesAverage {
	public competitionId: number;
	public entrantId: number;
	public nameOne: string;
	public nameTwo: string;
	public teamFullName: string;
	public team: string;
	public teamId: number;
	public club: string;
	public clubId: number;
	public userOneId: string;
	public userTwoId: string;
	public played: number;
	public won: number;
	public percentage: number;
	public updated: Date;

	public reserveOne: boolean;
	public reserveTwo: boolean;
}

export class AverageGroup {
	public divisionId: number;
	public competitionId: number;
	public division: string;
	public competition: string;
	public averages: Average[];
}

export enum AverageSort {
	Name = 1,
	Team = 2,
	Club = 3
}
