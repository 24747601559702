import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

interface ICompetitionOverviewProps {
	site?: string;
	match?: any;
}

interface ICompetitionOverviewState {
	loading: boolean;
	competition?: Competition;
	search?: string;
}
export class CompetitionOverviewPage extends React.Component<ICompetitionOverviewProps, ICompetitionOverviewState> {

	private competitionId: number;

	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		this.competitionId = this.props.match.params.competitionId as number;

		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		this.competitionId = this.props.match.params.competitionId as number;
		
		if(props.match.params.competitionId != this.competitionId){
			this.load();
		}
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		CompetitionService.getById(this.competitionId).then(competition => {
			const queue = [];
			competition.divisions = [];
			const d1 = DivisionService.get(competition.id).then(results => {
				results.forEach(div => {
					competition.divisions.push(div);
				});
			});
			queue.push(d1);

			Promise.all(queue).then(result => {
				this.setState({ loading: false, competition });
			});
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div>
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">{this.state.competition.name}</span></h2>
											<p className="text-center">Competition overview</p>
											{/* <div className="col-3"><div className="card p-3 mb-3"><Link className="mr-2" to={`/league/${this.state.competition.id}/rankings`} title="Rankings">League Rankings <i className="fal fa-list-ol"></i></Link></div></div> */}
										</div>
										<Link className="mr-2 float-right" to={`/league/${this.state.competition.id}/results`} title="Results"><div className="tx-20"><i className="fal fa-table-tennis"></i></div></Link>
										<Link className="mr-2 float-right" to={`/league/${this.state.competition.id}/fixtures`} title="Fixtures"><div className="tx-20"><i className="fal fa-bars"></i></div></Link>
										<br />
										<hr />
										<div className="competition-list mg-t-20">
											<div className="row clearfix">
												<div className="col-lg-12">
													<div className="list-group list-group-default">
														<div key={`competition-${this.state.competition.id}`} className="competition mb-3">
															<div className="row">
																{
																	this.state.competition.divisions.map(d => {
																		return (
																			<div className="col-4">
																				<div className="card p-3 mb-3">
																					<div className="row">
																						<div className="col-8">
																							<p className="m-0"><Link to={`/league/${this.state.competition.id}/division/${d.id}`}>{d.name}</Link></p>
																						</div>
																						<div className="col-4 text-right">
																							{(d.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && <Link className="mr-2" to={`/league/${this.state.competition.id}/division/${d.id}/table`} title="Tables"><i className="fal fa-table"></i></Link>}
																							<Link className="mr-2" to={`/league/${this.state.competition.id}/division/${d.id}/fixtures`} title="Fixtures"><i className="fal fa-bars"></i></Link>
																							<Link className="mr-2" to={`/league/${this.state.competition.id}/division/${d.id}/results`} title="Results"><i className="fal fa-table-tennis"></i></Link>
																							{(d.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && <Link className="mr-2" to={`/league/${this.state.competition.id}/division/${d.id}/averages`} title="Averages"><i className="fal fa-percentage"></i></Link>}
																							{!this.state.competition.teamHandicap && <Link className="mr-2" to={`/league/${this.state.competition.id}/division/${d.id}/potm`} title="POTMs"><i className="fal fa-user-circle"></i></Link>}
																						</div>
																					</div>
																				</div>
																			</div>
																		);
																	})
																}
															</div>
														</div>
														{
															this.state.loading ? <ActivitySpinner /> : undefined
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
