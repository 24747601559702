import { AppConfig } from "../../config";
import RestHelper from "@helpers/rest.helper";
import * as qs from "qs";
import { EventFilterOptions, InstanceFilterOptions } from "@models/filters/rankingFilterOptions";
import { BaseService } from "./base.service";
import { Instance } from "@models/rankings/instance";
import { Event } from "@models/rankings/event";
import { PagedList } from "@models/paging";
import { ISimpleItem } from "@models/rankings/simpleitem";
import { InstancedEvent } from "@models/rankings/instancedevent";


export class InstanceService extends BaseService {


	public static get(id:number): Promise<Instance> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instance/${id}`)).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}
	public static getAll(options: InstanceFilterOptions): Promise<PagedList<Instance>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instances`), {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}
	public static getSimple(): Promise<ISimpleItem<number, string>[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instances/simple`), {
					params: undefined,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}

	public static getEvents(instanceId:number, options:EventFilterOptions): Promise<PagedList<Event>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instance/${instanceId}/events`), {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}

	public static save(model: Instance): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance`), model).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static delete(id: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${id}/delete`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static saveInstancedEvent(model: InstancedEvent): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${model.instanceId}/event`), model).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static setPrimary(id: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${id}/primary`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static publishInstancedEvent(instanceId: number, eventId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${instanceId}/event/${eventId}/publish`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static unpublishInstancedEvent(instanceId: number, eventId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${instanceId}/event/${eventId}/unpublish`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static publishAllEventsForInstance(instanceId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${instanceId}/events/publish`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static GetInstanceEventDatesGroupedByMonth(instanceId?:number, categoryId?:number): Promise<string[]> {
		let url = "";
		if (instanceId === undefined && categoryId === undefined) {
			url = "instance/primary/event/dates";
		} else if (instanceId === undefined && categoryId !== undefined) {
			url = `instance/primary/category/${categoryId}/event/dates`;
		} else if (instanceId !== undefined && categoryId === undefined) {
			url = `instance/${instanceId}/event/dates`;
		} else {
			url = `instance/${instanceId}/category/${categoryId}/event/dates`;
		}

		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(url), {
					params: {},
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error);
				});
		});
	}
}