import { string } from "prop-types";

export class DatatablePaging {
	public pageNo: number;
	public pageSize: number;
	public total?: number;
	public pages?: number;
}

export class DatatableSort {
	public field: string;
	public sort: string;
}

export class DatatableSearch {
	public field?: string;
	public value: string;
}

export class DatatableQuery {
	public paging: DatatablePaging;
	public sort: DatatableSort;
	public search: DatatableSearch;
}

export class DatatableMeta {
	public field?: string;
	public page?: number;
	public pages?: number;
	public perPage?: number = 10;
	public sort?: string;
	public total?: number;
	public paging?: DatatablePaging;
}

export class DatatableResponse<T> {
	public data: T[];
	public meta: DatatableMeta;
}