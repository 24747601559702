import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { Row, Col } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import Sidebar from "react-sidebar";

import { Helmet } from "react-helmet";
import { WebsiteCompetitionData } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";
import { Entrant } from "@models/entrant";
import { Adjustment } from "@models/adjustment";
import DivisionStandings from "@components/pages/public/competitions/DivisionStandings";

interface ITablesPageProps {
	site?: string;
	match?: any;
}

interface ITablesPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	divisions: Division[];
	competition?: Competition;
	standings: Standing[];
	entrantId?: number;
	adjustments: Adjustment[];
}
export class TablesPage extends React.Component<ITablesPageProps, ITablesPageState> {

	private competitionId: number;
	private divisionId: number;
	private compIndex: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			standings: [],
			divisions: [],
			adjustments: [],
		};
	}

	componentDidMount() {
		//
		this.divisionId = this.props.match.params.divisionId as number;
		this.competitionId = this.props.match.params.competitionId as number;

		this.load();
	}

	componentDidUpdate(props: ITablesPageProps, state: ITablesPageState) {
		this.divisionId = this.props.match.params.divisionId as number;
		if (props.match.params.divisionId != this.divisionId) {
			this.load();
		}
	}

	load() {
		const p1 = DivisionService.standings(this.divisionId).then(standings => {
			this.setState({ standings });
		});

		const p2 = DivisionService.getById(this.divisionId).then(division => {
			this.setState({ division });
		});

		const p3 = DivisionService.get(this.competitionId).then(divisions => {
			this.setState({ divisions });
		});

		Promise.all([p1, p2, p3]).then(result => {
			const p4 = CompetitionService.getById(this.state.division.competitionId).then(competition => {
				this.setState({ competition, loading: false });
			});
		});
	}

	Adjustments(entrantId: number) {
		DivisionService.entrantsAdjustments(this.state.division.id, entrantId).then(result => {
			this.setState({ entrantId: entrantId, adjustments: result });
		});
	}

	public render() {
		const sidebar = (
			<div className="sidebar">
				<div className="sidebar--header">
					<div className="sidebar--close"></div>
					<h6 className="slim-pagetitle">Adjustments Reasons
						{/* <span><button onClick={() => this.setState({entrantId: null})} className="btn btn-circle btn-icon">
                            <div className="tx-20"><i className="icon fas fa-times"></i></div></button></span> */}</h6>
				</div>
				<div className="sidebar--content">
					<div>
						{this.state.adjustments.map(a => {
							return (
								<div>
									<div className="card card--border pd-25 mb-4">
										<Row>
											<Col xs={8}>
												{a.reason}
											</Col>
											<Col xs={4} className="text-center">
												<h3 className="m-0">{a.total > 0 && "+"}{a.total}</h3>
												<span className="text-small small">points</span>
											</Col>
										</Row>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="sidebar--footer text-center mt-4">
					<button onClick={() => this.setState({ entrantId: null })} className="btn btn-primary btn-rounded btn-outline btn-small bd-0 mg-r-5">Close</button>
				</div>
			</div>);

		return (
			<PublicLayout className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<Sidebar
					sidebar={sidebar}
					pullRight={true}
					open={this.state.entrantId != null}
					sidebarClassName="sidebar-root"
					styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999999 } }}>
					<ShowMe
				    	mode={ShowMeMode.Full}
						visible={!this.state.loading}
						progress={true}
						render={() => (
							<div className="sub-page">
								{
									AppCache.website.config.subHeader ?
										<div className="elementor elementor-6">
											<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-container elementor-column-gap-extended">
													<div className="elementor-row">
														<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
															<div className="elementor-column-wrap  elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner"></div>
																			</div>
																		</div>
																	</div>
																	<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner"></div>
																			</div>
																		</div>
																	</div>
																	<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																		<div className="elementor-widget-container">
																			<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																				<h1 className="sc_layouts_title_caption">Table</h1>
																				<p className="sc_layouts_title_breadcrumbs">{this.state.competition.name} <span className="separator"> - </span> {this.state.division ? this.state.division.name : ""}</p>
																			</div>
																			</div>
																			</div>
																		</div>
																	</div>
																	<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner"></div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />
										</div> : undefined
								}
								<div className="page_content_wrap">
									<div className="container">
										<div className="content">
											{
												AppCache.website.config.subHeader ? undefined :
													<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
														<div className="elementor-widget-container">
															<div className="sc_title sc_title_default">
																<h1 className=" m-0 sc_item_subtitle sc_align_center">
																	<span className="sc_item_title_text">Table</span>
																</h1>
																<h2 className=" m-0 sc_item_title sc_align_center">
																	<span className="sc_item_title_text">{this.state.division ? this.state.division.name : ""}</span>
																</h2>
																<p className="text-center">
																	{this.state.competition ? this.state.competition.name : ""}
																</p>
															</div>
														</div>
													</div>
											}
											{!AppCache.website.config.subHeader && <CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />}
											<div className="row">
												<div className="col-12">
													<div>
														<DivisionStandings competition={this.state.competition} division={this.state.division} standings={this.state.standings} onAjustmentClick={this.Adjustments.bind(this)} />
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													<span className="tx-bold">Last Updated</span> <span title={moment(this.state.division.updated).format("Do MMMM YYYY")}>{moment(this.state.division.updated).fromNow()}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)} />
				</Sidebar>
			</PublicLayout>);
	}
}
