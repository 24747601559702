
import RestHelper from "@helpers/rest.helper";
import { BookingSlotFilterOptions, ConsentAgreementsFilterOptions, ConsentAssociationFilterOptions, FilterOptions, InvoiceFilterOptions, MembershipUserFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

import * as qs from "query-string";
import { CreateInvoice, Invoice, TransactionType } from "@models/invoice";
import { Consent, ConsentAgreement, ConsentAssociations, CreateConsentAccept } from "@models/consent";
import { DatatableQuery, DatatableResponse } from "@models/query";

import { AppConfig } from "../config";

import { LoginService } from "./login.service";
import { Contact } from "@models/contact";

export class ConsentService {

	constructor() {
		//
	}

	public static data(query:DatatableQuery, deleted: boolean = null): Promise<DatatableResponse<Consent>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `consents/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(deleted ? {query, deleted} : {query}),
				success: (data:DatatableResponse<Consent>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getAgreements(query: ConsentAgreementsFilterOptions): Promise<PagedList<ConsentAgreement>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`consents/agreements`, {
				params: query,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getAgreementsByContact(): Promise<ConsentAgreement[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`contacts/agreements`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static getById(consentId: number): Promise<Consent> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`consents/${consentId}`, {
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getAll(): Promise<Consent[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`consents`, {
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsert(consent: Consent): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`consents`, consent)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static delete(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`consents/${id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static deleteAssociation(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`consents/${id}/association`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static deleteAgreement(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`consents/${id}/agreement`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static deleteAgreements(consentId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`consents/${consentId}/agreements`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static resetAgreements(consentId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`consents/${consentId}/agreements/reset`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error);
			});
		});
	}

	public static changeAssociation(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`consents/${id}/required`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getAssociations(options: ConsentAssociationFilterOptions): Promise<ConsentAssociations[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`consents/associations`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static updateAssociations(associations: ConsentAssociations[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`consents/associations`, associations).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static accept(acceptances: CreateConsentAccept[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/accept/consents`, acceptances).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject(error?.response?.data);
			});
		});
	}

	public static renew(agreementId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`contacts/agreement/${agreementId}/renew`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject(error?.response?.data);
			});
		});
	}
}
