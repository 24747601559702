import "react";
import "react-dom";
import "react-router";
import "moment";

// styles
import "nprogress/nprogress.css";
// import "antd/dist/antd.css";

require("es6-shim");

//window.$ = window.jQuery = require("jquery");
//window.Popper = require("popper.js");
//window.fn = require("react-owl-carousel2");
