import RestHelper from "@helpers/rest.helper";
import { PagedList } from "@models/paging";
import { FilterOptions, EventSessionFilterOptions, EventFilterOptions } from "@models/filters/filterOptions";
import * as qs from "qs";
import { Event, EventMember, EventSession, EventSchedule, EventCalendarItem, EventMembership, CancelSession, EventPrice, ScheduleResponse } from "@models/event";
import * as moment from "moment";
import { Invoice } from "@models/invoice";
import { ConsentAssociations, ConsentBookingAssociation } from "@models/consent";
import { BasketItem } from "@models/basket";

export class EventService {

	public static get(options: EventFilterOptions): Promise<PagedList<Event>> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events`, {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getById(eventId: number, contactId?: number): Promise<Event> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/${eventId}`, {
					params: { contactId },
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getSchedules(eventId: number): Promise<EventSchedule[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/${eventId}/schedules`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getScheduleById(eventScheduleId: number): Promise<EventSchedule> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/schedule/${eventScheduleId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getScheduleByEventId(eventId: number): Promise<EventSchedule> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/scheduleByEvent/${eventId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static deleteSchedule(scheduleId: number): Promise<EventSchedule> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`events/schedule/${scheduleId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getMembers(eventId: number): Promise<EventMember[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/${eventId}/members`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getSessions(options: EventSessionFilterOptions, eventId?: number): Promise<PagedList<EventSession>> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(eventId ? `events/${eventId}/sessions` : `events/sessions`, {
					params: options,
					paramsSerializer: params => qs.stringify(params, {
						serializeDate: (date: Date) => moment(date).format("YYYY-MM-DDTHH:mmZ")
					})
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getSessionsBySpace(options: EventSessionFilterOptions, spaceId?: number): Promise<EventSession[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/${spaceId}/eventSessions`, {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getSessionById(sessionId: number, options: EventSessionFilterOptions = null): Promise<EventSession> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/session/${sessionId}`, {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					const data = Object.assign(new EventSession(), result.data);
					resolve(data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsert(event: Event): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events`, event)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsertSchedule(schedule: EventSchedule): Promise<ScheduleResponse> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/schedule`, schedule)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static delete(eventId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`events/${eventId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static bookSession(sessionId: number, contactId: number, paymentMethodId?: string): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/book`, {sessionId, contactId, paymentMethodId})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static book(eventId: number, sessionId: number[], contactId: number, bookeeId: number, guests: number[], upfront: boolean, markPaid: boolean,  newPrice: number, paymentMethodId?: string): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/book`, {eventId, sessionId, contactId, bookeeId, guests, upfront, newPrice, markPaid, paymentMethodId})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static reserve(eventId: number, sessionId: number[], contactId: number, bookeeId: number, guests: number[], upfront: boolean, markPaid: boolean,  newPrice: number, paymentMethodId?: string): Promise<BasketItem[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/reserve`, {eventId, sessionId, contactId, bookeeId, guests, upfront, newPrice, markPaid, paymentMethodId})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static remove(member: EventMember): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/remove`, {sessionId: member.sessionId, contactId: member.contactId})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static calendar(start: Date, end: Date, category?: number): Promise<EventCalendarItem> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/calendar`, {
					params: { start, end, category },
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static addAttend(id: number): Promise<void> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/attendance/add/${id}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static removeAttend(id: number): Promise<void> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/attendance/remove/${id}`,)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsertSession(session: EventSession): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/upsert`, session)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsertMembership(membership: EventMembership): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/${membership.eventId}/membership`, membership)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static cancelSession(cancel: CancelSession): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/cancel`, cancel)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static unCancelSession(id: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/session/${id}/uncancel`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getEventMembershps(eventId: number): Promise<EventMembership[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/${eventId}/memberships`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static createEventMembership(eventMembership: EventMembership): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`events/membership/create`, eventMembership)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static deleteEventMembership(id: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`events/membership/${id}/delete`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getUsersEvents(userId: string): Promise<Event[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/user/${userId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getCost(sessionId: number, contactId?:number): Promise<EventPrice> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/session/${sessionId}/cost/${contactId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static publish(eventId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`events/${eventId}/publish`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getContactUsage(eventId: number, contactId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`events/${eventId}/contact/${contactId}/usage`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getAssociations(eventId: number): Promise<ConsentAssociations[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`events/activeAssociations/${eventId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

}
