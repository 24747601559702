import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardBody, CardHeader, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { CostAdjustment, CostFeedback, Entry, EntryCosts, EntryPlayer, EntryStatus, EntryTeam } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { RegistrationService } from "@services/registration.service";
import EntryTeams from "./components/EntryTeams";
import { Registration, RegistrationCategory } from "@models/registration";

import RegisterTeamSidebar from "@components/sidebar/public/RegisterTeamSidebar";
import RegisterPlayerSidebar from "@components/sidebar/public/RegisterPlayerSidebar";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import Header from "./components/Header";
import LocaleHelper from "@helpers/locale.helper";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";



interface IEntryViewPageProps {
	site?: string;
	match?: any;
	location?: any;
}

export default (props: IEntryViewPageProps) => {
	const [loading, setLoading] = useState<boolean>(true);

	const [entryId, setEntryId] = useState<number>(props.match.params.entryId);
	const [entry, setEntry] = useState<Entry>();

	const [costs, setCosts] = useState<EntryCosts>();

	const [registration, setRegistration] = useState<Registration>();

	const [activeTab, setActiveTab] = useState<string>("Summary");

	const [totalTeams, setTotalTeams] = useState<number>();
	const [totalPlayers, setTotalPlayers] = useState<number>();
	const [totalPending, setTotalPending] = useState<number>();
	const [totalPaid, setTotalPaid] = useState<number>();

	const [costAdjustments, setCostAdjustments] = useState<CostAdjustment[]>();
	const [balance, setBalance] = useState<number>(0);

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		// improve this!
		if (costs) {
			let _totalPending = 0;
			let _totalPaid = 0;

			const _teams: EntryTeam[] = [];
			const _players: EntryPlayer[] = [];

			costs.pending.forEach(p => p.entryTeams.forEach(et => {
				_totalPending += et.cost;
				if (_teams.filter(t => t.id === et.id).length === 0) {
					_teams.push(et);
				}
			}));
			costs.pending.forEach(p => p.entryPlayers.forEach(et => {
				_totalPending += et.cost;
				if (_players.filter(t => t.id === et.id).length === 0) {
					_players.push(et);
				}
			}));

			costs.invoiced.forEach(p => p.entryTeams.forEach(et => {
				_totalPending += et.cost;
				if (_teams.filter(t => t.id === et.id).length === 0) {
					_teams.push(et);
				}
			}));
			costs.invoiced.forEach(p => p.entryPlayers.forEach(et => {
				_totalPending += et.cost;
				if (_players.filter(t => t.id === et.id).length === 0) {
					_players.push(et);
				}
			}));

			costs.paid.forEach(p => p.entryTeams.forEach(et => {
				_totalPaid += et.cost;
				if (_teams.filter(t => t.id === et.id).length === 0) {
					_teams.push(et);
				}
			}));
			costs.paid.forEach(p => p.entryPlayers.forEach(et => {
				_totalPaid += et.cost;
				if (_players.filter(t => t.id === et.id).length === 0) {
					_players.push(et);
				}
			}));

			setTotalPlayers(_players.length);
			setTotalTeams(_teams.length);
			setTotalPending(_totalPending);
			setTotalPaid(_totalPaid);
		}
	}, [costs]);

	const load = () => {
		var entryResult;

		const entry = EntryService.getById(entryId).then(async result =>  {
			setEntry(result);
			entryResult = result;
		});

		const costs = EntryService.costFeedback(entryId).then(result => {
			setCosts(result);
		});

		Promise.all([entry]).then(async result => {
			const registration = await RegistrationService.getById(entryResult.registrationId);
			setRegistration(registration);

			const costAdjustments = await RegistrationService.getCostAdjustments({ clubId: entryResult.clubId, processed: false, balance: true, invoiced: false });
			setCostAdjustments(costAdjustments.items);

			if (costAdjustments.total > 0) {
				setBalance(costAdjustments.items.map(c => c.value).reduce((a, b) => a + b));
			}

			setLoading(false);
		});
	};

	const sidebar = () => {
		return <></>;
	};

	const openSidebar = () => {
		return false;
	};

	return (
		<PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<Sidebar
						sidebar={sidebar()}
						pullRight={true}
						open={openSidebar()}
						sidebarClassName="sidebar-root"
						styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
						<div>
							<div className="page_content_wrap">
								<div className="container">
									<Row>
										<Col>
											<Header entry={entry} registration={registration} tab="Summary" />
											<div>
												<TabContent activeTab={activeTab}>
													<TabPane tabId="Summary">
														{
															!entry.termsAccepted &&
															<Row className="mb-2">
																<Col>
																	<div className="alert alert-danger mb-2" role="alert">
																		<strong>Important</strong> Please ensure you have agreed all the terms and conditions on the <Link to={`/member/entries/${entryId}/agreements`}>agreements tab</Link>
																	</div>
																</Col>
															</Row>
														}
														<Row className="mb-4">
															<Col>
																<div className="alert alert-info mb-2" role="alert">
																	<strong>Reminder</strong> Please ensure that all club info and contact details witin Sport:80 TTMembers are up to date.
																</div>
															</Col>
														</Row>
														<Row className="mb-5">
															<Col>
																<Card>
																	<CardBody>
																		<h3 className="m-0">{totalTeams != null ? totalTeams : <ActivitySpinner  mode={"light"}/>}</h3>
																		<span>Teams</span>
																	</CardBody>
																</Card>
															</Col>
															<Col>
																<Card>
																	<CardBody>
																		<h3 className="m-0">{totalPlayers != null ? totalPlayers : <ActivitySpinner  mode={"light"}/>}</h3>
																		<span>Players</span>
																	</CardBody>
																</Card>
															</Col>
															<Col>
																<Card>
																	<CardBody>
																		<h3 className="m-0">{totalPending != null ? LocaleHelper.toPrice(totalPending) : <ActivitySpinner  mode={"light"}/>}</h3>
																		<span>Pending</span>
																	</CardBody>
																</Card>
															</Col>
															<Col>
																<Card>
																	<CardBody>
																		<h3 className="m-0">{totalPaid != null  ? LocaleHelper.toPrice(totalPaid) : <ActivitySpinner  mode={"light"}/>}</h3>
																		<span>Paid</span>
																	</CardBody>
																</Card>
															</Col>
															<Col>
																<Card>
																	<CardBody>
																		<h3 className={`m-0 ${balance > 0 ? "text-success" : ""} ${balance < 0 ? "text-danger" : ""}`}>{LocaleHelper.toPrice(balance)}</h3>
																		<span>Balance</span>
																	</CardBody>
																</Card>
															</Col>
														</Row>
													</TabPane>
												</TabContent>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Sidebar>)} />
		</PublicLayout>
	);
};