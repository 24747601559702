import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { MemberRegistration, Member, MemberSetup, MemberAccess, MemberDetails, Platform, CreateMember, SearchMember, MemberIdentity } from "../models/member";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { SelectOption } from "@models/forms";

import browser from "browser-detect";
import { CreateMessage, Message, MessageRecipient, MessageMember, RecipientOptions, Recipients } from "@models/message";
import { TransactionType } from "@models/invoice";
import { Contact } from "@models/contact";
import { Booking, CreateBooking } from "@models/booking";
import { EventMember, EventSession } from "@models/event";
import RestHelper from "@helpers/rest.helper";
import { ContactFormDetails } from "@models/website";

export class MessageService {

	constructor() {
		//
	}

	public static data(query: DatatableQuery, draft: boolean = false): Promise<DatatableResponse<Message>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({ query, draft }),
				success: (data: DatatableResponse<Message>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static recipients(messageId: number): Promise<MessageRecipient[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/${messageId}/recipients`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: MessageRecipient[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static recipientOptions(messageId: number): Promise<RecipientOptions> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/${messageId}/recipients/options`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: RecipientOptions, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(messageId: number): Promise<Message> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/${messageId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Message, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static send(member: CreateMessage): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages`,
				cache: false,
				method: "POST",
				data: JSON.stringify(member),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notificateMembership(model: CreateMessage, contactId: number, membershipId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/notificateMembership`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ model, contactId, membershipId }),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notificatePayment(model: CreateMessage, reference: string, transactionType: TransactionType): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/notificatePayment`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ model, reference, transactionType }),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notificateMedicalUpdate(model: CreateMessage, contact: Contact): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/medicalUpdate`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ model, contact }),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notificateBooking(model: CreateMessage, booking: CreateBooking): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/notificateBooking`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ model, booking }),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notificateBookingCancel(model: CreateMessage, booking: Booking): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/notificateBookingCancel`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ model, booking }),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notificateSessionCancel(model: CreateMessage, session: EventSession, contactId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/notificateSessionCancel`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ model, session, contactId }),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static save(member: CreateMessage): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/save`,
				cache: false,
				method: "POST",
				data: JSON.stringify(member),
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static recipientsPreview(member: CreateMessage): Promise<Recipients> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/recipients/preview`,
				cache: false,
				method: "POST",
				data: JSON.stringify(member),
				contentType: "application/json",
				success: (data: Recipients, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(messageId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `messages/${messageId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static contactForm(contactform: ContactFormDetails): Promise<string[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`messages/contactform`, contactform).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
