import version from "../version.json";

import production from "../config/appsettings.Production.json";
import sandbox from "../config/appsettings.Sandbox.json";
import development from "../config/appsettings.Development.json";
import testing from "../config/appsettings.Testing.json";

export enum ReleaseType {
	development = 1,
	sandbox = 2,
	production = 3,
	testing = 4
}

export enum EntryType {
	public = 1,
	members = 2,
	manage = 3,
	admin = 4
}

export interface IUrlConfig {
	baseUrl: string;
	apiUrl: string;
}

export class AppSettings {
	public baseUrl: string;
}

export class AppConfig {
	public static get version(): string {
		return version.version;
	}

	public static get date(): string {
		return version.date;
	}

	public static get settings(): AppSettings {
		switch (AppConfig.release) {
			case ReleaseType.development:
				return development as AppSettings;
			case ReleaseType.sandbox:
				return sandbox as AppSettings;
			case ReleaseType.production:
				return production as AppSettings;
			case ReleaseType.testing:
				return testing as AppSettings;
			default:
				break;
		}
	}

	public static get release(): ReleaseType {
		if (window.env && window.env.release) {
			return (window.env.release as ReleaseType);
		}

		return ReleaseType.development;
	}

	public static get entry(): EntryType {
		if (window.env && window.env.entry) {
			return (window.env.entry as EntryType);
		}

		return EntryType.manage;
	}

	public static get hostUrl(): string {
		switch (AppConfig.release) {
			case ReleaseType.development:
				return "192.168.0.20:8082";
			case ReleaseType.sandbox:
				return "ttleagues-sandbox.com";
			case ReleaseType.production:
				return "ttleagues.com";
		}

		return "";
	}

	public static get baseUrl(): string {
		if (AppConfig.isLocal) {
			return window.env.baseUrl;
		} if(AppConfig.release === ReleaseType.testing) {
			return AppConfig.settings.baseUrl.replace("{tenant}", AppConfig.tenant);
		} else {
			return AppConfig.settings.baseUrl;
		}
	}

	public static get tenant(): string {
		return window.env.tenant;
	}

	public static get isLocal(): boolean {
		return window.env.local;
	}

	public static get apiUrl(): string {
		switch (AppConfig.release) {
			case ReleaseType.development:
				return AppConfig.baseUrl + "api/";
			case ReleaseType.sandbox:
				return AppConfig.baseUrl + "api/";
			case ReleaseType.production:
				return AppConfig.baseUrl + "api/";
			case ReleaseType.testing:
				return AppConfig.baseUrl + "api/";
		}

		return "";
	}

	public static get stripeApi(): string {
		switch (AppConfig.release) {
			case ReleaseType.development:
			case ReleaseType.sandbox:
			case ReleaseType.testing:
				return `pk_test_51HLGEmDiP0NHU40LLp9R445dBMIzt6OyWZZXhSuf5LZIFtaq5xM6jfC5gIYDzSkJKH03mEapoOHvxzKeCeGrpTgN00x9GeS43j`;
			case ReleaseType.production:
				return `pk_live_8GUaQrB8EVNyWS7wm0cQUThl`;
		}
	}

	public static get sport80(): string {
		switch (AppConfig.release) {
			case ReleaseType.development:
			case ReleaseType.sandbox:
			case ReleaseType.testing:
				return `https://tabletennisengland.s80testing.co.uk`;
			case ReleaseType.production:
				return `https://tabletennisengland.sport80.com`;
		}
	}

	public static get analytics(): string {
		return "G-C9F2752402";
	}

	public static get googleApi(): string {
		return "AIzaSyCLoyyA04IuilBOzfTh-DW_ZvFTRB6J_po";
	}

	public static get isMobile(): boolean {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			return true;
		}

		return false;
	}
}
