import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";
import classnames from "classnames";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";

interface IMedicalDetails {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IMedicalDetails) => {
	const [contact, setContact] = useState<Contact>(props.contact);
	const [agree, setAgree] = useState<boolean>(false);

	const [groups, setGroups] = useState<FieldGroup[]>([]);
	const [values, setValues] = useState<FieldValue[]>([]);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const groups = await FieldService.getGroups(FieldArea.Contacts);
		const values = await FieldService.getValues(contact.id);

		setGroups(groups);
		setValues(values);
	};

	const handleNewFieldValue = (fieldId: number, value: string) => {
		const fieldValue = new FieldValue();
		fieldValue.fieldId = fieldId;
		fieldValue.itemId = contact.id.toString();
		fieldValue.value = value;

		return fieldValue;
	};

	const handleChangeCustom = (value: FieldValue) => (e: any) => {
		const v = [...values];
		const index = v.findIndex(v => v.fieldId === value.fieldId);
		value.value = e.currentTarget.value;
		if (index !== -1)
			v[index] = value;
		else
			v.push(value);

		setValues(v);
		setContact({ ...contact, medicalAgreement: null });
	};

	const handleChangeCustomCheckbox = (value: FieldValue) => (e: any) => {
		const v = [...values];
		const index = v.findIndex(v => v.fieldId === value.fieldId);
		value.value = value.value === "true" ? "false" : "true";
		if (index !== -1)
			v[index] = value;
		else
			v.push(value);

		setValues(v);
		setContact({ ...contact, medicalAgreement: null });
	};

	const update = () => (e: any) => {
		e.preventDefault();

		const validContactNumber = contact.emergencyNumber.match(/^[0-9]{7,12}$/);


		if (!validContactNumber)
			toast.error("Please enter a valid emergency contact number (numbers only, 7 minimum)");
		else if (contact.emergencyName.trim() == "")
			toast.error("Please enter an emergency contact name");
		else if (contact.medicalAgreement) {
			NProgress.start();

			const p1 = FieldService.save(values);
			contact.medicalAgreementUpdate = agree;

			const p2 = ContactService.upsert(contact);

			Promise.all([p1, p2]).then(result => {
				NProgress.done();
				toast.success("Medical details updated", {});
				props.onConfirm();
			});
		}
	};

	const handleAgreementChecked = () => (e) => {
		setAgree(e.currentTarget.checked);
		setContact({ ...contact, medicalAgreement: moment().toDate() });
	};

	return (
		<>
			<Card className="mb-5 p-5">
				<h6 className="mb-3">Emergency Contact</h6>
				<Row>
					<Col>
						<FormGroup>
							<Label>Contact Name</Label>
							<Input type="text" value={contact.emergencyName} onChange={e => setContact({ ...contact, emergencyName: e.currentTarget.value })} />
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<Label>Contact Number</Label>
							<Input type="text" value={contact.emergencyNumber} onChange={e => setContact({ ...contact, emergencyNumber: e.currentTarget.value })} />
						</FormGroup>
					</Col>
				</Row>
			</Card>
			{
				groups.map(g => {
					return (
						<Card className="p-5 mg-b-25">
							<h6 className="m-0">{g.name}</h6>
							<p className="m-0">{g.description}</p>

							<div className="form-layout mt-3">
								<Row>
									{
										g.fields.map(f => {
											const value = values.find(v => v.fieldId === f.id) ?? handleNewFieldValue(f.id, f.type === FieldType.Checkbox ? "false" : "");
											return (
												<Col md={6}>
													<div className="form-group">
														{
															f.type === FieldType.Text &&
															<>
																<label className="form-control-label">{f.label}</label>
																<input className="form-control" type="text" name={f.name} value={value?.value} placeholder={f.placeholder} onChange={handleChangeCustom(value)} />
															</>
														}
														{
															f.type === FieldType.TextArea &&
															<>
																<label className="form-control-label">{f.label}</label>
																<textarea className="form-control" name={f.name} value={value?.value} onChange={handleChangeCustom(value)} />
															</>
														}
														{
															f.type === FieldType.Checkbox &&
															<>
																<label className="ckbox ckbox-inline ml-4">
																	<input type="checkbox" className="d-block form-check-input" checked={value?.value === "true"} onChange={handleChangeCustomCheckbox(value)} />
																	<span className="no-highlight">{f.label}</span>
																</label>
															</>
														}
													</div>
												</Col>
											);
										})
									}
								</Row>
							</div>

							<div className="alert alert-info mt-2">
								<i className="fa fa-info-circle mr-2"></i>
								Please provide any medical information you would like us to hold, that may be of assistance in us getting help in an emergency situation.
							</div>
						</Card>
					);
				})
			}
			<Card className="p-5 mg-b-25">
				<h6 className="m-0">Medical Agreement</h6>
				<div className="form-layout">
					<Row>
						<Col>
							{
								!contact.medicalAgreement &&
								<>
									<p>Confirm the above deails are valid and complete.</p>
									<div className="form-group">
										<label className="ckbox ckbox-inline ml-4">
											<input type="checkbox" onChange={handleAgreementChecked()} className="d-block form-check-input" />
											<span className="no-highlight">I Agree</span>
										</label>
									</div>
								</>
							}
							{
								contact.medicalAgreement &&
								<div>
									<p className="m-0">Confirmed {moment(contact.medicalAgreement).format("Do MMMM YYYY")}</p>
								</div>
							}
						</Col>
					</Row>
				</div>
			</Card>
			<Row>
				<Col>
					<div className="sp-view-all-link">
						<a onClick={update()} className={classnames("", { disabled: !contact.medicalAgreement })}>
							Save
						</a>
					</div>
				</Col>
			</Row>
		</>
	);
};