import { timeFormat } from "@constants/scheduleTimeTable";
import * as moment from "moment";
import { Contact, ContactBasic } from "./contact";
import { CustomEmailType } from "./email";
import { EventCancellationPolicyType } from "./event";
import { Invoice } from "./invoice";
import { Member } from "./member";
import { Membership } from "./membership";
import { User, UserBasic } from "./user";

export class Booking {
	public id: number;
	public spaceId: number;
	public space: BookingSpace;
	public resourceId: number;
	public date: Date;
	public startDate: Date;
	public endDate: Date;
	public contactId: number;
	public contact: Contact;
	public resource: BookingResource;
	public attendees: BookingAttendee[] = [];
	public confirmed?: Date;
	public invoice: Invoice;
	public cancelled?: Date;
	public title: string;
	public type: BookingType;
	public total: number;

	public expires?: Date;
	public categoryId?: number;
	public category?: BookingCategory;
}

export class BookingAttendee {
	public id: number;
	public contactId: number;
	public contact: ContactBasic;
	public bookingId: number;
	public date: Date;
	public confirmed?: Date;
}

export class BookingCategory {
	public id: number;
	public spaceId: number;
	public name: string;
	public summary: string;
	public color: string;
	public rules: BookingRule[];

	public emailType: CustomEmailType;
	public emailIntro: string;
	public emailBody: string;
}

export class BookingRule {
	public id: number;
	public categoryId: number;
	public name: string;
	public minAge?: number;
	public maxAge?: number;
	public minSlots?: number;
	public maxSlots?: number;
	public advancedPeriod: number;
	public minAdvancedPeriod: number;
	public membersOnly: boolean;
	public payLater: boolean;

	public memberships: BookingRuleMembership[];

	public costType: BookingRuleCostType;
	public cancellationType?: EventCancellationPolicyType;
	public memberFee: number;
	public guestFee: number;
	public resourceFee: number;
}

export class BookingRuleMembership {
	public id: number;
	public ruleId: number;
	public membershipId: number;
	public membership: Membership;
	public cost: number;
}

export class BookingGroup {
	public spaceId: number;
	public space: BookingSpace;
	public bookings: Booking[];
}

export class BookingSpace {
	public id: number;
	public name: string;
	public description: string;
	public displayEarliest: string;
	public displayLatest: string;
	public duration: number;

	public emailType: CustomEmailType;
	public emailIntro: string;
	public emailBody: string;
}

export class BookingResource {
	public id: number;
	public spaceId: number;
	public name: string;
	public available: boolean;

	public categoryId?: number;
	public category?: BookingCategory;
}

export class BookingSlotResource extends BookingResource {
	public categoryId: number;
}

export class BookingTime {
	public id?: number;
	public scheduleId: number;
	public resourceId: number;
	public dayOfWeek: number;
	public startTime: string;
	public endTime: string;
	public type: BookingTimeType;
	public categoryId?: number;

	public category?: BookingCategory;
	public schedule?: BookingSchedule;
}

export class BookingTimeExtensions {
	public static toComparable(source:BookingTime):string {
		let props:string[] = [
			source.id?.toString() ?? "",
			source.scheduleId?.toString() ?? "",
			source.resourceId?.toString() ?? "",
			source.dayOfWeek.toString(),
			source.startTime,
			source.endTime,
			source.type.toString(),
			source.categoryId?.toString() ?? "",
		];
		return props.join(";;");
	}

	public static areEqual(source:BookingTime, target:BookingTime):boolean {
		return this.toComparable(source) === this.toComparable(target);
	}

	public static sort(source:BookingTime[]):BookingTime[] {
		const output = source.slice();
		output.sort((a, b) => {
			if (a.dayOfWeek < b.dayOfWeek) {
				return -1;
			}
			else if (a.dayOfWeek > b.dayOfWeek) {
				return 1;
			}
			else if (moment(a.startTime, timeFormat).isBefore(moment(b.startTime, timeFormat))) {
				return -1;
			}
			else if (moment(a.startTime, timeFormat).isAfter(moment(b.startTime, timeFormat))) {
				return 1;
			}
			else {
				return 0;
			}
		});
		return output;
	}
}

export class BookingSchedule {
	public id: number;
	public name: string;
	public start?: Date;
	public end?: Date;
}

export enum BookingTimeType {
	Available = 1,
	Unavailable = 2,
	Closed = 3
}

export enum BookingRuleCostType {
	Person = 1,
	Resource = 2
}

export class BookingSlot {
	public order: number;
	public spaceId: number;
	public start: Date;
	public end: Date;
	public resources: BookingSlotResource[];
	public available: boolean;
	public category: BookingCategory;
	public rule: BookingRule;
}

export class CreateBooking {
	public bookeeId: number;
	public ruleId: number;
	public spaceId: number;
	public startDate: Date;
	public endDate: Date;
	public resourceId: number;
	public attendees: number[];
	public categoryId: number;
	public slots: number;
	public newPrice: number;
	public paymentMethodId: string;

	public reservation?: boolean;
	public basket?: string;
}

export class CreateBookingReservation {
	public title: string;
	public spaceId: number;
	public startDate: Date;
	public endDate: Date;
	public resources: number[];
}

export class CalendarTimeframe {
	public start: Date;
	public end: Date;
}

export enum BookingType {
	Contact = 1,
	Reservation = 2
}

export class ContactCalendarTimeframe extends CalendarTimeframe{
	public contactId: number;
}

export class  CalendarConflict extends CalendarTimeframe{
	public name: string;
	public type: string;
	public reservation: boolean;
}