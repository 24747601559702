import * as React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Select from "react-select";

import { SHARE_SITES, buildShareUrl, isInternetExplorer, buildShareMatchesUrl } from '@helpers/calendar.helper';
import { Match } from "@models/match";

interface IAddToCalendarProps {
    event?: any,
    matches?: Match[];
}

interface IAddToCalendarState {
    open: boolean
}

export class AddToCalendar extends React.Component<IAddToCalendarProps, IAddToCalendarState> {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        //
    }

    handleChange(e: any) {
    }

    toggle = e => {
        e.preventDefault();
        this.setState(prevState => ({ open: !prevState.open }));
    };

    handleCalendarButtonClick = e => {
        e.preventDefault();
        const url = e.currentTarget.getAttribute('href');
        if (url.startsWith('BEGIN')) {
            const filename = 'download.ics';
            const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

            if (isInternetExplorer()) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } else {
            window.open(url, '_blank');
        }
    };

    render() {

        const Share = (props: any) => (<a
            className="d-block"
            key={props.site}
            onClick={this.handleCalendarButtonClick}
            href={buildShareUrl(this.props.event, props.site)}>
            {props.site == SHARE_SITES.GOOGLE ? <span><i className="fab fa-google"></i> Google</span> : undefined}
            {props.site == SHARE_SITES.ICAL ? <span><i className="fab fa-apple"></i> Apple</span> : undefined}
            {props.site == SHARE_SITES.OUTLOOK ? <span><i className="fab fa-microsoft"></i> Outlook</span> : undefined}
            {props.site == SHARE_SITES.YAHOO ? <span><i className="fab fa-yahoo"></i> Yahoo</span> : undefined}
        </a>);

        return (
            <>
                {this.props.matches && <a
                    title="Download Fixtures" className="btn btn-primary btn-circle text-white clickable"
                    onClick={this.handleCalendarButtonClick}
                    href={buildShareMatchesUrl(this.props.matches)}><div className="tx-20"><i className="icon fal fa-calendar"></i></div></a>}

                {this.props.event && <a onClick={this.toggle}><i className="fal fa-calendar"></i></a>}
                <Modal centered={true} isOpen={this.state.open} toggle={this.toggle.bind(this)}>
                    <ModalBody>
                        <div className="row">
                            <div className="col-6 text-center mb-2">
                                <Share site={SHARE_SITES.GOOGLE} />
                            </div>
                            <div className="col-6 text-center mb-2">
                                <Share site={SHARE_SITES.ICAL} />
                            </div>
                            <div className="col-6 text-center mb-2">
                                <Share site={SHARE_SITES.OUTLOOK} />
                            </div>
                            <div className="col-6 text-center mb-2">
                                <Share site={SHARE_SITES.YAHOO} />
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <Button color="gray-200" className="btn-md" onClick={this.toggle.bind(this)}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
