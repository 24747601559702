import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardBody, CardHeader, Row, Col, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter  } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { CostFeedback, Entry, EntryCategory, EntryCosts, EntryPlayer, EntryStatus, EntryTeam } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { RegistrationService } from "@services/registration.service";
import EntryTeams from "./components/EntryTeams";
import { Registration, RegistrationCategory } from "@models/registration";

import RegisterTeamSidebar from "@components/sidebar/public/RegisterTeamSidebar";
import RegisterPlayerSidebar from "@components/sidebar/public/RegisterPlayerSidebar";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import Header from "./components/Header";
import LocaleHelper from "@helpers/locale.helper";
import { Invoice, InvoiceStatus } from "@models/invoice";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";



interface IEntryCategoriesPageProps {
	site?: string;
	match?: any;
	location?: any;
}

export default (props: IEntryCategoriesPageProps) => {
	const [loading, setLoading] = useState<boolean>(true);

	const [entryId, setEntryId] = useState<number>(props.match.params.entryId);
	const [entry, setEntry] = useState<Entry>();

	const [costs, setCosts] = useState<EntryCosts>();

	const [registration, setRegistration] = useState<Registration>();

	const [activeTab, setActiveTab] = useState<string>("Pending");

	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const [invoice, setInvoice] = useState<Invoice>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const entry = await EntryService.getById(entryId);
		setEntry(entry);

		const registration = await RegistrationService.getById(entry.registrationId);
		setRegistration(registration);

		setLoading(false);

		const costs = await EntryService.costFeedback(entryId);
		setCosts(costs);

		const invoices = await EntryService.getInvoices(entryId);
		setInvoices(invoices);
	};

	const toggle = (tab: string) => (e) => {
		setActiveTab(tab);
	};

	const sidebar = () => {
		return <></>;
	};

	const openSidebar = () => {
		return false;
	};

	const groupBy = (array, f) => {
		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		})
	};

	const pay = (invoice: Invoice) => (e: any) => {
		if ((invoice.status === InvoiceStatus.Pending || invoice.status === InvoiceStatus.Partial) && entry.termsAccepted == true && registration.enablePayment == true) {
			History.push(`/pay/${invoice.reference}`);
		}
	};

	return (
		<PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<Sidebar
						sidebar={sidebar()}
						pullRight={true}
						open={openSidebar()}
						sidebarClassName="sidebar-root"
						styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
						<div>
							<div className="page_content_wrap">
								<div className="container">
									<Row>
										<Col>
											<Header entry={entry} registration={registration} tab="Payments" />
											<div>
												<Nav className="d-flex w-100">
													<NavItem className="flex-fill">
														<NavLink
															className={classnames({ active: activeTab === `Pending` }, "text-center")}
															onClick={toggle(`Pending`)}>
															Pending
														</NavLink>
													</NavItem>
													<NavItem className="flex-fill">
														<NavLink
															className={classnames({ active: activeTab === `Invoiced` }, "text-center")}
															onClick={toggle(`Invoiced`)}>
															Invoiced
														</NavLink>
													</NavItem>
													<NavItem className="flex-fill">
														<NavLink
															className={classnames({ active: activeTab === `Paid` }, "text-center")}
															onClick={toggle(`Paid`)}>
															Paid
														</NavLink>
													</NavItem>
													<NavItem className="flex-fill">
														<NavLink
															className={classnames({ active: activeTab === `Invoices` }, "text-center")}
															onClick={toggle(`Invoices`)}>
															Invoices
														</NavLink>
													</NavItem>
												</Nav>
												<TabContent activeTab={activeTab}>
													<TabPane tabId="Pending">
														{costs != null ? <div className="mt-5">
															{
																groupBy(costs.pending, (c) => c.categoryId).map((cg: CostFeedback[], index) => (
																	<>
																		{
																			cg.map((cost: CostFeedback) => {
																				return <>
																					<Card className="mb-4">
																						<CardBody>
																							<Row>
																								<Col className="my-auto">
																									<h6>{cost.category.category.name}</h6>
																								</Col>
																								<Col className="my-auto">
																									<h6 className="mb-0">{cost.teams}</h6>
																									<span className="small">Teams</span>
																								</Col>
																								<Col className="my-auto">
																									<h6 className="mb-0">{cost.players}</h6>
																									<span className="small">Players</span>
																								</Col>
																								<Col className="my-auto">
																									<h5 className="mb-0">{LocaleHelper.toPrice(cost.total)}</h5>
																									<span className="small">Total</span>
																								</Col>
																								{entry.termsAccepted == true && registration.enablePayment == true && <Col xs={2}>
																									{entry.allowInvoicing && <div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																										<Link to={`/member/entries/${entry.id}/invoice/${cost.categoryId}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																											<span className="sc_button_text">
																												<span className="sc_button_title">Invoice</span>
																											</span>
																										</Link>
																									</div>}
																									<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																										<Link to={`/member/entries/${entry.id}/pay/${cost.categoryId}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																											<span className="sc_button_text">
																												<span className="sc_button_title">Pay Now</span>
																											</span>
																										</Link>
																									</div>
																								</Col>}
																							</Row>
																						</CardBody>
																					</Card>
																				</>;
																			})
																		}
																	</>
																))
															}
														</div>
														:<Col><ActivitySpinner /></Col>}
														{
															costs?.pending.length > 0 &&
															<div className="mt-5 mb-5">
																<h5>Summary</h5>
																<Card>
																	<CardBody>
																		<Row>
																			<Col className="my-auto">
																				<h6>All Categories</h6>
																			</Col>
																			<Col className="my-auto">
																				<h6 className="mb-0">{costs.pending.reduce((a, b) => a + b.teams, 0)}</h6>
																				<span className="small">Teams</span>
																			</Col>
																			<Col className="my-auto">
																				<h6 className="mb-0">{costs.pending.reduce((a, b) => a + b.players, 0)}</h6>
																				<span className="small">Players</span>
																			</Col>
																			<Col className="my-auto">
																				<h5 className="mb-0">{LocaleHelper.toPrice(costs.pending.reduce((a, b) => a + b.total, 0))}</h5>
																				<span className="small">Total</span>
																			</Col>
																			{entry.termsAccepted == true && registration.enablePayment == true && <Col xs={2}>
																				{entry.allowInvoicing && <div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																					<Link to={`/member/entries/${entry.id}/invoice`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																						<span className="sc_button_text">
																							<span className="sc_button_title">Invoice</span>
																						</span>
																					</Link>
																				</div>}
																				<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																					<Link to={`/member/entries/${entry.id}/pay`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																						<span className="sc_button_text">
																							<span className="sc_button_title">Pay All</span>
																						</span>
																					</Link>
																				</div>
																			</Col>}
																		</Row>
																	</CardBody>
																</Card>
															</div>
														}
													</TabPane>
													<TabPane tabId="Invoiced">
														{costs != null ? <div className="mt-5">
															{
																groupBy(costs.invoiced, (c) => c.categoryId).map((cg, index) => (
																	<>
																		{
																			cg.map((cost: CostFeedback) => {
																				return <>
																					<Card>
																						<CardBody>
																							<Row>
																								<Col className="my-auto">
																									<h6>{cost.category.category.name}</h6>
																								</Col>
																								<Col className="my-auto">
																									<h6 className="mb-0">{cost.teams}</h6>
																									<span className="small">Teams</span>
																								</Col>
																								<Col className="my-auto">
																									<h6 className="mb-0">{cost.players}</h6>
																									<span className="small">Players</span>
																								</Col>
																								<Col className="my-auto">
																									<h5 className="mb-0">{LocaleHelper.toPrice(cost.total)}</h5>
																									<span className="small">Total</span>
																								</Col>
																								<Col xs={2}>
																									<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																										<a onClick={() => setActiveTab("Invoices")} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																											<span className="sc_button_text">
																												<span className="sc_button_title">Invoices</span>
																											</span>
																										</a>
																									</div>
																								</Col>
																							</Row>
																						</CardBody>
																					</Card>
																				</>;
																			})
																		}
																	</>
																))
															}
														</div>:<Col><ActivitySpinner /></Col>}
													</TabPane>
													<TabPane tabId="Paid">
														{costs != null ?<div className="mt-5">

															{
																groupBy(costs.paid, (c) => c.categoryId).map((cg, index) => (
																	<>
																		{
																			cg.map((cost: CostFeedback) => {
																				return <>
																					<Card>
																						<CardBody>
																							<Row>
																								<Col className="my-auto">
																									<h6>{cost.category.category.name}</h6>
																								</Col>
																								<Col className="my-auto">
																									<h6 className="mb-0">{cost.teams}</h6>
																									<span className="small">Teams</span>
																								</Col>
																								<Col className="my-auto">
																									<h6 className="mb-0">{cost.players}</h6>
																									<span className="small">Players</span>
																								</Col>
																								<Col className="my-auto">
																									<h5 className="mb-0">{LocaleHelper.toPrice(cost.total)}</h5>
																									<span className="small">Total</span>
																								</Col>
																								<Col xs={2}>
																									<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																										<Link to={`/member/entries/${entry.id}/categories/${cost.categoryId}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																											<span className="sc_button_text">
																												<span className="sc_button_title">View</span>
																											</span>
																										</Link>
																									</div>
																								</Col>
																							</Row>
																						</CardBody>
																					</Card>
																				</>;
																			})
																		}
																	</>
																))
															}
														</div>
														:<Col><ActivitySpinner /></Col>}
													</TabPane>
													<TabPane tabId="Invoices">
														<div className="mb-4 memberships">
															<h6>Invoices</h6>
															<Row>
																{
																	invoices != null ? invoices.length > 0 && invoices.map(i => (
																		<Col xs={12}>
																			<Card className="mb-3 p-3 clickable" onClick={pay(i)}>
																				<Row>
																					<Col lg={5} className="d-flex align-items-center align-self-center">
																						<i className="far fa-file-invoice mr-2" title={i.reference} />
																						<div>
																							<h6 className="small m-0">Items</h6>
																							{i.items.map(p => (<p className="m-0" style={{borderBottom: "dashed 1px #aaa", padding: "0px 0"}} title={i.reference}>{p.title}</p>))}
																						</div>
																					</Col>
																					<Col lg={2}>
																						<h6 className="small m-0">Date</h6>
																						{moment(i.date).format("Do MMMM")}
																					</Col>
																					<Col lg={2}>
																						<h6 className="small m-0">Due</h6>
																						{moment(i.due).format("Do MMMM")}
																					</Col>
																					<Col lg={2}>
																						<h6 className="small m-0">Total</h6>
																						{LocaleHelper.toPrice(i.total)}<span className="small">+{LocaleHelper.toPrice(i.fee)} fee</span>
																					</Col>
																					<Col lg={1} className="justify-content-center align-self-center text-right">
																						{(moment(i.due).add(30, "days") <= moment() && i.status === InvoiceStatus.Pending) ?
																							<span title={moment(i.due).format()} className="status bg-danger text-white m-0"></span> :
																							<>
																								{i.status === InvoiceStatus.Paid && <span className="status bg-success mg-r-5 text-white"></span>}
																								{(i.status === InvoiceStatus.Pending || i.status === InvoiceStatus.Partial) && <span className="status bg-warning mg-r-5 text-white"></span>}
																								{i.status === InvoiceStatus.Failed && <span className="status bg-danger mg-r-5 text-white"></span>}
																								{i.status === InvoiceStatus.Void && <span className="status bg-danger mg-r-5 text-white"></span>}
																								{i.status === InvoiceStatus.Refunded && <span className="status bg-info mg-r-5 text-white"></span>}
																							</>
																						}
																					</Col>
																				</Row>
																				{(i.status === InvoiceStatus.Pending || i.status === InvoiceStatus.Failed || i.status === InvoiceStatus.Partial) && <Row>
																					<Col lg={2}>
																						<button onClick={(e) => {e.stopPropagation(); setInvoice(i);}} className="btn btn-secondary btn-rounded w-100">
																							View Ref#
																						</button>
																					</Col>
																				</Row>}
																			</Card>
																		</Col>
																	))
																	: <Col><ActivitySpinner /></Col>
																}
															</Row>
														</div>
													</TabPane>
												</TabContent>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
						<Modal className="modal--default" isOpen={invoice} toggle={() => setInvoice(null)}>
							<ModalHeader toggle={() => setInvoice(null)}>Invoice Reference</ModalHeader>
							<ModalBody>
								<Col>
									<Row>
										<p>Please contact registration admins and provide the following invoice reference to complete payment.</p>
									</Row>
									<Row>
										<Col>
											<h6>Invoice Total: {LocaleHelper.toPrice(invoice?.total ?? 0)}</h6>
											{invoice?.paid > 0 && <div>
												<h6>Transaction Total: {LocaleHelper.toPrice(invoice?.paid ?? 0)}</h6>
												<h6>Remaining Total: {LocaleHelper.toPrice((invoice?.total - invoice?.paid))}</h6>
											</div>}
										</Col>
									</Row>
									<Row>
										<h6>Invoice Ref#: {invoice?.reference}</h6>
									</Row>
								</Col>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-md btn-secondary btn-rounded" onClick={() => setInvoice(null)}>Close</button>
							</ModalFooter>
						</Modal>
					</Sidebar>)} />
		</PublicLayout>
	);
};