import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import classnames from "classnames";

import { AppConfig } from "@config";

import history from "@helpers/history.helper";
import * as moment from "moment";
import * as NProgress from "nprogress";

import { ManageLayout } from "@components/layouts/ManageLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { Toggle } from "@components/controls/Toggle";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import { ProgressButton, CircularProgressButton } from "@components/controls/ProgressButton";

import History from "@helpers/history.helper";

import { Member } from "@models/member";
import { Match, MatchGroup, MatchGrouping, MatchCard, MatchSet, MatchPlayer, MatchDoubles, MatchGame, MatchStatus, PotmType } from "@models/match";
import { Standing } from "@models/standing";

import { DatatableQuery, DatatableMeta } from "@models/query";
import { MemberService } from "@services/member.service";

import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { LeagueService } from "@services/league.service";
import { CompetitionService } from "@services/competition.service";
import { MatchService } from "@services/match.service";
import { VenueService } from "@services/venue.service";
import { TeamService } from "@services/team.service";
import { Competition, CreateCompetition, PlayerLabels } from "@models/competition";

import Sidebar from "react-sidebar";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { SelectOption } from "@models/forms";
import { Venue } from "@models/venue";

import { confirmAlert } from "react-confirm-alert"; // Import
import { DivisionService } from "@services/division.service";
import { CreateDivision, Division } from "@models/division";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { TeamMember } from "@models/team";
import { number, elementType } from "prop-types";
import { has } from "store2";
import { MatchEntrant, MatchMember } from "../../../models/match";

import * as Queue from "promise-queue";
import ObjectHelper from "@helpers/object.helper";
import { Can } from "@components/controls/Authentication";
import { Options } from "@data";
import { MatchCardBase, Doubles, Player } from "@components/shared/MatchCardBase";
import PublicLayout from "@components/layouts/PublicLayout";
import { MatchCardTemplatePage } from "./MatchCardTemplatePage";


export class MatchCardPage extends MatchCardBase {

	private doubleHeight: string = "90px";
	private singleHeight: string = "45px";
	hasWon(home: boolean, set: MatchSet): boolean {
		const won = set.completed && ((home && (set.homeScore > set.awayScore)) ||
			(!home && (set.awayScore > set.homeScore)));

		return won;
	}

	runningScore(set: MatchSet, home: boolean): string {
		var homeScore = 0;
		var awayScore = 0;
		var sets = this.state.matchCard.sets;
		var index = this.state.matchCard.sets.findIndex(s => s.id == set.id);
		for(let i = 0; i <= index; i++){
			var s = sets[i];
			if(s.completed){
				if(s.awayScore > s.homeScore){
					awayScore ++;
				}
				else if(s.homeScore > s.awayScore){
					homeScore ++;
				}
			}
		}
		

		return set.completed ? (home ? `${homeScore}` : `${awayScore}`) : ``;
	}

	playerName(p: MatchPlayer): string {
		return p.userId.length > 0 ? p.printoutName : "";
	}

	label(ordering:number, home:boolean, playerCount: number){
		var alpha = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
		var index = 0;
		if(this.state.competition.matchCard.playerLabels == PlayerLabels.Alphabetical) {
			if(home)
				index = ordering;
			else
				index = (26 - playerCount) + ordering;
			return alpha[index];
		}
		else {
			return `${home ? `H` : `A`} ${ordering + 1}`;
		}
	}

	public render() {
		const scratchSet = (
			<div className="sidebar">
				<div className="sidebar--header">
					<div className="sidebar--close"></div>
					<h6 className="slim-pagetitle">Scratch Set</h6>
				</div>
				<div className="sidebar--content mg-t-30">
					<form onSubmit={this.scratchSet.bind(this)}>
						{
							this.state.matchCard != null ?
								<div className="form-layout">

									<div className="row mg-b-25">
										<div className="col-lg-12">
											<div className="form-group">
												<label className="form-control-label">Team <span className="tx-danger">*</span></label>
												<Select
													options={[
														{ label: this.state.matchCard.match.home.displayName, value: this.state.matchCard.match.home.id },
														{ label: this.state.matchCard.match.away.displayName, value: this.state.matchCard.match.away.id }
													]}
													closeMenuOnSelect={true}
													onChange={this.handleScratchEntrantChange.bind(this)} />
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label className="form-control-label">Type <span className="tx-danger">*</span></label>
												<Select
													options={[
														{ label: "Walkover", value: 1 },
														{ label: "Conceded", value: 2 }
													]}
													closeMenuOnSelect={true}
													onChange={this.handleScratchTypeChange.bind(this)} />
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label className="form-control-label">Reason</label>
												<input className="form-control" type="text" name="name" onChange={e => this.setState({ scratchReason: e.currentTarget.value })} placeholder="Enter scratch reason" />
											</div>
										</div>
									</div>
									<div className="form-layout-footer">
										<button className="btn btn-primary btn-rounded btn-outline bd-0 mg-r-5">Update</button>
										<button className="btn btn-secondary btn-rounded btn-outline bd-0" onClick={this.cancel.bind(this)}>Cancel</button>
									</div>
								</div> : undefined
						}
					</form>
				</div>
				<div className="sidebar--footer">

				</div>
			</div>);

		const handicaps = (
			<div className="sidebar">
				<div className="sidebar--header">
					<div className="sidebar--close"></div>
					<h6 className="slim-pagetitle">Team Handicaps</h6>
				</div>
				<div className="sidebar--content mg-t-30">
					<form onSubmit={this.updateTeamHandicaps.bind(this)}>
						{
							this.state.matchCard != null ?
								<div className="form-layout">

									<div className="row mg-b-25">
										<div className="col-lg-12">
											<div className="form-group">
												<label className="form-control-label">Home Handicap</label>
												<input type="number" className={`d-inline form-control`} title={`Home Handicap`} value={this.homeHandicap} onChange={e => {
													var handicap = e != null && e.currentTarget.value.length > 0 ? parseInt(e.currentTarget.value) : null
													this.homeHandicap = handicap
													this.setState({matchCard: {...this.state.matchCard, match: {...this.state.matchCard.match, homeHandicap: handicap}}})}} />
											</div>
										</div>
										<div className="col-lg-12">
											<div className="form-group">
												<label className="form-control-label">Away Handicap</label>
												<input type="number" className={`d-inline form-control`} title={`Away Handicap`} value={this.awayHandicap} onChange={e => {
													console.log(e)
													var handicap = e != null && e.currentTarget.value.length > 0 ? parseInt(e.currentTarget.value) : null
													this.awayHandicap = handicap
													this.setState({matchCard: {...this.state.matchCard, match: {...this.state.matchCard.match, awayHandicap: handicap}}})}} />
											</div>
										</div>
									</div>
									<div className="form-layout-footer">
										<button className="btn btn-primary btn-rounded btn-outline bd-0 mg-r-5">Save</button>
										<button className="btn btn-secondary btn-rounded btn-outline bd-0" onClick={this.cancel.bind(this)}>Cancel</button>
									</div>
								</div> : undefined
						}
					</form>
				</div>
				<div className="sidebar--footer">

				</div>
			</div>);

		let sidebar;

		if(this.state.set != null){
			sidebar = scratchSet;
		}

		if(this.state.editHandicaps){
			sidebar = handicaps;
		}

		return (
			<PublicLayout className="page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout"
				print={
					() => (
						this.state.matchCard ? <MatchCardTemplatePage match={{ params: { matchId: this.state.matchCard.match.id, divisionId: this.state.matchCard.match.divisionId, competitionId: this.state.matchCard.match.competitionId } }} />
						: undefined
					)
				}>
				<div className={`loading-overlay ${this.state.updating ? "d-block" : "d-none"}`}>
					<ActivitySpinner mode="overlay" />
				</div>
				<Sidebar
					sidebar={sidebar}
					pullRight={true}
					open={this.state.set != null || this.state.editHandicaps}
					sidebarClassName="sidebar-root"
					styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible", zIndex: 999 }, sidebar: { position: "fixed", background: "white" } }}>
					<ShowMe
						visible={!this.state.loading}
						progress={true}
						isEmpty={this.state.competition == null}
						emptyText="Invalid Match"
						mode={ShowMeMode.Full}
						render={() => (
							<div className="sub-page">
								<div className="pt-4 pb-4">
									<this.Entry />
								</div>
							</div>
						)} />
				</Sidebar>
				{<this.Modals />}
			</PublicLayout>);
	}
}