import * as React from "react";
import { Helmet } from "react-helmet";

import * as NProgress from "nprogress";
import * as moment from "moment";
import { Row, Col, Card } from "reactstrap";

import { toast } from "react-toastify";

import { MembershipService } from "@services/membership.service";
import StateHelper from "@helpers/state.helper";

import { loadStripe } from "@stripe/stripe-js";
import { StripeService } from "@services/stripe.service";
import PublicLayout from "@components/layouts/PublicLayout";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { InvoiceService } from "@services/invoice.service";
import { Invoice, InvoiceStatus, InvoiceType, TransactionType } from "@models/invoice";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { LoginService } from "@services/login.service";
import { Link } from "react-router-dom";

import History from "@helpers/history.helper";
import { AppConfig } from "@config";
import { ProgressButton } from "@components/controls/ProgressButton";
import { AppCache } from "@cache";
import { ContactService } from "@services/contact.service";
import { MessageService } from "@services/message.service";
import { CreateMessage } from "@models/message";

const stripePromise = () => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId });

interface IPayPageProps {
	site?: string;
	match?: any;
}

interface IPayPageState {
	loading: boolean;
	nextPathname?: any;
	submitting: boolean;
	secret?: string;
	invoice?: Invoice;
	active: boolean;
}

const CheckoutForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		event.preventDefault();

		props.onSubmit();

		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement)
		});

		if (paymentMethod) {
			try {
				var status = await InvoiceService.pay(props.reference, TransactionType.Web, props.value, paymentMethod.id);

				if (status === InvoiceStatus.Failed) {
					toast.error("Payment failed. Please retry");
				}
				else {
					History.push(`/pay/confirmation/${props.reference}`);
				}
			} catch (error) {
				toast.error(error.message ?? "Something went wrong");
				props.onError();
			}

			props.onComplete();
		} else {
			toast.error("Payment details missing");
			props.onError();
		}
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<form className="stripe" onSubmit={handleSubmit}>
			<div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>
			<ProgressButton className="btn btn-secondary btn-rounded w-100" submitForm={true} loading={props.submitting}>
				Pay
			</ProgressButton>
		</form>
	);
};

export class PayPage extends React.Component<IPayPageProps, IPayPageState> {

	private reference?: string;

	constructor(props) {
		super(props);

		this.reference = (this.props.match.params as any).reference as string;
		this.state = { loading: true, submitting: false, active: false };
	}

	async componentDidMount() {
		try {
			if (LoginService.isAuthenticated) {
				const contact = await ContactService.getByUser();
				const invoice = await InvoiceService.getByReference(this.reference);

				if (invoice.contactId === contact.id) {
					this.setState({ invoice, active: true, loading: false });
				} else {
					History.push({ pathname: `/login`, state: { prevPath: `/pay/${this.reference}` } });
				}
			} else {
				History.push({ pathname: `/login`, state: { prevPath: `/pay/${this.reference}` } });
			}
		} catch (error) {
			History.push("/");
		}
	}

	public render() {
		return (
			<PublicLayout className="sub-page">
				<Helmet />
				<ShowMe
					visible={!this.state.loading}
					progress={true}
					mode={ShowMeMode.Full}
					render={() =>
					(
						<div>
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			{
																				this.state.invoice.status === InvoiceStatus.Paid &&
																				<>
																					<h1 className="sc_layouts_title_caption">Paid Successfully</h1>
																					<p className="header--description">{moment(this.state.invoice.updated).format("Do MMMM")}</p>
																				</>
																			}
																			{
																				this.state.invoice.status !== InvoiceStatus.Paid &&
																				<>
																					<h1 className="sc_layouts_title_caption">Payment</h1>
																					<p className="header--description">{moment(this.state.invoice.due).format("Do MMMM")}</p>
																				</>
																			}
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													{
														this.state.invoice.status === InvoiceStatus.Paid &&
														<>
															<h1 className="sc_layouts_title_caption">Paid Successfully</h1>
															<p className="header--description">{moment(this.state.invoice.updated).format("Do MMMM")}</p>
														</>
													}
													{
														this.state.invoice.status !== InvoiceStatus.Paid &&
														<>
															<h1 className="sc_layouts_title_caption">Payment</h1>
															<p className="header--description">{moment(this.state.invoice.due).format("Do MMMM")}</p>
														</>
													}
												</div>
											</div>
										</div>
									</div>
							}
							{
								this.state.invoice.status !== InvoiceStatus.Paid &&
								<>
									<div className="page_content_wrap">
										<div className="container">
											<div className="content">
												<div className="membership mg-t-20">
													<Row>
														<Col md={{ size: 6, offset: 3 }}>
															<Card className="mb-5 p-4">
																{
																	this.state.invoice.items.map(item => (
																		<Row>
																			<Col>
																				<h6 className="m-0">{item.title}</h6>
																				<p className="m-0">
																					{item.booking && <span>{item.booking.title}</span>}
																					{item.membership && <span>{item.membership.name}</span>}
																					{item.session && <span>{item.session.event.title}</span>}
																				</p>
																			</Col>
																			<Col className="text-right">
																				£{item.total.toFixed(2)}
																			</Col>
																		</Row>
																	))
																}
															</Card>
															<Row>
																<Col className="text-center">
																	{this.state.invoice.paid > 0 && <span className="price">Total: £{this.state.invoice.total.toFixed(2)}</span>}
																	{this.state.invoice.paid > 0 && <p className="price">Transactions: - £{this.state.invoice.paid.toFixed(2)}</p>}
																	<h2 className="price">£{this.state.invoice.paid > 0 ? (this.state.invoice.total - this.state.invoice.paid).toFixed(2) : this.state.invoice.total.toFixed(2)}</h2>
																</Col>
															</Row>
															<Row>
																<Col>
																	<Elements stripe={stripePromise()}>
																		<CheckoutForm
																			submitting={this.state.submitting}
																			secret={this.state.secret}
																			reference={this.reference}
																			value={this.state.invoice.paid > 0 ? (this.state.invoice.total - this.state.invoice.paid) : null}
																			onSubmit={e => this.setState({ submitting: true })}
																			onComplete={e => this.setState({ submitting: false })}
																			onError={e => this.setState({ submitting: false })}
																		/>
																	</Elements>
																</Col>
															</Row>
															<Row className="mt-4 text-center">
																<Col>
																	<img title="Powered by Stripe" width={"200px"} src={require("../../../../assets/images/misc/stripe-plurple.png")} />
																</Col>
															</Row>
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</div>
								</>
							}
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
