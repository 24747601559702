import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DelayInput } from "react-delay-input";
import { Options } from "@data";
import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as moment from "moment";
import { SelectOption } from "@models/forms";
import ObjectHelper from "@helpers/object.helper";
import { Format } from "@models/format";
import { ArrayHelper } from "@helpers/array.helper";
import { FormatService } from "@services/format.service";
import { Competition, CompetitionDay } from "@models/competition";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker";
import TimePicker from "rc-time-picker";
import { Division } from "@models/division";
import { BookingService } from "@services/booking.service";
import { Booking, BookingResource, BookingSlot, CalendarConflict, ContactCalendarTimeframe, CreateBooking } from "@models/booking";
import { MemberService } from "@services/member.service";
import { toast } from "react-toastify";
import { LoginService } from "@services/login.service";
import { Contact, ContactLink } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { ProgressButton } from "@components/controls/ProgressButton";
import { AppConfig } from "@config";
import { AppCache } from "@cache";
import History from "@helpers/history.helper";
import { InvoiceStatus } from "@models/invoice";
import { Constants } from "@consts";

import { Toggle } from "@components/controls/Toggle";
import { CreateConsentAccept } from "@models/consent";
import { CreateMessage } from "@models/message";

import { MessageService } from "@services/message.service";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ResourceHelper } from "@helpers/resource.helper";
import { BasketService } from "@services/basket.service";
import { Basket, BasketItem } from "@models/basket";
import { WebsiteContext } from "@context/BasketContext";
import { AgendaConflictAlert } from "@components/controls/AgendaConflictAlert";


const StripeContainer = (props) => {
	const [stripePromise] = useState(() => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId }));

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm booking={props.booking} onSubmit={props.onSubmit} onComplete={props.onComplete} onError={props.onError} submitting={props.submitting} payLater={props.payLater} onBookLater={props.onBookLater} />
		</Elements>
	);
};

interface ICheckoutFormProps {
	booking: CreateBooking;
	payLater: boolean;
	onSubmit?: () => void;
	onComplete?: () => void;
	onError?: () => void;
	onBookLater?: () => void;
	submitting: boolean;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = () => async (event: any) => {
		event.preventDefault();

		try {
			validate();

			props.onSubmit();

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement)
			});

			if (paymentMethod) {
				try {
					const booking = props.booking;
					booking.paymentMethodId = paymentMethod.id;
					const confirmation = await BookingService.book(props.booking);

					if ((confirmation.invoice?.status ?? InvoiceStatus.Paid) === InvoiceStatus.Failed) {
						await toast.error("Payment failed. Please retry");
						History.push(`/pay/${confirmation.invoice.reference}`);

					} else {
						toast.success("Successfully Booked");
						History.push(`/bookings/confirmation/${confirmation.invoice.reference}`);
					}
					props.onComplete();

				} catch (error) {
					toast.error(error.message);
					props.onError();
				}

				props.onComplete();
			} else {
				toast.error("Payment details missing");
				props.onError();
			}
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const handleBookClick = async () => {
		const confirmation = await BookingService.book(props.booking);
		
		toast.success("Successfully Booked");
		History.push(`/bookings/confirmation/${confirmation.invoice.reference}`);
	}

	const handleBookLaterClick = () => {
		props.onBookLater();
	}

	const validate = () => {
		return true;
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<div className="stripe">
			<div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>
			<Row>
				<Col xs={12}>
					<ProgressButton onClick={handleSubmit()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
						Book and Pay Now
					</ProgressButton>
				</Col>
				{
					props.payLater &&
					<Col xs={12} className="mb-3">
						<ProgressButton onClick={handleBookClick} className="mt-3 btn btn-primary btn-rounded w-100" loading={props.submitting}>
							Book and Pay Later
						</ProgressButton>
					</Col>
				}
				<Col xs={12} className="mb-3">
					<ProgressButton onClick={handleBookLaterClick} className="btn btn-primary btn-rounded w-100" loading={props.submitting}>
						Book Later
					</ProgressButton>
				</Col>
			</Row>
		</div>
	);
};

interface IBookingSlotSidebar {
	slot: BookingSlot;
	slots: BookingSlot[];
	onClose?: () => void;
	onConfirm?: (booking?: Booking) => void;
}

export default (props: IBookingSlotSidebar) => {
	const [startSlot, setStartSlot] = useState<BookingSlot>(props.slot);
	const [endSlot, setEndSlot] = useState<BookingSlot>();
	const [availableSlots, setAvailableSlots] = useState<BookingSlot[]>([]);
	const [resource, setResource] = useState<BookingResource>();
	const [attendees, setAttendees] = useState<number[]>([]);
	const [price, setPrice] = useState<number>();
	const [contact, setContact] = useState<Contact>();
	const [contacts, setContacts] = useState<ContactLink[]>([]);
	const [booking, setBooking] = useState<CreateBooking>();
	const [submitting, setSubmitting] = useState<boolean>();
	const [checkedState, setCheckedState] = useState<any>();
	const [consentsNotAccepted, setConsentsNotAccepted] = useState<any[]>([]);
	const [openModalBool, setOpenModalBool] = useState<boolean>();
	const [conflicts, setConflicts] = React.useState<CalendarConflict[]>([]);

	const { setBasket } = useContext(WebsiteContext);

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		if (endSlot) {
			getPrice();
			checkAgendaConflicts([{ contactId: contact.id, start: startSlot.start, end: endSlot.end }]);
		} else {
			setPrice(undefined);
			setConflicts([]);
		}
	}, [endSlot]);

	useEffect(() => {
		openModal();
	}, [consentsNotAccepted]);

	const load = async () => {
		const contact = await ContactService.getByUser();
		const contacts = await ContactService.getLinked(contact.id);

		setContact(contact);
		setContacts(contacts);
	};

	const checkAgendaConflicts = async (toCheck: ContactCalendarTimeframe[]) => {
		NProgress.start();
		ContactService.checkAgendaConflicts(toCheck).then((result) => {
			setConflicts(result);
		}).finally(() => NProgress.done());
	}

	const loadSlots = () => {
		let available = [];

		props.slots
			.filter(s => !resource || s.resources.find(r => r.id === resource.id)?.available)
			.filter(s => s.available && s.start >= startSlot.start)
			.some((e, i, a) => {
				available.push(e);

				const next = a[i + 1];

				// make sure they are in category and time order
				const found = next && e.order < (next.order - 1);
				return found;
			});

		if (props.slot.rule.minSlots) {
			available = available.filter((a, i) => i >= (props.slot.rule.minSlots - 1));
		}

		if (props.slot.rule.maxSlots) {
			const max = props.slot.rule.maxSlots - ((props.slot.rule.minSlots ?? 1 - 1) ?? 0);
			available = available.filter((a, i) => i < max);
		}

		ContactService.getByUser().then(contact => {
			setContact(contact);
		});

		setAvailableSlots(available);
	}

	const openModal = () => {

		if (consentsNotAccepted[0] !== undefined) {
			setOpenModalBool(true);
		}

	}

	const closeModal = () => (e: any) => {
		setOpenModalBool(false);
	}

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const createBooking = (): CreateBooking => {
		const book = new CreateBooking();
		book.bookeeId = contact.id;
		book.ruleId = startSlot.rule?.id;
		book.spaceId = startSlot.spaceId;
		book.startDate = startSlot.start;
		book.endDate = endSlot.end;
		book.resourceId = resource?.id;
		book.attendees = attendees;
		book.categoryId = resource.categoryId;
		book.slots = (endSlot.order - startSlot.order) + 1;

		return book;
	}

	const handleConfirm = async (e: any) => {
		const booking = createBooking();

		if (price > 0) {
			booking.reservation = true;
		}

		setSubmitting(true);
		const result = await BookingService.book(booking);

		if (result) {
			const item = new BasketItem();
			item.expires = result.expires;
			item.name = result.title;
			item.bookingId = result.id;
			item.contactId = result.contactId;
			item.total = result.total;
			item.date = result.startDate;

			if (price > 0) {
				const basket = await BasketService.add(item);
				setBasket({ ...basket });
			}
		}

		setSubmitting(false);

		if (price === 0) {
			toast.success("Successfully Booked");
		} else {
			toast.success("Added to Basket - Expires in 30 minutes", {
				onClick: () => History.push("/basket")
			});
		}

		props.onConfirm(result);
		props.onClose();
	};

	const handlePayNow = (e) => {
		const booking = createBooking();
		setBooking(booking);
	}

	const handleSelectEndSlot = () => (e: any) => {
		setEndSlot(e);
	};

	const getPrice = async () => {
		NProgress.start();
		const price = await BookingService.getPrice(resource.categoryId, startSlot.spaceId, (endSlot.order - startSlot.order) + 1, attendees);
		setPrice(price);

		NProgress.done();
	};

	const handleOnChange = (position) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === position ? !item : item
		);
		setCheckedState(updatedCheckedState);
	};

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Booking Slot
					<div className="help-container">
						<button className="btn p-0 tx-20" onClick={props.onClose}>
							<i className="far fa-times"></i>
						</button>
					</div>
				</h6>
			</div>
			<div className="sidebar--content">
				{
					startSlot.rule.cancellationType != null &&
					<Row>
						<Col>
							<div className="alert alert-info alert-rounded">
								<span>{ResourceHelper.getCancellationMessage(startSlot.rule.cancellationType)}</span>
							</div>
						</Col>
					</Row>
				}
				{
					contact &&
					<>
						{
							!contact.completed && AppCache.tenant.config.booking.completeProfileRequired &&
							<Row className="mb-5">
								<Col>
									<Link tabIndex={0} to={`/member/profile`} className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block text-white">Complete Profile</Link>
								</Col>
							</Row>
						}
						{
							(contact.completed || !AppCache.tenant.config.booking.completeProfileRequired) &&
							<Row>
								<Col>
									<FormGroup>
										<Label>Attendees <span className="tx-danger">*</span></Label>
										<Select
											options={[...contacts.map(c => c.contact), contact]}
											closeMenuOnSelect={true}
											isMulti
											isClearable
											getOptionLabel={e => e.name !== "" ? e.name : e.fullName}
											getOptionValue={e => e.id}
											onChange={e => setAttendees(e ? e.map(e => e.id) : [])} />
									</FormGroup>
									<FormGroup>
										<Label>Resources <span className="tx-danger">*</span></Label>
										<Select
											isClearable
											isSearchable={false}
											closeMenuOnSelect={true}
											options={startSlot.resources.filter(r => r.available)}
											getOptionLabel={e => e.name}
											getOptionValue={e => e.id}
											onChange={e => {
												setResource(e);
												loadSlots();
											}} />
									</FormGroup>
									{
										resource &&
										<FormGroup>
											<Label>End <span className="tx-danger">*</span></Label>
											<Select
												options={availableSlots}
												isClearable
												isSearchable={false}
												getOptionLabel={e => moment(e.end).format("HH:mm")}
												getOptionValue={e => e.order}
												onChange={handleSelectEndSlot()}
												closeMenuOnSelect={true} />
										</FormGroup>
									}
								</Col>
							</Row>
						}
						{
							conflicts.length > 0 &&
							<Row className="mt-3">
								<Col>
									<AgendaConflictAlert conflicts={conflicts} />
								</Col>
							</Row>
						}
						{
							price !== undefined && price > 0 &&
							<Row className="mt-3 text-center">
								<Col className="text-center">
									<h2 className="m-0">£{price}</h2>
									<span>Total</span>
								</Col>
							</Row>
						}
						{
							price === 0 &&
							<Row className="mt-3 mb-3 text-center">
								<Col className="text-center">
									<h2 className="m-0">FREE</h2>
								</Col>
							</Row>
						}
					</>
				}
				{
					!LoginService.isAuthenticated &&
					<Row>
						<Col>
							<Link tabIndex={0} to={"/login"} className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block text-white">Login</Link>
						</Col>
						<Col>
							<Link tabIndex={0} to={"/register"} className="btn btn-primary btn-rounded btn-outline bd-0 btn-block text-white">Register</Link>
						</Col>
					</Row>
				}
			</div>
			<div className="sidebar--footer">
				{
					LoginService.isAuthenticated &&
					<>
						{
							booking && price !== undefined && price > 0 &&
							<Row>
								<Col>
									<StripeContainer
										booking={booking}
										payLater={startSlot.rule.payLater}
										onSubmit={e => setSubmitting(true)}
										onComplete={e => { props.onConfirm(); props.onClose(); }}
										onError={e => setSubmitting(false)}
										onBookLater={e => setBooking(null)}
										submitting={submitting} />
								</Col>
							</Row>
						}
						{
							((price === 0 && submitting) || (!booking && contact && (contact.completed || !AppCache.tenant.config.booking.completeProfileRequired))) &&
							<Row className="mt-2">
								{
									price !== undefined &&
									<Col xs={12} className="mb-3">
										<ProgressButton className="btn btn-primary btn-rounded btn-outline bd-0 btn-block" loading={submitting} onClick={handleConfirm}>
											{price > 0 ? "Add to Basket" : "Confirm"}
										</ProgressButton>
									</Col>
								}
								{
									price > 0 &&
									<Col>
										<button className="btn btn-primary btn-rounded btn-outline bd-0 btn-block" disabled={submitting} onClick={handlePayNow}>Book Now</button>
									</Col>
								}
								<Col>
									<button className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block" type="button" disabled={submitting} onClick={handleClose()}>Cancel</button>
								</Col>
							</Row>
						}
					</>
				}
			</div>
		</div>
	);
};