import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DelayInput } from "react-delay-input";
import { Options } from "@data";
import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import * as moment from "moment";
import { SelectOption } from "@models/forms";
import ObjectHelper from "@helpers/object.helper";
import { Format } from "@models/format";
import { ArrayHelper } from "@helpers/array.helper";
import { FormatService } from "@services/format.service";
import { Competition, CompetitionDay } from "@models/competition";
import DayPickerInput from "react-day-picker/DayPickerInput";
import TimePicker from "rc-time-picker";
import { Division } from "@models/division";
import { BookingService } from "@services/booking.service";
import { Booking, BookingResource, BookingSpace } from "@models/booking";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { Standing } from "@models/standing";
import { Link } from "react-router-dom";
import { Average, DoublesAverage } from "@models/average";


interface IDivisionAverages {
	averages: Average[];
	dAverages: DoublesAverage[];
	pairs: boolean;
	teams: boolean;
	division: Division;
	competition: Competition;
}

export default (props: IDivisionAverages) => {
	const [competition, setCompetition] = useState<Competition>(props.competition);
	const [division, setDivision] = useState<Division>(props.division);
	const [averages, setAverages] = useState<Average[]>(props.averages);
	const [dAverages, setDAverages] = useState<DoublesAverage[]>(props.dAverages);
	const [pairs, setPairs] = useState<boolean>(props.pairs);
	const [teams, setTeams] = useState<boolean>(props.teams);

	if (averages.some(s => props.averages.every(p=> p.entrantId != s.entrantId ))){
		setAverages(props.averages)
	}

	if (dAverages.some(s => props.dAverages.every(p=> p.entrantId != s.entrantId ))){
		setDAverages(props.dAverages)
	}

	if(pairs != props.pairs){
		setPairs(props.pairs);
	}

	if(teams != props.teams){
		setTeams(props.teams);
	}
	
	return (
		<div className="standings w-100">
			<div>
				<div className="table-responsive">
					<div>
						<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" id="DataTables_Table_0" role="grid">
							<thead>
								<tr role="row">
									<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Player">Player</th>
									<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team"> Team</th>
									{!teams ? <th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team">Club</th> : undefined}
									<th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="P">P</th>
									<th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="W">W</th>
									<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">%</th>
								</tr>
							</thead>
							<tbody>
								{averages.map(s => {
									return (
										<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
											<td className="data-name" data-label="Name"><Link to={`/league/${props.competition.id}/player/${s.userId}`}>{s.name}{s.reserve && <i className="far fa-arrow-circle-up text-info ml-1"/>}</Link></td>
											<td className="data-team" data-label="Team"><Link to={`/league/${props.competition.id}/team/${s.teamId}`}>{s.team}</Link></td>
											{!teams ? <td className="data-team" data-label="Team"><Link to={`/club/${s.clubId}`}>{s.club}</Link></td> : undefined}
											<td className="data-p" data-label="P">{s.played}</td>
											<td className="data-w" data-label="W">{s.won}</td>
											<td className="data-pts" data-label="Pts">{s.percentage}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" id="DataTables_Table_0" role="grid">
							<thead>
								<tr role="row">
									<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Player">Players</th>
									<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team"> Team</th>
									{!pairs ? <th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team">Club</th> : undefined}
									<th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="P">P</th>
									<th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="W">W</th>
									<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">%</th>
								</tr>
							</thead>
							<tbody>
								{dAverages.map(s => {
									return (
										<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
											<td className="data-name" data-label="Name"><Col>
												<Row><Link to={`/league/${props.competition.id}/player/${s.userOneId}`}>{s.nameOne}</Link></Row>
												<Row><Link to={`/league/${props.competition.id}/player/${s.userTwoId}`}>{s.nameTwo}</Link></Row>
											</Col></td>
											<td className="data-team" data-label="Team"><Link to={`/league/${props.competition.id}/team/${s.teamId}`}>{s.team}</Link></td>
											{!pairs ? <td className="data-team" data-label="Team"><Link to={`/club/${s.clubId}`}>{s.club}</Link></td> : undefined}																							<td className="data-p" data-label="P">{s.played}</td>
											<td className="data-w" data-label="W">{s.won}</td>
											<td className="data-pts" data-label="Pts">{s.percentage}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};