import * as React from "react";

import Select from "react-select";
import { DatatableMeta } from "@models/query";
import { AppCache } from "@cache";
import { FilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";
import { timeStamp } from "console";

interface IPagingProps {
	onChangePageSize?: (pageSize: number) => void;
	onChangePage?: (pageNo: number) => void;
	meta?: DatatableMeta;
	paging?: PagedList<any>;
}

interface IPagingState {
	page: number;
	count: number;
	size: number;
}

export class Paging extends React.Component<IPagingProps, IPagingState> {

	constructor(props) {
		super(props);

		let page = 0;
		let count = 0;

		if (props.meta) {
			page = props.meta.paging.pageNo;
			count = props.meta.paging.pages;
		} else if (props.paging) {
			page = props.paging.page;
			count = props.paging.pages;
		}
		this.state = { page, count, size: AppCache.settings.pageSize };
	}

	componentDidMount() {
		//
	}

	componentDidUpdate(prevProps: IPagingProps, prevState: IPagingState) {
		if (prevProps.paging?.page !== this.props.paging?.page ||
			prevProps.paging?.size !== this.props.paging?.size ||
			prevProps.paging?.pages	!== this.props.paging?.pages)  {
			this.setState({ page: this.props.paging?.page, count: this.props.paging?.pages, size: this.props.paging?.size ?? AppCache.settings.pageSize });
		} else if (prevProps.meta?.paging?.pages !== this.props.meta?.paging?.pages ||
			prevProps.meta?.paging?.pageNo !== this.props.meta?.paging?.pageNo) {
			this.setState({ page: this.props.meta.paging.pageNo, count: this.props.meta.paging.pages, size: this.props.meta.paging?.pageSize ?? AppCache.settings.pageSize });
		}
	}

	handleChangePageSize(e: any) {
		AppCache.settings = { ...AppCache.settings, pageSize: e.value as number };
		this.props.onChangePageSize(e.value);
	}

	handleChangePage(pageNo: number) {
		this.props.onChangePage(pageNo);
	}

	render() {
		const options = [
			{ value: 10, label: "10" },
			{ value: 25, label: "25" },
			{ value: 50, label: "50" },
			{ value: 100, label: "100" },
			{ value: null, label: "View All" }];

		return (
			<>
				{
					this.state.count > 0 && <div className="row">
						<div className="col-6 offset-3">
							<div className="pagination-wrapper">
								<ul className="pagination pagination-circle mg-b-0">
									{
										this.state.page > 1 ? <li className="page-item">
											<button onClick={this.handleChangePage.bind(this, this.state.page - 1)} className="page-link tx-bold text-primary" aria-label="Previous"><i className="fa fa-angle-left"></i></button>
										</li> : undefined
									}
									<li className="page-item"><a className="page-link page-link-middle tx-bold" href="#">{this.state.page} of {this.state.count}</a></li>
									{
										this.state.count > 1 && this.state.page < this.state.count ?
											<li className="page-item">
												<button onClick={this.handleChangePage.bind(this, this.state.page + 1)} className="page-link tx-bold text-primary" aria-label="Next"><i className="fas fa-angle-right"></i></button>
											</li> : undefined
									}
								</ul>
							</div>
						</div>
						{this.props.onChangePageSize != null && <div className="col-3">
							<div className="page-size-wrapper float-right">
								<Select
									styles={{
										control: (provided, state) => ({
											...provided,
											width: 110,
										})
									}}
									options={options}
									value={this.state.size === null ? options[4] : options.filter(o => o.value === (this.state.size))}
									closeMenuOnSelect={true}
									onChange={this.handleChangePageSize.bind(this)} /></div>
						</div>}
					</div>
				}
			</>);
	}
}
