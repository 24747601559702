import * as React from "react";
import * as NProgress from "nprogress";
import { Editor } from "@tinymce/tinymce-react";
import { DocumentService } from "@services/document.service";
import { AppConfig } from "@config";
import { AppCache } from "@cache";
import { ImageService } from "@services/image.service";
import Plugin from "../lib/tinymce/Plugin";
import config from "@components/lib/tinymce/config";

interface IEditorProps {
	disabled?: boolean;
	content?: string;
	menubar?: boolean;
	height?: number;
	onChange?: (value: string) => void;
	children?: any;
}

const fullToolbar = [
	{
		name: "history", items: ["undo", "redo"]
	},
	{
		name: "styles", items: ["styleselect"]
	},
	{
		name: "formatting", items: ["bold", "italic", "forecolor", "backcolor"]
	},
	{
		name: "alignment", items: ["alignleft", "aligncenter", "alignright", "alignjustify"]
	},
	{
		name: "indentation", items: ["numlist", "bullist", "outdent", "indent"]
	},
	{
		name: "insert", items: ["image", "media", "link", "inserttable"]
	},
	{
		name: "table", items: ["table"]
	},
	{
		name: "other", items: ["code", config.pluginId]
	}
];

const slimToolbar = [
	{
		name: "paste", items: ["pastetext"]
	},
	{
		name: "formatting", items: ["bold", "italic", "forecolor", "backcolor"]
	},
	{
		name: "insert", items: ["image", "media", "link", "inserttable"]
	},
	{
		name: "alignment", items: ["alignleft", "aligncenter", "alignright", "alignjustify"]
	},
	{
		name: "indentation", items: ["numlist", "bullist", "outdent", "indent"]
	},
	{
		name: "styles", items: ["styleselect"]
	},
	{
		name: "font", items: ["fontsizeselect"]
	},
	{
		name: "table", items: ["table"]
	},
	{
		name: "other", items: ["code", config.pluginId]
	}
];

export class VisualEditor extends React.Component<IEditorProps, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		height: 500,
		menubar: true
	};

	handleEditorChange(value: string) {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	public render() {
		Plugin();
		return (
			<Editor apiKey="023zs8s4b2evz6e50n6jpe3wqca1v7408pm0j1v3k32g0icn"
				disabled={this.props.disabled}
				onEditorChange={this.handleEditorChange.bind(this)}
				value={this.props.content ?? ""}
				init={{
					menubar: this.props.menubar,
					promotion: false,
					height: this.props.height,
					plugins: config.pluginId + " paste link image code imagetools lists table preview",
					toolbar: this.props.menubar ? fullToolbar : slimToolbar,
					table_sizing_mode: "relative",
					// mobile: {
					// 	plugins: ["lists", "autolink"],
					// 	toolbar: ["undo", "bold", "italic", "styleselect"]
					// },
  					browser_spellcheck: true,
					relative_urls: false,
					remove_script_host: false,
					imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions",
					images_upload_handler: (blobInfo, progress) => new Promise((resolve, reject) => {
						const blob = blobInfo.blob();

						console.log(blob);

						NProgress.start();

						ImageService.create({
							title: blobInfo.name(),
							base64: blobInfo.base64(),
							name: blobInfo.name(),
							size: blob.size,
							type: blob.type
						}).then(imageId => {
							NProgress.done();
							const url = `${AppConfig.baseUrl}image/${imageId}`;
							resolve(url);
						});
					}),
					file_picker_types: "file",
					file_picker_callback(callback, value, meta) {
						const input = document.createElement("input");
						input.setAttribute("type", "file");
						input.setAttribute("accept", "*");

						input.onchange = function (e: any) {
							const file = e.target.files[0];
							const reader = new FileReader();

							reader.onload = () => {
								const upload = reader.result.toString().replace(/^data:[a-z0-9-+./]+;base64,/, "");

								NProgress.start();

								DocumentService.create({
									size: file.size,
									title: file.name,
									name: file.name,
									type: file.type,
									base64: upload,
								}).then(slug => {
									NProgress.done();
									const url = `${AppConfig.baseUrl}download/${AppCache.tenant.code}/${slug}`;
									callback(url, { text: url });
								});
							};

							reader.readAsDataURL(file);
						};

						input.click();
					},
					paste_data_images: true,
				}}>
				{this.props.children}
			</Editor>
		);
	}
}
