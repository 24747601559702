import { Venue } from "./venue";
import { Member, MemberIdentity } from "./member";
import { Club } from "./club";
import { INumericKeyValuePair } from "./interfaces";
import { PlayerResult, PlayerGame, PlayerMatch } from "./player";
import { ResultType } from "./result";

export class Team {
	public id: number;
	public competitionId: number;
	public competition: string;
	public name: string;
	public description: string;
	public shortName: string;
	public club: Club;
	public clubId: number;
	public venueId: number;
	public venue: Venue;
	public night: number;
	public time?: Date;
	public captain: MemberIdentity;
	public captainId: number;
	public play: number;
	public badgeId?: number;
	public abbreviation: string;
	public ordering: number;
	public reservesOverride: boolean = false;
	public locked: boolean = false;
	public deleted?: Date;
	public displayName: string;
	public retentionId?: number;
	public pool: boolean;

	public members?: TeamMember[];
}

export class TeamMember {
	public memberId: string;
	public membershipNo?: number;
	public expired?: boolean;
	public incorrectMembership?: boolean;
	public name: string;
	public email: string;
	public teamId: number;
	public ordering: number;
	public transfer: boolean;
	public transfered: boolean;
	public transferTeam: string;
	public previousTeam: string;
}

export class TeamCaptain {
	public id: number;
	public firstName: string;
	public lastName: string;
	public displayName: string;
	public email: string;
	public mobile: string;
	public picture: string;
	public initials: string;
	public imageId?: number;
	public team: string;
	public teamId: number;
	public club: string;
	public competition: string;
}

export class CreateTeam {
	public name: string;
	public clubId: number;
	public teamId: number;
	public competitionId: number;
}

export class AssignTeamMember {
	public memberId: string;
	public teamId: number;
}

export class TeamResults {
	public userId: number;
	public name: string;
	public matchId: number;
	public divisionId: number;
	public team: INumericKeyValuePair;
	public versus: INumericKeyValuePair;
	public home: boolean;
	public date: Date;
	public games: PlayerGame[];
	public type: ResultType;
	public for: number;
	public against: number;
	public potm: string;
}

export class TeamPlayer {
	public id: string;
	public name: string;
	public played: number;
	public average: number;
	public won: number;
	public potm: number;
	public matches: PlayerMatch[];
}

export class TeamMatch {
	public id: number;
	public divisionId: number;
	public date: Date;
	public versus: INumericKeyValuePair; 
}

export class TeamStats {
	public id: string;
	public name: string;
	public shortName: string;

	public competition: INumericKeyValuePair;
	public division: INumericKeyValuePair;
	public club: INumericKeyValuePair;

	public played: number;

	public results: TeamResults[];
	public players: TeamPlayer[];

	public next: TeamMatch;
	public last: TeamMatch;

	constructor() {
		
	}
}

export class Overlap {
	public userId: string;

	public name: string;

	public teams: Team[];
}