import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";

interface IMedicalDetails {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IMedicalDetails) => {
	const [contact, setContact] = useState<Contact>(props.contact);
	const [agree, setAgree] = useState<boolean>(false);

	const [groups, setGroups] = useState<FieldGroup[]>([]);
	const [values, setValues] = useState<FieldValue[]>([]);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {

	};

	const update = () => async (e: any) => {
		e.preventDefault();

		NProgress.start();

		await ContactService.upsert(contact);
		NProgress.done();

		toast.success("Contact preferences updated", {});

		props.onConfirm();
	};

	return (
		<>
			
			<Card className="mb-5 p-5">
				<h6 className="m-0">Unsubscribe</h6>
				<Row>
					<Col>
						<FormGroup>
							<Label className="ml-4">
								<Input
									type="checkbox"
									className="inited d-block"
									checked={contact.unsubscribe ?? false}
									onChange={e => setContact({ ...contact, unsubscribe: e.currentTarget.checked ? moment().toDate() : null })} />
								No longer receive general communication emails
							</Label>
							<p className="small text-muted m-0">* You will still continue to receive emails when making bookings</p>
						</FormGroup>
					</Col>
				</Row>
			</Card>
			<Row>
				<Col>
					<div className="sp-view-all-link">
						<a onClick={update()}>
							Save
						</a>
					</div>
				</Col>
			</Row>
		</>
	);
};