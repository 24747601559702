import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardBody, CardHeader, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { CostFeedback, Entry, EntryPlayer, EntryStatus, EntryTeam } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { RegistrationService } from "@services/registration.service";
import EntryTeams from "./components/EntryTeams";
import { Registration, RegistrationCategory } from "@models/registration";

import RegisterTeamSidebar from "@components/sidebar/public/RegisterTeamSidebar";
import RegisterPlayerSidebar from "@components/sidebar/public/RegisterPlayerSidebar";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import Header from "./components/Header";



interface IEntryAgreementsPageProps {
	site?: string;
	match?: any;
	location?: any;
}

export default (props: IEntryAgreementsPageProps) => {
	const [loading, setLoading] = useState<boolean>(true);

	const [entryId, setEntryId] = useState<number>(props.match.params.entryId);
	const [entry, setEntry] = useState<Entry>();

	const [registration, setRegistration] = useState<Registration>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const entry = await EntryService.getById(entryId);
		setEntry(entry);

		const registration = await RegistrationService.getById(entry.registrationId);
		setRegistration(registration);

		setLoading(false);
	};

	const sidebar = () => {
		return <></>;
	};

	const openSidebar = () => {
		return false;
	};

	const handleAccept = () => {
		EntryService.acceptTerms(entryId).then(result => {
			load();
		});
	};

	return (
		<PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<Sidebar
						sidebar={sidebar()}
						pullRight={true}
						open={openSidebar()}
						sidebarClassName="sidebar-root"
						styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
						<div>
							<div className="page_content_wrap">
								<div className="container">
									<Row>
										<Col>
											<Header entry={entry} registration={registration} tab="Agreements" />
											<div>
												<h6>Terms and Conditions</h6>
												<Card>
													<CardBody>
														<span className="terms-title">{registration.name} Terms and Conditions</span>
														<div className="form-group mt-2">
															<label className="checkmark-container">I Agree
																<input type="checkbox" onChange={e => handleAccept()} checked={entry.termsAccepted} className="d-block form-check-input" />
																<span className="checkmark"></span>
															</label>
														</div>
														<a href={registration.link} className="btn btn-primary btn-rounded">View Terms</a>
													</CardBody>
												</Card>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Sidebar>)} />
		</PublicLayout>
	);
};