import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";
import { ImageUpload } from "@components/controls/ImageUpload";
import { AppConfig } from "@config";
import { VisualEditor } from "@components/controls/VisualEditor";

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { FacebookProvider, Page, Group } from "react-facebook";

import Select from "react-select";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactPlayer from "react-player";
import { SketchPicker } from "react-color";
import { ContactService } from "@services/contact.service";
import { Contact, CreateContact, CreateContactType } from "@models/contact";
import { AppCache } from "@cache";
import { Toggle } from "@components/controls/Toggle";

const DraggableSocialMedia = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [subTitle, setSubTitle] = useState(props.payload.subTitle);
	const [info, setInfo] = useState(props.payload.info);
	const [body, setBody] = useState(props.payload.body);
	const [layout, setlayout] = useState(props.payload.layout);

	const [orientation, setOrientation] = useState(props.payload.orientation ?? 2)
	const [displayFacebook, setDisplayFacebook] = useState(props.payload.facebook ?? true);
	const [displayTwitter, setDisplayTwitter] = useState(props.payload.twitter ?? true);

	const [editMode, setEditMode] = useState(false);
	const [setupMode, setSetupMode] = useState(props.payload.layout ? false : true);

	const getLayoutName = () => {
		switch (layout) {
			case 1:
				return "Twitter";
			case 2:
				return "Facebook";
			default:
				break;
		}
	};

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<div className="sc_layouts_widgets d-none d-md-block">
				{
					layout === 1 && AppCache.website.twitter &&
					<aside id="text-4" className="widget widget_text mb-4">
						<h5 className="widget_title">
							<i className="fab fa-x-twitter"></i>
						</h5>
						<div>
							<TwitterTimelineEmbed
								sourceType="profile"
								screenName={`${AppCache.website.twitter}`}
								options={{ height: 400 }}
							/>
						</div>
					</aside>
				}
				{
					layout === 2 && AppCache.website.facebook &&
					<aside id="text-4" className="widget widget_text p-20 mb-4">
						<h5 className="widget_title">
							facebook
						</h5>
						<div>
							<FacebookProvider appId="412401072952926">
								{AppCache.website.facebook.indexOf("groups") >= 0 && <Group href={`https://www.facebook.com/${AppCache.website.facebook}/`} />}
								{AppCache.website.facebook.indexOf("page") < 0 && <Page href={`https://www.facebook.com/${AppCache.website.facebook}/`} tabs="timeline" />}
							</FacebookProvider>
						</div>
					</aside>
				}
			</div>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, layout } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, layout } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	const handleLayoutConfirm = (layout: number) => (e: any) => {
		setlayout(layout);
		setSetupMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { layout } });
	};

	return (
		<>
			<Draggable {...props} >
				<Card className="clickable" onClick={() => setEditMode(true)}>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<span className="u-text-small">{getLayoutName()}</span>
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor element-editor--edit"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-outline btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-secondary btn-outline btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			<Modal className={"element-editor element-editor--setup"} isOpen={setupMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Row>
							<Col md={6}>
								<Card onClick={handleLayoutConfirm(1)}>
									<CardBody>
										<p className="section-title mt-0"><i className="fab fa-x-twitter"></i></p>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleLayoutConfirm(2)}>
									<CardBody>
										<p className="section-title mt-0">Facebook</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</FormGroup>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableSocialMedia;
