import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import RestHelper from "@helpers/rest.helper";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { County } from "@models/county";
import { CountyFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

import * as qs from "qs";


export class CountyService {

	constructor() {
		//
	}

	public static getById(id:number): Promise<County> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `counties/${id}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:County, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static data(query:DatatableQuery): Promise<DatatableResponse<County>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `counties/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({query}),
				success: (data:DatatableResponse<County>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static get(options: CountyFilterOptions): Promise<PagedList<County>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`counties`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static assign(countyId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`counties/${countyId}/assign`, {countyId, memberId}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static Unassign(countyId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`counties/${countyId}/unassign`, {countyId, memberId}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
