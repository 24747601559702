import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";

interface ILoginPageProps {
	site?: string;
	match?: any;
	location?: any;
}

interface ILoginPageState {
	loading: boolean;
	nextPathname?: any;
	email?: string;
	password?: string;
	submitting: boolean;
}

export class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
	constructor(props) {
		super(props);

		this.state = { loading: true, submitting: false };

		if (AppCache.tenant.clubId) {
			if (AppConfig.release !== ReleaseType.development) {
				const parsed = qs.parse(location.search);
				let returnUrl = "/";

				if (parsed.token) {
					LoginService.refresh(parsed.token as string)
						.then(result => {
							if(parsed.returnUrl) {
								returnUrl = parsed.returnUrl;
							}

							History.push(returnUrl as string);
						});
				} else {
					if (props.location.state && props.location.state.from) {
						returnUrl = props.location.state.from.pathname;
					}

					const saml = `${AppConfig.baseUrl}saml/login?returnUrl=${window.location.protocol}//${window.location.host}/login?returnUrl=${returnUrl}&tenantId=${AppCache.tenant.id}`;

					window.location.href = saml;
				}
			}
		}
	}

	componentDidMount() {
		if ((!AppCache.tenant.clubId && this.state.loading)  || AppConfig.release === ReleaseType.development) {
			this.setState({ loading: false });
		}
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	login(mode: LoginMode, e: any) {
		e.preventDefault();

		NProgress.start();
		this.setState({ submitting: true });

		const login = LoginService.login(this.state.email, this.state.password, mode)
			.then(result => MemberService.me())
			.then(result => {
				NProgress.done();
				this.setState({ submitting: false });

				if(this.props.location.state && this.props.location.state.from) {
					History.push(this.props.location.state.from.pathname);
				}
				else if (History.location.state && History.location.state.prevPath) {
					History.push(History.location.state.prevPath);
				} else {
					History.push("/");
				}
			})
			.catch(error => {
				const message = error && error.message ? error.message : "There was a problem logging into your account";
				toast.error(message);
				this.setState({ submitting: false });
				NProgress.done();
			});
	}

	public render() {
		const registrationUrl = AppConfig.release === ReleaseType.production ? "https://tabletennisengland.sport80.com/register" : "https://tabletennisengland.s80testing.co.uk/register";

		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Member Login</h1>
																			<p className="header--description">
																				{AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Sport80) && <p>Enter your Sport80 login details</p>}
																				{AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Internal) && <p>Enter your TT Leagues login details</p>}
																			</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">Member Login</h1>
													<p className="header--description">
														{AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Sport80) && <p>Enter your Sport80 login details</p>}
														{AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Internal) && <p>Enter your TT Leagues login details</p>}
													</p>
												</div>
											</div>
										</div>
									</div>
							}
							<section className="bg-white p-5">
								<div className="container">
									<div className="row">
										<div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
											<form className="login100-form validate-form">
												<div className="mb-3" data-validate="Valid email is required: ex@abc.xyz">
													<input className="w-100" type="text" name="email" placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.currentTarget.value })} />
												</div>
												<div className="mb-3" data-validate="Password is required">
													<input className="w-100" type="password" name="pass" placeholder="Password" value={this.state.password} onChange={e => this.setState({ password: e.currentTarget.value })} />
												</div>
												{
													AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Internal) &&
													<p className="mg-b-0 text-center">Forgot your password? <Link to={"forgot"}>Reset Password</Link></p>
												}
												{
													AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Sport80) ?
														<div className="m-3 sport80-login text-center">
															<button className="bg-secondary text-white sc_button sc_button_default w-100 text-center" onClick={this.login.bind(this, LoginMode.Sport80)}>Sign In</button>
															{AppCache.tenant.leagueId && <p className="mt-2 mg-b-0">Don't have an account? <a href={registrationUrl} target="_blank">Register</a></p>}
															{AppCache.tenant.clubId && <p className="mt-2 mg-b-0">Don't have an account? <Link to={"register"}>Register</Link></p>}
														</div> : undefined
												}
												{
													AppCache.tenant.organisation.loginModes.find(l => l === LoginMode.Internal) ?
														<div className="m-3 internal-login text-center">
															<button className="bg-secondary text-white sc_button sc_button_default w-100 text-center" onClick={this.login.bind(this, LoginMode.Internal)}>Sign In</button>
															{<p className="mt-2 mg-b-0">Don't have an account? <Link to={"register"}>Sign Up</Link></p>}

														</div> : undefined
												}
											</form>
										</div>
									</div>
								</div>
							</section>
						</div>)} />
			</PublicLayout>);
	}
}
