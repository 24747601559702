import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Team, TeamMember, CreateTeam } from "@models/team";

import store from "store2";

import * as moment from "moment";
import { SelectOption } from "@models/forms";

import { Media, CreateMedia } from "../models/media";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { CreateImage } from "@models/image";

export class ImageService {

	constructor() {
		//
	}

	public static create(image: CreateImage): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `image/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(image),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}
}
