import * as React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import * as moment from "moment";
import * as NProgress from "nprogress";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import PublicLayout from "@components/layouts/PublicLayout";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { PagedList } from "@models/paging";
import { EventService } from "@services/event.service";
import { Event, EventType } from "@models/event";
import LocaleHelper from "@helpers/locale.helper";
import { Website, WebsiteCustomMenuItem } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";

interface IEventListProps {
	site?: string;

}

interface IEventListState {
	loading: boolean;
	events?: PagedList<Event>;
	website?: Website;
	websiteMenu?: WebsiteCustomMenuItem;
	search?: string;
}
export class EventListPage extends React.Component<IEventListProps, IEventListState> {

	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		WebsiteService.updateActivity("Events");
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		EventService.get({ types: [EventType.OneOff, EventType.MultiDate], public: true }).then(events => {
			WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, 5).then(result => {
				this.setState({
					websiteMenu: result,
					events,
					loading: false
				}, () => {
					MiscHelper.scrollToTop();
				});
			});
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Events")}</h1>
																			<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available events")}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Events")}</h1>
													<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available events")}</p>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="pre-content">
										{
											this.state.websiteMenu?.body &&
											<Row>
												<Col>
													{ReactHtmlParser(this.state.websiteMenu.body)}
												</Col>
											</Row>
										}
									</div>
									<div className="content">
										<div className="memberships-list mg-t-20">
											{
												this.state.events.items.map(e => (
													<Card className="mb-4">
														<CardBody>
															<Row>
																<Col md={4} className="my-auto">
																	<h5 className="mb-0">{e.title}</h5>
																	<p className="mb-0">{e.summary}</p>
																</Col>
																<Col md={3} className="my-auto">
																	{
																		e.session && <>
																			<h6>Date</h6>
																			{e.session?.space && <div><i className="far fa-table-tennis mr-1"></i>{e.session.space.name}</div>}
																			<div><i className="far fa-calendar mr-1"></i>{moment(e.session?.startDate).format("dddd Do MMMM")}</div>
																			<div><i className="far fa-clock mr-1"></i>{moment(e.session?.startDate).format("HH:mm")}-{moment(e.session?.endDate).format("HH:mm")}</div>
																			{
																				(e.minAdvancedPeriod || e.session.minAdvancedPeriod) && e.session.startDate &&
																				<div><i className="far fa-stopwatch mr-1" />Book until {moment(moment(e.session.startDate).subtract(e.session?.minAdvancedPeriod ?? e.minAdvancedPeriod, "minutes").toDate()).format("DD/MM/YY H:mm a")}</div>
																			}
																			{e.limit != null && <div><i className="far fa-users mr-1"></i>Available: {e.session.available}/{e.limit}</div>}
																		</>
																	}
																	{
																		e.nextSession && <>
																			{e.type === EventType.OneOff && <h6>Date</h6>}
																			{e.type === EventType.MultiDate && <h6>Next Date</h6>}
																			{e.nextSession?.space && <div><i className="far fa-table-tennis mr-1"></i>{e.nextSession.space.name}</div>}
																			<div><i className="far fa-calendar mr-1"></i>{moment(e.nextSession?.startDate).format("dddd Do MMMM")}</div>
																			<div><i className="far fa-clock mr-1"></i>{moment(e.nextSession?.startDate).format("HH:mm")}-{moment(e.nextSession?.endDate).format("HH:mm")}</div>
																			{
																				(e.minAdvancedPeriod || e.nextSession.minAdvancedPeriod) && e.nextSession.startDate ?
																				<div><i className="far fa-stopwatch mr-1" />Book until {moment(moment(e.nextSession.startDate).subtract(e.nextSession?.minAdvancedPeriod ?? e.minAdvancedPeriod, "minutes").toDate()).format("DD/MM/YY H:mm a")}</div>
																				:
																				undefined
																			}
																			{e.limit != null && <div><i className="far fa-users mr-1"></i>Available: {e.nextSession.available}/{e.limit}</div>}
																		</>
																	}
																</Col>
																{e.memberPrice == null && <Col md={2}></Col>}
																<Col md={1} className={`p-0 my-auto text-center event-price public-price ${e.price != null && e.price < e.cost && "disabled-price"}`}>
																	{
																		e.cost != null &&
																		<>
																			<span className="small">&nbsp;</span>
																			{e.cost > 0 && <h4 className="membership-price m-0 text-nowrap">{LocaleHelper.toPrice(e.cost)}</h4>}
																			{e.cost === 0 && <h4 className="membership-price m-0">FREE</h4>}
																			{((e.price == null || e.memberPrice != null) || (e.price != null && e.price !== e.cost)) && <span className="small">Public</span>}
																		</>
																	}
																</Col>
																{
																	e.memberPrice != null &&
																	<Col md={2} className={`my-auto text-center event-price member-price ${((e.price != null && e.price === e.cost) || (LoginService.isAuthenticated && e.price == null)) && "disabled-price"}`}>
																		{
																			(e.price == null || e.price === e.cost) && e.memberPrice != null && <>
																				<span className="small">From</span>
																				{e.memberPrice <= 0 && <h4 className="membership-price m-0">FREE</h4>}
																				{e.memberPrice > 0 && <h4 className="m-0 text-nowrap">{LocaleHelper.toPrice(e.memberPrice)}</h4>}
																				<span className="small">
																					{e.cost == null ? "Members Only" : "Membership Cost"}
																				</span>
																			</>
																		}
																		{
																			(e.price != null && (e.cost == null || e.price < e.cost)) &&
																			<>
																				<span className="small">&nbsp;</span>
																				{(e.price === 0 || e.usage) && <h4 className="membership-price m-0">FREE</h4>}
																				{e.price > 0 && !e.usage && <h4 className="m-0 text-nowrap">{LocaleHelper.toPrice(e.price)}</h4>}
																				<span className="small">Membership</span>
																			</>
																		}
																	</Col>
																}
																<Col md={2} className="my-auto">
																	<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																		{
																			(!LoginService.isAuthenticated || e.price != null) &&
																			<Link to={`/events/${e.id}/join/${e.session ? e.session.id : ""}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																				<span className="sc_button_text">
																					<span className="sc_button_title">Book</span>
																				</span>
																			</Link>
																		}
																		{
																			(LoginService.isAuthenticated && e.price == null) &&
																			<Link to={`/memberships`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																				<span className="sc_button_text">
																					<span className="sc_button_title">Book</span>
																				</span>
																			</Link>
																		}
																	</div>
																</Col>
															</Row>
														</CardBody>
													</Card>
												))
											}
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
