import * as React from "react";
import { CalendarConflict } from "@models/booking";
import { AppConfig, EntryType } from "@config";
import * as moment from "moment";

interface IAgendaConflict {
	conflicts: CalendarConflict[] 
}

export class AgendaConflictAlert extends React.Component<IAgendaConflict, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		enabled: true,
		className: "btn"
	};

	componentDidMount() {
		//
	}

	componentWillUnmount() {
	}

	render() {
		return (
			<div className="alert alert-warning">
				<i className="fa fa-warning mr-2"></i>
				{`Selected date(s) conflict with the following event${this.props.conflicts.length > 1 ? "s" : ""} in ${AppConfig.entry == EntryType.public ? "your" : "contact's"} agenda:`}
				<ul>
					{
						this.props.conflicts.map(m =>
							<li>
								<div className="d-flex flex-column mb-2">
									<b>{m.name}</b>
									{`Start: ${moment(m.start).format("Do MMMM")} ${moment(m.start).format("HH:mm")} - End: 
									${moment(m.end).format("Do MMMM")} ${moment(m.end).format("HH:mm")}`}
								</div>
							</li>
						)
					}
				</ul>
			</div>
		);
	}
}
