import "../vendors";
import "../assets";

import "../../assets/scss/public.scss";

import * as React from "react";
import * as ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import { Router, Route } from "react-router";

import { Routes } from "@routes/PublicRoutes";

import { AppConfig, ReleaseType } from "@config";

import History from "@helpers/history.helper";
import { TenantService } from "@services/tenant.service";
import { AppCache } from "@cache";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import { LeagueService } from "@services/league.service";
import { WebsiteService } from "@services/website.service";
import { ClubService } from "@services/club.service";
import { CountyService } from "@services/county.service";
import { UserSettings } from "@models/user";
import * as Cookies from "js-cookie";
import * as qs from "query-string";
import { toast, ToastContainer } from "react-toastify";
import { WebsiteProvider } from "@context/BasketContext";

import * as Sentry from "@sentry/react";

interface IPublicState {
	loading: boolean;
}

export class Public extends React.Component<any, IPublicState> {

	constructor(props) {
		super(props);

		this.state = { loading: true };
	}

	async componentDidMount() {
		// reset cached elements
		AppCache.reset();

		if (window.location.hostname === AppConfig.hostUrl) {
			const parsed = qs.parse(location.search);

			if (parsed.host) {
				Cookies.remove("host", { path: "/", samesite: "none", secure: true, domain: `.${AppConfig.hostUrl}` });
				Cookies.set("host", parsed.host, { path: "/", samesite: "none", secure: true, domain: `.${AppConfig.hostUrl}` });
			} else {
				const host = Cookies.get("host");

				if (host) {
					window.location.href = `https://${host}/login`;
				} else {
					window.location.href = `https://www.${AppConfig.hostUrl}/`;
				}
			}
		} else {
			const p1 = TenantService.getByHost().then(tenant => {
				if (tenant) {
					AppCache.tenant = tenant;
				}
			}).catch(error => {
				window.location.href = `https://www.${AppConfig.hostUrl}/`;
			});

			const p2 = LoginService.refresh().then(token => {
				if (token) {
					LoginService.startTimer();
				}
			}).catch(error => {
				// just continue
			});

			const p4 = WebsiteService.getByHost().then(website => {
				AppCache.website = website;

				if (AppConfig.release !== ReleaseType.development) {
					setTimeout(() => {
						const config = [
							{ trackingId: AppConfig.analytics },
						  ];
	  
						  if (AppCache.website?.config?.googleAnalytics) {
							  config.push({ trackingId: AppCache.website?.config?.googleAnalytics });
						  }
					
						  ReactGA.initialize(config);
					}, 1000);
				}
			});

			Promise.all([p1, p2, p4]).then(result => {
				if (!AppCache.settings) {
					AppCache.settings = new UserSettings();
				}

				if (AppCache.tenant && !AppCache.tenant.isDefault) {
					MemberService.me().then(check => { });

					let p3;

					if (AppCache.tenant.leagueId) {
						p3 = LeagueService.getById(AppCache.tenant.leagueId).then(league => {
							AppCache.league = league;
						});
					} else if (AppCache.tenant.clubId) {
						p3 = ClubService.getById(AppCache.tenant.clubId).then(club => {
							AppCache.club = club;
						});
					} else if (AppCache.tenant.countyId) {
						p3 = CountyService.getById(AppCache.tenant.countyId).then(county => {
							AppCache.county = county;
						});
					}

					Promise.all([p3]).then(result => {
						this.setState({ loading: false });
					});
				} else {
					this.setState({ loading: false });
				}
			});
		}
	}

	render() {
		return (
			this.state.loading ? <div className="pong-loader"></div> :
				<>
					<WebsiteProvider>
						<ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
						<Routes />
					</WebsiteProvider>

				</>
		);
	}
}

Sentry.init({
	dsn: "https://d6c0b60997637cae15e97cef8185a7b7@o4506315542495232.ingest.sentry.io/4506315564449792",
	integrations: [
	  new Sentry.BrowserTracing({
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/ttleagues-api.azurewebsites\.net\/api/],
	  }),
	  new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

ReactDOM.render(<Public />, document.getElementsByTagName("tte-public")[0]);