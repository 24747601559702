import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";

import { GoogleMap, LoadScript, Marker, useLoadScript } from "@react-google-maps/api";

import { VenueService } from "@services/venue.service";
import { Venue } from "@models/venue";
import { AppConfig } from "@config";
import History from "@helpers/history.helper";
import Select from "react-select";

declare const google: any;

const DraggableMap = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [info, setInfo] = useState(props.payload.title);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setlayout] = useState(props.payload.layout);
	const [venueId, setVenueId] = useState<number>(props.payload.venueId);

	const [venues, setVenues] = useState<Venue[]>([]);

	const [bounds, setBounds] = useState(null);
	const [zoom, setZoom] = useState(11);
	const [fitToBounds, setFitToBounds] = useState(true);

	const [editMode, setEditMode] = useState(false);

	const [map, setMap] = React.useState(null);

	const { isLoaded } = useLoadScript({
		// Enter your own Google Maps API key
		googleMapsApiKey: AppConfig.googleApi
	});

	const [dynamicMapVisible, setDynamicMapVisible] = useState<boolean>(false);

	useEffect(() => {

		if (isLoaded && showPreview) {
			VenueService.get().then(result => {
				setVenues(result);
				const bounds = new google.maps.LatLngBounds();
				if (venueId) {
					const venue = result.find(r => r.id === venueId);
					bounds.extend(new google.maps.LatLng(venue.latitude, venue.longitude));
				} else {
					result.forEach((l, i) => {
						if (l.latitude && l.longitude) {
							const position = new google.maps.LatLng(l.latitude, l.longitude);
							bounds.extend(position);
						}
					});
				}
				setBounds(bounds);
			});
		}
		else {
			VenueService.get().then(result => {
				setVenues(result);
			});
		}
	}, [isLoaded]);

	useEffect(() => {
		if (isLoaded && bounds && map) {
			const listener = google.maps.event.addListener(map, "bounds_changed", function (event) {
				if (map.getZoom() > 15) {
					map.setZoom(15);
					setZoom(15);
				}
				google.maps.event.removeListener(listener);
			});

			map.fitBounds(bounds);
		}
	}, [bounds, map]);

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	const onScroll = () => {
		if(ref.current != null){
			var bounding = ref.current.getBoundingClientRect();
			var top = bounding.top - 500;
			var bottom = bounding.bottom + 500;
			if(top <= window.innerHeight && bottom >= 0){
				if(!dynamicMapVisible){
					setDynamicMapVisible(true);
				}
			}
			else{
				if(dynamicMapVisible){
					setDynamicMapVisible(false);
				}
			}
		}
	}

	if (showPreview) {
		var ref = React.useRef<HTMLDivElement>()

		useEffect(() => {
				window.addEventListener("scroll", () => onScroll());
		}, [])

		const Pin = ({ ...props }) => (
			<div className="map-pin" >
				<i className="far fa-building"></i>
			</div>
		);

		const onLoad = React.useCallback(function callback(map) {
			setMap(map);
		}, []);

		return (
			<div ref={ref} style={{height: "400px"}} className="map">
				{
					dynamicMapVisible && isLoaded && 
					<GoogleMap
						mapContainerStyle={{
							height: "400px"
						}}
						onLoad={onLoad}
						zoom={zoom}>
						{
							venues.map(l => {
								return (<Marker position={{ lat: parseFloat(l.latitude), lng: parseFloat(l.longitude) }} onClick={() => History.push(`/venue/${l.id}`)} />);
							})
						}
					</GoogleMap>
				}
			</div>
		);
	}


	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout, venueId } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout, venueId } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	return (
		<>
			<Draggable {...props} >
				<Card>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<span className="u-text-small">List of select venues</span>
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-pencil"></i>
							</a>
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Main Venue</Label>
						<Select
							isClearable
							value={venueId != null ? { label: venues.find(v => v.id === venueId)?.name, value: venues.find(v => v.id === venueId)?.id } : null}
							options={venues.filter(v => v.latitude && v.longitude).map(v => ({ label: v.name, value: v.id }))}
							onChange={e => setVenueId(e ? e.value : null)}
							closeMenuOnSelect={true} />
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-gray-200 btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableMap;
