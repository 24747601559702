import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { Row, Col } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";
import { WebsiteCompetitionData } from "@models/website";
import { textChangeRangeIsUnchanged } from "typescript";
import { resolveNaptr } from "dns";
import { RankingService } from "@services/ranking.service";
import { MemberRanking } from "@models/member";

const dataFormat = ["table", "results", "averages", "potms", "rankings"];

interface IRankingsTableProps {
	site?: string;
	match?: any;

}

interface IRankingsTableState {
	loading: boolean;
	competition?: Competition;
	division?: Division;
	rankings: MemberRanking[];
	search?: string;
}
export class RankingsTablePage extends React.Component<IRankingsTableProps, IRankingsTableState> {

	private competitionId?: number;
	private divisionId?: number;
	private navigationIds: WebsiteCompetitionData[];
	private compIndex: number;
	private divIndex: number;
	private formIndex: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			rankings: []
		};
	}

	componentDidMount() {
		//
		this.divisionId = this.props.match.params.divisionId;
		this.competitionId = this.props.match.params.competitionId;

		this.load();
	}

	componentDidUpdate(props: IRankingsTableProps, state: IRankingsTableState) {
		this.divisionId = this.props.match.params.divisionId;

		if (props.match.params.divisionId != this.divisionId) {
			this.load();
		}
	}

	load() {
		const p1 = RankingService.get(null, this.competitionId, this.divisionId, this.state.search).then(rankings => {
			this.setState({ rankings });
		});

		Promise.all([p1]).then(result => {
			this.setState({ loading: false });
		});
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Rankings</h1>
																			{
																				this.state.competition &&
																				<p className="sc_layouts_title_breadcrumbs">{this.state.competition.name} <span className="separator"> - </span> {this.state.division ? this.state.division.name : ""}</p>
																			}
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> : undefined
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										{
											AppCache.website.config.subHeader ? undefined :
												<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div className="sc_title sc_title_default mb-5">
															<h1 className=" m-0 sc_item_subtitle sc_align_center">
																<span className="sc_item_title_text">Rankings</span>
															</h1>
															<h2 className=" m-0 sc_item_title sc_align_center">
																<span className="sc_item_title_text">{this.state.division ? this.state.division.name : ""}</span>
															</h2>
															<p className="text-center">
																{this.state.competition ? this.state.competition.name : ""}
															</p>
														</div>
													</div>
												</div>
										}
										<Row>
											<Col>
											</Col>
											<Col>
											</Col>
											<Col>
												<a className="btn btn-lg btn-rounded bg-primary" href="https://tte-rankings.sport80.com/index.html#/overview" target="_blank"><span className="tx-20"><i className="icon far fa-globe"></i></span> National Rankings</a>
											</Col>
										</Row>
										<br/>
										<Row>
											<Col>
											</Col>
											<Col>
												<p><a href="/documents/ttleagues_rankings.pdf" target="_blank">Click here</a> to download the full guide on rankings.</p>
											</Col>
										</Row>
										<div className="search-box row">
											<div className="col-11">
												<input type="text" className="form-control" placeholder="Search Rankings" onChange={this.handleSearchChange.bind(this)} />
											</div>
											<div className="col-1">
												<span className="tx-20 mg-t-10 mg-r-15"><i className="far fa-search"></i></span>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div>
													<div className="standings">
														<div>
															<div className="table-responsive">
																<div>
																	<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-rank sorting" rowSpan={1} colSpan={1} aria-label="Pos">#</th>
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Player">Player</th>
																				<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Season">Season +/-</th>
																				<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">Points</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.rankings.map(r => {
																					return (
																						<tr key={`ranking-${r.userId}`} className={`sp-row-no-0 odd ${r.position <= 3 ? "bg-white" : ""}`}>
																							<td className="data-rank" data-label="Pos">{r.position}</td>
																							<td className="data-name nowrap" data-label="Player">
																								<h6 className="m-auto"><Link to={`/rankings/${r.userId}`}>{r.name}</Link></h6>
																							</td>
																							<td className="data-pts" data-label="%">{r.change}</td>
																							<td className="data-pts" data-label="PTS">{r.rank}</td>
																						</tr>)
																				})
																			}
																		</tbody>
																	</table>
																	{
																		this.state.loading ? <ActivitySpinner /> : <Empty text="No Rankings available" enabled={this.state.rankings.length === 0} />
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>
		);
	}
}