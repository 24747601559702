import React from "react";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import { Competition, CompetitionDay } from "@models/competition";

import { TeamService } from "@services/team.service";
import { CreateTeam } from "@models/team";
import { Division } from "@models/division";
import { SelectOption } from "@models/forms";
import { CreateEntrant, EntrantType } from "@models/entrant";
import { DivisionService } from "@services/division.service";

interface ICreateTeamSidebar {
	competition: Competition;
	divisions: Division[];

	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: ICreateTeamSidebar) => {
	const [team, setTeam] = useState<CreateTeam>(new CreateTeam());
	const [division, setDivision] = useState<SelectOption<number>>();

	useEffect(() => {
		load();
	}, []);



	const load = async () => {

	};

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {
		var model = team;

		model.competitionId = props.competition.id;
		await TeamService.create(model).then(async result => {
			if (division != null) {
				const entrant = new CreateEntrant();
				entrant.divisionId = division.value;
				entrant.competitionId = props.competition.id;
				entrant.type = EntrantType.Default;
				entrant.teamId = result;
				await DivisionService.assign(entrant);
			}
			props.onConfirm();
			props.onClose();
		});
	};

	const handleChangeTeam = (property: Function) => (e: any) => {
		const name = /\.([^\.;]+);?\s*\}$/.exec(property.toString())[1];
		let value = null;

		if (e == null) {
			value = null;
		} else if (e.value) {
			value = e.value;
		} else if (e.currentTarget) {
			value = e.currentTarget.value;
		} else {
			value = e;
		}

		setTeam({ ...team, [name]: value });
	}

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Quick Add Team
					<div className="help-container">
						<a className="help">
							<i className="far fa-info-circle"></i>
						</a>
					</div>
				</h6>
			</div>
			<div className="sidebar--content mg-t-30">
				<form>
					<div className="form-layout">
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label className="form-control-label">Name <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" name="name" placeholder="Enter team name" value={team.name} onChange={handleChangeTeam(() => team.name)} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label className="form-control-label">Divisions
									</label>
									<Select
										isClearable
										value={division}
										defaultOptions
										options={props.divisions?.map(d => { return { label: d.name, value: d.id } })}
										closeMenuOnSelect={true}
										onChange={e => setDivision(e)}
									/>
								</div>
							</div>
						</div>
						<div className="form-layout-footer">
							<Row>
								<Col>
									<button className="btn btn-primary btn-rounded btn-outline bd-0 mg-r-5" type="button" onClick={handleConfirm()}>Create</button>
								</Col>
								<Col>
									<button className="btn btn-secondary btn-rounded btn-outline bd-0" type="button" onClick={handleClose()}>Cancel</button>
								</Col>
							</Row>
						</div>
					</div>
				</form>
			</div>
			<div className="sidebar--footer">

			</div>
		</div>
	);
};