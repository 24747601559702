import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import ReactHtmlParser, {  } from "react-html-parser";
import PublicLayout from "@components/layouts/PublicLayout";

import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership, MembershipPayment } from "@models/membership";
import { PagedList } from "@models/paging";
import LocaleHelper from "@helpers/locale.helper";
import { Website, WebsiteCustomMenuItem, WebsiteMenuPreset } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";
import { Category } from "@models/rankings/category";
import { toast } from "react-toastify";
import { CategoryService } from "@services/rankings/category.service";
import { CategoryFilterOptions } from "@models/filters/rankingFilterOptions";
import { Paging } from "@components/controls/Paging";
import { imageUrl } from "@helpers/file.helper";


interface IRankingsCategoriesListPageProps {
	site?: string;
}

const RankingsCategoriesListPage = (props: IRankingsCategoriesListPageProps) => {
	const storageKey: string = "PublicRankingsCategoriesListPageNo";
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingMenu, setLoadingMenu] = useState<boolean>(true);
	const [categories, setCategories] = useState<PagedList<Category>>(undefined);
	const [websiteMenu, setWebsiteMenu] = useState<WebsiteCustomMenuItem>(undefined);
	const [options, setOptions] = useState<CategoryFilterOptions>({
		count: AppCache.settings.pageSize,
		page: 1
	});

	const isLoading = loadingMenu || loading;

	useEffect(() => {
		WebsiteService.updateActivity("NationalRankingsCategoriesList");
		setOptions({
			...options
		});
		menuLoad();
	}, []);

	useEffect(() => {
		MiscHelper.scrollToTop();
	}, [websiteMenu])

	useEffect(() => {
		load();
	}, [options])


	const menuLoad = async () => {
		setLoadingMenu(true);
		try {
			const menu = await WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Rankings);
			setWebsiteMenu(menu);
		}
		catch(err) {
			toast.error(err);
		}
		setLoadingMenu(false);
	};

	const load = async () => {
		setLoading(true);
		try {

			const memberships = await CategoryService.getAll(options)

			setCategories(memberships);
		}
		catch(err) {
			toast.error(err);
		}
		setLoading(false);
	};

	const handleChangePageSize = (count: number) => {
		setOptions({ ...options, count });
	}

	const handlePageChange = (page: number) => {
		sessionStorage.setItem(storageKey, page.toString());
		setOptions({...options, page });
	}

	const handleSearchChanged = (e:React.ChangeEvent<HTMLInputElement>) => {
		setOptions({
			...options,
			search: e.currentTarget.value ?? ""
		});
	}

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!loadingMenu}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																		<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(websiteMenu?.title, "National Rankings")}</h1>
																		<p className="header--description">{StringHelper.ifEmpty(websiteMenu?.subTitle, "Overview")}</p>
																	</div>
																	</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(websiteMenu?.title, "Memberships")}</h1>
												<p className="header--description">{StringHelper.ifEmpty(websiteMenu?.subTitle, "Available memberships")}</p>
											</div>
										</div>
									</div>
								</div>
						}

						<div className="page_content_wrap">
							<div className="container">
								<div className="pre-content">
									{
										websiteMenu?.body &&
										<Row>
											<Col>
												{ReactHtmlParser(websiteMenu.body)}
											</Col>
										</Row>
									}
								</div>
								<div className="content national-rankings">
									<div className="categories-list mg-t-20">
										{
											categories?.items?.map(m =>
												<Card
													className="mb-4"
													style={m.imageId && {
														backgroundImage: `url("${imageUrl(m?.imageId)}")`,
														backgroundRepeat: "no-repeat",
														//backgroundAttachment:"fixed",
														backgroundPosition: "center",
														backgroundSize:"cover"
													}}>
													<CardBody className="d-flex">
														<Row className="flex-fill">
															<Col xs={8} md={8} className="my-auto">
																<h4 className={`m-0 ${m.darkImage ? "text-light" :"text-dark"}`}>{m.name}</h4>
															</Col>
															<Col xs={4} md={4} className="my-auto text-center">
																<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button d-block mb-2 text-right">
																	<Link to={`/rankings/national/category/${m.id}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																		<span className="sc_button_text">
																			<span className="sc_button_title">View</span>
																		</span>
																	</Link>
																</div>
															</Col>
														</Row>
													</CardBody>
												</Card>
											)
										}
									</div>

									<Paging onChangePageSize={handleChangePageSize.bind(this)} onChangePage={handlePageChange.bind(this)} paging={categories} />
								</div>
							</div>
						</div>
					</div>)}
			/>
		</PublicLayout>
	);
};

export default RankingsCategoriesListPage;