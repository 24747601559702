import { Group, GroupContact } from "./group";
import { Membership, MembershipUser } from "./membership";
import { Gender, User } from "./user";

export class Contact {
	public id: number;
	public userId: string;
	public user: User;
	public name: string;
	public description: string;
	public email: string;
	public phoneNumber: string;
	public type: ContactType;
	public fullName: string;
	public firstName: string;
	public lastName: string;
	public initials: string;
	public addressLine1: string;
	public addressLine2: string;
	public town: string;
	public county: string;
	public country: string;
	public postcode: string;
	public source: string;
	public notes: string;

	public dob?: Date;
	public gender: Gender;

	public emergencyName: string;
	public emergencyNumber: string;

	public completed: Date;
	public confirmed?: Date;

	public memberships: MembershipUser[];
	public groups: Group[];

	public medicalAgreement?: Date;
	public unsubscribe?: Date;
	public defaultBookingTimeSearch?: number;
	public displayNameVisible?: boolean;

	public medicalAgreementUpdate?: boolean;
}

export class ContactAccount {
	public id: number;
	public stripeId: number;
	public paymentMethodId: string;
	public balance: number;
	public credits: number;
	public points: number;
}

export class ContactBasic {
	public id: number;
	public name: string;
	public firstName: string;
	public lastName: string;
	public email: string;
}

export class CreateContact {
	public type: CreateContactType;

	public firstName?: string;
	public lastName?: string;
	public email?: string;
	public dob?: Date;
	public gender?: Gender;

	public name?: string;
	public membershipNo?: number;

	public guardianEmail?: string;

	public linkType: ContactLinkType;
}

export class ContactLink {
	public id: number;
	public contact: Contact;
	public type: ContactLinkType;
	public confirmed?: Date;
}

export class ContactCredits{
	public id: number;
	public contactId: number;
	public availableCredits?: number;
	public usedCredits?: number;
}

export enum ContactType {
	Prospect = 1,
	Supplier = 2,
	Other = 3
}

export enum CreateContactType {
	Account = 1,
	Quick = 2,
	TTE = 3,
	Email = 4
}

export enum ContactLinkType {
	Full = 1,
	Guest = 2
}

export enum ContactLinkResult {
	Success = 1,
	Duplicate = 2,
	Confirmation = 3,
	Failed = 4
}

export enum ContactGuardianType {
	Managed = 1,
	SelfManaged = 2,
	DualManaged = 3
}