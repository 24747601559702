import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Container, Card, CardBody } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";

const DraggableRow = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [initialElements, setInitialElements] = useState(props.initialElements);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);
	const [name, setName] = useState(props.name);
	const [open, setOpen] = useState(false);

	const [grid, setGrid] = useState<number[]>(props.payload.grid ?? []);

	const [marginTop, setMarginTop] = useState<number>(props.payload.marginTop);
	const [marginBottom, setMarginBottom] = useState<number>(props.payload.marginBottom);
	const [paddingTop, setPaddingTop] = useState<number>(props.payload.paddingTop);
	const [paddingBottom, setPaddingBottom] = useState<number>(props.payload.paddingBottom);

	const [editMode, setEditMode] = useState<boolean>(false);
	const [setupMode, setSetupMode] = useState<boolean>(props.payload.grid ? false : true);

	if (showBasicContent) {
		return (
			<Draggable {...props} >
				<span>{props.name}</span>
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<section style={
				{
					paddingBottom: `${`${paddingBottom}px` ?? ""}`,
					paddingTop: `${`${paddingTop}px` ?? ""}`,
					marginTop: `${`${marginTop}px` ?? ""}`,
					marginBottom: `${`${marginBottom}px` ?? ""}`
				}
			}>
				<Container>
					<Row>
						{
							grid.map((_, i) => <Col lg={grid[i]}>{props.childNode[`canvas-1-${i}`]}</Col>)
						}
					</Row>
				</Container>
			</section>
		);
	}

	const filterInitialElements = (dID) => {
		return initialElements.filter(e => e.dropzoneID === dID) || [];
	};

	const onDrop = (data, cb) => {
		if (data.id === props.id) {
			return false;
		}

		// no need to ask id and name again
		if (data.payload && data.payload.dropped) {
			return cb(data);
		}

		const id = uuidv4();

		const result = cb({
			...data,
			id,
			payload: { ...data.payload, dropped: true }
		});
	};

	const handleGridConfirm = (grid: number[]) => (e: any) => {
		setGrid(grid);
		setSetupMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { grid, marginBottom, marginTop, paddingBottom, paddingTop } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { grid, marginBottom, marginTop, paddingBottom, paddingTop } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	return (
		<>
			<Draggable {...props}>
				<div className="pm-container mb-2">
					<Row>
						<Col>
							<span>{props.name}</span>
						</Col>
						<Col className="text-right">
							<a className="btn p-0 mb-2 mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-border-outer"></i>
							</a>
							<a className="btn p-0 mb-2 mr-2"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
					<Row className="mb-3">
						{
							grid.map((_, i) =>
								<Col lg={grid[i]}>
									<Dropzone
										placeholder="Drop Here"
										parentID={id}
										initialElements={filterInitialElements(`canvas-1-${i}`)}
										id={`canvas-1-${i}`}
										onDrop={onDrop}
										capacity={4}
									/>
								</Col>)
						}
					</Row>
				</div>
			</Draggable>
			<Modal className={"element-editor element-editor--edit"} isOpen={editMode}>
				<ModalBody className="p-4">
					<Row className="mb-5">
						<Col>
							<FormGroup>
								<Row>
									<Col>
										<h5 className="text-center">Margin</h5>
										<Row>
											<Col>
												<Label className="text-center">Top</Label>
												<Input type="number" value={marginTop} onChange={e => setMarginTop(e.target.value)} />
											</Col>
											<Col>
												<Label className="text-center">Bottom</Label>
												<Input type="number" value={marginBottom} onChange={e => setMarginBottom(e.target.value)} />
											</Col>
										</Row>
									</Col>
									<Col>
										<h5 className="text-center">Padding</h5>
										<Row>
											<Col>
												<Label className="text-center">Top</Label>
												<Input type="number" value={paddingTop} onChange={e => setPaddingTop(e.target.value)} />
											</Col>
											<Col>
												<Label className="text-center">Bottom</Label>
												<Input type="number" value={paddingBottom} onChange={e => setPaddingBottom(e.target.value)} />
											</Col>
										</Row>
									</Col>
								</Row>
							</FormGroup>
						</Col>
					</Row>

					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-outline btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-secondary btn-outline btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			<Modal className={"element-editor element-editor--setup"} isOpen={setupMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Row>
							<Col md={6}>
								<Card onClick={handleGridConfirm([12])} className="text-center card--maker-setup">
									<CardBody>
										<p className="section-title mt-0">1 Column</p>
										<Row>
											<Col xs={12}>
												<div className="highlight-block"></div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleGridConfirm([8, 4])} className="text-center card--maker-setup">
									<CardBody>
										<p className="section-title mt-0">2 Columns</p>
										<Row>
											<Col xs={8}>
												<div className="highlight-block"></div>
											</Col>
											<Col xs={4}>
												<div className="highlight-block"></div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleGridConfirm([4, 4, 4])} className="text-center card--maker-setup">
									<CardBody>
										<p className="section-title mt-0">3 Columns</p>
										<Row>
											<Col xs={4}>
												<div className="highlight-block"></div>
											</Col>
											<Col xs={4}>
												<div className="highlight-block"></div>
											</Col>
											<Col xs={4}>
												<div className="highlight-block"></div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleGridConfirm([6, 6])} className="text-center card--maker-setup">
									<CardBody>
										<p className="section-title mt-0">Split Columns</p>
										<Row>
											<Col xs={6}>
												<div className="highlight-block"></div>
											</Col>
											<Col xs={6}>
												<div className="highlight-block"></div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</FormGroup>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableRow;
