import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect, Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";

import { GoogleMap, LoadScript, Marker, useLoadScript } from "@react-google-maps/api";

import { VenueService } from "@services/venue.service";
import { Venue } from "@models/venue";
import { AppConfig } from "@config";
import History from "@helpers/history.helper";
import Select from "react-select";
import * as OwlCarousel from "react-owl-carousel2";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { MatchGrouping, MatchGroupType } from "@models/match";
import { AppCache } from "@cache";
import { MatchService } from "@services/match.service";
import { Toggle } from "@components/controls/Toggle";

declare const google: any;

const DraggableResults = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [info, setInfo] = useState(props.payload.title);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setlayout] = useState(props.payload.layout);
	const [minimum, setMinimun] = useState(props.payload.minimum);

	const [matches, setMatches] = useState<MatchGrouping>();

	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		if (showPreview /*&& AppCache.website.config.resultsCarousel*/) {
			MatchService.latest(10, MatchGroupType.Results).then(result => {
				setMatches(result);
			});
		}
	}, []);

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			matches && (minimum || matches.matches.length >= 3) ? <section className="mt-4 mb-4 latest-results">
				<OwlCarousel options={{ autoplay: matches.matches.length > 3, loop: true, responsive: { 992: { items: 3 }, 768: { items: 2 }, 0: { items: 1 } } }}>
					{
						matches.matches.map(m => {
							return (
								<div key={`custom_latest_${m.id}`} className="card m-2 p-2 clickable">
									<div className="row">
										<div className="col-12 text-center">
											<span className="date mt-2">{m.name}</span>
											<span className="date d-block">{moment(m.date).format("dddd Do MMMM")}</span>
										</div>
									</div>
									<div className="row">
										<div className="col-6 text-center wrap-text"><span className="team-name">{m.home.displayName}</span></div>
										<div className="col-6 text-center wrap-text"><span className="team-name">{m.away.displayName}</span></div>
									</div>
									<div className="row">
										<div className="col-6 text-center"><span className="score">{m.home.score}</span></div>
										<div className="col-6 text-center"><span className="score">{m.away.score}</span></div>
									</div>
									<div className="row">
										<div className="col-12 text-center"><Link className="tx-12" to={`/league/${m.competitionId}/division/${m.divisionId}/match/${m.id}`}>Match Card</Link></div>
									</div>
								</div>
							);
						})
					}
				</OwlCarousel>
			</section>
			: <div></div>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout, minimum } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout, minimum } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	return (
		<>
			<Draggable {...props} >
				<Card>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<span className="u-text-small">Carousel of Match Results</span>
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-pencil"></i>
							</a>
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Ignore 3 match minimum</Label>
						<Toggle checked={minimum} onChange={e => setMinimun(e)} />
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-gray-200 btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableResults;
