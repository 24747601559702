import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";
import classnames from "classnames";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, CardBody, CardHeader, Nav, NavBar, NavItem, NavLink } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Registration, RegistrationCategory, RegistrationEntrantType } from "@models/registration";
import { Entry, EntryPlayer, EntryTeam } from "@models/entry";
import { EntryService } from "@services/entry.service";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { tokenToString } from "typescript";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

interface IHeader {
	entry: Entry;
	registration: Registration;
	tab: string;
}

export default (props: IHeader) => {

	return (
		<Card className="mb-4 card--nav">
			<Row className="px-4">
				<Col md={12}>
					<div className="name">
						<div className="row">
							<div className="col-11">
								<p className="m-0">{props.registration.name}</p>
								<h3 className="m-0">{props.entry.club.name}</h3>
							</div>
							<div className="col-1" style={{ float: "right" }}>
								{/* <ExtendedButton className="btn btn-circle btn-outline btn-icon mb-2" tooltip={`Club ${props.entry.affiliated ? "" : "not "}affiliated`} id={`affiliated-${props.entry.id}`}>
									<div className={`tx-20 ${!props.entry.affiliated ? "tx-danger" : ""}`}><i className="fal fa-link"></i></div>
								</ExtendedButton> */}
							</div>
						</div>
						<div>
							<p className="text-muted d-block m-0">Manage your registered teams and players</p>
						</div>
					</div>
				</Col>
			</Row>
			<Nav>
				<NavItem>
					<Link className={classnames({ active: props.tab === "Summary"}, "nav-link")} to={`/member/entries/${props.entry.id}`}>Summary</Link>
				</NavItem>
				<NavItem>
					<Link className={classnames({ active: props.tab === "Categories"}, "nav-link")} to={`/member/entries/${props.entry.id}/categories`}>
						Competitions
					</Link>
				</NavItem>
				<NavItem>
					<Link className={classnames({ active: props.tab === "Payments"}, "nav-link")} to={`/member/entries/${props.entry.id}/payments`}>Payments</Link>
				</NavItem>
				<NavItem>
					<Link className={classnames({ active: props.tab === "Agreements"}, "nav-link")} to={`/member/entries/${props.entry.id}/agreements`}>Agreements</Link>
				</NavItem>
				<NavItem>
					<Link className={classnames({ active: props.tab === "Contact"}, "nav-link")} to={`/member/entries/${props.entry.id}/contact`}>Contact</Link>
				</NavItem>
				<NavItem>
					<NavLink target="_blank" href="https://tabletennisengland.zendesk.com/hc/en-gb">Help</NavLink>
				</NavItem>
			</Nav>
		</Card>
	);
};