import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DelayInput } from "react-delay-input";
import { Options } from "@data";
import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import * as moment from "moment";
import { SelectOption } from "@models/forms";
import ObjectHelper from "@helpers/object.helper";
import { Format } from "@models/format";
import { ArrayHelper } from "@helpers/array.helper";
import { FormatService } from "@services/format.service";
import { Competition, CompetitionDay } from "@models/competition";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker";
import TimePicker from "rc-time-picker";
import { Division } from "@models/division";
import { BookingService } from "@services/booking.service";
import { Contact, CreateContactType, CreateContact, ContactLinkType, ContactLink, ContactLinkResult } from "@models/contact";
import { ContactService } from "@services/contact.service";
import DatePicker from "react-date-picker";
import { CreateMember } from "@models/member";
import { MemberService } from "@services/member.service";
import { Gender } from "@models/user";
import History from "@helpers/history.helper";
import { toast } from "react-toastify";

interface ILinkContactSidebar {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: ILinkContactSidebar) => {
	const [type, setType] = useState<CreateContactType>();
	const [linkType, setLinkType] = useState<ContactLinkType>(ContactLinkType.Full);

	const [name, setName] = useState<string>();
	const [email, setEmail] = useState<string>(props.contact.email);
	const [guardianEmail, setGuardianEmail] = useState<string>(props.contact.email);
	const [firstName, setFirstName] = useState<string>();
	const [lastName, setLastName] = useState<string>();
	const [dob, setDob] = useState<Date>();

	const [dateArray, setDate] = useState<number[]>([dob?.getDate(), dob?.getMonth(), dob?.getFullYear()]);

	const [gender, setGender] = useState<Gender>();
	const [day, setDay] = useState<SelectOption<number>>();
	const [month, setMonth] = useState<SelectOption<number>>();
	const [year, setYear] = useState<SelectOption<number>>();

	const [membershipNo, setMembershipNo] = useState<number>(undefined);

	const [under18, setUnder18] = useState<boolean>(false);

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {

		NProgress.start();
		const contact = new CreateContact();
		contact.type = type;
		contact.linkType = linkType;

		// account
		contact.firstName = firstName;
		contact.lastName = lastName;
		contact.email = email;
		contact.dob = dob;
		contact.gender = gender;
		contact.guardianEmail = guardianEmail;

		// tte
		contact.membershipNo = membershipNo;

		try {
			const result = await ContactService.createLink(props.contact.id, contact);
			if(result === ContactLinkResult.Confirmation) {
				toast.success("Approval Required");
			} else if(result === ContactLinkResult.Success) {
				toast.success("Successfully Linked");
			}
			props.onConfirm();
			props.onClose();
		} catch (error) {
			toast.error(error.message);
		} finally {
			NProgress.done();
		}
	};

	const handleChangeSelect = (e: any, property: string) => {
		const date = dateArray;
		switch (property) {
			case "year":
				setYear(e);
				date[2] = e.value;
				break;
			case "month":
				setMonth(e);
				date[1] = e.value;
				break;
			case "day":
				setDay(e);
				date[0] = e.value;
				break;
		}
		setDate(date);

		const actualDate = new Date(date[2], date[1], date[0]);
		setDob(actualDate);

		if (moment(actualDate).isValid()) {
			const years = moment().diff(moment(actualDate), 'years');

			if (years < 18) {
				setUnder18(true);
			} else {
				setUnder18(false);
			}
		}
	};

	const dayOptions = () => {
		const array = [];
		for (var i = 1; i <= 31; i++) {
			array.push({ label: i.toString(), value: i });
		}
		return array;
	};

	const monthOptions = () => {
		var array = [];
		for (var i = 1; i <= 12; i++) {
			array.push({ label: i.toString(), value: i - 1 })
		}
		return array;
	}

	const yearOptions = () => {
		var array = [];
		const year = new Date().getFullYear()
		for (var i = year; i >= year - 100; i--) {
			array.push({ label: i.toString(), value: i })
		}
		return array;
	}

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="m-0">Link Contact
					<div className="help-container">
						<button className="btn p-0 tx-20" onClick={props.onClose}>
							<i className="far fa-times"></i>
						</button>
					</div></h6>
			</div>
			<div className="sidebar--content">
				{
					!type &&
					<Row>
						<Col>
							<Card className="card--border mb-3 clickable p-0" onClick={() => setType(CreateContactType.Quick)}>
								<CardBody>
									<h6 className="m-0">New</h6>
									<p className="m-0">Create and Link new contact</p>
								</CardBody>
							</Card>
							<Card className="card--border mb-3 clickable p-0" onClick={() => setType(CreateContactType.TTE)}>
								<CardBody>
									<h6 className="m-0">TTE</h6>
									<p className="m-0">Link using a TTE Membership No.</p>
								</CardBody>
							</Card>
						</Col>
					</Row>
				}
				{
					type === CreateContactType.TTE &&
					<>
						<Row>
							<Col>
								{/* <FormGroup>
									<Label>Access</Label>
									<Select
										options={Options.contactLinkType}
										onChange={e => setLinkType(e ? e.value : null)}
									/>
								</FormGroup> */}
								<div className="form-group">
									<label className="form-control-label">TTE Membership No. <span className="tx-danger">*</span></label>
									<input className="form-control" type="number" name="email" placeholder="Membership No" value={membershipNo} onChange={e => setMembershipNo(e.currentTarget.valueAsNumber)} />
								</div>
							</Col>
						</Row>
					</>
				}
				{
					type === CreateContactType.Quick &&
					<form>
						<div className="form-layout">
							<div className="row mg-b-25">
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-control-label">First Name <span className="tx-danger">*</span></label>
										<input className="form-control" type="text" name="firstName" placeholder="Enter name" value={firstName} onChange={e => setFirstName(e.currentTarget.value)} />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group">
										<label className="form-control-label">Last Name <span className="tx-danger">*</span></label>
										<input className="form-control" type="text" name="lastName" placeholder="Enter name" value={lastName} onChange={e => setLastName(e.currentTarget.value)} />
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<label className="form-control-label">Gender <span className="tx-danger">*</span></label>
										<Select
											value={Options.gender.find(g => g.value === gender)}
											options={Options.gender}
											onChange={e => setGender(e.value)}
										/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<label className="form-control-label d-block">Date of Birth <span className="tx-danger">*</span></label>
										<Row>
											<Col md={4}>
												<Select
													value={day}
													options={dayOptions()}
													onChange={e => handleChangeSelect(e, "day")}
												/>
											</Col>
											<Col md={4}>
												<Select
													value={month}
													options={monthOptions()}
													onChange={e => handleChangeSelect(e, "month")}
												/>
											</Col>
											<Col md={4}>
												<Select
													value={year}
													options={yearOptions()}
													onChange={e => handleChangeSelect(e, "year")}
												/>
											</Col>
										</Row>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<label className="form-control-label">Email <span className="tx-danger">*</span></label>
										<input className="form-control" type="text" name="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.currentTarget.value)} />
									</div>
								</div>
								{
									under18 &&
									<Col lg={12}>
										<Row>
											<Col lg={12} className="mb-3">
												<label className="form-control-label">Guardian Email <span className="tx-danger">*</span></label>
												<input className="form-control w-100" type="text" name="month" placeholder="Guardian Email" value={guardianEmail} onChange={e => setGuardianEmail(e.currentTarget.value)} />
											</Col>
											<Col lg={12}>
												<p className="mb-0"><strong>Please note:</strong> Under 18s require a guardian email address.</p>
												<p className="mb-0">You will also receive a confirmation email when creating a new account.</p>
											</Col>
										</Row>
									</Col>
								}
							</div>
						</div>
					</form>
				}
			</div>
			<div className="sidebar--footer">
				<Row>
					{
						type &&
						<Col>
							<button className="btn btn-primary btn-rounded btn-outline bd-0 btn-block" type="button" onClick={handleConfirm()}>Confirm</button>
						</Col>
					}
					<Col>
						<button className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block" type="button" onClick={handleClose()}>Cancel</button>
					</Col>
				</Row>
			</div>
		</div>
	);
};