import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Team, TeamMember, CreateTeam, Overlap } from "@models/team";

import store from "store2";

import * as moment from "moment";
import { SelectOption } from "@models/forms";

import { DatatableQuery, DatatableResponse } from "@models/query";
import { Entrant } from "@models/entrant";

import RestHelper from "@helpers/rest.helper";
import { Division } from "@models/division";

export class TeamService {

	constructor() {
		//
	}

	public static search(term:string = "", take: number, published?: boolean, archived?: boolean, hidden?: boolean): Promise<Team[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {search: term, take, published, archived, hidden},
				success: (data:Team[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static available(competitionId: number, term:string = "", take: number): Promise<Team[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/teams/available?search=${term}&take=${take}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Team[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(teamId: number): Promise<Team> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/${teamId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Team, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByIds(teamIds: number[]): Promise<Team[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/teamIds`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(teamIds),
				success: (data:Team[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getMembers(teamId: number, pool: boolean): Promise<TeamMember[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/${teamId}/members${pool ? "/pool": ""}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:TeamMember[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByMember(): Promise<Team[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `member/teams`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Team[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getEntrantsByMember(competitionId: number): Promise<Entrant[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competition/${competitionId}/member/teams/entrants`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Entrant[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getEntrants(competitionId: number): Promise<Entrant[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Entrant[]>(`competitions/${competitionId}/entrants`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getDivision(teamId: number): Promise<Division> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`teams/${teamId}/division`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static data(query:DatatableQuery, competitionId?: number, clubId?: number, archived?: boolean, deleted: boolean = false): Promise<DatatableResponse<Team>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({competitionId, clubId, query, archived, deleted}),
				success: (data:DatatableResponse<Team>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(team: CreateTeam): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(team),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(team: Team): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(team),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static assign(teamId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/${teamId}/assign`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({teamId, memberId}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static unassign(teamId: number, memberId: string, all: boolean = false): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/${teamId}/unassign${all ? "/all" : ""}`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({teamId, memberId}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static order(teams: Team[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/order`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(teams),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static orderMembers(members: TeamMember[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/members/order`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(members),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(teamId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `teams/${teamId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static loadMembers(teamId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`teams/${teamId}/players/entry/assign`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static transferPlayers(teamId: number, memberId: string, transferId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`teams/${teamId}/players/${memberId}/transfer/${transferId}`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static clearMemberTransfer(teamId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`teams/${teamId}/players/${memberId}/transfer/clear`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static clearAllMemberTransfers(teamId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`teams/${teamId}/players/${memberId}/transfer/clear/all`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static memberOverlaps(teamId: number): Promise<Overlap[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Overlap[]>(`teams/${teamId}/members/overlap`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}
}
