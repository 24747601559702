import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { CreateTeam, Team, TeamMember } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CreateDivision, Division, DivisionProgress, DivisionStatus } from "@models/division";
import { Competition, CompetitionMember, CreateCompetition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { Entry, EntryStatus } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { confirmAlert } from "react-confirm-alert";
import { CompetitionService } from "@services/competition.service";
import { DatatableMeta, DatatableQuery, DatatableResponse } from "@models/query";
import { Paging } from "@components/controls/Paging";
import ObjectHelper from "@helpers/object.helper";
import { HelpPopover } from "@components/controls/Popover";
import { Options } from "@data";
import { FormatService } from "@services/format.service";
import { PointService } from "@services/point.service";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { CreateEntrant, Entrant, EntrantType } from "@models/entrant";
import { MatchService } from "@services/match.service";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ArrayHelper } from "@helpers/array.helper";
import CreateCompetitionSidebar from "@components/sidebar/public/competition/CreateCompetitionSidebar";
import CreateDivisionSidebar from "@components/sidebar/public/competition/CreateDivisionSidebar";
import CreateTeamSidebar from "@components/sidebar/public/competition/CreateTeamSidebar";
import AssignTeamMembersSidebar from "@components/sidebar/public/competition/AssignTeamMembersSidebar";
import AssignEntrantsSidebar from "@components/sidebar/public/competition/AssignEntrantsSidebar";
import { SelectOption } from "@models/forms";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import CompetitionWizardModal from "@components/modals/CompetitionWizardModal";
import DivisionItem from "./components/DivisionItem";
import AssignCompetitionRoleSidebar from "@components/sidebar/public/competition/AssignCompetitionRoleSidebar";
import UpdateTeamSidebar from "@components/sidebar/public/competition/UpdateTeamSidebar";

interface ICompetitionEditPagePageProps {
    site?: string;
    match?: any;
    location?: any;
}

export default (props: ICompetitionEditPagePageProps) => {
    const [loading, setLoading] = useState<boolean>(true);

    const [competition, setCompetition] = useState<Competition>();
    const [competitionId, setCompetitionId] = useState<number>(props.match.params.competitionId);

    const [competitions, setCompetitions] = useState<DatatableResponse<Competition>>();
    const [archived, setArchived] = useState<DatatableResponse<Competition>>();
    const [meta, setMeta] = useState<DatatableMeta>({ paging: { pageSize: AppCache.settings.pageSize, pageNo: 1 } });
    const [archiveMeta, setArchiveMeta] = useState<DatatableMeta>({ paging: { pageSize: AppCache.settings.pageSize, pageNo: 1 } });

    const [division, setDivision] = useState<Division>();
    const [team, setTeam] = useState<Team>();

    const [editModal, setEditModal] = useState<boolean>();
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [teamMeta, setTeamMeta] = useState<DatatableMeta>({ paging: { pageSize: 5, pageNo: 1 } });
    const [teams, setTeams] = useState<Team[]>();

    const [createCompetition, setCreateCompetition] = useState<boolean>();
    const [createDivision, setCreateDivision] = useState<boolean>();
    const [createTeam, setCreateTeam] = useState<boolean>();
    const [assignEntrants, setAssignEntrants] = useState<boolean>();
    const [assignTeamMembers, setAssignTeamMembers] = useState<boolean>();
    const [assignRoles, setAssignRoles] = useState<boolean>();
    const [updateTeam, setUpdateTeam] = useState<boolean>();

    const [member, setMember] = useState<SelectOption<string>>();

    const [activeTab, setActiveTab] = useState<string>("Divisions");
    const [modalTab, setModalTab] = useState<string>("Divisions");

    const [showWizard, setShowWizard] = useState<boolean>(false);

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (competition != null)
            loadTeams()
    }, [teamMeta.paging.pageNo]);

    useEffect(() => {
        loadCompetitions();
    }, [meta.paging.pageNo, meta.paging.pageSize]);

    useEffect(() => {
        loadArchives();
    }, [archiveMeta.paging.pageNo, archiveMeta.paging.pageSize]);

    const load = async () => {

        const competition = await CompetitionService.getById(competitionId)
        setCompetition(competition);

        loadDivisions();
        loadTeams();
        loadArchives();

        setLoading(false);
    };

    const loadCompetitions = async () => {
        const query = new DatatableQuery();
        query.paging = meta.paging;

        const competitions = await CompetitionService.getByCompetitionRoles(query, false, null, false);
        setCompetitions(competitions);
        setMeta(competitions.meta);
    };

    const loadArchives = async () => {
        const archivedQuery = new DatatableQuery();
        archivedQuery.paging = archiveMeta.paging;

        const archived = await CompetitionService.getByCompetitionRoles(archivedQuery, true, null, true);
        setArchived(archived);
        setArchiveMeta(archived.meta);
    };

    const toggle = (tab: string) => (e) => {
        setActiveTab(tab);
    }

    const handleCancelClick = (competition: Competition) => (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>This will permanently delete this Competition.</p>
                        <a className="btn btn-secondary text-white mr-2"
                            onClick={() => {
                                CompetitionService.delete(competition.id).then(() => {
                                    load();
                                    onClose();
                                    toast.success("Competition Removed");
                                });
                            }}
                        >
                            Yes, delete it!
                        </a>
                        <a className="btn btn-primary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const handleCancelTeamClick = (team: Team) => (e) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>This will permanently delete this Division.</p>
                        <a className="btn btn-secondary text-white mr-2"
                            onClick={() => {
                                TeamService.delete(team.id).then(() => {
                                    loadTeams();
                                    onClose();
                                    toast.success("Team Removed");
                                });
                            }}
                        >
                            Yes, delete it!
                        </a>
                        <a className="btn btn-primary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const handleChangePageSize = () => (pageSize: number) => {
        setMeta({ ...meta, paging: { ...meta.paging, pageSize } });
    }

    const handlePageChange = () => (pageNo: number) => {
        setMeta({ ...meta, paging: { ...meta.paging, pageNo } });
    };

    const handleChangeArchivePageSize = () => (pageSize: number) => {
        setArchiveMeta({ ...archiveMeta, paging: { ...archiveMeta.paging, pageSize } });
    }

    const handleArchivePageChange = () => (pageNo: number) => {
        setArchiveMeta({ ...archiveMeta, paging: { ...archiveMeta.paging, pageNo } });
    };

    const handleTeamPageChange = () => (pageNo: number) => {
        setTeamMeta({ ...teamMeta, paging: { ...teamMeta.paging, pageNo } });
    };

    const loadDivisions = async () => {
        await DivisionService.get(competitionId).then((divisions) => {
            setDivisions(divisions);
        });
    };

    const loadTeams = async () => {
        const query = new DatatableQuery();
        query.paging = teamMeta.paging;

        TeamService.data(query, competitionId, null, null, false).then(result => {
            setTeams(result.data);
            setTeamMeta(result.meta);
        });
    };

    const handleEditCompetition = (competition: Competition) => (e) => {
        const p1 = CompetitionService.getById(competition.id).then(result => {
            FormatService.get().then(formats =>
                result.format = formats.find(f => f.id == result.formatId)
            );
            PointService.get().then(points =>
                result.point = points.find(p => p.id == result.pointId))
            setCompetition(result);
        });

        const p2 = loadDivisions();

        const p3 = loadTeams();

        Promise.all([p1, p2, p3]).then(result => {
            setEditModal(true);
        })
    };

    const handleCreateCompetition = () => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateCompetition(true);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
    }

    const handleCreateDivision = () => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateDivision(true);
        setCreateCompetition(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
    }

    const handleCreateTeam = () => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateTeam(true);
        setCreateCompetition(false);
        setCreateDivision(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
    }

    const handleManageEntrants = (division: Division) => {
        setDivision(division);
        setTeam(null);
        setAssignEntrants(true);
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignTeamMembers(false);
    };

    const handleManageTeamMembers = (team: Team) => (e) => {
        setTeam(team);
        setDivision(null);
        setAssignTeamMembers(true);
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
    };

    const handleUpdateTeam = (team: Team) => (e) => {
        setTeam(team);
        setUpdateTeam(true);
    };

    const handleManageRoles = (competition: Competition) => (e) => {
        setDivision(null);
        setTeam(null);
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
    };

    const closeEditModal = () => (e) => {
        setEditModal(false);
        setCompetition(null);
        setTeams(null);
        setDivisions(null);
        setModalTab("Divisions");
        setTeamMeta({ paging: { pageSize: 5, pageNo: 1 } });
        cancel();
    }

    const cancel = () => {
        setCreateCompetition(false);
        setCreateDivision(false);
        setCreateTeam(false);
        setAssignEntrants(false);
        setAssignTeamMembers(false);
    }

    const publish = (competition: Competition) => () => {
        if (!LoginService.allow(["manage:full:?", "leagues:?"]))
            return false;

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="react-confirm-alert--default">
                        <h1>Are you sure?</h1>
                        <p>
                            {competition.published ?
                                <span>Unpublish competition?</span> :
                                <span>Publish competition?</span>
                            }
                        </p>
                        <a className={`btn btn-${competition.published ? "danger" : "success"} text-white mr-2`}
                            onClick={() => {
                                CompetitionService.publish(competition.id).then(result => {
                                    load();
                                });
                                onClose();
                            }}>
                            Yes, {competition.published ? <span>Unpublish</span> : <span>Publish</span>} it!
                        </a>
                        <a className="btn btn-secondary text-white" onClick={onClose}>No</a>
                    </div>
                );
            }
        });
    }

    const sidebar = () => {
        if (createCompetition) {
            return (<CreateCompetitionSidebar onConfirm={() => load()} onClose={() => setCreateCompetition(false)} />);
        }

        if (createDivision) {
            return (<CreateDivisionSidebar competition={competition} onConfirm={() => loadDivisions()} onClose={() => setCreateDivision(false)} />);
        }

        if (createTeam) {
            return (<CreateTeamSidebar competition={competition} divisions={divisions} onConfirm={() => loadTeams()} onClose={() => setCreateTeam(false)} />);
        }

        if (assignEntrants) {
            return (<AssignEntrantsSidebar competition={competition} division={division} onClose={() => { setAssignEntrants(false); setDivision(null); }} />);
        }

        if (assignTeamMembers) {
            return (<AssignTeamMembersSidebar competition={competition} team={team} onClose={() => { setAssignTeamMembers(false); setTeam(null); }} />);
        }

        if (assignRoles) {
            return (<AssignCompetitionRoleSidebar competition={competition} onClose={() => setAssignRoles(false)} />);
        }

        if (updateTeam) {
            return (<UpdateTeamSidebar team={team} onClose={() => setUpdateTeam(false)} onConfirm={loadTeams} />);
        }

        return <></>;
    }

    const openSidebar = () => {
        if (createCompetition) {
            return true;
        }
        if (createDivision) {
            return true;
        }
        if (createTeam) {
            return true;
        }
        if (assignEntrants) {
            return true;
        }
        if (assignTeamMembers) {
            return true;
        }
        if (assignRoles) {
            return true;
        }

        if(updateTeam) {
            return true;
        }

        return false;
    }


    return (
        <PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
            <ShowMe
                visible={!loading}
                mode={ShowMeMode.Full}
                progress={true}
                render={() => (
                    <Sidebar
                        sidebar={sidebar()}
                        pullRight={true}
                        open={openSidebar()}
                        sidebarClassName="sidebar-root"
                        styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
                        <div>
                            <div className="page_content_wrap">
                                <div className="container">
                                    <Row>
                                        <Col>
                                            <Card className="mb-5 card--nav">
                                                <Row className="pt-4 px-4">
                                                    <Col md={12}>
                                                        <div className="name">
                                                            <h3 className="m-0">{competition?.name}</h3>
                                                            <div>
                                                                <p className="text-muted d-block m-0">Manage your fast format league</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Nav>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === "Divisions" })}
                                                            onClick={toggle("Divisions")}>
                                                            Divisions
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === "Teams" })}
                                                            onClick={toggle("Teams")}>
                                                            Teams
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === "Settings" })}
                                                            onClick={toggle("Settings")}>
                                                            Settings
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="ml-auto">
                                                        {
                                                            activeTab === "Divisions" &&
                                                            <a className="btn btn--circle text-white float-right mr-2" onClick={() => setCreateDivision(true)}><i className="far fa-plus" /></a>
                                                        }
                                                        {
                                                            activeTab === "Teams" &&
                                                            <a className="btn btn--circle text-white float-right mr-2" onClick={() => setCreateTeam(true)}><i className="far fa-plus" /></a>
                                                        }
                                                        {
                                                            activeTab === "Settings" &&
                                                            <a className="btn btn--circle text-white float-right mr-2" onClick={()=> setAssignRoles(true)}><i className="far fa-users-cog" /></a>
                                                        }
                                                    </NavItem>
                                                </Nav>
                                            </Card>
                                            <div>

                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId="Divisions">
                                                        <Row>
                                                            {
                                                                divisions.map(d => (
                                                                    <DivisionItem division={d} competition={competition} loadDivisions={() => loadDivisions()} manageEntrants={(division) => handleManageEntrants(division)} />
                                                                ))
                                                            }
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId="Teams">
                                                        {
                                                            teams && teams.map(t => (
                                                                <Card className="mb-3 p-3">
                                                                    <Row>
                                                                        <Col lg={5} className="d-flex align-self-center">
                                                                            <div className="col">
                                                                                <h6 className="m-0">{t.displayName}</h6>
                                                                                <p className="m-0 small">{t.description}</p>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={3} className="d-flex justify-content-center align-self-center">

                                                                        </Col>
                                                                        <Col lg={4} className="d-flex justify-content-end align-self-center">
                                                                            <a className="btn btn--circle text-white mr-2" onClick={handleUpdateTeam(t)}><i className="far fa-pencil" /></a>
                                                                            <a className="btn btn--circle text-white mr-2" onClick={handleManageTeamMembers(t)}><i className="far fa-users" /></a>
                                                                            <a className="btn btn--circle bg-danger text-white" onClick={handleCancelTeamClick(t)}><i className="far fa-times" /></a>
                                                                        </Col>
                                                                    </Row>
                                                                </Card>

                                                            ))
                                                        }
                                                        <Paging onChangePage={handleTeamPageChange()} meta={teamMeta} />
                                                    </TabPane>
                                                    <TabPane tabId="Settings">
                                                        <Row>
                                                            <Col>
                                                                {competition && <div>
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Fixture Format</label>
                                                                        <label className="form-control">{Options.fixtureFormats.find(f => f.value == competition.fixtureFormat).label}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Game Format</label>
                                                                        <label className="form-control">{Options.gameFormats.find(f => f.value == competition.gameFormat)?.label}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Games Per Set</label>
                                                                        <label className="form-control">{Options.gamesPerSet.find(f => f.value == competition.gamesPerSet)?.label}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Play Each Other</label>
                                                                        <label className="form-control">{competition.playEachOther}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Format</label>
                                                                        <label className="form-control">{competition.format?.name}</label>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Points</label>
                                                                        <label className="form-control" title={competition.point?.description}>{competition.point?.name}</label>
                                                                    </div>
                                                                </div>}
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Sidebar>)} />
            <Modal className="modal--default" isOpen={editModal} toggle={closeEditModal()}>
                <ModalHeader toggle={closeEditModal()}>Edit Competition</ModalHeader>
                <ModalBody>
                    <Nav>
                        <NavItem>
                            <NavLink className={classnames({ active: modalTab === "Divisions" })} onClick={() => setModalTab("Divisions")}>
                                <span style={{ textDecorationLine: modalTab === "Divisions" ? "underline" : "" }}>Divisions</span>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({ active: modalTab === "Teams" })} onClick={() => setModalTab("Teams")} >
                                <span style={{ textDecorationLine: modalTab === "Teams" ? "underline" : "" }}>Teams</span>
                            </NavLink>
                        </NavItem>
                        <NavItem >
                            <NavLink className={classnames({ active: modalTab === "Settings" })} onClick={() => setModalTab("Settings")} >
                                <span style={{ textDecorationLine: modalTab === "Settings" ? "underline" : "" }}>Settings</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={modalTab}>
                        <TabPane tabId="Divisions">
                            <Row>
                                <Col>
                                    <a className="btn btn--circle text-white float-right" onClick={handleCreateDivision()}><i className="far fa-plus" /></a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>

                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="Teams">
                            <Row>
                                <Col>
                                    <a className="btn btn--circle text-white float-right" onClick={handleCreateTeam()}><i className="far fa-plus" /></a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        teams && teams.map(t => (
                                            <Col xs={12}>
                                                <Card className="mb-3 p-3">
                                                    <Row>
                                                        <Col lg={5} className="d-flex align-self-center">
                                                            <div className="col">
                                                                <h6 className="m-0">{t.displayName}</h6>
                                                                <p className="m-0 small">{t.description}</p>
                                                            </div>
                                                        </Col>
                                                        <Col lg={3} className="d-flex justify-content-center align-self-center">

                                                        </Col>
                                                        <Col lg={4} className="d-flex justify-content-end align-self-center">
                                                            <a className="btn btn--circle text-white" onClick={handleManageTeamMembers(t)}><i className="far fa-users" /></a>
                                                            <a className="btn btn--circle text-white" onClick={handleCancelTeamClick(t)}><i className="far fa-ban" /></a>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>

                                        ))
                                    }
                                    <Paging onChangePage={handleTeamPageChange()} meta={teamMeta} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="Settings">
                            <Row>
                                <Col>
                                    <a className="btn btn--circle text-white float-right" onClick={handleManageRoles(competition)}><i className="far fa-users-cog" /></a>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {competition && <div>
                                        <div className="form-group">
                                            <label className="form-control-label">Fixture Format</label>
                                            <label className="form-control">{Options.fixtureFormats.find(f => f.value == competition.fixtureFormat).label}</label>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label">Game Format</label>
                                            <label className="form-control">{Options.gameFormats.find(f => f.value == competition.gameFormat)?.label}</label>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label">Games Per Set</label>
                                            <label className="form-control">{Options.gamesPerSet.find(f => f.value == competition.gamesPerSet)?.label}</label>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label">Play Each Other</label>
                                            <label className="form-control">{competition.playEachOther}</label>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label">Format</label>
                                            <label className="form-control">{competition.format?.name}</label>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label">Points</label>
                                            <label className="form-control" title={competition.point?.description}>{competition.point?.name}</label>
                                        </div>
                                    </div>}
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-md btn-secondary btn-rounded" onClick={closeEditModal()}>Close</button>
                </ModalFooter>
            </Modal>
            <CompetitionWizardModal show={showWizard} fastFormat={true} onClose={() => setShowWizard(false)} onConfirm={load} />
        </PublicLayout>
    );
};