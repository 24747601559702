import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardBody, CardHeader, Row, Col, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { CostFeedback, Entry, EntryCosts, EntryPlayer, EntryStatus, EntryTeam } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { RegistrationService } from "@services/registration.service";
import EntryTeams from "./components/EntryTeams";
import { Registration, RegistrationCategory, RegistrationStatus } from "@models/registration";

import RegisterTeamSidebar from "@components/sidebar/public/RegisterTeamSidebar";
import RegisterPlayerSidebar from "@components/sidebar/public/RegisterPlayerSidebar";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import Header from "./components/Header";
import UpdateTeamSidebar from "@components/sidebar/public/UpdateTeamSidebar";



interface IEntryCategoriesPageProps {
	site?: string;
	match?: any;
	location?: any;
}

export default (props: IEntryCategoriesPageProps) => {
	const [loading, setLoading] = useState<boolean>(true);

	const [entryId, setEntryId] = useState<number>(props.match.params.entryId);
	const [entry, setEntry] = useState<Entry>();

	const [costs, setCosts] = useState<EntryCosts>();

	const [registration, setRegistration] = useState<Registration>();

	const [activeTab, setActiveTab] = useState<string>("");

	const [teamEntry, setTeamEntry] = useState<EntryTeam>();
	const [category, setCategory] = useState<RegistrationCategory>();
	const [playerEntry, setPlayerEntry] = useState<EntryPlayer>();

	const [showTeamSidebar, setShowTeamSidebar] = useState<boolean>();
	const [showPlayerSidebar, setShowPlayerSidebar] = useState<boolean>();
	const [showUpdateTeam, setShowUpdateTeam] = useState<boolean>();
	const [teamModal, setTeamModal] = useState<boolean>();
	const [modalData, setModalData] = useState<EntryTeam>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const entry = EntryService.getById(entryId).then(async result => {
			setEntry(result);
			const registration = await RegistrationService.getById(result.registrationId);
			setRegistration(registration);

			if (registration.categories.length > 0 && activeTab === "") {
				setActiveTab(`category_${registration.categories[0].id}`);
				setCategory(registration.categories[0]);
			}

			setLoading(false)
		});


		const costs = EntryService.costFeedback(entryId, true).then(result => setCosts(result));
	};

	const toggle = (category: RegistrationCategory) => (e) => {
		setActiveTab(`category_${category.id}`);
		setCategory(category);
	};

	const sidebar = () => {
		if (showTeamSidebar) {
			return <RegisterTeamSidebar category={category} entry={entry} onConfirm={() => { load(); }} onClose={() => { setShowTeamSidebar(false); setTeamEntry(null); }} />;
		} else if (showPlayerSidebar) {
			return <RegisterPlayerSidebar category={category} team={teamEntry} entry={entry} player={playerEntry} onConfirm={() => { load(); }} onClose={() => { setShowPlayerSidebar(false); setTeamEntry(null); setPlayerEntry(null); }} />;
		} else if (showUpdateTeam) {
			return <UpdateTeamSidebar category={category} registration={registration} team={teamEntry} entryId={entryId} onConfirm={(team: EntryTeam) => { 
				load();
				updateCheck(team);
			 }} 
			 onClose={() => { setShowUpdateTeam(false); }} />;
		}

		return <></>;
	};

	const openSidebar = () => {
		if (showTeamSidebar || showPlayerSidebar || showUpdateTeam) {
			return true;
		}

		return false;
	};

	const updateCheck = (team: EntryTeam) => {
		if (!(team?.name?.length > 0) || !(team?.contact?.length > 0) || !(team?.addressLine1?.length > 0) || !(team?.postcode?.length > 0) || !(team?.telephone?.length > 0)|| !(team?.email?.length > 0) || !(team?.shirtColors?.length > 0) && registration.requireKitDetails || !(team?.shortColors?.length > 0) && registration.requireKitDetails) {
			setTeamModal(true);
			setModalData(team);
		} else {
			setTeamEntry(null);
		}
	}

	const handleRegisterTeamClick = (category: RegistrationCategory, team?: EntryTeam) => {
		setTeamEntry(team);
		setCategory(category);
		setShowTeamSidebar(true);
	};

	const handleUpdateTeamClick = (team: EntryTeam) => {
		setTeamEntry(team);
		setShowUpdateTeam(true);
	};

	const handleRegisterPlayerClick = (category: RegistrationCategory, team: EntryTeam, player?: EntryPlayer) => {
		setTeamEntry(team);
		setPlayerEntry(player);
		setCategory(category);
		setShowPlayerSidebar(true);
	};

	return (
		<PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<Sidebar
						sidebar={sidebar()}
						pullRight={true}
						open={openSidebar()}
						sidebarClassName="sidebar-root"
						styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
						<div>
							<div className="page_content_wrap">
								<div className="container">
									<Row>
										<Col>
											<Header entry={entry} registration={registration} tab="Categories" />
											<div>
												<Nav className="d-flex w-100">
													{
														registration.categories.map(c => (
															<NavItem>
																<NavLink
																	className={classnames({ active: activeTab === `category_${c.id}` }, "text-center")}
																	onClick={toggle(c)}>
																	{c.name}
																</NavLink>
															</NavItem>
														))
													}
													<NavItem className="ml-auto">
														{/* <button onClick={() => setShowDeletedTeams(!showDeletedTeams)} className="btn btn-circle btn-outline btn-icon mb-2" title={showDeletedTeams ? "View Active Teams" : "View Deleted Teams"}>
																<div className={`tx-20`}><i className={`fal fa-${showDeletedTeams ? "users" : "trash-alt"}`}></i></div>
															</button> */}
													</NavItem>
													<NavItem>
														{registration.status != RegistrationStatus.Closed && <a onClick={() => handleRegisterTeamClick(category)} title="Add Team" className="btn btn-circle btn-secondary text-white btn-outline btn-icon mr-2 mb-2">
															<div className={`tx-20`}><i className="fal fa-plus"></i></div>
														</a>}
													</NavItem>
												</Nav>
												<TabContent activeTab={activeTab}>
													{
														registration.categories.map(c => (
															<TabPane tabId={`category_${c.id}`}>
																<Row>
																	<Col>
																		<EntryTeams costs={costs} entry={entry} registration={registration} category={c} onRegisterTeam={handleRegisterTeamClick} onUpdateTeam={handleUpdateTeamClick} onRegisterPlayer={handleRegisterPlayerClick} />
																	</Col>
																</Row>
															</TabPane>
														))
													}
												</TabContent>
											</div>
										</Col>
									</Row>
								</div>
							</div>
						</div>
					</Sidebar>)} />
					<Modal className="modal--default" isOpen={teamModal} toggle={() => { setTeamModal(false); setShowUpdateTeam(true); setModalData(null);}}>
						<ModalHeader>Missing Fields</ModalHeader>
						<ModalBody>
							<div className="col-12">
								<label>Fields missing that are required for team Registration</label>
								<label>Missing Fields:</label>
								<ul>
									{!(modalData?.name?.length > 0) ? <li>Name</li> : undefined}
									{!(modalData?.contact?.length > 0) ? <li>Contact Name</li> : undefined}
									{!(modalData?.addressLine1?.length > 0) ? <li>Address Line 1</li> : undefined}
									{!(modalData?.postcode?.length > 0) ? <li>Postcode</li> : undefined}
									{!(modalData?.telephone?.length > 0) ? <li>Phone Number</li> : undefined}
									{!(modalData?.email?.length > 0) ? <li>Email</li> : undefined}
									{!(modalData?.shirtColors?.length > 0) && registration?.requireKitDetails ? <li>Shirt Colors</li> : undefined}
									{!(modalData?.shortColors?.length > 0) && registration?.requireKitDetails ? <li>Short/Skirt/Legging Colours</li> : undefined}
								</ul>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn btn-md btn-secondary btn-rounded" onClick={() => { setTeamModal(false); setTeamEntry(null); setModalData(null); }}>Confirm</button>
							<button className="btn btn-md btn-primary btn-rounded" onClick={() => { setTeamModal(false); setShowUpdateTeam(true); setModalData(null); }}>Cancel</button>
						</ModalFooter>
					</Modal>
		</PublicLayout>
	);
};