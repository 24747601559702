import React from "react";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import AsyncSelect from 'react-select/async';
import { Competition, CompetitionDay, CreateCompetition } from "@models/competition";
import { HelpPopover } from "@components/controls/Popover";
import { CompetitionService } from "@services/competition.service";

interface ICreateCompetitionSidebar {

	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: ICreateCompetitionSidebar) => {
	const [competition, setCompetition] = useState<CreateCompetition>(new CreateCompetition());

	useEffect(() => {
		load();
	}, []);



	const load = async () => {

	};

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {
		var model = competition;
		if (model.name.length > 0 && model.prefabId != null) {
			model.fastFormat = true;
			await CompetitionService.create(model);
			props.onConfirm();
			props.onClose();
		}
	};

	const handleChange = (property: Function) => (e: any) => {
		const name = /\.([^\.;]+);?\s*\}$/.exec(property.toString())[1];
		let value = null;

		if (e == null) {
			value = null;
		} else if (e.value) {
			value = e.value;
		} else if (e.currentTarget) {
			value = e.currentTarget.value;
		} else {
			value = e;
		}

		setCompetition({ ...competition, [name]: value });
	}

	const loadCompetitionPrefabOptions = () => (value: string) => {
		return new Promise(resolve => {
			CompetitionService.getPresets(value).then(results => {
				const options = results.map(m => ({ label: m.name, value: m.id }));
				resolve(options);
			});
		});
	}

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Create Competition
					<div className="help-container">
						<a className="help">
							<i className="far fa-info-circle"></i>
						</a>
					</div>
				</h6>
			</div>
			<div className="sidebar--content">
				<form>
					<div className="form-layout">
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label className="form-control-label">Name <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" name="name" placeholder="Enter league name" value={competition.name} onChange={handleChange(() => competition.name)} />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label className="form-control-label w-100">Preset <span className="tx-danger">*</span>
										<HelpPopover id="preset" title="Competition Template">
											<p>Selecting from the list below will use a competition type as the basis and template for your competition</p>
										</HelpPopover>
									</label>
									<AsyncSelect
										defaultOptions
										loadOptions={loadCompetitionPrefabOptions()}
										closeMenuOnSelect={true}
										onChange={handleChange(() => competition.prefabId)} />
								</div>
							</div>
						</div>
						<div className="form-layout-footer">
							<Row>
								<Col>
									<button className="btn btn-primary btn-rounded btn-outline bd-0 mg-r-5" type="button" onClick={handleConfirm()}>Confirm</button>
								</Col>
								<Col>
									<button className="btn btn-secondary btn-rounded btn-outline bd-0" type="button" onClick={handleClose()}>Cancel</button>
								</Col>
							</Row>
						</div>
					</div>
				</form>
			</div>
			<div className="sidebar--footer">

			</div>
		</div>
	);
};