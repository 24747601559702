import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { MemberRegistration, Member, MemberSetup, MemberAccess, MemberDetails, Platform, CreateMember, SearchMember, MemberIdentity, MemberRoles, MemberNotifications } from "../models/member";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { SelectOption } from "@models/forms";

import browser from "browser-detect";

import RestHelper from "@helpers/rest.helper";
import { TeamCaptain } from "@models/team";
import { MemberFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

import * as qs from "qs";
import { MemberQuickSearch } from "@models/filters/searchOptions";
import { TenantRole } from "@models/tenant";

export class MemberService {

	constructor() {
		//
	}

	public static data(expired: boolean, query: DatatableQuery): Promise<DatatableResponse<Member>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({ expired, query }),
				success: (data: DatatableResponse<Member>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByIdAndTenant(memberId: string, tenant: string): Promise<Member> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`members/${memberId}/tenant/${tenant}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getById(memberId: string): Promise<Member> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/${memberId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Member, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByIds(memberIds: string[]): Promise<MemberIdentity[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/memberIds`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(memberIds),
				success: (data: MemberIdentity[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static search(options: MemberQuickSearch): Promise<MemberIdentity[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`members/search`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static roles(roles: MemberRoles): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/roles`,
				cache: false,
				method: "POST",
				data: JSON.stringify(roles),
				contentType: "application/json",
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static notifications(notifications: MemberNotifications): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`members/notifications`, notifications).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static acceptTerms(policies: number[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/privacypolicy/accept`,
				cache: false,
				method: "POST",
				data: JSON.stringify(policies),
				contentType: "application/json",
				success: (data: boolean, status) => {
					if (data) {
						const details = LoginService.getDetails();
						details.policiesRequired = [];
						LoginService.store(details, true);
					}

					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static upload(image: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/upload`,
				cache: false,
				method: "POST",
				data: JSON.stringify({ image }),
				contentType: "application/json",
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static me(): Promise<MemberDetails> {
		const result = browser();
		const details = new MemberAccess();
		details.platform = Platform.Web;
		details.model = `${result.name} (${result.version})`;
		details.os = result.os;
		details.screen = `${window.screen.width}x${window.screen.height}`;

		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/me`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(details),
				success: (data: MemberDetails, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}


	public static create(member: CreateMember): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`members`, member).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static import(): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `members/import`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject(xhr.responseJSON as RestException);
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static importFile(file): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`members/import`, file).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCaptains(): Promise<TeamCaptain[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`members/captains?take=0`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsert(member: Member): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`members`, member).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static delete(memberId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`members/${memberId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static get(options: MemberFilterOptions): Promise<PagedList<Member>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`members`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static setup(id: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`members/${id}/setup`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static resetRoles(memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`members/${memberId}/roles/reset`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getRoles(memberId: string): Promise<TenantRole[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`members/${memberId}/roles`).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static checkPlayerTeamAvailablity(memberId: string, teamId: number): Promise<string[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`members/${memberId}/team/${teamId}/check`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
