import { NProgress } from 'nprogress';
import store from "store2";
import { Basket, BasketItem } from "@models/basket";
import short from "short-uuid";
import { isPast, parseISO } from "date-fns";
import { WebsiteContext } from "@context/BasketContext";
import { useContext } from "react";
import RestHelper from "@helpers/rest.helper";

export class BasketService {

    static contextType = WebsiteContext;

    constructor() {
        //
    }

    public static async get(): Promise<Basket> {
        let basket = store.get("basket") as Basket;
        

        if (!basket) {
            basket = new Basket();
            basket.id = short.generate();
        }

        basket.items.forEach(async (i) => {
			if (isPast(parseISO(i.expires.toString()))) {
				basket = await this.remove(basket, i);
			}
		});

        store.set("basket", basket);
        return basket;
    }

    public static async remove(basket: Basket, item: BasketItem): Promise<Basket> {
		await BasketService.cancelBasketItem(item);

		const items = basket.items.filter(c => c.id !== item.id);
		basket.items = items;
		basket.total -= item.total;

		store.set("basket", basket);

		return basket;
	}

    public static async add(item: BasketItem): Promise<Basket> {
        item.id = short.generate();
        const basket = await this.get();
        basket.items.push(item);
		basket.total += item.total;

        store.set("basket", basket);

        return basket;
    }

    public static clear(removeReservation: boolean) {

        let basket = store.get("basket") as Basket;

        if(removeReservation){
            basket.items.forEach(async element => {
                await BasketService.cancelBasketItem(element);
            });
        }
        
        store.remove("basket");
    }

    public static cancelBasketItem(basket: BasketItem): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/basket/item/cancel`, basket)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}
}