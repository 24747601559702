import React from "react";

import { Can } from "@components/controls/Authentication";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import { LoginService } from "../../services/login.service";

import { AppConfig, ReleaseType } from "@config";
import { Website, WebsiteImageType } from "@models/website";

import { ToastContainer, ToastOptions, ToastPosition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ScrollToTop from "../controls/ScrollToTop";

import "popper.js";
import moment from "moment"; 
import History from "@helpers/history.helper";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { AppCache } from "@cache";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TenantType } from "@models/tenant";

interface IEmptyLayoutProps {
	//
	showNav?: boolean;
	showLogin?: boolean;
	overlayFooter?: boolean;
	hideScroll?: boolean;
	showFooter?: boolean;
	className?: string;
	theme?: string;
	print?: any;
}

interface IMenu {
	pages: [];
}

interface IEmptyLayoutState {
	//
	loading: boolean;
	menu: IMenu;
}

export class EmptyLayout extends React.Component<IEmptyLayoutProps, IEmptyLayoutState> {

	constructor(props) {
		super(props);

		this.state = { loading: false, menu: { pages: [] } };
	}

	static defaultProps = {
		showNav: true,
		overlayFooter: false,
		showLogin: true,
		hideScroll: false,
		showFooter: true,
		className: "",
		theme: "scheme_default"
	};

	componentDidMount() {
		//
	}

	componentDidUpdate(nextProps, nextState) {
		//
	}

	logout() {
		LoginService.logout();
	}

	isActive(url: string[]) {
		for (let i = 0; i < url.length; i++) {
			const element = url[i];
			if ((element !== "/" && window.location.pathname.includes(element)) || window.location.pathname === element) {
				return "active";
			}
		}

		return "";
	}

	public render() {

		const Close = () => <a className="close-button"><i className="la la-times"></i></a>;

		return (
			<div className={`${this.props.theme}`}>
				<div className={`${this.props.className} d-print-none`}>
					<Helmet titleTemplate={`%s | TT Leagues`}>
						<title>Home</title>
						<meta name="description" content={`The official website for TT Leagues`} />
						<link rel="canonical" href={`${window.location.href}`} />
					</Helmet>
					<ScrollToTop />
					<ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
					<header />
					<div className="body_wrap">
						<div className="page_wrap">
							<header className="top_panel top_panel_custom top_panel_custom_1578 top_panel_custom_header-default-simple without_bg_image">
								<nav className="navbar navbar-expand-lg navbar-dark navbar-quick">
									<div className="container">
										<ul className="navbar-nav navbar-social left">
											<li>
												<a href={`https://twitter.com/`} title="Twitter" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-twitter"></i></span></a>
											</li>
											<li>
												<a href={`https://facebook.com/`} title="Facebook" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-facebook"></i></span></a>
											</li>
											<li>
												<a href={`https://instagram.com/`} title="Instagram" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-instagram"></i></span></a>
											</li>
											<li>
												<a href={`https://wa.me/`} title="WhatsApp" target="_blank" className=""><span className="tx-22 m-2"><i className="fab fa-whatsapp"></i></span></a>
											</li>
										</ul>
										<ul className="navbar-nav navbar-top ml-auto">
										</ul>
									</div>
								</nav>

								<nav className="navbar navbar-menu navbar-expand-lg navbar-light bg-white">
									<div className="container">
										<Link to={"/"} className="navbar-brand">
											<h5>TT Leagues</h5>
										</Link>
										<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
											<span className="navbar-toggler-icon"></span>
										</button>

										<div className="collapse navbar-collapse" id="navbarSupportedContent">
											{/* <ul className="navbar-nav ml-auto">
												<li className="nav-item dropdown">
													<Link className="nav-link" to={"/"}>Home</Link>
												</li>
											</ul> */}
										</div>
									</div>
								</nav>
							</header>
							{this.props.children}
							<footer className="mt-md-5 footer_wrap footer_custom footer_custom_872 footer_custom_footer-home-2						 scheme_dark						">
								<div className="elementor elementor-872 elementor-bc-flex-widget">
									<div className="elementor-inner">
										<div className="elementor-section-wrap">
											<section>
												<div className="sc_layouts_item elementor-element elementor-element-6743357d sc_fly_static elementor-widget elementor-widget-spacer">
													<div className="elementor-widget-container">
														<div className="elementor-spacer">
															<div className="elementor-spacer-inner" />
														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-element elementor-element-2e7e245e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
												data-id="2e7e245e"
												data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div className="elementor-row">
														<div
															className="elementor-element elementor-element-77ff75d0 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column"
															data-id="77ff75d0"
															data-element_type="column">
															<div className="elementor-column-wrap  elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div
																		className="sc_layouts_item elementor-element elementor-element-39c2827b sc_fly_static elementor-widget elementor-widget-spacer"
																		data-id="39c2827b"
																		data-element_type="widget"
																		data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner" />
																			</div>
																		</div>
																	</div>
																	<div
																		className="sc_layouts_item elementor-element elementor-element-73075dae sc_fly_static elementor-widget elementor-widget-divider"
																		data-id="73075dae"
																		data-element_type="widget"
																		data-widget_type="divider.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-divider">
																				<span className="elementor-divider-separator" />
																			</div>
																		</div>
																	</div>
																	<div
																		className="sc_layouts_item elementor-element elementor-element-6a425bd6 sc_fly_static elementor-widget elementor-widget-spacer"
																		data-id="6a425bd6"
																		data-element_type="widget"
																		data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
											<section
												className="elementor-element elementor-element-2189e58e elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
												data-id="2189e58e"
												data-element_type="section">
												<div className="elementor-container elementor-column-gap-extended">
													<div className="elementor-row">
														<div
															className="elementor-element elementor-element-7cc27e17 sc_layouts_column sc_layouts_column_align_left sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-50 elementor-top-column"
															data-id="7cc27e17"
															data-element_type="column">
															<div className="elementor-column-wrap  elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div
																		className="sc_layouts_item elementor-element elementor-element-5d516a8a sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_menu"
																		data-id="5d516a8a"
																		data-element_type="widget"
																		data-widget_type="trx_sc_layouts_menu.default">
																		<div className="elementor-widget-container">
																			<nav
																				className="sc_layouts_menu sc_layouts_menu_default sc_layouts_menu_dir_horizontal menu_hover_fade hide_on_mobile inited"
																				id="trx_sc_layouts_menu_788888722"
																				data-animation-in="fadeIn"
																				data-animation-out="fadeOut">
																				<ul
																					id="sc_layouts_menu_166904521"
																					className="sc_layouts_menu_nav prepared inited sf-js-enabled sf-arrows"
																					style={{ touchAction: "pan-y" }}>
																					<li className="menu-item">
																						<Link to={"/"}>
																							<span>Home</span>
																						</Link>
																					</li>
																					<li className="menu-item">
																						<Link to={"/leagues"}>
																							<span>Leagues</span>
																						</Link>
																					</li>
																					<li className="menu-item">
																						<Link to={"/articles"}>
																							<span>News</span>
																						</Link>
																					</li>
																					<li className="menu-item">
																						<Link to={"/venues"}>
																							<span>Venues</span>
																						</Link>
																					</li>
																				</ul>
																			</nav>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="elementor-element elementor-element-497bd034 sc_layouts_column sc_layouts_column_align_right sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-50 elementor-top-column">
															<div className="elementor-column-wrap  elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div className="sc_layouts_item elementor-element elementor-element-32d19e29 copyright-text sc_fly_static elementor-widget elementor-widget-text-editor">
																		<div className="elementor-widget-container">
																			<div className="elementor-text-editor elementor-clearfix">
																				<p>
																					Copyright ©&nbsp; <a href="https://www.ttleagues.com/" rel="noopener">Table Tennis England</a> 2019. All Rights Reserved.
																			</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
										</div>
									</div>
								</div>
							</footer>
						</div>
					</div>
					<a
						href="#"
						className="trx_addons_scroll_to_top trx_addons_icon-up inited"
						title="Scroll to top"
					/>
					{/* Instagram Feed JS */}
					{/*[if lte IE 9]>
					<![endif]*/}
					<span id="elementor-device-mode" className="elementor-screen-only" />
				</div>
				{
					this.props.print ?
						<div className="d-none d-print-block">
							{this.props.print()}
						</div> : undefined
				}
			</div>
		);
	}
}
