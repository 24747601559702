import { SortingProperty } from "./filters/filterOptions";
import { Member, MemberNotifications, MemberRoles, MembershipType } from "./member";

export class User {
	public id: string;
	public linkId: number;
	public name: string;
	public firstName: string;
	public lastName: string;
	public addressLine1: string;
	public addressLine2: string;
	public city: string;
	public county: string;
	public country: string;
	public postcode: string;
	public displayName: string;
	public email: string;
	public userName: string;
	public phoneNumber: string;
	public birthDate: Date;
	public picture: string;
	public initials: string;
	public imageId?: number;
	public membershipNo: number;
	public membershipType: string;
	public expiry?: Date;
	public roles: MemberRoles;
	public notifications: MemberNotifications;
	public type?: MembershipType;
	public clubs: string[];
	public gender: Gender;
	public dob?: Date;
	public age?: number;
}

export class UserLogin {
	public name: string;
	public userId: string;
	public membershipNo: string;
	public current: boolean;
}

export class UserBasic {
	public id: string;
	public name: string;
}

export enum Gender {
	Male = 1,
	Female = 2
}

export class UserSettings {
	public pageSize: number = 10;
	public invoiceSorting: SortingProperty[];
}
