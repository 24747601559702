import { Match } from "@models/match";
import * as moment from "moment";

export const SHARE_SITES = {
    GOOGLE: 'Google',
    ICAL: 'iCal',
    OUTLOOK: 'Outlook',
    YAHOO: 'Yahoo',
};

export class Event {
    description = '';
    ctz = '';
    endDateTime;
    location = '';
    startDateTime;
    timezone = '';
    title = '';
}

/**
 * Converts Date String with UTC timezone to date consumable by calendar
 * apps. Changes +00:00 to Z.
 * @param {string} Date in YYYYMMDDTHHmmssZ format
 * @returns {string} Date with +00:00 replaceed with Z
 */
export const formatDate = date => date && date.replace('+00:00', 'Z');

export const formatDuration = duration => {
    if (typeof duration === 'string') return duration;
    const parts = duration.toString().split('.');
    if (parts.length < 2) {
        parts.push('00');
    }

    return parts.map(part => part.length === 2 ? part : `0${part}`).join('');
};

/**
 * Tests provided UserAgent against Known Mobile User Agents
 * @returns {bool} isMobileDevice
 */
export const isMobile = () => /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(window.navigator.userAgent || window.navigator.vendor);

/**
 * Tests userAgent to see if browser is IE
 * @returns {bool} isInternetExplorer
 */
export const isInternetExplorer = () => /MSIE/.test(window.navigator.userAgent) || /Trident/.test(window.navigator.userAgent);

/**
 * Takes an event object and returns a Google Calendar Event URL
 * @param {string} event.description
 * @param {string} event.endDateTime
 * @param {string} event.location
 * @param {string} event.startDateTime
 * @param {string} event.title
 * @returns {string} Google Calendar Event URL
 */
const googleShareUrl = ({
    description,
    endDateTime,
    location,
    startDateTime,
    timezone,
    title,
}) =>
    `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${
        startDateTime
    }/${endDateTime}${timezone && `&ctz=${timezone}`}&location=${location}&text=${title}&details=${description}`;

/**
 * Takes an event object and returns a Yahoo Calendar Event URL
 * @param {string} event.description
 * @param {string} event.duration
 * @param {string} event.location
 * @param {string} event.startDateTime
 * @param {string} event.title
 * @returns {string} Yahoo Calendar Event URL
 */
const yahooShareUrl = ({
    description,
    duration,
    location,
    startDateTime,
    title,
}) =>
    `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${title}&st=${
    startDateTime
    }&dur=${duration}&desc=${description}&in_loc=${location}`;

/**
 * Takes an event object and returns an array to be downloaded as ics file
 * @param {string} event.description
 * @param {string} event.endDateTime
 * @param {string} event.location
 * @param {string} event.startDateTime
 * @param {string} event.title
 * @returns {array} ICS Content
 */
const buildShareFile = ({
    description = '',
    ctz = '',
    endDateTime,
    location = '',
    startDateTime,
    timezone = '',
    title = '',
}) => {
    let content = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `URL:${document.URL}`,
        'METHOD:PUBLISH',
        // TODO: Will need to parse the date without Z for ics
        // This means I'll probably have to require a date lib - luxon most likely or datefns
        timezone === '' ? `DTSTART:${startDateTime}` : `DTSTART;TZID=${timezone}:${startDateTime}`,
        timezone === '' ? `DTEND:${endDateTime}` : `DTEND;TZID=${timezone}:${endDateTime}`,
        `SUMMARY:${title}`,
        `DESCRIPTION:${description}`,
        `LOCATION:${location}`,
        'END:VEVENT',
        'END:VCALENDAR',
    ].join('\n');

    return isMobile() ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content;
}

/**
 * Takes an event object and a type of URL and returns either a calendar event
 * URL or the contents of an ics file.
 * @param {string} event.description
 * @param {string} event.duration
 * @param {string} event.endDateTime
 * @param {string} event.location
 * @param {string} event.startDateTime
 * @param {string} event.title
 * @param {enum} type One of SHARE_SITES from ./enums
 */
export const buildShareUrl = (
    { description = '', duration, endDateTime, location = '', startDateTime, timezone = '', title = '' },
    type,
) => {
    const encodeURI = type !== SHARE_SITES.ICAL && type !== SHARE_SITES.OUTLOOK;

    const data = {
        description: encodeURI ? encodeURIComponent(description) : description,
        duration: formatDuration(duration),
        endDateTime: formatDate(endDateTime),
        location: encodeURI ? encodeURIComponent(location) : location,
        startDateTime: formatDate(startDateTime),
        timezone,
        title: encodeURI ? encodeURIComponent(title) : title,
    };

    switch (type) {
        case SHARE_SITES.GOOGLE:
            return googleShareUrl(data);
        case SHARE_SITES.YAHOO:
            return yahooShareUrl(data);
        default:
            return buildShareFile(data);
    }
};

/**
 * Takes an event object and a type of URL and returns either a calendar event
 * URL or the contents of an ics file.
 * @param {string} event.description
 * @param {string} event.duration
 * @param {string} event.endDateTime
 * @param {string} event.location
 * @param {string} event.startDateTime
 * @param {string} event.title
 * @param {enum} type One of SHARE_SITES from ./enums
 */
export const buildShareMatchesUrl = (matches: Match[]) => {
    const items = [];
    
    // bof
    items.push('BEGIN:VCALENDAR');
    items.push('VERSION:2.0');

    matches.forEach(m=> {

        const date = moment.utc(m.date).format("YYYYMMDD");
        const time = moment.utc(m.time).format("HH:mm");
        const datetime = moment.utc(`${date} ${time}`, "YYYYMMDD HH:mm");

        const e = {
            title: `${m.home?.name ?? m.homeName} vs ${m.away?.name ?? m.awayName}`,
            description: `${m.name} - ${m.competition}`,
            location: m.venue,
            duration: "1",
            startDateTime: datetime.format("YYYYMMDDTHH:mm:ssZ"),
            endDateTime: datetime.add(1, "hours").format("YYYYMMDDTHH:mm:ssZ")
        };

        items.push('BEGIN:VEVENT');
        items.push(`URL:${document.URL}`);
        items.push(`DTSTART:${formatDate(e.startDateTime)}`);
        items.push(`DTEND:${formatDate(e.endDateTime)}`);
        items.push(`SUMMARY:${e.title}`);
        items.push(`DESCRIPTION:${e.description}`);
        items.push(`LOCATION:${e.location}`);
        items.push('END:VEVENT');
    });

    // eof
    items.push('END:VCALENDAR');
    
    let content = items.join('\n');

    return /* isMobile() ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : */ content;
};