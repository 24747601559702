import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Division, CreateDivision } from "@models/division";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Standing } from "@models/standing";
import { MatchGrouping, MatchCard, CreateMatch, MatchStatus, Match, MatchGroupRange, MatchGroupType, MatchGroup, MatchSet, PotmType, MatchFilters, MatchResults, MatchCardEntryLevel, MatchReport } from "@/models/match";
import { Entrant } from "@models/entrant";
import { TeamMember } from "@models/team";
import { MatchGame } from "../models/match";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { MemberRanking } from "@models/member";
import * as qs from "query-string";
import { RankingStats } from "@models/rankings";

export class RankingService {

	constructor() {
		//
	}

	public static get(leagueId?:number, competitionId?: number, divisionId?: number, search?: string): Promise<MemberRanking[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<MemberRanking[]>(`rankings`,
			{
				params: {leagueId, competitionId, divisionId, search},
				paramsSerializer: params => qs.stringify(params)
			})
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static rerun(): Promise<MemberRanking[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<MemberRanking[]>(`rankings/rerun`,
			{
				params: {},
				paramsSerializer: params => qs.stringify(params)
			})
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static rerunPermanent(): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper.get<any>(`rankings/rerun/permanent`,
			{
				params: {},
				paramsSerializer: params => qs.stringify(params)
			})
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static reset(leagueId:number): Promise<void> {
		return new Promise((resolve, reject) => {
			RestHelper.get<void>(`rankings/reset`,
			{
				params: {leagueId},
				paramsSerializer: params => qs.stringify(params)
			})
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getStats(userId: string): Promise<RankingStats> {
		return new Promise((resolve, reject) => {
			RestHelper.get<RankingStats>(`rankings/${userId}/stats`)
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static remove(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`rankings/${id}/delete`)
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static adjust(id: number, total: number, start: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`rankings/${id}/adjust?total=${total}&start=${start}`)
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCategories(): Promise<string[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<string[]>(`rankings/national/categories`)
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static import(): Promise<void> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `import/rankings`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:void, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}
}