import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";
import History from "@helpers/history.helper";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { Venue } from "@models/venue";
import { VenueService } from "@services/venue.service";

// -- toggle for header 
import { Toggle } from "@components/controls/Toggle";
import StateHelper from "@helpers/state.helper";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { WebsiteService } from "@services/website.service";
import { StaticMap } from "@components/controls/StaticMap";
import { StaticMarker } from "@components/controls/StaticMarker";


export const googleApiKey = AppConfig.googleApi;
declare const google: any;

interface IVenueListPageProps {
	site?: string;
	match?: any;
}

interface IVenueListPageState {
	loading: boolean;
	nextPathname?: any;
	venues: Venue[];
	viewAllVenues: boolean;
	dynamicMapVisible: boolean;
}

export const googleMapURL = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyA1IYJvmYQFPyrcT6l45Tj6zLZ1iytet_4';

const containerStyle = {
	width: '100%',
	height: '467.59px',
	marginbottom: '300px',
};

const containerStylePreview = {
	width: '100%',
	height: '300px',
	marginbottom: '300px',
};

export class VenueListPage extends React.Component<IVenueListPageProps, IVenueListPageState> {

	private pageNo: number = 1;
	private ref: React.RefObject<HTMLDivElement>;
	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.state = {
			loading: true,
			venues: [],
			viewAllVenues: true,
			dynamicMapVisible: false,
		};
	}

	componentDidMount() {
		WebsiteService.updateActivity("Venues");
		window.addEventListener("scroll", () => this.onScroll());
		this.load();
	}

	componentDidUpdate(props: IVenueListPageProps, state: IVenueListPageState) {
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", () => this.onScroll());
	}

	load() {
		const p1 = VenueService.get().then(venues => {
			this.setState({ venues });
		});

		Promise.all([p1]).then(result => {
			this.setState({ loading: false });
		});
	}

	onScroll() {
		if(this.ref.current != null){
			var bounding = this.ref.current.getBoundingClientRect();
			var top = bounding.top - 500;
			var bottom = bounding.bottom + 500;
			if(top <= window.innerHeight && bottom >= 0){
				if(!this.state.dynamicMapVisible){
					this.setState({dynamicMapVisible: true})
				}
			}
			else{
				
				if(this.state.dynamicMapVisible){
					this.setState({dynamicMapVisible: false})
				}
			}
		}
	}

	public render() {

		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Venues</h1>
																			<p className="header--description"></p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">Venues</h1>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="row">
										<div className="col-lg col-md-12 mb-4">
											<div className="content">
												<div className="posts_container">
													<div className="row">
														{
															this.state.venues.map(a => {
																return (
																	<div className="col-12 col-md-6 col-lg-4 d-flex mb-4">
																		<article className="venue w-100 post_item post_format_standard post_layout_classic post_layout_classic_3 post-174 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-blog tag-champions tag-competition">
																			<div className="post_featured with_thumb hover_simple text-center background-image" style={{ height: "300px", backgroundImage: `url(${a.previewId ? `${AppConfig.baseUrl}image/${a.previewId}` : require("@assets/images/placeholders/tte-square.png")})` }}>
																				{
																					a.latitude && a.longitude && !a.previewId &&
																					<div>
																						{/* <LoadScript googleMapsApiKey={googleApiKey}>
																							<GoogleMap mapContainerStyle={containerStylePreview}
																								center={{ lat: parseFloat(a.latitude), lng: parseFloat(a.longitude) }}
																								zoom={15}
																								options={}>
																								<Marker position={{ lat: parseFloat(a.latitude), lng: parseFloat(a.longitude) }} />
																							</GoogleMap>
																						</LoadScript> */}
																						<StaticMap
																							mapContainerStyle={containerStylePreview}
																							center={{ lat: parseFloat(a.latitude), lng: parseFloat(a.longitude) }}
																							zoom={15}
																							googleMapsApiKey={googleApiKey}>
																							<StaticMarker position={{ lat: parseFloat(a.latitude), lng: parseFloat(a.longitude) }}/>
																						</StaticMap>
																					</div>
																				}
																				<div className="mask"></div>
																				<Link to={`/venue/${a.id}`} className="icons"></Link>
																			</div>
																			<div className="post_layout_classic_wrap">
																				<div className="post_header entry-header">
																					<div className="post_meta">
																						<span className="post_meta_item post_date">
																							{a.addressLine1}, {a.postcode}
																						</span>
																					</div>
																					<h5>

																						<Link to={`/venue/${a.id}`} className="icons">{a.name}</Link>
																					</h5>
																				</div>
																				<div className="post_content entry-content">
																					<div className="post_content_inner"></div>
																				</div>
																				<div className="post_meta post_meta_single">
																					{
																						a.clubs.length > 0 ?
																							<span className="tags">
																								{
																									a.clubs.map(t => {
																										return (
																											<Link to={`/club/${t.id}`}>{t.name}</Link>
																										)
																									})
																								}
																							</span> : undefined
																					}
																				</div>
																			</div>
																		</article>
																	</div>
																);
															})
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div ref={this.ref} style={{height: containerStyle.height, width: containerStyle.width}}>
								{this.state.dynamicMapVisible && <LoadScript googleMapsApiKey={googleApiKey}>
									<section>
										<GoogleMap
											clickableIcons={false}
											mapContainerStyle={containerStyle}
											onLoad={(map) => {
												if(this.state.venues && this.state.venues.length > 1){
													const bounds = new google.maps.LatLngBounds();
													this.state.venues.forEach((l, i) => {
														if (l.latitude && l.longitude) {
															const position = new google.maps.LatLng(l.latitude, l.longitude);
															bounds.extend(position);
														}
													});

													map.fitBounds(bounds);
												}
											}}
											center={this.state.venues && this.state.venues.length == 1 ? { lat: parseFloat(this.state.venues[0].latitude), lng: parseFloat(this.state.venues[0].longitude) } : undefined}
											zoom={10}>
											{
												this.state.venues.filter(v => v.latitude && v.longitude).map(venue => {
													return (<Marker onClick={() => History.push(`/venue/${venue.id}`)} position={{ lat: parseFloat(venue.latitude), lng: parseFloat(venue.longitude) }} />);
												})
											}
										</GoogleMap>
									</section>
								</LoadScript>
								// :
								// <section>
								// 	<div style={containerStyle}></div>
								// </section>
								}
							</div>
							{/* <StaticMap 
								mapContainerStyle={containerStyle}
								center={this.state.venues && this.state.venues.length == 1 ? { lat: parseFloat(this.state.venues[0].latitude), lng: parseFloat(this.state.venues[0].longitude) } : null}
								zoom={10}
								googleMapsApiKey={googleApiKey}>
								{
									this.state.venues.filter(v => v.latitude && v.longitude).map(venue => {
										return (<StaticMarker position={{ lat: parseFloat(venue.latitude), lng: parseFloat(venue.longitude) }} />);
									})
								}
							</StaticMap> */}
						</div>
					)}
				/>
			</PublicLayout>);
	}
}
