import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { LoginService } from "./login.service";

import store from "store2";

import * as moment from "moment";
import { Format } from "@/models/format";

export class FormatService {

	constructor() {
		//
	}

	public static get(): Promise<Format[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `formats`,
				cache: false,
				method: "GET",
				contentType: "application/json; charset=utf-8",
				success: (data:Format[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(format: Format): Promise<Format> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `formats/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(format),
				success: (data:Format, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static clone(formatId: number): Promise<Format> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `formats/${formatId}/clone`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:Format, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(formatId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `formats/${formatId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}
}
