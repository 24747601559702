import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import ObjectHelper from "@helpers/object.helper";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ArrayHelper } from "@helpers/array.helper";
import { CompetitionService } from "@services/competition.service";

interface IClubManagePageProps {
	site?: string;
	match?: any;
	location?: any;
}

interface IClubManagePageState {
	loading: boolean;
	nextPathname?: any;
	submitting: boolean;
	club?: Club;
	clubs?: Club[];
	competition?: Competition;
	competitions?: Competition[];
	teams?: Team[];
	activeTab: string;
}

export class ClubManagePage extends React.Component<IClubManagePageProps, IClubManagePageState> {
	private clubId?: number;

	constructor(props) {
		super(props);

		this.state = { loading: true, submitting: false, activeTab: (this.props.match?.params?.tabId ?? "1") };

		this.clubId = this.props.match.params.clubId as number;
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
		const clubId = this.props.match.params.clubId as number;

		if (clubId !== this.clubId) {
			this.clubId = clubId;
			this.setState({ activeTab: "1", loading: true }, () => this.load());
		}
	}

	load() {
		this.setState({ loading: true });

		if (this.clubId) {
			const p1 = ClubService.getById(this.clubId).then(club => {
				this.setState({ club });
			});

			const p2 = ClubService.getCompetitions(this.clubId).then(competitions => {
				this.setState({ competitions });
			});

			Promise.all([p2]).then(result => {
				this.setState({ loading: false });
			});
		}
	}

	loadTeams() {
		ClubService.getCompetitionTeams(this.clubId, this.state.competition.id).then(teams => {
			this.setState({teams});
		})
	}

	loadCompetition() {
		CompetitionService.getById(this.state.competition.id).then(competition => {
			this.setState({competition});
			this.loadTeams();
		})
	}

	onSortEnd(team, sort) {
		const members = ArrayHelper.arrayMove(team.members, sort.oldIndex, sort.newIndex);

		for (let i = 0; i < members.length; i++) {
			const element = members[i];
			element.ordering = i;
		}

		const teams = this.state.teams;
		var index = this.state.teams.findIndex(t => t.id == team.id)
		teams[index] = team;
		this.setState({ teams });

		TeamService.orderMembers(team.members).then(result => {

		});
	}

	toggle(tab: string) {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
	}

	sidebar = () => {
		return <></>;
	}

	openSidebar = () => {
		return false;
	}

	

	public render() {

		const SortableItem = SortableElement(({ value }) => (<div>
			<div key={`member-${value.memberId}`} className="list-group-item">
				<div className="row">
					<div className="col-1">
						<h4>
							{value.ordering + 1}
						</h4>
					</div>
					<div className="col-1">
						{
							value.imageId ?
								<img src={`${AppConfig.baseUrl}image/${value.imageId}`} alt="" /> :
								<span className="avatar">{value.initials}</span>
						}
					</div>
					<div className="col">
						<div>{value.name}</div>
						<span>{value.membershipNo}</span>
					</div>
				</div>
			</div>
		</div>));

		const SortableList = SortableContainer(({ items }) => {
			return (
				<div className="list-group list-group-user">
					{items.map((value, index) => (
						<SortableItem disabled={this.state.competition?.submissionPeriods?.length > 0 && this.state.competition?.submissionPeriods.every(s => !moment().isBetween(s.startDate, s.endDate))} key={`item-${index}`} index={index} value={value} />
					))}
				</div>
			);
		});

		return (
			<PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<Sidebar
							sidebar={this.sidebar()}
							pullRight={true}
							open={this.openSidebar()}
							sidebarClassName="sidebar-root"
							styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 9999 }, overlay: { zIndex: 999 } }}>
							<div>
								<div className="page_content_wrap">
									<div className="container">
										<Row>
											<Col xl={{ size: 10, offset: 1 }}>
											<Card className="mb-4 card--nav">
													<Row className="px-4">
														<Col md={12}>
															<div className="name">
																<div className="row">
																	<div className="col-11">
																		<h3 className="m-0">{this.state.club?.name}</h3>
																	</div>
																	<div className="col-1" style={{ float: "right" }}>
																	</div>
																</div>
																<div>
																	<p className="text-muted d-block m-0">Manage your competing teams and players</p>
																</div>
															</div>
														</Col>
													</Row>
													<Nav>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "1" })}
																onClick={() => { this.toggle('1'); }}>
																Ranking Submission
															</NavLink>
														</NavItem>
														{/* <NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "1" })}
																onClick={() => { this.toggle('2'); }}>
															</NavLink>
														</NavItem> */}
													</Nav>
												</Card>
												<div>
													<TabContent activeTab={this.state.activeTab}>
														<TabPane tabId="1">
															<Row>
																<Col>
																	{this.state.competition && this.state.teams?.length > 0 ? <div>
																		{(this.state.competition?.submissionPeriods?.length > 0)
																		? <div>
																			{this.state.competition?.submissionPeriods.some(s => moment().isBetween(s.startDate, s.endDate)) && <p>Ranking submission closes: {moment(this.state.competition?.submissionPeriods.filter(s => moment().isBetween(s.startDate, s.endDate)).sort((a, b) => moment(a.endDate).isSameOrBefore(b.endDate) ? 1 : -1)[0].endDate).format("dddd Do MMMM YYYY")}</p>}
																			{this.state.competition?.submissionPeriods.some(s => moment().isBefore(s.startDate)) && <p>Ranking submission next open: {moment(this.state.competition?.submissionPeriods.filter(s => moment().isBefore(s.startDate)).sort((a, b) => moment(a.startDate).isSameOrBefore(b.startDate) ? 1 : -1)[0].startDate).format("dddd Do MMMM YYYY")}</p>}
																		</div>
																		:
																		<div>
																			<p>Ranking submission open</p>
																		</div>}
																		{this.state.teams.filter(t => this.state.competition?.reserves.type != 6 || t.members?.length > 0).map(t => {
																			return (<Card className="section-wrapper mg-t-20">
																				<div className="section-wrapper-header">
																					<div className="float-left">
																						<label className="section-title">{t.name} Members</label>
																						<p className="mg-b-20 mg-sm-b-40">Current members within <strong>{t.name}</strong></p>
																					</div>
																					<div className="float-right">
																						<button className="btn p-0 tx-20" onClick={() => this.setState({competition: null})}>
																							<i className="far fa-times"></i>
																						</button>
																					</div>
																				</div>
																				<div className="row clearfix">
																					<div className="col-lg-12">
																						<SortableList distance={1} items={t.members} onSortEnd={this.onSortEnd.bind(this, t)} helperClass="list-group list-group-user" />
																					</div>
																				</div>
																			</Card>)}
																		)}
																	</div>
																	:
																	<div>
																		<div className="row">
																			{
																				this.state.competitions?.map(c => {
																					return (
																						<div className="col-4">
																							<div className="card p-3 mb-3">
																								<div className="row">
																									<div className="col-12">
																										<a onClick={() => {this.setState({competition: c}, () => {this.loadCompetition()})}} className="dropdown-item btn">{c.name}</a>
																									</div>
																								</div>
																							</div>
																						</div>
																					);
																				})
																			}
																		</div>
																	</div>}
																	
																</Col>
															</Row>
														</TabPane>
														{/* <TabPane tabId="2">
															<Row>
																<Col>
																</Col>
															</Row>
														</TabPane> */}
													</TabContent>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</Sidebar>)} />
			</PublicLayout>);
	}
}
