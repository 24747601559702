import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { BookingConfig, CompetitionConfig, CreateTenant, Tenant, TenantConfig, TenantDetails } from "@models/tenant";

import RestHelper from "@helpers/rest.helper";
import { FilterOptions, TenantFilterOptions } from "@models/filters/filterOptions";
import * as qs from "qs";
import { PagedList } from "@models/paging";


export class TenantService {

	constructor() {
		//
	}

	public static get(options: TenantFilterOptions): Promise<PagedList<Tenant>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`tenants`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static search(options: TenantFilterOptions): Promise<PagedList<TenantDetails>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`tenants/search`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getByHost(): Promise<Tenant> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
				},
				url: AppConfig.apiUrl + `tenants/host`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Tenant, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static upsert(tenant: Tenant): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants`, tenant).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static update(config: TenantConfig): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants/config`, config).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static updateCompetition(config: CompetitionConfig): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants/config/competition`, config).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static updateBooking(config: BookingConfig): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants/config/booking`, config).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}


	public static available(domain: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`tenants/domain/available`,
				{
					params: { domain },
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static updateDomain(domain: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants/domain`, { domain }).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static tenantOptions(): Promise<Tenant[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`tenants/articles/options`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static create(tenant: CreateTenant): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants/create`, tenant).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static delete(tenantId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`tenants/${tenantId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static cloneWebsiteContent(cloneFrom: number, cloneInto: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`tenants/website/clone`, {cloneFrom, cloneInto}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
