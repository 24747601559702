import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { ManageLayout } from "@components/layouts/ManageLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, Card, CardBody, Row, Col } from "reactstrap";

import { toast } from "react-toastify";
import { Toggle } from "@components/controls/Toggle";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import History from "@helpers/history.helper";
import { Member } from "@models/member";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { MemberService } from "@services/member.service";

import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { LeagueService } from "@services/league.service";
import { CompetitionService } from "@services/competition.service";

import { VenueService } from "@services/venue.service";
import { Competition, CompetitionGrouping, CreateCompetition } from "@models/competition";

import Sidebar from "react-sidebar";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { SelectOption } from "@models/forms";
import { Venue } from "@models/venue";

import { confirmAlert } from "react-confirm-alert"; // Import
import { HelpPopover } from "@components/controls/Popover";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { AppCache } from "@cache";
import { Can } from "@components/controls/Authentication";
import { LoginService } from "@services/login.service";
import { Options } from "@data";
import ObjectHelper from "@helpers/object.helper";
import { Registration } from "@models/registration";
import { PagedList } from "@models/paging";
import { RegistrationFilterOptions, FilterOptions } from "@models/filters/filterOptions";
import CreateRegistrationSidebar from "@components/sidebar/CreateRegistrationSidebar";
import { RegistrationService } from "@services/registration.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import PublicLayout from "@components/layouts/PublicLayout";
import { Club } from "@models/club";
import { ProgressButton } from "@components/controls/ProgressButton";
import { ClubService } from "@services/club.service";
import { EntryService } from "@services/entry.service";
import { Entry } from "@models/entry";



interface IRegistrationListProps {
	match?: any;
}

export default (props: IRegistrationListProps) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const [registrationId, setRegistrationId] = useState<number>(props.match.params.registrationId);
	const [registration, setRegistration] = useState<Registration>();
	const [clubs, setClubs] = useState<Club[]>([]);
	const [club, setClub] = useState<Club>();
	const [entries, setEntries] = useState<Entry[]>([]);

	const [showCreate, setShowCreate] = useState<boolean>(false);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const registration = await RegistrationService.getById(registrationId);
		setRegistration(registration);

		const clubs = await ClubService.getByMemberRegistration();
		setClubs(clubs);

		const entries = await EntryService.getByPermission();

		setEntries(entries);

		setLoading(false);
	}
	const handleRegisterClick = () => (e) => {
		setSubmitting(true);
		var entry = new Entry();
		entry.registrationId = registrationId;
		entry.clubId = club.id;
		EntryService.registerClub(entry).then(result =>{
			setSubmitting(false);
			window.location.pathname = `/member/entries/${result}`
		});
	}

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																		<h1 className="sc_layouts_title_caption">Registrations</h1>
																		<p className="header--description">Available registrations</p>
																	</div>
																	</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{registration.name}</h1>
												<p className="header--description">{registration.summary}</p>
											</div>
										</div>
									</div>
								</div>
						}
						<div className="page_content_wrap">
							<div className="container">
								<div className="content">
									<div className="registration mg-t-20">
										<Row className="mb-3">
											<Col md={{ size: 6, offset: 3 }}>
												<Select
													options={clubs}
													value={club}
													closeMenuOnSelect={true}
													getOptionLabel={e => e.name !== "" ? e.name : e.fullName}
													getOptionValue={e => e.id}
													onChange={(e) => setClub(e)} />
												{ club && entries.some(e => e.clubId == club.id && e.registrationId == registrationId) ? <Link to={`/member/entries/${entries.find(e => e.clubId == club.id && e.registrationId == registrationId).id}`} className="btn btn-primary btn-rounded w-100 mt-3">
													Edit Entry
												</Link>
												:<ProgressButton onClick={handleRegisterClick()} className="btn btn-primary btn-rounded w-100 mt-3" loading={submitting}>
													Register
												</ProgressButton>}
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</div>
					</div>)}
			/>
		</PublicLayout>
	);
}