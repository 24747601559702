import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { Entry, EntryStatus } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { confirmAlert } from "react-confirm-alert";


interface IEntryMemberPageProps {
    site?: string;
    match?: any;
    location?: any;
}

export default (props: IEntryMemberPageProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [entries, setEntries] = useState<Entry[]>();

    const [activeTab, setActiveTab] = useState<string>("Entries");

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        const entries = await EntryService.getByPermission();
        setEntries(entries);

        setLoading(false);
    };

    const toggle = (tab: string) => (e) => {
        setActiveTab(tab);
    }

    const handleCancelClick = (entry: Entry) => (e) => {
        confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will permanently delete this Entry.</p>
						<a className="btn btn-secondary text-white mr-2"
							onClick={() => {
								EntryService.removeEntry(entry.id).then(() => {
									load();
									onClose();
									toast.success("Entry Removed");
								});
							}}
						>
							Yes, delete it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
    }

    return (
        <PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
            <ShowMe
                visible={!loading}
                mode={ShowMeMode.Full}
                progress={true}
                render={() => (
                    <div>
                        <div className="page_content_wrap">
                            <div className="container">
                                <Row>
                                    <Col>
                                        <Card className="mb-5 card--nav">
                                            <Row className="pt-4 px-4">
                                                <Col md={12}>
                                                    <div className="name">
                                                        <h3 className="m-0">Registrations</h3>
                                                        <div>
                                                            <p className="text-muted d-block m-0">Manage your online registrations</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Nav>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === "Entries" })}
                                                        onClick={toggle("Entries")}>
                                                        Entries
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === "Invoices" })}
                                                        onClick={toggle("Invoices")}>
                                                        Invoices
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === "Clubs" })}
                                                        onClick={toggle("Clubs")}>
                                                        Clubs
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Card>
                                        <div>

                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="Entries">
                                                    <Row>
                                                        <Col>
                                                            <div className="mb-4 entries">
                                                                <h6>Entries</h6>
                                                                <Row>
                                                                    {
                                                                        entries && entries.map(e => (
                                                                            <Col xs={12}>
                                                                                <Card className="mb-3 p-3">
                                                                                    <Row>
                                                                                        <Col lg={5} className="d-flex align-self-center">
                                                                                            <div className="col">
                                                                                                <h6 className="m-0">{e.registration.name}</h6>
                                                                                                <p className="m-0 small">{e.registration.summary}</p>
                                                                                                <p className="m-0 small">{e.club?.name}</p>
                                                                                            </div>
                                                                                        </Col>
                                                                                        <Col lg={2} className="d-flex justify-content-center align-self-center">
                                                                                            {/* <h6 className="small m-0">End</h6> {moment(m.end).format("Do MMMM")} */}
                                                                                        </Col>
                                                                                        <Col lg={2} className="d-flex justify-content-center align-self-center">

                                                                                        </Col>
                                                                                        <Col lg={1} className="d-flex justify-content-center align-self-center">
                                                                                            {e.status === EntryStatus.New && <span title="New" className="status bg-warning"></span>}
                                                                                        </Col>
                                                                                        <Col lg={2} className="d-flex justify-content-end align-self-center">
                                                                                            <Link to={`/member/entries/${e.id}`} className="btn btn--circle text-white mr-2"><i className="far fa-pencil" /></Link>
                                                                                            <a className="btn btn--circle text-white" onClick={handleCancelClick(e)}><i className="far fa-ban" /></a>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Card>
                                                                            </Col>
                                                                        ))
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="Invoices">
                                                    <Row>
                                                        <Col>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="Clubs">
                                                    <Row>
                                                        <Col>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>)} />
        </PublicLayout>
    );
};