import { Booking } from "./booking";
import { Contact } from "./contact";
import { Entry } from "./entry";
import { EventMember, EventSession } from "./event";
import { Membership, MembershipUser } from "./membership";
import { User } from "./user";

export class Invoice {
	public id: number;
	public date: Date;
	public contactId: number;
	public due: Date;
	public total: number;
	public fee: number;
	public notes: string;
	public balance: number;
	public status: InvoiceStatus;
	public type: InvoiceType;
	public updated: string;
	public items: InvoiceItem[];
	public contact: Contact;
	public reference: string;
	public transactions: Transaction[];
	public details: string;
	public paymentType?: InvoicePaymentType;
	public paid?: number;
}

export class CreateInvoice {
	public title: string;
	public userId: string;
	public due: Date;
	public total: number;
	public contactId: number;
	public type: InvoiceType;
}

export class InvoiceItem {
	public id: number;
	public invoiceId: number;
	public invoice: Invoice;
	public title: string;
	public quantity: number;
	public unitPrice: number;
	public tax: number;
	public shipping: number;
	public total: number;

	public membershipId: number | null;
	public membership: Membership;

	public sessionId: number | null;
	public session: EventSession;

	public bookingId: number | null;
	public booking: Booking;

	public entryId: number | null;
	public entry: Entry;
}

export class Transaction {
	public invoiceId: number;
	public invoice: Invoice;
	public date?: Date;
	public total: number;
	public notes: string;
	public stripeId: string;
	public action: TransactionAction;
	public type: TransactionType;
	public voucherId: number;
}

export enum InvoiceStatus {
	All = 0,
	Pending = 1,
	Failed = 3,
	Paid = 4,
	Partial = 5,
	Refunded = 6,
	Void = 7,
	Outstanding = 2,
	Overdue = 99
}

export enum InvoiceView {
	Invoice = 1,
	Actions = 2
}

export enum TransactionType {
	Card = 1,
	Cash = 2,
	Credit = 3,
	Web = 4,
	BACS = 5,
	DirectDebit = 6,
	Cheque = 7
}

export enum TransactionAction {
	Payment = 1,
	Refund = 2,
	Void = 3
}

export enum PaymentType {
	OneOff = 1,
	Week = 2,
	Month = 3,
	Year = 4,
	Day = 5
}

export enum InvoiceType {
	Product = 1,
	Membership = 2,
	Event = 3,
	Balance = 4,
	Booking = 5,
	General = 6
}

export enum InvoicePaymentType {
	Card = 1,
	Cash = 2,
	Credit = 3,
	Web = 4,
	BACS = 5,
	DirectDebit = 6,
	Cheque = 7,
	Usage = 8,
	Mixed = 9
}

export enum InvoiceDueDate {
	Overdue = 1,
	NotDue = 2,
}