import * as React from "react";
import * as ReactDOM from "react-dom";

import { Router, Route, Redirect } from "react-router";
import { Link, Switch, BrowserRouter } from "react-router-dom";
import { LoginService } from "@services/login.service";

interface IPrivateRoute {
	children?: any;
	component?: any;
	rules?: string[];
	redirect?: string;
	exact?: boolean;
	path: string;
	render?: ((props: any) => React.ReactNode);
	computedMatch?: any;
	location?: any;
	onUnauthorized?: ()=> void;
}

export class PrivateRoute extends React.Component<IPrivateRoute, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		children: undefined,
		component: undefined,
		exact: undefined,
		rules: [],
		redirect: "/login",
		render: undefined
	};

	componentDidMount() {
		//
	}

	render() {
		const { component, render, ...newProps } = this.props;

		if (!LoginService.isAuthenticated) {
			return <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />;
		}

		if ((this.props.rules.length > 0 && !LoginService.allow(this.props.rules))) {
			if(this.props.onUnauthorized) {
				this.props.onUnauthorized();
				return null;
			} else {
				return <Redirect to={{ pathname: "/limited", state: { from: this.props.location } }} />;
			}
		}

		if (this.props.component) {
			return <this.props.component {...this.props} match={this.props.computedMatch} />;
		}

		return (<Route {...this.props} match={this.props.computedMatch}>{this.props.children}</Route>);
	}
}