
import { AppConfig } from "../../config";
import RestHelper from "@helpers/rest.helper";
import * as qs from "qs";

import { PagedList } from "@models/paging";
import { RankingModel } from "@models/rankings/rankingmodel";
import { CategoryFilterOptions } from "@models/filters/rankingFilterOptions";
import { ISimpleItem } from "@models/rankings/simpleitem";
import { BaseService } from "./base.service";
import { Category } from "@models/rankings/category";

export class CategoryService extends BaseService {

	public static get(id:number): Promise<Category> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`grouping/${id}`)).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}
	public static getSimple(options: CategoryFilterOptions): Promise<ISimpleItem<number, string>[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`groupings/simple`), {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}
	public static getAll(options: CategoryFilterOptions): Promise<PagedList<Category>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`groupings`), {
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static save(model: Category): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`grouping`), model).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static delete(id: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`grouping/${id}/delete`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}