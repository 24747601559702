import axios from "axios";
import * as store from "store2";
import { AppConfig, ReleaseType } from "@config";
import { Constants } from "@consts";
import { AppCache } from "@cache";

const identity = store.get("access_token");
const token = identity ? identity.accessToken : "";

if(token) {
	axios.defaults.headers.common[Constants.authHeaderKey] = `Bearer ${token}`;
}

axios.defaults.baseURL = AppConfig.apiUrl;
axios.defaults.headers.common[Constants.tenantKey] = window.location.hostname;

export default axios;
