import * as React from "react";
import { Link } from "react-router-dom";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

interface IExtendedLink {
	text?: string;
	buttonText?: string;
	className?: string;
	enabled?: boolean;
	icon?: string;
	id?: string;
	tooltip?: string;
	to?: any;
	href?: any;
	target?: any;
	children?: any;
}

export class ExtendedLink extends React.Component<IExtendedLink, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		enabled: true,
		className: ""
	};

	componentDidMount() {
		//
	}

	render() {
		return (
			<>
				{
					this.props.to ?
						<Link to={this.props.to} className={this.props.className} id={`${this.props.id}`}>
							{this.props.icon ? <div className="tx-20"><i className={`icon ${this.props.icon}`}></i></div> : undefined}
							{this.props.children}
						</Link> :
						<a href={this.props.href} target={this.props.target} className={this.props.className} id={`${this.props.id}`}>
							{this.props.icon ? <div className="tx-20"><i className={`icon ${this.props.icon}`}></i></div> : undefined}
							{this.props.children}
						</a>
				}
				{
					this.props.tooltip ?
						<UncontrolledTooltip placement="top" target={`${this.props.id}`} delay={{ show: 400, hide: 100 }}>
							{this.props.tooltip}
						</UncontrolledTooltip> : undefined
				}
			</>
		);
	}
}
