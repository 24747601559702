import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership, MembershipPayment } from "@models/membership";
import { PagedList } from "@models/paging";
import LocaleHelper from "@helpers/locale.helper";
import { Website, WebsiteCustomMenuItem, WebsiteMenuPreset } from "@models/website";
import { CreateMessage } from "@models/message";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";

interface IMembershipListProps {
	site?: string;

}

interface IMembershipListState {
	loading: boolean;
	memberships?: PagedList<Membership>;
	website?: Website;
	websiteMenu?: WebsiteCustomMenuItem;
	search?: string;
}
export class MembershipListPage extends React.Component<IMembershipListProps, IMembershipListState> {

	constructor(props) {
		super(props);
		this.state = { loading: true };
	}

	componentDidMount() {
		WebsiteService.updateActivity("Memberships");
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		MembershipService.get({ public: true }).then(memberships => {
			WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Memberships).then(result => {
				this.setState({
					websiteMenu: result,
					memberships,
					loading: false
				}, () => {
					MiscHelper.scrollToTop();
				});
			});
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Memberships")}</h1>
																			<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available memberships")}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Memberships")}</h1>
													<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available memberships")}</p>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="pre-content">
										{
											this.state.websiteMenu?.body &&
											<Row>
												<Col>
													{ReactHtmlParser(this.state.websiteMenu.body)}
												</Col>
											</Row>
										}
									</div>
									<div className="content">
										<div className="memberships-list mg-t-20">
											{
												this.state.memberships.items.map(m => (
													<Card className="mb-4">
														<CardBody>
															<Row>
																<Col xs={12} md={6} className="my-auto">
																	<h4 className="m-0">{m.name}</h4>
																	<p className="mb-0">{m.summary}</p>
																</Col>
																<Col xs={6} md={2} className="text-center my-auto">
																	{
																		m.upfrontCost &&
																		<>
																			<h4 className="membership-price m-0">{LocaleHelper.toPrice(m.upfrontCost)}</h4>
																			<span>Upfront</span>
																		</>
																	}
																</Col>
																<Col xs={6} md={2} className="text-center my-auto">
																	{m.cost === 0 && <h3 className="membership-price m-0">FREE</h3>}
																	{m.cost > 0 && <h3 className="membership-price m-0">{LocaleHelper.toPrice(m.cost)}</h3>}
																	<span>{m.payment === MembershipPayment.Month && "Monthly"}</span>
																	<span>{m.payment === MembershipPayment.OneOff && "Upfront"}</span>
																</Col>
																<Col md={2} className="text-center">
																	<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button d-block mb-2">
																		<Link to={m.body ? `/memberships/${m.id}` : `/memberships/${m.id}/join`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																			<span className="sc_button_text">
																				<span className="sc_button_title">Join</span>
																			</span>
																		</Link>
																	</div>
																</Col>
															</Row>
														</CardBody>
													</Card>
												))
											}
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
