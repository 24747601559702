import moment from "moment";
import { Booking, BookingSpace } from "./booking";
import { Contact } from "./contact";
import { CustomEmailType } from "./email";
import { Membership } from "./membership";
import { User } from "./user";

export class Event {
	public id: number;
	public type: EventType;
	public title: string;
	public venueId?: number;
	public summary: string;
	public duration: number;
	public enabled: boolean;
	public previewId?: number;
	public staffId: string;
	public body: string;
	public categoryId: number;
	public limit?: number;
	public staff: User;
	public usageEnabled: boolean;

	public memberCost?: number;
	public cost?: number;
	public costType?: EventCostType;
	public cancellationType?: EventCancellationPolicyType;
	public minAdvancedPeriod: number;

	public payLater: boolean;

	public upfrontMemberCost?: number;
	public upfrontCost?: number;
	public upfrontExpiry?: Date;

	public published?: Date;
	public expiryDate?: Date;

	public bookableDates: boolean;
	public bookableUpfront: boolean;

	public showDates: boolean;

	public nextSession: EventSession;

	public session: EventSession;

	public memberships: EventMembership[];

	public memberPrice?: number;
	public price?: number;

	public upfrontPrice?: number;
	public upfrontMemberPrice?: number;

	public usage?: boolean;

	public emailType: CustomEmailType;
	public emailIntro: string;
	public emailBody: string;
}

export class EventPrice {
	public cost: number;
	public usage: boolean;
}

export class EventSchedule {
	public id: number;
	public eventId: number;
	public event?: Event;
	public duration: number = 0;
	public staffId: number;
	public startTime: string;
	public daysOfWeek: DayOfWeek[] = [];
	public locationId?: number;

	public spaceId?: number;
	public resourceIds?: number[];

	public startDate: Date;
	public endDate: Date;
	public recurring: RecurringType;
}

export class EventSession {
	public id: number;
	public eventId: number;
	public event: Event;
	public scheduleId: number;
	public schedule: EventSchedule;
	public startDate: Date;
	public endDate: Date;
	public staffId: string;
	public locationId?: number;
	public limit?: number;
	public cancelDateTime?: Date;
	public cancelledById: string;
	public cancelledBy?: User;
	public cancelReason: string;
	public members: EventMember[] = [];

	public spaceId?: number;
	public space: BookingSpace;

	public resourceIds?: number[];
	public resources: string;
	public staff: User;

	public checkedIn?: number;
	public available?: number;

	public memberPrice?: number;
	public price?: number;
	public usage?: boolean;

	public minAdvancedPeriod?: number;
	public maxAdvancedPeriod?: number;
	public cancellationPolicy?: number;
}

export class EventSessionExtension extends EventSession {
	public get bookableUntil(): Date | undefined {
		if (this.minAdvancedPeriod) {
			return moment(this.startDate).subtract(this.minAdvancedPeriod, "minutes").toDate();
		}

		return null;
	}

	public get bookableFrom(): Date | undefined {
		if (this.maxAdvancedPeriod) {
			return moment(this.startDate).subtract(this.maxAdvancedPeriod, "minutes").toDate();
		}

		return null;
	}

	public get bookable(): boolean | undefined {
		return (!this.bookableUntil || moment(this.bookableUntil) >= moment());
	}
}

export class CancelSession {
	public id: number;
	public reason: string;
}

export class EventMember {
	public id: number;
	public sessionId: number;
	public contactId: number;
	public contact: Contact;
	public joined: Date;
	public attended: boolean;
	public updatedById: number;

	public session: EventSession;
}

export class EventMembership {
	public id: number;
	public eventId: number;
	public event?: Event;
	public membershipId: number;
	public membership?: Membership;
	public cost: number;
	public usageEnabled: boolean;
}

export class EventCalendarItem {
	public title: string;
	public allDay: boolean;
	public start: Date;
	public end: Date;
	public color: string;
	public sessionId: number;
	public eventId: number;
}

export class ScheduleResponse {
	public resource: number = 0;
	public booking?: Booking;
	public session?: EventSession;
}

export enum EventType {
	OneOff = 1,
	Sessions = 2,
	Appointment = 3,
	MultiDate = 4
}

export enum EventCostType {
	All = 1,
	Members = 2,
	Memberships = 3
}

export enum RecurringType {
	Weekly = 1,
	BiWeekly = 2,
	Monthly = 3,
	Quarterly = 4,
	Yearly = 5
}

export enum DayOfWeek {
	Sunday = 0,
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6
}

export enum EventSessionStatus {
	Upcoming = 1,
	Previous = 2,
	Cancelled = 3,
	Custom = 4
}

export enum EventCancellationPolicyType {
	CancelAnyTime = 0,
	NoCancellations = 1,
	UpTo1 = 2,
	UpTo2 = 3,
	UpTo6 = 4,
	UpTo24 = 5,
	UpTo48 = 6,
	UpTo1Week = 7
}
