export class Basket {
    public id?: string;
    public paymentMethodId?: string;

    public items: BasketItem[] = [];

    public total: number = 0;
}

export class BasketItem {
    public id?: string;
    public bookingId?: number;
    public eventId?: number;
    public sessionId?: number;
    public contactId?:number;

    public date: Date;
    public name: string;
    public total: number;

    public expires: Date;
}