import { EventCancellationPolicyType } from "@models/event";

export class ResourceHelper {
	public static getCancellationMessage(type: EventCancellationPolicyType) {
		const message = "You can cancel this booking";

		switch (type) {
			case EventCancellationPolicyType.CancelAnyTime:
				return `${message} at any time`;
			case EventCancellationPolicyType.NoCancellations:
				return `You cannot cancel this booking`;
			case EventCancellationPolicyType.UpTo1:
				return `${message} up to one hour before the start`;
			case EventCancellationPolicyType.UpTo2:
				return `${message} up to two hours before the start`;
			case EventCancellationPolicyType.UpTo6:
				return `${message} up to six hours before the start`;
			case EventCancellationPolicyType.UpTo24:
				return `${message} up to 24 hours before the start`;
			case EventCancellationPolicyType.UpTo48:
				return `${message} up to 48 hours before the start`;
			case EventCancellationPolicyType.UpTo1Week:
				return `${message} up to one week before the start`;
			default:
				break;
		}
	}
}