import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import History from "@helpers/history.helper";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Booking } from "@models/booking";
import { EventMember } from "@models/event";
import { BookingService } from "@services/booking.service";
import { PagedList } from "@models/paging";
import { MembershipStatus, MembershipUser } from "@models/membership";
import { Invoice, InvoiceStatus } from "@models/invoice";
import LocaleHelper from "@helpers/locale.helper";

interface IInvoices {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IInvoices) => {
	const [contact, setContact] = useState<Contact>(props.contact);

	const [invoices, setInvoices] = useState<PagedList<Invoice>>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const invoices = await ContactService.getInvoices(contact.id, { });

		setInvoices(invoices);
	};

	const pay = (invoice: Invoice) => (e: any) => {
		History.push(`/pay/${invoice.reference}`)
	}

	return (
		<>
			<div className="mb-4 memberships">
				<h6>Invoices</h6>
				<Row>
					{
						invoices && invoices.items.map(i => (
							<Col xs={12}>
								<Card className="mb-3 p-3 clickable" onClick={i.status === InvoiceStatus.Pending && pay(i)}>
									<Row>
										<Col lg={5} className="d-flex align-items-center align-self-center">
											<i className="far fa-file-invoice mr-2" title={i.reference} />
											<div>
												<h6 className="small m-0">Items</h6>
												{i.items.map(p => (<p className="m-0" title={i.reference}>{p.title}</p>))}
											</div>
										</Col>
										<Col lg={2}>
											<h6 className="small m-0">Date</h6>
											{moment(i.date).format("Do MMMM")}
										</Col>
										<Col lg={2}>
											<h6 className="small m-0">Due</h6>
											{moment(i.due).format("Do MMMM")}
										</Col>
										<Col lg={2}>
											<h6 className="small m-0">Total</h6>
											{LocaleHelper.toPrice(i.total)}
										</Col>
										<Col lg={1} className="justify-content-center align-self-center text-right">
											{(moment(i.due).add(30, "days") <= moment() && i.status === InvoiceStatus.Pending) ?
												<span title={`Overdue on ${moment(i.due).format("LLL")}`} className="status bg-danger text-white m-0"></span> :
												<>
													{i.status === InvoiceStatus.Paid && <span className="status bg-success mg-r-5 text-white"></span>}
													{i.status === InvoiceStatus.Pending && <span className="status bg-warning mg-r-5 text-white"></span>}
													{i.status === InvoiceStatus.Failed && <span className="status bg-danger mg-r-5 text-white"></span>}
													{i.status === InvoiceStatus.Void && <span className="status bg-danger mg-r-5 text-white"></span>}
													{i.status === InvoiceStatus.Refunded && <span className="status bg-info mg-r-5 text-white"></span>}
												</>
											}
										</Col>
									</Row>
								</Card>
							</Col>
						))
					}
				</Row>
			</div>
		</>
	);
};