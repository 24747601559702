import { Content } from "./content";
import { MediaGallery } from "./gallery";
import { MatchCardEntryType } from "./match";
import { Site } from "./site";

export class Website {
	public tenantId: number;
	public updated: Date;
	public title: string = "";
	public description: string = "";
	public about: string = "";
	public contact: string = "";
	public instagram: string = "";
	public twitter: string = "";
	public facebook: string = "";
	public whatsApp: string = "";
	public email: string = "";
	public mobile: string = "";
	public location: string = "";
	public defaultTextSelected: number = 0;

	public emailIntro: string = "";
	public emailBody: string = "";

	public images: WebsiteImage[] = [];
	public links: WebsiteLink[] = [];
	public config: WebsiteConfig;
	public galleryId?: number;
	public gallery: MediaGallery;
	public menu: WebsiteMenu;
}


export class WebsiteContent {
	public id: number;
	public live: string = "";
	public draft: string = "";
	public updated: Date;
	public published?: Date;
	public type: WebsiteContentType;
}

export class WebsiteTemplate {
	public id: number;
	public name: string;
	public published?: Date;
	public content: string;
}

export enum WebsiteContentType {
	Homepage = 1
}

export enum WebsiteMenuType {
	Primary = 1,
	Secondary = 2,
	Footer = 3
}

export class WebsiteConfig {
	public replyEmail: string;
	public fromEmail: string;
	public subHeader: boolean;
	public homeHeader: boolean;
	public resultsCarousel: boolean;
	public competitionMenuDisplay: CompetitionMenuDisplay;
	public competitionPreference: CompetitionPreference;
	public maxArticles?: number;
	public autoScrollCheck: boolean;
	public published?: Date;
	public visibility: WebsiteVisibility;
	public redirectUrl: string;
	public googleAnalytics: string;
}

export enum WebsiteVisibility {
	Published = 1,
	ComingSoon = 2,
	Redirect = 3,
	Unavailable = 4
}

export class WebsiteImage {
	public id: number;
	public imageId: number;
	public type: WebsiteImageType;
	public title: string;
	public description: string;
	public externalUrl: boolean;
	public url: string;
	public ordering: number;
}

export class WebsiteLink {
	public id: number;
	public title: string;
	public url: string;
}

export class MenuGroup {
	public id: number;
	public tenantId: number;
	public name: string;
	public ordering: number;
}

export class CreateMenuGroup {
	public name: string;
}

export class UpdateMenuGroup {
	public id: number;
	public name: string;
	public ordering: number;
}

export class WebsiteMenuGroup {
	public name: string;
	public items: WebsiteMenuItem[];
}

export class WebsiteMenuItem {
	public id: string;
	public parentId: string;
	public menuGroupId: string;
	public name: string;
	public slug: string;
	public forwardTo: string;
	public limited: boolean;
}

export class WebsiteMenu {
	public competitions: WebsiteMenuItem[];
	public divisions: WebsiteMenuItem[];
	public archived: WebsiteMenuItem[];
	public news: WebsiteMenuItem[];
	public feed: WebsiteMenuItem[];
	public pages: WebsiteMenuGroup[];
	public primary: WebsiteCustomMenuItem[];
	public secondary: WebsiteCustomMenuItem[];
	public footer: WebsiteCustomMenuItem[];
}

export class WebsiteCompetitionData {
	public id: string;
	public divisionIds: string[];
}

export class SiteActivity {
	public tenantId: number;
	public dateKey: string;
	public date: Date;
	public uniqueCount: number;
	public updated: Date;
}

export class WebsiteCustomMenuItem {
	public id: number;
	public tenantId: number;
	public parentId?: number;
	public parent: WebsiteCustomMenuItem;
	public title: string;
	public subTitle: string;
	public body: string;
	public name: string;
	public ordering: number;
	public preset?: WebsiteMenuPreset;
	public contentId?: number;
	public content: Content;
	public url: string;
	public items: WebsiteCustomMenuItem[];
	public type: WebsiteMenuType;
	public enabled: boolean;
}

export class CreateCustomWebsiteMenuItem {
	public parentId?: number;
	public title: string;
	public subTitle: string;
	public name: string;
	public ordering: number;
	public preset?: number;
	public contentId?: number;
	public url: string;
	public type: WebsiteMenuType;
}

export class ContactFormDetails {
	public firstName: string;
	public lastName: string;
	public email: string;
	public details: string;
}

export enum WebsiteImageType {
	Logo = 1,
	Header = 2,
	Footer = 3,
	News = 4,
}

export enum WebsiteMenuPreset {
	Memberships = 1,
	Bookings = 2,
	Fixtures = 3,
	Articles = 4,
	Events = 5,
	Sessions = 6,
	Gallery = 7,
	Content = 8,
	Home = 9,
	Leagues = 10,
	Venues = 11,
	Rankings = 12,
	Reserves = 13,
	Registrations = 14
}

export enum CompetitionMenuDisplay {
	Full = 1,
	Division = 2,
	Competition = 3,
}

export enum CompetitionPreference {
	League = 1,
	Club = 2,
	Both = 3,
}
