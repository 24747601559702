import * as React from "react";
import { Row, Col, Card } from "reactstrap";
import NProgress from "nprogress";
import { AppCache } from "@cache";
import PublicLayout from "@components/layouts/PublicLayout";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { WebsiteService } from "@services/website.service";
import { MediaGallery } from "@models/gallery";
import { GalleryService } from "@services/gallery.service";
import { PagedList } from "@models/paging";
import InfiniteScroll from "react-infinite-scroll-component";
import { FilterOptions } from "@models/filters/filterOptions";
import { AppConfig } from "@config";

interface IGalleryListPageProps {
	site?: string;
	match?: any;
}

interface IGalleryListPageState {
	loading: boolean;
	nextPathname?: any;
	galleries?: PagedList<MediaGallery>;
	gallery?: MediaGallery;
	photos: any[];

	options: FilterOptions;
}
const containerStyle = {
	width: '100%',
	height: '467.59px',
	marginbottom: '300px',
};

const containerStylePreview = {
	width: '100%',
	height: '300px',
	marginbottom: '300px',
};

export class GalleryListPage extends React.Component<IGalleryListPageProps, IGalleryListPageState> {

	private pageNo: number = 1;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			photos: [],
			options: { count: AppCache.settings.pageSize, page: 1 }
		};
	}

	componentDidMount() {
		//
		WebsiteService.updateActivity("Galleries");
		this.load();
	}

	componentDidUpdate(props: IGalleryListPageProps, state: IGalleryListPageState) {

	}

	load() {
		const p1 = GalleryService.get({}).then(galleries => {
			this.setState({ galleries });
		});

		Promise.all([p1]).then(result => {
			this.setState({ loading: false });
		});
	}

	loadImages() {
		GalleryService.images(this.state.gallery.id, null).then(result => {
			this.setState({ gallery: { ...this.state.gallery, media: result.data } });
		});
	}

	selectGallery(gallery: MediaGallery) {
		const photos = [];

		for (let i = 0; i < gallery.media.length; i++) {
			const media = gallery.media[i];
			photos.push({ src: "", width: 4, height: 3 });
		}

		this.setState({ gallery, photos }, () => {
			this.loadImages();
		});
	}

	next = () => async () => {
		NProgress.start();

		const page = this.state.options.page + 1;
		const result = await GalleryService.get({ ...this.state.options, page });

		const galleries = this.state.galleries;
		galleries.page = page;
		result.items.forEach(e => galleries.items.push(e));

		this.setState({ galleries, options: { ...this.state.options, page } });

		NProgress.done();
	}

	public render() {

		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							<div className="page_content_wrap gallery">
								<div className="container">
									<InfiniteScroll
										dataLength={this.state.galleries.items.length}
										next={this.next()}
										hasMore={this.state.galleries.items.length < this.state.galleries.total}
										loader={<h4>Loading...</h4>}
										style={{ overflowX: "hidden" }}>
										<Row className="no-gutters">
											{
												this.state.galleries && this.state.galleries.items.map(g => (
													<Col md={6} lg={4}>
														<Card onClick={e => History.push(`/gallery/${g.id}`)} className="gallery--item clickable p-0 m-2">
															<img className="img-fluid" src={`${AppConfig.baseUrl}media/${g.coverId}`} alt="Image" />
															<div className="card-img-overlay pd-30 bg-black-4 d-flex flex-column justify-content-center">
																<p className="tx-white tx-medium mg-b-15">{g.name}</p>
																<p className="tx-white-7 tx-13">{g.description}</p>
															</div>
														</Card>
													</Col>
												))
											}
										</Row>
									</InfiniteScroll>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout>);
	}
}
