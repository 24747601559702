import { Gender, User } from "./user";

export class Member extends User {
	public emergencyName: string;
	public emergencyNumber: string;
}

export enum MembershipType {
	AssociateMember = 1,
	CadetCompete = 2,
	CadetCompetePlus = 3,
	SeniorCompete = 4,
	SeniorCompetePlus = 5,
	HonouraryMember = 6,
	HomeCountriesMember = 7,
	ClubPlayerSenior = 8,
	ClubPlayerCadet = 9,
	TTKidz = 10,
	InternationalUpgrade = 11,
	JuniorInternationalUpgrade = 12,
	InternationalMembership = 13,
	U19Compete = 14,
	U11Compete = 15,
	U11ClubPlay = 16,
	U19ClubPlay = 17,
	U19CompetePlus = 18,
	U11CompetePlus = 19
}

export class MemberIdentity {
	public id: string;
	public name: string;
	public email: string;
	public membershipNo: number;
	public membershipType: MembershipType;
	public disabled: boolean;
}
export class GenderedMemberIdentity extends MemberIdentity {
	public gender: Gender;
}
export class RankingMemberIdentity {
	public id: string;
	public name: string;
	public membershipNo: number;
	public disabled: boolean;
	public gender: Gender;
}

export class CreateMember {
	public firstName: string;
	public lastName: string;
	public email: string;
	public dob: Date;
	public gender: Gender;
}

export class SearchMember {
	public id: string = "";
}

export class MemberAccess {
	public platform: Platform;
	public model: string;
	public os: string;
	public screen: string;
}

export class MemberDetails {

}

export class MemberRegistration {
	public firstName: string;
	public lastName: string;
	public email: string;
	public password: string;
}

export class MemberSetup {
	public displayName: string;
	public mobile: string;
	public clubs: number[] = [];
	public leagues: number[] = [];
}

export enum Platform {
	Web = 1,
	App = 2
}

export class MemberRoles {
	public userId: string;
	public leagues: boolean = false;
	public results: boolean = false;
	public submit: boolean = false;
	public approve: boolean = false;
	public messages: boolean = false;
	public content: boolean = false;
	public members: boolean = false;
	public memberships: boolean = false;
	public invoices: boolean = false;
	public bookings: boolean = false;
	public full: boolean = false;
	public master: boolean = false;

	public adminMaster: boolean = false;
	public adminFull: boolean = false;

	public globalMaster: boolean = false;
	public globalFull: boolean = false;
	public manual: boolean = false;

	public register: boolean = false;
	public entry: boolean = false;

	public primary: boolean = false;

	public tenant: string;
}

export class MemberNotifications {
	public userId: string;

	public memberships: boolean = false;
	public cancellations: boolean = false;
	public payments: boolean = false;
	public medical: boolean = false;
	public consents: boolean = false;
	public bookings: boolean = false;

	public tenant: string;
}

export class MemberRanking {
	public id: number;
	public userId: string;
	public position: number;
	public season: number;
	public name: string;
	public change: number;
	public rank: number;
	public team: string;
}