import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";

import { MembershipService } from "@services/membership.service";
import { PagedList } from "@models/paging";
import { Membership } from "@models/membership";
import { ImageUpload } from "@components/controls/ImageUpload";
import { AppConfig } from "@config";
import { Display, Options } from "@data";

import * as moment from "moment";
import { Link } from "react-router-dom";
import { TwitterPicker } from "react-color";

const DraggableMemberships = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [info, setInfo] = useState(props.payload.title);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [bgColor, setBgColor] = useState(props.payload.bgColor);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setlayout] = useState(props.payload.layout);
	const [imageId, setImageId] = useState<number>(props.payload.imageId);

	const [editMode, setEditMode] = useState(false);

	const [memberships, setMemberships] = useState<PagedList<Membership>>();

	useEffect(() => {
		MembershipService.get({ public: true, available: true }).then(result => {
			setMemberships(result);
		});
	}, []);

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<>
				{
					memberships && memberships.items.length > 0 &&
					<section className="memberships memberships--element spad bg-cover overlay p-5" style={{ backgroundImage: image ? `url(${image})` : "", backgroundColor: bgColor }}>
						<Container>
							<div className="row mb-5">
								<div className="col-lg-12">
									<div className="section-title text-center">
										<h2 className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default text-white">{title}</h2>
										<p className="text-white">{body}</p>
									</div>
								</div>
							</div>
							<div className="row equal">
								{
									memberships.items.map(m => {
										return (
											<div key={`memberships_${m.id}`} className="membership col-lg-4 mb-5">
												<Card className="text-center">
													<CardBody>
														<div className="single-price-plan">
															<h4 className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default text-secondary">{m.name}</h4>
															<div className="price-plan">
																<h2>
																	{m.cost === 0 && <>FREE</>}
																	{m.cost > 0 && <><span>£</span>{m.cost}</>}
																</h2>
																{
																	m.payment > 0 && <p>{Display.paymentTypes.find(p => p.value === m.payment).label}</p>
																}
															</div>
															<div>
																{m.benefits}
															</div>
															<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																<Link to={m.body ? `/memberships/${m.id}` : `/memberships/${m.id}/join`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																	<span className="sc_button_text">
																		<span className="sc_button_title">Join</span>
																	</span>
																</Link>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										);
									})
								}
							</div>
						</Container>
					</section>
				}
			</>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout, imageId, bgColor } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, body, link, image, layout, imageId, bgColor } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	const handleUpload = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleSelect = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleReset = () => () => {
		setImageId(null);
	};

	return (
		<>
			<Draggable {...props} >
				<Card>
					<Row>
						<Col>
							<p className="tx-semibold m-0">{name}</p>
							<span className="u-text-small">List of current memberships</span>
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-pencil"></i>
							</a>
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Title</Label>
						<Input value={title} placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Body</Label>
						<Input value={body} placeholder="Enter body" onChange={e => setBody(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Background Color</Label>
						<TwitterPicker
							width="100%"
							color={bgColor}
							onChange={e => setBgColor(e.hex)}
							colors={["#8E1730", "#151515", "#1A213B", "#9197AE", "#058ED9", "#20BF55"]} />
					</FormGroup>
					<FormGroup>
						<Label>Background Image</Label>
						{
							!imageId && <Input value={image} placeholder="Enter image url" onChange={e => setImage(e.target.value)} />
						}
						<Row className="mt-3">
							<Col>
								<ImageUpload
									reset={true}
									imageId={imageId}
									onSelect={handleSelect()}
									onReset={handleReset()}
									onCancel={() => { }}
									onUpload={handleUpload()}
									aspectRatio={16 / 9} />
							</Col>
						</Row>
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-gray-200 btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableMemberships;
