import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { AppConfig } from "@config";
import * as moment from "moment";
import * as NProgress from "nprogress";
import Select from "react-select";
import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ProgressButton } from "@components/controls/ProgressButton";
import { Event, EventCancellationPolicyType, EventSchedule, EventSession, EventSessionExtension } from "@models/event";
import { EventService } from "@services/event.service";
import { Contact, ContactLink } from "@models/contact";
import { ContactService } from "@services/contact.service";
import LocaleHelper from "@helpers/locale.helper";
import { InvoiceStatus } from "@models/invoice";
import { InvoiceService } from "@services/invoice.service";
import { ResourceHelper } from "@helpers/resource.helper";
import ConsentsModal from "@components/modals/ConsentsModal";
import { BasketService } from "@services/basket.service";
import { WebsiteContext } from "@context/BasketContext";
import { CalendarConflict, ContactCalendarTimeframe } from "@models/booking";
import { AgendaConflictAlert } from "@components/controls/AgendaConflictAlert";
import useBasket from "@context/useBasket";

const StripeContainer = (props) => {
	const [stripePromise] = useState(() => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId }));

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm schedule={props.schedule} eventId={props.eventId} payLater={props.payLater} sessionId={props.sessionId} contact={props.contact} bookee={props.bookee} onSubmit={props.onSubmit} onComplete={props.onComplete} onError={props.onError} useBasket={props.useBasket} submitting={props.submitting} />
		</Elements>
	);
};

interface ICheckoutFormProps {
	eventId: number;
	sessionId: number[];
	contact: Contact;
	schedule: EventSchedule;
	bookee: Contact;
	onSubmit?: () => void;
	onComplete?: () => void;
	onError?: () => void;
	useBasket?: () => void;
	submitting: boolean;
	payLater: boolean;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleBookAndPayClick = () => async (event: any) => {
		event.preventDefault();

		try {
			validate();

			props.onSubmit();

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement)
			});

			if (paymentMethod) {
				try {
					const invoice = await EventService.book(props.eventId, props.sessionId, props.contact.id, props.bookee.id, [], false, false, null, paymentMethod.id);
					if (invoice.status === InvoiceStatus.Failed) {
						await toast.error("Payment failed. Please retry");
						History.push(`/pay/${invoice.reference}`);
					}
					else
						History.push(`/sessions/confirmation/${invoice.reference}`);
				} catch (error) {
					toast.error(error.message ?? "Something went wrong");
					props.onError();
				}

				props.onComplete();
			} else {
				toast.error("Payment details missing");
				props.onError();
			}
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const handleBookClick = () => async (event: any) => {
		event.preventDefault();

		try {
			const invoice = await EventService.book(props.eventId, props.sessionId, props.contact.id, props.bookee.id, null, false, false, undefined);
			History.push(`/sessions/confirmation/${invoice.reference}`);
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const validate = () => {
		return true;
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<div className="stripe">
			<div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>
			<Row>
				<Col>
					<ProgressButton onClick={handleBookAndPayClick()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
						Book and Pay Now
					</ProgressButton>
				</Col>
				{
					props.payLater &&
					<Col>
						<ProgressButton onClick={handleBookClick()} className="mt-1 btn btn-primary btn-rounded w-100" loading={props.submitting}>
							Book and Pay Later
						</ProgressButton>
					</Col>
				}
				<Col>
					<Button onClick={() => props.useBasket()} className="mt-1 btn btn-primary btn-rounded w-100">
						Book Later
					</Button>
				</Col>
			</Row>
		</div>
	);
};

interface ISessionJoinProps {
	site?: string;
	match?: any;

}

const SessionJoinPage = (props: ISessionJoinProps) => {
	const websiteContext = useContext(WebsiteContext);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [session, setSession] = React.useState<EventSession>();
	const [event, setEvent] = React.useState<Event>();
	const [contact, setContact] = React.useState<Contact>();
	const [bookee, setBookee] = React.useState<Contact>();
	const [submitting, setSubmitting] = React.useState<boolean>(false);
	const [usage, setUsage] = React.useState<number>(0);
	const [links, setLinks] = React.useState<ContactLink[]>([]);
	const [fetching, setFetching] = React.useState<boolean>(false);
	const [consentValid, setConsentValid] = React.useState<boolean>(false);
	const [payingWithStripe, setPayingWithStripe] = React.useState<boolean>(false);
	const [sessionId, setSessionId] = React.useState<number>(props.match.params.sessionId as number);
	const [conflicts, setConflicts] = React.useState<CalendarConflict[]>([]);

	useEffect(() => {
		if (!LoginService.isAuthenticated) {
			History.replace(`/register?returnUrl=/sessions/${sessionId}/join`);
		} else {
			load();
		}
	}, []);

	useEffect(() => {
		if (contact) 
			loadSession();
	}, [contact]);

	const load = async () => {
		const bookee = await ContactService.getByUser();

		if (!bookee) {
			History.push("/member/profile/confirm");
		} else {

			ContactService.getLinked(bookee.id).then((result) => {

			}).catch((err) => {
				0
			});

			const links = await ContactService.getLinked(bookee.id);
			setBookee(bookee);
			setLinks(links);
			setContact(bookee);
		}
	}

	const loadSession = async () => {
		const session = await EventService.getSessionById(sessionId, { public: true, contactId: contact.id });
		
		const event = await EventService.getById(session.eventId, contact.id);
		const usage = await EventService.getContactUsage(event.id, contact.id);
		const bookee = await ContactService.getByUser();

		const eventSession = Object.assign(new EventSessionExtension(), session);

		if (eventSession.bookableUntil && moment(eventSession.bookableUntil) <= moment()) {
			toast.warn("Online Bookings have now closed for this session.");
			history.back();
		} else {
			// check for failed payment
			const paymentFailedForContactSession = await InvoiceService.getFailedBySessionContact(sessionId, bookee.id);
			if (paymentFailedForContactSession !== null && paymentFailedForContactSession !== undefined) {
				toast.warn("Please complete your payment");
				History.push(`/pay/${paymentFailedForContactSession.reference}`);
			}
		}

		setEvent(event);
		setSession(session);
		setLoading(false);
		setFetching(false);
		setUsage(usage);
		
		checkAgendaConflicts([{ contactId: contact.id, start: session?.startDate, end: session?.endDate }]);
	}

	const checkAgendaConflicts = async (toCheck: ContactCalendarTimeframe[]) => {
		setLoading(true);
		ContactService.checkAgendaConflicts(toCheck).then((result) => {
			setConflicts(result);
		}).finally(() => setLoading(false));
	}

	const handleReserveClick = () => async (e: any) => {
		e.preventDefault();

		NProgress.start();
		setSubmitting(true);

		try {
			const basketItems = await EventService.reserve(event.id, [sessionId], contact.id, bookee.id, null, false, false, null);

			for (const basketItem of basketItems) {
				const basket = await BasketService.add(basketItem);
				websiteContext.setBasket(basket);
			}

			NProgress.done();
			toast.success("Added to Basket - Expires in 30 minutes", {
				onClick: () => History.push("/basket")
			});
		} catch (error) {
			toast.error(error.message);
		} finally {
			setSubmitting(false);
			NProgress.done();
		}
	}

	const handleBookClick = () => async (e: any) => {
		e.preventDefault();

		NProgress.start();
		setSubmitting(true);

		try {
			const invoice = await EventService.book(event.id, [sessionId], contact.id, bookee.id, null, false, false, null);
			History.push(`/sessions/confirmation/${invoice.reference}`);
		} catch (error) {
			toast.error(error.message);
		} finally {
			setSubmitting(false);
			NProgress.done();
		}
	}

	const handleContactChange = () => async (contact: Contact) => {
		setFetching(true);
		setContact(contact);
	}

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																		<h1 className="sc_layouts_title_caption">{session.event.title}</h1>
																		<p className="header--description">{session.event.summary}</p>
																	</div>
																	</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{session.event.title}</h1>
												<p className="header--description">{session.event.summary}</p>
											</div>
										</div>
									</div>
								</div>
						}
						<div className="page_content_wrap">
							<div className="container">
								<div className="content">
									<div className="membership mg-t-20">
										{
											(session.cancellationPolicy != null) &&
											<Row className="mt-3">
												<Col md={{ size: 6, offset: 3 }}>
													<div className="alert alert-info alert-rounded">
														<span>{ResourceHelper.getCancellationMessage(session.cancellationPolicy)}</span>
													</div>
												</Col>
											</Row>
										}
										{
											links.length > 0 &&
											<Row className="mb-3">
												<Col md={{ size: 6, offset: 3 }}>
													<Select
														options={[...links.map(l => l.contact), bookee]}
														value={contact}
														closeMenuOnSelect={true}
														getOptionLabel={e => e.name !== "" ? e.name : e.fullName}
														getOptionValue={e => e.id}
														onChange={handleContactChange()} />
												</Col>
											</Row>
										}
										{
											!contact.completed && AppCache.tenant.config.booking.completeProfileRequired &&
											<Row className="mb-5">
												<Col lg={{ size: 6, offset: 3 }}>
													<p className="text-center">Emergency contact details and medical agreement must be completed</p>
													<Link tabIndex={0} to={`/member/profile/${bookee.id !== contact.id ? contact.id + "/" : ""}tab/5`} className="btn btn-secondary btn-rounded btn-outline bd-0 btn-block text-white">Complete Profile</Link>
												</Col>
											</Row>
										}
										{
											(!fetching && (contact.completed || !AppCache.tenant.config.booking.completeProfileRequired)) &&
											<Row>
												<Col md={{ size: 6, offset: 3 }}>
													<Form>
														<Row>
															<Col>
																{session.space && <div><i className="far fa-table-tennis mr-1"></i>{session.space.name}</div>}
																<div><i className="far fa-calendar mr-1"></i>{moment(session.startDate).format("dddd Do MMMM")}</div>
																<div><i className="far fa-clock mr-1"></i>{moment(session.startDate).format("HH:mm")}-{moment(session.endDate).format("HH:mm")}</div>
															</Col>
															<Col className="my-auto text-right">
																{
																	(session.price > 0 && usage === 0) &&
																	<h6 className="membership-price m-0">{LocaleHelper.toPrice(session.price)}</h6>
																}
																{
																	(session.price === null) &&
																	<h6 className="membership-price m-0 text-right">Unavailable</h6>
																}
																{
																	(session.price === 0 || usage > 0) &&
																	<h6 className="membership-price m-0 text-right">FREE</h6>
																}
															</Col>
														</Row>
														{
															conflicts.length > 0 &&
															<Row className="mt-3">
																<Col>
																	<AgendaConflictAlert conflicts={conflicts} />
																</Col>
															</Row>
														}

														{
															(usage === 0 && session.price > 0) &&
															<>
																{
																	!payingWithStripe &&
																	<Row>
																		<Col>
																			
																			<ProgressButton onClick={handleReserveClick()} className="mt-5 btn btn-primary btn-rounded w-100" loading={submitting}>
																				Add to Basket
																			</ProgressButton>
																			<Button onClick={() => {setPayingWithStripe(true)}} className="mt-1 btn btn-primary btn-rounded w-100">
																				Book Now
																			</Button>
																		</Col>
																	</Row>
																}
																{
																	payingWithStripe &&
																	<>
																		<Row>
																			<Col>
																				<StripeContainer
																					eventId={event.id}
																					contact={contact}
																					schedule={session.schedule}
																					bookee={bookee}
																					sessionId={[sessionId]}
																					onSubmit={e => setSubmitting(true)}
																					onComplete={e => setSubmitting(false)}
																					onError={e => setSubmitting(false)}
																					useBasket={e => setPayingWithStripe(false)}
																					submitting={submitting}
																					payLater={session.event.payLater} />
																			</Col>
																		</Row>
																		<Row className="mt-4 text-center">
																			<Col>
																				<img title="Powered by Stripe" width={"200px"} src={require("../../../../assets/images/misc/stripe-plurple.png")} />
																			</Col>
																		</Row>
																	</>
																}
															</>
														}
														{
															(session.price === 0) &&
															<Row className="mt-3">
																<Col>
																	<ProgressButton onClick={handleBookClick()} className="btn btn-primary btn-rounded w-100" loading={submitting}>
																		Book
																	</ProgressButton>
																</Col>
															</Row>
														}
														{
															(usage > 0 && session.price > 0) &&
															<Row className="mt-3">
																<Col>
																	<ProgressButton onClick={handleBookClick()} className="btn btn-primary btn-rounded w-100" loading={submitting}>
																		Confirm Usage
																	</ProgressButton>
																</Col>
															</Row>
														}
														{
															(session.price === null) &&
															<>
																{
																	(event.memberCost || event.memberships.length > 0) &&
																	<>
																		<h4 className="mb-0 mt-3">Memberships</h4>
																		<p>Valid memberships required to book onto session</p>
																		{
																			event.memberCost &&
																			<Row className="my-3">
																				<Col>
																					<p className="m-0 font-weight-semibold">Any Valid Memberships</p>
																				</Col>
																				<Col>
																					<h4 className="m-0 text-right">£{event.memberCost}</h4>
																				</Col>
																			</Row>
																		}
																		{event.memberships.map(m => (
																			<Row className="my-3">
																				<Col className="my-auto">
																					<p className="m-0 font-weight-semibold">{m.membership.name}</p>
																				</Col>
																				<Col className="text-right">
																					<h4 className="m-0">£{m.cost}</h4>
																				</Col>
																			</Row>
																		))}
																	</>
																}
															</>
														}
													</Form>
												</Col>
											</Row>
										}
										{fetching && <ActivitySpinner height={"50"} />}
									</div>
								</div>
							</div>
						</div>
					</div>)}
			/>
			<ConsentsModal autoCheck={true} eventId={session?.eventId} onClose={() => History.goBack()} onConfirm={() => setConsentValid(true)} />
		</PublicLayout>
	);
}

export default SessionJoinPage;