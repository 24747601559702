import { ScoreSheetVersion } from "@models/competition";
import * as React from "react";

const Header = ({printout, printoutSub, season, date, venue, division, umpire, round, table }) => {
    return (
        <div className="mt-3 mb-4">
            {printout == ScoreSheetVersion.VBCL && <div className="col-12">
                <h3 style={{ textAlign: "center", margin: "0px" }}>{printoutSub == 1 && "MEN"}{printoutSub == 2 && "WOMEN"}</h3>
            </div>}
            <div className="col-12">
                <h3 style={{ textAlign: "center", margin: "10px" }}>Season {season}</h3>
            </div>
            <div className="d-flex justify-content-between">
                <Details name={umpire} width={500} />
                <Details name="Date" value={date} width={250} />
                <Details name="Venue" value={venue} />
                <Details center name="Div." value={division} width={200} />
                <Box name="Round" value={round} />
                <Box name="Table" value={table} />
            </div>
        </div>
    )
}

const Details = ({ name, value, width, center }: { name: string; value?: string; width?: number; center?: boolean; }) => {
    return (
        <div style={{ width: `${width ?? 400}px`, height: "60px" }} className={`d-flex align-items-center mx-2`}>
            <span style={{ fontSize: "16pt", fontWeight: 500 }}>{name}</span>
            <div style={{ border: "1px solid black", height: "60px", lineHeight: "16px", whiteSpace: "break-spaces" }} className={`${center ? "justify-content-center text-center" : ""} d-flex border-1 flex-grow-1 ml-3 p-2 align-items-center text-truncate`}>
                <span className={`${center ? "text-center" : ""}`}>{value}</span>
            </div>
        </div>
    )
}

const Box = ({ name, value }: { name: string; value?: string; }) => {
    return (
        <div style={{ width: `100px`, height: "60px", border: "1px solid black" }} className="d-flex flex-column mx-2 justify-content-center">
            <div className="text-center" style={{ border: "none", fontSize: "8pt", height: "20px", textTransform: "uppercase" }}>
                {name}
            </div>
            <div className="text-center" style={{ border: "none", height: "25px" }}>
                <span>{value}</span>
            </div>
        </div>
    )
}

export { Header };