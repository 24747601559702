import * as React from "react";
import * as NProgress from "nprogress";
import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { EventService } from "@services/event.service";
import { EventSession } from "@models/event";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import * as moment from "moment";
import { BookingResource } from "@models/booking";
import { BookingService } from "@services/booking.service";

interface ISessionAttendeesProps {
	site?: string;
	match?: any;
}

interface ISessionAttendeesState {
	loading: boolean;
	session?: EventSession;
	contact?: Contact;
	overEighteen?: boolean;
	resources?:BookingResource[];
}

export class SessionAttendeesPage extends React.Component<ISessionAttendeesProps, ISessionAttendeesState> {

	private sessionId?: number;

	constructor(props) {
		super(props);
		this.state = { loading: true, session: {}, contact: {}, resources:[] };
		this.sessionId = this.props.match.params.sessionId as number;
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	load() {
		const p1 = EventService.getSessionById(this.sessionId).then(session => {
			this.setState({ session });
			BookingService.getResources({sessionId: this.sessionId, spaceId: session.spaceId}).then((resources) => {
				this.setState({resources});
			});
		});

		const p2 = ContactService.getByUser().then(contact => {
			let overAge = false;

			if (contact.user?.dob != null && contact.user?.dob != undefined) {
				const dob = new Date(contact?.user.dob);
				var ageDifMs = Date.now() - dob.getTime();
				var ageDate = new Date(ageDifMs);
				var age = Math.abs(ageDate.getUTCFullYear() - 1970);
				if (age > 17) {
					overAge = true;
				} else {
					overAge = false;
				}
			}

			this.setState({ contact, overEighteen: overAge });
		});

		const p3 = 

		Promise.all([p1, p2]).then(result => {
			this.setState({ loading: false });
		});
	}

	updateContact() {
		NProgress.start();
		ContactService.upsert(this.state.contact).then((result) => {
			toast.success("Your preference has been updated");
		}).catch((err) => {
			toast.error("Something went wrong");
		}).finally(() => NProgress.done());
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page" style={{ minHeight: "400px" }}>
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="mb-5">
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">Class Participants</span></h2>
											<p className="text-center">Current participants within your selected date</p>
										</div>

										<Card className="text-center w-50 mx-auto mb-4">
											<CardBody>
												<div className="single-price-plan my-auto d-flex flex-column">
													<div className="price-plan">
														<h4>
															{this.state.session?.event?.title}
														</h4>
													</div>
													{`${this.state.session?.space?.name} - 
													${moment(this.state.session?.startDate).format("Do MMMM")} ${moment(this.state.session?.startDate).format("HH:mm")} ~ 
													${moment(this.state.session?.endDate).format("Do MMMM")} ${moment(this.state.session?.endDate).format("HH:mm")}`}
													{
														this.state.resources?.map(m =>
															<span>{m.name}</span>
														)
													}
												</div>
											</CardBody>
										</Card>

										{
											this.state.overEighteen &&
											<div className="d-flex justify-content-center">
												<Toggle checked={this.state.contact?.displayNameVisible}
													className="mr-3"
													onChange={e => this.setState({ contact: { ...this.state.contact, displayNameVisible: e } }, () => this.updateContact())} />
												Allow my name to be seen by other participants
											</div>
										}


										<div className="competition-list mg-t-5">
											<div className="row clearfix">
												<div className="col-lg-12">
													<div className="list-group list-group-default">
														<div className="row">
															{
																this.state.session?.members?.map(c => {
																	return (
																		<div className="col-4">
																			<div className="card p-3 mb-3">
																				<div className="row">
																					<div className="col-12">
																						<i className="fa fa-user mr-3" />
																						{
																							c.contact?.id == this.state.contact?.id ?
																								<span>You</span>
																								:
																								(c.contact?.displayNameVisible == true) ?
																									<span>{c.contact?.displayName ?? ""}</span>
																									:
																									<span>Anonymous participant</span>
																						}
																					</div>
																				</div>
																			</div>
																		</div>
																	);
																})
															}
														</div>
													</div>
													{/* {
														this.state.loading ? <ActivitySpinner /> : <Empty text="No clubs to manage" enabled={this.state.clubs.length === 0} />
													} */}
												</div>
											</div>
										</div>


									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
