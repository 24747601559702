import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";
import { WebsiteService } from "@services/website.service";

interface IArchivesPageProps {
	site?: string;

}

interface IArchivesState {
	loading: boolean;
	competitions?: Competition[];
	search?: string;
}
export class ArchivesPage extends React.Component<IArchivesPageProps, IArchivesState> {

	constructor(props) {
		super(props);
		this.state = { loading: true, competitions: [] };
	}

	componentDidMount() {
		WebsiteService.updateActivity("Archives");

		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		CompetitionService.getArchives().then(competitions => {
			const queue = [];

			competitions.forEach(c => {
				c.divisions = [];
				const d1 = DivisionService.get(c.id).then(results => {
					results.forEach(div => {
						c.divisions.push(div);
					});
				});
				queue.push(d1);
			});

			Promise.all(queue).then(result => {
				this.setState({ loading: false, competitions });
			});
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div>
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">Archives</span></h2>
											<p className="text-center">A collection of the archived leagues</p>
										</div>
										<div className="competition-list mg-t-20">
											<div className="row clearfix">
												<div className="col-lg-12">
													<div className="list-group list-group-default">
														{
															this.state.competitions.map(c => {
																return (
																	<div key={`competition-${c.id}`} className="competition mb-3">
																		<h5>{c.name}</h5>
																		<div className="row">
																			{
																				c.divisions.map(d => {
																					return (
																						<div className="col-lg-4 col-md-6 col-12">
																							<div className="card p-3 mb-3">
																								<div className="row">
																									<div className="col-8">
																										<p className="m-0"><Link to={`/league/${c.id}/division/${d.id}`}>{d.name}</Link></p>
																									</div>
																									<div className="col-4 text-right">
																										<Link className="mr-2" to={`/league/${c.id}/division/${d.id}/table`} title="Tables"><i className="fal fa-table"></i></Link>
																										<Link className="mr-2" to={`/league/${c.id}/division/${d.id}/results`} title="Results"><i className="fal fa-table-tennis"></i></Link>
																										<Link className="mr-2" to={`/league/${c.id}/division/${d.id}/averages`} title="Averages"><i className="fal fa-percentage"></i></Link>
																										<Link className="mr-2" to={`/league/${c.id}/division/${d.id}/potm`} title="POTMs"><i className="fal fa-user-circle"></i></Link>
																									</div>
																								</div>
																							</div>
																						</div>
																					);
																				})
																			}
																		</div>
																	</div>
																);
															})
														}
														{
															this.state.loading ? <ActivitySpinner /> : <Empty text="No archived leagues available" enabled={this.state.competitions.length === 0} />
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
