import { SortingProperty, SortingType } from './../models/filters/filterOptions';
export class StringHelper {
	public static capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	}

	public static ifEmpty(value: string, defaultValue: string) {
		if (value) {
			value = value.trim();

			if (value !== "") {
				return value;
			}
		}

		return defaultValue;
	}

	public static buildOrderingQuery(sortingList: SortingProperty[]){
		let output = "";
		for (let index = 0; index < sortingList.length; index++) {
			output = output + (index == 0 ? "" : ", ") + sortingList[index].name + " " + (sortingList[index].type == SortingType.Ascending ? "ASC" : "DESC");			
		}
		return output;
	}
}
