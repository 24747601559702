import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import { Row, Col } from "reactstrap";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage, PlayerStats } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe } from "@components/controls/ShowMe";
import { MatchService } from "@services/match.service";
import { Match, MatchSet } from "@models/match";
import { AppCache } from "@cache";
import { ResultType } from "@models/result";


interface IPlayerPageProps {
	site?: string;
	match?: any;
}

interface IPlayerPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	match?: Match;
	sets: MatchSet[];
	stats?: PlayerStats;
}
export class PlayerPage extends React.Component<IPlayerPageProps, IPlayerPageState> {

	private competitionId: number;
	private userId: string;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			sets: []
		};
	}

	componentDidMount() {
		//
		this.competitionId = this.props.match.params.competitionId as number;
		this.userId = this.props.match.params.userId as string;

		this.load();
	}

	componentDidUpdate(props: IPlayerPageProps, state: IPlayerPageState) {
		this.userId = this.props.match.params.userId as string;

		if (props.match.params.userId != this.userId) {
			this.load();
		}
	}

	load() {
		this.setState({ loading: true });

		const p1 = CompetitionService.getById(this.competitionId).then(competition => {
			this.setState({ competition });
		});

		const p2 = CompetitionService.getPlayerStats(this.competitionId, this.userId).then(stats => {
			this.setState({ stats })
		});

		Promise.all([p1, p2]).then(result => {
			this.setState({ loading: false });
		});
	}

	public render() {
		return (
			<PublicLayout className="page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
											<div className="elementor-widget-container">
												<div id="trx_sc_title_789087444" className="sc_title sc_title_default text-center">
													<h2 className="m-0">{this.state.stats.name}</h2>
												</div>
											</div>
										</div>
										<div className="stats-details">
											<div className="row">
												<div className="col-6 col-md-3">
													<h3 className="text-center">Club</h3>
													<p className="text-center">{this.state.stats.club.value}</p>
												</div>
												<div className="col-6 col-md-3">
													<h3 className="text-center">Team</h3>
													<p className="text-center">{this.state.stats.team.value}</p>
												</div>
												<div className="col-6 col-md-3">
													<h3 className="text-center">Average</h3>
													{this.state.stats.average ? <p className="text-center">{((this.state.stats.average.won / this.state.stats.average.played) * 100).toFixed(2)}%</p> : undefined}
												</div>
												<div className="col-6 col-md-3">
													<h3 className="text-center">Form</h3>
													<p className="text-center">
														{
															this.state.stats.form.map(r => {
																return (
																	<Link title={`${r.versus.value} - ${moment(r.date).format("Do MMMM")}`} className={`player-match`} to={`/league/${this.competitionId}/division/${this.state.stats.division.key}/match/${r.matchId}`}>
																		<span className={`won-${r.form}`}>{r.won}</span>
																	</Link>
																)
															})
														}
													</p>
												</div>
											</div>
											<div className="row">
												{
													AppCache.tenant.config.competition.rankingEnabled && this.state.stats.localRanking != null && <div className="col">
														<h3 className="text-center">Local Ranking</h3>
															<Col className="text-center">
																<p className="text-center mb-0">
																	<span className="text-primary" style={{ fontWeight: "bold" }}>#{this.state.stats.localRanking.position}</span> | {this.state.stats.localRanking.score}
																</p>
															</Col>
													</div>
												}
											</div>
											<div className="row">
												{
													this.state.stats.nationalRanking != null && <div className="col">
														<h3 className="text-center">National Ranking</h3>
														<Col className="text-center">
															<p className="text-center mb-0">
																<span className="text-primary" style={{ fontWeight: "bold" }}>#{this.state.stats.nationalRanking.position}</span> | {this.state.stats.nationalRanking.score}
															</p>
															<span>{this.state.stats.nationalRanking.category}</span>
														</Col>
													</div>
												}
											</div>
											{/* <div className="row">
												{
													this.state.stats.rankings.length > 0 &&
													<div className="col">
														<h3 className="text-center">National Ranking</h3>
														<Row>
															{
																this.state.stats.rankings.map(r => (
																	<>
																		<Col className="text-center">
																			<p className="text-center mb-0">
																				<span className="text-primary" style={{ fontWeight: "bold" }}>#{r.position}</span> | {r.score}
																			</p>
																			<span>{r.category}</span>
																		</Col>
																	</>
																))
															}
														</Row>
													</div>
												}
											</div> */}
										</div>
										<div className="member-results">
											<div className="sp-template sp-template-event-performance sp-template-event-performance-values">
												<div className="sp-table-wrapper">
													<div className="sp-scrollable-table-wrapper"><div id="DataTables_Table_1_wrapper" className="dataTables_wrapper">

														<div className="mb-5 table-responsive">
															<table className="data-table-results sp-data-table sp-scrollable-table sp-sortable-table dataTable" role="grid">
																<thead>
																	<tr role="row">
																		<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Opponent</th>
																		<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Versus</th>
																		<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Date</th>
																		<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Score</th>
																		<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Result</th>
																		{AppCache.tenant.config.competition.rankingEnabled && <th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Rank</th>}
																		{AppCache.tenant.config.competition.rankingEnabled && <th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>+/-</th>}
																	</tr>
																</thead>
																{
																	this.state.stats.results.length === 0 ?
																		<tbody>
																			<tr>
																				<td colSpan={6}>
																					<p className="text-center no-results">No results available</p>
																				</td>
																			</tr>
																		</tbody> :
																		this.state.stats.results.map(g => {
																			return (
																				<tbody>
																					<tr className="data-header">
																						<td colSpan={7}>
																							<Link to={`/league/${this.competitionId}/team/${g.team.key}`}>{g.team.value}</Link>
																						</td>
																					</tr>
																					{
																						g.results.map(r => {
																							return (
																								<tr className="lineup odd" role="row">
																									<td className="data-item nowrap"><Link to={`/league/${this.competitionId}/player/${r.opponent.key}`}>{r.opponent.value}</Link></td>
																									<td className="data-item nowrap"><Link to={`/league/${this.competitionId}/team/${r.versus.key}`}>{r.versus.value}</Link></td>
																									<td className="data-item nowrap">{moment(r.date).format("Do MMM YYYY")}</td>
																									<td className="data-games nowrap">
																										<p>
																											{
																												r.games.map(g => {
																													return (g.for != null && g.against != null ? <span className="mr-3">{g.for} - {g.against}</span> : undefined);
																												})
																											}
																										</p>
																									</td>
																									<td className="data-games nowrap">
																										<Link to={`/league/${this.competitionId}/division/${r.divisionId}/match/${r.matchId}`}>
																											<p className={r.type === ResultType.Won ? "text-success" : "text-danger"}>
																												{r.for} - {r.against}
																											</p>
																										</Link>
																									</td>
																									{AppCache.tenant.config.competition.rankingEnabled && <td className="data-item nowrap">{r.rank}</td>}
																									{AppCache.tenant.config.competition.rankingEnabled && <td className="data-item nowrap">{r.score >= 0 ? "+" : ""}{r.score}</td>}
																								</tr>
																							)
																						})
																					}
																					<tr>
																						<td colSpan={5}></td>
																					</tr>
																				</tbody>)
																		})}
															</table>
														</div>
													</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
