import { Club } from "./club";
import { County } from "./county";
import { League } from "./league";
import { MatchCardEntryType } from "./match";
import { MemberRoles } from "./member";
import { Organisation } from "./organisation";
import { Website } from "./website";

export class Tenant {
	public id: number;
	public code: string;
	public host: string;
	public name: string;
	public leagueId?: number;
	public clubId?: number;
	public countyId?: number;
	public updated: Date;
	public created: Date;
	public isDefault: boolean;
	public isTesting: boolean;
	public config: TenantConfig;

	public organisationId: number;
	public organisation: Organisation;
	
	public checkExpiry: boolean = true;
}

export class TenantRole {
	public tenant: Tenant;
	public roles: MemberRoles;
}

export class TenantDetails extends Tenant {
	public league: League;
	public club: Club;
	public county: County;
	public website: Website;
}

export class CreateTenant {
	public name: string;
	public leagueId?: number;
	public clubId?: number;
}

export class TenantConfig {
	public appEnabled: boolean;
	public stripeId: string;
	public autoCharge: boolean;
	public autoRenew: boolean;
	public chargedEnabled: boolean;
	public competitionsModule: boolean;
	public bookingsModule: boolean;
	public fastFormatModule: boolean;
	public fastOnly: boolean;

	public googleAnalytics: string;

	public allowGlobalSearch: boolean;
	public registrationsModule: boolean;
	public allowNationalRankings: boolean;
	public allowAdvancedOptions: boolean;
	public allowTeamChecker: boolean;
	public allowClubplayCompete: boolean;

	public enableTTClubsFeatures: boolean;

	public linkedTenants: TenantLink[];

	public competition: CompetitionConfig;
	public booking: BookingConfig;
	public menu: MenuConfig;
}

export class CompetitionConfig {
	public approvalType: MatchCardEntryType;
	public submissionType: MatchCardEntryType;
	public reportType: MatchCardEntryType;
	public rankingEnabled: boolean;
	public globalMembers: boolean;
	public restrictByClub: boolean;
	public restrictByMembership: string;
	public membershipRestrictions: number[];
}

export class BookingConfig {
	public completeProfileRequired: boolean;
	public bookingTimeFrame: number;
	public medicalAgreementExpiryDays?: number;
	public medicalAgreementExpiryDate?: Date;
}

export class MenuConfig {
	public homeEnabled: boolean;
	public homeLabel: string;
}

export class ClubStatsOptions {
	public end: Date;
	public timespan: number;
	public type: string;
}

export class TenantLink {
	public tenantId: number;
	public parentId: number;
	public tenant: Tenant;
}

export enum LoginMode {
	Internal = 1,
	Sport80 = 2,
	Social = 3
}

export enum TenantType {
	League = 1,
	Club = 2,
	County = 3
}

export enum ModuleType {
	Competitions = 1,
	Bookings = 2,
	Registrations = 3
}

export enum MedicalAgreementExpireOptions {
	SpecificDay = 1,
	TimeInterval = 2,
	Never = 3
}