
import { AppConfig } from "../../config";
import RestHelper from "@helpers/rest.helper";
import * as qs from "qs";

import { PagedList } from "@models/paging";
import { RankingModel } from "@models/rankings/rankingmodel";
import { Match } from "@models/rankings/match";
import { MatchFilterOptions } from "@models/filters/rankingFilterOptions";
import { BaseService } from "./base.service";
import { InstancedEvent } from "@models/rankings/instancedevent";
import { PlayerRanking } from "@models/rankings/playerranking";
import { Gender } from "@models/rankings/enums/gender";
import { PlayerRankingCategoryPosition } from "@models/rankings/playerrankingcategoryposition";

export class RankingsService extends BaseService {

	public static canGenerate(instanceId:number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instance/${instanceId}/rankings/ready`)).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem validating the testing scenario");
			});
		});
	}

	public static generate(instanceId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(this.base(`instance/${instanceId}/rankings/generate`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem generating the rankings");
			});
		});
	}

	public static getResultsForEvent(instanceId: number, eventId: number, gender?:Gender): Promise<InstancedEvent> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instance/${instanceId}/rankings/event/${eventId}`), {
				params: { gender },
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a fetching the results");
			});
		});
	}
	public static getDetailedResultData(eventId: number, resultId: number): Promise<PlayerRanking> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`rankings/event/${eventId}/result/${resultId}`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem fetching detailed result data");
			});
		});
	}

	public static getPlayerPositionsFromInstancedEventAndMemberId(memberId:number, instanceId?:number):Promise<PlayerRankingCategoryPosition[]> {
		let url = `instance/primary/`;
		if (instanceId !== undefined)
			url = `instance/${instanceId}/`;

		url += `member/${memberId}/rankings/positions`

		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(url), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem fetching positions");
			});
		});
	}

	public static getDetailedResultDataFromInstanceCategoryEventAndMemberId(categoryId:number, memberId: number, instanceId?: number): Promise<PlayerRanking[]> {
		let url = `instance/primary/`;
		if (instanceId !== undefined)
			url = `instance/${instanceId}/`;

		url += `category/${categoryId}/member/${memberId}/rankings/results/`

		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(url), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem fetching detailed result data");
			});
		});
	}

	public static getCategoryResults(categoryId: number, date:string): Promise<PlayerRanking[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`category/${categoryId}/${date}`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem fetching the rankings data");
			});
		});
	}
	public static getCategoryResultsForInstance(instanceId: number, categoryId: number): Promise<PlayerRanking[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`instance/${instanceId}/category/${categoryId}`), undefined).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error?.response?.data ?? "There was a problem fetching the rankings data");
			});
		});
	}
}