import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership } from "@models/membership";
import { PagedList } from "@models/paging";
import DayPicker from "react-day-picker/DayPicker";
import { Booking, BookingResource, BookingSlot, BookingSpace } from "@models/booking";
import { BookingService } from "@services/booking.service";
import BookingSlotSidebar from "@components/sidebar/public/BookingSlotSidebar";
import { InvoiceService } from "@services/invoice.service";
import { Website, WebsiteCustomMenuItem, WebsiteMenuPreset } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";
import { ConsentAssociations, CreateConsentAccept } from "@models/consent";
import { ContactService } from "@services/contact.service";
import ConsentsModal from "@components/modals/ConsentsModal";
import { ConsentService } from "@services/consent.service";
import { ArrayHelper } from "@helpers/array.helper";

interface IBookingSlotsProps {
	site?: string;
	match?: any;
}

interface IBookingSlotsState {
	loading: boolean;
	slots?: BookingSlot[];
	spaces?: BookingSpace[];
	resource?: BookingResource;
	space?: BookingSpace;
	slot?: BookingSlot;
	website?: Website;
	websiteMenu?: WebsiteCustomMenuItem;
	date: Date;
	search?: string;
	consentsRequired: ConsentAssociations[];
	showConsentModal: boolean;
}
export class BookingSlotsPage extends React.Component<IBookingSlotsProps, IBookingSlotsState> {

	private spaceId?: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			slots: [],
			date: moment().toDate(),
			consentsRequired: [],
			showConsentModal: false
		};

		if (this.props.match.params.spaceId) {
			this.spaceId = parseInt(this.props.match.params.spaceId, 0);
		}
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		const spaceId = this.props.match.params.spaceId;

		if (props.match.params.spaceId !== spaceId) {
			this.spaceId = parseInt(this.props.match.params.spaceId, 0);

			if (this.spaceId) {
				const space = this.state.spaces.find(s => s.id === this.spaceId);
				this.setState({ loading: true, space }, () => this.loadSlots());
			} else {
				this.setState({ loading: false, slot: null, space: null, slots: [] });
			}
		}
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	async load() {
		NProgress.start();

		await BookingService.getSpaces({}).then(async spaces => {
			const space = spaces.items.find(s => s.id === this.spaceId);
			await WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Bookings).then(result => {
				this.setState({
					websiteMenu: result,
					space,
					spaces: spaces.items,
				});
			});
		});

		if (this.spaceId) {
			await this.loadAgreements();
			await this.loadSlots();
		} else {
			this.setState({ loading: false }, () => {
				NProgress.done();
			});
		}

		NProgress.done();
	}

	loadSlots = () => {
		BookingService.getSlots({
			spaceId: this.spaceId,
			date: this.state.date,
			resourceId: this.state.resource?.id,
			public: true
		}).then(slots => {
			this.setState({
				loading: false,
				slots
			}, () => {
				NProgress.done();
			});
		});
	}

	loadAgreements = async () => {
		if (LoginService.isAuthenticated && this.spaceId) {
			const associations = await ConsentService.getAssociations({ spaceId: this.spaceId });
			const required: ConsentAssociations[] = [];

			await ConsentService.getAgreementsByContact().then(agreements => {
				associations.forEach(association => {
					let exist = false;
					agreements.forEach(accepted => {
						if (accepted.consent.id === association.consentId && (accepted.renew >= new Date() || !accepted.renew)) {
							exist = true;
						}
					});

					if (!exist) {
						required.push(association);
					}
				});

				this.setState({ consentsRequired: required });
			});
		}
	}

	handleDayClick = () => (e: any) => {
		this.setState({ date: e }, () => this.load());
	}

	handleBookingSlotClick = (slot: BookingSlot) => (e: any) => {
		if (LoginService.isAuthenticated) {
			if (this.state.consentsRequired.length > 0) {
				this.setState({ showConsentModal: true });
			} else {
				InvoiceService.getFailedByBookingSlotInfo({
					spaceId: slot.spaceId,
					date: slot.start,
					public: true
				}).then(result => {
					if (result) {
						toast.warn("Please complete your payment");
						History.push(`/pay/${result.reference}`);
					}
				});

				if (slot.available)
					this.setState({ slot });
			}

		} else {
			History.push(`/register?returnUrl=${window.location.pathname}`);
		}
	}

	sidebar = () => {
		if (this.state.slot) {
			return <BookingSlotSidebar
				slot={this.state.slot}
				slots={this.state.slots}
				onConfirm={(booking?: Booking) => {
					this.setState({ slot: null }, () => this.loadSlots());
				}}
				onClose={() => this.setState({ slot: null })} />;
		}

		return <></>;
	}

	openSidebar = () => {
		if (this.state.slot) {
			return true;
		}

		return false;
	}



	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<Sidebar
							sidebar={this.sidebar()}
							pullRight={true}
							open={this.openSidebar()}
							sidebarClassName="sidebar-root"
							styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 99999 }, overlay: { zIndex: 999 } }}>
							<div className="sub-page">
								{
									AppCache.website.config.subHeader ?
										<div className="elementor elementor-6">
											<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
												<div className="elementor-container elementor-column-gap-extended">
													<div className="elementor-row">
														<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
															<div className="elementor-column-wrap  elementor-element-populated">
																<div className="elementor-widget-wrap">
																	<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner"></div>
																			</div>
																		</div>
																	</div>
																	<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner"></div>
																			</div>
																		</div>
																	</div>
																	<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																		<div className="elementor-widget-container">
																			<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																				<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Bookings")}</h1>
																				<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available spaces")}</p>
																			</div>
																			</div>
																			</div>
																		</div>
																	</div>
																	<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																		<div className="elementor-widget-container">
																			<div className="elementor-spacer">
																				<div className="elementor-spacer-inner"></div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</section>
										</div> :
										<div className="header_content_wrap mt-5">
											<div className="container">
												<div className="row">
													<div className="col-12">
														<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(this.state.websiteMenu?.title, "Bookings")}</h1>
														<p className="header--description">{StringHelper.ifEmpty(this.state.websiteMenu?.subTitle, "Available spaces")}</p>
													</div>
												</div>
											</div>
										</div>
								}
								<div className="page_content_wrap">
									<div className="container">
										<div className="pre-content">
											{
												this.state.websiteMenu?.body &&
												<Row>
													<Col>
														{ReactHtmlParser(this.state.websiteMenu.body)}
													</Col>
												</Row>
											}
										</div>
										<div className="content">
											{
												this.state.space &&
												<div className="booking-slots mg-t-20">
													<Row>
														<Col>
															<h3 className="mt-2">{this.state.space.name}</h3>
														</Col>
														<Col className="text-right">
															{
																ArrayHelper.groupBy(this.state.slots.filter(c => c.category != null), (s) => s.category).map(c => {
																	return (
																		<div className="btn btn-key text-white btn-icon mr-2" style={{ backgroundColor: c[0].category.color }}>
																			{c[0].category.name}
																		</div>
																	);
																})
															}
															<a className="btn btn-key text-white btn-icon" style={{ backgroundColor: "#dddddd" }}>
																Unavailable
															</a>
														</Col>
													</Row>
													<Row>
														<Col md={6} lg={4}>
															<DayPicker
																className="d-block"
																selectedDays={[this.state.date]}
																onDayClick={this.handleDayClick()} />
														</Col>
														<Col md={6} lg={8}>
															<Label className="section-title mt-0">Slots</Label>
															<Row>
																{
																	this.state.slots.map(s => (
																		<Col xs={4} lg={2}>
																			<div className="slot">
																				<a onClick={this.handleBookingSlotClick(s)} style={{ backgroundColor: s.available ? s.category?.color : undefined }} className={`btn d-block mb-3 ${s.category && "text-white"} ${!s.available ? "bg-gray-300 text-white" : "bg-gray-300 text-gray-800"}`}>
																					<span>{moment(s.start).format("HH:mm")}</span>
																				</a>
																			</div>
																		</Col>
																	))
																}
															</Row>
															{
																this.state.slots.length === 0 && <p>No slots available</p>
															}
														</Col>
													</Row>
												</div>
											}
											{
												!this.state.space &&
												<Row className="equal">
													{
														this.state.spaces.map(s => (
															<Col md={6} lg={4} key={`spaces_${s.id}`} className="spaces mb-5">
																<Card className="text-center h-100">
																	<CardBody>
																		<div className="single-price-plan my-auto">
																			<div className="price-plan">
																				<h4>
																					{s.name}
																				</h4>
																			</div>
																			<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																				<Link to={`/bookings/${s.id}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																					<span className="sc_button_text">
																						<span className="sc_button_title">Select</span>
																					</span>
																				</Link>
																			</div>
																		</div>
																	</CardBody>
																</Card>
															</Col>
														))
													}
												</Row>
											}
										</div>
									</div>
								</div>
							</div>
						</Sidebar>)}
				/>
				<ConsentsModal
					spaceId={this.state.space?.id}
					show={this.state.showConsentModal}
					required={this.state.consentsRequired}
					onClose={() => this.setState({ showConsentModal: false }, History.goBack)}
					onConfirm={() => this.setState({ showConsentModal: false }, this.loadAgreements)} />
			</PublicLayout>
		);
	}
}
