import * as React from "react";

import { ScoreSheetVersion } from "@models/competition"

const Logos = ({ printout, printoutSub, ordering, sponserLogo }) => {
    return (
        <div style={{ height: 150 }} className="row">
            {/* <div className="col-3">
                {sponserLogo && <img width={290} src={require("@assets/images/brand/bribar-double-fish-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
            </div>
            <div className="col-9" style={{ textAlign: "right" }}>
                {printout == ScoreSheetVersion.SBCL && ordering == 0 && <img style={{ marginRight: "60px" }} width={390} src={require("@assets/images/brand/senior-bcl-premier-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.SBCL && ordering == 1 && <img style={{ marginRight: "10px" }} width={490} src={require("@assets/images/brand/senior-bcl-championship-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.SBCL && ordering > 1 && <img style={{ marginRight: "10px" }} width={470} src={require("@assets/images/brand/senior-bcl-tier-a-b-c-league-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.WBCL && <img style={{ marginRight: "60px" }} width={415} src={require("@assets/images/brand/womens-bcl-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.VBCL && <img style={{ marginRight: "90px" }} width={340} src={require("@assets/images/brand/veterans-bcl-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YBGBCL && printoutSub == 1 && <img style={{ marginRight: "120px" }} width={275} src={require("@assets/images/brand/boys-youth-league-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YBGBCL && printoutSub == 2 && <img style={{ marginRight: "120px" }} width={275} src={require("@assets/images/brand/girls-youth-league-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YCJBCL && printoutSub == 1 && <img style={{ marginRight: "85px" }} width={345} src={require("@assets/images/brand/BCL-youth-cadet-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YCJBCL && printoutSub == 2 && <img style={{ marginRight: "75px" }} width={355} src={require("@assets/images/brand/bcl-junior-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout != ScoreSheetVersion.Default && <img style={{ marginRight: "10px" }} width={175} src={require("@assets/images/brand/british-clubs-leagues-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                <img width={130} src={require("@assets/images/brand/tabletennis-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
            </div> */}
                {printout == ScoreSheetVersion.SBCL && ordering == 0 && <img src={require("@assets/images/brand/logosets/SPBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.SBCL && ordering == 1 && <img src={require("@assets/images/brand/logosets/SCBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.SBCL && ordering > 1 && <img  src={require("@assets/images/brand/logosets/STBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.WBCL && <img src={require("@assets/images/brand/logosets/WBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.VBCL && <img src={require("@assets/images/brand/logosets/VBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YBGBCL && printoutSub == 1 && <img src={require("@assets/images/brand/logosets/YBBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YBGBCL && printoutSub == 2 && <img src={require("@assets/images/brand/logosets/YGBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YCJBCL && printoutSub == 1 && <img src={require("@assets/images/brand/logosets/YCBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.YCJBCL && printoutSub == 2 && <img src={require("@assets/images/brand/logosets/YJBCL-Logo-Set.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
                {printout == ScoreSheetVersion.Default && <img style={{ float: "right" }} width={130} src={require("@assets/images/brand/tabletennis-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
        </div>
    )
}

export { Logos };