import { Group } from "./group";
import { User } from "./user";
import { MenuGroup } from "./website";

export class Content {
	public id: number;
	public title: string;
	public description: string;
	public slug: string;
	public summary: string;
	public body: string;
	public menuName: string;
	public menuGroupId: number;
	public menuGroup: string;
	public published?: Date;
	public forwardTo: string;
	public showOnMenu: boolean;
	public featured: boolean;
	public created: Date;
	public headerId?: number;
	public featuredId?: number;
	public tags: string[];
	public category: string;
	public secure: boolean;
	public accessType: number;
	public userContentAccess: string[];
	public groupContentAccess: number[];
	public layout?: ContentLayout;
	public group: Group;
	public groupId?: number;
}

export class CreateContent {
	public title: string;
}

export class ContentOrder {
	public id: number;
	public name: string;
	public ordering: number;
}

export class ContentAccess {
	public contentId: number;
	public content: Content;
	public userId: string;
	public user: User;
	public groupId: number;
	public group: Group;
}

export enum ContentLayout {
	MemberGroup = 1,
	CaptainsList = 2,
	ClubSecretaries = 3
}
