import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "reactstrap";
import * as NProgress from "nprogress";
import Lightbox from 'react-images';

import { AppCache } from "@cache";

import PublicLayout from "@components/layouts/PublicLayout";

import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { Paging } from "@components/controls/Paging";
import History from "@helpers/history.helper";

import { WebsiteService } from "@services/website.service";
import { GalleryMedia, MediaGallery } from "@models/gallery";
import { GalleryService } from "@services/gallery.service";
import { DatatableMeta, DatatableQuery } from "@models/query";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import Gallery from "react-photo-gallery";
import { PagedList } from "@models/paging";
import InfiniteScroll from "react-infinite-scroll-component";
import { FilterOptions } from "@models/filters/filterOptions";
import { AppConfig } from "@config";

interface IGalleryViewPageProps {
	site?: string;
	match?: any;
}

interface IGalleryViewPageState {
	loading: boolean;
	nextPathname?: any;
	photos?: PagedList<GalleryMedia>;
	gallery?: MediaGallery;

	selected: number;
	isOpen: boolean;

	options: FilterOptions;
}
const containerStyle = {
	width: '100%',
	height: '467.59px',
	marginbottom: '300px',
};

const containerStylePreview = {
	width: '100%',
	height: '300px',
	marginbottom: '300px',
};

export class GalleryViewPage extends React.Component<IGalleryViewPageProps, IGalleryViewPageState> {

	private galleryId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			options: { count: AppCache.settings.pageSize, page: 1 },
			selected: 0,
			isOpen: false
		};
	}

	componentDidMount() {
		//
		this.galleryId = this.props.match.params.galleryId as number;

		this.load();
	}

	componentDidUpdate(props: IGalleryViewPageProps, state: IGalleryViewPageState) {

	}

	async load() {
		NProgress.start();

		const gallery = await GalleryService.getById(this.galleryId);
		const photos = await GalleryService.getMediaByGalleryId(this.galleryId, this.state.options);

		this.setState({ loading: false, gallery, photos });

		NProgress.done();
	}

	loadImages() {
		GalleryService.images(this.state.gallery.id, null).then(result => {
			this.setState({ gallery: { ...this.state.gallery, media: result.data } });
		});
	}

	next = () => async () => {
		NProgress.start();

		const page = this.state.options.page + 1;
		const result = await GalleryService.getMediaByGalleryId(this.galleryId, { ...this.state.options, page });

		const photos = this.state.photos;
		photos.page = page;
		result.items.forEach(e => photos.items.push(e));

		this.setState({ photos, options: { ...this.state.options, page } });

		NProgress.done();
	}

	getImages = (): any[] => {
		let images;
		if (this.state.photos) {
			images = this.state.photos.items.map(l => {
				return ({
					id: l.media.id,
					src: `${AppConfig.baseUrl}media/${l.media.id}`,
					width: l.media.width,
					height: l.media.height,
					media: l
				});
			});
		}

		return images;
	}

	closeLightbox = () => () => {
		this.setState({ isOpen: false, selected: 0 });
	}

	openLightbox = () => () => {
		this.setState({ isOpen: true, selected: 0 });
	}

	gotoPrevious = () => () => {
		this.setState({
			selected: this.state.selected - 1,
		});
	}
	gotoNext = () => () => {
		this.setState({
			selected: this.state.selected + 1,
		});
	}

	public render() {

		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							<div className="page_content_wrap">
								<div className="container">
									<InfiniteScroll
										dataLength={this.state.photos.items.length}
										next={this.next()}
										hasMore={this.state.photos.items.length < this.state.photos.total}
										loader={<h4>Loading...</h4>}>
										<Gallery photos={this.getImages()} onClick={this.openLightbox()} />
										<Lightbox images={this.getImages()}
											onClose={this.closeLightbox()}
											onClickPrev={this.gotoPrevious()}
											onClickNext={this.gotoNext()}
											currentImage={this.state.selected}
											isOpen={this.state.isOpen}
										/>
									</InfiniteScroll>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout>);
	}
}
