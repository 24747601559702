import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from "reactstrap";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";
import Select from "react-select";


import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { Average, AverageSort, DoublesAverage } from "@models/average";
import { AppCache } from "@cache";
import { SelectOption } from "@models/forms";
import { Options } from "@data";
import { WebsiteCompetitionData } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";

const dataFormat = ["table", "results", "averages", "potms", "rankings"];

interface IAveragesPageProps {
	site?: string;
	match?: any;
}

interface IAveragesPageState {
	loading: boolean;
	updating: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	averages: Average[];
	averagesHold: Average[];
	dAverages: DoublesAverage[];
	dAveragesHold: DoublesAverage[];
	doubles: boolean;
	showFilters: boolean;
}
export class AveragesPage extends React.Component<IAveragesPageProps, IAveragesPageState> {

	private competitionId: number;
	private divisionId: number;
	private sort: SelectOption<number>;
	private filter: SelectOption<number> = { label: "Any", value: 0 };
	private group: SelectOption<number> = { label: "Team", value: 0 };
	private Teams: boolean = true;
	private Pairs: boolean = true;
	private navigationIds: WebsiteCompetitionData[];
	private compIndex: number;
	private divIndex: number;
	private formIndex: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			updating: false,
			averages: [],
			averagesHold: [],
			dAverages: [],
			dAveragesHold: [],
			doubles: false,
			showFilters: false
		};
	}

	componentDidMount() {
		//
		this.divisionId = this.props.match.params.divisionId as number;
		this.competitionId = this.props.match.params.competitionId as number;
		const p1 = CompetitionService.getById(this.competitionId).then(competition => {
			this.setState({ competition });
		});
		Promise.all([p1]).then(result => {
			this.group = this.state.competition.averages.consolidatedAverages ? { label: "Club", value: 1 } : { label: "Team", value: 0 };
			this.filter = this.state.competition.averages.minimumPlayed > 0 ? { label: this.state.competition.averages.minimumPlayed.toString(), value: this.state.competition.averages.minimumPlayed } : { label: "Any", value: 0 }
			if(this.group.value === 0) {
				this.Teams = true;
				this.Pairs = true;
			} else {
				this.Teams = false;
				this.Pairs = false;
			}
			this.load();
		});
	}

	componentDidUpdate(props: IAveragesPageProps, state: IAveragesPageState) {
		this.divisionId = this.props.match.params.divisionId as number;

		if (props.match.params.divisionId !== this.divisionId) {
			this.load();
		}
	}

	load() {
		var p1 = null;
		var p2 = null;
		if(this.Pairs){
			p2 = DivisionService.getDoublesAverages(this.divisionId).then(averages => {
				NProgress.done();
				this.setState({ dAverages: this.sortFilterAverages(null, this.filter, averages), dAveragesHold: JSON.parse(JSON.stringify(averages))});
			});
		}
		else{
			p2 = DivisionService.getDoublesAveragesAll(this.divisionId).then(averages => {
				NProgress.done();
				this.setState({ dAverages: this.sortFilterAverages(null, this.filter, averages), dAveragesHold: JSON.parse(JSON.stringify(averages))});
			});
		}
		if (this.Teams) {
			p1 = DivisionService.getAverages(this.divisionId).then(averages => {
				NProgress.done();
				this.setState({ averages: this.sortFilterAverages(null, this.filter, averages), averagesHold: JSON.parse(JSON.stringify(averages)) });
			});
		} 
		else {
			p1 = DivisionService.getAveragesAll(this.divisionId).then(averages => {
				NProgress.done();
				this.setState({ averages: this.sortFilterAverages(null, this.filter, averages), averagesHold: JSON.parse(JSON.stringify(averages)) });
			});
		}
		this.sort = null;

		const p3 = DivisionService.getById(this.divisionId).then(division => {
			this.setState({ division });
		});
		Promise.all([p1, p2, p3]).then(result => {

			this.setState({ loading: false });
		});
	}

	sortFilterAverages(sort: SelectOption<number>, filter: SelectOption<number>, averages: any[]) {
		if(averages.length > 0){
			if (sort != null) {
				if (this.state.competition.averages.orderType === 1) {
					averages = averages.sort((a, b) => a[AverageSort[sort.value].toLowerCase()].localeCompare(b[AverageSort[sort.value].toLowerCase()], "en-EN") || b.percentage - a.percentage || b.won - a.won || b.played - a.played || a.name.localeCompare(b.name, "en-EN"));
				} else if (this.state.competition.averages.orderType === 2) {
					averages = averages.sort((a, b) => a[AverageSort[sort.value].toLowerCase()].localeCompare(b[AverageSort[sort.value].toLowerCase()], "en-EN") || b.won - a.won || b.percentage - a.percentage || b.played - a.played || a.name.localeCompare(b.name, "en-EN"));
				} else {
					averages = averages.sort((a, b) => a[AverageSort[sort.value].toLowerCase()].localeCompare(b[AverageSort[sort.value].toLowerCase()], "en-EN") || a.name.localeCompare(b.name, "en-EN"));
				}
			}
			averages = averages.filter((a) => a.played >= filter.value);
		}
		return averages;
	}

	handleChangeSelectSort = () => (e: any) => {
		this.sort = e;
		this.setState({updating: true});
		if (!this.state.doubles){
			var averages = JSON.parse(JSON.stringify(this.state.averagesHold));
			averages = this.sortFilterAverages(e, this.filter, averages);
			this.setState({averages: averages, updating: false});
		}
		else if (this.state.doubles){
			var dAverages = JSON.parse(JSON.stringify(this.state.dAveragesHold));
			dAverages = this.sortFilterAverages(e, this.filter, dAverages);
			this.setState({dAverages: dAverages, updating: false});
		}
	}

	handleChangeSelectGroup= () => (e: any) => {
		this.group = e;
		if(e.value === 0) {
			this.Teams = true;
			this.Pairs = true;
		} else {
			this.Teams = false;
			this.Pairs = false;
		}
		this.setState({loading: true}, ()=> {
			this.load();
		});
	}

	// generateFilterOptions() {
	// 	const options = [];
	// 	if(this.state.competition.averages.minimumPlayed == 0)
	// 	{
	// 		options.push({ label: "Any", value: 0 });
	// 		for (var i = 1; i < 50; i++) {
	// 			options.push({ label: i.toString(), value: i });
	// 		}
	// 	}
	// 	else{
	// 		for (var i = this.state.competition.averages.minimumPlayed; i < 50; i++) {
	// 			options.push({ label: i.toString(), value: i });
	// 		}
	// 	}

	// 	return options;
	// }

	generateFilterOptions() {
		var options = []
		for(var i = /* this.state.competition ? this.state.competition.averages.minimumPlayed : */ 0; i < 50; i++){
			options.push({label: i.toString(), value: i})
		}
		return options;
	}

	handleChangeSelectFilter = () => (e: any) => {
		this.filter = e;
		this.setState({updating: true});
		if(e != null){
			if (!this.state.doubles){
				var averages: Average[];
				averages = JSON.parse(JSON.stringify(this.state.averagesHold));
				averages = this.sortFilterAverages(this.sort, e, averages);
				this.setState({averages: averages, updating: false});
			}
			else if (this.state.doubles){
				var dAverages: DoublesAverage[];
				dAverages = JSON.parse(JSON.stringify(this.state.dAveragesHold));
				dAverages = this.sortFilterAverages(this.sort, e, dAverages);
				this.setState({dAverages: dAverages, updating: false});
			}
		}
	}

	toggleFilters = () => (e: any) => {
		this.setState({ showFilters: !this.state.showFilters });
	}
	toggleDoubles(){
		this.filter = this.state.competition.averages.minimumPlayed > 0 ? { label: this.state.competition.averages.minimumPlayed.toString(), value: this.state.competition.averages.minimumPlayed } : { label: "Any", value: 0 }
		this.sort = null;
		this.setState({doubles: !this.state.doubles});
	}

	public render() {
		return (
			<PublicLayout className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
				    mode={ShowMeMode.Full}
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Averages</h1>
																			<p className="sc_layouts_title_breadcrumbs">{this.state.competition ? this.state.competition.name : ""} <span className="separator"> - </span> {this.state.division ? this.state.division.name : ""}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />
									</div> : undefined
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										{
											AppCache.website.config.subHeader ? undefined :
												<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div id="trx_sc_title_789087444" className="sc_title sc_title_default mb-5">
															<h1 className=" m-0 sc_item_subtitle sc_align_center">
																<span className="sc_item_title_text">Averages</span>
															</h1>
															<h2 className=" m-0 sc_item_title sc_align_center">
																<span className="sc_item_title_text">{this.state.division ? this.state.division.name : ""}</span>
															</h2>
															<p className="text-center">
																{this.state.competition ? this.state.competition.name : ""}
															</p>
														</div>
													</div>
												</div>
										}
										{!AppCache.website.config.subHeader && <CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />}
										<div className="row">
											<div className="col-12">
												<div>
													<div className="standings">
														<div>
															<div className="row" id="filters">
																<div className="col-11">
																	<a className="clickable float-right" onClick={() => this.setState({doubles: !this.state.doubles})}><i className={`fal fa-${this.state.doubles ? `user` : `user-friends`} tx-24`}></i></a>
																</div>
																<div className="col-1">
																	<a className="clickable float-right" onClick={this.toggleFilters()}><i className={`fal fa-filter tx-24`}></i></a>
																</div>
																
															</div>
															{
																this.state.showFilters ?
																	<div className="row mb-4">
																		<div className="col-4">
																			<label>Games Played</label>
																			<Select
																				value={this.filter}
																				options={this.generateFilterOptions()}
																				onChange={this.handleChangeSelectFilter()}
																				closeMenuOnSelect={true} />
																		</div>
																		<div className="col-4">
																			<label>Sort By</label>
																			<Select
																				isClearable
																				value={this.sort}
																				options={this.Teams ? Options.averageSort : Options.averageSort.filter((a) => a.label !== "Team")}
																				onChange={this.handleChangeSelectSort()}
																				closeMenuOnSelect={true} />
																		</div>
																		<div className="col-4">
																			<label>Group By</label>
																			<Select
																				value={this.group}
																				options={[
																					{ label: "Team", value: 0},
																					{ label: "Club", value: 1}
																				]}
																				onChange={this.handleChangeSelectGroup()}
																				closeMenuOnSelect={true} />
																		</div>
																	</div> : undefined
															}
															<div className="table-resposive">
																<div>
																	<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" id="DataTables_Table_0" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Player">{this.state.doubles ? "Players" : "Player"}</th>
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team"> Team</th>
																				{!this.Teams || !this.Pairs ? <th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team">Club</th> : undefined}
																				<th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="P">P</th>
																				<th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="W">W</th>
																				<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">%</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.doubles ? 
																				this.state.dAverages.map(s => {
																					return (
																						<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
																							<td className="data-name" data-label="Name"><Col>
																								<Row><Link to={`/league/${this.competitionId}/player/${s.userOneId}`}>{s.nameOne}</Link></Row>
																								<Row><Link to={`/league/${this.competitionId}/player/${s.userTwoId}`}>{s.nameTwo}</Link></Row>
																							</Col></td>
																							<td className="data-team" data-label="Team"><Link to={`/league/${this.competitionId}/team/${s.teamId}`}>{s.team}</Link></td>
																							{!this.Pairs ? <td className="data-team" data-label="Team"><Link to={`/club/${s.clubId}`}>{s.club}</Link></td> : undefined}																							<td className="data-p" data-label="P">{s.played}</td>
																							<td className="data-w" data-label="W">{s.won}</td>
																							<td className="data-pts" data-label="Pts">{s.percentage}</td>
																						</tr>
																					);
																				})
																				:
																				this.state.averages.map(s => {
																					return (
																						<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
																							<td className="data-name" data-label="Name"><Link to={`/league/${this.competitionId}/player/${s.userId}`}>{s.name}{s.reserve && <i className="far fa-arrow-circle-up text-info ml-1"/>}</Link></td>
																							<td className="data-team" data-label="Team"><Link to={`/league/${this.competitionId}/team/${s.teamId}`}>{s.team}</Link></td>
																							{!this.Teams ? <td className="data-team" data-label="Team"><Link to={`/club/${s.clubId}`}>{s.club}</Link></td> : undefined}
																							<td className="data-p" data-label="P">{s.played}</td>
																							<td className="data-w" data-label="W">{s.won}</td>
																							<td className="data-pts" data-label="Pts">{s.percentage}</td>
																						</tr>
																					);
																				})
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<span className="tx-bold">Last Updated</span> <span title={moment(this.state.division.updated).format("Do MMMM YYYY")}>{moment(this.state.division.updated).fromNow()}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
