import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";


import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { Row, Col, Card } from "reactstrap";

interface IReservesListProps {
	site?: string;

}

interface IReservesListState {
	loading: boolean;
	competitions?: Competition[];
	search?: string;
}
export class ReservesListPage extends React.Component<IReservesListProps, IReservesListState> {

	constructor(props) {
		super(props);
		this.state = { loading: true, competitions: [] };
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleSearchChange(e: any) {
		this.setState({ search: e.currentTarget.value }, () => {
			this.load();
		});
	}

	load() {
		CompetitionService.get().then(competitions => {
			this.setState({ loading: false, competitions });
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div>
											<h2 className=" m-0 sc_item_title sc_align_center"><span className="sc_item_title_text">Reserves</span></h2>
											<p className="text-center">A list of leagues currently running</p>
										</div>
										<div className="competition-list mg-t-20">
											<div className="row clearfix">
												<div className="col-lg-12">
													<div className="list-group list-group-default">
														{
															this.state.competitions.map(c => {
																return (
																	<div key={`competition-${c.id}`} className="competition mb-3">
																		<Row className="mb-3">
																			<Col xs={4}>
																				<Link className="mr-2" to={`/league/${c.id}/reserves`} title="Reserves">
																					<Card className="p-3">
																						<h5 className="mt-2 mb-2">{c.name}</h5>
																					</Card>
																				</Link>
																			</Col>
																		</Row>
																	</div>
																);
															})
														}
														{
															this.state.loading ? <ActivitySpinner /> : <Empty text="No leagues currently published" enabled={this.state.competitions.length === 0} />
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
			</PublicLayout>
		);
	}
}
