import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Division, CreateDivision } from "@models/division";

import store from "store2";

import * as moment from "moment";
import { DatatableMeta, DatatableQuery, DatatableResponse } from "@models/query";
import { Standing } from "@models/standing";
import { MatchGrouping, MatchCard, CreateMatch, MatchStatus, Match, MatchGroupRange, MatchGroupType, MatchGroup, MatchSet, PotmType, MatchFilters, MatchResults, MatchCardEntryLevel, MatchReport, UpdateMatch } from "@/models/match";
import { Entrant } from "@models/entrant";
import { TeamMember } from "@models/team";
import { MatchGame } from "../models/match";
import { ActionEntrant, AddComment, MatchAction } from "../models/action";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { Action } from "@fullcalendar/core";
import { CompetitionDay, ReserveFilter } from "@models/competition";
import { Reserve } from "@models/reserve";
import { FreeSlots } from "@models/week";
import { CreateGallery, MediaGallery, GalleryMedia } from "@models/gallery";
import { PagedList } from "@models/paging";
import { FilterOptions } from "@models/filters/filterOptions";

import * as qs from "qs";

export class GalleryService {

	constructor() {
		//
	}

	public static get(options: FilterOptions): Promise<PagedList<MediaGallery>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`gallery`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getMediaByGalleryId(galleryId: number, options: FilterOptions): Promise<PagedList<GalleryMedia>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`gallery/${galleryId}/media`,
			{
				params: options,
				paramsSerializer: params => qs.stringify(params)
			}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getById(galleryId: number): Promise<MediaGallery> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`gallery/${galleryId}`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static data(query: DatatableQuery): Promise<DatatableResponse<MediaGallery>> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/data`, { query } ).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static images(galleryId: number, query: DatatableQuery): Promise<DatatableResponse<GalleryMedia>> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/${galleryId}/images`, { query } ).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static create(data: CreateGallery): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/create`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static remove(galleryId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`gallery/${galleryId}/remove`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static update(data: MediaGallery): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/update`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static delete(galleryId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`gallery/${galleryId}`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static sort(media: GalleryMedia[]): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/sort`, media).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static assign(data: GalleryMedia): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/assign`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static unassign(data: GalleryMedia): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`gallery/unassign`, data).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}
}
