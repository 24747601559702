import * as React from "react";

import Select from "react-select";

interface IEmpty {
	text?: string;
	buttonText?: string;
	className?: string;
	onClick?: () => void;
	enabled?: boolean;
	icon: string;
}

export class Empty extends React.Component<IEmpty, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		enabled: true,
		className: "",
		icon: "fal fa-ellipsis-h"
	};

	componentDidMount() {
		//
	}

	render() {
		return (
			this.props.enabled ?
				<div className={`empty-container d-flex pos-relative align-items-center ${this.props.className}`}>
					<div className="content">
						<i className={`icon ${this.props.icon}`}></i>
						{this.props.text && <p className="mt-2">{this.props.text}</p>}
						{
							this.props.buttonText &&
								<a href="" className="btn btn-secondary btn-oblong text-white mr-2" title="edit"><i className="icon ion-plus"></i> {this.props.buttonText}</a>
						}
						{this.props.children}
					</div>
				</div> : null
		);
	}
}
