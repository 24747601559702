import * as React from "react";

import * as moment from "moment";

import { MatchSet, MatchPlayer, MatchCard, Match } from "@models/match";

import { Competition, PlayerLabels, ScoreSheetVersion } from "@models/competition";
import { Division } from "@models/division";
import { MatchService } from "@services/match.service";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";
import { ready } from "jquery";
import { th } from "date-fns/locale";
import { Season } from "@models/season";
import { Logos } from "./print/Logos";
import { Header } from "./print/Header";

interface IMatchCardTemplatePageProps {
	site?: string;
	match?: any;
}

interface IMatchCardTemplatePageState {
	loading: boolean;
	updating: boolean;
	nextPathname?: any;
	competition?: Competition;
	division?: Division;
	matchCard?: MatchCard;
}

export class MatchCardTemplatePage extends React.Component<IMatchCardTemplatePageProps, IMatchCardTemplatePageState> {

	public matchId: number;
	public matchCard: MatchCard;

	public selfUpmire: boolean;
	public umpireReferee: boolean;
	public matchNumber: boolean;
	public freeHeader: boolean;
	public freeFooter: boolean;
	public comments: boolean;
	public showVs: boolean;
	public showRound: boolean;
	public showWarnings: boolean;
	public setWinner: boolean;
	public umpireSignature: boolean;
	public sblInfo: boolean;
	public doublesText: boolean;
	public sponserLogo: boolean;
	public divVenue: boolean;

	public printout: ScoreSheetVersion;
	public printoutSub: number;

	private umpires: number[][];

	public get homePlayers(): MatchPlayer[] {
		return this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3);
	}

	public get awayPlayers(): MatchPlayer[] {
		return this.state.matchCard.playing.away.filter(m => m.type == 1 || m.type == 3);
	}

	public get homeDoublePlayers(): any[] {
		return this.groupBy(this.state.matchCard.playing.home.filter(m => m.type == 2), (g) => g.ordering);
	}

	public get awayDoublePlayers(): any[] {
		return this.groupBy(this.state.matchCard.playing.away.filter(m => m.type == 2), (g) => g.ordering);
	}

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			updating: false,
		};
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	load() {

		this.matchId = this.props.match.params.matchId as number;
		const divisionId = this.props.match.params.divisionId as number;
		const competitionId = this.props.match.params.competitionId as number;

		const p1 = DivisionService.getById(divisionId).then(division => {
			this.setState({ division }, () => {

			});
		});

		const p2 = CompetitionService.getById(competitionId).then(competition => {
			this.setState({ competition }, () => {

			});
		});

		const p3 = MatchService.matchCard(this.matchId).then(matchCard => {

			this.setState({ matchCard }, () => {
				this.matchCard = matchCard;
			});
		});

		Promise.all([p1, p2, p3]).then(result => {
			this.printout = this.state.competition.matchCard.printoutLayout
			this.printoutSub = this.state.competition.matchCard.printoutSubLayout
			if (this.printout == ScoreSheetVersion.SBCL) {
				this.selfUpmire = false;
				this.umpireReferee = true;
				this.showVs = false;
				this.showRound = true;
				this.freeHeader = true;
				this.freeFooter = true;
				this.matchNumber = true;
				this.comments = false;
				this.showWarnings = true;
				this.sblInfo = true;
				this.setWinner = false;
				this.umpireSignature = false;
				this.doublesText = true;
				this.sponserLogo = true;
				this.divVenue = true;
			}
			else if (this.printout == ScoreSheetVersion.WBCL || this.printout == ScoreSheetVersion.VBCL) {
				this.selfUpmire = false;
				this.umpireReferee = false;
				this.showVs = true;
				this.showRound = true;
				this.freeHeader = true;
				this.freeFooter = true;
				this.matchNumber = true;
				this.comments = false;
				this.showWarnings = true;
				this.sblInfo = false;
				this.setWinner = false;
				this.umpireSignature = true;
				this.doublesText = true;
				this.sponserLogo = true;
				this.divVenue = true;
			}
			else if (this.printout == ScoreSheetVersion.YBGBCL) {
				this.selfUpmire = false;
				this.umpireReferee = false;
				this.showVs = true;
				this.showRound = true;
				this.freeHeader = true;
				this.freeFooter = true;
				this.matchNumber = true;
				this.comments = false;
				this.showWarnings = true;
				this.sblInfo = true;
				this.setWinner = false;
				this.umpireSignature = true;
				this.doublesText = true;
				this.sponserLogo = true;
				this.divVenue = true;
			}
			else if (this.printout == ScoreSheetVersion.YCJBCL) {
				this.selfUpmire = true;
				this.umpireReferee = false;
				this.showVs = false;
				this.showRound = false;
				this.freeHeader = true;
				this.freeFooter = true;
				this.matchNumber = true;
				this.comments = false;
				this.showWarnings = true;
				this.sblInfo = true;
				this.setWinner = true;
				this.umpireSignature = false;
				this.doublesText = true;
				this.sponserLogo = true;
				this.divVenue = true;
			}
			else {
				this.selfUpmire = false;
				this.umpireReferee = false;
				this.showVs = true;
				this.showRound = false;
				this.freeHeader = false;
				this.freeFooter = false;
				this.matchNumber = false;
				this.comments = true;
				this.showWarnings = false;
				this.sblInfo = false;
				this.setWinner = false;
				this.umpireSignature = false;
				this.doublesText = false;
				this.sponserLogo = false;
				this.divVenue = false;
			}

			if (this.state.matchCard.sets.filter(s => s.homePlayers.length == 1).length == 6) {
				this.umpires = [[0, 1, 0, 2], [1, 0, 1, 2], [2, 2, 1, 1], [0, 0, 0, 1], [2, 1, 0, 0], [1, 2, 1, 0]]
				//this.umpires = [this.label(2,true,3),this.label(2,false,3),this.label(1,false,3),this.label(1,true,3),this.label(0,true,3),this.label(0,false,3)];
			}
			else if (this.state.matchCard.sets.filter(s => s.homePlayers.length == 1).length == 9) {
				this.umpires = [[0, 0, 0, 2], [1, 1, 1, 2], [2, 2, 0, 0], [1, 0, 1, 1], [0, 2, 0, 1], [2, 1, 1, 0], [1, 2, 0, 0], [2, 0, 1, 1], [0, 1, 0, 1]]
				//this.umpires = [this.label(2,true,3),this.label(2,false,3),this.label(0,true,3),this.label(1,false,3),this.label(1,true,3),this.label(0,false,3),this.label(0,true,3),this.label(1,false,3),this.label(1,true,3)];
			}

			this.setState({ loading: false }, () => {
				if (!this.state.matchCard.match.home.score && !this.state.matchCard.match.away.score) {
					this.matchCard.sets.map(s => this.matchCard.match.home.score += (s.homeScore > s.awayScore ? 1 : 0));
					this.matchCard.sets.map(s => this.matchCard.match.away.score += (s.awayScore > s.homeScore ? 1 : 0));
					this.setState({ matchCard: this.matchCard });
				}
			});
		});
	}

	hasWon(home: boolean, set: MatchSet): boolean {
		const won = set.completed && ((home && (set.homeScore > set.awayScore)) ||
			(!home && (set.awayScore > set.homeScore)));

		return won;
	}

	runningScore(set: MatchSet, home: boolean): string {
		var homeScore = 0;
		var awayScore = 0;
		var sets = this.state.matchCard.sets;
		var index = this.state.matchCard.sets.findIndex(s => s.id == set.id);
		for (let i = 0; i <= index; i++) {
			var s = sets[i];
			if (s.completed) {
				if (s.awayScore > s.homeScore) {
					awayScore++;
				}
				else if (s.homeScore > s.awayScore) {
					homeScore++;
				}
			}
		}

		return set.completed ? (home ? `${homeScore}` : `${awayScore}`) : undefined;
	}

	playerName(p: MatchPlayer): string {
		return p.userId.length > 0 ? p.printoutName : "";
	}

	label(ordering: number, home: boolean, playerCount: number) {
		var alpha = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
		var index = 0;
		if (this.state.competition?.matchCard.playerLabels === PlayerLabels.Alphabetical) {
			if (home)
				index = ordering;
			else
				index = (26 - playerCount) + ordering;
			return alpha[index];
		}
		else {
			return `${home ? `H` : `A`} ${ordering + 1}`;
		}
	}

	umpire(set: MatchSet) {
		var umpire = this.umpires.find(u => u[0] == set.homePlayers[0].ordering && u[1] == set.awayPlayers[0].ordering);
		return this.label(umpire[3], umpire[2] == 0, 3);
	}

	groupBy(array, f) {
		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		})
	}

	isReady(): boolean {
		return this.state.matchCard.sets.find(s => !s.completed) == null;
	}

	private doubleHeight: string = "70px";
	private singleHeight: string = "35px";

	toSeason(date: Date): Season {
		var d = moment(date);
		var year = (d.month() >= 8 ? d.year() : d.year() - 1);
		var start = moment(`${year}-08-01`);
		var end = moment(`${year + 1}-07-31`);
		var name = `${start.year()}-${end.year().toString().substring(2, 4)}`;

		return { name, start: start.toDate(), end: end.toDate() };
	}

	getVenue(division: Division, match: Match, divVenue: boolean) {
		if(divVenue && !(match.venue?.length > 0)){
			var name = division.name
			var nameSplit = name.split('-');
			if(nameSplit.length > 1){
				return nameSplit[1];
			}
			else{
				return undefined;
			}
		}
		else{
			return match.venue
		}
	}

	getDivision(division: Division, divVenue: boolean) {
		if(divVenue){
			var name = division.shortName.length > 0 ? division.shortName : division.name
			var nameSplit = name.split('-');
			if(nameSplit.length > 0){
				return nameSplit[0];
			}
			else{
				return undefined;
			}
		}
		else{
			return division ? (division.shortName.length > 0 ? division.shortName : division.name) : undefined
		}
	}

	public render() {
		return (
			this.state.matchCard ? <div style={{ pageBreakAfter: "always", backgroundColor: "white", color: "black" }}>
				{this.freeHeader ? <div>
					<Logos printout={this.printout} printoutSub={this.printoutSub} ordering={this.state.division.ordering} sponserLogo={this.sponserLogo} />
					{/* <div style={{border: "1px solid black", height: 150}} className="row">
						<div className="col-4">
							{this.sponserLogo && <img style={{border: "1px red solid"}} width={290} src={require("@assets/images/brand/bribar-double-fish-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
						</div>
						<div className="col-4">
							<img style={{border: "1px red solid"}} width={800} src={require("@assets/images/brand/senior-bcl-championship-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
						</div>
						<div className="col-4" style={{textAlign:"right"}}>
							<img style={{border: "1px red solid"}} width={175} src={require("@assets/images/brand/british-clubs-leagues-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
							<img style={{border: "1px red solid"}} width={170} src={require("@assets/images/brand/tabletennis-logo.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
						</div>
					</div> */}
					<Header
						printout={this.printout}
						printoutSub={this.printoutSub}
						season={this.toSeason(this.state.competition.startDate).name}
						venue={this.getVenue(this.state.division, this.state.matchCard.match, this.divVenue)}
						umpire={this.umpireReferee ? "Umpire" : "Referee"}
						division={this.getDivision(this.state.division, this.divVenue)}
						date={this.state.matchCard.match.time ? moment(this.state.matchCard.match.time).format("DD-MM-YYYY hh:mm") : this.state.matchCard.match.date ? moment(this.state.matchCard.match.date).format("DD-MM-YYYY") : undefined}
						round={this.state.matchCard.match.round}
						table={this.state.matchCard.match.tableNo}
					/>
				</div>
					:
					<div>
						<div className="row">
							<div className="col-3">
								<img width={150} height={150} src={require("@assets/images/placeholders/tte-square.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
							</div>
							<div className="col-6">
							</div>
							<div className="col-3" style={{ textAlign: "right" }}>
								{this.sponserLogo && <img width={225} height={225} src={require("@assets/images/placeholders/Butterfly_Teessport Logo.jpg")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />}
							</div>
						</div>
						<br />
						<table style={{ border: "1px solid black", borderBottom: "0px", borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
							<tbody>
								<tr style={{ border: "1px solid black", borderBottom: "0px" }}>
									<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px 1px 1px 5px" }} colSpan={9}><h3 style={{ textAlign: "center" }}>{this.state.competition ? `${this.state.competition.name}` : undefined}</h3></th>
								</tr>
							</tbody>
						</table>
						<table style={{ border: "1px solid black", borderBottom: "0px", borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
							<tbody>
								<tr style={{ border: "1px solid black", borderBottom: "0px" }}>
									<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "126px", height: "60px" }}>Venue</th>
									<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "380px", height: "60px" }} colSpan={3}>{this.state.matchCard.match.venue}</th>
									<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "128px", height: "60px" }}>Date</th>
									{this.showRound ?
										<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "140px", height: "60px" }}>{this.state.matchCard.match.time ? moment(this.state.matchCard.match.time).format("DD-MM-YYYY hh:mm") : this.state.matchCard.match.date ? moment(this.state.matchCard.match.date).format("DD-MM-YYYY") : undefined}</th>
										:
										<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "140px", height: "60px" }}>{this.state.matchCard.match.date ? moment(this.state.matchCard.match.date).format("DD-MM-YYYY") : undefined}</th>
									}
									<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "126px", height: "60px" }}>Referee</th>
									<th style={{ border: "1px solid black", borderBottom: "0px", padding: "1px", width: "300px", height: "60px" }} colSpan={3}></th>
								</tr>
							</tbody>
						</table>
						<table style={{ border: "1px solid black", borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
							<tbody>
								<tr style={{ border: "1px solid black" }}>
									<th style={{ border: "1px solid black", padding: "1px", width: "126px", height: this.singleHeight }}>Division</th>
									<th style={{ border: "1px solid black", padding: "1px", width: "380px", height: this.singleHeight }} colSpan={3}>{this.state.division ? `${this.state.division.name}` : undefined}</th>
									{this.showRound ?
										<>
											<th style={{ border: "1px solid black", padding: "1px", width: "128px", height: this.singleHeight }}>Round</th>
											<th style={{ border: "1px solid black", padding: "1px", width: "140px", height: this.singleHeight }}>{this.state.matchCard.match.round}</th>
										</>
										:
										<>
											<th style={{ border: "1px solid black", padding: "1px", width: "128px", height: this.singleHeight }}>Time</th>
											<th style={{ border: "1px solid black", padding: "1px", width: "140px", height: this.singleHeight }}>{this.state.matchCard.match.time ? moment(this.state.matchCard.match.time).format("hh:mm") : undefined}</th>
										</>
									}
									<th style={{ border: "1px solid black", padding: "1px", width: "126px", height: this.singleHeight }}>{this.selfUpmire ? "Table #" : "Umpire"}</th>
									<th style={{ border: "1px solid black", padding: "1px", width: "300px", height: this.singleHeight }} colSpan={3}>{this.selfUpmire ? this.state.matchCard.match.tableNo : undefined}</th>
								</tr>
							</tbody>
						</table>
						{(this.showVs || !this.selfUpmire) && <table style={{ border: "1px solid black", borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
							<tbody>
								<tr style={{ border: "1px solid black" }}>
									{this.showVs ? <>
										<th style={{ border: "1px solid black", padding: "1px", width: "126px", height: this.singleHeight }} >Match</th>
										<th style={{ border: "1px solid black", padding: "1px", width: "380px", height: this.singleHeight }} colSpan={this.selfUpmire ? 4 : 3}>{this.state.matchCard.match.home.displayName}</th>
										<th style={{ border: "1px solid black", padding: "1px", width: "128px", height: this.singleHeight }} >v</th>
										<th style={{ border: "1px solid black", padding: "1px", width: "380px", height: this.singleHeight }} colSpan={this.selfUpmire ? 4 : 3}>{this.state.matchCard.match.away.displayName}</th>
									</>
										:
										<th style={{ border: "none", padding: "1px", height: this.singleHeight }} colSpan={8}></th>
									}
									{!this.selfUpmire && <>
										<th style={{ border: "1px solid black", padding: "1px", width: "126px", height: this.singleHeight }} >Table #</th>
										<th style={{ border: "1px solid black", padding: "1px", width: "60px", height: this.singleHeight }} >{this.state.matchCard.match.tableNo}</th>
									</>}
								</tr>
							</tbody>
						</table>}
						<br />
					</div>}
				<table style={{ border: "1px solid black", borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
					<tbody >
						<tr>
							<th style={{ textTransform: "uppercase", border: "1px solid black", padding: "2px 1px 1px 5px", height: this.singleHeight }}>Home Team</th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", height: this.singleHeight }} colSpan={4}>{this.state.matchCard.match.home.displayName}</th>
							<th style={{ textTransform: "uppercase", border: "1px solid black", padding: "2px 1px 1px 5px", height: this.singleHeight }}>Away Team</th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", height: this.singleHeight }} colSpan={4}>{this.state.matchCard.match.away.displayName}</th>
						</tr>
						<tr style={{ border: "1px solid black" }}>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", height: this.singleHeight }}></th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", height: this.singleHeight }} colSpan={3}>FULL NAME OF PLAYER</th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 1px", height: this.singleHeight, width: "110px", wordSpacing: "100vw" }} >LICENCE #</th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", height: this.singleHeight }}></th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", height: this.singleHeight }} colSpan={3}>FULL NAME OF PLAYER</th>
							<th style={{ border: "1px solid black", padding: "1px 1px 1px 1px", height: this.singleHeight, width: "110px", wordSpacing: "100vw" }} >LICENCE #</th>
						</tr>
						{this.state.matchCard.playing.home.filter(m => m.type == 1 || m.type == 3).map(p => {
							return (<tr style={{ border: "1px solid black" }}>
								<th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight, width: "100px" }} >{this.label(p.ordering, true, this.homePlayers.length)}</th>
								<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", textAlign: "left", height: this.singleHeight, width: "360px" }} colSpan={3}>{this.playerName(p)}</th>
								<th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight, width: "110px" }} >{p.membershipNo}</th>
								<th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight, width: "100px" }} >{this.label(this.state.matchCard.playing.away[this.state.matchCard.playing.home.findIndex(i => i == p)].ordering, false, this.awayPlayers.length)}</th>
								<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", textAlign: "left", height: this.singleHeight, width: "360px" }} colSpan={3}>{this.playerName(this.state.matchCard.playing.away[this.state.matchCard.playing.home.findIndex(i => i == p)])}</th>
								<th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight, width: "110px" }} >{this.state.matchCard.playing.away[this.state.matchCard.playing.home.findIndex(i => i == p)].membershipNo}</th>
							</tr>)
						})}
						{this.state.matchCard.playing.home.filter(m => m.type == 2).map(p => {
							return (<tr style={{ border: "1px solid black" }}>
								{!this.doublesText && <th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight }} >D{this.label(p.ordering, true, this.homeDoublePlayers.length)}</th>}
								{this.doublesText && (this.state.matchCard.playing.home.filter(m => m.type == 2).findIndex(x => x == p) + 1) % 2 != 0 && <th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight }} rowSpan={2}>Doubles</th>}
								<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", textAlign: "left", height: this.singleHeight }} colSpan={3}>{this.playerName(p)}</th>
								<th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight }} >{p.membershipNo}</th>
								{!this.doublesText && <th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight }} >D{this.label(this.state.matchCard.playing.away[this.state.matchCard.playing.home.findIndex(i => i == p)].ordering, false, this.awayDoublePlayers.length)}</th>}
								{this.doublesText && (this.state.matchCard.playing.home.filter(m => m.type == 2).findIndex(x => x == p) + 1) % 2 != 0 && <th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight }} rowSpan={2}>Doubles</th>}
								<th style={{ border: "1px solid black", padding: "1px 1px 1px 5px", textAlign: "left", height: this.singleHeight }} colSpan={3}>{this.playerName(this.state.matchCard.playing.away[this.state.matchCard.playing.home.findIndex(i => i == p)])}</th>
								<th style={{ border: "1px solid black", padding: "1px", height: this.singleHeight }} >{this.state.matchCard.playing.away[this.state.matchCard.playing.home.findIndex(i => i == p)].membershipNo}</th>
							</tr>)
						})}
						<tr style={{ border: "1px solid black" }}>
							<th style={{ border: "1px solid black", padding: "1px", height: "90px", fontSize: "10pt", lineHeight: "11pt", fontWeight: 600 }} >
								Individuals authorised to be at the playing area
							</th>
							<th style={{ border: "1px solid black", padding: "0px", textAlign: "left", height: "90px" }} colSpan={3}>
								<div style={{ textAlign: "start", borderCollapse: "collapse", height: "30px" }}>
									{this.state.matchCard.match.home?.captain}
								</div>
							</th>
							<th style={{ border: "1px solid black", padding: "0px", height: "90px", backgroundColor: "#bbbbbb" }} >

							</th>
							<th style={{ border: "1px solid black", padding: "1px", height: "90px", fontSize: "10pt", lineHeight: "11pt", fontWeight: 600 }} >
								Individuals authorised to be at the playing area
							</th>
							<th style={{ border: "1px solid black", padding: "0px", textAlign: "left", height: "90px" }} colSpan={3}>
								<div style={{ textAlign: "center", borderCollapse: "collapse", height: "30px" }}>
									{this.state.matchCard.match.away?.captain}
								</div>
							</th>
							<th style={{ border: "1px solid black", padding: "0px", height: "90px", backgroundColor: "#bbbbbb" }} >
							</th>
						</tr>
					</tbody>
				</table>
				{this.showWarnings && <div>
					<div style={{ fontSize: "15pt" }}>Please insert FULL NAME & LICENCE NUMBER, where known, to ensure results are applied to the correct player.</div>
					{/* {this.state.matchCard.players.every(p => p.type != 2) ?
						<div style={{fontSize: "15pt"}} className="text-danger"><span style={{fontWeight: "bold"}}>IMPORTANT</span>: In addition to the player(s) at the table being able to call Time Out, from the team bench only the Team Captain can call a Time Out (ITTF Regulation 3.4.4.2.1) </div>
						:
						<div style={{fontSize: "15pt"}} className="text-danger"><span style={{fontWeight: "bold"}}>IMPORTANT</span>: Doubles players must be confidentially nominated by the team captain, to the referee, after match 2 and before the start of the doubles match 3. In addition to the player(s) at the table being able to call Time Out, from the team bench only the Team Captain can call a Time Out (ITTF Regulation 3.4.4.2.1) </div>
					} */}
				</div>}
				<table className="mt-4" style={{ borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
					<tbody >
						<tr style={{ border: "none" }}>
							{this.selfUpmire && <th style={{ border: "1px solid black", padding: "0px", width: "35px", writingMode: "vertical-rl" }} rowSpan={2}>Umpire</th>}
							{this.matchNumber && <th style={{ border: "1px solid black", padding: "0px", width: "35px",/*  wordSpacing: "100vw", */ writingMode: "vertical-rl" }} rowSpan={2}>Match #</th>}
							<th style={{ border: "none" }} colSpan={2}></th>
							<th style={{ border: "1px solid black", padding: "1px" }} colSpan={this.state.division?.gamesPerSet ?? this.state.competition?.gamesPerSet}>Game Score</th>
							{this.setWinner && <th style={{ border: "1px solid black", padding: "1px", width: "180px", wordSpacing: "100vw" }} rowSpan={2}>Winner's Name</th>}
							<th style={{ border: "1px solid black", padding: "1px", wordSpacing: "100vw" }} colSpan={2}>{this.printout == ScoreSheetVersion.YCJBCL ? "Individual Match Score in Games" : "Match Score"}</th>
							<th style={{ border: "1px solid black", padding: "2px" }} colSpan={2}>{this.printout == ScoreSheetVersion.YCJBCL ? "Running Team Match Score" : "Running Match Score"}</th>
						</tr>
						<tr style={{ border: "none" }}>
							<th style={{ border: "none", padding: "0px" }} colSpan={2}></th>
							{this.state.matchCard.sets[0].games.map(g => {
								return (
									<th style={{ border: "1px solid black", padding: "0px", width: "70px" }} >{this.state.matchCard.sets[0].games.findIndex(gi => gi == g) + 1}</th>
								)
							})}
							<th style={{ border: "1px solid black", padding: "0px", width: "70px" }} >H</th>
							<th style={{ border: "1px solid black", padding: "0px", width: "70px" }} >A</th>
							<th style={{ border: "1px solid black", padding: "0px", width: "70px" }} >H</th>
							<th style={{ border: "1px solid black", padding: "0px", width: "70px" }} >A</th>
						</tr>
						{this.state.matchCard.sets.map(s => {
							return (<tr style={{ border: "3px solid black" }}>
								{this.selfUpmire && <th style={{ border: "1px solid black", padding: "0px" }} >
									{this.umpires?.length > 0 && s.homePlayers.length == 1 ? this.umpire(s) : undefined}
								</th>}
								{this.matchNumber && <th style={{ border: "1px solid black", padding: "0px" }} >
									{this.state.matchCard.sets.findIndex(si => si == s) + 1}
								</th>}
								{(!this.doublesText || s.homePlayers.length == 1) && <th style={{ border: "1px solid black", padding: "0px", width: "60px"}} >
									<div style={{ padding: "1px 1px 1px 5px", borderBottom: "1px dashed black", textAlign: "center", borderCollapse: "collapse", height: s.homePlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
										<div style={{ height: this.singleHeight, fontSize: "14pt" }} >{`${s.homePlayers.length > 1 ? `D` : ``} ${this.label(this.state.matchCard.playing.home.find(i => i.id == s.homePlayers[0].playerId).ordering, true, s.homePlayers[0].type == 2 ? this.homeDoublePlayers.length : this.homePlayers.length)}`}</div>
										{s.homePlayers.length > 1 ? <div style={{ height: this.singleHeight, fontSize: "14pt" }} >{`${s.homePlayers.length > 1 ? `D` : ``} ${this.label(this.state.matchCard.playing.home.find(i => i.id == s.homePlayers[1].playerId).ordering, true, s.homePlayers[0].type == 2 ? this.homeDoublePlayers.length : this.homePlayers.length)}`}</div> : undefined}
									</div>
									<div style={{ padding: "1px 1px 1px 5px", borderTop: "1px dashed black", textAlign: "center", borderCollapse: "collapse", height: s.awayPlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
										<div style={{ height: this.singleHeight, fontSize: "14pt" }} >{`${s.awayPlayers.length > 1 ? `D` : ``} ${this.label(this.state.matchCard.playing.away.find(i => i.id == s.awayPlayers[0].playerId).ordering, false, s.awayPlayers[0].type == 2 ? this.awayDoublePlayers.length : this.awayPlayers.length)}`}</div>
										{s.awayPlayers.length > 1 ? <div style={{ height: this.singleHeight, fontSize: "14pt" }} >{`${s.awayPlayers.length > 1 ? `D` : ``} ${this.label(this.state.matchCard.playing.away.find(i => i.id == s.awayPlayers[1].playerId).ordering, false, s.awayPlayers[0].type == 2 ? this.awayDoublePlayers.length : this.awayPlayers.length)}`}</div> : undefined}
									</div>
								</th>}
								{(this.doublesText && s.homePlayers.length > 1) && <th style={{ border: "1px solid black", padding: "0px", width: "60px", writingMode: "vertical-rl", textOrientation: "upright", fontSize: "11pt" }} >
									Doubles
								</th>}
								<th style={{ border: "1px solid black", padding: "0px" }} >
									<div style={{ padding: "1px 1px 1px 5px", borderBottom: `1px ${s.homePlayers.length > 1 ? "solid" : "dashed"} black`, textAlign: "left", borderCollapse: "collapse", height: s.homePlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
										<div style={{ height: this.singleHeight, fontSize: "14pt" }} >{this.playerName(this.state.matchCard.playing.home.find(i => i.id == s.homePlayers[0].playerId))}</div>
										{s.homePlayers.length > 1 ? <div style={{ borderTop: "1px dashed black", height: this.singleHeight, fontSize: "14pt" }} >{this.playerName(this.state.matchCard.playing.home.find(i => i.id == s.homePlayers[1].playerId))}</div> : undefined}
									</div>
									<div style={{ padding: "1px 1px 1px 5px", borderTop: `1px ${s.awayPlayers.length > 1 ? "solid" : "dashed"} black`, textAlign: "left", borderCollapse: "collapse", height: s.awayPlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
										<div style={{ height: this.singleHeight, fontSize: "14pt" }} >{this.playerName(this.state.matchCard.playing.away.find(i => i.id == s.awayPlayers[0].playerId))}</div>
										{s.awayPlayers.length > 1 ? <div style={{ borderTop: "1px dashed black", height: this.singleHeight, fontSize: "14pt" }}>{this.playerName(this.state.matchCard.playing.away.find(i => i.id == s.awayPlayers[1].playerId))}</div> : undefined}
									</div>
								</th>
								{/* <th style={{ border: "1px solid black", padding: "0px" }} >
									<div style={{ border: "1px solid black", textAlign: "center", borderCollapse: "collapse", height: s.homePlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
										<div>{this.state.matchCard.playing.home.find(i => i.id == s.homePlayers[0].playerId).membershipNo}</div>
										{s.homePlayers.length > 1 ? <div>{this.state.matchCard.playing.home.find(i => i.id == s.homePlayers[1].playerId).membershipNo}</div> : undefined}
									</div>
									<div style={{ border: "1px solid black", textAlign: "center", borderCollapse: "collapse", height: s.awayPlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
										<div>{this.state.matchCard.playing.away.find(i => i.id == s.awayPlayers[0].playerId).membershipNo}</div>
										{s.awayPlayers.length > 1 ? <div>{this.state.matchCard.playing.away.find(i => i.id == s.awayPlayers[1].playerId).membershipNo}</div> : undefined}
									</div>
								</th> */}
								{s.games.map(g => {
									return (
										<th style={{ border: "1px solid black", padding: "0px" }} >
											<div style={{ borderBottom: "1px dashed black", textAlign: "center", borderCollapse: "collapse", height: s.homePlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
												{g.home}
											</div>
											<div style={{ borderTop: "1px dashed black", textAlign: "center", borderCollapse: "collapse", height: s.awayPlayers.length > 1 ? this.doubleHeight : this.singleHeight }}>
												{g.away}
											</div>
										</th>
									)
								}
								)}
								{this.setWinner && <th style={{ border: "1px solid black", padding: "0px", fontSize: "14pt" }} >
									{s.completed && s.homeScore > s.awayScore ? <>
										<div>{this.playerName(s.homePlayers[0])}</div>
										{s.homePlayers.length > 1 ? <div>{this.playerName(s.homePlayers[1])}</div> : undefined} 
									</>
									: <>
										<div>{this.playerName(s.awayPlayers[0])}</div>
										{s.awayPlayers.length > 1 ? <div>{this.playerName(s.awayPlayers[1])}</div> : undefined} 
									</>}	
								</th>}
								<th style={{ border: "1px solid black", padding: "0px" }} >{s.homeScore != null ? `${s.homeScore}` : undefined}</th>
								<th style={{ border: "1px solid black", padding: "0px" }} >{s.awayScore != null ? `${s.awayScore}` : undefined}</th>
								<th style={{ border: "1px solid black", padding: "0px" }} >{this.runningScore(s, true)}</th>
								<th style={{ border: "1px solid black", padding: "0px" }} >{this.runningScore(s, false)}</th>
							</tr>
							)
						}
						)}
					</tbody>
				</table>
				<br />
				{this.freeFooter ? <div style={{ breakInside: "avoid" }}>
					<table style={{ breakInside: "avoid", fontSize: "16pt", width: "100%" }}>
						<tbody>
							<tr style={{ border: "none" }}>
								<th style={{ border: "none", padding: "1px", width: "300px", textAlign: "left" }}>Final Match Result:</th>
								<th style={{ border: "none", padding: "1px", width: "120px" }}>
									<div className="d-flex align-items-center justify-content-between">
										<div className="d-flex align-items-center">
											<div className="d-flex flex-column justify-content-right text-right">
												<span style={{ fontWeight: 600 }}>Home Team</span>
												<span>{this.state.matchCard.match.home.displayName}</span>
											</div>
											<div className="ml-2" style={{ border: "1px solid black", padding: "1px", width: this.doubleHeight, height: this.doubleHeight }}>
												<h4 style={{marginTop: "15px"}}>{this.isReady() && this.state.matchCard.match.home.score}</h4>
											</div>
										</div>
										<div className="d-flex align-items-center">
											<div className="d-flex flex-column justify-content-right text-right">
												<span style={{ fontWeight: 600 }}>Away Team</span>
												<span>{this.state.matchCard.match.away.displayName}</span>
											</div>
											<div className="ml-2">
												<div style={{ border: "1px solid black", padding: "1px", width: this.doubleHeight, height: this.doubleHeight }}>
													<h4 style={{marginTop: "15px"}}>{this.isReady() && this.state.matchCard.match.away.score}</h4>
												</div>
											</div>
										</div>
									</div>
								</th>
							</tr>
						</tbody>
					</table>
					<br />
					<table className="mb-5" style={{ breakInside: "avoid", fontSize: "16pt", width: "100%" }}>
						<tbody>
							<tr style={{ border: "none" }} >
								<th style={{ border: "none", padding: "1px", width: "50%", height: this.singleHeight, textAlign: "left" }}>Signed and checked by Referee</th>
								<th style={{ border: "none", padding: "1px", width: "50%", height: this.singleHeight, textAlign: "left" }}>
									{this.umpireSignature && <div className="d-flex justify-content-end">
										<div className="d-flex align-items-center">
											<span>Umpire</span>
											<div className="ml-2" style={{ border: "1px solid black", padding: "1px", width: "300px", height: this.singleHeight }}>

											</div>
										</div>
									</div>}
								</th>
							</tr>
						</tbody>
					</table>
					<table style={{ breakInside: "avoid", fontSize: "16pt", width: "100%" }}>
						<tbody>
							<tr style={{ border: "none" }} >
								<th style={{ border: "none", padding: "1px", width: "50%", height: this.singleHeight, textAlign: "left" }}>Signed: Home Team Captain</th>
								<th style={{ border: "none", padding: "1px", width: "50%", height: this.singleHeight, textAlign: "left" }}>Signed: Away Team Captain</th>
							</tr>
						</tbody>
					</table>
				</div>
					:
					<table style={{ breakInside: "avoid", borderCollapse: "collapse", fontSize: "16pt", width: "100%" }}>
						<tbody >
							<tr style={{ border: "none" }}>
								<th style={{ border: "none" }} colSpan={10}>Final Match Result</th>
							</tr>
							<tr style={{ border: "none" }} >
								<th style={{ border: "none", padding: "0px", width: "120px" }} ><div style={{ borderTop: "1px solid black", borderLeft: "1px solid black", padding: "1px" }}>Home Team</div></th>
								<th style={{ border: "1px solid black", padding: "1px", width: "360px" }} colSpan={3} >{this.state.matchCard.match.home.displayName}</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "120px" }} >{this.isReady() && this.state.matchCard.match.home.score}</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "120px" }} >Away Team</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "360px" }} colSpan={3} >{this.state.matchCard.match.away.displayName}</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "120px" }} >{this.isReady() && this.state.matchCard.match.away.score}</th>
							</tr>
							<tr style={{ border: "none" }} >
								<th style={{ border: "1px solid black", padding: "1px", width: "240px", height: this.singleHeight }} colSpan={2} >Home Captains' Signature</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "360px", height: this.singleHeight }} colSpan={3} ></th>
								<th style={{ border: "1px solid black", padding: "1px", width: "240px", height: this.singleHeight }} colSpan={2} >Away Captains' Signature</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "360px", height: this.singleHeight }} colSpan={3} ></th>
							</tr>
							<tr style={{ border: "none" }} >
								<th style={{ border: "1px solid black", padding: "1px", width: "240px", height: this.singleHeight }} colSpan={2} >Referees' Signature</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "360px", height: this.singleHeight }} colSpan={3} ></th>
								<th style={{ border: "1px solid black", padding: "1px", width: "240px", height: this.singleHeight }} colSpan={2} >Umpires' Signature</th>
								<th style={{ border: "1px solid black", padding: "1px", width: "360px", height: this.singleHeight }} colSpan={3} ></th>
							</tr>
						</tbody>
					</table>}
				{this.sblInfo ? <div style={{marginTop: "15px"}}>
						{/* <div style={{fontStyle: "italic", fontSize: "14pt"}} className="text-warning">
							Please email a copy of this score sheet to SBL@tabletennisengland.co.uk or alternatively send it to Henry Arthur, C/O Table Tennis England, Bradwell Road, Loughton Lodge, Milton Keynes, Buckinghamshire MK8 9LA.   
						</div> */}
						<div style={{ fontSize: "12pt", fontWeight: "bold" }}>
							Table Tennis England state that Double Fish V40+ 3* plastic ball (white) must be used for ALL {this.printout == ScoreSheetVersion.YCJBCL || this.printout == ScoreSheetVersion.YBGBCL ? "Youth" : "Senior"} British Clubs League matches.
						</div>
					</div>
					:
					this.printout != ScoreSheetVersion.Default && <div style={{marginTop: "15px"}}>
						<div style={{ fontSize: "12pt", fontWeight: "bold" }}>
							Table Tennis England state that the JOOLA Flash 3* plastic ball (white) must be used for ALL {this.printout == ScoreSheetVersion.WBCL ? "Women's" : /* this.printout == ScoreSheetVersion.VBCL ? */ "Veterans"} British Clubs League matches.
						</div>
					</div>
				}
				{this.comments && <table style={{ breakInside: "avoid", borderCollapse: "collapse", fontSize: "16pt", width: "1200px" }}>
					<tbody >
						<tr style={{ border: "none" }} >
							<th style={{ border: "1px solid black", padding: "1px", width: "360px", height: this.singleHeight }} >Additional Comments</th>
							<th style={{ border: "1px solid black", padding: "1px", width: "840px", height: this.singleHeight }} ></th>
						</tr>
					</tbody>
				</table>}
			</div>
				: <div>No Data</div>
		)
	}
}