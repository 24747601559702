import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardBody, CardHeader, Row, Col, FormGroup, Label, Input, Form, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import { CostFeedback, Entry, EntryCategory, EntryCosts, EntryPlayer, EntryStatus, EntryTeam } from "@models/entry";
import { PagedList } from "@models/paging";
import { EntryService } from "@services/entry.service";
import { RegistrationService } from "@services/registration.service";
import EntryTeams from "./components/EntryTeams";
import { Registration, RegistrationCategory } from "@models/registration";

import RegisterTeamSidebar from "@components/sidebar/public/RegisterTeamSidebar";
import RegisterPlayerSidebar from "@components/sidebar/public/RegisterPlayerSidebar";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import Header from "./components/Header";
import LocaleHelper from "@helpers/locale.helper";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Invoice, InvoiceStatus } from "@models/invoice";
import { ProgressButton } from "@components/controls/ProgressButton";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

const StripeContainer = (props) => {
	const [stripePromise] = useState(() => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId }));

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm entryId={props.entryId} categories={props.categories} contact={props.contact} onSubmit={props.onSubmit} onComplete={props.onComplete} onError={props.onError} submitting={props.submitting} type={props.type} balance={props.balance} />
		</Elements>
	);
};

interface ICheckoutFormProps {
	entryId: number;
	categories: number[];
	contact: Contact;
	onSubmit?: () => void;
	onComplete?: () => void;
	onError?: () => void;
	submitting: boolean;
	type?: number;
	balance?: number;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const stripe = useStripe();
	const elements = useElements();
	const [invoice, setInvoice] = useState<Invoice>();

	const handleBookAndPayClick = () => async (event: any) => {
		event.preventDefault();

		try {
			validate();

			props.onSubmit();
			if(props.balance > 0){
				const { error, paymentMethod } = await stripe.createPaymentMethod({
					type: "card",
					card: elements.getElement(CardElement)
				});

				if (paymentMethod) {
					try {
						const invoice = await EntryService.pay(props.entryId, props.categories, paymentMethod.id);
						if (invoice.status === InvoiceStatus.Failed) {
							await toast.error("Payment failed. Please retry");
							History.push(`/pay/${invoice.reference}`);
						}
						else
							History.push(`/member/entries/${props.entryId}/pay/confirmation/${invoice.reference}`);
					} catch (error) {
						toast.error(error?.message ?? "Something went wrong");
						props.onError();
					}

					props.onComplete();
				} else {
					toast.error("Payment details missing");
					props.onError();
				}
			}
			else {
				try {
					const invoice = await EntryService.pay(props.entryId, props.categories, null);
					if (invoice.status === InvoiceStatus.Failed) {
						await toast.error("Payment failed. Please retry");
						History.push(`/pay/${invoice.reference}`);
					}
					else
						History.push(`/member/entries/${props.entryId}/pay/confirmation/${invoice.reference}`);
				} catch (error) {
					toast.error(error?.message ?? "Something went wrong");
					props.onError();
				}

				props.onComplete();
			}
		} catch (error) {
			toast.error(error?.message);
			props.onError();
		}
	};

	const handleInvoiceClick = () => async (event: any) => {
		event.preventDefault();
		props.onSubmit();

		try {
			const invoice = await EntryService.invoice(props.entryId, props.categories);
			await toast.info("Items Invoiced.");
			setInvoice(invoice);
		} catch (error) {
			toast.error(error?.message ?? "Something went wrong");
			props.onError();
		}

		props.onComplete();
	};

	const handleClose = () => {
		setInvoice(null);
		History.push(`/member/entries/${props.entryId}/payments`);
	}

	const validate = () => {
		return true;
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<div className="stripe">
			{props.type == 1 && props.balance != 0 && <div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>}
			<Row>
				<Col>
					{props.type == 2 ? <ProgressButton onClick={handleInvoiceClick()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
						Invoice
					</ProgressButton>
					:
					<ProgressButton onClick={handleBookAndPayClick()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
						Book and Pay Now
					</ProgressButton>}
				</Col>
			</Row>
			<Modal className="modal--default" isOpen={invoice} toggle={() => handleClose()}>
                <ModalHeader toggle={() => handleClose()}>Items Invoiced</ModalHeader>
                <ModalBody>
                    <Col>
						<Row>
							<p>These items have been invoiced. Please contact registration admins and provide the following invoice reference to complete payment. This can also be accessed via your entry's payment invoices page.</p>
						</Row>
						<Row>
							<h6>Invoice Value: {invoice?.total}</h6>
						</Row>
						<Row>
							<h6>Invoice Ref# {invoice?.reference}</h6>
						</Row>
					</Col>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-md btn-secondary btn-rounded" onClick={() => handleClose()}>Close</button>
                </ModalFooter>
            </Modal>
		</div>
	);
};

interface ISessionJoinProps {
	site?: string;
	match?: any;

}

interface IEntryCategoriesPageProps {
	site?: string;
	match?: any;
	location?: any;
	type?: number;
}

export default (props: IEntryCategoriesPageProps) => {
	const [fetching, setFetching] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const [entryId, setEntryId] = useState<number>(props.match.params.entryId);
	const [entry, setEntry] = useState<Entry>();

	const [costs, setCosts] = useState<EntryCosts>();

	const [registration, setRegistration] = useState<Registration>();

	const [activeTab, setActiveTab] = useState<string>("Pending");

	const [selected, setSelected] = useState<number[]>([]);

	const [payAll, setPayAll] = useState<boolean>(false);

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		if (payAll && costs) {
			setSelected(costs.pending.map(c => c.categoryId));
		}
	}, [payAll, costs]);

	const load = async () => {
		const entry = await EntryService.getById(entryId);
		setEntry(entry);

		const registration = await RegistrationService.getById(entry.registrationId);
		setRegistration(registration);

		const costs = await EntryService.costFeedback(entryId);
		setCosts(costs);

		if (props.match.params.categoryId) {
			const _selected = [];
			_selected.push(parseInt(props.match.params.categoryId, 0));
			setSelected(_selected);
		} else {
			setPayAll(true);
		}

		setLoading(false);
	};

	const groupBy = (array, f) => {
		var groups = {};
		array.forEach(function (o) {
			var group = JSON.stringify(f(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});
		return Object.keys(groups).map(function (group) {
			return groups[group];
		})
	};

	const handleCategoryClick = (cost: CostFeedback) => (e: any) => {
		let _selected = [...selected];

		if (_selected.find(s => s === cost.categoryId)) {
			_selected = _selected.filter(s => s !== cost.categoryId);
		} else {
			_selected.push(cost.categoryId);
		}

		setSelected(_selected);
	};

	const handlePayAllClick = () => (e) => {
		if (payAll) {
			setPayAll(false);
		} else {
			setPayAll(true);
		}
	};

	const getFee = () => {
		const total = getTotal();
		const fee = ((total + 0.20) / (1 - 0.014)) - total;
		return fee;
	};

	const getTotal = (): number => {
		const totals = costs.pending.filter(p => selected.indexOf(p.categoryId) >= 0).map(c => c.total);

		if (totals.length > 0) {
			return totals.reduce((a, b) => (a + b)) - getRelevantBalance();
		}

		return 0;
	};

	const getRelevantBalance = () => {
		var types = [];
		if(!payAll){
			types = entry.categories.filter(c => selected.some(s => s == c.id)).map(c => c.category.competitionTypeId);
		}
		else{
			var categories = costs.pending.map(c => c.categoryId)
			types = entry.categories.filter(c => categories.some(s => s == c.id)).map(c => c.category.competitionTypeId);
		}
		
		if (types.length > 0) {
			var balanceFeedback = costs.balanceBreakdown.filter(b => types.some(s => s == b.competitionTypeId) || b.competitionTypeId == null);

			return balanceFeedback.reduce((a, b) => {return a + b.total}, 0);
		}
	};

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!loading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						<div className="page_content_wrap">
							<div className="container">
								<div className="header_content_wrap">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">Payments</h1>
												<p className="header--description">{entry.club.name}</p>
											</div>
										</div>
									</div>
								</div>
								<div className="membership mg-t-20">
									{
										(!fetching) &&
										<Row>
											<Col md={{ size: 6, offset: 3 }}>
												<Form>
													{
														!payAll && groupBy(costs.pending, (c) => c.categoryId).map((cg, index) => (
															<>
																{
																	cg.map((cost: CostFeedback) => {
																		return <>
																			<Card className={`mb-3 option clickable ${selected.find(x => x === cost.categoryId) && "selected"}`} onClick={handleCategoryClick(cost)}>
																				<CardBody>
																					<Row>
																						<Col className="my-auto">
																							<h5 className="m-0">{cost.category.category.name}</h5>
																						</Col>
																						<Col className="my-auto text-center">
																							<h6 className="m-0">{cost.teams}</h6>
																							<span className="small">Teams</span>
																						</Col>
																						<Col className="my-auto text-center">
																							<h6 className="m-0">{cost.players}</h6>
																							<span className="small">Players</span>
																						</Col>
																						<Col className="my-auto text-right">
																							<h5 className="m-0">{LocaleHelper.toPrice(cost.total)}</h5>
																							<span className="small">Total</span>
																						</Col>
																					</Row>
																				</CardBody>
																			</Card>
																		</>;
																	})
																}
															</>
														))
													}
													{
														payAll &&
														<ProgressButton className="btn btn-primary btn-rounded w-100" onClick={() => setPayAll(false)}>
															Choose Categories
														</ProgressButton>
													}
													<Row className="mt-4 mb-3">
														<Col>
															<Card className={`option text-center p-3 clickable ${payAll && "selected"}`} onClick={handlePayAllClick()}>
																<Row>
																	<Col className="my-auto text-left">
																		<h5 className="m-0">Pay All</h5>
																		<span className="small">{costs.pending.length} categories</span>
																	</Col>
																	<Col className="my-auto text-center">
																		<h6 className="m-0">{costs.pending.reduce((a, b) => a + b.teams, 0)}</h6>
																		<span className="small">Teams</span>
																	</Col>
																	<Col className="my-auto text-center">
																		<h6 className="m-0">{costs.pending.reduce((a, b) => a + b.players, 0)}</h6>
																		<span className="small">Players</span>
																	</Col>
																	<Col className="my-auto text-right">
																		<h3 className="membership-price m-0 text-center">
																			{LocaleHelper.toPrice(costs.pending.reduce((a, b) => a + b.total, 0))}
																		</h3>
																	</Col>
																</Row>
															</Card>
														</Col>
													</Row>
													<Row>
														<Col className="text-center">
															<span>Total</span>
															<h2 className="price mb-0">{LocaleHelper.toPrice(getTotal())}</h2>
															{/* <p className="fee mb-0">{LocaleHelper.toPrice(getTotal())} + {LocaleHelper.toPrice(getFee())} fee <span id="fee"><i className="far fa-info-circle" /></span></p>
															<UncontrolledTooltip placement="top" target={`fee`} delay={{ show: 400, hide: 100 }}>
																<span>This is the card payment transaction fee</span>
															</UncontrolledTooltip> */}
															{
																/* (costs.balance > 0 || costs.balance < 0) &&
																<span className="font-italic">* Your current club balance of {LocaleHelper.toPrice(costs.balance)} has been applied to this payment</span> */
															}
															{
																(getRelevantBalance() > 0 || getRelevantBalance() < 0) &&
																<span className="font-italic">* Your current relevant club balance of {LocaleHelper.toPrice(getRelevantBalance())} has been applied to this payment for the current club balance of {LocaleHelper.toPrice(costs.balance)}</span>
															}
														</Col>
													</Row>
													{(selected?.length > 0 || payAll) && <Row>
														<Col>
															<StripeContainer
																entryId={entryId}
																categories={selected}
																onSubmit={e => setSubmitting(true)}
																onComplete={e => setSubmitting(false)}
																onError={e => setSubmitting(false)}
																submitting={submitting}
																type={props.type}
																balance={getTotal()} />
														</Col>
													</Row>}
													<Row className="mt-4 text-center">
														<Col>
															<img title="Powered by Stripe" width={"200px"} src={require("../../../../../assets/images/misc/stripe-plurple.png")} />
														</Col>
													</Row>
												</Form>
											</Col>
										</Row>
									}
									{fetching && <ActivitySpinner height={"50"} />}
								</div>
							</div>
						</div>
					</div>)}
			/>
		</PublicLayout>
	);
};