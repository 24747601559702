import RestHelper from "@helpers/rest.helper";

import { RankingMemberIdentity } from "@models/member";
import { BaseService } from "./base.service";

export class MemberService extends BaseService {

	public static getByMembershipNumber(num:number): Promise<RankingMemberIdentity> {
		return new Promise((resolve, reject) => {
			RestHelper.get(this.base(`members/${num}`)).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error?.response?.data ?? "There was a problem checking the membership number");
				});
		});
	}
}