import { AppConfig } from "../config";

import { User } from "../models/user";
import { TokenApiModel } from "../models/identity";

import { MemberRegistration, Member, MemberSetup, MemberAccess, MemberDetails, Platform, CreateMember, SearchMember, MemberIdentity, MemberRoles } from "../models/member";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { SelectOption } from "@models/forms";

import browser from "browser-detect";

import RestHelper from "@helpers/rest.helper";
import { TeamCaptain } from "@models/team";
import { BookingSlotFilterOptions, FilterOptions, InvoiceFilterOptions, MembershipUserFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

import * as qs from "query-string";
import { Contact } from "@models/contact";
import { AssignMembership, Membership, MembershipUser } from "@models/membership";
import { CreateInvoice, Invoice, TransactionType } from "@models/invoice";
import { Basket, BasketItem } from "@models/basket";

export class InvoiceService {

	constructor() {
		//
	}

	public static get(query: InvoiceFilterOptions): Promise<PagedList<Invoice>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`invoices`, {
				params: query,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getByReference(reference: string): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`invoices/${reference}`)
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error.response.data);
			});
		});
	}

	public static getFailedBySessionContact(sessionId: number, contactId: number): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`invoices/session/${sessionId}/contact/${contactId}`)
			.then(result => {
				resolve(result.data === "" ? null : result.data);
			}).catch(error => {
				reject(error.response.data);
			});
		});
	}

	public static getFailedByEventContact(eventId: number, contactId: number): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`invoices/event/${eventId}/contact/${contactId}`)
			.then(result => {
				resolve(result.data === "" ? null : result.data);
			}).catch(error => {
				reject(error.response.data);
			});
		});
	}

	public static getFailedByBookingSlotInfo(query: BookingSlotFilterOptions): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`invoices/booking`,
				{
					params: query,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data === "" ? null : result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getFailedByMembershipContact(membershipId: number, contactId: number): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`invoices/membership/${membershipId}/contact/${contactId}`)
			.then(result => {
				resolve(result.data === "" ? null : result.data);
			}).catch(error => {
				reject(error.response.data);
			});
		});
	}

	public static pay(reference: string, transactionType: TransactionType, value?: number , paymentMethodId?: string): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/pay`, {reference, transactionType, value, paymentMethodId})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static close(reference: string): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/close`, {reference})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static payBasket(basket: Basket): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/pay/basket`, basket)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static cancelBasketItem(basket: BasketItem): Promise<boolean> {
        return new Promise((resolve, reject) => {
            RestHelper
                .post(`invoices/basket/item/cancel`, basket)
                .then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error.response.data);
                });
        });
    }

	public static notes(reference: string, notes: string): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/${reference}/notes`, {notes})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response?.data);
				});
		});
	}

	public static void(reference: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/${reference}/void`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static voidPayment(reference: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/${reference}/void/payment`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static refundPayment(reference: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/${reference}/refund/payment`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static secret(reference: string): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`invoices/secret/${reference}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static export(reference: number, start: string, end: string): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`export/invoices/${reference}/${start}/${end}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static requestPayment(reference: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/${reference}/payment/request`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsert(invoice: Invoice): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices`, invoice)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static create(invoice: CreateInvoice): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`invoices/create`, invoice)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static receipt(reference: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`invoices/${reference}/receipt`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static voidEntry(invoiceId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`maintenance/invoices/${invoiceId}/void/entry`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static correctEntryInvoice(invoiceId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`maintenance/invoices/${invoiceId}/correct/entry`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static correctBalanceEntryInvoice(invoiceId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`maintenance/invoices/${invoiceId}/correct/entry/balance`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}
}
