import * as React from "react";
import { Link } from "react-router-dom";
import { AppConfig } from "@config";
import * as moment from "moment";
import { Row, Col, Container, Form, Card } from "reactstrap";
import { ArticleService } from "@services/article.service";
import { DivisionService } from "@services/division.service";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article, NewsItem } from "@models/article";
import { Website, WebsiteContent, WebsiteContentType, WebsiteImage, WebsiteImageType, WebsiteVisibility } from "@models/website";
import { AppCache } from "@cache";
import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { StandingGroup } from "@models/standing";
import { Sponsor } from "@models/sponsor";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { AverageGroup } from "@models/average";
import { FacebookProvider, Page, Group } from "react-facebook";
import { TenantType } from "@models/tenant";
import { Can } from "@components/controls/Authentication";
import { MatchGroup, MatchGrouping, MatchGroupType, Match } from "@models/match";
import { MatchService } from "@services/match.service";
import History from "@helpers/history.helper";
import { WebsiteService } from "@services/website.service";
import PagePreview from "@components/maker/PagePreview";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { PagedList } from "@models/paging";
import HeaderSlider from "@components/controls/website/HeaderSlider";

interface IHomePageProps {
	site?: string;
}

interface IHomePageState {
	loading: boolean;
	nextPathname?: any;
	leagues: League[];
	teams: Team[];
	clubs: Club[];
	news?: PagedList<Article>;
	standings: StandingGroup[];
	results: number[];
	averages: AverageGroup[];
	sponsors: Sponsor[];
	matches?: MatchGrouping;
	newsFeed: NewsItem[];
	content?: WebsiteContent;
	search?: string;
}
export class HomePage extends React.Component<IHomePageProps, IHomePageState> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			leagues: [],
			teams: [],
			clubs: [],
			results: [],
			averages: [],
			sponsors: [],
			standings: [],
			newsFeed: []
		};
	}

	async componentDidMount() {

		console.log('AppCache.website :>> ', AppCache.website);

		if (AppCache.website.config.visibility === WebsiteVisibility.Unavailable) {
			if (AppCache.tenant.leagueId || AppCache.tenant.countyId) {
				setTimeout(() => window.location.href = "https://www.ttleagues.com/", 3000);
			} else if (AppCache.tenant.clubId) {
				setTimeout(() => window.location.href = "https://www.ttclubs.co.uk/", 3000);
			}
		} else if (AppCache.website.config.visibility === WebsiteVisibility.Redirect) {
			setTimeout(() => window.location.href = AppCache.website.config.redirectUrl, 3000);
		}
		else {
			DivisionService.getGroupStandings().then(standings => {
				this.setState({ standings });
			});

			DivisionService.getGroupAverages().then(averages => {
				this.setState({ averages });
			});

			if (AppCache.county == null && AppCache.website.config.resultsCarousel) {
				MatchService.latest(10, MatchGroupType.Results).then(result => {
					this.setState({ matches: result });
				});
			}

			WebsiteService.updateActivity("Home");

			ArticleService.get({ page: 1, count: AppCache.website.config.maxArticles ?? 3, hidden: false }).then(news => {
				this.setState({ news });
			});

			ArticleService.GetFeed().then(result => {
				this.setState({ newsFeed: result });
			});

			WebsiteService.getContent(WebsiteContentType.Homepage).then(content => {
				this.setState({ content });
			});
		}
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	search = () => (e: any) => {
		History.push(`/search/all${this.state.search !== undefined ? `?term=${this.state.search}` : ""}`);
	}

	public render() {
		const header: WebsiteImage[] = AppCache.website.images.filter(i => i.type === WebsiteImageType.Header);
		const newsImage: WebsiteImage = AppCache.website.images.find(i => i.type === WebsiteImageType.News);

		return (
			<>
				{
					AppCache.website.config.visibility !== WebsiteVisibility.Published ?
						<div className="d-flex h-100 flex-column justify-content-center align-items-center">
							<div className="text-center">
								{
									AppCache.tenant.clubId &&
									<img width={250} src={require("../../../../assets/images/brand/ttclubs-logo-white.png")} />
								}
								{
									(AppCache.tenant.leagueId || AppCache.tenant.countyId) &&
									<img width={250} src={require("../../../../assets/images/brand/ttleagues-logo-white.png")} />
								}
								{AppCache.website.config.visibility === WebsiteVisibility.ComingSoon && <p>Coming Soon</p>}
								{AppCache.website.config.visibility === WebsiteVisibility.Redirect && <p>Redirecting</p>}
								{
									AppCache.website.config.visibility === WebsiteVisibility.Unavailable &&
									<>
										{
											AppCache.tenant.clubId && <p onLoad={() => window.location.href = "https://www.ttclubs.co.uk/"}>Redirecting</p>
										}
										{
											(AppCache.tenant.leagueId || AppCache.tenant.countyId) && <p onLoad={() => window.location.href = "https://www.ttleagues.com/"}>Redirecting</p>
										}
									</>
								}
							</div>
						</div> :
						<PublicLayout theme={`scheme_alter ${AppCache.tenant.leagueId ? "scheme-dark" : "scheme-light"}`} className="remove_margins">
							<div className="page_content_wrap">
								{
									AppCache.website.config.homeHeader &&
									<div className="header elementor elementor-598">
										{
											header.length === 0 ?
												<section className="elementor-element elementor-element-99e1259 elementor-section-stretched elementor-section-content-bottom scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
													<div className="container">
														<div className="elementor-row">
															<div className="elementor-element elementor-element-390f28f sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-50 elementor-top-column animated fadeInLeft" data-id="390f28f" data-element_type="column" data-settings='{"animation":"fadeInLeft","animation_delay":300}'>
																<div className="elementor-column-wrap  elementor-element-populated">
																	<div className="elementor-widget-wrap">
																		<div className="elementor-element elementor-element-49158eb sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="49158eb" data-element_type="widget" data-widget_type="spacer.default">
																			<div className="elementor-widget-container">
																				<div className="elementor-spacer">
																					<div className="elementor-spacer-inner" />
																				</div>
																			</div>
																		</div>
																		<div className="elementor-element elementor-element-41a5bd6 sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="41a5bd6" data-element_type="widget" data-widget_type="spacer.default">
																			<div className="elementor-widget-container">
																				<div className="elementor-spacer">
																					<div className="elementor-spacer-inner" />
																				</div>
																			</div>
																		</div>
																		<div className="elementor-element elementor-element-fc40494 med-title sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-id="fc40494" data-element_type="widget" data-widget_type="trx_sc_title.default">
																			<div className="elementor-widget-container">
																				<div id="trx_sc_title_546087442" className="sc_title sc_title_default">
																					<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
																						<span className="sc_item_title_text" style={{ minHeight: "72px", display: "inline-block" }}>
																							<Can tenants={[TenantType.League]} render={() => <span> {AppCache.league.name}</span>} />
																							<Can tenants={[TenantType.Club]} render={() => <span> {AppCache.club.name}</span>} />
																							<Can tenants={[TenantType.County]} render={() => <span> {AppCache.county.name}</span>} />
																						</span>
																					</h1>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section> :
												<HeaderSlider images={header} />
										}
									</div>
								}
								{
									AppCache.tenant.isDefault &&
									<div className="header d-flex global-search">
										<section className="scheme_dark justify-content-center align-self-center">
											<Container>
												<Row>
													<Col xs={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
														<Form onSubmit={this.search()}>
															<p className="title">
																<span>
																	Search for your league, club or county TT Leagues website
																</span>
															</p>
															<p>
																<input type="text" className="w-100" placeholder="Enter search terms" onChange={e => this.setState({ search: e.currentTarget.value })} />
															</p>
															<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																<a onClick={this.search()} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left mr-3">
																	<span className="sc_button_text">
																		<span className="sc_button_title">
																			Search
																		</span>
																	</span>
																</a>
																<Link to={"/search/all"} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																	<span className="sc_button_text">
																		<span className="sc_button_title">
																			View All
																		</span>
																	</span>
																</Link>
															</div>
														</Form>
													</Col>
												</Row>
											</Container>
										</section>
									</div>
								}
								{
									this.state.content && this.state.content.live ? <PagePreview content={JSON.parse(this.state.content.live)} />
								:
								<Can tenants={[TenantType.League, TenantType.County]}>
									<div className="container">
										<Can tenants={[TenantType.League]}>
											<div className="row">
												<div className="col-12">
													{
														this.state.matches && this.state.matches.matches.length >= 3 ?
															<section className="mt-4 mb-4 latest-results">
																<OwlCarousel options={{ autoplay: true, loop: true, responsive: { 992: { items: 3 }, 768: { items: 2 }, 0: { items: 1 } } }}>
																	{
																		this.state.matches.matches.map(m => {
																			return (
																				<div key={`latest_${m.id}`} className="card m-2 p-2 clickable">
																					<div className="row">
																						<div className="col-12 text-center">
																							<span className="date mt-2">{m.name}</span>
																							<span className="date d-block">{moment(m.date).format("dddd Do MMMM")}</span>
																						</div>
																					</div>
																					<div className="row">
																						<div className="col-6 text-center wrap-text"><span className="team-name">{m.home.displayName}</span></div>
																						<div className="col-6 text-center wrap-text"><span className="team-name">{m.away.displayName}</span></div>
																					</div>
																					<div className="row">
																						<div className="col-6 text-center"><span className="score">{m.home.score}</span></div>
																						<div className="col-6 text-center"><span className="score">{m.away.score}</span></div>
																					</div>
																					<div className="row">
																						<div className="col-12 text-center"><Link className="tx-12" to={`/league/${m.competitionId}/division/${m.divisionId}/match/${m.id}`}>Match Card</Link></div>
																					</div>
																				</div>
																			);
																		})
																	}
																</OwlCarousel>
															</section> : undefined
													}
												</div>
											</div>
										</Can>
										<div className="row mt-5">
											<div className="col-lg-8 col-md-12 mb-4">
												<div className="content">
													<section className="post_item_single post_type_page post-598 page type-page status-publish hentry">
														<div className="entry-content">
															<div className="elementor elementor-598">
																<div className="elementor-inner">
																	<div className="elementor-section-wrap">
																		<section className="">
																			<div className="elementor-column-gap-extended">
																				<div className="">
																					<div className="">
																						<div className="elementor-column-wrap elementor-element-populated">
																							<div className="elementor-widget-wrap">
																								<div className="elementor-element elementor-element-be3d94e sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-id="be3d94e" data-element_type="widget" data-widget_type="trx_sc_title.default">
																									<div className="elementor-widget-container">
																										<div className="sc_title sc_title_accent">
																											<h2 className="sc_item_title sc_title_title sc_item_title_style_accent">
																												<span className="sc_item_title_text">
																													Local News
																												</span>
																											</h2>
																										</div>
																									</div>
																								</div>
																								<div className="elementor-element elementor-element-c793c8d sc_fly_static elementor-widget elementor-widget-trx_sc_recent_news mb-4">
																									<div className="elementor-widget-container">
																										<div id="trx_sc_recent_news_783420989" className="sc_recent_news sc_recent_news_style_news-excerpt sc_recent_news_with_accented">
																											{this.state.news && this.state.news.total === 0 ? <p className="m-0">No news available</p> : undefined}
																											{this.state.news && this.state.news.items.map(n => {
																												return (
																													<article className="post_item post_layout_news-excerpt post_format_standard post-240 post type-post status-publish format-standard has-post-thumbnail hentry category-training tag-articles tag-blog tag-match tag-soccer tag-topic">
																														<div className="post_featured with_thumb hover_simple">
																															<img width={260} height={240} src={n.previewId ? `${AppConfig.baseUrl}image/${n.previewId}` : newsImage ? `${AppConfig.baseUrl}image/${newsImage.imageId}` : require("@assets/images/placeholders/tte-square.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
																															<div className="mask" />
																															<a href={`/article/${n.slug}`} aria-hidden="true" className="icons" />
																														</div>
																														<div className="post_body">
																															<div className="post_header entry-header">
																																<div className="post_meta">
																																	{
																																		n.category ?
																																			<span className="post_meta_item post_categories">
																																				<Link to={`/articles/category/${n.category}`}>{n.category}</Link>
																																			</span> : undefined
																																	}
																																	<span className="post_meta_item post_date">
																																		{moment(n.published).format("Do MMMM")}
																																	</span>
																																</div>
																																<h4 className="post_title entry-title">
																																	<Link
																																		to={`/article/${n.slug}`}
																																		rel="bookmark">
																																		{n.title}
																																	</Link>
																																</h4>
																															</div>
																															<div className="post_content entry-content">
																																<p>
																																	{n.summary}
																																</p>
																															</div>
																														</div>
																													</article>
																												);
																											})}
																										</div>
																									</div>
																									{
																										this.state.news && this.state.news.items.length < 1 ? undefined :
																											<div className="sp-view-all-link">
																												<Link className="bg-secondary" to={"/articles"}>View all</Link>
																											</div>
																									}
																								</div>
																								<div className="elementor-element elementor-element-fec56ce sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-id="fec56ce" data-element_type="widget" data-widget_type="trx_sc_title.default">
																									<div className="elementor-widget-container">
																										<div id="trx_sc_title_1288911470" className="sc_title sc_title_accent">
																											<h2 className="sc_item_title sc_title_title sc_item_title_style_accent">
																												<span className="sc_item_title_text">
																													National News
																												</span>
																											</h2>
																										</div>
																									</div>
																								</div>
																								<div className="elementor-element elementor-element-b71eb6c scheme_default sc_fly_static elementor-widget elementor-widget-trx_sc_blogger mg-b-5" data-id="b71eb6c" data-element_type="widget" data-widget_type="trx_sc_blogger.default">
																									<div className="elementor-widget-container">
																										<div className="">
																											<div className="feed">
																												{
																													this.state.newsFeed.length > 0 ?
																														<OwlCarousel
																															lazyLoad={true}
																															options={{ autoHeight: true, loop: false, dots: false, responsive: { 0: { items: 1 }, 992: { items: 2 } } }}>
																															{this.state.newsFeed.map((f, i) => {
																																return (
																																	<div className="feed--item mr-4">
																																		<div className="feed--image" style={{ backgroundImage: `url(${f.imageUri})` }}>
																																			<img src={f.imageUri} className="" />{" "}
																																			<div className="mask" />
																																			<a href={f.uri} target={"_blank"} className="icons" />
																																		</div>
																																		<div>
																																			<div className="post_meta sc_blogger_item_meta post_meta">
																																				<span className="post_meta_item post_date">
																																					{moment(f.publishDate).fromNow()}
																																				</span>
																																			</div>
																																			<h5 data-item-number={1}>
																																				<a href={f.uri} target="_blank">{f.title}</a>
																																			</h5>
																																			<div>
																																				{ReactHtmlParser(f.summary)}
																																			</div>
																																		</div>
																																	</div>);
																															})}
																														</OwlCarousel> : undefined
																												}
																											</div>
																										</div>
																									</div>
																								</div>
																								<div className="elementor-element elementor-element-2b5b5cf sc_fly_static elementor-widget elementor-widget-spacer" data-id="2b5b5cf" data-element_type="widget" data-widget_type="spacer.default">
																									<div className="elementor-widget-container">
																										<div className="elementor-spacer">
																											<div className="elementor-spacer-inner" />
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</section>
																	</div>
																</div>
															</div>
														</div>
													</section>
													<div className="sc_layouts_widgets d-none d-md-block">
														<div className="row">
															<div className="col-12 col-md-6">
																{
																	AppCache.website.twitter ?
																		<aside id="text-4" className="widget widget_text mb-4">
																			<h5 className="widget_title">
																				<i className="fab fa-x-twitter"></i>
																			</h5>
																			<div>
																				<TwitterTimelineEmbed
																					sourceType="profile"
																					screenName={`${AppCache.website.twitter}`}
																					options={{ height: 400 }}
																				/>
																			</div>
																		</aside> : undefined
																}
															</div>
															<div className="col-12 col-md-6">
																{
																	AppCache.website.facebook ?
																		<aside id="text-4" className="widget widget_text p-20 mb-4">
																			<h5 className="widget_title">
																				facebook
																			</h5>
																			<div>
																				<FacebookProvider appId="412401072952926">
																					{AppCache.website.facebook.indexOf("groups") >= 0 && <Group href={`https://www.facebook.com/${AppCache.website.facebook}/`} lazy />}
																					{AppCache.website.facebook.indexOf("page") < 0 && <Page href={`https://www.facebook.com/${AppCache.website.facebook}/`} lazy />}
																				</FacebookProvider>
																			</div>
																		</aside> : undefined
																}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-4 col-md-12 right mb-4">
												<div className="sidebar widget_area sidebar_below">
													<div className="sidebar_inner">
														<div className="elementor-widget-wrap">
															<div>
																<div className="elementor-widget-container">
																	<div className="sc_layouts_widgets widget_area">
																		<div className="sc_layouts_widgets_inner widget_area_inner">
																			{this.state.standings.length > 0 ?
																				<div className="sp-widget-align-none">
																					<OwlCarousel ref="standings" options={{
																						dots: this.state.standings.length <= 10,
																						nav: this.state.standings.length > 10,
																						autoHeight: true,
																						loop: false,
																						responsive: { 0: { items: 1, } },
																						navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
																					}}>
																						{this.state.standings.map(s => {
																							return (
																								<aside className="widget widget_sportspress widget_league_table widget_sp_league_table widget--league-table">
																									<h5 className="widget_title widget--title">
																										<span className="sub-title">{s.competition}</span>
																										{s.name}
																									</h5>
																									<div className="sp-template sp-template-league-table">
																										<div className="sp-table-wrapper">
																											<div className="sp-scrollable-table-wrapper">
																												<div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
																													<table className="sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table dataTable no-footer" data-sp-rows={10} id="DataTables_Table_0" role="grid">
																														<thead>
																															<tr role="row">
																																<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																																	Team</th>
																																<th className="data-rank sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																																	P</th>
																																<th className="data-w sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																																	W</th>
																																<th className="data-d sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																																	D</th>
																																<th className="data-l sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																																	L</th>
																																<th className="data-pts sorting" tabIndex={0} rowSpan={1} colSpan={1}>
																																	Pts</th>
																															</tr>
																														</thead>
																														<tbody>
																															{s.standings.map(s => {
																																return (
																																	<tr className="sp-row-no-0 odd">
																																		<td className="data-name" data-label="Club">
																																			<Link to={`/league/${s.competitionId}/team/${s.teamId}`}>{s.name}</Link>
																																		</td>
																																		<td className="data-rank" data-label="P">
																																			{s.played}
																																		</td>
																																		<td className="data-w" data-label="W">
																																			{s.won}
																																		</td>
																																		<td className="data-d" data-label="D">
																																			{s.drawn}
																																		</td>
																																		<td className="data-l">
																																			{s.lost}
																																		</td>
																																		<td className="data-pts">
																																			{s.points}
																																		</td>
																																	</tr>
																																);
																															})}
																														</tbody>
																													</table>
																												</div>
																											</div>
																										</div>
																										{/* <div className="sp-league-table-link sp-view-all-link">
																														<a href="https://fcunited.azurewebsites.net/table/league-table/">
																															View full table
																														</a>
																													</div> */}
																									</div>
																								</aside>);
																						})}
																					</OwlCarousel>
																				</div> : undefined}
																			{this.state.averages.length > 0 ?
																				<div className="sp-widget-align-none">
																					<OwlCarousel ref="averages" options={{
																						dots: this.state.averages.length <= 10,
																						nav: this.state.averages.length > 10,
																						autoHeight: true,
																						loop: false,
																						responsive: { 0: { items: 1, } },
																						navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
																					}}>
																						{this.state.averages.map(s => {
																							return (
																								<aside id="sportspress-league-table-3" className="widget widget_sportspress widget_league_table widget_sp_league_table widget--league-table">
																									<h5 className="widget_title widget--title">
																										<span className="sub-title">{s.competition}</span>
																										{s.division}
																									</h5>
																									<div className="sp-template sp-template-player-list">
																										<div className="sp-table-wrapper">
																											<div className="sp-scrollable-table-wrapper">
																												<div id="DataTables_Table_1_wrapper" className="dataTables_wrapper no-footer">
																													<table className="sp-player-list sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table dataTable no-footer" data-sp-rows={10} id="DataTables_Table_1" role="grid">
																														<thead>
																															<tr role="row">
																																<th className="data-name sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Player">
																																	Name
																																</th>
																																<th className="data-goals sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Goals">
																																	P
																																</th>
																																<th className="data-assists sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Assists">
																																	W
																																</th>
																																<th className="data-assists sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Assists">
																																	%
																																</th>
																															</tr>
																														</thead>
																														<tbody>
																															{s.averages.map(a => {
																																return (
																																	<tr className="odd" role="row">
																																		<td className="data-name" data-label="Player">
																																			<Link to={`/league/${a.competitionId}/player/${a.userId}`}>{a.name}{a.reserve && <i title="Reserve" className="far fa-arrow-circle-up ml-1" />}</Link>
																																		</td>
																																		<td className="data-goals" data-label="Goals">
																																			{a.played}</td>
																																		<td className="data-assists" data-label="Assists">
																																			{a.won}</td>
																																		<td className="data-assists" data-label="Assists">
																																			{parseFloat(a.percentage.toFixed(0))}</td>
																																	</tr>
																																);
																															})}
																														</tbody>
																													</table>
																												</div>
																											</div>
																										</div>
																										<div className="sp-league-table-link sp-view-all-link">
																											<Link to={`/league/${s.competitionId}/division/${s.divisionId}/averages`}>
																												View full table
																											</Link>
																										</div>
																									</div>
																								</aside>);
																						})}
																					</OwlCarousel>
																				</div> : undefined}
																			{/*Defualt Image test and example*/}
																			{/* <aside id="text-4" className="widget widget_text mb-4">
																	<h5 className="widget_title"> Test Default </h5>
																	<div>
																	{defualtImage != null ?	
																	<img
																		className="news-image"
																		src={`${AppConfig.baseUrl}image/${defualtImage.imageId}`}
																		alt={AppCache.website.title} /> 
																	: <label>No Image</label>}
																	</div>
																</aside> */}
																			{
																				this.state.sponsors.length > 0 ?
																					<aside id="trx_addons_widget_banner-4" className="widget widget_banner">
																						<h5 className="widget_title">
																							Sponsors
																						</h5>
																						<OwlCarousel options={{ loop: false, responsive: { 0: { items: 1, } } }}>
																							{
																								this.state.sponsors.map(s => {
																									return (
																										<a href="http://fcunited.azurewebsites.net/sponsors/" className="image_wrap">
																											<img src="https://tabletennisengland.co.uk/wp-content/uploads/2015/11/Loop-at-Work.jpg" alt="sponsor" width={375} height={438} />
																										</a>
																									)
																								})
																							}
																						</OwlCarousel>
																					</aside> : undefined
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-12 d-md-none">
												<div className="sc_layouts_widgets">
													<div className="row">
														<div className="col-12 col-md-6">
															{
																AppCache.website.twitter ?
																	<aside id="text-4" className="widget widget_text mb-4">
																		<h5 className="widget_title">
																			<i className="fab fa-x-twitter"></i>
																		</h5>
																		<div>
																			<TwitterTimelineEmbed
																				sourceType="profile"
																				screenName={`${AppCache.website.twitter}`}
																				options={{ height: 400 }}
																			/>
																		</div>
																	</aside> : undefined
															}
														</div>
														<div className="col-12 col-md-6">
															{
																AppCache.website.facebook ?
																	<aside id="text-4" className="widget widget_text p-20 mb-4">
																		<h5 className="widget_title">
																			facebook
																		</h5>
																		<div>
																			<FacebookProvider appId="412401072952926">
																				{AppCache.website.facebook.indexOf("groups") >= 0 && <Group href={`https://www.facebook.com/${AppCache.website.facebook}/`} />}
																				{AppCache.website.facebook.indexOf("page") < 0 && <Page href={`https://www.facebook.com/${AppCache.website.facebook}/`} tabs="timeline" />}
																			</FacebookProvider>
																		</div>
																	</aside> : undefined
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Can>}
							</div>
						</PublicLayout>
				}
			</>
		);
	}
}
