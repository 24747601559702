import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";
import { ImageUpload } from "@components/controls/ImageUpload";
import { AppConfig } from "@config";
import { VisualEditor } from "@components/controls/VisualEditor";

import Select from "react-select";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactPlayer from "react-player";
import { SketchPicker } from "react-color";

const DraggableBlock = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [subTitle, setSubTitle] = useState(props.payload.subTitle);
	const [info, setInfo] = useState(props.payload.info);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [linkText, setLinkText] = useState(props.payload.linkText);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setlayout] = useState(props.payload.layout);
	const [imageId, setImageId] = useState<number>(props.payload.imageId);
	const [position, setPosition] = useState(props.payload.imagePosition);

	const [showVideo, setShowVideo] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [setupMode, setSetupMode] = useState(props.payload.layout ? false : true);

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<>
				{
					layout === 1 &&
					<section className="pagemaker-content pagemaker-content--1 theme--clear">
						<Container>
							<div className="sc_title scheme_dark sc_title_default">
								<span className="subtitle sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">
									{subTitle}
								</span>
								<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
									<span className="sc_item_title_text">{title}</span>
								</h1>
								<div className="sc_item_descr sc_title_descr">
									<p>
										{info}
									</p>
								</div>
								<div className="body">
									{ReactHtmlParser(body)}
								</div>
								<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
									{
										link &&
										<a href={link} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
											<span className="sc_button_text"><span className="sc_button_title">{linkText}</span></span>
										</a>
									}
								</div>
							</div>
						</Container>
					</section>
				}
				{
					layout === 2 &&
					<section className="pagemaker-content pagemaker-content--2 theme--clear">
						<div className="sc_title scheme_dark sc_title_default">
							<span className="subtitle sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
								{subTitle}
							</span>
							<h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
								<span className="sc_item_title_text">{title}</span>
							</h1>
							<div className="sc_item_descr sc_title_descr">
								<p>
									{info}
								</p>
							</div>
						</div>
					</section>
				}
				{
					layout === 3 &&
					<section className="pagemaker-content pagemaker-content--3 theme--light">
						<Row>
							<Col md={6}>
								<div className="bg-image overlay bg-cover" style={{ backgroundImage: `url(${image})` }}></div>
							</Col>
							<Col md={6}>
								<div className="content">
									<div className="sc_title scheme_dark sc_title_default">
										<span className="subtitle sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">{subTitle}</span>
										<h2 className="sc_item_title sc_title_title sc_item_title_style_default">
											<span className="sc_item_title_text">{title}</span>
										</h2>
										<div className="sc_item_descr sc_title_descr">
											<p>
												{body}
											</p>
										</div>
										<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
											{
												link &&
												<a href={link} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
													<span className="sc_button_text"><span className="sc_button_title">{linkText}</span></span>
												</a>
											}
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</section>
				}
				{
					layout === 4 &&
					<section
						className="elementor-section elementor-top-section elementor-element elementor-element-eabacc7 elementor-section-boxed elementor-section-height-default elementor-section-height-default">
						<div className="elementor-container elementor-column-gap-extended">
							<div className="elementor-row">
								<div
									className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-8e2b6db sc_inner_width_none sc_layouts_column_icons_position_left animated fadeIn">
									<div className="elementor-column-wrap elementor-element-populated">
										<div className="elementor-widget-wrap">
											<div
												className="sc_layouts_item elementor-element elementor-element-7c0070d sc_fly_static elementor-widget elementor-widget-trx_sc_title">
												<div className="elementor-widget-container">
													<div
														className="sc_title sc_title_default">
														<h2 className="sc_item_title sc_title_title sc_item_title_style_default">
															<span className="sc_item_title_text">Sign Up Now</span>
														</h2>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-19f2f15 sc_inner_width_none sc_layouts_column_icons_position_left animated fadeIn">
									<div className="elementor-column-wrap elementor-element-populated">
										<div className="elementor-widget-wrap">
											<div
												className="sc_layouts_item elementor-element elementor-element-8e71f9c sc_fly_static elementor-widget elementor-widget-text-editor">
												<div className="elementor-widget-container">
													<div className="elementor-text-editor elementor-clearfix">
														<p>
															Become a member of our online community and get tickets to
															upcoming matches or sports events faster!
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				}
				{
					layout === 5 &&
					<>
						<img src={image} />
					</>
				}
			</>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, link, linkText, image, layout } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, info, body, link, linkText, image, layout } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	const handleUpload = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleSelect = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleReset = () => () => {
		setImageId(null);
	};

	const handleLayoutConfirm = (layout: number) => (e: any) => {
		setlayout(layout);
		setSetupMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, body, link, linkText, image, layout } });
	};

	return (
		<>
			<Draggable {...props} >
				<Card>
					<Row>
						<Col>
							{name}
							<p className="u-text-small">Content layout {layout}</p>
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-pencil"></i>
							</a>
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<EditorElement title={"Title"} include={[1, 2, 3]} layout={layout}>
						<Input value={title} placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Sub Title"} include={[1, 2, 3]} layout={layout}>
						<Input value={subTitle} placeholder="Enter sub title" onChange={e => setSubTitle(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Info"} include={[1, 2, 3]} layout={layout}>
						<Input value={info} placeholder="Enter info" onChange={e => setInfo(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Body"} include={[1, 4]} layout={layout}>
						<VisualEditor
							menubar={false}
							height={200}
							content={body}
							onChange={e => setBody(e)}
						/>
					</EditorElement>
					<EditorElement title={"Body"} include={[2, 3]} layout={layout}>
						<Input value={body} placeholder="Enter body text" onChange={e => setBody(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Link"} include={[1, 3]} layout={layout}>
						<Input value={link} placeholder="Enter link url" onChange={e => setLink(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Link Text"} include={[1, 3]} layout={layout}>
						<Input value={linkText} placeholder="Enter link text" onChange={e => setLinkText(e.target.value)} />
					</EditorElement>
					<EditorElement title={"Image"} include={[3, 5]} layout={layout}>
						{
							!imageId && <Input value={image} placeholder="Enter image url" onChange={e => setImage(e.target.value)} />
						}
						<Row className="mt-3">
							<Col>
								<ImageUpload
									reset={true}
									imageId={imageId}
									onSelect={handleSelect()}
									onReset={handleReset()}
									onCancel={() => { }}
									onUpload={handleUpload()}
									aspectRatio={16 / 9} />
							</Col>
						</Row>
					</EditorElement>
					<EditorElement title={"Image Position"} include={[3]} layout={layout}>
						<Label>Image Position</Label>
						<Select
							options={[
								{ label: "Left", value: 1 },
								{ label: "Right", value: 2 }
							]}
							onChange={e => setPosition(e ? e.value : e)}
						/>
					</EditorElement>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-outline btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-secondary btn-outline btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			<Modal className={"element-editor"} isOpen={setupMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Row>
							<Col md={6}>
								<Card onClick={handleLayoutConfirm(1)}>
									<CardBody>
										<p className="section-title mt-0">Card</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleLayoutConfirm(2)}>
									<CardBody>
										<p className="section-title mt-0">Post</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleLayoutConfirm(3)}>
									<CardBody>
										<p className="section-title mt-0">Quote</p>
									</CardBody>
								</Card>
							</Col>
							<Col md={6}>
								<Card onClick={handleLayoutConfirm(4)}>
									<CardBody>
										<p className="section-title mt-0">Call to Action</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</FormGroup>
				</ModalBody>
			</Modal>
		</>
	);
};

const EditorElement = (props) => {
	return <>
		{props.include.includes(props.layout) ?
			<FormGroup>
				<Label>{props.title}</Label>
				{props.children}
			</FormGroup> :
			<></>
		}
	</>
}

export default DraggableBlock;
