import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";
import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import { Helmet } from "react-helmet";
import { Average } from "@models/average";
import { Match } from "@models/match";
import { MatchService } from "@services/match.service";
import CompetitionMenu from "@components/controls/CompetitionMenu";

interface IDivisionOverviewPageProps {
	site?: string;
	match?: any;
}

interface IDivisionOverviewPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	standings: Standing[];
	averages: Average[];
	results: Match[];
	fixtures: Match[];
}

export class DivisionOverviewPage extends React.Component<IDivisionOverviewPageProps, IDivisionOverviewPageState> {
	private competitionId: number;
	private divisionId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			standings: [],
			averages: [],
			results: [],
			fixtures: []
		};
	}

	componentDidMount() {
		//
		this.divisionId = this.props.match.params.divisionId as number;
		this.competitionId = this.props.match.params.competitionId as number;

		this.load();
	}

	componentDidUpdate(props: IDivisionOverviewPageProps, state: IDivisionOverviewPageState) {
		this.divisionId = this.props.match.params.divisionId as number;

		if (props.match.params.divisionId !== this.divisionId) {
			this.load();
		}
	}

	load() {
		const p1 = DivisionService.standings(this.divisionId).then(standings => {
			this.setState({ standings });
		});

		const p2 = DivisionService.getAverages(this.divisionId).then(averages => {
			this.setState({ averages });
		});

		const p3 = DivisionService.getById(this.divisionId).then(division => {
			this.setState({ division });
		});

		Promise.all([p1, p2, p3]).then(result => {

			const p6 = CompetitionService.getById(this.state.division.competitionId).then(competition => {
				this.setState({ competition });
			});

			Promise.all([p6]).then(result => {
				this.setState({ loading: false });
			});
		});
	}

	public render() {
		return (
			<PublicLayout className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout"
				print={
					() => (
						<div style={{margin: "20px"}}>
							<Row>
								<Col>
									<img width={200} height={200} src={require("@assets/images/placeholders/tte-square.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
								</Col>
								<Col>
									<h4 style={{textAlign: "center"}}>{this.state.competition ? this.state.competition.name : undefined}</h4>
									<h5 style={{textAlign: "center"}}>{this.state.division ? this.state.division.name : undefined}</h5>
								</Col>
							</Row>
							<Row style={{height: "1450px"}}>
								<Col>
									{this.state.competition && this.state.standings && (this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && <Row style={{height: `${(30 * this.state.standings.length) + 150}px`}}>
										<h5>Standings</h5>
										<table style={{textAlign: "center", width: "1100px", fontSize: "16pt"}}  role="grid">
											<thead>
												<tr role="row">
													<th rowSpan={1} colSpan={1}>#</th>
													<th rowSpan={1} colSpan={1}>Team</th>
													{this.state.competition.table.winLossDraw ? <th rowSpan={1} colSpan={1}>P</th> : ""}
													{this.state.competition.table.winLossDraw ? <th rowSpan={1} colSpan={1}>W</th> : ""}
													{this.state.competition.table.winLossDraw ? <th rowSpan={1} colSpan={1}>D</th> : ""}
													{this.state.competition.table.winLossDraw ? <th rowSpan={1} colSpan={1}>L</th> : ""}
													{this.state.competition.table.gamesForAgainst ? <th rowSpan={1} colSpan={1}>GF</th> : ""}
													{this.state.competition.table.gamesForAgainst ? <th rowSpan={1} colSpan={1}>GA</th> : ""}
													{this.state.competition.table.gamesRatio ? <th rowSpan={1} colSpan={1}>GR</th> : ""}
													{this.state.competition.table.setsForAgainst ? <th rowSpan={1} colSpan={1}>SF</th> : ""}
													{this.state.competition.table.setsForAgainst ? <th rowSpan={1} colSpan={1}>SA</th> : ""}
													{this.state.competition.table.setsRatio ? <th rowSpan={1} colSpan={1}>SR</th> : ""}
													{this.state.competition.table.pointsForAgainst ? <th rowSpan={1} colSpan={1}>PA</th> : ""}
													{this.state.competition.table.pointsAdjustments ? <th rowSpan={1} colSpan={1}>ADJ</th> : ""}
													<th rowSpan={1} colSpan={1}>Pts</th>
												</tr>
											</thead>
											<tbody>
												{
													this.state.standings.map(s => {
														return (
															<tr key={`standings-${s.entrantId}`} role="row">
																<td>{s.position}</td>
																<td>{s.name}</td>
																{this.state.competition.table.winLossDraw ? <td>{s.played}</td> : ""}
																{this.state.competition.table.winLossDraw ? <td>{s.won}</td> : ""}
																{this.state.competition.table.winLossDraw ? <td>{s.drawn}</td> : ""}
																{this.state.competition.table.winLossDraw ? <td>{s.lost}</td> : ""}
																{this.state.competition.table.gamesForAgainst ? <td>{s.gamesFor}</td> : ""}
																{this.state.competition.table.gamesForAgainst ? <td>{s.gamesAgainst}</td> : ""}
																{this.state.competition.table.gamesRatio ? <td>{s.gamesAgainst > 0 ? (s.gamesFor/s.gamesAgainst).toFixed(2) : s.gamesFor.toFixed(2)}</td> : ""}
																{this.state.competition.table.setsForAgainst ? <td>{s.setsFor}</td> : ""}
																{this.state.competition.table.setsForAgainst ? <td>{s.setsAgainst}</td> : ""}
																{this.state.competition.table.setsRatio ? <td>{s.setsAgainst > 0 ? (s.setsFor/s.setsAgainst).toFixed(2) : s.setsFor.toFixed(2)}</td> : ""}
																{this.state.competition.table.pointsForAgainst ? <td>{s.pointsAgainst}</td> : ""}
																{this.state.competition.table.pointsAdjustments ? <td>{s.adjustment}</td> : ""}
																<td>{s.points}</td>
															</tr>
														);
													})
												}
											</tbody>
										</table>
									</Row>}
									{this.state.competition && this.state.averages && (this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && <Row style={{height: `${(30 * this.state.averages.length) + 150}px`}}>
										<h5>Averages</h5>
										<table style={{textAlign: "center", width: "1100px", fontSize: "16pt"}} role="grid">
											<thead>
												<tr role="row">
													<th rowSpan={1} colSpan={1}>Player</th>
													<th rowSpan={1} colSpan={1}>Team</th>
													<th rowSpan={1} colSpan={1}>P</th>
													<th rowSpan={1} colSpan={1}>W</th>
													<th rowSpan={1} colSpan={1}>%</th>
												</tr>
											</thead>
											<tbody>
												{
													this.state.averages.map(s => {
														return (
															<tr key={`standings-${s.entrantId}`} role="row">
																<td>{s.name}</td>
																<td>{s.team}</td>
																<td>{s.played}</td>
																<td>{s.won}</td>
																<td>{s.percentage}</td>
															</tr>
														);
													})
												}
											</tbody>
										</table>
									</Row>}
								</Col>
							</Row>
							<Row>
								<Col>
									<img width={200} height={200} src={require("@assets/images/placeholders/tte-square.png")} className="attachment-fcunited-thumb-plain size-fcunited-thumb-plain wp-post-image" />
								</Col>
								<Col>
									<h4 style={{textAlign: "center"}}>{this.state.competition ? this.state.competition.name : undefined}</h4>
									<h5 style={{textAlign: "center"}}>{this.state.division ? this.state.division.name : undefined}</h5>
								</Col>
							</Row>
						</div>
					)
				}>
				<ShowMe
				    mode={ShowMeMode.Full}
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Table</h1>
																			<p className="sc_layouts_title_breadcrumbs">{this.state.competition.name} <span className="separator"> - </span> {this.state.division.name}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />
									</div> : undefined
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										{
											AppCache.website.config.subHeader ? undefined :
												<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div className="sc_title sc_title_default mb-1">
															<h1 className=" m-0 sc_item_subtitle sc_align_center">
																<span className="sc_item_title_text">Overview</span>
															</h1>
															<h2 className=" m-0 sc_item_title sc_align_center">
																<span className="sc_item_title_text">{this.state.division ? this.state.division.name : ""}</span>
															</h2>
															<p className="text-center">{this.state.competition.name}</p>
														</div>
													</div>
												</div>
										}
										{!AppCache.website.config.subHeader && <CompetitionMenu competitionId={this.competitionId} divisionId={this.divisionId} />}
										{(this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && <div className="row mb-5">
											<div className="col-12">
												<div>
													<h3 className="text-center">Table</h3>
													<div className="standings">
														<div>
															<div className="table-responsive">
																<div>
																	<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-rank sorting" rowSpan={1} colSpan={1} aria-label="Pos">#</th>
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Club">Team</th>
																				{this.state.competition.table.winLossDraw ? <th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="P">P</th> : ""}
																				{this.state.competition.table.winLossDraw ? <th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="W">W</th> : ""}
																				{this.state.competition.table.winLossDraw ? <th className="data-d sorting" rowSpan={1} colSpan={1} aria-label="D">D</th> : ""}
																				{this.state.competition.table.winLossDraw ? <th className="data-l sorting" rowSpan={1} colSpan={1} aria-label="L">L</th> : ""}
																				{this.state.competition.table.gamesForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="F">GF</th> : ""}
																				{this.state.competition.table.gamesForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="F">GA</th> : ""}
																				{this.state.competition.table.setsForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="F">SF</th> : ""}
																				{this.state.competition.table.setsForAgainst ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="F">SA</th> : ""}
																				{this.state.competition.table.pointsForAgainst ? <th className="d-none d-md-table-cell data-a sorting" rowSpan={1} colSpan={1} aria-label="A">PA</th> : ""}
																				{this.state.competition.table.pointsAdjustments ? <th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="F">ADJ</th> : ""}
																				<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">Pts</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.standings.map(s => {
																					return (
																						<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
																							<td className="data-rank" data-label="Pos">{s.position}</td>
																							<td className="data-name nowrap" data-label="Team"><Link to={`/league/${this.competitionId}/team/${s.teamId}`}>{s.name}</Link></td>
																							{this.state.competition.table.winLossDraw ? <td className="data-p" data-label="P">{s.played}</td> : ""}
																							{this.state.competition.table.winLossDraw ? <td className="data-w" data-label="W">{s.won}</td> : ""}
																							{this.state.competition.table.winLossDraw ? <td className="data-d" data-label="D">{s.drawn}</td> : ""}
																							{this.state.competition.table.winLossDraw ? <td className="data-l" data-label="L">{s.lost}</td> : ""}
																							{this.state.competition.table.gamesForAgainst ? <td className="d-none d-md-table-cell data-g" data-label="GF">{s.gamesFor}</td> : ""}
																							{this.state.competition.table.gamesForAgainst ? <td className="d-none d-md-table-cell data-g" data-label="GA">{s.gamesAgainst}</td> : ""}
																							{this.state.competition.table.setsForAgainst ? <td className="d-none d-md-table-cell data-s" data-label="SF">{s.setsFor}</td> : ""}
																							{this.state.competition.table.setsForAgainst ? <td className="d-none d-md-table-cell data-s" data-label="SA">{s.setsAgainst}</td> : ""}
																							{this.state.competition.table.pointsForAgainst ? <td className="d-none d-md-table-cell data-a" data-label="PA">{s.pointsAgainst}</td> : ""}
																							{this.state.competition.table.pointsAdjustments ? <td className="d-none d-md-table-cell data-a" data-label="ADJ">{s.adjustment}</td> : ""}
																							<td className="data-pts" data-label="PTS">{s.points}</td>
																						</tr>
																					);
																				})
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>}
										{(this.state.division.fixtureFormat ?? this.state.competition.fixtureFormat) != 5 && <div className="row">
											<div className="col-12">
												<div>
													<h3 className="text-center">Averages</h3>
													<div className="standings">
														<div>
															<div className="table-resposive">
																<div>
																	<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" id="DataTables_Table_0" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Player">Player</th>
																				<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Team">Team</th>
																				<th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="P">P</th>
																				<th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="W">W</th>
																				<th className="data-pts sorting" rowSpan={1} colSpan={1} aria-label="Pts">%</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.averages.map(s => {
																					return (
																						<tr key={`standings-${s.entrantId}`} className="sp-row-no-0 odd" role="row">
																							<td className="data-name" data-label="Name"><Link to={`/league/${this.competitionId}/player/${s.userId}`}>{s.name}</Link></td>
																							<td className="data-team" data-label="Team"><Link to={`/league/${this.competitionId}/team/${s.teamId}`}>{s.team}</Link></td>
																							<td className="data-p" data-label="P">{s.played}</td>
																							<td className="data-w" data-label="W">{s.won}</td>
																							<td className="data-pts" data-label="Pts">{s.percentage}</td>
																						</tr>
																					);
																				})
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>}
										<div className="row">
											<div className="col-12">
												<span className="tx-bold">Last Updated</span> <span title={moment(this.state.division.updated).format("Do MMMM YYYY")}>{moment(this.state.division.updated).fromNow()}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
