// import { Theme, Content } from './models/theme';
import { Tenant } from "@models/tenant";
import { League } from "@models/league";

import * as store from "store2";
import { Website } from "@models/website";
import { UserSettings } from "@models/user";
import { Club } from "@models/club";
import { County } from "@models/county";
import { SortingProperty } from "@models/filters/filterOptions";

export class AppCache {

	private static _tenantKey: string = "current_tenant";
	private static _leagueKey: string = "current_league";
	private static _websiteKey: string = "current_website";
	private static _settingsKey: string = "current_settings";
	private static _clubKey: string = "current_club";
	private static _countyKey: string = "current_county";
	private static _unauthorised: string = "current_unauthorised";
	private static _invoiceOrderingKey: string = "invoice_ordering";

	public static get tenant(): Tenant {
		return store.get(AppCache._tenantKey);
	}
	public static set tenant(v: Tenant) {
		store.set(AppCache._tenantKey, v);
	}

	public static get league(): League {
		return store.get(AppCache._leagueKey);
	}
	public static set league(v: League) {
		store.set(AppCache._leagueKey, v);
	}

	public static get club(): Club {
		return store.get(AppCache._clubKey);
	}
	public static set club(v: Club) {
		store.set(AppCache._clubKey, v);
	}

	public static get county(): County {
		return store.get(AppCache._countyKey);
	}
	public static set county(v: County) {
		store.set(AppCache._countyKey, v);
	}

	public static get website(): Website {
		return store.get(AppCache._websiteKey);
	}
	public static set website(v: Website) {
		store.set(AppCache._websiteKey, v);
	}

	public static get settings(): UserSettings {
		return store.get(AppCache._settingsKey);
	}
	public static set settings(v: UserSettings) {
		store.set(AppCache._settingsKey, v);
	}

	public static get invoiceOrdering(): SortingProperty[] {
		return store.get(AppCache._invoiceOrderingKey);
	}
	public static set invoiceOrdering(v: SortingProperty[]) {
		store.set(AppCache._invoiceOrderingKey, v);
	}

	public static reset() {
		store.remove(AppCache._countyKey);
		store.remove(AppCache._clubKey);
		store.remove(AppCache._leagueKey);

		AppCache.tenant = null;
		AppCache.website = null;
		AppCache.settings = null;
	}
}
