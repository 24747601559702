import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Booking } from "@models/booking";
import { EventMember } from "@models/event";
import { BookingService } from "@services/booking.service";
import { PagedList } from "@models/paging";
import { MembershipStatus, MembershipUsage, MembershipUser } from "@models/membership";

interface IBookings {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IBookings) => {
	const [contact, setContact] = useState<Contact>(props.contact);

	const [memberships, setMemberships] = useState<PagedList<MembershipUser>>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const memberships = await ContactService.getMemberships(contact.id, {});
		console.log("🚀 ~ file: Memberships.tsx:40 ~ load ~ memberships:", memberships)

		setMemberships(memberships);
	};

	return (
		<>
			<div className="mb-4 memberships">
				<h6>Memberships</h6>
				<Row>
					{
						memberships && memberships.items.map(m => (
							<Col xs={12}>
								<Card className="mb-3 p-3">
									<Row>
										<Col lg={5}>
											<h6 className="m-0">{m.membership.name}</h6>
											<p className="m-0 small">{m.membership.summary}</p>
										</Col>
										<Col lg={2} className="justify-content-center align-self-center">
											<h6 className="small m-0">Membership Expiry</h6>
											{m.end && <span>{moment(m.end).format("DD/MM/YYYY")}</span>}
											{!m.end && <span>N/A</span>}
										</Col>
										<Col lg={2} className="justify-content-center align-self-center">
											<h6 className="small m-0">Next Payment Due</h6>
											{m.due && <span>{moment(m.due).format("DD/MM/YYYY")}</span>}
											{!m.due && <span>N/A</span>}
										</Col>
										<Col lg={2} className="justify-content-center align-self-center">
											<Row>
											{
												(m.usage != null || m.membership.usageType ===  MembershipUsage.Unlimited) &&
												<Col>
													<h6 className="small m-0">Remaining Usage</h6>
													{m.membership.usageType ===  MembershipUsage.Unlimited ? <span className="small">Unlimited</span> :
													m.usage != null && <span>{m.usage}</span>}
												</Col>
											}
											{
												(m.usage != null && m.membership.usageType ===  MembershipUsage.Unlimited) &&
												<Col>
													<h6 className="small m-0">Used</h6>
													<span>{999-m.usage}</span>
												</Col>
											}
											</Row>
										</Col>
										<Col lg={1} className="justify-content-center align-self-center text-center">
											{m.status === MembershipStatus.Active && <span title="Active" className="status bg-success"></span>}
											{m.status === MembershipStatus.Pending && <span title="Pending" className="status bg-warning"></span>}
											{m.status === MembershipStatus.Overdue && <span title="Overdue" className="status bg-danger"></span>}
										</Col>
									</Row>
								</Card>
							</Col>
						))
					}
				</Row>
			</div>
		</>
	);
};