import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Division, CreateDivision } from "@models/division";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Standing } from "@models/standing";
import { MatchGrouping, MatchCard, CreateMatch, MatchStatus, Match, MatchGroupRange, MatchGroupType, MatchGroup, MatchSet, PotmType, MatchFilters, MatchResults, MatchCardEntryLevel, MatchReport } from "@/models/match";
import { Entrant } from "@models/entrant";
import { Team, TeamMember } from "@models/team";
import { MatchGame } from "../models/match";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { MemberRanking } from "@models/member";
import * as qs from "query-string";
import { RankingStats } from "@models/rankings";
import { Registration, RegistrationCompetitionType, RegistrationTeam } from "@models/registration";
import { EntryFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";
import { CostAdjustment, CostFeedback, Entry, EntryCosts, EntryPlayer, EntryStats, EntryTeam, RegisterEntryPlayer, RegisterEntryTeam } from "@models/entry";
import { Invoice } from "@models/invoice";

export class EntryService {

	constructor() {
		//
	}

	public static get(options: EntryFilterOptions): Promise<PagedList<Entry>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<Entry>>(`entry`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getById(entryId: number): Promise<Entry> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Entry>(`entry/${entryId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getByMember(options: EntryFilterOptions): Promise<PagedList<Entry>> {
		return new Promise((resolve, reject) => {
			RestHelper.get<PagedList<Entry>>(`entry/member`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getByPermission(): Promise<Entry[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Entry[]>(`entry/member/permission`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static registerClub(club: Entry): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`entry/club/register`, club)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static registerTeam(team: RegisterEntryTeam): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`entry/team/register`, team)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsertTeam(team: EntryTeam): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`entry/team`, team)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getTeam(teamId: number): Promise<EntryTeam> {
		return new Promise((resolve, reject) => {
			RestHelper.get<EntryTeam>(`entry/team/${teamId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getPlayer(playerId: number): Promise<EntryPlayer> {
		return new Promise((resolve, reject) => {
			RestHelper.get<EntryPlayer>(`entry/player/${playerId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static acceptTerms(entryId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`entry/${entryId}/terms/accept`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static teams(options: EntryFilterOptions): Promise<EntryTeam[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post<EntryTeam[]>(`entry/teams`, options)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static players(options: EntryFilterOptions): Promise<EntryPlayer[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post<EntryPlayer[]>(`entry/players`, options)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static assignPlayer(model: RegisterEntryPlayer): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`entry/player/assign`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static movePlayer(model: RegisterEntryPlayer): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`entry/player/move`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removeEntry(entryId: number): Promise<Entry> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Entry>(`entry/${entryId}/remove`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removeTeam(model: EntryTeam): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`entry/team/remove`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static removePlayer(model: EntryPlayer): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`entry/player/${model.id}/remove`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static updateStatus(model: Entry): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`entry/update/status`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static costFeedback(entryId: number, readyOnly: boolean = false): Promise<EntryCosts> {
		return new Promise((resolve, reject) => {
			RestHelper.get<EntryCosts>(`entry/${entryId}/costs`,
			{
				params: {readyOnly},
				paramsSerializer: params => qs.stringify(params)
			})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static existingTeams(entryId: number, competitionTypeId?: number): Promise<RegistrationTeam[]> {
		console.log(competitionTypeId)
		return new Promise((resolve, reject) => {
			RestHelper.post<RegistrationTeam[]>(`entry/teams/existing`, {competitionTypeId, entryId})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static pay(entryId: number, categories: number[], paymentMethodId: string): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.post<Invoice>(`entry/pay`,{ entryId, categories, paymentMethodId })
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static invoice(entryId: number, categories: number[]): Promise<Invoice> {
		return new Promise((resolve, reject) => {
			RestHelper.post<Invoice>(`entry/invoice`,{ entryId, categories })
			.then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getInvoices(entryId: number): Promise<Invoice[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Invoice[]>(`entry/${entryId}/invoices`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getCostAdjustments(teamId?: number, playerId?: number): Promise<CostAdjustment[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<CostAdjustment[]>(`entry/adjustments`,
				{
					params: {teamId, playerId},
					paramsSerializer: params => qs.stringify(params)
				})
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static upsert(model: Entry): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post<number>(`entry`, model)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static refreshPaidStatus(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`entry/${id}/invoices/refresh`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static changeTeamName(id: number, names: any): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`entry/team/${id}/name`, names)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static allowInvoicing(entryId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.get<boolean>(`entry/${entryId}/invoice/allow`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static updateTeamHistory(id: number, teamId: number, existingId?: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.get<number>(`entry/team/${id}/history/${teamId}${existingId ? `/previous/${existingId}`: ``}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}
}