import React from "react";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';

import { ArrayHelper } from "@helpers/array.helper";
import { Competition, CompetitionDay } from "@models/competition";
import { toast } from "react-toastify";
import { AppConfig } from "@config";

import { CreateEntrant, Entrant, EntrantType } from "@models/entrant";
import { DivisionService } from "@services/division.service";
import { Division, DivisionStatus } from "@models/division";
import ObjectHelper from "@helpers/object.helper";
import { SelectOption } from "@models/forms";
import { TeamService } from "@services/team.service";

interface IAssignEntrantsSidebar {
	division: Division;
	competition: Competition;

	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IAssignEntrantsSidebar) => {
	const [createEntrant, setCreateEntrant] = useState<boolean>();
	const [entrant, setEntrant] = useState<CreateEntrant>();
	const [team, setTeam] = useState<SelectOption<number>>();
	const [entrants, setEntrants] = useState<Entrant[]>([]);

	const [entrantId, setEntrantId] = useState<number>();
	const [switchModal, setSwitchModal] = useState<boolean>(false);

	useEffect(() => {
		load();
	}, [, props.division]);



	const load = async () => {
		DivisionService.entrants(props.division.id).then(result => {
			setEntrants(result);
		});
	};

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {
		props.onConfirm();
		props.onClose();
	};

	const create = (type: EntrantType) => () => {
		const entrant = new CreateEntrant();
		if(switchModal){
			entrant.entrantId = entrantId;
		}
		entrant.divisionId = props.division.id;
		entrant.competitionId = props.competition.id;
		entrant.type = type;

		setEntrant(entrant);
		setCreateEntrant(true);
		setSwitchModal(false);
	}

	const removeEntrant = (entrantId: number) => () => {
		DivisionService.unassign(entrantId).then(result => {
			toast.success("Entrant removed");
			load();
		});
	}

	const loadTeamOptions = () => (value: string): Promise<SelectOption<number>[]> => {
		return new Promise(resolve => {
			TeamService.available(props.competition.id, value, 20).then(teams => {
				const options = teams.map((t) => ({ label: `${t.name} - ${t.competition}`, value: t.id }));
				resolve(options);
			});
		});
	}

	const onSortEnd = () => (sort) => {
		const entrantsSort = ArrayHelper.arrayMove(entrants, sort.oldIndex, sort.newIndex);

		for (let i = 0; i < entrantsSort.length; i++) {
			const element = entrantsSort[i];
			element.ordering = i;
		}

		setEntrants(entrantsSort);

		DivisionService.order(entrantsSort).then(result => {
			load();
		});
	}

	const handleChange = (property: Function) => (e: any) => {
		const name = /\.([^\.;]+);?\s*\}$/.exec(property.toString())[1];
		setEntrant({ ...entrant, [name]: e.currentTarget.value });
	}

	const handleChangeMember = () => (option: SelectOption<number>) => {
		setTeam(option);
		var createEntrant = entrant;
		createEntrant.teamId = option.value
		DivisionService.assign(createEntrant).then(result => {
			load();
			setEntrant(null);
			setTeam(null);
			setCreateEntrant(false);
		});
	}

	const assign = () => () => {
		const createEntrant = entrant;

		if (createEntrant.name === "" || createEntrant.name === undefined && createEntrant.type === EntrantType.Bye) {
			createEntrant.name = "Bye";
		}

		DivisionService.assign(createEntrant).then(result => {
			load();
			setEntrant(null);
			setCreateEntrant(false);
			setEntrantId(null);
		});
	}

	const toggleSwitch = (entrantId?: number) => () => {
		setSwitchModal(!switchModal);
		setEntrantId(entrantId);
	}

	const SortableItem = SortableElement(({ value, index }) => (
		<Card className="mb-3 p-3">
			<Row>
				<Col lg={2}>
					<button className="btn btn--circle btn-icon text-white" style={{ height: "30px", width: "30px" }}>
						<span className="m-auto">{index + 1}</span>
					</button>
				</Col>
				<Col lg={7} className="d-flex align-self-center">
					<div className="user-name-address">
						<p>{value.fullName}</p>
					</div>
				</Col>
				<Col lg={3} className="d-flex justify-content-end align-self-center">
					{props.competition.archived ?
						undefined
						: <div className="user-btn-wrapper">
							{
								props.division.status === DivisionStatus.New ?
								<button onClick={removeEntrant(value.id)} title="Delete Entrant" className="btn btn--circle btn-sm text-white">
									<i className="fas fa-times"></i>
								</button> :
								<button onClick={toggleSwitch(value.id)} title = "Change Type" className="btn btn--circle btn-sm text-white">
									<i className="icon far fa-repeat"></i>
								</button>
							}
						</div>
					}
				</Col>
			</Row>
		</Card>));

	const SortableList = SortableContainer(({ items }) => {
		return (
			<div className="list-group list-group-sortable list-group-user list-cards">
				{items.map((value, index) => (
					<SortableItem disabled={props.division.status != DivisionStatus.New} key={`item-${index}`} index={index} value={value} />
				))}
			</div>
		);
	});

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Entrants
					<div className="help-container">
						<a className="help">
							<i className="far fa-info-circle"></i>
						</a>
					</div>
				</h6>
			</div>
			<div className="sidebar--content">
				<div className="form-layout">
					{createEntrant && (entrant != null && entrant.type === EntrantType.Default ? <div className="form-group">
						<label className="form-control-label">Team <span className="tx-danger">*</span></label>
						<Row>
							<Col xs={10}>
								<AsyncSelect
									value={team}
									defaultOptions
									loadOptions={loadTeamOptions()}
									closeMenuOnSelect={true}
									onChange={handleChangeMember()} />
							</Col>
							<Col xs={2}>
								<button onClick={() => { setCreateEntrant(false); setEntrant(null); }} className="btn btn-circle bg-primary text-white btn-icon mr-2" title="Cancel"><div className="tx-20"><i className="icon fas fa-times"></i></div></button>
							</Col>
						</Row>
					</div> :
						<div className="form-group">
							<label className="form-control-label">Name</label>
							<input className="form-control" type="text" name="description" onChange={handleChange(() => entrant.name)} value={ObjectHelper.ifNotNull(entrant, () => entrant.name, "")} placeholder="Name" />
							<br />
							<button onClick={assign()} className="btn btn-circle bg-primary text-white btn-icon mr-2" title="Add Entrant"><div className="tx-20"><i className="icon fas fa-check"></i></div></button>
							<button onClick={() => { setCreateEntrant(false); setEntrant(null); }} className="btn btn-circle bg-primary text-white btn-icon mr-2" title="Cancel"><div className="tx-20"><i className="icon fas fa-times"></i></div></button>
						</div>)}
				</div>
				{
					!createEntrant &&
					<>
						<div className="row">
							<div className="col">
								<SortableList distance={1} items={entrants} onSortEnd={onSortEnd()} helperClass="list-group list-group-user" />
							</div>
						</div>
						<Row className="mt-4">
							<Col xs={6}>
								<button onClick={create(EntrantType.Default)} className="btn btn-primary btn-rounded btn-outline bd-0" title="Add Team">
									Add Team
								</button>
							</Col>
							<Col xs={6}>
								<button onClick={create(EntrantType.Bye)} className="btn btn-primary btn-rounded btn-outline bd-0" title="Add Bye">
									Add Bye
								</button>
							</Col>
						</Row>
					</>
				}
			</div>
			<div className="form-layout-footer text-center mt-4">
				<a tabIndex={0} className="btn-link clickable" onClick={handleClose()}>Cancel</a>
			</div>
			<div className="sidebar--footer">

			</div>
			<Modal className="modal--default" isOpen={switchModal} toggle={toggleSwitch()}>
				<ModalHeader toggle={toggleSwitch()}>Switch Type</ModalHeader>
				<ModalBody>
					<div className="col-lg-12">
						<div className="form-group">
							<div className="row">
								<div className="col-6">
									<button className="btn btn-md btn-primary btn-rounded btn-block" onClick={create(EntrantType.Default)}>Team</button>
								</div>
								<div className="col-6">
									<button className="btn btn-md btn-secondary btn-rounded btn-block" onClick={create(EntrantType.Bye)}>Bye</button>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<button className="btn btn-md bg-gray-300 btn-rounded" onClick={toggleSwitch()}>Close</button>
				</ModalFooter>
			</Modal>
		</div>
	);
};