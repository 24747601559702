import RestHelper from "@helpers/rest.helper";
import { PagedList } from "@models/paging";
import { FilterOptions } from "@models/filters/filterOptions";
import * as qs from "qs";
import { StripeAccount, StripeTransfer } from "@models/stripe";
import { PaymentMethod } from "@stripe/stripe-js";

export class StripeService {
	public static setup(email: string): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`stripe/account`, { email })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static retry(stripeId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`stripe/account`, { stripeId })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static setupMember(paymentId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`stripe/setup/member`, { paymentId })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getByClubId(clubId: number): Promise<StripeAccount> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`stripe/account/${clubId}`)
				.then((result) => {
					if (result.data != null) {
						resolve(result.data);
					} else {
						resolve(null);
					}
				})
				.catch((error) => {
					reject(error.response.data);
				});
		});
	}

	public static getCustomerPaymentMethods(userId: string): Promise<PaymentMethod[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`stripe/payment-methods/${userId}`)
				.then((result) => {
					if (result.data != null) {
						resolve(result.data);
					} else {
						resolve(null);
					}
				})
				.catch((error) => {
					reject(error.response.data);
				});
		});
	}

	public static isActive(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`stripe/account/active`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}
}