export class LocaleHelper {
	public static toPrice(price: number): string {
		let converted = price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });

		if (converted.endsWith(".00")) {
			converted = converted.substring(0, converted.indexOf(".00", 0));
		}

		if(price < 1 && price > 0) {
			converted = (price * 100).toFixed(0) + "p";
		}

		return converted;
	}
}

export default LocaleHelper;