import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Team, TeamMember, CreateTeam } from "@models/team";

import store from "store2";

import * as moment from "moment";
import { SelectOption } from "@models/forms";

import { Article, CreateArticle, NewsItem } from "../models/article";
import { DatatableQuery, DatatableResponse } from "@models/query";
import * as qs from "query-string";
import RestHelper from "@helpers/rest.helper";
import { ArticleFilterOptions } from "@models/filters/filterOptions";
import { PagedList } from "@models/paging";

export class ArticleService {

	constructor() {
		//
	}

	public static get(query: ArticleFilterOptions): Promise<PagedList<Article>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`articles`, {
				params: query,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getFeatured(query: ArticleFilterOptions): Promise<PagedList<Article>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`articles/featured`, {
				params: query,
				paramsSerializer: params => qs.stringify(params)
			}).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getById(articleId: number): Promise<Article> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `articles/${articleId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Article, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getBySlug(slug: string): Promise<Article> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
				},
				url: AppConfig.apiUrl + `articles/${slug}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Article, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static data(query:DatatableQuery, deleted: boolean = null, shared: boolean = null, external: boolean = null): Promise<DatatableResponse<Article>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `articles/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(deleted ? (shared ? external ? {query, deleted, shared, external} : {query, deleted, shared} : external ? {query, deleted, external} : {query, deleted}) : (shared ? external ? {query, shared, external} : {query, shared} : external ? {query, external} : {query})),
				success: (data:DatatableResponse<Article>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(article: CreateArticle): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `articles/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(article),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(article: Article): Promise<Article> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `articles/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(article),
				success: (data:Article, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(articleId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `articles/${articleId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static GetFeed(): Promise<NewsItem[]>{
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
				},
				url: AppConfig.apiUrl + `articles/feed`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:NewsItem[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static restore(articleId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`articles/${articleId}/restore`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static feature(articleId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.get<number>(`articles/${articleId}/feature`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static acceptUpdate(articleId: number): Promise<Article> {
		return new Promise((resolve, reject) => {
			RestHelper.get<Article>(`articles/${articleId}/accept`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
