import * as React from "react";

import Select from "react-select";
import { string } from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

interface IExtendedButton {
	text?: string;
	buttonText?: string;
	className?: string;
	onClick?: (e?) => void;
	enabled?: boolean;
	icon?: string;
	id?: string;
	tooltip?: string;
	children?: any;
	style?: React.CSSProperties;
}

export class ExtendedButton extends React.Component<IExtendedButton, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		enabled: true,
		className: "btn"
	};

	componentDidMount() {
		//
	}

	componentWillUnmount() {
	}

	onClick() {
		if(this.props.onClick) {
			this.props.onClick();
		};
	}

	render() {
		return (
			<button type="button" className={this.props.className} style={this.props.style} id={`${this.props.id}`} onClick={this.onClick.bind(this)}>
				{this.props.icon ? <div className="tx-20"><i className={`icon ${this.props.icon}`}></i></div> : undefined}
				{
					this.props.tooltip ?
						<UncontrolledTooltip placement="top" target={`${this.props.id}`} delay={{ show: 400, hide: 100 }}>
							{ReactHtmlParser(this.props.tooltip)}
						</UncontrolledTooltip> : undefined
				}
				{this.props.children}
			</button>
		);
	}
}
