import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { VenueService } from "@services/venue.service";
import { Venue } from "@models/venue";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { StaticMap } from "@components/controls/StaticMap";
import { StaticMarker } from "@components/controls/StaticMarker";

const containerStyle = {
	width: '100%',
	height: '300px',
	marginbottom: '300px',
  };

interface IVenueViewPageProps {
	site?: string;
	match?: any;
}

interface IVenueViewPageState {
	loading: boolean;
	nextPathname?: any;
	venue?: Venue;
}

export class VenueViewPage extends React.Component<IVenueViewPageProps, IVenueViewPageState> {

	private venueId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	componentDidMount() {
		//
		this.venueId = this.props.match.params.venueId as number;

		this.load();
	}

	componentDidUpdate(props: IVenueViewPageProps, state: IVenueViewPageState) {
		this.venueId = this.props.match.params.venueId as number;

		if (props.match.params.venueId != this.venueId) {
			this.load();
		}
	}

	load() {
		VenueService.getById(this.venueId).then(venue => {
			this.setState({ venue, loading: false });
		});
	}

	public render() {
		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" style={{ backgroundImage: `url('${AppConfig.baseUrl}image/${this.state.venue.headerId}')` }}>
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{this.state.venue.name}</h1>
																			<p className="header--description">{this.state.venue.addressLine1}, {this.state.venue.postcode}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{this.state.venue.name}</h1>
													<p className="header--description">{this.state.venue.addressLine1}, {this.state.venue.postcode}</p>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="row">
										<div className="col-lg col-md-12 mb-4">
											<div className="content">
												<h5>Directions</h5>
												<article id="post-136" className="mb-4 post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league">
													<div className="post_content post_content_single entry-content">
														<div>
															{this.state.venue.directions ? ReactHtmlParser(this.state.venue.directions) : <p className="m-0">No details available</p>}
														</div>
													</div>
												</article>
												<h5>Parking</h5>
												<article id="post-136" className="mb-4 post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league">
													<div className="post_content post_content_single entry-content">
														<div>
															{this.state.venue.parking ? ReactHtmlParser(this.state.venue.parking) : <p className="m-0">No details available</p>}
														</div>
													</div>
												</article>
												<h5>Instructions</h5>
												<article id="post-136" className="mb-4 post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league">
													<div className="post_content post_content_single entry-content">
														<div>
															{this.state.venue.instructions ? ReactHtmlParser(this.state.venue.instructions) : <p className="m-0">No details available</p>}
														</div>
													</div>
												</article>
											</div>
										</div>
										<div className="col-lg-4 col-md-12 right mb-4">
											<div className="sidebar widget_area sidebar_below">
												<div className="sidebar_inner">
													<div className="row">
														{
															this.state.venue.latitude && this.state.venue.longitude && <div className="col-12 col-md-6 col-lg-12 mb-4">
																<aside className="widget widget_recent_posts">
																	<h5 className="widget_title">Map</h5>
																	{/* <LoadScript googleMapsApiKey={AppConfig.googleApi}>
																		<GoogleMap mapContainerStyle={containerStyle}
																			center={{ lat: parseFloat(this.state.venue.latitude), lng: parseFloat(this.state.venue.longitude) }}
																			zoom={15}>
																			<Marker position={{ lat: parseFloat(this.state.venue.latitude), lng: parseFloat(this.state.venue.longitude) }} />
																		</GoogleMap>
																	</LoadScript> */}
																	<StaticMap
																		mapContainerStyle={containerStyle}
																		center={{ lat: parseFloat(this.state.venue.latitude), lng: parseFloat(this.state.venue.longitude) }}
																		zoom={15}
																		googleLink={true}
																		googleMapsApiKey={AppConfig.googleApi}>
																		<StaticMarker position={{ lat: parseFloat(this.state.venue.latitude), lng: parseFloat(this.state.venue.longitude) }}/>
																	</StaticMap>
																</aside>
															</div>
														}
														<div className="col-12 col-md-6 col-lg-12 mb-4">
															<aside className="widget widget_recent_posts">
																<h5 className="widget_title">Address</h5>
																{this.state.venue.addressLine1 ? <span className="d-block">{this.state.venue.addressLine1}</span> : undefined}
																{this.state.venue.addressLine2 ? <span className="d-block">{this.state.venue.addressLine2}</span> : undefined}
																{this.state.venue.addressLine3 ? <span className="d-block">{this.state.venue.addressLine3}</span> : undefined}
																{this.state.venue.town ? <span className="d-block">{this.state.venue.town}</span> : undefined}
																{this.state.venue.county ? <span className="d-block">{this.state.venue.county}</span> : undefined}
																{this.state.venue.postcode ? <span className="d-block">{this.state.venue.postcode}</span> : undefined}
																{this.state.venue.information ? <span className="d-block">{this.state.venue.information}</span> : undefined}
															</aside>
														</div>
														<div className="col-12 col-md-6 col-lg-12 mb-4">
															<aside className="widget widget_recent_posts">
																<h5 className="widget_title">Contacts</h5>
																{this.state.venue.contact ? <span className="d-block tx-bold">{this.state.venue.contact}</span> : undefined}
																{this.state.venue.email ? <a className="d-block" href={`mailto:${this.state.venue.email}`}>{this.state.venue.email}</a> : undefined}
																{this.state.venue.telephone ? <span className="d-block">{this.state.venue.telephone}</span> : undefined}
																{this.state.venue.website ? <a className="d-block" href={this.state.venue.website}>{this.state.venue.website}</a> : undefined}
															</aside>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout>);
	}
}
