import DraggableSection from "./elements/DraggableSection";
import DraggableRow from "./elements/DraggableRow";
import DraggableContent from "./elements/DraggableContent";
import DraggableMemberships from "./elements/DraggableMemberships";
import DraggableArticles from "./elements/DraggableArticles";
import DraggableMap from "./elements/DraggableMap";
import DraggableWidget from "./elements/DraggableWidget";
import DraggableBlock from "./elements/DraggableBlock";
import DraggableResults from "./elements/DraggableResults";
import DraggableMailList from "./elements/DraggableMailList";
import DraggableSocialMedia from "./elements/DraggableSocialMedia";

export const elements = {
	TEXTBOX: "TEXTBOX",
	SLIDER: "SLIDER",
	HEADER: "HEADER",
	DROPDOWN: "DROPDOWN",
	CONTAINER: "CONTAINER",
	SECTION: "SECTION",
	CONTENT: "CONTENT",
	VIDEO: "VIDEO",
	MEMBERSHIPS: "MEMBERSHIPS",
	ARTICLES: "ARTICLES",
	MAP: "MAP",
	WIDGET: "WIDGET",
	BLOCK: "BLOCK",
	RESULTS: "RESULTS",
	MAILLIST: "MAILLIST",
	SOCIALS: "SOCIALS"
};

export const palette = [{
	type: elements.CONTAINER,
	component: DraggableRow
},{
	type: elements.SECTION,
	component: DraggableSection
},{
	type: elements.CONTENT,
	component: DraggableContent
},{
	type: elements.BLOCK,
	component: DraggableBlock
},{
	type: elements.WIDGET,
	component: DraggableWidget
},{
	type: elements.MEMBERSHIPS,
	component: DraggableMemberships
},{
	type: elements.ARTICLES,
	component: DraggableArticles
},{
	type: elements.MAP,
	component: DraggableMap
},{
	type: elements.RESULTS,
	component: DraggableResults
},{
	type: elements.MAILLIST,
	component: DraggableMailList
},{
	type: elements.SOCIALS,
	component: DraggableSocialMedia
}];