import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { Tenant } from "@models/tenant";
import { CreateCustomWebsiteMenuItem, WebsiteCustomMenuItem, SiteActivity, Website, WebsiteContent, WebsiteContentType, WebsiteImage, WebsiteMenuType, WebsiteMenuPreset, WebsiteTemplate } from "@models/website";

import { RestException } from "../models/exceptions";

import * as store from "store2";

import RestHelper from "@helpers/rest.helper";

import { v4 as uuidv4 } from "uuid";
import { CardNumberElementComponent } from "@stripe/react-stripe-js";

export class WebsiteService {

	constructor() {
		// 
	}

	public static getByHost(): Promise<Website> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
				},
				url: AppConfig.apiUrl + `websites/host`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Website, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(tenantId: number): Promise<Website> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `websites/${tenantId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: Website, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(website: Website): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `websites/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(website),
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateImage(image: WebsiteImage): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `websites/images/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(image),
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static deleteImage(imageId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `websites/images/${imageId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data: boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getOrSetVisitorId(): Promise<string> {
		return new Promise((resolve, reject) => {
			let visitorId = store.get("visitor");

			if (!visitorId) {
				visitorId = uuidv4();
				store.set("visitor", visitorId);
			}

			resolve(visitorId);
		});
	}

	public static getActivity(start: string, end: string): Promise<SiteActivity[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`websites/activity/${start}/${end}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getTotalActivity(start: string, end: string): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`websites/activity/total/${start}/${end}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static updateActivity(name: string): Promise<number> {
		return new Promise(async (resolve, reject) => {
			const visitorId = await WebsiteService.getOrSetVisitorId();
			RestHelper.post(`websites/activity`, { visitorId, name }).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getContent(type: WebsiteContentType): Promise<WebsiteContent> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/content/${type}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getMenu(): Promise<WebsiteCustomMenuItem[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/menu/custom`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsertContent(content: WebsiteContent): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`websites/content`, content)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getCustomMenuItems(type: WebsiteMenuType, parentId: number = null): Promise<WebsiteCustomMenuItem[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(parentId ? `websites/menu/${parentId}/submenu` : `websites/menu/${type}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getCustomMenuByPreset(tenantId: number, preset: WebsiteMenuPreset): Promise<WebsiteCustomMenuItem> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/menu/${tenantId}/${preset}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static searchCustomMenuItems(search: string, type: WebsiteMenuType): Promise<WebsiteCustomMenuItem[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`websites/menu/${type}/search`, { search })
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static createCustomMenuItem(menuItem: CreateCustomWebsiteMenuItem): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`websites/menu/custom/create`, menuItem)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static updateCustomMenuItem(menuItem: WebsiteCustomMenuItem): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`websites/menu/custom/update`, menuItem)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static removeCustomMenuItem(menuItemId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/menu/custom/${menuItemId}/delete`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static updateCustomMenuOrdering(menuItems: WebsiteCustomMenuItem[]): Promise<boolean> {
		menuItems.forEach(i => i.parent = null);
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`websites/menu/custom/update/ordering`, menuItems)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getTemplates(): Promise<WebsiteTemplate[]> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/templates`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getTemplateById(id: number): Promise<WebsiteTemplate> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/template/${id}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static upsertTemplate(template: WebsiteTemplate): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.post(`websites/template`, template)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static deleteTemplate(id: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`websites/template/${id}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static getTwitterAccount(screenName: string): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper
				.get(`websites/twitter/account/${screenName}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}
}
