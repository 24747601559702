export class ObjectHelper {
	public static shallowCopy(source) {
		return JSON.parse(JSON.stringify(source));
	}

	public static get(s:any, property:string): any {
		return property.split(".").reduce(function(o,x) {
			return (typeof o == "undefined" || o === null) ? o : o[property];
		}, s);
	}

	public static isNull(p:any): boolean {
		if(p == undefined || p == null)
			return true;

		return false;
	}

	public static isNotNull(s:any, property: string = ""): boolean {
		return property.split(".").every(function(x) {
			if(typeof s != "object" || s === null || !(x in s))
				return false;
			s = s[property];
			return true;	
		});
	}

	public static ifNotNull<T>(s:T, m:(t:T)=> any, defaultValue: any = null, property: string = ""): any {
		if(property) {
			return property.split(".").every(function(x) {
				if(typeof s !== "object" || s === null || !(x in s)){
					return null;
				}
				s = s[property];
				return m(s);
			});
		}
		else {
			return (typeof s == undefined || s == null || s == undefined) ? (defaultValue != null ? defaultValue : undefined) : m(s);
		}
	}
}
export default ObjectHelper
