import { Contact } from "./contact";
import { CustomEmailType } from "./email";
import { User } from "./user";

export class Membership {
	id: number;
	name: string;
	startDate: Date | null;
	endDate: Date | null;
	usage: number | null;
	usageType: MembershipUsage | null;
	payment: MembershipPayment;
	interval: number | null;
	setupFee?: number;
	active: boolean;
	summary: string;
	benefits: string;
	body: string;
	previewId: number | null;
	headerId: number | null;
	cost: number;
	renewCost: number | null;
	upfrontCost?: number;
	upfrontRenewCost?: number | null;
	payLater: boolean;
	autoPaid: boolean;
	durationType: MembershipDuration;
	duration: number;
	limit: number;
	autoRenew: boolean;
	renewId: number | null;
	eligibility: string;
	approvalRequired: boolean;
	multiUser: boolean;
	backdate: boolean;
	deleted: string | null;
	termsUrl: string;
	termsText: string;
	visible: boolean;
	published?: Date;
	ordering: number;

	emailType: CustomEmailType;
	emailIntro: string;
	emailBody: string;
}

export class MembershipUser {
	id: number;
	membershipId: number;
	membership: Membership;
	contactId: number;
	contact: Contact;
	parentId: number | null;
	parent: MembershipUser;
	joined: Date;
	start: Date;
	end?: Date;
	confirmed: string | null;
	usage: number | null;
	status: MembershipStatus;
	cost: number;
	setupFee: number;
	due: Date;
	agreedTerms: string | null;
	approved: boolean;
}

export class AssignMembership {
	public contactId: number;
	public linked: number[];
	public membershipId: number;
	public autorenew: boolean;
	public cost: number;
	public start: Date;
	public paid: boolean;
	public upfront?: boolean;
}

export class MembershipCategory {
	id: number;
}

export enum MembershipStatus {
	Active = 1,
	Paused = 2,
	Cancelled = 3,
	Overdue = 4,
	Pending = 5,
	Warning = 6,
	Expired = 7
}

export enum MembershipDuration {
	Days = 1,
	Weeks = 2,
	Months = 3,
	Years = 4,
	FixedTerm = 5
}

export enum MembershipUsage {
	Weekly = 1,
	Monthly = 2,
	Expire = 3,
	Unlimited = 4
}

export enum MembershipPayment {
	OneOff = 1,
	Week = 2,
	Month = 3,
	Year = 4,
	Day = 5
}

export enum CostType {
	Period = 1,
	Upfront = 2
}