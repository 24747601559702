import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { Row, Col } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { MatchGrouping, MatchGroupRange, Match, MatchGroupType, MatchFilters, TemplateAccess } from "@models/match";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { MatchService } from "@services/match.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AddToCalendar } from "@components/controls/AddToCalendar";
import { AppCache } from "@cache";

import * as queryString from "query-string";
import { SelectOption } from "@models/forms";
import { CompetitionFilterOptions } from "@models/filters";
import { Display, Options } from "@data";
import { confirmAlert } from "react-confirm-alert";
import { Tenant, TenantDetails, TenantType } from "@models/tenant";
import { WebsiteCompetitionData, WebsiteVisibility } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";
import { WebsiteService } from "@services/website.service";
import { FreeSlots } from "@models/week";
import { Sidebar } from "rsuite";
import { MatchCardTemplatePage } from "../divisions/MatchCardTemplatePage";
import { TenantFilterOptions } from "@models/filters/filterOptions";
import { TenantService } from "@services/tenant.service";
import { PagedList } from "@models/paging";
import { DelayInput } from "react-delay-input";

const dataFormat = ["table", "results", "averages", "potms", "rankings"];

interface ISearchPageProps {
	site?: string;
	match?: any;
	type?: TenantType;
}

interface ISearchPageState {
	loading: boolean;
	nextPathname?: any;
	tenants?: PagedList<TenantDetails>;
	showFilters: boolean;
	options: TenantFilterOptions;
	searching: boolean;
}
export class SearchPage extends React.Component<ISearchPageProps, ISearchPageState> {

	constructor(props) {
		super(props);

		const qs = queryString.parse(location.search);
		const term = qs.term as string;

		this.state = { loading: true, showFilters: false, searching: false, options: { page: 1, count: 20, type: props.type, search: term } };
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: ISearchPageProps, state: ISearchPageState) {
		if(this.props.type !== props.type) {
			this.setState({options: {...this.state.options, type: this.props.type}}, ()=> this.load());
		}
	}

	load() {
		this.setState({ searching: true });
		NProgress.start();

		TenantService.search(this.state.options).then(tenants => {
			this.setState({ searching: false, loading: false, tenants }, () => {
				NProgress.done();
			});
		});
	}

	select(match: Match) {
	}

	reset = () => (e: any) => {
		
	}

	next = () => async () => {
		NProgress.start();

		const page = this.state.options.page + 1;
		const result = await TenantService.search({ ...this.state.options, page });

		const tenants = this.state.tenants;
		tenants.page = page;
		result.items.forEach(e => tenants.items.push(e));

		this.setState({ tenants, options: { ...this.state.options, page } });

		NProgress.done();
	}

	toggleFilters = () => (e: any) => {
		this.setState({ showFilters: !this.state.showFilters });
	}

	handleSearchChange = () => (e: any) => {
		this.setState({ options: { ...this.state.options, search: e.target.value } }, () => {
			this.load();
		});
	}

	public render() {
		return (
			<PublicLayout
				className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<section className="">
											<div className="tenant-list">
												{/* <Row className="mb-3">
													<Col className="text-right">
														<a id="filters" className="clickable mr-2" onClick={this.toggleFilters()}><i className={`fal fa-filter tx-24`}></i></a>
													</Col>
												</Row> */}
												<Row className="mb-3">
													<Col>
														<DelayInput type="text" delayTimeout={300} value={this.state.options.search} className="w-100" placeholder="Enter name" onChange={this.handleSearchChange()} />
													</Col>
												</Row>
												<div className="row">
													<div className="col-12">
														<nav className="navbar navbar-submenu navbar-expand-lg">
															<div className="container">
																<ul className="navbar-nav">
																	<li className="text-white">
																		<Link to={`/search/all`} className={!this.state.options.type && "active"}>All</Link>
																	</li>
																	<li className="text-white">
																		<Link to={`/search/leagues`} className={this.state.options.type === TenantType.League && "active"}>Leagues</Link>
																	</li>
																	<li className="text-white">
																		<Link to={`/search/clubs`} className={this.state.options.type === TenantType.Club && "active"}>Clubs</Link>
																	</li>
																	<li className="text-white">
																		<Link to={`/search/counties`} className={this.state.options.type === TenantType.County && "active"}>Counties</Link>
																	</li>
																</ul>
															</div>
														</nav>
													</div>
												</div>
												{
													this.state.showFilters &&
													<div>
														{/* <div className="row">
																<div className="col-4 mb-3">
																	<label>Type</label>
																	<Select
																		options={Options.matchGroupTypes}
																		value={this.type}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.type, () => this.typeId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Division</label>
																	<Select
																		isClearable
																		options={this.getDivisionOptions()}
																		value={this.division}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.division, () => this.divisionId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Venue</label>
																	<Select
																		isClearable
																		options={this.getVenueOptions()}
																		value={this.venue}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.venue, () => this.venueId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Clubs</label>
																	<Select
																		isClearable
																		options={this.getClubOptions()}
																		value={this.club}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.club, () => this.clubId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Teams</label>
																	<Select
																		isClearable
																		options={this.getTeamOptions()}
																		value={this.team}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.team, () => this.teamId)}
																		closeMenuOnSelect={true} />
																</div>
																<div className="col-4 mb-3">
																	<label>Date</label>
																	<Select
																		isClearable
																		options={this.state.dateOptions}
																		value={this.date}
																		defaultOptions
																		onChange={this.handleChangeSelect(() => this.date, () => this.dateValue)}
																		closeMenuOnSelect={true} />
																</div>
															</div> */}
														<div className="row mt-3 mb-5">
															<div className="col-12 text-center">
																<a className="btn btn-primary btn-rounded text-white clickable" onClick={this.reset()}>Reset</a>
															</div>
														</div>
													</div>
												}
												{
													this.state.tenants.items.map(t => (
														<div key={`tenant-${t.id}`} className="card fixture mb-3 p-3">
															<Row>
																<Col className="text-left">
																	<p className="m-0">
																		<a className="team-logo logo-odd" href={`https://${t.host}`} title={t.name}>
																			<strong className="team-name">{t.name}</strong>
																		</a>
																		{
																			!this.state.options.type && <span className="badge badge-primary align-self-center ml-2">
																				{t.clubId && <span>Club</span>}
																				{t.leagueId && <span>League</span>}
																				{t.countyId && <span>County</span>}
																			</span>
																		}
																	</p>
																	{
																		t.website.location && <span className="d-block tx-12">{t.website.location}</span>
																	}
																	{
																		t.website.description && <p className="m-0 mb-2">{t.website.description}</p>
																	}
																	{
																		<span className="tx-15 text-primary mr-2"><i className="far fa-globe mr-2"></i>{t.website.config.visibility !== WebsiteVisibility.Unavailable ? <a href={`https://${t.host}`} title="Website" target="_blank" className="text-primary">Website</a> : <span>Unavailable</span>}</span>
																	}
																	{
																		t.website.mobile && <span className="tx-15 text-primary"><i className="far fa-phone mr-2"></i>{t.website.mobile}</span>
																	}
																	{
																		t.website.twitter && <a href={`https://twitter.com/${t.website.twitter}`} title="Twitter" target="_blank" className=""><span className="tx-15 text-primary m-2"><i className="fab fa-x-twitter mr-1"></i>{t.website.twitter}</span></a>

																	}
																	{
																		t.website.facebook && <a href={`https://facebook.com/${t.website.facebook}`} title="Facebook" target="_blank" className=""><span className="tx-15 text-primary m-2"><i className="fab fa-facebook mr-1"></i>{t.website.facebook}</span></a>
																	}
																</Col>
															</Row>
														</div>
													))
												}
												{
													this.state.tenants.items.length < this.state.tenants.total && (
														<div className='sp-view-all-link'>
															<a className='' onClick={this.next()}>
																Load More
															</a>
														</div>
													)
												}
											</div>
										</section>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout >
		);
	}
}
