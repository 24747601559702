import { AppConfig } from "@config";

export function download(base64) {
	const link = document.createElement('a');
	if (base64 == null) return;

	const filename = 'export.csv';

	var blob = new Blob([base64], { type: 'text/csv;charset=utf-8;' });
	var url = URL.createObjectURL(blob);

	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	link.click();
}

export function downloadUrl(url, filename) {
	const link = document.createElement('a');
	if (url == null) return;

	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	link.click();
}

export function downloadCSV(array) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = 'export.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}

function convertArrayOfObjectsToCSV(array) {
	let result;
	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(array[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];

			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

export const imageUrl = (id: number) => `${AppConfig.baseUrl}image/${id}`;