import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, FormGroup, Label, Input } from "reactstrap";
import classnames from "classnames";

import * as moment from "moment";
import * as NProgress from "nprogress";
import Sidebar from "react-sidebar";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { FieldType } from "@models/field";
import Medical from "./components/Medical";
import Comms from "./components/Comms";
import Personal from "./components/Personal";
import Bookings from "./components/Bookings";
import Memberships from "./components/Memberships";
import Invoices from "./components/Invoices";
import Linked from "./components/Linked";
import LinkContactSidebar from "@components/sidebar/public/LinkContactSidebar";
import Consents from "./components/Consents";
import Credits from "./components/Credits";

interface IProfilePageProps {
	site?: string;
	match?: any;
	location?: any;
}

interface IProfilePageState {
	loading: boolean;
	nextPathname?: any;
	submitting: boolean;
	contact?: Contact;
	activeTab: string;
	linkContact: boolean;
}

export class ProfilePage extends React.Component<IProfilePageProps, IProfilePageState> {
	private contactId?: number;

	constructor(props) {
		super(props);

		this.state = { loading: true, submitting: false, activeTab: (this.props.match?.params?.tabId ?? "1"), linkContact: false };

		this.contactId = this.props.match.params.contactId as number;
	}

	componentDidMount() {
		this.load();
	}

	componentDidUpdate(props: any, state: any) {
		//
		const contactId = this.props.match.params.contactId as number;

		if (contactId !== this.contactId) {
			this.contactId = contactId;
			this.setState({ activeTab: "1", loading: true }, () => this.load());
		}
	}

	load() {
		this.setState({ loading: true });

		if (this.contactId) {
			const p1 = ContactService.getById(this.contactId).then(contact => {
				this.setState({ contact });
			});

			Promise.all([p1]).then(result => {
				this.setState({ loading: false });
			});
		} else {
			const p1 = ContactService.getByUser().then(contact => {
				this.setState({ contact });
			});

			Promise.all([p1]).then(result => {
				if(!this.state.contact) {
					History.push("/member/profile/confirm");
				}

				this.setState({ loading: false });
			});
		}
	}

	toggle(tab: string) {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
	}

	sidebar = () => {
		if (this.state.linkContact) {
			return <LinkContactSidebar
				contact={this.state.contact}
				onConfirm={(contact?: Contact) => {
					this.setState({ loading: true }, () => this.load());
				}}
				onClose={() => this.setState({ linkContact: false })} />;
		}

		return <></>;
	}

	openSidebar = () => {
		if (this.state.linkContact) {
			return true;
		}

		return false;
	}

	public render() {
		return (
			<PublicLayout theme="scheme_default" className="profile-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<Sidebar
							sidebar={this.sidebar()}
							pullRight={true}
							open={this.openSidebar()}
							sidebarClassName="sidebar-root"
							styles={{ content: { overflowY: "visible", position: "relative", height: "100%" }, root: { position: "relative", overflow: "visible" }, sidebar: { position: "fixed", background: "white", zIndex: 9999 }, overlay: { zIndex: 999 } }}>
							<div>
								<div className="page_content_wrap">
									<div className="container">
										<Row>
											<Col xl={{ size: 10, offset: 1 }}>
												<Card className="mb-5 card--nav">
													<Row className="pt-4 px-4">
														<Col md={2}>
															<div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
																<img className="w-100" src={require("../../../../../assets/images/placeholders/profile.png")} alt="image" />
																<div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
															</div>
														</Col>
														<Col md={10}>
															<div className="name">
																<h3 className="m-0">{this.state.contact.name === "" ? this.state.contact.fullName : this.state.contact.name}</h3>
																<div>
																	<p className="text-muted d-block m-0">{this.state.contact.email}</p>
																	<p className="text-muted d-block m-0">{this.state.contact.phoneNumber}</p>
																</div>
															</div>
														</Col>
													</Row>
													<Nav>
														{AppCache.tenant.config.bookingsModule && <NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "1" })}
																onClick={() => { this.toggle('1'); }}>
																Bookings
															</NavLink>
														</NavItem>}
														{AppCache.tenant.config.bookingsModule && <NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "2" })}
																onClick={() => { this.toggle('2'); }}>
																Memberships
															</NavLink>
														</NavItem>}
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "3" })}
																onClick={() => { this.toggle('3'); }}>
																Invoices
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "4" })}
																onClick={() => { this.toggle('4'); }}>
																Personal
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "5" })}
																onClick={() => { this.toggle('5'); }}>
																Medical
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "6" })}
																onClick={() => { this.toggle('6'); }}>
																Communication
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "7" })}
																onClick={() => { this.toggle('7'); }}>
																Linked
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "8" })}
																onClick={() => { this.toggle('8'); }}>
																Consents
															</NavLink>
														</NavItem>
														{AppCache.tenant.config.bookingsModule && <NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === "9" })}
																onClick={() => { this.toggle('9'); }}>
																Credits
															</NavLink>
														</NavItem>}
													</Nav>
												</Card>
												<div>

													<TabContent activeTab={this.state.activeTab}>
														<TabPane tabId="1">
															<Row>
																<Col>
																	<Bookings contact={this.state.contact} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="2">
															<Row>
																<Col>
																	<Memberships contact={this.state.contact} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="3">
															<Row>
																<Col>
																	<Invoices contact={this.state.contact} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="4">
															<Row>
																<Col>
																	<Personal contact={this.state.contact} onConfirm={()=> this.load()} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="5">
															<Row>
																<Col>
																	<Medical contact={this.state.contact} onConfirm={()=> this.load()} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="6">
															<Row>
																<Col>
																	<Comms contact={this.state.contact} onConfirm={()=> this.load()} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="7">
															<Row>
																<Col>
																	<Linked contact={this.state.contact} />
																</Col>
															</Row>
															{
																!this.contactId &&
																<Row>
																	<Col>
																		<div className="sp-view-all-link mt-0">
																			<a onClick={() => this.setState({ linkContact: true })}>
																				Link Contact
																			</a>
																		</div>
																	</Col>
																</Row>
															}
														</TabPane>
														<TabPane tabId="8">
															<Row>
																<Col>
																	<Consents contact={this.state.contact} />
																</Col>
															</Row>
														</TabPane>
														<TabPane tabId="9">
															<Row>
																<Col>
																	<Credits contact={this.state.contact} />
																</Col>
															</Row>
														</TabPane>
													</TabContent>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</Sidebar>)} />
			</PublicLayout>);
	}
}
