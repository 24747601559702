import * as React from "react";

import { ActivitySpinner } from "@components/controls/ActivitySpinner";
import { Empty } from "@components/controls/Empty";
import { EntryType, AppConfig } from "@config";
import { AppCache } from "@cache";

interface IShowMe {
	visible: boolean;
	progress: boolean;
	isEmpty: boolean;
	emptyText: string;
	icon: string;
	mode: ShowMeMode;
	entry?: EntryType;
	onChange?: (e: any) => void;
	render?: any;
	children?: any;
}

export enum ShowMeMode {
	Inline = 1,
	Full = 2,
}

export class ShowMe extends React.Component<IShowMe, any> {

	constructor(props) {
		super(props);
	}

	static defaultProps = {
		visible: true,
		progress: false,
		isEmpty: false,
		emptyText: "",
		mode: ShowMeMode.Inline,
		icon: "fal fa-ellipsis-h"
	};

	componentDidMount() {
		//
	}

	renderChild() {
		return this.props.render ? (typeof(this.props.render) === "function" ? this.props.render() : this.props.render) : undefined
	}
	
	isVisible(): boolean {
		if(this.props.entry != null && this.props.entry !== AppConfig.entry) {
			return false;
		}
		
		return this.props.visible;
	}

	render() {
		return (
			this.isVisible() ?
				(this.props.isEmpty ? <Empty className={`${this.props.mode === ShowMeMode.Inline ? "ht-50" : "ht-600"}`} icon={this.props.icon} text={this.props.emptyText} /> : <div className="d-inline">{this.renderChild()}{this.props.children}</div>) :
				(this.props.progress ?
					(this.props.mode === ShowMeMode.Full ? <ActivitySpinner height={"600"} /> : <ActivitySpinner />)
					: null)
		);
	}
}
