import { AppConfig } from "@config";
import ISignalRService from "@interfaces/scoring/isignalrservice";
import { SignalRServiceBase } from "./signalrbase.service";
import { IDispatcher } from "@interfaces/scoring/idispatcher";
import { SignalRConnectionStatus } from "@models/enums/signalrconnectionstatus";
import { CurrentMatchStatus, MatchGame } from "@models/match";

export class ScoringHubService extends SignalRServiceBase implements ISignalRService {

	private static _instance: ScoringHubService;
	public static get instance(): ScoringHubService {
		if (ScoringHubService._instance === undefined)
			ScoringHubService._instance = new ScoringHubService();

		return ScoringHubService._instance;
	}


	constructor() {
		super();
		this.connection = this.buildConnection(`${AppConfig.baseUrl}scoreboardhub`);
		this.connection
		this.initializeConnectionEvents();
		this.subscribeEvents();
	}
	
	OnServiceConnectivityChanged(callback: (status: SignalRConnectionStatus, message: string) => void): IDispatcher {
		throw new Error("Method not implemented.");
	}
	

	public cleanup() {
		this.unsubscribeEvents();
	}

	public subscribeSignalR = (methodName: string, callback: (data: any) => void) => {
		this.connection.on(methodName, callback);
		return { off: () => this.connection.off(methodName, callback) };
	}

	// Outgoing Server Notifications

	public SendMessage(username: string, message: string): Promise<void> {
		return this.connection.send("newMessage", username, message);
	}

	sendGameData(liveGame: MatchGame){
		this.connection
			.invoke("SendData", liveGame)
			.catch(err => console.error(err.toString()));
	}

	sendMatchStatus(matchStatus: CurrentMatchStatus){
		this.connection
			.invoke("SendStatus", JSON.stringify(matchStatus))
			.catch(err => console.error(err.toString()));
	}

	sendGetStatus(){
		this.connection
			.invoke("GetStatus")
			.catch(err => console.error(err.toString()));
	}

	sendGetGame(){
		this.connection
			.invoke("GetGame")
			.catch(err => console.error(err.toString()));
	}

	GetGroupState(){
		this.connection
			.invoke("GroupState")
			.catch(err => console.error(err.toString()));
	}

	clearGameData(){
		this.connection
			.invoke("SendEnd")
			.catch(err => console.error(err.toString()));
	}

	getGroups(id: number){
		this.connection
			.invoke("GetGroups", id)
			.catch(err => console.error(err.toString()));
	}

	CheckConnections(){
		this.connection
			.invoke("CheckConnections")
			.catch(err => console.error(err.toString()));
	}

	ConfirmConnection(){
		this.connection
			.invoke("ConfirmConnection")
			.catch(err => console.error(err.toString()));
	}
	
	// /Outgoing Server Notifications


	// Incoming Server Notifications


	// public OnErrorMessageReceived = (callback: (error: string) => void) => {
	// 	return this.subscribeAndReturnSignalRCleanupFunction("errorMessageReceived", callback);
	// }

	// /Incoming Server Notifications
}