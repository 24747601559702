import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { Division, CreateDivision } from "@models/division";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";
import { Standing } from "@models/standing";
import { MatchGrouping, MatchCard, CreateMatch, MatchStatus, Match, MatchGroupRange, MatchGroupType, MatchGroup, MatchSet, PotmType, MatchFilters, MatchResults, MatchCardEntryLevel, MatchReport, UpdateMatch, MatchSchedule } from "@/models/match";
import { Entrant } from "@models/entrant";
import { TeamMember } from "@models/team";
import { MatchGame } from "../models/match";
import { ActionEntrant, AddComment, MatchAction } from "../models/action";

import RestHelper from "@helpers/rest.helper";
import * as queryString from "query-string";
import { Action } from "@fullcalendar/core";
import { CompetitionDay, ReserveFilter } from "@models/competition";
import { Reserve } from "@models/reserve";
import { FreeSlots } from "@models/week";

export class MatchService {

	constructor() {
		//
	}

	public static getMatch(matchId: number): Promise<Match> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:Match, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getSets(matchId: number): Promise<MatchSet[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/sets`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchSet[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getResults(matchId: number): Promise<MatchResults> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`matches/${matchId}/results`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static division(divisionId: number): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static matchCard(matchId: number): Promise<MatchCard> {
		return new Promise((resolve, reject) => {
			RestHelper.get<MatchCard>(`matches/${matchId}/card`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static filter(filters: MatchFilters): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			const qs = filters ? queryString.stringify(filters) : "";

			RestHelper.get(`matches/?${qs}`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static upcoming(divisionId: number): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/upcoming`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static results(divisionId: number): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/results`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static memberUpcoming(): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/member/upcoming`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static memberResults(): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/member/results`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static awaiting(competitionId: number): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competition/${competitionId}/awaiting`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {score: false},
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getAllAwaiting(): Promise<Match[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/awaiting`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {score: false},
				success: (data:Match[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getAllUnscheduled(): Promise<Match[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/unscheduled`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {score: false},
				success: (data:Match[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static outstanding(status?: MatchStatus, competitionId?: number): Promise<Match[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/outstanding`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {competitionId, status},
				success: (data:Match[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static opponents(divisionId: number, entrantId: number, home: boolean): Promise<Entrant[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/match/opponents`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {entrantId, home},
				success: (data:Entrant[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static create(match: CreateMatch): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(match),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static update(match: UpdateMatch): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(match),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static swap(matchId: number, all: boolean): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/swap${all ? "/all" : ""}`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static skip(divisionId: number, weekNo: number, title: string): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/division/${divisionId}/week/${weekNo}/skip`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({title}),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateSkip(divisionId: number, weekNo: number, title: string, description: string): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/division/${divisionId}/week/${weekNo}/skip/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({title , description}),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static slots(divisionId: number): Promise<FreeSlots[]> { 
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/division/${divisionId}/slots/free`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:FreeSlots[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateMatchPlayer(matchPlayerId: number, matchuserId: string): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/update/player`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({matchPlayerId, matchuserId}),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateSetPlayer(setPlayerId: number, setUserId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/update/set`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({setPlayerId, setUserId}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updateGame(game: MatchGame): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/update/game`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(game),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static status(matchId: number, status: MatchStatus): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/status/${status}`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static members(matchId: number, teamId: number): Promise<Entrant[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/members`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {teamId},
				success: (data:Entrant[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static generate(divisionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/generate`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static progress(divisionId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/generateprogress`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:any, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static progressReset(divisionId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/resetprogress`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:any, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static progressAll(competitionId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `competitions/${competitionId}/matches/generateprogress`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:any, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static reset(divisionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/reset`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static resetFixtures(divisionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/fixtures/reset`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static resetMatchCards(divisionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matchcards/reset`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static resetTableNumbers(divisionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/tablenumbers/reset`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static resetTableNumbersDay(divisionId: number, date: Date): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`/divisions/${divisionId}/tablenumbers/reset/day`, date).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static schedule(divisionId: number, homeId: number, awayId: number): Promise<MatchSchedule[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post<MatchSchedule[]>(`/divisions/${divisionId}/matches/schedule`, {homeId, awayId}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static resetMatch(matchId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/reset`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static resetSet(setId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/set/${setId}/reset`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static delete(matchId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/delete`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static deleteByes(divisionId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.delete<boolean>(`/division/${divisionId}/matches/byes`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static forfeitMatch(matchId: number, entrantId: number, reason: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/entrant/${entrantId}/forfeit`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({reason}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static forfeitPlayer(matchId: number, playerId: number, reason: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/forfeit/player/${playerId}`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({reason}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static abandonMatch(matchId: number, reason: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/abandon`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({reason}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static updatePotm(matchId: number, potmId: string, type: PotmType): Promise<boolean> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/potm/`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({matchId, potmId, type}),
				success: (data:boolean, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static scratch(setId: number, entrantId: number, type: number, reason: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<boolean>(`matches/set/${setId}/entrant/${entrantId}/scratch`, {type, reason}).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static weeksDays(divisionId: number, type: MatchGroupType, weeks?: number, days?: number): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `divisions/${divisionId}/matches/latest`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				data: {divisionId, weeks, type, days},
				success: (data:MatchGrouping, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getActionCount(matchId: number, entrantId: number): Promise<ActionEntrant> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`matches/${matchId}/actions/entrant`, { entrantId }).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static GetActions(matchId: number): Promise<MatchAction[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${matchId}/actions`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchAction[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static AddAction(action: MatchAction): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/action/create`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(action),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static GetAction(actionId: number): Promise<MatchAction> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/${actionId}/action`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data:MatchAction, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static UpdateAction(model: MatchAction): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/action/update`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(model),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static AddActionComment(comment: AddComment): Promise<number> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `matches/action/comment`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify(comment),
				success: (data:number, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static latest(take: number, type: MatchGroupType): Promise<MatchGrouping> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`matches/latest?take=${take}&type=${type}`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getEntryLevel(matchId: number): Promise<MatchCardEntryLevel> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`matches/${matchId}/entry`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static getReport(matchId: number): Promise<MatchReport> {
		return new Promise((resolve, reject) => {
			RestHelper.get<MatchReport>(`matches/${matchId}/report`).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static updateReport(report: MatchReport): Promise<MatchReport> {
		return new Promise((resolve, reject) => {
			RestHelper.post<MatchReport>(`matches/${report.matchId}/report`, report).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static removeReport(report: MatchReport): Promise<MatchReport> {
		return new Promise((resolve, reject) => {
			RestHelper.post<MatchReport>(`matches/${report.matchId}/report/remove`, report).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static hideReport(report: MatchReport): Promise<MatchReport> {
		return new Promise((resolve, reject) => {
			RestHelper.post<MatchReport>(`matches/${report.matchId}/report/hide`, report).then(result=> {
				resolve(result.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static reserveOptions(competitionId: number , ReserveFilters: ReserveFilter): Promise<Reserve[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post<Reserve[]>(`competition/${competitionId}/reserves/options`, ReserveFilters).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static matchDates(competitionId: number, divisionIds: number[]): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper.post<any[]>(`matches/competition/${competitionId}/dates`, divisionIds).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static matchRounds(competitionId: number, divisionId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper.get<any>(`matches/competition/${competitionId}/division/${divisionId}/rounds`).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static repairSetPlayers(matchId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper.get<any>(`maintenance/${matchId}/repair/set/players`).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static repairCompetitionSetPlayers(competitionId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper.get<any>(`maintenance/competition/${competitionId}/repair/set/players`).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static play(matchId): Promise<any> {
		return new Promise((resolve, reject) => {
			RestHelper.get<any>(`matches/${matchId}/play`).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static roundOptions(stageId): Promise<number[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<number[]>(`matches/stage/${stageId}/rounds`).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}

	public static updateTeamHandicap(model: any): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post<any>(`matches/${model.matchId}/handicaps`, model).then(results => {
				resolve(results.data);
			}).catch(error=> {
				reject();
			});
		});
	}
}
