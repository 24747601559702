import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import classnames from "classnames";

import { AppConfig } from "@config";

import history from "@helpers/history.helper";
import * as moment from "moment";
import * as NProgress from "nprogress";

import { ManageLayout } from "@components/layouts/ManageLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { Toggle } from "@components/controls/Toggle";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import { ProgressButton, CircularProgressButton } from "@components/controls/ProgressButton";

import History from "@helpers/history.helper";

import { Member } from "@models/member";
import { Match, MatchGroup, MatchGrouping, MatchCard, MatchSet, MatchPlayer, MatchDoubles, MatchGame, MatchStatus, PotmType, CurrentMatchStatus } from "@models/match";
import { Standing } from "@models/standing";

import { DatatableQuery, DatatableMeta } from "@models/query";
import { MemberService } from "@services/member.service";

import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { LeagueService } from "@services/league.service";
import { CompetitionService } from "@services/competition.service";
import { MatchService } from "@services/match.service";
import { VenueService } from "@services/venue.service";
import { TeamService } from "@services/team.service";
import { Competition, CreateCompetition, PlayerLabels, ResultDisplay } from "@models/competition";

import Sidebar from "react-sidebar";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { SelectOption } from "@models/forms";
import { Venue } from "@models/venue";

import { confirmAlert } from "react-confirm-alert"; // Import
import { DivisionService } from "@services/division.service";
import { CreateDivision, Division } from "@models/division";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { TeamMember } from "@models/team";
import { number, elementType } from "prop-types";
import { has } from "store2";
import { MatchEntrant, MatchMember } from "../../../models/match";

import * as Queue from "promise-queue";
import ObjectHelper from "@helpers/object.helper";
import { Can } from "@components/controls/Authentication";
import { Options } from "@data";
import { MatchCardBase, Doubles, Player } from "@components/shared/MatchCardBase";
import { MatchCardTemplatePage } from "./MatchCardTemplatePage";

import * as signalR from "@microsoft/signalr";
import { dictionary } from "@components/dataTypes/dictionary";
import { ScoringHubService } from "@services/hubs/scoringhub.service";
import { IDispatcher } from "@interfaces/scoring/idispatcher";
import * as OwlCarousel from "react-owl-carousel2";

interface IMatchCardScoreBoardProps {
	site?: string;
	match?: any;
}

interface IMatchCardScoreBoardState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	liveGame?: MatchGame;
	liveSet?: MatchSet;
	matchCard?: MatchCard;
	hubConnection?: signalR.HubConnection;
	index: number;
	gameEnd: boolean;
	groupNames?: dictionary<string,number>[];
	channelId?: string;
	groupName?: string;
	endMatch: boolean;
	timeout: boolean;
	timeoutHome: boolean;
	matchId?: number;
	gameId?: number;
}

export class MatchCardScoreBoard extends React.Component<IMatchCardScoreBoardProps, IMatchCardScoreBoardState> {
	private matchCard: MatchCard;
	interval: NodeJS.Timer;

	private signalREvents: IDispatcher[];

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			index: 0,
			gameEnd: false,
			endMatch: false,
			timeout: false,
			timeoutHome: false,
		};
	}

	componentDidMount() {
		var channelId = sessionStorage.getItem("channelId");
		const divisionId = this.props.match.params.divisionId as number;
		const competitionId = this.props.match.params.competitionId as number;

		const p1 = DivisionService.getById(divisionId).then(division => {
			this.setState({ division }, () => {

			});
		});

		const p2 = CompetitionService.getById(competitionId).then(competition => {
			this.setState({ competition }, () => {

			});
		});

		Promise.all([p1, p2]).then(result => {
			if (channelId != null){
				ScoringHubService.instance.Start(channelId).then(() => {
					this.signalREvents = [
						ScoringHubService.instance.subscribeSignalR("checkConnectionsRecieved", () => {
							ScoringHubService.instance.ConfirmConnection();
						}),
						ScoringHubService.instance.subscribeSignalR("dataReceived", (data:MatchGame) => {
							var game = new MatchGame;
							var set = new MatchSet;
							if (data != null) {
								set = this.state.matchCard.sets.find(s => s.games.some(g => g.id == data.id));
								game = data;
							}
							else {
								game = null;
								set  =null;
							}
							this.setState({ liveGame: game, liveSet: set});
						}),
						ScoringHubService.instance.subscribeSignalR("statusReceived", (data:CurrentMatchStatus) => {
							if(data.id == 0){
								data.id = null;
							}
							this.setState({gameEnd: data.gameEnd, matchId: data.id, endMatch: data.matchEnd, timeout: data.timeout, timeoutHome: data.timeoutHome}, () => {
								this.load();
							});
						}),
						ScoringHubService.instance.subscribeSignalR("endReceived", () => {
							this.load();
							this.setState({ liveGame: null, liveSet: null});
						}),
					];
					ScoringHubService.instance.sendGetStatus();
				});
			}
			else{
				var hubConnection = new signalR.HubConnectionBuilder()
					.withUrl(`${AppConfig.baseUrl}scoreboardhub`, {withCredentials: false})
					.configureLogging(signalR.LogLevel.Information)
					.build();
				this.setState({hubConnection}, () => {
					this.state.hubConnection.start().then(() => {
						if (this.state.hubConnection.state == signalR.HubConnectionState.Connected){
							this.state.hubConnection.on("groupsRecieved", (data: dictionary<string,number>[]) => {
								if(this.state.groupNames != data){
									this.setState({groupNames: data});
								}
							});
							this.state.hubConnection.invoke("CheckConnections");
							this.interval = setInterval(() => 
							{
								this.state.hubConnection.invoke("GetGroups", this.state.division.id)
								.catch(err => console.error(err.toString()));
							}, 1500);
						}
					}).catch(error => {
						console.log("SignalR:");
						console.log(error);
					});
				});
			}
			this.load()
		});
	}

	componentDidUpdate(props: any, state: any) {
		var channelId = sessionStorage.getItem("channelId");
		if (channelId != this.state.channelId) {
			ScoringHubService.instance.Stop()
			.then(() => {
				if (channelId != null){
					ScoringHubService.instance.Start(channelId).then(() => {
						this.signalREvents = [
							ScoringHubService.instance.subscribeSignalR("checkConnectionsRecieved", () => {
								ScoringHubService.instance.ConfirmConnection();
							}),
							ScoringHubService.instance.subscribeSignalR("dataReceived", (data:MatchGame) => {
								var game = new MatchGame;
								var set = new MatchSet;
								if (data != null) {
									set = this.state.matchCard.sets.find(s => s.games.some(g => g.id == data.id));
									game = data;
								}
								else {
									game = null;
									set = null;
								}
								this.setState({ liveGame: game, liveSet: set});
							}),
							ScoringHubService.instance.subscribeSignalR("statusReceived", (data:CurrentMatchStatus) => {
								if(data.id == 0){
									data.id = null;
								}
								this.setState({gameEnd: data.gameEnd, matchId: data.id, endMatch: data.matchEnd, gameId: data.gameId, timeout: data.timeout, timeoutHome: data.timeoutHome}, () => {
									this.load();
								});
							}),
							ScoringHubService.instance.subscribeSignalR("endGameReceived", () => {
								this.load();
								this.setState({ liveGame: null, liveSet: null});
							}),
						];
						ScoringHubService.instance.GetGroupState();
						ScoringHubService.instance.sendGetStatus();
					});
					
				}
			});
			this.setState({channelId: channelId})
		}
		if(this.state.hubConnection == null && channelId == null){
			var hubConnection = new signalR.HubConnectionBuilder()
				.withUrl(`${AppConfig.baseUrl}scoreboardhub`, {withCredentials: false})
				.configureLogging(signalR.LogLevel.Information)
				.build();
			this.setState({hubConnection}, () => {
				this.state.hubConnection.start().then(() => {
					if (this.state.hubConnection.state == signalR.HubConnectionState.Connected){
						this.state.hubConnection.on("groupsRecieved", (data: dictionary<string,number>[]) => {
							if(this.state.groupNames != data){
								this.setState({groupNames: data});
							}
						});
						this.state.hubConnection.invoke("CheckConnections");
						this.interval = setInterval(() => 
						{
							this.state.hubConnection.invoke("GetGroups", this.state.division.id)
							.catch(err => console.error(err.toString()));
						}, 1500);
					}
				});
			});
		}
	}

	componentWillUnmount() {
		this.disconnect();
	}

	load() {
		var matchId = this.state.matchId
		if(matchId != null ){
			this.loadMatch();
		}

		this.setState({ loading: false });
	}

	unload() {
		this.signalREvents?.map(dispatcher => dispatcher.off());
	};

	loadMatch(){
		if(this.state.matchId != null){
			const p1 = MatchService.matchCard(this.state.matchId).then(matchCard => {

				this.setState({ matchCard }, () => {
					this.matchCard = matchCard;
					if(this.state.gameId != null){
						ScoringHubService.instance.sendGetGame();
					}
				});
			});

			Promise.all([p1]).then(result => {
				this.setState({ index: 0 }, () => {
					if (!this.state.matchCard.match.home.score && !this.state.matchCard.match.away.score) {
						if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.PointsAwarded && this.state.matchCard.match.submitted && !this.state.matchCard.match.approved) {
							this.matchCard.match.home.score = this.matchCard.match.home.points;
							this.matchCard.match.away.score = this.matchCard.match.away.points;
						}
						else if((this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Points){
							this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.home.score += g.home));
							this.matchCard.sets.map(s => s.games.map(g => this.matchCard.match.away.score += g.away));		
						}
						else {
							this.matchCard.sets.map(s => this.matchCard.match.home.score += (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.homeScore : (s.homeScore > s.awayScore && s.completed ? 1 : 0));
							this.matchCard.sets.map(s => this.matchCard.match.away.score += (this.state.division.resultDisplay ?? this.state.competition.resultDisplay) == ResultDisplay.Games ? s.awayScore :  (s.awayScore > s.homeScore && s.completed ? 1 : 0));		
						}
						if(this.matchCard.match.homeHandicap != null && this.matchCard.match.awayHandicap != null) {
							if(this.matchCard.match.homeHandicap > this.matchCard.match.awayHandicap){
								this.matchCard.match.home.score += ((this.matchCard.match.homeHandicap - this.matchCard.match.awayHandicap));
							}
							else{
								this.matchCard.match.away.score += ((this.matchCard.match.awayHandicap - this.matchCard.match.homeHandicap));
							}
						}
						var set = null;
						if (this.state.liveGame != null){
							set = this.state.matchCard.sets.find(s => s.games.some(g => g.id == this.state.liveGame.id));
						}
						else {
							set = null;
						}

						this.setState({ matchCard: this.matchCard, liveSet: set });
					}
				});
			});
		}
	}

	hasWon(home: boolean, set: MatchSet): boolean {
		const won = set.completed && ((home && (set.homeScore > set.awayScore)) ||
			(!home && (set.awayScore > set.homeScore)));

		return won;
	}

	playerName(p: MatchPlayer): string {
		return p.userId.length > 0 ? p.printoutName : "";
	}

	label(ordering:number, home:boolean, playerCount: number){
		var alpha = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
		var index = 0;
		if(this.state.competition.matchCard.playerLabels == PlayerLabels.Alphabetical) {
			if(home)
				index = ordering;
			else
				index = (26 - playerCount) + ordering;
			return alpha[index];
		}
		else {
			return `${home ? `H` : `A`} ${ordering + 1}`;
		}
	}

	connect(){
		var channelId = this.state.division.id + "~" + this.state.groupName;
		sessionStorage.setItem("channelId", channelId);

		this.setState({groupName: null});
		if(this.state.hubConnection != null){
			this.state.hubConnection.off("groupsRecieved")
			this.state.hubConnection.stop();
			this.setState({hubConnection: null});
		}
		clearInterval(this.interval);
	}

	disconnect(){
		sessionStorage.removeItem("channelId");
		this.unload();
		if(this.state.hubConnection != null){
			this.state.hubConnection.off("groupsRecieved")
			this.state.hubConnection.stop();
			this.setState({hubConnection: null});
		}
		clearInterval(this.interval);
		ScoringHubService.instance.Stop();
		this.setState({matchCard: null, liveGame: null, liveSet: null, gameEnd: false, endMatch: false, matchId: null, channelId: null})
	}

	public render() {
		return (
			<ShowMe
				visible={!this.state.loading && (this.state.matchId == null || this.state.matchCard != null)}
				progress={true}
				isEmpty={this.state.competition == null}
				emptyText=""
				mode={ShowMeMode.Full}
				render={() => (
					<div className="sub-page bg-primary text-white" style={{height:"100%"}}>
						{this.state.channelId == null && <div>
							<div className="col-lg-12">
								<div className="form-group" style={{margin: "0px 30px", paddingTop: "30px"}}>
									{this.state.channelId?.split("~")[1] != this.state.groupName && this.state.groupName?.length > 0 && this.state.groupName?.length <= 20 && <button onClick={() => this.connect()} title="Join Channel" className="btn btn-circle text-secondary btn-icon mr-2 float-right">
										<div className="tx-20"><i className="icon far fa-link"></i></div>
									</button>}
									<label className="form-control-label">Connection Name <span className={`${this.state.groupName?.length > 20 && "tx-error"}`}>{this.state.groupName?.length}/20</span></label>
									<input className="form-control" type="text" name="name" value={this.state.groupName} onChange={e => this.setState({ groupName: e.currentTarget.value})} placeholder="Enter connection group name" />
								</div>
								<br/>
								<div className="form-group" style={{margin: "0px 30px", padding: "0px 30px"}}>
									<p>or Select from List of Existing Connections:</p>
									<div className="row list-group-default text-primary" style={{justifyContent: "center"}}>
										{
											this.state.groupNames && this.state.groupNames?.map(t => {
												return (
													<div key={`league_${t.key}`} className="list-group-item col-3" style={{margin: "5px"}}>
														<div className="list-title">
															<p>{t.key?.split("~")[1]}</p>
															<span>Active Connection: {t.value}</span>
														</div>
														<div className="list-btn-wrapper">
															{this.state.channelId != t.key && <button onClick={() => this.setState({groupName: t.key?.split("~")[1]}, () => {this.connect()})} title="Join Channel" className="btn btn-circle text-secondary btn-icon mr-2">
																<div className="tx-20"><i className="icon far fa-link"></i></div>
															</button>}
															{this.state.channelId == t.key && <button onClick={() => this.disconnect()} title="Leave Channel" className="btn btn-circle text-secondary btn-icon mr-2">
																<div className="tx-20"><i className="icon far fa-unlink"></i></div>
															</button>}
														</div>
													</div>
												);
											})
										}
									</div>
								</div>
							</div>
						</div>}
						{this.state.channelId != null && <div style={{margin:"0px", padding: "0px"}}>
							<div style={{margin:"0px", padding: "0px", textAlign: "right"}}>
								<button onClick={() => this.disconnect()} title="Leave Channel" style={{margin: "0px", padding: "0px"}} className="btn btn-circle text-secondary btn-icon mr-2">
									<div className="tx-20"><i className="icon far fa-unlink"></i></div>
								</button>
							</div>
							{this.state.liveGame != null && <div>
								{this.state.gameEnd ? <div className="matchcard" style={{margin:"0px 50px"}}>
									{!this.state.liveSet.completed && <div style={{textAlign:"center"}}>
										<Row>
											<Col>
											</Col>
											<Col>
												<h1  style={{margin:"0px", padding: "0px"}}>Set #{this.state.matchCard?.sets.findIndex(s => s.id == this.state.liveSet?.id) + 1}</h1>
											</Col>
											<Col>
											</Col>
										</Row>
									</div>}
									<Row>
										<Col>
											{this.state.liveSet.completed ? <div className="text-primary" style={{textAlign:"center", borderRadius:"15px", height: "500px", padding: "25px", margin: "10px", backgroundColor: "white"}}>
												<h1 style={{margin: "10px"}}>
													Set #{this.state.matchCard?.sets.findIndex(s => s.id == this.state.liveSet?.id) + 1} Winner
												</h1>
												<h1 style={{}}>
													{this.state.liveSet?.homeScore > this.state.liveSet?.awayScore ? 
													this.state.liveSet?.homePlayers.map(p => {
														if (this.state.liveSet?.homePlayers.findIndex(x => x.id == p.id) > 0){
															return " & " + p.name
														}
														else{
															return p.name;
														}
													})
													:
													this.state.liveSet?.awayPlayers.map(p => {
														if (this.state.liveSet?.awayPlayers.findIndex(x => x.id == p.id) > 0){
															return " & " + p.name
														}
														else{
															return p.name;
														}
													})}
												</h1>
												<h1 style={{}}>
													{this.state.liveSet?.homeScore} - {this.state.liveSet?.awayScore}
												</h1>
											</div>
											:
											<div className="text-primary" style={{textAlign:"center", borderRadius:"15px", height: "500px", padding: "25px", margin: "10px", backgroundColor: "white"}}>
												<h1 style={{margin: "10px"}}>
													Game #{this.state.matchCard?.sets.find(s => s.id == this.state.liveSet?.id)?.games.findIndex(g => g.id == this.state.liveGame?.id) + 1} Winner
												</h1>
												<h1 style={{}}>
													{this.state.liveGame?.home > this.state.liveGame?.away ? 
													this.state.liveSet?.homePlayers.map(p => {
														if (this.state.liveSet?.homePlayers.findIndex(x => x.id == p.id) > 0){
															return " & " + p.name
														}
														else{
															return p.name;
														}
													})
													:
													this.state.liveSet?.awayPlayers.map(p => {
														if (this.state.liveSet?.awayPlayers.findIndex(x => x.id == p.id) > 0){
															return " & " + p.name
														}
														else{
															return p.name;
														}
													})}
												</h1>
												<h1 style={{}}>
													{this.state.liveGame?.home} - {this.state.liveGame?.away}
												</h1>
											</div>}
										</Col>
									</Row>
								</div>
								:
								<div className="matchcard" style={{margin:"0px 50px"}}>
									<div style={{textAlign:"center"}}>
										<Row>
											<Col className="bg-primary" style={{borderRadius:"30px 30px 0px 0px", border: "3px solid white", borderBottom: "none"}}>
												<h1 style={{margin: "0px", padding: "5px"}}>Home</h1>
											</Col>
											<Col style={{margin: "0px 5px"}}>
												<h2 className="bg-primary" style={{margin: "20px", padding: "0px"}}>Set #{this.state.matchCard?.sets.findIndex(s => s.id == this.state.liveSet?.id) + 1}</h2>
												{/* <h1 style={{margin: "30px", padding: "0px"}}>Game #{this.state.matchCard?.sets.find(s => s.id == this.state.liveSet?.id)?.games.findIndex(g => g.id == this.state.liveGame?.id) + 1}</h1> */}
											</Col>
											<Col className="bg-secondary" style={{borderRadius:"30px 30px 0px 0px", border: "3px solid white", borderBottom: "none"}}>
												<h1 style={{margin: "0px", padding: "5px"}}>Away</h1>
											</Col>
										</Row>
									</div>
									<div style={{textAlign:"center"}}>
										<Row>
											<Col className="bg-primary" style={{borderRadius:"0px 0px 0px 0px", border: "3px solid white", borderBottom: "none", borderTop: "none"}}>
												<div>
													{this.state.liveSet?.homePlayers.map(s => {
														return <h3 style={{margin: "5px"}}>{s.name}
														{this.state.liveGame?.currentServer?.userId == s.userId ? <span className="tx-success tx-30 mg-10"><i className="fa fa-circle"></i></span>
														: this.state.liveGame?.secondaryServer?.userId == s.userId && <span className="tx-warning tx-30 mg-10"><i className="fa fa-circle"></i></span>}</h3>
													})}
												</div>
											</Col>
											<Col style={{margin: "0px 5px"}}>
												<h1 className="bg-primary" style={{margin: "30px", padding: "0px"}}>Game #{this.state.matchCard?.sets.find(s => s.id == this.state.liveSet?.id)?.games.findIndex(g => g.id == this.state.liveGame?.id) + 1}</h1>
											</Col>
											<Col className="bg-secondary" style={{borderRadius:"0px 0px 0px 0px", border: "3px solid white", borderBottom: "none", borderTop: "none"}}>
												<div>
													{this.state.liveSet?.awayPlayers.map(s => {
														return <h3 style={{margin: "5px"}}>{this.state.liveGame?.currentServer?.userId == s.userId ? <span className="tx-success tx-30 mg-10"><i className="fa fa-circle"></i></span>
														: this.state.liveGame?.secondaryServer?.userId == s.userId && <span className="tx-warning tx-30 mg-10"><i className="fa fa-circle"></i></span>}{s.name}</h3>
													})}
												</div>
											</Col>
										</Row>
									</div>
									<Row>
										<Col className="bg-primary" style={{borderLeft: "3px solid white"}}>
										</Col>
										<Col className="bg-primary">
										</Col>
										<Col className="bg-primary" style={{borderRadius:"0px 30px 0px 0px", border: "3px solid white", borderBottom: "0px", borderLeft: "0px", height: "30px", marginRight: "5px"}}>
										</Col>
										<Col className="bg-secondary" style={{borderRadius:"30px 0px 0px 0px", border: "3px solid white", borderBottom: "0px", borderRight: "0px", height: "30px", marginLeft: "5px"}}>
										</Col>
										<Col className="bg-secondary">
										</Col>
										<Col className="bg-secondary" style={{borderRight: "3px solid white"}}>
										</Col>
									</Row>
									<Row>
										<Col className="bg-primary" style={{borderRadius:"0px 0px 30px 30px", border: "3px solid white", borderTop: "none", marginRight: "5px"}}>
											<div style={{textAlign:"center"}}>
												<h1 className="text-primary" style={{backgroundColor: "white", borderRadius:"15px", fontSize:"200pt", height: "300px", padding: "25px", margin: "20px"}}>
													{this.state.liveGame?.home}
												</h1>
											</div>
										</Col>
										<Col className="bg-secondary" style={{borderRadius:"0px 0px 30px 30px", border: "3px solid white", borderTop: "none", marginLeft: "5px"}}>
											<div style={{textAlign:"center"}}>
												<h1 className="text-primary" style={{backgroundColor: "white", borderRadius:"15px", fontSize:"200pt", height: "300px", padding: "25px", margin: "20px"}}>
													{this.state.liveGame?.away}
												</h1>
											</div>
										</Col>
									</Row>
									<div className="row" style={{textAlign:"center", height:"200px", padding:"20px 0px", justifyContent:"center"}}>
										{this.state.liveSet?.games.map(g => {
											return <div style={this.state.liveGame?.id == g.id ? {height:"140px", width: "285px", backgroundColor: "white", justifyContent:"center", borderRadius:"15px 15px 15px 15px"}: {}}>
												<div className="row" style={this.state.liveGame?.id == g.id ? {width: "245px", margin: "20px", justifyContent:"center"} : {width: "245px", margin: "20px 5px", justifyContent:"center"}}>
													<h5 className="text-primary" style={{backgroundColor: "white", borderRadius:"15px 0px 0px 15px", fontSize:"65pt", width: "100px", height: "100px", paddingTop: "5px"}}>
														{this.state.liveGame?.id == g.id ? this.state.liveGame?.home : (g.home != null ? g.home.toString() : "")}
													</h5>
													<h5 className="text-primary" style={{backgroundColor: "white", fontSize:"65pt", width: "40px", height: "100px", paddingRight: "5px"}}>
														{/* s.homeScore != null ? "-" : "" */}-
													</h5>
													<h5 className="text-primary" style={{backgroundColor: "white", borderRadius:"0px 15px 15px 0px", fontSize:"65pt", width: "100px", height: "100px", paddingTop: "5px"}}>
														{this.state.liveGame?.id == g.id ? this.state.liveGame?.away : (g.away != null ? g.away.toString() : "")}
													</h5>
												</div>
											</div>
											// return <div className="d-inline game">
											// 	<input className={`d-inline form-control `} style={{fontSize: "60pt", width:"100px"}} title={`Game ${g.ordering + 1} - Home`} value={g.home != null ? g.home.toString() : this.state.liveGame.id == g.id ? this.state.liveGame.home?.toString() : ""} disabled/>
											// 	<input className={`d-inline form-control `} style={{fontSize: "60pt", width:"100px"}} title={`Game ${g.ordering + 1} - Away`} value={g.away != null ? g.away.toString() : this.state.liveGame.id == g.id ? this.state.liveGame.away?.toString() : ""} disabled/>
											// </div>
										})}
									</div>
								</div>}
							</div>}
							
							{this.state.matchCard != null && this.state.liveGame == null && <div style={{margin:"0px 50px", padding: "10px 10px"}} className="matchcard" >
								{this.state.endMatch && <div style={{textAlign:"center"}}>
									<h3>Previous Match</h3>
								</div>}
								<div style={{textAlign:"center", fontSize:"20pt", padding: "0px"}}>
									<Row>
										<Col className="bg-primary" style={{borderRadius:"30px 30px 0px 0px", border: "3px solid white", borderBottom: "none", marginRight: "5px"}}>
											<h2 style={{margin: "0px", padding: "10px"}}>Home</h2>
										</Col>
										<Col className="bg-secondary" style={{borderRadius:"30px 30px 0px 0px", border: "3px solid white", borderBottom: "none", marginLeft: "5px"}}>
											<h2 style={{margin: "0px", padding: "10px"}}>Away</h2>
										</Col>
									</Row>
								</div>
								<div style={{textAlign:"center", padding: "0px"}}>
									<Row>
										<Col className="bg-primary" style={{borderRadius:"0px 0px 0px 0px", border: "3px solid white", borderBottom: "none", borderTop: "none", marginRight: "5px"}}>
											<div style={{margin: "0px", padding: "0px"}}>
												<h2>{this.state.matchCard.match.home.displayName}</h2>
											</div>
										</Col>
										<Col className="bg-secondary" style={{borderRadius:"0px 0px 0px 0px", border: "3px solid white", borderBottom: "none", borderTop: "none", marginLeft: "5px"}}>
											<div style={{margin: "0px", padding: "0px"}}>
												<h2>{this.state.matchCard.match.away.displayName}</h2>
											</div>
										</Col>
									</Row>
								</div>
								{/* <Row>
									<Col className="bg-primary" style={{borderLeft: "2px solid white"}}>
									</Col>
									<Col className="bg-primary">
									</Col>
									<Col className="bg-primary" style={{borderRadius:"0px 0px 30px 0px", border: "2px solid white", borderTop: "none", borderLeft: "none", height: "30px"}}>
									</Col>
									<Col className="bg-secondary" style={{borderRadius:"0px 0px 0px 30px", border: "2px solid white", borderTop: "none", borderRight: "none", height: "30px"}}>
									</Col>
									<Col className="bg-secondary">
									</Col>
									<Col className="bg-secondary" style={{borderRight: "2px solid white"}}>
									</Col>
								</Row> */}
								<Row>
									<Col className="bg-primary" style={{borderRadius:"0px 0px 30px 30px", border: "3px solid white", borderTop: "none", marginRight: "5px"}}>
										<div style={{textAlign:"center", margin: "0px"}}>
											<h1 className="text-primary"  style={{backgroundColor: "white", borderRadius:"15px", fontSize:"150pt", height: "235px", padding: "25px", margin: "20px"}}>
												{this.state.matchCard?.match.home.score}
											</h1>
										</div>
									</Col>
									{/* <Col>
									</Col> */}
									<Col className="bg-secondary" style={{borderRadius:"0px 0px 30px 30px", border: "3px solid white", borderTop: "none", marginLeft: "5px"}}>
										<div style={{textAlign:"center", margin: "0px"}}>
											<h1 className="text-primary"  style={{backgroundColor: "white", borderRadius:"15px", fontSize:"150pt", height: "235px", padding: "25px", margin: "20px"}}>
												{this.state.matchCard?.match.away.score}
											</h1>
										</div>
									</Col>
								</Row>
								{/* !this.state.endMatch && this.state.matchCard?.sets.map (s => {
									var index = this.state.matchCard.sets.findIndex(x => x.id == s.id);
									if(index >= this.state.index && index <= this.state.index + 1) {
										return <div style={{margin: "0px", padding: "0px"}}>
											<div className="row">
												<div className="col-1">
													<h4 style={{margin: "5px", padding: "15px 0px"}}>Set #{index + 1}</h4>
												</div>
												<div>
													<h5>
														{s.homePlayers.map(p => {
															if (s.homePlayers.findIndex(x => x.id == p.id) > 0){
																return " & " + p.name
															}
															else{
																return p.name;
															}
														})} 
													</h5>
													<h5>{s.awayPlayers.map(p => {
														if (s.awayPlayers.findIndex(x => x.id == p.id) > 0){
															return " & " + p.name
														}
														else{
															return p.name;
														}
													})}</h5>
												</div>
												
											</div>
											<div className="row">
												<div className="games col" style={{textAlign:"center", height:"110px", padding:"0px", margin: "0px"}}>
													{s.games.map(g => {
														if (!s.completed || g.home != null && g.away != null)
														return <div className="d-inline game" style={{padding:"0px", margin: "0px, 10px"}}>
															<input className={`d-inline form-control`} style={{fontSize: "40pt", width:"100px"}} title={`Game ${g.ordering + 1} - Home`} value={g.home != null ? g.home.toString() : ""} disabled/>
															<input className={`d-inline form-control`} style={{fontSize: "40pt", width:"100px"}} title={`Game ${g.ordering + 1} - Away`} value={g.away != null ? g.away.toString() : ""} disabled/>
														</div>
													})}
												</div>
											</div>
										</div>
									}	
								}) */}
								<div className="row" style={{margin: "0px 40px", padding: "0px"}}>
									{!this.state.endMatch && <OwlCarousel options={{autoplay: true, autoplayTimeout:15000, autoplaySpeed:7500, loop: true, responsive: { 992: { items: 1 }, 768: { items: 1 }, 0: { items: 1 } } }}>
										{this.state.matchCard?.sets.filter(i => (i.ordering + 1) % (/* this.state.matchCard?.sets.length % 6 > 0 ? Math.ceil(this.state.matchCard?.sets.length/2) : */ 6) == 1).map (i => {
											return <div className="row" style={{margin: "10px 0px", padding: "0px"}}>
												{this.state.matchCard?.sets.filter(x => x.ordering >= i.ordering && x.ordering < (i.ordering + (/* this.state.matchCard?.sets.length % 6 > 0 ? Math.ceil(this.state.matchCard?.sets.length/2) : */ 6))).map(s => {
													var index = this.state.matchCard.sets.findIndex(c => c.id == s.id);
													return <div className="col-4" style={{margin: "0px", padding: "5px 25px"/* , border: "1px dotted black" */, borderRadius:"15px"}}>
														<div className="row">
															<div style={{textAlign:"left", margin:"0px", marginBottom:"5px"}}>
																<h5 style={{margin: "0px", padding: "0px"}}>
																	{s.homePlayers.map(p => {
																		if (s.homePlayers.findIndex(x => x.id == p.id) > 0){
																			return p.forfeit == null ? " & " + p.name : ""
																		}
																		else{
																			return p.forfeit != null ? p.forfeitReason : p.name;
																		}
																	})} 
																</h5>
																<h5 style={{margin: "0px", padding: "0px"}}>
																	{s.awayPlayers.map(p => {
																		if (s.awayPlayers.findIndex(x => x.id == p.id) > 0 && p.forfeit == null){
																			return " & " + p.name
																		}
																		else{
																			return p.forfeit != null ? p.forfeitReason : p.name;
																		}
																	})}
																</h5>
															</div>
														</div>
														<div className="row">
															<div className="col-4">
																<h4 style={{margin: "0px", padding: "30px 0px"}}>Set #{index + 1}</h4>
															</div>
															<div className="games col-8" style={{height:"100px", padding:"0px", margin: "0px", textAlign: "center", overflow: "hidden"}}>
															{/* s.homeScore != null && s.awayScore != null && */ <div className="row">
																	<h5 className="text-primary" style={{backgroundColor: "white", borderRadius:"15px 0px 0px 15px", fontSize:"65pt", width: "100px", height: "100px", paddingTop: "5px", marginLeft: "20px"}}>
																		{s.homeScore != null ? s.homeScore.toString() : ""}
																	</h5>
																	<h5 className="text-primary" style={{backgroundColor: "white", fontSize:"65pt", width: "40px", height: "100px", paddingRight: "5px"}}>
																		{/* s.homeScore != null ? "-" : "" */}-
																	</h5>
																	<h5 className="text-primary" style={{backgroundColor: "white", borderRadius:"0px 15px 15px 0px", fontSize:"65pt", width: "100px", height: "100px", paddingTop: "5px"}}>
																		{s.awayScore != null ? s.awayScore.toString() : ""}
																	</h5>
																</div>}
																{/* <input className={`d-inline form-control`} style={{fontSize: "40pt", width:"100px", textAlign:"center"}} title={`Score - Home`} value={s.homeScore != null ? s.homeScore.toString() : ""} disabled/>
																<input className={`d-inline form-control`} style={{fontSize: "40pt", width:"100px", textAlign:"center"}} title={`Score - Away`} value={s.awayScore != null ? s.awayScore.toString() : ""} disabled/> */}
															</div>
														</div>
													</div>
												})}
											</div>
										})}
									</OwlCarousel>}
								</div>
							</div>
							}
							{this.state.matchCard == null &&
							<div>
								<br/>
								<h1 style={{textAlign:"center"}}>Awaiting First Match</h1>
								<br/>
							</div>
							}
						</div>}
						<Modal className="modal--large bg-transparent text-white"isOpen={this.state.timeout}>
							<ModalHeader className={`bg-${this.state.timeoutHome ? "primary" : "secondary"}`} style={{justifyContent: "center", fontSize: "50pt"}}>TIMEOUT</ModalHeader>
							<ModalBody className={`bg-${this.state.timeoutHome ? "primary" : "secondary"}`} style={{justifyContent: "center"}}>
								<div className="bg-white" style={{borderRadius: "30px", height: "300px"}}>

								</div>
								<h1 style={{textAlign: "center"}}>
									{this.state.timeoutHome ? 
									this.state.liveSet?.homePlayers.map(p => {
										if (this.state.liveSet?.homePlayers.findIndex(x => x.id == p.id) > 0){
											return " & " + p.name
										}
										else{
											return p.name;
										}
									})
									:
									this.state.liveSet?.awayPlayers.map(p => {
										if (this.state.liveSet?.awayPlayers.findIndex(x => x.id == p.id) > 0){
											return " & " + p.name
										}
										else{
											return p.name;
										}
									})}
								</h1>
							</ModalBody>
						</Modal>
					</div>
					
			)} />
		)
	}
}