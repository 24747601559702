import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Card, CardBody, Container } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";
import { ImageUpload } from "@components/controls/ImageUpload";
import { AppConfig } from "@config";
import { VisualEditor } from "@components/controls/VisualEditor";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

const DraggableSection = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);

	const [name, setName] = useState(props.name);
	const [description, setDescription] = useState(props.description);

	// payload
	const [title, setTitle] = useState(props.payload.title);
	const [subTitle, setSubTitle] = useState(props.payload.subTitle);
	const [body, setBody] = useState(props.payload.body);
	const [link, setLink] = useState(props.payload.link);
	const [linkText, setLinkText] = useState(props.payload.linkText);
	const [image, setImage] = useState(props.payload.image);
	const [layout, setLayout] = useState(props.payload.layout);
	const [imageId, setImageId] = useState<number>(props.payload.imageId);

	const [editMode, setEditMode] = useState(false);
	const [setupMode, setSetupMode] = useState(props.payload.layout ? false : true);

	const [parent, setParent] = useState(state.getElementParent(props.dropzoneID, props.parentID));

	if (showBasicContent) {
		return (
			<Draggable {...props}>
				{
					<span>{name}</span>
				}
			</Draggable>
		);
	}

	const Content = (props) => {
		return (
			<>
				{
					layout === 1 &&
					<section className="pagemaker-section pagemaker-section--1 theme--light">
						<Container>
							<Row>
								<Col md={6}>
									<div className="bg-image overlay bg-cover" style={{ backgroundImage: `url(${image})` }}></div>
								</Col>
								<Col md={6}>
									<div className="content">
										<div className="sc_title scheme_dark sc_title_default">
											<span className="subtitle sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default">{subTitle}</span>
											<h2 className="sc_item_title sc_title_title sc_item_title_style_default">
												<span className="sc_item_title_text">{title}</span>
											</h2>
											<div className="sc_item_descr sc_title_descr">
												<p>
													{body}
												</p>
											</div>
											<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
												{
													link &&
													<a href={link} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
														<span className="sc_button_text"><span className="sc_button_title">{linkText}</span></span>
													</a>
												}
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				}
				{
					layout === 2 &&
					<div className="feature-1 feature-item overlay bg-cover" style={{ backgroundImage: `url(${image})` }}>
						<h3>{title}</h3>
						{ReactHtmlParser(body)}
						{
							link && <a href={`${link}`} className="primary-btn f-btn">{linkText}</a>
						}
					</div>
				}
				{
					layout === 3 &&
					<div className="feature-2 classes-item overlay bg-cover" style={{ backgroundImage: `url(${image})` }}>
						<h4>{title}</h4>
						{ReactHtmlParser(body)}
						{
							link && <a href={`${link}`} className="primary-btn f-btn">{linkText}</a>
						}
					</div>
				}
			</>
		);
	};

	if (showPreview) {
		return (
			<>
				<Content />
			</>
		);
	}

	const onChange = (e) => {
		const value = e.target.value;
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, body, link, linkText, image, layout, imageId } });
	};

	const handleConfirm = () => (e: any) => {
		setEditMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, body, link, linkText, image, layout, imageId } });
	};

	const handleClose = () => (e: any) => {
		setEditMode(false);
	};

	const handleUpload = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleSelect = () => async (id: number) => {
		setImageId(id);
		setImage(`${AppConfig.baseUrl}image/${id}`);
	};

	const handleReset = () => () => {
		setImage(null);
		setImageId(null);
	};

	const handleLayoutConfirm = (layout: number) => (e: any) => {
		setLayout(layout);
		setSetupMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title, subTitle, body, link, linkText, image, layout } });
	};

	return (
		<>
			<Draggable {...props} >
				<Card>
					<Row>
						<Col>
							{title ? <>
								<p className="u-text-small">{name}</p>
								{title}
							</> : <>
								{name}
								<p className="u-text-small">Text with a background image</p>
							</>
							}
						</Col>
						<Col className="text-right">
							<a className="btn btn-circle btn-border mr-2"
								onClick={() => setEditMode(true)}>
								<i className="far fa-pencil"></i>
							</a>
							<a className="btn btn-circle btn-border"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
				</Card>
			</Draggable>
			<Modal className={"element-editor"} isOpen={editMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Title</Label>
						<Input value={title} placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Sub Title</Label>
						<Input value={subTitle} placeholder="Enter sub title" onChange={e => setSubTitle(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Body</Label>
						{
							layout === 1 &&
							<Input value={body} placeholder="Enter body text" onChange={e => setBody(e.target.value)} />
						}
						{
							[2, 3].indexOf(layout) >= 0 &&
							<VisualEditor
								menubar={false}
								height={200}
								content={body}
								onChange={e => setBody(e)}
							/>
						}
					</FormGroup>
					<FormGroup>
						<Label>Link</Label>
						<Input value={link} placeholder="Enter link url" onChange={e => setLink(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Link Text</Label>
						<Input value={linkText} placeholder="Enter link text" onChange={e => setLinkText(e.target.value)} />
					</FormGroup>
					<FormGroup>
						<Label>Image</Label>
						{
							!imageId && <Input className="mb-3" value={image} placeholder="Enter image url" onChange={e => setImage(e.target.value)} />
						}
						<Row>
							<Col>
								<ImageUpload
									reset={true}
									imageId={imageId}
									onSelect={handleSelect()}
									onReset={handleReset()}
									onCancel={() => { }}
									onUpload={handleUpload()}
									aspectRatio={16 / 9} />
							</Col>
						</Row>
					</FormGroup>
					<Row>
						<Col>
							<a tabIndex={0} className="btn btn-primary btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
						</Col>
						<Col>
							<a tabIndex={0} className="btn btn-gray-200 btn-rounded w-100" onClick={handleClose()}>Cancel</a>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			<Modal className={"element-editor"} isOpen={setupMode}>
				<ModalBody className="p-4">
					<FormGroup>
						<Label>Sections</Label>
						<Row>
							<Col>
								<Card onClick={handleLayoutConfirm(1)} className="card--maker-setup">
									<CardBody>
										<h6>Section 1</h6>
										<p></p>
									</CardBody>
								</Card>
							</Col>
							<Col>
								<Card onClick={handleLayoutConfirm(2)} className="card--maker-setup">
									<CardBody>
										<h6>Section 2</h6>
										<p></p>
									</CardBody>
								</Card>
							</Col>
							<Col>
								<Card onClick={handleLayoutConfirm(3)} className="card--maker-setup">
									<CardBody>
										<h6>Section 3</h6>
										<p></p>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</FormGroup>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableSection;
