import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Card, CardBody, Label } from "reactstrap";

import ReactHtmlParser, { } from "react-html-parser";
import PublicLayout from "@components/layouts/PublicLayout";

import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import { Row, Col } from "reactstrap";
import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership, MembershipPayment } from "@models/membership";
import { PagedList } from "@models/paging";
import LocaleHelper from "@helpers/locale.helper";
import { Website, WebsiteCustomMenuItem, WebsiteMenuPreset } from "@models/website";
import { StringHelper } from "@helpers/string.helper";
import { MiscHelper } from "@helpers/misc.helper";
import { Category } from "@models/rankings/category";
import { toast } from "react-toastify";
import { CategoryService } from "@services/rankings/category.service";
import { CategoryFilterOptions } from "@models/filters/rankingFilterOptions";
import { Paging } from "@components/controls/Paging";
import { imageUrl } from "@helpers/file.helper";
import { RankingsService } from "@services/rankings/rankings.service";
import { PlayerRanking } from "@models/rankings/playerranking";
import { Gender } from "@models/user";
import { NumberHelper } from "@helpers/number.helper";
import { InstanceService } from "@services/rankings/instance.service";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";
import Select from "react-select";
import { SelectOption } from "@models/forms";
import moment from "moment";
import History from "@helpers/history.helper";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import ObjectHelper from "@helpers/object.helper";
import { genderLetter } from "@helpers/rankings/gender.helper";


interface IRankingsListPageProps {
	categoryId?: string;
	date?:string;
	instanceId?:string;
}

const dateToSelectOption = (date:string):SelectOption<string> => ({ label: moment(date, "YYYY-MM-DD").format("MMMM YYYY"), value: date });

const TableHeadings = () => (
	<tr role="row">
		<th className="data-rank sorting" rowSpan={1} colSpan={1} aria-label="Pos">#</th>
		<th className="data-name sorting" rowSpan={1} colSpan={1} aria-label="Athlete Name">Name</th>
		<th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="Membership Number">No</th>
		<th className="data-p sorting" rowSpan={1} colSpan={1} aria-label="Gender">Gender</th>
		<th className="data-w sorting" rowSpan={1} colSpan={1} aria-label="ELO">ELO</th>
		<th className="data-d sorting" rowSpan={1} colSpan={1} aria-label="Protected Points">Protected</th>
		<th className="data-l sorting" rowSpan={1} colSpan={1} aria-label="Bonus">Bonus</th>
		<th className="d-none d-md-table-cell data-f sorting" rowSpan={1} colSpan={1} aria-label="Total">Total</th>
	</tr>
);

const RowItem = ({ranking:r, categoryId, date}:{categoryId:string, date:string, ranking?:PlayerRanking}) => {
	const url = `/rankings/national/category/${categoryId}/${r.player?.membershipNo}/${r.eventId}`;
	const Link = (props:PropsWithChildren) => <a href={url}>{props.children}</a>
	return (
		<tr className="sp-row-no-0 odd bg-white" role="row">
			<td className="data-rank" data-label="Pos">{r.positions?.at(0)?.position}</td>
			<td className="data-name nowrap" data-label="Team"><Link>{r.player?.name}</Link></td>
			<td className="data-p sorting" data-label="Membership Number"><Link>{r.player?.membershipNo}</Link></td>
			<td className="data-p" data-label="Gender">{genderLetter(r.player?.gender)}</td>
			<td className="data-w" data-label="ELO">{NumberHelper.toPrecision(r.elo)}</td>
			<td className="data-d" data-label="Protected">{NumberHelper.toPrecision(r.totalProtected)}</td>
			<td className="data-l" data-label="Bonus">{NumberHelper.toPrecision(r.totalBonusScaled)}</td>
			<td className="d-none d-md-table-cell data-g" data-label="Total">{NumberHelper.toPrecision(r.total)}</td>
		</tr>
	);
};
const EmptyRowItem = () => {
	return (
		<tr className="sp-row-no-0 odd bg-white" role="row">
			<td className="data-empty text-center align-middle" colSpan={8} style={{height: 200}}>
				<p className="m-0">No Results for this category or period found.</p>
			</td>
		</tr>
	);
};

const RankingsListPage = (props: RouteComponentProps<IRankingsListPageProps>) => {
	const storageKey: string = "PublicRankingsListPageNo";
	const [loadingDates, setLoadingDates] = useState<boolean>(true);
	const [loadingRankings, setLoadingRankings] = useState<boolean>(false);
	const [loadingMenu, setLoadingMenu] = useState<boolean>(true);
	const [category, setCategory] = useState<Category>(undefined);
	const [rankings, setRankings] = useState<PlayerRanking[]>(undefined);
	const [websiteMenu, setWebsiteMenu] = useState<WebsiteCustomMenuItem>(undefined);
	const [dates, setDates] = useState<string[]>([]);
	const [parent, enableAnimations] = useAutoAnimate({
		duration: 500,
		easing:"ease-out"
	});

	const { categoryId, instanceId, date } = props.match.params;
	const isLoading = loadingMenu || loadingDates;

	useEffect(() => {
		WebsiteService.updateActivity("NationalRankingsList");
		menuLoad();
		loadDatesAndCategory();
		//loadRankings();
	}, []);

	useEffect(() => {
		if (moment(date, "YYYY-MM-DD").isValid()) {
			loadRankings();
		}
	}, [date, instanceId, categoryId])

	useEffect(() => {
		MiscHelper.scrollToTop();
	}, [websiteMenu])

	const menuLoad = async () => {
		setLoadingMenu(true);
		try {
			const menu = await WebsiteService.getCustomMenuByPreset(AppCache.tenant.id, WebsiteMenuPreset.Rankings);
			setWebsiteMenu(menu);
		}
		catch (err) {
			toast.error(err);
		}
		setLoadingMenu(false);
	};

	const loadDatesAndCategory = async () => {
		setLoadingDates(true);
		try {
			const _instanceId = isNaN(instanceId as any) ? undefined : parseInt(instanceId, 10);
			const _categoryId = isNaN(categoryId as any) ? undefined : parseInt(categoryId, 10);

			const dates = await InstanceService.GetInstanceEventDatesGroupedByMonth(_instanceId, _categoryId);
			setDates(dates);

			const category = await CategoryService.get(_categoryId);
			setCategory(category);

			if ((date === undefined || date.length === 0) && dates.length > 0 || !dates.includes(date)) {
				handleDateSelected(true, dateToSelectOption(dates?.at(0)));
			}
		}
		catch (err) {
			toast.error(err);
		}
		setLoadingDates(false);
	};

	const loadRankings = async () => {
		setLoadingRankings(true);
		try {
			const _categoryId = isNaN(categoryId as any) ? undefined : parseInt(categoryId, 10);
			const rankings = await RankingsService.getCategoryResults(_categoryId, date);
			setRankings(rankings);
		}
		catch (err) {
			toast.error(err);
		}
		setLoadingRankings(false);
	};


	const handleDateSelected = (replace: boolean, item: SelectOption<string>) => {
		const url = `/rankings/national/category/${categoryId}/${item?.value ?? ""}`;
		if (replace) {
			History.replace(url);
		} else {
			History.push(url);
		}
	};

	const RankingsList = useMemo(() => (
		rankings?.map(m => <RowItem key={m.player.id} ranking={m} categoryId={categoryId} date={date} />)
	), [rankings, categoryId, date]);

	const dateOptions = dates.map<SelectOption<string>>(x => dateToSelectOption(x));

	return (
		<PublicLayout theme="scheme_alter">
			<ShowMe
				visible={!isLoading}
				mode={ShowMeMode.Full}
				progress={true}
				render={() => (
					<div className="sub-page">
						{
							AppCache.website.config.subHeader ?
								<div className="elementor elementor-6">
									<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
										style={category?.imageId && {
											backgroundImage: `url("${imageUrl(category?.imageId)}")`,
											backgroundRepeat: "no-repeat",
											//backgroundAttachment:"fixed",
											backgroundPosition: "center",
											backgroundSize: "cover"
										}}
									>
										<div className="elementor-container elementor-column-gap-extended">
											<div className="elementor-row">
												<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
													<div className="elementor-column-wrap  elementor-element-populated">
														<div className="elementor-widget-wrap">
															<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																<div className="elementor-widget-container">
																	<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																		<h1 className={`sc_layouts_title_caption ${(ObjectHelper.isNull(category?.imageId) || category?.darkImage) ? "text-light" : "text-dark"}`}>{category?.name ?? "National Rankings"}</h1>
																		<p className={`header--description ${(ObjectHelper.isNull(category?.imageId) || category?.darkImage) ? "text-light" : "text-dark"}`}>National Rankings</p>
																	</div>
																	</div>
																	</div>
																</div>
															</div>
															<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div> :
								<div className="header_content_wrap mt-5">
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h1 className="sc_layouts_title_caption">{StringHelper.ifEmpty(websiteMenu?.title, "Memberships")}</h1>
												<p className="header--description">{StringHelper.ifEmpty(websiteMenu?.subTitle, "Available memberships")}</p>
											</div>
										</div>
									</div>
								</div>
						}

						<div className="page_content_wrap">
							<div className="container">
								<div className="pre-content">
									{
										websiteMenu?.body &&
										<Row>
											<Col>
												{ReactHtmlParser(websiteMenu.body)}
											</Col>
										</Row>
									}
								</div>
								<div className="content national-rankings">
									<div className="categories-list mg-t-20">
										<Card
											className="mb-4">
											<CardBody className="d-flex">
												<div className="col-12">
													<div>
														<div className="heading mb-5">

															<div className="text-right">
																<Label className="mr-2">Ranking Period</Label>
																<Select
																	id="instancepicker"
																	styles={{
																		control: (baseStyles, state) => ({
																			...baseStyles,
																			//textAlign: "right",
																			minWidth: "15em",
																			width:"15em"
																		}),
																		menuList: (baseStyles, props) => ({
																			...baseStyles,
																			textAlign: "left"
																		})
																	}}
																	className="d-inline-block mr-2"
																	placeholder={"Select date..."}
																	options={dateOptions}
																	closeMenuOnSelect={true}
																	value={dateOptions.find(x => x.value === date)}
																	onChange={handleDateSelected.bind(this, false)}
																	/>
															</div>
														{/* <h3 className="text-center">Heading</h3> */}
														</div>

														<div className="standings position-relative" style={{minHeight: "10rem"}}>
															<div>
																<div className="table-responsive">
																	<div>
																		<table className="league-table sp-league-table sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table" data-sp-rows="10" role="grid">
																			<thead>
																				<TableHeadings />
																			</thead>
																			<tbody ref={parent}>
																				{rankings?.length > 0 ? RankingsList :
																					<EmptyRowItem />
																				}
																			</tbody>
																		</table>
																	</div>
																</div>
															</div>
															{loadingRankings && <ActivitySpinner overlay />}
														</div>

													</div>
												</div>

											</CardBody>
										</Card>
									</div>
								</div>
							</div>
						</div>
					</div>)}
			/>
		</PublicLayout>
	);
};

export default RankingsListPage;