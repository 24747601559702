import React from "react";


import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import * as Queue from "promise-queue";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import { ArrayHelper } from "@helpers/array.helper";
import { Team, TeamMember } from "@models/team";
import { TeamService } from "@services/team.service";
import { User } from "@models/user";
import { SelectOption } from "@models/forms";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { Can } from "@components/controls/Authentication";
import { TenantType } from "@models/tenant";
import { AppConfig } from "@config";
import { Competition, CompetitionMember } from "@models/competition";
import { toast } from "react-toastify";
import { MemberService } from "@services/member.service";
import { CompetitionService } from "@services/competition.service";
import { Options } from "@data";
import { confirmAlert } from "react-confirm-alert";


interface IAssignCompetitionRoleSidebar {
	competition: Competition;

	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: IAssignCompetitionRoleSidebar) => {
	const [roleMembers, setRolesMembers] = useState<CompetitionMember[]>([]);
	const [member, setMember] = useState<SelectOption<string>>();
	const [memberRole, setMemberRole] = useState<CompetitionMember>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		loadRoles();
	};

	const handleClose = (e: any) => {
		props.onClose();
	};

	const handleConfirm = (e: any) => {
		props.onConfirm();
		props.onClose();
	};

	const loadRoles = async () => {
		CompetitionService.getCompetitionMembers(props.competition.id).then(result => {
			setRolesMembers(result);
		});
	};

	const loadMemberOptions = () => (value: string): Promise<SelectOption<string>[]> => {
		return new Promise(resolve => {
			MemberService.search({
				search: value,
				competitionId: props.competition.id,
				email: false,
				player: false,
				assignment: false
			}).then(members => {
				const options = members.filter(m => roleMembers.every(r => r.name != m.name)).map(m => ({ label: m.name, value: m.id }));
				resolve(options);
			});
		});
	}

	const handleCancelRoleClick = (member: CompetitionMember) => (e) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will permanently delete this manager</p>
						<a className="btn btn-secondary text-white mr-2"
							onClick={() => {
								CompetitionService.removeMember(member.userId, member.competitionId).then(() => {
									loadRoles();
									onClose();
									toast.success("Competition manager removed");
								});
							}}
						>
							Yes, delete it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	const handleAssignRole = () => () => {
		if (memberRole.userId?.length > 0 && memberRole.role != null)
			CompetitionService.assignMember(memberRole).then(result => {
				setMember(null);
				setMemberRole(null);
				load();
			})
	}

	const handleChangeMember = () => (option: SelectOption<string>) => {
		setMember(option);
		setMemberRole({ ...memberRole, userId: option.value })
	}

	const handleChangeRole = () => (option: SelectOption<number>) => {
		setMemberRole({ ...memberRole, role: option.value })
	}

	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Member Roles
					<div className="help-container">
						<a className="help">
							<i className="far fa-info-circle"></i>
						</a>
					</div>
				</h6>
			</div>
			<div className="sidebar--content">
				{
					memberRole &&
					<div className="form-layout">
						<div className="form-group">
							<label className="form-control-label">Player <span className="tx-danger">*</span></label>
							<AsyncSelect
								value={member}
								loadOptions={loadMemberOptions()}
								closeMenuOnSelect={true}
								onChange={handleChangeMember()} />
						</div>
						<div className="form-group">
							<label className="form-control-label">Role <span className="tx-danger">*</span></label>
							<Select
								value={Options.memberRoles.find(m => m.value == memberRole.role)}
								options={Options.memberRoles.filter(m => m.value > 0)}
								closeMenuOnSelect={true}
								onChange={handleChangeRole()} />
						</div>
						<div className="text-center">
							<button onClick={handleAssignRole()} className="btn btn-circle bg-primary text-white btn-icon mr-2" title="Add Entrant"><div className="tx-20"><i className="icon fas fa-check"></i></div></button>
							<button onClick={() => { setMemberRole(null); setMember(null); }} className="btn btn-circle bg-primary text-white btn-icon mr-2" title="Add Entrant"><div className="tx-20"><i className="icon fas fa-times"></i></div></button>
						</div>
					</div>
				}
				{
					!memberRole &&
					<>
						<div className="list-cards">
							{
								roleMembers.map(r => (
									<Card className="mb-3 p-3">
										<Row>
											<Col xs={9} className="d-flex align-self-center">
												<div className="col">
													<h6 className="m-0">{r.name}</h6>
													<p className="m-0 small">{Options.memberRoles.find(m => m.value == r.role).label}</p>
												</div>
											</Col>
											<Col xs={3} className="d-flex justify-content-end align-self-center">
												{r.role != 0 && <a className="btn btn--circle btn-sm text-white" onClick={handleCancelRoleClick(r)}><i className="far fa-times" /></a>}
											</Col>
										</Row>
									</Card>
								))
							}
						</div>
						<div className="form-layout-footer text-center mt-4">
							<button onClick={() => setMemberRole(new CompetitionMember(props.competition.id))} className="btn btn-secondary btn-rounded btn-outline bd-0 mr-2" title="Add Member">Assign</button>
							<div className="mt-4">
								<a tabIndex={0} className="btn-link clickable" onClick={handleClose}>Cancel</a>
							</div>
						</div>
					</>
				}
			</div>
		</div>
	);
};