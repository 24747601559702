import { SignalRConnectionStatus } from "@models/enums/signalrconnectionstatus";
import EventDispatcher from "@utils/eventDispatcher";
import * as QueryString from "qs";
import * as signalR from "@microsoft/signalr";
import { IDispatcher } from "@interfaces/scoring/idispatcher";
import { ISignalRMethod } from "@interfaces/scoring/isignalrmethod";
import { AppCache } from "@cache";

export abstract class SignalRServiceBase {

	public static EnableConsoleLogging: boolean = true;

	protected dispatcher: EventDispatcher;
	protected connection: signalR.HubConnection;
	protected _status = SignalRConnectionStatus.Disconnected;

	private _reconnectTimeout = -1;
	private _timeoutMS: number = 2000;
	private _signalRShouldReconnect = true;
	protected _channelId = undefined;
	private hubUrl:string;

	constructor() {
		this.dispatcher = new EventDispatcher();
	}

	protected initializeConnectionEvents() {
		// This is called seperately so we can make sure the inherited class has set the connection Url first!
		this.connection.onclose(this.signalROnClose);
		this.connection.onreconnected(this.signalROnReconnected);
		this.connection.onreconnecting(this.signalROnReconnecting);
	}

	public get Status():SignalRConnectionStatus {
		return this._status;
	}

	// Core Functionality Event Subscriptions
	protected signalRMethods = (): ISignalRMethod[] => [
		{ methodName: "debugMessageReceived", method: this._debugMessageReceived }, // This is just temporary
	]

	protected subscribeEvents = () => {
		this.signalRMethods().forEach(element => {
			this.connection.on(element.methodName, element.method);
		});
	}
	protected unsubscribeEvents = () => {
		this.signalRMethods().forEach(element => {
			this.connection.off(element.methodName, element.method);
		});
	}
	// End of core Functionality Event Subscriptions


	// Connection Management
	private getConnectionUrl(): string {
		let params = {} as any;

		if (this._channelId) {
			params = {
				...params,
				"channel-id": this._channelId
			};
		}

		// QueryString.stringifyUrl({ url: this.hubUrl, query: params });
		return `${this.hubUrl}?${QueryString.stringify(params)}`;
	}

	protected buildConnection = (url:string):signalR.HubConnection => {
		this.hubUrl = url;
		return new signalR.HubConnectionBuilder()
			// withAutomaticReconnect()
			.withUrl(this.getConnectionUrl(), {
				withCredentials: false
			})
			.build();
	}

	public async Start(channelId?: string) {
		this._signalRShouldReconnect = true;
		this._channelId = channelId && channelId.length > 0 ? channelId : undefined;

		if (this.connection.state === "Disconnected") {
			this.connection.baseUrl = this.getConnectionUrl();
			await this.connection.start()
				.then(this.signalRstart)
				.catch(this.signalROnConnectionError);
		} else {
			this._dispatchConnectivityChanged(SignalRConnectionStatus.ConnectionFailed, `Cannot run start() if state is not \"Disconnected\". State is: ${this.connection.state.toString()}`);
			this.signalRTryToReconnect();
		}
	}
	public async Stop() {
		this._signalRShouldReconnect = false;

		if (this._reconnectTimeout > -1) {
			clearTimeout(this._reconnectTimeout);
			this._reconnectTimeout = -1;
		}

		await this.connection.stop();
	}
	// End Connectivity Management


	// Core Event Definitions
	private signalRstart = () => {
		this._dispatchConnectivityChanged(SignalRConnectionStatus.Connected, `Connected, ChannelId: ${this._channelId}`);
		this.connection.invoke("CheckConnections");
	}

	private signalROnConnectionError = (error) => {
		this._dispatchConnectivityChanged(SignalRConnectionStatus.ConnectionFailed, "Couldn't connect!");
		this.signalRTryToReconnect();
	}

	private signalROnClose = (error) => {
		console.log(error);
		if (error) {
			this.signalRTryToReconnect();
		}
		this._dispatchConnectivityChanged(SignalRConnectionStatus.Disconnected, "Disconnected");
	}

	private signalRTryToReconnect = (delay: boolean = true) => {
		// Dont try to reconnect if the state is "Disconnecting" because this is not an error.
		if (this._signalRShouldReconnect && this._reconnectTimeout === -1) {
			try {
				const _delay = delay ? this._timeoutMS : 10;
				this._reconnectTimeout = setTimeout(() => {
					// So this is the reconnect culprit.. Sometimes, this function block just never executes.. i have no idea why :/
					this._reconnectTimeout = -1;

					this._dispatchConnectivityChanged(SignalRConnectionStatus.Reconnecting, "Reconnecting...");
					this.Start(this._channelId);
				}, _delay) as any;
			}
			catch(err){
				console.log(err);
			}
		} else {
			this._dispatchConnectivityChanged(SignalRConnectionStatus.Disconnecting, "User Requested Disconnect. Will not try to automatically reconnect");
		}
	}
	private signalROnReconnected = () => {
		this._dispatchConnectivityChanged(SignalRConnectionStatus.Reconnected, "Reconnected");	
	}
	private signalROnReconnecting = () => {
		this._dispatchConnectivityChanged(SignalRConnectionStatus.Disconnected, "Disconnected, Reconnecting...");
	}

	private _debugMessageReceived = (username: string, message: string) => {
		if (SignalRServiceBase.EnableConsoleLogging) {
			console.log(`[SIGNALR Debug]: ${username} sent: ${message}`);
		}
	}

	private _dispatchConnectivityChanged(status: SignalRConnectionStatus, message: string) {
		this._status = status;

		this.dispatcher.dispatch("connectivityChanged", status, message);

		if (SignalRServiceBase.EnableConsoleLogging) {
			console.log(`[SIGNALR]: ${message}`);
		}
	}

	// protected subscribeAndReturnSignalRCleanupFunction = (methodName: string, callback: (...args: any) => void):IDispatcher => {
	// 	this.connection.on(methodName, callback);
	// 	return { off: () => this.connection.off(methodName, callback) };
	// }
	// End of Core Event Definitions
}