import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import { Row, Col } from "reactstrap";
import * as moment from "moment";
import * as NProgress from "nprogress";

import PublicLayout from "@components/layouts/PublicLayout";

import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import * as qs from "query-string";
import { ContactService } from "@services/contact.service";
import { Contact, ContactGuardianType } from "@models/contact";

interface IConfirmMemberPageProps {
	site?: string;
	match?: any;
	location?: any;
}

interface IConfirmMemberPageState {
	loading: boolean;
	nextPathname?: any;
	success: boolean;
}

export class ConfirmMemberPage extends React.Component<IConfirmMemberPageProps, IConfirmMemberPageState> {

	private code: string;
	private name: string;
	private email: string;

	constructor(props) {
		super(props);

		this.state = { loading: true, success: false };
	}

	componentDidMount() {
		this.code = this.props.match.params.code as string;

		const parsed = qs.parse(location.search);
		this.name = parsed.name;
		this.email = parsed.email;

		if (this.state.loading) {
			this.setState({ loading: false });
		}
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	handleConfirmClick = (accept: boolean) => () => {
		ContactService.ConfirmMember(this.code, accept).then(result => {
			this.setState({ success: true });
		});
	}

	public render() {

		return (
			<PublicLayout theme="scheme_default" className="sub-page post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							<div className="header_content_wrap mt-5">
								<div className="container">
									<div className="row">
										<div className="col-12">
											<h2 className="sc_layouts_title_caption">Member Confirmation</h2>
											{
												this.state.success &&
												<Row>
													<Col>
														<p className="header--description">Thank you for your confirmation</p>
													</Col>
												</Row>
											}
											{
												!this.state.success &&
												<>
													<p>Click below to confirm you are happy with {AppCache.tenant.name} having access to your TTE member details</p>
													<Row className="mt-5">
														<Col>
															<a tabIndex={0} className={`w-100 bg-primary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center`} type="text" onClick={this.handleConfirmClick(true)}>Accept</a>
														</Col>
														<Col>
															<a tabIndex={0} className={`w-100 bg-primary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center`} type="text" onClick={this.handleConfirmClick(false)}>Decline</a>
														</Col>
													</Row>
												</>
											}
										</div>
									</div>
								</div>
							</div>
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="mg-t-20">

										</div>
									</div>
								</div>
							</div>
						</div>)} />
			</PublicLayout>);
	}
}
