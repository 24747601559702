import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { Container, Row, Col } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { MatchGrouping, MatchGroupRange, Match, MatchGroupType, MatchFilters, TemplateAccess } from "@models/match";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { MatchService } from "@services/match.service";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AddToCalendar } from "@components/controls/AddToCalendar";
import { AppCache } from "@cache";

import * as queryString from "query-string";
import { SelectOption } from "@models/forms";
import { CompetitionFilterOptions } from "@models/filters";
import { Display, Options } from "@data";
import { confirmAlert } from "react-confirm-alert";
import { Tenant, TenantDetails, TenantType } from "@models/tenant";
import { WebsiteCompetitionData, WebsiteVisibility } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";
import { WebsiteService } from "@services/website.service";
import { FreeSlots } from "@models/week";
import { Sidebar } from "rsuite";
import { Toggle } from "@components/controls/Toggle";
import { MatchCardTemplatePage } from "../divisions/MatchCardTemplatePage";
import { TenantFilterOptions } from "@models/filters/filterOptions";
import { TenantService } from "@services/tenant.service";
import { PagedList } from "@models/paging";
import { DelayInput } from "react-delay-input";

const dataFormat = ["table", "results", "averages", "potms", "rankings"];

interface INotFoundPageProps {
	site?: string;
	match?: any;
	type?: TenantType;
}

interface INotFoundPageState {
	loading: boolean;
	nextPathname?: any;
}
export class NotFoundPage extends React.Component<INotFoundPageProps, INotFoundPageState> {

	constructor(props) {
		super(props);

		this.state = { loading: true };
	}

	componentDidMount() {
	}

	public render() {
		return (
			<PublicLayout theme={`scheme_alter ${AppCache.tenant.leagueId ? "scheme-dark" : "scheme-light"}`} className="remove_margins">
				<div>
					<div className="page_content_wrap">
						<div className="header d-flex global-search">
							<section className="scheme_dark justify-content-center align-self-center">
								<Container>
									<Row>
										<Col xs={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
											<p className="title">
												<span>
													Ooops, we can't find what you are looking for
												</span>
											</p>
											<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
												<Link to={"/"} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
													<span className="sc_button_text">
														<span className="sc_button_title">
															Go Back
														</span>
													</span>
												</Link>
											</div>
										</Col>
									</Row>
								</Container>
							</section>
						</div>
					</div>
				</div>
			</PublicLayout>
		);
	}
}
