import * as React from "react";
import { useState } from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Form, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { toast } from "react-toastify";
import PublicLayout from "@components/layouts/PublicLayout";
import { Paging } from "@components/controls/Paging";
import { Empty } from "@components/controls/Empty";

import { ExtendedButton } from "@components/controls/ExtendedButton";
import { ExtendedLink } from "@components/controls/ExtendedLink";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

import Select from "react-select";
import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { CompetitionService } from "@services/competition.service";
import { Competition } from "@models/competition";
import { DatatableQuery, DatatableMeta } from "@models/query";
import { ShowMe, ShowMeMode } from "@components/controls/ShowMe";
import History from "@helpers/history.helper";
import { LoginService } from "@services/login.service";
import { AppCache } from "@cache";
import { Division } from "@models/division";
import { DivisionService } from "@services/division.service";

import { WebsiteService } from "@services/website.service";
import { MembershipService } from "@services/membership.service";
import { Membership } from "@models/membership";
import { PagedList } from "@models/paging";

import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ProgressButton } from "@components/controls/ProgressButton";
import { User } from "@models/user";
import { MemberService } from "@services/member.service";
import { StripeService } from "@services/stripe.service";
import { Contact, ContactLink } from "@models/contact";
import { ContactService } from "@services/contact.service";
import LocaleHelper from "@helpers/locale.helper";
import { InvoiceService } from "@services/invoice.service";
import { InvoiceStatus } from "@models/invoice";
import { CreateMessage } from "@models/message";
import { MessageService } from "@services/message.service";
import ConsentsModal from "@components/modals/ConsentsModal";

const StripeContainer = (props) => {
	const [stripePromise] = useState(() => loadStripe(AppConfig.stripeApi, { stripeAccount: AppCache.tenant.config.stripeId }));

	return (
		<Elements stripe={stripePromise}>
			<CheckoutForm linked={props.linked} upfront={props.upfront} membershipId={props.membershipId} contact={props.contact} onSubmit={props.onSubmit} onComplete={props.onComplete} onError={props.onError} submitting={props.submitting} />
		</Elements>
	);
};

interface ICheckoutFormProps {
	membershipId: number;
	contact: Contact;
	linked: number[];
	onSubmit?: () => void;
	onComplete?: () => void;
	onError?: () => void;
	submitting: boolean;
	upfront: boolean;
}

const CheckoutForm = (props: ICheckoutFormProps) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = () => async (event: any) => {
		event.preventDefault();

		try {
			validate();

			props.onSubmit();

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement)
			});

			if (paymentMethod) {
				try {
					const invoice = await MembershipService.join(props.membershipId, props.upfront, paymentMethod.id, props.contact.id, props.linked);
					if (invoice.status === InvoiceStatus.Failed) {
						await toast.error("Payment failed. Please retry");
						History.push(`/pay/${invoice.reference}`);
					}
					else
						History.push(`/memberships/confirmation/${invoice.reference}`);
				} catch (error) {
					toast.error(error.message);
					props.onError();
				}

				props.onComplete();
			} else {
				toast.error("Payment details missing");
				props.onError();
			}
		} catch (error) {
			toast.error(error.message);
			props.onError();
		}
	};

	const validate = () => {
		return true;
	};

	const style = {
		base: {
			"color": "#32325d",
			"fontSmoothing": "antialiased",
			"fontSize": "16px",
			"::placeholder": {
				color: "#aab7c4"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	};

	return (
		<div className="stripe">
			<div className="card-input">
				<CardElement options={{ hidePostalCode: true, style }} />
			</div>
			<ProgressButton onClick={handleSubmit()} className="btn btn-secondary btn-rounded w-100" loading={props.submitting}>
				Join and Pay Now
			</ProgressButton>
		</div>
	);
};

interface IMembershipJoinProps {
	site?: string;
	match?: any;

}

interface IMembershipJoinState {
	loading: boolean;
	membership?: Membership;
	contact?: Contact;
	bookee?: Contact;
	linked: number[];
	links: ContactLink[];
	submitting: boolean;
	upfront?: boolean;
	active: boolean;
	consentValid: boolean;
}
export class MembershipJoinPage extends React.Component<IMembershipJoinProps, IMembershipJoinState> {

	private membershipId?: number;

	constructor(props) {
		super(props);

		this.state = { loading: true, submitting: false, active: false, links: [], linked: [], consentValid: false };

		this.membershipId = this.props.match.params.membershipId as number;
	}

	componentDidMount() {
		if (!LoginService.isAuthenticated) {
			History.replace(`/register?returnUrl=/memberships/${this.membershipId}/join`);
		}
		else {
			this.load();
		}
	}

	componentDidUpdate(props: any, state: any) {
		//
	}

	async load() {
		const contact = await ContactService.getByUser();

		if (!contact) {
			History.push("/member/profile/confirm");
		} else {
			const membership = await MembershipService.getById(this.membershipId);
			const bookee = contact;

			// check for failed payment
			const paymentFailedForContactEvent = await InvoiceService.getFailedByMembershipContact(membership.id, contact.id);
			if (paymentFailedForContactEvent !== null && paymentFailedForContactEvent !== undefined) {
				toast.warn("Outstanding payment already on Membership");
				History.push(`/pay/${paymentFailedForContactEvent.reference}`);
			}

			const active = await StripeService.isActive();
			const links = await ContactService.getLinked(contact.id);

			this.setState({ membership, bookee, contact, active, loading: false, links });
		}
	}

	handleJoinClick = () => async (e: any) => {
		e.preventDefault();

		try {
			const invoice = await MembershipService.join(this.state.membership.id, this.state.upfront, null, this.state.contact.id, this.state.linked);
			History.push(`/memberships/confirmation/${invoice.reference}`);
		} catch (error) {
			toast.error(error.message);
		}
	}

	public render() {
		return (
			<PublicLayout theme="scheme_alter">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
											style={this.state.membership.headerId && { backgroundImage: `url('${AppConfig.baseUrl}image/${this.state.membership.headerId}')` }}>
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">{this.state.membership.name}</h1>
																			<p className="header--description">{this.state.membership.summary}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">{this.state.membership.name}</h1>
													<p className="header--description">{this.state.membership.summary}</p>
												</div>
											</div>
										</div>
									</div>
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="membership-join mg-t-20">
											<Row>
												<Col md={{ size: 6, offset: 3 }}>
													{
														this.state.membership.upfrontCost &&
														<Row className="mb-3">
															<Col>
																<Card className={`membership-option text-center p-5 clickable mb-4 ${this.state.upfront === true && "selected"}`} onClick={e => this.setState({ upfront: true })}>
																	<h3 className="membership-price m-0 text-center">{LocaleHelper.toPrice(this.state.membership.upfrontCost)}</h3>
																	Upfront
																</Card>
															</Col>
															<Col>
																<Card className={`membership-option text-center p-5 clickable mb-4 ${this.state.upfront === false && "selected"}`} onClick={e => this.setState({ upfront: false })}>
																	<h3 className="membership-price m-0 text-center">{LocaleHelper.toPrice(this.state.membership.cost)}</h3>
																	Monthly
																</Card>
															</Col>
														</Row>
													}
													{
														(!this.state.membership.upfrontCost || this.state.upfront !== undefined) &&
														<>
															{
																this.state.membership.multiUser &&
																<>
																	<Row>
																		<Col>
																			<p className="mb-1">Please select linked members</p>
																		</Col>
																	</Row>
																	<Row className="mb-5">
																		<Col>
																			<Select
																				options={[...this.state.links]}
																				closeMenuOnSelect={true}
																				isMulti
																				isClearable
																				getOptionLabel={e => e.contact.name !== "" ? e.contact.name : e.contact.fullName}
																				getOptionValue={e => e.contact.id}
																				onChange={e => this.setState({ linked: (e ? e.map(e => e.contact.id) : []) })} />
																		</Col>
																	</Row>
																</>
															}
															{
																(!this.state.membership.multiUser && this.state.links.length > 0) &&
																<Row className="mb-5">
																	<Col>
																		<Select
																			options={[...this.state.links.map(l => l.contact), this.state.bookee]}
																			closeMenuOnSelect={true}
																			value={this.state.contact}
																			getOptionLabel={e => e.name !== "" ? e.name : e.fullName}
																			getOptionValue={e => e.id}
																			onChange={e => this.setState({ contact: e })} />
																	</Col>
																</Row>
															}
															<Form>
																<Row>
																	<Col>
																		<h3 className="membership-price m-0 text-center">
																			{this.state.upfront && <span>{LocaleHelper.toPrice(this.state.membership.upfrontCost)}</span>}
																			{!this.state.upfront &&
																				<>
																					{this.state.membership.cost > 0 && <span>{LocaleHelper.toPrice(this.state.membership.cost)}</span>}
																					{this.state.membership.cost === 0 && <span>FREE</span>}
																				</>
																			}
																		</h3>
																	</Col>
																</Row>
																{
																	(this.state.active && (this.state.membership.cost > 0 || this.state.membership.setupFee > 0)) &&
																	<Row>
																		<Col>
																			<StripeContainer
																				contact={this.state.contact}
																				linked={this.state.linked}
																				membershipId={this.membershipId}
																				upfront={this.state.upfront}
																				onSubmit={e => this.setState({ submitting: true })}
																				onComplete={e => this.setState({ submitting: false })}
																				onError={e => this.setState({ submitting: false })}
																				submitting={this.state.submitting} />
																		</Col>
																	</Row>
																}
																{
																	this.state.membership.payLater &&
																	<Row className="mt-3">
																		<Col>
																			<ProgressButton onClick={this.handleJoinClick()} className="btn btn-primary btn-rounded w-100" loading={this.state.submitting}>
																				Join and Pay Later
																			</ProgressButton>
																		</Col>
																	</Row>
																}
																{
																	(this.state.membership.cost === 0 && this.state.membership.setupFee === 0) &&
																	<Row className="mt-3">
																		<Col>
																			<ProgressButton onClick={this.handleJoinClick()} className="btn btn-primary btn-rounded w-100" loading={this.state.submitting}>
																				Confirm
																			</ProgressButton>
																		</Col>
																	</Row>
																}
																{
																	!this.state.active && !this.state.membership.payLater &&
																	<Row className="mt-3">
																		<Col>
																			<a tabIndex={0} href={`mailto:${AppCache.website.email}`} className="btn btn-secondary text-white btn-rounded w-100">
																				Contact Us
																			</a>
																		</Col>
																	</Row>
																}
															</Form>
														</>
													}
													{
														this.state.active &&
														<Row className="mt-5 text-center">
															<Col>
																<img title="Powered by Stripe" width={"200px"} src={require("../../../../assets/images/misc/stripe-plurple.png")} />
															</Col>
														</Row>
													}
												</Col>
											</Row>
										</div>
									</div>
								</div>
							</div>
						</div>)}
				/>
				<ConsentsModal autoCheck={true} membershipId={this.membershipId} onClose={() => History.goBack()} onConfirm={() => this.setState({ consentValid: true })} />
			</PublicLayout>
		);
	}
}
