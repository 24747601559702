import React from "react";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalHeader, ModalFooter, UncontrolledCollapse, Card, CardHeader, CardBody } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { Competition, CompetitionDay } from "@models/competition";
import { CreateDivision, Division } from "@models/division";
import { DivisionService } from "@services/division.service";

interface ICreateDivisionSidebar {
	competition: Competition;

	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: ICreateDivisionSidebar) => {
	const [division, setDivision] = useState<CreateDivision>(new CreateDivision());

	useEffect(() => {
		load();
	}, []);

	

	const load = async () => {
		
	};

	const handleClose = () => (e: any) => {
		props.onClose();
	};

	const handleConfirm = () => async (e: any) => {
		var model = division;

        model.competitionId = props.competition.id;
        await DivisionService.create(model).then(result => {
            props.onConfirm();
			props.onClose();
        });
	};

	const handleChangeDivision = (property: Function) => (e: any) => {
		const name = /\.([^\.;]+);?\s*\}$/.exec(property.toString())[1];
		let value = null;

		if (e == null) {
			value = null;
		} else if (e.value) {
			value = e.value;
		} else if (e.currentTarget) {
			value = e.currentTarget.value;
		} else {
			value = e;
		}

		setDivision({ ...division, [name]: value });
	}


	return (
		<div className="sidebar">
			<div className="sidebar--header">
				<div className="sidebar--close"></div>
				<h6 className="slim-pagetitle">Quick Add Division
					<div className="help-container">
						<a className="help">
							<i className="far fa-info-circle"></i>
						</a>
					</div>
				</h6>
			</div>
			<div className="sidebar--content mg-t-30">
				<form>
					<div className="form-layout">
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group">
									<label className="form-control-label">Name <span className="tx-danger">*</span></label>
									<input className="form-control" type="text" name="name" placeholder="Enter division name" value={division.name} onChange={handleChangeDivision(() => division.name)} />
								</div>
							</div>
						</div>
						<div className="form-layout-footer">
							<button className="btn btn-primary btn-rounded btn-outline bd-0 mg-r-5" type="button" onClick={handleConfirm()}>Create</button>
							<button className="btn btn-secondary btn-rounded btn-outline bd-0" type="button" onClick={handleClose()}>Cancel</button>
						</div>
					</div>
				</form>
			</div>
			<div className="sidebar--footer">

			</div>
		</div>
	);
};