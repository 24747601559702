import { AffiliationType, Club } from "./club";
import { Team } from "./team";
import { Gender } from "./user";

export class Registration {
	public id: number;
	public tenantId: number;
	public name: string;
	public summary: string;

	public link: string;
	public playerTerms: string;

	public published?: Date;
	public date?: Date;
	public endDate?: Date;
	public invoiceDuration?: number;
	public type: RegistrationType;

	public categories: RegistrationCategory[];

	public venuePreference: boolean;
	public noOfPreferences: number;
	public sharing: RegistrationSharing;
	public sharedTenants: SharedTenant[];

	public enablePayment: boolean;
	public allowPublicSwapPlayer: boolean;
	public requireActiveCompete: boolean;
	public requireKitDetails: boolean;
	public lateEntry: boolean;
	public lateEntryEndDate?: Date;

	public status: RegistrationStatus;
}

export class RegistrationCategory {
	public id: number;
	public name: string;
	public summary: string;
	public registrationId: number;
	public registration: Registration;
	public entrantType: RegistrationEntrantType;
	public competitionTypeId?: number;
	// costs
	public teamCost?: number;
	public playerCost?: number;
	// eligibility
	public requiredPlayers: number;
	public ageEligibility?: number[];
	public genderEligibility?: Gender;
	public requireTenantLink: boolean;
	public requireTeamGender: boolean;
}

export class CostMatrix {
	public id: number;
	public tenantId: number;
	public registrationId?: number;
	public competitionTypeId: number;
	public clubType?: AffiliationType;
	public tiers: number[];
	public teamCost: number;
	public playerCost: number;
	public firstTeamDepositCost?: number;
	public depositCost?: number;
	public lateEntry: boolean;
}

export class CompetitionType {
	public id: number;

	public tenantId?: number;

	public name: string;

	public shortName: string;

	public invoiceCode: string;

	public isDefault: boolean;
}

export class SharedTenant
{
    public registrationId: number;

    public tenantId: number;

    public displayName: string;
}

export class RegistrationDivision
{
	public id: number;

	public name: string;
	
	public ordering: number;

	public registrationId: number;

	public competitionTypeId: number;
}

export class RegistrationTeam
{
	public id: number;

	public name: string;

	public divisionOrdering: number;
   
	public registrationId: number;

	public competitionTypeId: number;
	
	public clubId: number;

	public club: Club;
   
	public retentionId?: number;
   
	public teamId?: number;

	public team: Team;
   
	public competitionId?: number;
   
	public divisionId?: number; 
   
	
}

export enum RegistrationType {
	Default = 1,
	Competition = 2,
}

export enum RegistrationEntrantType {
	Default = 1,
	Teams = 2,
	Players = 3
}

export enum RegistrationCompetitionType {
	Local = 1,
	SBL = 2,
	WBL = 3,
	JBLB = 4,
	JBLG = 5,
	VBLM = 6,
	VBLW = 7
}

export enum RegistrationSharing
{
    None = 1,
    MultiRead = 2,
}

export enum RegistrationStatus{
	Active = 1,
	LateEntry = 2,
	Closed = 3
}