import * as React from "react";

import Select from "react-select";

interface IToggle {
	onChange: (e:boolean|null) => void;
	checked?: boolean;
	disabled?: boolean;
	value?: string;
	title?: string;
	isClearable?: boolean;
	className?: string;
	label?:string;
}

export class Toggle extends React.Component<IToggle, any> {

	constructor(props) {
		super(props);

		this.state = {
			isChecked: props.checked || false,
			isClearable: props.isClearable || false,
		};

		this.handleChange = this.handleChange.bind(this);

		this.handleClear = this.handleClear.bind(this);
	}

	componentDidMount() {
		//
	}

	handleChange(e:any) {
		this.setState({ isChecked: !this.state.isChecked }, ()=> {
			this.props.onChange(this.state.isChecked);
		});
	}

	handleClear() {
		this.setState({ isChecked: false }, ()=> {
			this.props.onChange(null);
		});
	}

	render() {
		return (
			<div className={this.props.className}>
				<label className="switch" title={this.props.title}>
					<input disabled={this.props.disabled} type="checkbox" checked={this.state.isChecked} value={this.props.value} onChange={this.handleChange} title={this.props.title} />
					<div className="sliderch round"></div>
				</label>
				<span className="label">{this.props.label}</span>
				{this.state.isClearable && this.props.checked != null ? <div className="help-container">
					<button className="btn p-0 tx-20" onClick={this.handleClear}>
						<i className="far fa-times"></i>
					</button>
				</div> : undefined}
			</div>
		);
	}
}
