import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { CompetitionService } from "@services/competition.service";
import { DivisionService } from "@services/division.service";

interface ICompetitionMenu {
	competitionId: number;
	divisionId?: number;
}

const CompetitionMenu = (props: ICompetitionMenu) => {
	const [open, setOpen] = useState(true);
	const [knockout, setKnockout] = useState(false);
	const [handicap, setHandicap] = useState(false);

	useEffect(() => {
	}, [open]);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		var competition = await CompetitionService.getById(props.competitionId);
		var division = await DivisionService.getById(props.divisionId);

		var knockout = (division?.fixtureFormat ?? competition.fixtureFormat) == 5;

		var handicap = (competition.teamHandicap);

		setKnockout(knockout);
		setHandicap(handicap);
	}

	const handleConfirm = () => async (e: any) => {
	};

	const handleClose = () => (e: any) => {
		//
		setOpen(false);
	};

	return (
		<div className="row">
			<div className="col-12">
				<nav className="navbar navbar-submenu navbar-expand-lg">
					<div className="container">
						<ul className="navbar-nav">
							{props.divisionId && !knockout && <li className="text-white">
								<Link to={`/league/${props.competitionId}/division/${props.divisionId}/table`}>Tables</Link>
							</li>}
							<li className="text-white">
								<Link to={`/league/${props.competitionId}${props.divisionId ? `/division/${props.divisionId}` : ``}/fixtures`}>Fixtures</Link>
							</li>
							<li className="text-white">
								<Link to={`/league/${props.competitionId}${props.divisionId ? `/division/${props.divisionId}` : ``}/results`}>Results</Link>
							</li>
							{props.divisionId && !knockout && <li className="text-white">
								<Link to={`/league/${props.competitionId}/division/${props.divisionId}/averages`}>Averages</Link>
							</li>}
							{props.divisionId && !handicap &&  <li className="text-white">
								<Link to={`/league/${props.competitionId}/division/${props.divisionId}/potm`}>Potm</Link>
							</li>}
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default CompetitionMenu;