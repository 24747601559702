import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";
import { ContentService } from "@services/content.service";

import { MembersLayout } from "@components/layouts/MembersLayout";
import { EmptyLayout } from "@components/layouts/EmptyLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";
import { Content } from "@models/content";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";

import * as ReactMarkdown from "react-markdown";
import { EmbedLayout } from "@components/layouts/EmbedLayout";
import { Standing } from "@models/standing";
import { CompetitionService } from "@services/competition.service";
import { Average } from "@models/average";

interface IAveragesEmbedPageProps {
	site?: string;
	match?: any;
}

interface IAveragesEmbedPageState {
	loading: boolean;
	nextPathname?: any;
	averages?: Average[];
	competition?: Competition;
	division?: Division;
}

export class AveragesEmbedPage extends React.Component<IAveragesEmbedPageProps, IAveragesEmbedPageState> {

	private divisionId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: false
		};
	}

	componentDidMount() {
		//
		window.postMessage({
			type: 'INIT_IFRAME',
			value: { clientId: "" }
		}, "*");
		this.divisionId = 197;
		this.load();
	}

	load(){
		const p1 = DivisionService.getAverages(this.divisionId).then(averages => {
			this.setState({ averages });
		});

		const p2 = DivisionService.getById(this.divisionId).then(division => {
			this.setState({ division });
		});

		Promise.all([p1, p2]).then(result => {

			const p3 = CompetitionService.getById(this.state.division.competitionId).then(competition => {
				this.setState({ competition });
			});

			Promise.all([p3]).then(result => {
				this.setState({ loading: false })
			});
		});

	}

	public render() {
		return (
			<EmbedLayout>
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="col-lg-4 col-md-12 right mb-4">
							<div className="sidebar widget_area sidebar_below">
								<div className="sidebar_inner">
									<div className="elementor-widget-wrap">
										<div className="elementor-widget-container">
											<div className="sc_layouts_widgets widget_area">
												<div className="sc_layouts_widgets_inner widget_area_inner">
													<div className="sp-widget-align-none">
														<aside className="widget widget_sportspress widget_league_table widget_sp_league_table widget--league-table">
															<h5 className="widget_title widget--title">
																<span className="sub-title">{this.state.competition ? this.state.competition.name : "Competition"}</span>
																{this.state.division ? this.state.division.name : "Division"}
															</h5>
															<div className="sp-template sp-template-league-table">
																<div className="sp-table-wrapper">
																	<div className="sp-scrollable-table-wrapper">
																		<div id="DataTables_Table_0_wrapper" className="dataTables_wrapper no-footer">
																			<table className="sp-player-list sp-data-table sp-sortable-table sp-scrollable-table sp-paginated-table dataTable no-footer" data-sp-rows={10} id="DataTables_Table_1" role="grid">
																				<thead>
																					<tr role="row">
																						<th className="data-name sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Player">
																							Name
																									</th>
																						<th className="data-goals sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Goals">
																							P
																									</th>
																						<th className="data-assists sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Assists">
																							W
																									</th>
																						<th className="data-assists sorting" tabIndex={0} aria-controls="DataTables_Table_1" rowSpan={1} colSpan={1} aria-label="Assists">
																							%
																									</th>
																					</tr>
																				</thead>
																				<tbody>
																					{this.state.averages && this.state.averages.map(a => {
																						return (
																							<tr className="odd" role="row">
																								<td className="data-name" data-label="Player">
																									<Link to={`/league/${a.competitionId}/player/${a.userId}`}>{a.name}</Link>
																								</td>
																								<td className="data-goals" data-label="Goals">
																									{a.played}</td>
																								<td className="data-assists" data-label="Assists">
																									{a.won}</td>
																								<td className="data-assists" data-label="Assists">
																									{parseFloat(a.percentage.toFixed(0))}</td>
																							</tr>
																						);
																					})}
																				</tbody>
																			</table>
																		</div>
																	</div>
																</div>
															</div>
															<br/>
															<p className="sub-title">Powered By TTLeagues</p>
														</aside>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</EmbedLayout>);
	}
}
