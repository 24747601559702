import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";
import { Row, Col } from "reactstrap";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition, ReserveFilter } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe } from "@components/controls/ShowMe";
import { AppCache } from "@cache";

import AsyncSelect from 'react-select/async';
import Select from "react-select";

import { Helmet } from "react-helmet";
import { WebsiteCompetitionData } from "@models/website";
import CompetitionMenu from "@components/controls/CompetitionMenu";
import { Reserve } from "@models/reserve";
import { MatchService } from "@services/match.service";
import { Entrant } from "@models/entrant";
import { MemberService } from "@services/member.service";
import { SelectOption } from "@models/forms";
import { LoginService } from "@services/login.service";
import { MatchPlayer } from "@models/match";

interface ITeamCheckerPageProps {
	site?: string;
	match?: any;
}

interface ITeamCheckerPageState {
	loading: boolean;
	nextPathname?: any;
	division?: Division;
	competition?: Competition;
	entrantId?: number;
	entrants: Entrant[];
	memberId?: string;
	token?: string;
	reasons?: string[];

}
export class TeamCheckerPage extends React.Component<ITeamCheckerPageProps, ITeamCheckerPageState> {

	private competitionId: number;

	private entrant?: SelectOption<number>;

	private member?: SelectOption<string>;

	private tokenTimer: any;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			entrants: []
		};
	}

	componentDidMount() {
		//
		this.competitionId = this.props.match.params.competitionId as number;

		this.load();
	}

	componentDidUpdate(props: ITeamCheckerPageProps, state: ITeamCheckerPageState) {
		this.competitionId = this.props.match.params.competitionId as number;

		if (props.match.params.competitionId != this.competitionId) {
			this.load();
		}
		if(this.state.memberId != null && this.state.entrantId != null && this.state.reasons == null){
			this.checkPlayer();
		}
	}

	load() {
		const p1 = CompetitionService.getById(this.competitionId).then(result => {
			this.setState({ competition: result });
		});

		const p2 = TeamService.getEntrants(this.competitionId).then(result => {
			this.setState({ entrants: result });
		})
		Promise.all([p1, p2]).then(result => {
			this.setState({ loading: false })
		});
	}

	checkPlayer() {
		MemberService.checkPlayerTeamAvailablity(this.state.memberId, this.state.entrantId).then(result => {
			this.setState({ reasons: result });
		});
	}

	getTeamOptions(): SelectOption<number>[] {
		const entrants = this.state.entrants.filter(e => e.teamId != null).sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
		return entrants.map(d => ({ label: d.name, value: d.teamId }));
	}

	handleChangeSelect = () => (e: SelectOption<number>) => {
		this.entrant = e;
		var entrantId = e != null ? e.value : null;
		this.setState({ entrantId, reasons: null });
	}

	loadPlayerOptions(home: boolean, value: string): Promise<any[]> {
		return new Promise(resolve => {
			MemberService.search({
				search: value,
				competitionId: this.state.competition.id,
				email: false,
				player: false
			}).then(members => {
				const options = members.map((t) => ({ label: t.name, value: t.id }));
				resolve(options);
			});
		});
	}

	handleChangePlayer(e: SelectOption<string>) {
		this.member = e;
		var memberId = e != null ? e.value : null;
		this.setState({ memberId, reasons: null })
	}

	public render() {
		return (
			<PublicLayout className="page-light page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Team Checker</h1>
																			<p className="sc_layouts_title_breadcrumbs">{this.state.competition?.name}</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> : undefined
							}
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										{
											AppCache.website.config.subHeader ? undefined :
												<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div className="sc_title sc_title_default">
															<h1 className=" m-0 sc_item_subtitle sc_align_center">
																<span className="sc_item_title_text">Team Checker</span>
															</h1>
															<h2 className=" m-0 sc_item_title sc_align_center">
																<span className="sc_item_title_text">{this.state.competition ? this.state.competition.name : ""}</span>
															</h2>
														</div>
													</div>
												</div>
										}
										<div className="row">
											<div className="col-12 mb-3">
												<Row>
													<Col xs={6}>
														<label>Team</label>
														<Select
															options={this.getTeamOptions()}
															isClearable
															value={this.entrant}
															defaultOptions
															onChange={this.handleChangeSelect()}
															closeMenuOnSelect={true} />
													</Col>
													<Col xs={6}>
														<label>Member</label>
														<AsyncSelect
															loadOptions={this.loadPlayerOptions.bind(this, true)}
															isOptionDisabled={option => { return option.disabled; }}
															closeMenuOnSelect={true}
															onChange={this.handleChangePlayer.bind(this)} />
													</Col>
												</Row>
											</div>
											<div className="col-4 mb-3">
											</div>
										</div>
										<div className="row">
											<div className="col-12">
												<div className="container matchcard">
													<div className="slim-pageheader">
														<div className="slim-pageactions">
															{this.state.reasons && <h3 className="btn bg-transparent btn-icon mr-2">
																<i className={`fal fa-${this.state.reasons.length > 0 ? "times text-danger tx-80" : "check text-success tx-80"}`}></i>
															</h3>}
														</div>
														<div>
															<h3 className="slim-pagetitle">{this.member?.label}</h3>
														</div>
													</div>
													{this.state.reasons && <h4>Player is {this.state.reasons.length > 0 ? "ineligible" : "eligible"} for {this.entrant?.label}</h4>}
													{this.state.reasons && this.state.reasons.length > 0 && <div>
														<h4>Reasons</h4>
														{this.state.reasons.map(r => {
															return(<p>- {r}</p>)
														})}
													</div>}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
