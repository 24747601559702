import { Average, DoublesAverage } from "./average";
import { Competition, CompetitionDay } from "./competition";
import { Stage } from "./stage";
import { Standing } from "./standing";
import { DivisionDayTables, DivisionTable } from "./table";

export class Division {
	public competitionId: number;
	public competition: Competition;
	public id: number;
	public name: string;
	public shortName: string;
	public updated: Date;
	public status: DivisionStatus;
	public description: string;
	public details: string;
	public ordering: number;
	public oldOrdering: number;

	// overrides
	public fixtureFormat?: number;
	public resultDisplay?: number;
	public gamesPerSet?: number;
	public gameFormat?: number;
	public formatId?: number;
	public singlesAny?: boolean;
	public pointId?: number;
	public playEachOther?: number;
	public startDate?: Date;
	public reserves?: DivisionReserves;
	public baseRank: number;
	public playAll?: boolean;
	public hasOverrides: boolean;
	public hasEntrants: boolean;
	public entrantCount: number; 

	public days: CompetitionDay[];
	public splitRounds: boolean;

	public miniDivision: boolean;
	public previousLinkId: number;
	public tableAllocation?: boolean;

	public halfGeneration?: boolean;

	public showHomepage?: boolean;

	public showOverrideIndicator: boolean;

	public tables: DivisionDayTables[];

	public standings: Standing[];

	public averages: Average[];

	public dAverages: DoublesAverage[];

	public stage: Stage;

	public manualStandings: boolean;

	public unapproved: number;
}

export class DivisionReserves {
	public divisionId: number;
	public type?: number;
	public stepUpType?: number;
	public multipleTeams?: boolean;
	public transferPlayer?: boolean;
	public maxStepUp?: number;
	public overrideEnabled?: boolean;
}

export class CreateDivision {
	public competitionId: number;
	public name: string;
}

export class splitDivision{
	public divisionId: number;
	public useOverrides: boolean = false;
	public startingDayId?: number;
	public startingRound: number = 1;
	public startingDate?: Date;
	public startingWeek: number = 1;
	public splitPreference: number = 1;
}

export class DivisionOrdering{
	public id: number;
	public ordering: number;
}

export class DivisionProgress{
	public divisionId: number;
	public progress: number;
	public constructor (divisionId, progress){
		this.divisionId = divisionId;
		this.progress = progress;
	}
}

export enum DivisionStatus {
	New = 1,
	Generating = 2,
	Generated = 3,
	InProgress = 4,
	HalfGenerated = 6,
	GeneratingSecond = 7
}
