import { AppConfig } from "../config";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import { League } from "@models/league";

import store from "store2";

import * as moment from "moment";
import { DatatableQuery, DatatableResponse } from "@models/query";

import RestHelper from "@helpers/rest.helper";
import { PagedList } from "@models/paging";
import { LeagueFilterOptions } from "@models/filters/filterOptions";

import * as qs from "qs";

export class LeagueService {

	constructor() {
		//
	}

	public static data(query: DatatableQuery): Promise<DatatableResponse<League>> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Tenant", window.location.hostname);
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `leagues/data`,
				cache: false,
				method: "POST",
				contentType: "application/json",
				data: JSON.stringify({ query }),
				success: (data: DatatableResponse<League>, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getById(id: number): Promise<League> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `leagues/${id}`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: League, status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static getByMember(): Promise<League[]> {
		return new Promise((resolve, reject) => {
			$.ajax({
				beforeSend: (request) => {
					request.setRequestHeader("Authorization", LoginService.getTokenHeader());
				},
				url: AppConfig.apiUrl + `member/leagues`,
				cache: false,
				method: "GET",
				contentType: "application/json",
				success: (data: League[], status) => {
					resolve(data);
				},
				error: (xhr, status, errorThrown) => {
					LoginService.check(xhr);
					reject();
				},
				complete: (xhr) => {
					resolve();
				}
			});
		});
	}

	public static get(options: LeagueFilterOptions): Promise<PagedList<League>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`leagues`,
				{
					params: options,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static assign(leagueId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`leagues/${leagueId}/assign`, { leagueId, memberId }).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static Unassign(leagueId: number, memberId: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`leagues/${leagueId}/unassign`, { leagueId, memberId }).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsert(league: League): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`leagues`, league).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static delete(leagueId: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`leagues/${leagueId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
