import * as React from "react";
import * as ReactDOM from "react-dom";
import classnames from "classnames";

import { useState, useEffect } from "react";

import { Card, CardBody, CardHeader, UncontrolledCollapse, Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, ModalFooter, ModalHeader, Container } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { DelayInput } from "react-delay-input";
import { Options } from "@data";
import * as Queue from "promise-queue";
import History from "@helpers/history.helper";

import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as NProgress from "nprogress";

import * as moment from "moment";
import { SelectOption } from "@models/forms";
import ObjectHelper from "@helpers/object.helper";
import { Format } from "@models/format";
import { ArrayHelper } from "@helpers/array.helper";
import { FormatService } from "@services/format.service";
import { ActionEntrant, ActionType, AddComment, MatchAction } from "@models/action";
import { Match, UpdateMatch } from "@models/match";
import { MatchService } from "@services/match.service";
import { LoginService } from "@services/login.service";
import { BookingService } from "@services/booking.service";
import { ConsentAssociations, CreateConsentAccept } from "@models/consent";
import { toast } from "react-toastify";
import { ContactService } from "@services/contact.service";
import { Toggle } from "@components/controls/Toggle";
import { ConsentService } from "@services/consent.service";
import { ProgressButton } from "@components/controls/ProgressButton";
import InputSpinner from "react-bootstrap-input-spinner";
import { CompetitionPreset, SetupCompetition } from "@models/competition";
import { CompetitionService } from "@services/competition.service";

interface ICompetitionWizardModal {
	show?: boolean;
	fastFormat?: boolean;
	onClose?: () => void;
	onConfirm?: () => void;
}

enum Steps {
	Type = 1,
	Details = 2,
	Divisions = 3,
	Teams = 4,
	Confirm = 5
}

const CompetitionWizardModal = (props: ICompetitionWizardModal) => {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [show, setShow] = useState<boolean>(props.show);

	const [step, setStep] = useState<Steps>(Steps.Type);
	const [setup, setSetup] = useState<SetupCompetition>(new SetupCompetition());

	const [presets, setPresets] = useState<CompetitionPreset[]>([]);

	useEffect(() => {
		if (props.show) {
			setSetup(new SetupCompetition());
			setStep(Steps.Type);
		}
		setShow(props.show);
	}, [props.show]);

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		const presets = await CompetitionService.getPresets();
		setPresets(presets);
	}

	const handleCloseClick = (e) => {
		props.onClose();
		setShow(false);
	};

	const handleConfirmClick = async (e) => {
		if(setup.name?.length > 0 && setup.prefabId != null && setup.divisions > 0 && setup.teams > 0){
			NProgress.start();
			var setupData = setup;
			setupData.fastFormat = props.fastFormat;
			await CompetitionService.setup(setupData);
			NProgress.done();

			toast.success("League setup");
			props.onConfirm();
			props.onClose();
			setShow(false);
		}
	}

	const handlePreviousClick = (e) => {
		switch (step) {
			case Steps.Details:
				setStep(Steps.Type);
				break;
			case Steps.Divisions:
				setStep(Steps.Details);
				break;
			case Steps.Teams:
				setStep(Steps.Divisions);
				break;
			case Steps.Confirm:
				setStep(Steps.Teams);
				break;
			default:
				break;
		}
	}

	const handleNextClick = (e) => {
		switch (step) {
			case Steps.Type:
				setStep(Steps.Details);
				break;
			case Steps.Details:
				setStep(Steps.Divisions);
				break;
			case Steps.Divisions:
				setStep(Steps.Teams);
				break;
			case Steps.Teams:
				setStep(Steps.Confirm);
				break;
			default:
				break;
		}
	}

	return (
		<Modal size="lg" className="modal-wizard" isOpen={show} style={{ zIndex: 99999 }}>
			<ModalBody className="p-4">
				<Container className="d-flex align-items-center h-100">
					<div id="wizard" role="application" className="w-100 wizard clearfix vertical">
						<div className="steps clearfix">
							<ul role="tablist">
								<li role="tab" className={`first ${classnames({ "current": step === Steps.Type })} ${classnames({ "done": step > Steps.Type })}`} aria-disabled="false" aria-selected="true">
									<a id="wizard-t-0" href="#wizard-h-0" aria-controls="wizard-p-0">
										<span className="current-info audible">current step: </span>
										<span className="number">1</span>
									</a>
								</li>
								<li role="tab" className={`${classnames({ "current": step === Steps.Details })} ${classnames({ "done": step > Steps.Details })}`} aria-disabled="true">
									<a id="wizard-t-1" href="#wizard-h-1" aria-controls="wizard-p-1">
										<span className="number">2</span>
									</a>
								</li>
								<li role="tab" className={`last ${classnames({ "current": step === Steps.Divisions })} ${classnames({ "done": step > Steps.Divisions })}`} aria-disabled="true">
									<a id="wizard-t-2" href="#wizard-h-2" aria-controls="wizard-p-2">
										<span className="number">3</span></a>
								</li>
								<li role="tab" className={`last ${classnames({ "current": step === Steps.Teams })} ${classnames({ "done": step > Steps.Teams })}`} aria-disabled="true">
									<a id="wizard-t-2" href="#wizard-h-2" aria-controls="wizard-p-2">
										<span className="number">4</span></a>
								</li>
								<li role="tab" className={`last ${classnames({ "current": step === Steps.Confirm })} ${classnames({ "done": step > Steps.Confirm })}`} aria-disabled="true">
									<a id="wizard-t-2" href="#wizard-h-2" aria-controls="wizard-p-2">
										<span className="number">5</span></a>
								</li>
							</ul>
						</div>
						<div className="content clearfix w-100 align-items-center">
							<div className={`step step-1 ${classnames({ "d-block": step === Steps.Type }, { "d-none": step !== Steps.Type })}`}>
								<h3 id="wizard-h-0" tabIndex={-1} className="title current">Step 1 Title</h3>
								<section id="wizard-p-0" role="tabpanel" aria-labelledby="wizard-h-0" className={`body current`} aria-hidden="false">
									<h2 className="bd-wizard-step-title">Step 1</h2>
									<h3 className="section-heading">Select league type</h3>
									<p>Choose from the options below, with preset match format and scoring.</p>
									<div className="purpose-radios-wrapper">
										{
											presets.map((p, i) => (
												<div className="purpose-radio">
													<input type="radio" name="purpose" id={`preset_${i}`} className="purpose-radio-input" onChange={(e) => setSetup({ ...setup, prefabId: e.currentTarget.value })} value={p.id} />
													<label htmlFor={`preset_${i}`} className="purpose-radio-label">
														<span className="label-icon">
															<i className={`fal fa-circle-${i + 1} fa-2x`} />
														</span>
														<span className="label-text">{p.name}</span>
														<span className="label-info">{Options.gameFormats.find(g => g.value == p.gamesFormat).label}, {p.gamesPerSet} games</span>
													</label>
												</div>
											))
										}
									</div>
									<div className="actions w-100 text-left">
										<a onClick={handleCloseClick} className="btn btn-light btn-rounded mr-3" role="menuitem">Setup Manually</a>
										<a onClick={handleNextClick} className="btn btn-primary btn-rounded" role="menuitem">Next</a>
									</div>
								</section>
							</div>
							<div className={`step step-2 ${classnames({ "d-block": step === Steps.Details }, { "d-none": step !== Steps.Details })}`}>
								<h3 id="wizard-h-1" tabIndex={-1} className="title">Step 2 Title</h3>
								<section id="wizard-p-1" role="tabpanel" aria-labelledby="wizard-h-1" className="body" aria-hidden="true">
									<h2 className="bd-wizard-step-title">Step 2</h2>
									<h3 className="section-heading">Enter display details</h3>
									<p>How you do you want league to display on the website</p>
									<div className="form-group">
										<label htmlFor="name" className="sr-only">Display Name</label>
										<input type="text" name="name" id="name" className="form-control" placeholder="Enter league name" value={setup.name} onChange={(e) => setSetup({ ...setup, name: e.currentTarget.value })} />
									</div>
									<div className="form-group">
										<label htmlFor="lastName" className="sr-only">Summary</label>
										<input type="text" name="description" id="description" className="form-control" placeholder="Enter league summary" value={setup.description} onChange={(e) => setSetup({ ...setup, description: e.currentTarget.value })} />
									</div>
									<div className="actions w-100 text-left">
										<a onClick={handlePreviousClick} className="btn btn-light btn-rounded mr-3" role="menuitem">Previous</a>
										<a onClick={handleNextClick} className="btn btn-primary btn-rounded" role="menuitem">Next</a>
									</div>
								</section>
							</div>
							<div className={`step step-3 ${classnames({ "d-block": step === Steps.Divisions }, { "d-none": step !== Steps.Divisions })}`}>
								<h3 id="wizard-h-2" tabIndex={-1} className="title">Step 3 Title</h3>
								<section id="wizard-p-2" role="tabpanel" aria-labelledby="wizard-h-2" className="body" aria-hidden="true">
									<h2 className="bd-wizard-step-title">Step 3</h2>
									<h3 className="section-heading">Division setup</h3>
									<p>Split your competition into divisions or just select the one</p>
									<div className="form-group">
										<label htmlFor="firstName">Total</label>
										<InputSpinner
											type={'real'}
											precision={0}
											max={10}
											min={0}
											step={1}
											onChange={(num) => setSetup({ ...setup, divisions: num })}
											variant={'dark'}
											size="sm"
											value={setup.divisions ?? 0}
										/>
									</div>
									<div className="actions w-100 text-left">
										<a onClick={handlePreviousClick} className="btn btn-light btn-rounded mr-3" role="menuitem">Previous</a>
										<a onClick={handleNextClick} className="btn btn-primary btn-rounded" role="menuitem">Next</a>
									</div>
								</section>
							</div>
							<div className={`step step-4 ${classnames({ "d-block": step === Steps.Teams }, { "d-none": step !== Steps.Teams })}`}>
								<h3 id="wizard-h-2" tabIndex={-1} className="title">Step 4 Title</h3>
								<section id="wizard-p-2" role="tabpanel" aria-labelledby="wizard-h-2" className="body" aria-hidden="true">
									<h2 className="bd-wizard-step-title">Step 4</h2>
									<h3 className="section-heading">Teams setup</h3>
									<p>Select how many teams you want per division</p>
									<div className="form-group">
										<label htmlFor="firstName">Teams</label>
										<InputSpinner
											type={'real'}
											precision={0}
											max={10}
											min={0}
											step={1}
											onChange={(num) => setSetup({ ...setup, teams: num })}
											variant={'dark'}
											size="sm"
											value={setup.teams ?? 0}
										/>
										<span className="small">Per division</span>
									</div>
									<div className="actions w-100 text-left">
										<a onClick={handlePreviousClick} className="btn btn-light btn-rounded mr-3" role="menuitem">Previous</a>
										<a onClick={handleNextClick} className="btn btn-primary btn-rounded" role="menuitem">Next</a>
									</div>
								</section>
							</div>
							<div className={`step step-4 ${classnames({ "d-block": step === Steps.Confirm }, { "d-none": step !== Steps.Confirm })}`}>
								<h3 id="wizard-h-2" tabIndex={-1} className="title">Step 5 Title</h3>
								<section id="wizard-p-2" role="tabpanel" aria-labelledby="wizard-h-2" className="body" aria-hidden="true">
									<h2 className="bd-wizard-step-title">Step 5</h2>
									<h3 className="section-heading">Confirmation</h3>
									<p>Check the details below and click confirm to create your new league</p>
									<div className="summary">
										<label>Preset</label>
										<p>{presets.find(p => p.id == setup.prefabId)?.name}</p>
										<label>Name</label>
										<p>{setup.name}</p>
										<label>Summary</label>
										<p>{setup.description}</p>
										<label>Divisions</label>
										<p>{setup.divisions}</p>
										<label>Teams</label>
										<p>{setup.teams}</p>
									</div>
									<div className="actions w-100 text-left">
										<a onClick={handlePreviousClick} className="btn btn-light btn-rounded mr-3" role="menuitem">Previous</a>
										<a onClick={handleConfirmClick} className={`btn btn-${setup.name?.length > 0 && setup.prefabId != null && setup.divisions > 0 && setup.teams > 0 ? "primary" : "light"} btn-rounded`} role="menuitem">Confirm</a>
									</div>
								</section>
							</div>
						</div>
					</div>
				</Container>

			</ModalBody>
		</Modal>
	);
};

export default CompetitionWizardModal;
