import { AppConfig } from "../config";
import RestHelper from "@helpers/rest.helper";

import { LoginService } from "./login.service";

import { RestException } from "../models/exceptions";

import store from "store2";

import * as moment from "moment";
import { SelectOption } from "@models/forms";
import { Booking, BookingCategory, BookingGroup, BookingResource, BookingRule, BookingRuleMembership, BookingSchedule, BookingSlot, BookingSpace, BookingTime, CalendarTimeframe, CreateBooking, CreateBookingReservation } from "@models/booking";

import * as qs from "qs";
import { BookingFilterOptions, BookingSlotFilterOptions, ConsentAgreementsFilterOptions, FilterOptions, ResourcesFilterOptions } from "@models/filters/filterOptions";
import { IPaging, PagedList } from "@models/paging";
import { ConsentAssociations } from "@models/consent";

export class BookingService {

	constructor() {
		//
	}

	public static getById(id: number): Promise<Booking> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getSpaces(query: FilterOptions): Promise<PagedList<BookingSpace>> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/spaces`,
				{
					params: query,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getSpaceById(spaceId: number): Promise<BookingSpace> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/space/${spaceId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getRules(categoryId: number): Promise<BookingRule[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/category/${categoryId}/rules`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCategoryById(categoryId: number): Promise<BookingCategory> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/category/${categoryId}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getCategories(spaceId: number): Promise<BookingCategory[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${spaceId}/categories`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getGrouped(query: BookingFilterOptions): Promise<BookingGroup[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/grouped`,
				{
					params: query,
					paramsSerializer: params => qs.stringify(params, {
						serializeDate: (date: Date) => moment(date).format("YYYY-MM-DDTHH:mm:ssZ")
					})
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getBookings(spaceId: number, query: BookingFilterOptions): Promise<Booking[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${spaceId}/booked`,
				{
					params: query,
					paramsSerializer: params => qs.stringify(params, {
						serializeDate: (date: Date) => moment(date).format("YYYY-MM-DDTHH:mm:ssZ")
					})
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	// public static getResources(bookingSpaceId: number): Promise<BookingResource[]> {
	// 	return new Promise((resolve, reject) => {
	// 		RestHelper.get(`bookings/${bookingSpaceId}/resources`).then(result => {
	// 			resolve(result.data);
	// 		}).catch(error => {
	// 			reject();
	// 		});
	// 	});
	// }

	public static getResources(query: ResourcesFilterOptions): Promise<BookingResource[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/resources`,
				{
					params: query,
					paramsSerializer: params => qs.stringify(params, {
						serializeDate: (date: Date) => moment(date).format("YYYY-MM-DDTHH:mm:ssZ"),
					})
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getResourcesById(spaceId: number): Promise<BookingResource[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${spaceId}/resources`).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getTimes(spaceId: number, scheduleId?: number): Promise<BookingTime[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get<BookingTime[]>(`bookings/${spaceId}/times/${scheduleId ?? ""}`).then(result => {
				result.data.forEach(item => {
					if (item.categoryId === null) { item.categoryId = undefined; }
					if (item.resourceId === null) { item.resourceId = undefined; }
					if (item.scheduleId === null) { item.scheduleId = undefined; }
				});
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getSchedule(spaceId: number, scheduleId?: number): Promise<BookingSchedule> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${spaceId}/schedule/${scheduleId ?? ""}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static setTimes(times: BookingTime[], spaceId: number, scheduleId: number, resourceId: number): Promise<BookingTime[]> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/${spaceId}/times/${scheduleId}/resource/${resourceId ?? ""}`, times).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsertSpace(space: BookingSpace): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/space`, space).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsertResource(resource: BookingResource): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/${resource.spaceId}/resources`, resource).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsertCategory(category: BookingCategory): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/${category.spaceId}/category`, category).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsertRule(rule: BookingRule): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/category/${rule.categoryId}/rule`, rule).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static upsertMembership(membership: BookingRuleMembership): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/rule/${membership.ruleId}/membership`, membership).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static deleteMembership(membership: BookingRuleMembership): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.delete(`bookings/rule/${membership.ruleId}/membership/${membership.id}`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static book(booking: CreateBooking): Promise<Booking> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/book`, booking).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error.response.data);
			});
		});
	}

	public static reserve(reservation: CreateBookingReservation): Promise<Booking> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/reserve`, reservation).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject(error.response.data);
			});
		});
	}

	public static getPrice(categoryId: number, spaceId: number, slots: number, attendees: number[], bookeeId?: number): Promise<number> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/price`,
				{
					params: { bookeeId, categoryId, spaceId, attendees, slots },
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static confirm(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/${id}/confirm`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}

	public static getTimeFrame(spaceId: number): Promise<CalendarTimeframe> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${spaceId}/timeframe`).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static getSlots(query: BookingSlotFilterOptions): Promise<BookingSlot[]> {
		return new Promise((resolve, reject) => {
			RestHelper.get(`bookings/${query.spaceId}/slots`,
				{
					params: query,
					paramsSerializer: params => qs.stringify(params)
				}).then(result => {
					resolve(result.data);
				}).catch(error => {
					reject();
				});
		});
	}

	public static deleteSpace(spaceId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`bookings/space/${spaceId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static deleteCategory(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`bookings/category/${id}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static deleteResource(resourceId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`bookings/resource/${resourceId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static deleteRule(ruleId: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper
				.delete(`bookings/rule/${ruleId}`)
				.then(result => {
					resolve(result.data);
				}).catch(error => {
					reject(error.response.data);
				});
		});
	}

	public static cancel(id: number): Promise<boolean> {
		return new Promise((resolve, reject) => {
			RestHelper.post(`bookings/${id}/cancel`).then(result => {
				resolve(result.data);
			}).catch(error => {
				reject();
			});
		});
	}
}
