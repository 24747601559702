import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, ModalHeader, ModalFooter } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import History from "@helpers/history.helper";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact, ContactCredits } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Booking } from "@models/booking";
import { EventMember } from "@models/event";
import { BookingService } from "@services/booking.service";
import { PagedList } from "@models/paging";
import { MembershipStatus, MembershipUser } from "@models/membership";
import { Invoice, InvoiceStatus } from "@models/invoice";
import LocaleHelper from "@helpers/locale.helper";
import { Consent, ConsentAgreement } from "@models/consent";

interface ICredits {
	contact: Contact;
	onClose?: () => void;
	onConfirm?: () => void;
}

export default (props: ICredits) => {
	const [contact, setContact] = useState<Contact>(props.contact);
	const [credits, setCredits] = useState<ContactCredits>();

	useEffect(() => {
		load();
	}, []);

	const load = async () => {
		ContactService.getCredits(contact?.id).then(credits => {
			setCredits(credits);
		})
	};

	return (
		<>
			<Card className="mb-5 p-5">
				<h6 className="m-0">Credits</h6>
				<Row className="mb-3">
					<Col>
						<Label>Credit for Sessions/Events/Table Bookings</Label>
					</Col>
				</Row>
				<Row>
					<Col>
						Available Credits:
					</Col>
					<Col>
						{credits?.availableCredits ?? 0}
					</Col>
				</Row>
				<Row>
					<Col>
						Used Credits:
					</Col>
					<Col>
						{credits?.usedCredits ?? 0}
					</Col>
				</Row>
			</Card>
		</>
	);
};