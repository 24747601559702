export interface Field {
	id: number;
	tenantId: number | null;
	parentId: number | null;
	parent: Field;
	groupId: number;
	group: FieldGroup;
	name: string;
	label: string;
	help: string;
	placeholder: string;
	type: FieldType;
	options: FieldOption[];
}

export enum FieldType {
	Text = 1,
	Number = 2,
	Dropdown = 3,
	Checkbox = 4,
	MultiChoice = 5,
	TextArea = 6
}

export enum FieldArea {
	Members = 1,
	Contacts = 2
}

export interface FieldOption {
	id: number;
	parentId: number | null;
	parent: FieldOption;
	fieldId: number;
	label: string;
}

export interface FieldGroup {
	id: number;
	tenantId: number | null;
	area: FieldArea;
	name: string;
	description: string;
	fields: Field[];
}

export class FieldValue {
	public id: number;
	public fieldId: number;
	public itemId: string;
	public optionId: number | null;
	public value: string;
	public updated: string;
}