import * as React from "react";
import { Link } from "react-router-dom";
import { AppConfig } from "@config";
import { AppCache } from "@cache";
import * as moment from "moment";
import { ArticleService } from "@services/article.service";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from "react-html-parser";
import {
	TwitterTimelineEmbed,
	TwitterShareButton,
	TwitterFollowButton,
	TwitterHashtagButton,
	TwitterMentionButton,
	TwitterTweetEmbed,
	TwitterMomentShare,
	TwitterDMButton,
	TwitterVideoEmbed,
	TwitterOnAirButton,
} from "react-twitter-embed";
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page, Group } from "react-facebook";
import { WebsiteImageType, WebsiteImage } from "@models/website";
import { PagedList } from "@models/paging";
import History from "@helpers/history.helper";
import { Helmet } from "react-helmet";

interface IArticleViewPageProps {
	site?: string;
	match?: any;
}

interface IArticleViewPageState {
	loading: boolean;
	nextPathname?: any;
	article?: Article;
	latest?: PagedList<Article>;
	featured?: PagedList<Article>;
}

export class ArticleViewPage extends React.Component<
	IArticleViewPageProps,
	IArticleViewPageState
> {
	private articleId: number;
	private slug: string;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	componentDidMount() {
		//
		this.articleId = this.props.match.params.articleId as number;
		this.slug = this.props.match.params.slug as string;

		this.load();
	}

	componentDidUpdate(props: IArticleViewPageProps, state: IArticleViewPageState) {
		this.articleId = this.props.match.params.articleId as number;
		this.slug = this.props.match.params.slug as string;

		if (props.match.params.slug !== this.slug) {
			this.load();
		}
	}

	load() {
		const p1 = ArticleService.getBySlug(this.slug).then((article) => {
			this.setState({ article });
		});

		const p2 = ArticleService.get({ page: 1, count: 5 }).then((articles) => {
			this.setState({ latest: articles });
		});

		const p3 = ArticleService.getFeatured({ page: 1, count: 5 }).then((articles) => {
			this.setState({ featured: articles });
		});

		Promise.all([p1, p2, p3]).then((result) => {
			if (!this.state.article) {
				History.push("/not-found");
			}

			this.setState({ loading: false });
		});
	}

	public render() {
		const newsImage: WebsiteImage = AppCache.website.images.find(
			(i) => i.type === WebsiteImageType.News
		);

		const headerImage: WebsiteImage = AppCache.website.images.find(
			(_) => _.type === WebsiteImageType.Header
		);

		return (
			<PublicLayout
				theme='scheme_default'
				className='post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout'
			>
				<Helmet>
					<title>{this.state.article?.title}</title>
				</Helmet>
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							{AppCache.website.config.subHeader ? (
								<div className='elementor elementor-6 d-print-none'>
									<section
										className='elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section'
										style={
											this.state.article.headerId
												? {
													backgroundImage: `url('${AppConfig.baseUrl}image/${this.state.article.headerId}')`,
												}
												: headerImage
													? {
														backgroundImage: `url(${AppConfig.baseUrl}image/${headerImage.imageId})`,
													}
													: {}
										}
									>
										<div className='elementor-container elementor-column-gap-extended'>
											<div className='elementor-row'>
												<div className='elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column'>
													<div className='elementor-column-wrap  elementor-element-populated'>
														<div className='elementor-widget-wrap'>
															<div
																className='sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='6850b4b'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='b76221f'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn'
																data-id='612f306'
																data-element_type='widget'
																data-settings='{"_animation":"fadeIn"}'
																data-widget_type='trx_sc_layouts_title.default'
															>
																<div className='elementor-widget-container'>
																	<div
																		id='trx_sc_layouts_title_1841591666'
																		className='sc_layouts_title with_content without_image without_tint'
																	>
																		<div className='sc_layouts_title_content'>
																			<div className='sc_layouts_title_title'>
																				<h1 className='sc_layouts_title_caption'>
																					{this.state.article.title}
																				</h1>
																				<p className='header--description'>
																					{this.state.article.description}
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='01551c2'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div>
							) : (
								<div className='header_content_wrap mt-5 d-print-none'>
									<div className='container'>
										<div className='row'>
											<div className='col-12'>
												<h1 className='sc_layouts_title_caption'>
													{this.state.article.title}
												</h1>
												{this.state.article.description ? (
													<p className='header--description'>
														{this.state.article.description}
													</p>
												) : undefined}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className='header_content_wrap mt-5 d-none d-print-block'>
								<div className='container'>
									<div className='row'>
										<div className='col-12'>
											<h1 className='sc_layouts_title_caption'>
												{this.state.article.title}
											</h1>
											{this.state.article.description ? (
												<p className='header--description'>
													{this.state.article.description}
												</p>
											) : undefined}
										</div>
									</div>
								</div>
							</div>
							<div className='page_content_wrap'>
								<div className='container'>
									<div className='row'>
										<div className='col-lg col-md-12 mb-4'>
											<div className='content'>
												<article
													className='post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league'
												>
													<div className='header_content_wrap header_align_mc'>
														{this.state.article.featuredId ? (
															<div className='post_featured'>
																<img
																	width='1920'
																	height='1080'
																	src={`${AppConfig.baseUrl}image/${this.state.article.featuredId}`}
																	className='attachment-fcunited-thumb-full size-fcunited-thumb-full wp-post-image'
																	alt=''
																/>
															</div>
														) : undefined}

														<div className='post_header post_header_single entry-header'>
															<div className='post_meta post_meta_other'>
																{this.state.article.category ? (
																	<span className='post_meta_item post_categories'>
																		<Link
																			to={`/articles/category/${this.state.article.category}`}
																		>
																			{this.state.article.category}
																		</Link>
																	</span>
																) : undefined}
																<span className='post_meta_item post_date'>
																	{this.state.article.published
																		? moment(
																			this.state.article.published
																		).format("Do MMMM YYYY")
																		: "Preview Only"}
																</span>{" "}
															</div>
														</div>
													</div>
													<div className='post_content post_content_single entry-content'>
														<div>
															{ReactHtmlParser(this.state.article.body)}
														</div>

														<div className='post_meta post_meta_single'>
															{this.state.article.tags.length > 0 ? (
																<span className='post_meta_item post_tags'>
																	<span className='post_meta_label'>Tags:</span>
																	{this.state.article.tags.map((t) => {
																		return (
																			<Link to={`/articles/tag/${t}`} rel='tag'>
																				{t}
																			</Link>
																		);
																	})}
																</span>
															) : undefined}
														</div>
													</div>
												</article>
											</div>
										</div>
										<div className='col-lg-4 col-md-12 right mb-4'>
											<div className='sidebar widget_area sidebar_below d-print-none'>
												<div className='sidebar_inner'>
													<div className='row'>
														<div className='col-12 col-md-6 col-lg-12 mb-4'>
															<aside className='widget widget_recent_posts'>
																<h5 className='widget_title'>recent news</h5>
																{this.state.latest && this.state.latest.items.map((a) => {
																	return (
																		<article className='post_item with_thumb'>
																			<div className='post_thumb'>
																				<Link to={`/article/${a.slug}`}>
																					<img
																						width='90'
																						height='90'
																						src={
																							a.previewId
																								? `${AppConfig.baseUrl}image/${a.previewId}`
																								: newsImage
																									? `${AppConfig.baseUrl}image/${newsImage.imageId}`
																									: require("@assets/images/placeholders/tte-square.png")
																						}
																						className='attachment-fcunited-thumb-tiny size-fcunited-thumb-tiny wp-post-image'
																						alt={`${a.title}`}
																					/>
																				</Link>
																			</div>
																			<div className='post_content'>
																				<h6 className='post_title'>
																					<Link to={`/article/${a.slug}`}>
																						{a.title}
																					</Link>
																				</h6>
																				<div className='post_info'>
																					<span className='post_info_item post_info_posted'>
																						<Link
																							to={`/articles/${a.slug}`}
																							className='post_info_date'
																						>
																							{moment(a.published).format(
																								"Do MMMM YYYY"
																							)}
																						</Link>
																					</span>
																					<span className='post_info_item post_info_posted_by'>
																						by{" "}
																						<span className='post_info_author'>
																							admin
																						</span>
																					</span>
																				</div>
																			</div>
																		</article>
																	);
																})}
															</aside>
														</div>
														{
															this.state.featured && this.state.featured.total > 0 &&
															<div className='col-12 col-md-6 col-lg-12 mb-4'>
																<aside className='widget widget_recent_posts'>
																	<h5 className='widget_title'>featured news</h5>
																	{this.state.featured && this.state.featured.items.map((a) => {
																		return (
																			<article className='post_item with_thumb'>
																				<div className='post_thumb'>
																					<Link to={`/article/${a.slug}`}>
																						<img
																							width='90'
																							height='90'
																							src={
																								a.previewId
																									? `${AppConfig.baseUrl}image/${a.previewId}`
																									: newsImage
																										? `${AppConfig.baseUrl}image/${newsImage.imageId}`
																										: require("@assets/images/placeholders/tte-square.png")
																							}
																							className='attachment-fcunited-thumb-tiny size-fcunited-thumb-tiny wp-post-image'
																							alt={`${a.title}`}
																						/>
																					</Link>
																				</div>
																				<div className='post_content'>
																					<h6 className='post_title'>
																						<Link to={`/article/${a.slug}`}>
																							{a.title}
																						</Link>
																					</h6>
																					<div className='post_info'>
																						<span className='post_info_item post_info_posted'>
																							<Link
																								to={`/article/${a.slug}`}
																								className='post_info_date'
																							>
																								{moment(a.published).format(
																									"Do MMMM YYYY"
																								)}
																							</Link>
																						</span>
																						<span className='post_info_item post_info_posted_by'>
																							by{" "}
																							<span className='post_info_author'>
																								admin
																							</span>
																						</span>
																					</div>
																				</div>
																			</article>
																		);
																	})}
																</aside>
															</div>
														}
														{AppCache.website.twitter && (
															<div className='col-12 col-md-6 col-lg-12 mb-4'>
																<aside
																	id='text-4'
																	className='widget widget_text'
																>
																	<h5 className='widget_title'><i className="fab fa-x-twitter"></i></h5>
																	<div>
																		<TwitterTimelineEmbed
																			sourceType='profile'
																			placeholder="LOADING"
																			screenName={`${AppCache.website.twitter}`}
																			options={{ height: 400 }}
																		/>
																	</div>
																</aside>
															</div>
														)}
														{AppCache.website.facebook ? (
															<div className='col-12 col-md-6 col-lg-12 mb-4'>
																<aside
																	id='text-4'
																	className='widget widget_text p-20'
																>
																	<h5 className='widget_title'>facebook</h5>
																	<div>
																		<FacebookProvider appId='412401072952926'>
																			{AppCache.website.facebook.indexOf("groups") >= 0 && <Group href={`https://www.facebook.com/${AppCache.website.facebook}/`} showSocialContext={true} showMetaData={true} />}
																			{AppCache.website.facebook.indexOf("page") < 0 && <Page href={`https://www.facebook.com/${AppCache.website.facebook}/`} tabs="timeline" />}
																		</FacebookProvider>
																	</div>
																</aside>
															</div>
														) : undefined}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</PublicLayout>
		);
	}
}
