import * as React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { FormGroup, Label, Input, Col, Row, Modal, ModalBody, Container, Card, CardBody } from "reactstrap";

import { Draggable, Dropzone, state } from "react-page-maker";
import { elements } from "../elements";

const DraggableRow = (props) => {
	const [showBasicContent, setShowBasicContent] = useState(props.showBasicContent);
	const [id, setId] = useState(props.id);
	const [showPreview, setShowPreview] = useState(props.showPreview);
	const [initialElements, setInitialElements] = useState(props.initialElements);
	const [dropzoneID, setDropzoneID] = useState(props.dropzoneID);
	const [parentID, setParentID] = useState(props.parentID);
	const [name, setName] = useState(props.name);
	const [open, setOpen] = useState(false);

	const [title, setTitle] = useState<string>(props.payload.title);
	const [setupMode, setSetupMode] = useState<boolean>(props.payload.title ? false : true);

	if (showBasicContent) {
		return (
			<Draggable {...props} >
				<span>{props.name}</span>
			</Draggable>
		);
	}

	if (showPreview) {
		return (
			<div className="sc_layouts_widgets">
				<div className="row">
					<div className="col-12">
						<aside className="widget widget_text p-20 mb-4">
							<h5 className="widget_title">
								{title}
							</h5>
							<div>
								{props.childNode[`widget-1`]}
							</div>
						</aside>
					</div>
				</div>
			</div>
		);
	}

	const filterInitialElements = (dID) => {
		return initialElements.filter(e => e.dropzoneID === dID) || [];
	};

	const onDrop = (data, cb) => {
		if (data.id === props.id) {
			return false;
		}

		// no need to ask id and name again
		if (data.payload && data.payload.dropped) {
			return cb(data);
		}

		const id = uuidv4();

		const result = cb({
			...data,
			id,
			payload: { ...data.payload, dropped: true }
		});
	};

	const handleConfirm = () => (e: any) => {
		setSetupMode(false);
		state.updateElement(id, dropzoneID, parentID, { payload: { title } });
	};

	return (
		<>
			<Draggable {...props}>
				<div className="pm-container mb-2">
					<Row>
						<Col>
							<span>{title}</span>
						</Col>
						<Col className="text-right">
							<a className="btn p-0 mb-2 mr-2"
								onClick={() => state.removeElement(id, dropzoneID, parentID)}>
								<i className="far fa-times"></i>
							</a>
						</Col>
					</Row>
					<Row className="mb-3">
						<Col>
							<Dropzone
								placeholder="Drop Here"
								parentID={id}
								initialElements={filterInitialElements(`widget-1`)}
								id={`widget-1`}
								onDrop={onDrop}
								capacity={4}
							/>
						</Col>
					</Row>
				</div>
			</Draggable>
			<Modal className={"element-editor modal--transparent"} isOpen={setupMode}>
				<ModalBody className="p-4">
					<Row>
						<Col>
							<Card className="text-center">
								<CardBody>
									<FormGroup>
										<Label>Widget Title</Label>
										<Input value={title} placeholder="Enter title" onChange={e => setTitle(e.target.value)} />
									</FormGroup>
									<Row>
										<Col>
											<a tabIndex={0} className="btn btn-primary btn-rounded w-100" onClick={handleConfirm()}>Confirm</a>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		</>
	);
};

export default DraggableRow;
