import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label, Card, CardBody, CardHeader } from "reactstrap";

import * as NProgress from "nprogress";
import * as moment from "moment";

import { Toggle } from "@components/controls/Toggle";
import { FieldService } from "@services/field.service";
import { FieldArea, FieldGroup, FieldType, FieldValue } from "@models/field";
import { Contact } from "@models/contact";
import { ContactService } from "@services/contact.service";
import { toast } from "react-toastify";
import { Registration, RegistrationCategory, RegistrationEntrantType, RegistrationStatus } from "@models/registration";
import { CostFeedback, Entry, EntryCosts, EntryPlayer, EntryTeam } from "@models/entry";
import { EntryService } from "@services/entry.service";
import { ExtendedButton } from "@components/controls/ExtendedButton";
import { tokenToString } from "typescript";
import { confirmAlert } from "react-confirm-alert";
import LocaleHelper from "@helpers/locale.helper";
import { ActivitySpinner } from "@components/controls/ActivitySpinner";

interface IEntryTeams {
	entry: Entry;
	registration: Registration;
	category: RegistrationCategory;
	costs: EntryCosts;
	onClose?: () => void;
	onConfirm?: () => void;

	onUpdateTeam?: (team: EntryTeam) => void;
	onRegisterTeam?: (category: RegistrationCategory, team?: EntryTeam) => void;
	onRegisterPlayer?: (category: RegistrationCategory, team: EntryTeam, player?: EntryPlayer) => void;
	onRemoveTeam?: (team: EntryTeam) => void;
}

export default (props: IEntryTeams) => {
	const [loading, setLoading] = useState<boolean>(true);

	const [costs, setCosts] = useState<EntryCosts>();
	const [teams, setTeams] = useState<EntryTeam[]>([]);
	const [team, setTeam] = useState<EntryTeam>();
	const [players, setPlayers] = useState<EntryPlayer[]>([]);
	const [showEditTeam, setShowEditTeam] = useState<boolean>();
	const [showDeletedTeams, setShowDeletedTeams] = useState<boolean>(false);

	useEffect(() => {
		load();
	}, [, props.entry, showDeletedTeams]);

	useEffect(() => {
		setCosts(props.costs);
	}, [props.costs])

	const load = async () => {
		const requests = [];

		const teams = EntryService.teams({ entryId: props.entry.id, categoryId: props.category.id, deleted: showDeletedTeams }).then(async result => {
			if (props.category.entrantType !== RegistrationEntrantType.Players)
				await loadTeamPlayers(result);
			else
				setTeams(result);
		});

		requests.push(teams);

		if (props.category.entrantType === RegistrationEntrantType.Players) {
			const players = EntryService.players({ categoryId: props.category.id, entryId: props.entry.id }).then(result => {
				setPlayers(result);
			});
			requests.push(players);
		}

		Promise.all(requests).then(result => setLoading(false));
	};

	const loadTeamPlayers = async (teams: EntryTeam[]) => {
		const entryTeams = teams;

		for (let i = 0; i < entryTeams.length; i++) {
			const team = entryTeams[i];
			team.players = await EntryService.players({ teamId: team.id });
		}

		setTeams(entryTeams);
	};

	const handleRegisterTeamClick = (team?: EntryTeam) => (e) => {
		props.onRegisterTeam(props.category, team)
	};

	const handleRegisterPlayerClick = (team?: EntryTeam, player?: EntryPlayer) => (e) => {
		props.onRegisterPlayer(props.category, team, player);
	};

	const removeTeam = async (team: EntryTeam) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will {team.deleted ? "restore" : "delete"} this Team.</p>
						<a className="btn btn-secondary text-white mr-2"
							onClick={() => {
								team.players = null;
								team.category = null;
								EntryService.removeTeam(team).then(() => {
									load();
									onClose();
									toast.success(`Team ${team.deleted ? "Restored" : "Removed"}`);
								});
							}}
						>
							Yes, {team.deleted ? "restore" : "delete"} it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	const removePlayer = async (player: EntryPlayer) => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className="react-confirm-alert--default">
						<h1>Are you sure?</h1>
						<p>This will permanently delete this Player.</p>
						<a className="btn btn-secondary text-white mr-2"
							onClick={() => {
								EntryService.removePlayer(player).then(() => {
									load();
									onClose();
									toast.success("Player Removed");
								});
							}}>
							Yes, delete it!
						</a>
						<a className="btn btn-primary text-white" onClick={onClose}>No</a>
					</div>
				);
			}
		});
	}

	const getPlayerCost = (memberId: string, categoryId: number, paid: boolean) => {
		if(costs){
			let total = 0;
			if (paid) {
				const categoryPaid = costs.paid.filter(p => p.categoryId === categoryId);
				if (categoryPaid.length > 0) {
					total += categoryPaid[0].entryPlayers.find(ep => ep.memberId === memberId)?.cost ?? 0;
				}
			} else {
				const categoryPending = costs.pending.filter(p => p.categoryId === categoryId);
				const categoryUnfinished = costs.unfinished.filter(p => p.categoryId === categoryId);
				const categoryInvoiced = costs.invoiced.filter(p => p.categoryId == categoryId);
				if (categoryPending.length > 0 || categoryUnfinished.length > 0 || categoryInvoiced.length > 0) {
					total += categoryPending[0]?.entryPlayers.find(ep => ep.memberId === memberId)?.cost ?? categoryUnfinished[0]?.entryPlayers.find(ep => ep.memberId === memberId)?.cost ?? categoryInvoiced[0]?.entryPlayers.find(ep => ep.memberId === memberId)?.cost ?? 0;
				}
			}

			
			return total;
		}
	};

	const getTeamCost = (teamId: number, categoryId: number, paid: boolean) => {
		if(costs){
			let total = 0;

			if (paid) {
				const categoryPaid = costs.paid.filter(p => p.categoryId === categoryId);
				if (categoryPaid.length > 0) {
					total += categoryPaid[0].entryTeams.find(et => et.id === teamId)?.cost ?? 0;

					const players = categoryPaid[0].entryPlayers.filter(et => et.teamId === teamId).map(e => e.cost);

					if (players.length > 0) {
						total += players.reduce((a, b) => a + b);
					}
				}
			} else {
				const categoryPending = costs.pending.filter(p => p.categoryId === categoryId);
				const categoryUnfinished = costs.unfinished.filter(p => p.categoryId === categoryId);
				const categoryInvoiced = costs.invoiced.filter(p => p.categoryId == categoryId);
				if (categoryPending.length > 0 || categoryUnfinished.length > 0 || categoryInvoiced.length > 0) {
					total += categoryPending[0]?.entryTeams.find(et => et.id === teamId)?.cost ?? categoryUnfinished[0]?.entryTeams.find(et => et.id === teamId)?.cost ?? categoryInvoiced[0]?.entryTeams.find(et => et.id === teamId)?.cost ?? 0;

					const players = categoryPending[0]?.entryPlayers.filter(et => et.teamId === teamId).map(e => e.cost) ?? categoryUnfinished[0]?.entryPlayers.filter(et => et.teamId === teamId).map(e => e.cost) ?? categoryInvoiced[0]?.entryPlayers.filter(et => et.teamId === teamId).map(e => e.cost) ;

					if (players.length > 0) {
						total += players.reduce((a, b) => a + b);
					}
				}
			}

			return total;
		}
	};

	const handleEditTeamClick = (team: EntryTeam) => (e) => {
		props.onUpdateTeam(team);
	};

	return (
		<>
			<div className="my-4 entry-teams">
				<h6>Teams</h6>
				{loading && <ActivitySpinner height="50" />}
				{
					!loading && teams.map(t => {
						const paidTotal = getTeamCost(t.id, t.categoryId, true);
						const pendingTotal = getTeamCost(t.id, t.categoryId, false);

						return (
							<div>
								<Card className="mb-4 p-4">
									<Row>
										<Col className="my-auto">
											<h5 className="m-0">{t.name} {t.lateEntry == true && <i className="tx-20 icon fas fa-alarm-exclamation"></i>}</h5>
										</Col>
										<Col className="my-auto">
											<Row>
												<Col className="text-center">
													<h6 className="mb-0">{t.players.length}</h6>
													<span className="small">Players</span>
												</Col>
												<Col className="text-center">
													<h6 className="mb-0 text-success">{paidTotal != null ? LocaleHelper.toPrice(paidTotal) : <ActivitySpinner mode={"light"}/>}</h6>
													<span className="small text-success">Paid</span>
												</Col>
												<Col className="text-center">
													<h6 className="mb-0 text-danger">{pendingTotal != null ? LocaleHelper.toPrice(pendingTotal) : <ActivitySpinner  mode={"light"}/>}</h6>
													<span className="small text-danger">Pending</span>
												</Col>
											</Row>
										</Col>
										<Col>
											<div className="text-right">
												{!t.approved && <ExtendedButton className="btn btn-circle btn-outline btn-icon mb-2 mr-2 btn-hover-gray" tooltip={`Team ${t.approved ? "" : "not"} approved`} id={`team-approved-${t.id}`}>
													<div className={`tx-20 ${!t.approved ? "tx-danger" : "tx-success"}`}><i className="fal fa-check"></i></div>
												</ExtendedButton>}
												<ExtendedButton onClick={handleEditTeamClick(t)} className="btn btn-circle btn-outline btn-icon mb-2 mr-2 btn-hover-gray" tooltip={`Team details ${t.ready ? "ready" : "missing "}`} id={`team-details-${t.id}`}>
													<div className={`tx-20 ${!t.ready ? "tx-danger" : "tx-success"}`}><i className="fal fa-address-card"></i></div>
												</ExtendedButton>
												{(props.category.entrantType === RegistrationEntrantType.Teams && props.category.requiredPlayers) && <ExtendedButton className="btn btn-circle btn-outline btn-hover-gray btn-icon mb-2" tooltip={`Required players ${t.players.length >= props.category.requiredPlayers ? "" : "not "}allocated`} id={`team-players-${t.id}`}>
													<div className={`tx-20 ${t.players.length < props.category.requiredPlayers ? "tx-danger" : ""}`}><i className="fal fa-users"></i></div>
												</ExtendedButton>}
												<ExtendedButton className="btn btn-circle btn-outline btn-icon mb-2 mr-2 btn-hover-gray" tooltip={`Team Fees ${t.paid ? "" : "not "}paid`} id={`team-paid-${t.id}`}>
													<div className={`tx-20 ${!t.paid ? "tx-danger" : ""}`}><i className="fal fa-pound-sign"></i></div>
												</ExtendedButton>
												<a onClick={handleEditTeamClick(t)} title="Edit Team" className="btn btn-circle btn-primary btn-outline btn-icon mr-2 mb-2">
													<div className={`tx-20`}><i className="fal fa-pencil"></i></div>
												</a>
												{
													props.category.entrantType !== RegistrationEntrantType.Players &&
													<a onClick={handleRegisterPlayerClick(t)} title="Assign Player" className="btn btn-circle btn-primary btn-outline btn-icon mb-2">
														<div className={`tx-20`}><i className="fal fa-user-plus"></i></div>
													</a>
												}
												{
													(!t.paid && t.invoiceItemId == null) &&
													<a onClick={() => removeTeam(t)} title={`${t.deleted ? "Restore" : "Remove"} Team`} className="btn btn-circle btn-danger text-white btn-outline btn-icon mb-2 ml-2">
														<div className={`tx-20`}><i className="fal fa-times"></i></div>
													</a>
												}
											</div>
										</Col>
									</Row>
								</Card>
								<Row>
									{
										t.players.map(p => {
											const total = getPlayerCost(p.memberId, p.categoryId, p.paid != null);
											return (
												<Col md={6}>
													<Card className="mb-4">
														<CardBody>
															<div className="d-flex">
																<div className="my-auto flex-grow-1">
																	<h6 className="m-0">{p.displayName} {p.lateEntry == true && <i className="tx-20 icon fas fa-alarm-exclamation"></i>}</h6>
																	<p className="m-0">{p.membershipNo}</p>
																</div>
																<div className="mb-auto mt-1 mr-3 text-center price">
																	
																	<span className={`text-large mb-0 cost ${!p.paid ? "text-danger" : "text-success"}`}>
																		{total != null ? LocaleHelper.toPrice(total) : <ActivitySpinner  mode={"light"}/>}
																	</span>
																	<span className={`small d-block ${!p.paid ? "text-danger" : "text-success"}`}>
																		{p.paid ? "Paid" : "Pending"}
																	</span>
																</div>
																<div className="text-right">
																	{props.registration.requireActiveCompete && <ExtendedButton className="btn btn-circle btn-outline btn-icon mb-2 mr-2 btn-hover-gray" tooltip={`Player Membership ${p.expired ? "Expired/Incorrect" : "Active"}`} id={`player-expired-${p.id}`}>
																		<div className={`tx-20 ${p.expired ? "tx-danger" : "tx-success"}`}><i className="icon far fa-check-double"></i></div>
																	</ExtendedButton>}
																	{props.registration.allowPublicSwapPlayer == true && <a className="btn btn-primary btn-circle btn-outline btn-icon mb-2" onClick={handleRegisterPlayerClick(t, p)}>
																		<div className="tx-20"><i className="icon far fa-retweet"></i></div>
																	</a>}
																	{
																		(!p.paid && p.invoiceItemId == null) &&
																		<a className="btn btn-danger btn-circle btn-outline btn-icon mb-2 ml-2 text-white" onClick={() => removePlayer(p)}>
																			<div className="tx-20"><i className="icon far fa-times"></i></div>
																		</a>
																	}
																</div>
															</div>
														</CardBody>
													</Card>
												</Col>
											);
										})
									}
								</Row>
								<hr className="mb-4" />
							</div>);
					})
				}
				{!showDeletedTeams && props.category.entrantType === RegistrationEntrantType.Players && <>
					<h6>Players
						{props.category.requiredPlayers && <span className={`small ml-2 ${players.length >= (props.category.requiredPlayers * teams.filter(t => t.ready).length) ? "tx-success" : "tx-danger"}`}>{players.length}/{props.category.requiredPlayers * teams.filter(t => t.ready).length}</span>}
					</h6>
					<Row>
						{
							!loading && players?.map(p => {
								const total = getPlayerCost(p.memberId, p.categoryId, p.paid != null);
								return (
									<Col md={6}>
										<Card className="mb-4">
											<CardBody>
												<div className="d-flex">
													<div className="my-auto flex-grow-1">
														<h6 className="m-0">{p.displayName} {p.lateEntry == true && <i className="tx-20 icon fas fa-alarm-exclamation"></i>}</h6>
														<p className="m-0">{p.membershipNo}</p>
													</div>
													<div className="mb-auto mt-1 mr-3 text-center price">
														<span className={`text-large mb-0 cost ${!p.paid ? "text-danger" : "text-success"}`}>
															{total != null ? LocaleHelper.toPrice(total) : <ActivitySpinner  mode={"light"}/>}
														</span>
														<span className={`small d-block ${!p.paid ? "text-danger" : "text-success"}`}>
															{p.paid ? "Paid" : "Pending"}
														</span>
													</div>
													<div className="text-right">
														{props.registration.requireActiveCompete && <ExtendedButton className="btn btn-circle btn-outline btn-icon mb-2 mr-2 btn-hover-gray" tooltip={`Player Membership ${p.expired ? "Expired/Incorrect" : "Active"}`} id={`player-expired-${p.id}`}>
															<div className={`tx-20 ${p.expired ? "tx-danger" : "tx-success"}`}><i className="icon far fa-check-double"></i></div>
														</ExtendedButton>}
														{props.registration.allowPublicSwapPlayer == true && <a className="btn btn-primary btn-circle btn-outline btn-icon mb-2" onClick={handleRegisterPlayerClick(null, p)}>
															<div className="tx-20"><i className="icon far fa-retweet"></i></div>
														</a>}
														{
															(!p.paid && p.invoiceItemId == null) &&
															<a className="btn btn-danger btn-circle btn-outline btn-icon mb-2 ml-2 text-white" onClick={() => removePlayer(p)}>
																<div className="tx-20"><i className="icon far fa-times"></i></div>
															</a>
														}
													</div>
												</div>
											</CardBody>
										</Card>
									</Col>
								)
							}
							)}
					</Row>
				</>}
				{!showDeletedTeams && <Row>
					{props.registration.status != RegistrationStatus.Closed && <Col>
						<div className="sp-view-all-link">
							<a onClick={handleRegisterTeamClick()}>
								Add Team
							</a>
						</div>
					</Col>}
					{
						props.category.entrantType === RegistrationEntrantType.Players && teams?.filter(t => !t.deleted).length > 0 && <Col>
							<div className="sp-view-all-link">
								<a onClick={handleRegisterPlayerClick()}>
									Add Player
								</a>
							</div>
						</Col>
					}
				</Row>}
			</div>
		</>
	);
};