import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team, TeamStats, TeamMember } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage, PlayerStats } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Row, Col, Card } from "reactstrap";
import { Standing } from "@models/standing";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import { CompetitionService } from "@services/competition.service";

import { ShowMe } from "@components/controls/ShowMe";
import { MatchService } from "@services/match.service";
import { Match, MatchSet } from "@models/match";
import { AppCache } from "@cache";
import { ResultType } from "@models/result";
import { Toggle } from "@components/controls/Toggle";


interface ITeamPageProps {
	site?: string;
	match?: any;
}

interface ITeamPageState {
	loading: boolean;
	nextPathname?: any;
	competition?: Competition;
	match?: Match;
	sets: MatchSet[];
	stats?: TeamStats;
	members?: TeamMember[];
	showStatus: boolean;
}
export class TeamPage extends React.Component<ITeamPageProps, ITeamPageState> {

	private competitionId: number;
	private teamId: number;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			sets: [],
			showStatus: false
		};
	}

	componentDidMount() {
		//
		this.competitionId = this.props.match.params.competitionId as number;
		this.teamId = this.props.match.params.teamId as number;

		this.load();
	}

	componentDidUpdate(props: ITeamPageProps, state: ITeamPageState) {
		this.teamId = this.props.match.params.teamId as number;

		if (props.match.params.teamId !== this.teamId) {
			this.load();
		}
	}

	load() {
		this.setState({ loading: true });
		var pool: boolean;
		const p1 = CompetitionService.getById(this.competitionId).then(competition => {
			this.setState({ competition });
			pool = competition.reserves.type == 6;
		});

		const p2 = CompetitionService.getTeamStats(this.competitionId, this.teamId).then(stats => {
			this.setState({ stats });
		});
		Promise.all([p1]).then(result => {
			const p3 = TeamService.getMembers(this.teamId, pool).then(members => {
				this.setState({ members });
			});

			Promise.all([p2, p3]).then(result => {
				this.setState({ loading: false });
			});
		});
	}

	public render() {
		return (
			<PublicLayout className="page-template-default page page-id-493 wp-custom-logo ua_chrome woocommerce-js fcunited_inline_120743157 body_tag scheme_default blog_mode_page body_style_wide is_single sidebar_hide expand_content trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top elementor-default elementor-page elementor-page-493 desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					progress={true}
					render={() => (
						<div className="sub-page">
							<div className="page_content_wrap">
								<div className="container">
									<div className="content">
										<div className="elementor-element elementor-element-7c52348 sc_fly_static elementor-widget elementor-widget-trx_sc_title animated fadeIn" data-id="7c52348" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:300}" data-widget_type="trx_sc_title.default">
											<div className="elementor-widget-container">
												<div id="trx_sc_title_789087444" className="sc_title sc_title_default text-center">
													<h2 className="m-0">{this.state.stats.shortName.length > 0 ? this.state.stats.shortName : this.state.stats.name}</h2>
													{this.state.stats.shortName.length > 0 && <p className="text-center">{this.state.stats.name}</p>}
												</div>
											</div>
										</div>
										<div className="stats-details">
											<div className="row">
												<div className="col-6 col-md-4">
													<h3 className="text-center">Club</h3>
													<p className="text-center">{this.state.stats.club.value}</p>
												</div>
												<div className="col-md-4 d-none d-md-block">
													<h3 className="text-center">Competition</h3>
													<p className="text-center">{this.state.stats.competition.value}</p>
												</div>
												<div className="col-6 col-md-4">
													<h3 className="text-center">Division</h3>
													<p className="text-center">{this.state.stats.division.value}</p>
												</div>
											</div>
											<div className="row">
												<div className="col-6 col-md-4">
													<h3 className="text-center">Last</h3>
													{
														this.state.stats.last ?
															<div className="text-center">
																<p className="team"><span className="vs">vs</span> {this.state.stats.last.versus.value}</p>
																<p className="date">
																	<Link to={`/league/${this.competitionId}/division/${this.state.stats.last.divisionId}/match/${this.state.stats.last.id}`}>
																		{moment(this.state.stats.last.date).format("Do MMMM")}
																	</Link>
																</p>
															</div> :
															<p className="text-center">No match</p>
													}
												</div>
												<div className="col-6 col-md-4">
													<h3 className="text-center">Next</h3>
													{
														this.state.stats.next ?
															<div className="text-center">
																<p className="team"><span className="vs">vs</span> {this.state.stats.next.versus.value}</p>
																<p className="date">
																	<Link to={`/league/${this.competitionId}/division/${this.state.stats.next.divisionId}/match/${this.state.stats.next.id}`}>
																		{moment(this.state.stats.next.date).format("Do MMMM")}
																	</Link></p>
															</div> :
															<p className="text-center">No match</p>
													}
												</div>
												<div className="col-12 col-md-4">
													<h3 className="text-center">Form</h3>
													<p className="text-center">
														{
															this.state.stats.results.map(r => {
																return (
																	<Link className={`result result--${r.type}`} to={`/league/${this.competitionId}/division/${this.state.stats.division.key}/match/${r.matchId}`}>
																		<span>{r.home ? "H" : "A"}</span>
																	</Link>
																)
															})
														}
													</p>
												</div>
											</div>
										</div>
										{moment(this.state.competition.startDate).isAfter(moment()) ?
											<div className="member-results">
												<div className="sp-template sp-template-event-performance sp-template-event-performance-values">
													<div className="sp-table-wrapper">
														<div className="sp-scrollable-table-wrapper"><div id="DataTables_Table_1_wrapper" className="dataTables_wrapper">
															<div className="mb-5">
																<div className="mb-5 table-responsive">
																	<table className="data-table-results sp-data-table sp-scrollable-table sp-sortable-table dataTable mb-5" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>{this.state.competition.reserves.type == 6 && "Pool "}Player</th>
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>Membership</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.members.filter(m => !m.transfered).map(p => {
																					return (
																						<tr className="lineup odd" role="row">
																							<td className="data-item nowrap w-100"><Link to={`/league/${this.competitionId}/player/${p.memberId}`}>{p.name}</Link></td>
																							<td className="data-item text-center">
																								<span className="tx-20">
																									<i title={`${p.expired ? "Expired" : "Active"} Membership`} className={`fal fa-${p.expired ? "times text-danger" : "check text-success"}`}></i>|
																									<i title={`${p.incorrectMembership ? "Incorrect" : "Correct"} Membership`} className={`fal fa-${p.incorrectMembership ? "times text-danger" : "check text-success"}`}></i>
																								</span>
																							</td>
																						</tr>
																					)
																				})
																			}
																		</tbody>
																	</table>
																</div>
															</div>
														</div>
														</div>
													</div>
												</div>
											</div>
											:
											<div className="member-results">
												<div className="sp-template sp-template-event-performance sp-template-event-performance-values">
													<div className="sp-table-wrapper">
														<div className="sp-scrollable-table-wrapper"><div id="DataTables_Table_1_wrapper" className="dataTables_wrapper">
															<div className="mb-5">
																<div className="mb-5 table-responsive">
																	<table className="data-table-results sp-data-table sp-scrollable-table sp-sortable-table dataTable mb-5" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>Player</th>
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>Played</th>
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>Won %</th>
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>Potm</th>
																				<th className="data-name data-left" tabIndex={0} rowSpan={1} colSpan={1}>Results</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				this.state.stats.players.map(p => {
																					return (
																						<tr className="lineup odd" role="row">
																							<td className="data-item nowrap w-100"><Link to={`/league/${this.competitionId}/player/${p.id}`}>{p.name}</Link></td>
																							<td className="data-item">{p.played}</td>
																							<td className="data-item">{p.average.toFixed(2)}%</td>
																							<td className="data-games">
																								{p.potm}
																							</td>
																							<td className="data-matches nowrap">
																								{
																									p.matches.map(m => {
																										return (
																											<Link title={`${moment(m.date).format("Do MMMM")}`} className={`player-match`} to={`/league/${this.competitionId}/division/${this.state.stats.division.key}/match/${m.id}`}>
																												<span className={`won-${m.form}`}>
																													{m.won}
																												</span>
																											</Link>
																										)
																									})
																								}
																							</td>
																						</tr>
																					)
																				})
																			}
																		</tbody>
																	</table>
																</div>
																<div className="mb-5 table-responsive">
																	<table className="data-table-results sp-data-table sp-scrollable-table sp-sortable-table dataTable" role="grid">
																		<thead>
																			<tr role="row">
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Versus</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Fixture</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Date</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Score</th>
																				<th className="data-name sorting" tabIndex={0} rowSpan={1} colSpan={1}>Potm</th>
																			</tr>
																		</thead>
																		{
																			this.state.stats.results.length == 0 ?
																				<tbody>
																					<tr>
																						<td colSpan={5}>
																							<p className="text-center no-results">No results available</p>
																						</td>
																					</tr>
																				</tbody> :
																				<tbody>{
																					this.state.stats.results.map(r => {
																						return (
																							<tr className="lineup odd" role="row">
																								<td className="data-item nowrap"><Link to={`/league/${this.competitionId}/team/${r.versus.key}`}>{r.versus.value}</Link></td>
																								<td className="data-item nowrap">
																									{r.home ? "Home" : "Away"}
																								</td>
																								<td className="data-item nowrap">{moment(r.date).format("Do MMMM YYYY")}</td>
																								<td className="data-games nowrap">
																									<Link to={`/league/${this.competitionId}/division/${r.divisionId}/match/${r.matchId}`}>
																										<p className={r.type === ResultType.Won ? "text-success" : "text-danger"}>
																											{r.for} - {r.against}
																										</p>
																									</Link>
																								</td>
																								<td className="data-item nowrap">
																									{r.potm}
																								</td>
																							</tr>)
																					})}
																				</tbody>
																		}
																	</table>
																</div>
																<div style={{overflow: "hidden"}}>
																	<h5>{this.state.competition.reserves.type == 6 && "Pool "}Members <a className="clickable float-right" onClick={() => this.setState({showStatus: !this.state.showStatus})}><i className={`fal fa-${this.state.showStatus ? `user` : `user-check`} tx-24`}></i></a></h5>
																	<Row>
																		{this.state.members.filter(m => !m.transfered).map(item => {
																			return (
																				<Col md={5}>
																					<Card className="mb-3" key={`member-${item.memberId}`}>
																						<h6 className="m-0 text-primary">
																							{item.name}
																							{this.state.showStatus && <span className="tx-20 float-right">
																								<i title={`${item.expired ? "Expired" : "Active"} Membership`} className={`fal fa-${item.expired ? "times text-danger" : "check text-success"}`}></i>|
																								<i title={`${item.incorrectMembership ? "Incorrect" : "Correct"} Membership`} className={`fal fa-${item.incorrectMembership ? "times text-danger" : "check text-success"}`}></i>
																							</span>}
																						</h6>
																					</Card>
																				</Col>
																			)
																		})}
																	</Row>
																</div>
															</div>
														</div>
														</div>
													</div>
												</div>
												<div className="d-md-none">
													{
														this.state.sets.map(s => {
															return (
																<div className="set card mb-3">
																	<div className="row">
																		<div className="col-12 text-center">
																			<h6>Set {s.ordering + 1}</h6>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-6 text-center">
																			<p>{
																				s.homePlayers.map(p => {
																					return (
																						<span className={`d-block ${s.homeScore > s.awayScore ? "tx-medium" : ""}`}>{p.name}</span>
																					);
																				})
																			}</p>
																		</div>
																		<div className="col-6 text-center">
																			<p>{
																				s.awayPlayers.map(p => {
																					return (
																						<span className={`d-block ${s.awayScore > s.homeScore ? "tx-medium" : ""}`}>{p.name}</span>
																					);
																				})
																			}</p>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-6 text-center">
																			<h3 className="mt-0">
																				<span>{s.homeScore}</span>
																			</h3>
																		</div>
																		<div className="col-6 text-center">
																			<h3 className="mt-0">
																				<span>{s.awayScore}</span>
																			</h3>
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-12 text-center">
																			<p>
																				{
																					s.games.map(g => {
																						return (g.home != null && g.away != null ? <span className="mr-3">{g.home} - {g.away}</span> : undefined);
																					})
																				}
																			</p>
																		</div>
																	</div>
																</div>
															);
														})
													}
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					)} />
			</PublicLayout>);
	}
}
