import * as React from "react";
import * as ReactDOM from "react-dom";

import { useState, useEffect } from "react";

import { Modal, ModalBody, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { AppCache } from "@cache";
import { WebsiteImage } from "@models/website";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { AppConfig } from "@config";
import { useRef } from "react";

interface IHeaderSlider {
	images: WebsiteImage[];
}

export default (props: IHeaderSlider) => {
	const [images, setImages] = useState<WebsiteImage[]>(props.images ?? []);

	return (
		<OwlCarousel
			options={{ autoplay: AppCache.website.config.autoScrollCheck ? true : false, loop: true, responsive: { 0: { items: 1, } } }}>
			{
				images.map(h => {
					return (
						<section style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 75%, rgba(7, 18, 35, 1) 100%), url('${AppConfig.baseUrl}image/${h.imageId}')` }} className="slider elementor-element elementor-element-99e1259 elementor-section-stretched elementor-section-content-bottom scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
							<div className="elementor-container elementor-column-gap-extended slider--container">
								<div className="elementor-row slider--title">
									<div className="elementor-element elementor-element-390f28f sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-50 elementor-top-column">
										<div className="elementor-column-wrap  elementor-element-populated">
											<div className="elementor-widget-wrap">
												<div className="elementor-element elementor-element-fc40494 med-title sc_fly_static elementor-widget elementor-widget-trx_sc_title" data-id="fc40494" data-element_type="widget" data-widget_type="trx_sc_title.default">
													<div className="elementor-widget-container">
														<div className="sc_title sc_title_default">
															<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
																<span className="sc_item_title_text" style={{ minHeight: "72px", display: "inline-block" }}>
																	{h.title}
																</span>
															</h1>
															<p className="slider-description" style={{ minHeight: "20px", display: "inline-block" }}>
																<span>
																	{h?.description}
																</span>
															</p>
															{
																h.url ?
																	<div className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_title_button">
																		<a href={`${h.externalUrl ? `//` : ``}${h.url}`} className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																			<span className="sc_button_text">
																				<span className="sc_button_title">
																					Find out More
																				</span>
																			</span>
																		</a>
																	</div> : undefined
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					);
				})
			}
		</OwlCarousel>
	);
};