import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { AppConfig } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";
import { ContentService } from "@services/content.service";

import { MembersLayout } from "@components/layouts/MembersLayout";
import { EmptyLayout } from "@components/layouts/EmptyLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";
import { Content } from "@models/content";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";

import ReactMarkdown from "react-markdown";

interface IChangeLogViewPageProps {
	site?: string;
	match?: any;
}

interface IChangeLogViewPageState {
	loading: boolean;
	nextPathname?: any;
}

export class ChangeLogPage extends React.Component<IChangeLogViewPageProps, IChangeLogViewPageState> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false
		};
	}

	componentDidMount() {
		//
	}

	load() {

	}

	public render() {
		const web = require("../../../../changelog.md").default as string;
		const app = require("../../../../../TTEOnline.Mobile/CHANGELOG.md").default as string;

		return (
			<EmptyLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							<div className="elementor elementor-6">
								<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-id="b70eb50" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
									<div className="elementor-container elementor-column-gap-extended">
										<div className="elementor-row">
											<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column" data-id="ed1b682" data-element_type="column">
												<div className="elementor-column-wrap  elementor-element-populated">
													<div className="elementor-widget-wrap">
														<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner"></div>
																</div>
															</div>
														</div>
														<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner"></div>
																</div>
															</div>
														</div>
														<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
															<div className="elementor-widget-container">
																<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																	<h1 className="sc_layouts_title_caption">Change Log</h1>
																	<p className="header--description"></p>
																</div>
																</div>
																</div>
															</div>
														</div>
														<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner"></div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
							<div className="page_content_wrap">
								<div className="content_wrap">
									<div className="nav nav-pills float-right mb-3" id="v-pills-tab" role="tablist">
										<a className="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Web</a>
										<a className="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">App</a>
									</div>
									<div className="tab-content clearfix" id="v-pills-tabContent">
										<div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
											<article id="post-136" className="post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league">
												<div className="post_content post_content_single entry-content">
													<div className="changelog">
														<ReactMarkdown>{web}</ReactMarkdown>
													</div>
												</div>
											</article>
										</div>
										<div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
											<article id="post-136" className="post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league">
												<div className="post_content post_content_single entry-content">
													<div className="changelog">
														<ReactMarkdown>{app}</ReactMarkdown>
													</div>
												</div>
											</article>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
			</EmptyLayout>);
	}
}
