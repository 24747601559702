import * as React from "react";
import * as ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { Row, Col, Label } from "reactstrap";

import { AppConfig, ReleaseType } from "@config";
import { AppCache } from "@cache";

import * as moment from "moment";
import * as NProgress from "nprogress";

import { TeamService } from "@services/team.service";
import { ClubService } from "@services/club.service";
import { LeagueService } from "@services/league.service";
import { ArticleService } from "@services/article.service";

import { MembersLayout } from "@components/layouts/MembersLayout";

import { Wizard, Step, Steps, WithWizard } from "react-albus";

import { toast } from "react-toastify";
import { League } from "@models/league";
import { Team } from "@models/team";
import { Club } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Article } from "@models/article";
import { PlayerAverage } from "@models/player";

import * as OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import { Division } from "@models/division";
import { Competition } from "@models/competition";
import { DivisionService } from "@services/division.service";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page } from 'react-facebook';
import { LoginMode } from "@models/tenant";
import { LoginService } from "@services/login.service";
import { MemberService } from "@services/member.service";
import History from "@helpers/history.helper";
import * as qs from "query-string";
import { CreateMember } from "@models/member";
import { Gender } from "@models/user";
import { ContactService } from "@services/contact.service";
import { CreateContact, CreateContactType } from "@models/contact";
import { ProgressButton } from "@components/controls/ProgressButton";
import * as Cleave from "cleave.js/react";

interface IRegisterPageProps {
	site?: string;
	match?: any;
	location?: any;
}

interface IRegisterPageState {
	loading: boolean;
	nextPathname?: any;
	firstName?: string;
	lastName?: string;
	email?: string;
	guardianEmail?: string;
	guardianAgreement: boolean;
	phoneNumber?: string;
	date?: string;
	month?: string;
	year?: string;
	gender?: Gender;
	submitting: boolean;
	under18: boolean;
}

export class RegisterPage extends React.Component<IRegisterPageProps, IRegisterPageState> {
	private returnUrl: string;

	constructor(props) {
		super(props);

		this.state = { loading: true, submitting: false, under18: false, guardianAgreement: false };

		const parsed = qs.parse(location.search);
		this.returnUrl = parsed.returnUrl;
	}

	componentDidMount() {
		this.setState({ loading: false });
	}

	componentDidUpdate(props: any, state: any) {
		//
		if (this.state.date !== state.date ||
			this.state.month !== state.month ||
			this.state.year !== state.year) {
			if (this.state.date && this.state.month && this.state.year) {
				const date = moment.utc(new Date(parseInt(this.state.year, 0), (parseInt(this.state.month, 0) - 1), parseInt(this.state.date, 0)));
				if (date.isValid()) {
					const years = moment().diff(date, 'years');

					if (years < 18) {
						this.setState({ under18: true });
					} else {
						this.setState({ under18: false });
					}
				}
			} else {
				this.setState({ under18: false });
			}
		}
	}

	handleRegisterClick = () => async (e: any) => {
		NProgress.start();
		this.setState({ submitting: true });

		if (!this.validate()) {
			this.setState({ submitting: false }, () => NProgress.done());
			return;
		}

		try {
			const contact = new CreateContact();
			contact.firstName = this.state.firstName;
			contact.lastName = this.state.lastName;
			contact.dob = moment.utc(new Date(parseInt(this.state.year, 0), (parseInt(this.state.month, 0) - 1), parseInt(this.state.date, 0))).toDate();
			contact.email = this.state.email;
			contact.gender = this.state.gender;
			contact.type = CreateContactType.Account;

			const token = await ContactService.register(contact);
			LoginService.store(token, true);

			NProgress.done();
			this.setState({ submitting: false }, () => {
				History.push(`/success?returnUrl=${this.returnUrl ?? ""}`);
			});
		} catch (error) {
			toast.error(error?.message);

			NProgress.done();
			this.setState({ submitting: false });
		}
	}

	validate() {
		if (!this.state.firstName) {
			toast.error("First name required");
		} else if (!this.state.lastName) {
			toast.error("Last name required");
		} else if (!this.state.year || !this.state.month || !this.state.date) {
			toast.error("Date of birth required");
		} else if (!this.state.email) {
			toast.error("Email required");
		} else if (!this.state.gender) {
			toast.error("Gender required");
		} else if(this.state.under18 && !this.state.guardianEmail) {
			toast.error("Guardian email required");
		} else if(this.state.under18 && !this.state.guardianAgreement) {
			toast.error("Guardian email agreement required");
		} else {
			return true;
		}

		return false;
	}

	handleChangeAgreement = () => (e:any) => {
		this.setState({guardianAgreement: e.target.checked});
	}

	public render() {
		const registrationUrl = AppConfig.release === ReleaseType.production ? "https://auth.sport80.com/register?service_provider=f1926490-a686-4159-b38d-639b8c9108f7" : "https://dev-auth.s80testing.co.uk/register?service_provider=f1926490-a686-4159-b38d-639b8c9108f7";

		return (
			<PublicLayout theme="scheme_default" className="post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout">
				<ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div className="sub-page">
							{
								AppCache.website.config.subHeader ?
									<div className="elementor elementor-6">
										<section className="elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section">
											<div className="elementor-container elementor-column-gap-extended">
												<div className="elementor-row">
													<div className="elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column">
														<div className="elementor-column-wrap  elementor-element-populated">
															<div className="elementor-widget-wrap">
																<div className="sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer" data-id="6850b4b" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer" data-id="b76221f" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn" data-id="612f306" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="trx_sc_layouts_title.default">
																	<div className="elementor-widget-container">
																		<div id="trx_sc_layouts_title_1841591666" className="sc_layouts_title with_content without_image without_tint"><div className="sc_layouts_title_content"><div className="sc_layouts_title_title">
																			<h1 className="sc_layouts_title_caption">Register</h1>
																			<p className="header--description">Quickly setup your account below</p>
																		</div>
																		</div>
																		</div>
																	</div>
																</div>
																<div className="sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer" data-id="01551c2" data-element_type="widget" data-widget_type="spacer.default">
																	<div className="elementor-widget-container">
																		<div className="elementor-spacer">
																			<div className="elementor-spacer-inner"></div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div> :
									<div className="header_content_wrap mt-5">
										<div className="container">
											<div className="row">
												<div className="col-12">
													<h1 className="sc_layouts_title_caption">Register</h1>
													<p className="header--description">Quickly setup you account details below</p>
												</div>
											</div>
										</div>
									</div>
							}
							{
								AppCache.tenant.organisation.registrationEnabled &&
								<div>
									<section className="bg-white p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-12 mx-auto">
													<Row className="mb-5">
														<Col md={8}>
															<h6 className="m-0">Secure Registration</h6>
															<p className="m-0">If you are new to table tennis, or have not registered with Table Tennis England previously, click 'Create Account'. If you are an existing member of Table Tennis England, or have used our site before, click 'Already Registered'.</p>
														</Col>
													</Row>
													<Row>
														<Col md={6} className="my-auto">
															<a href={registrationUrl} className="bg-primary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center">
																<span className="sc_button_text">
																	<span className="sc_button_title">Create account?</span>
																</span>
															</a>
														</Col>
														<Col md={6} className="my-auto">
															<Link to={`/login?returnUrl=${this.returnUrl}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center">
																<span className="sc_button_text">
																	<span className="sc_button_title">Already registered?</span>
																</span>
															</Link>
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</section>
									{/* <section className="p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-10 mx-auto">
													<div className="login p-l-55 p-r-55 p-t-65 p-b-50">
														<form className="login100-form validate-form">
															<div className="text-left">
																<span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">Personal Details</span>
																<h3 className="m-0">Your Name</h3>
															</div>
															<Row className="mt-5">
																<Col>
																	<div className="mb-3" data-validate="Valid email is required: ex@abc.xyz">
																		<input className="w-100" type="text" name="firstName" placeholder="First Name" value={this.state.firstName} onChange={e => this.setState({ firstName: e.currentTarget.value })} />
																	</div>
																</Col>
																<Col>
																	<div className="mb-3" data-validate="Password is required">
																		<input className="w-100" type="text" name="lastName" placeholder="Last Name" value={this.state.lastName} onChange={e => this.setState({ lastName: e.currentTarget.value })} />
																	</div>
																</Col>
															</Row>
														</form>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="bg-white p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-10 mx-auto">
													<div className="login p-l-55 p-r-55 p-t-65 p-b-50">
														<form className="login100-form validate-form">
															<div className="text-left">
																<span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">Person Details</span>
																<h3 className="m-0">Date of Birth</h3>
															</div>
															<Row className="mt-5">
																<Col>
																	<input className="w-100" type="text" name="day" placeholder="DD" value={this.state.date} onChange={e => this.setState({ date: e.currentTarget.value })} maxLength={2} />
																</Col>
																<Col>
																	<input className="w-100" type="text" name="month" placeholder="MM" value={this.state.month} onChange={e => this.setState({ month: e.currentTarget.value })} maxLength={2} />
																</Col>
																<Col>
																	<input className="w-100" type="text" name="year" placeholder="YYYY" value={this.state.year} onChange={e => this.setState({ year: e.currentTarget.value })} maxLength={4} />
																</Col>
															</Row>
														</form>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-10 mx-auto">
													<div className="login p-l-55 p-r-55 p-t-65 p-b-50">
														<form className="login100-form validate-form">
															<div className="text-left">
																<span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">Person Details</span>
																<h3 className="m-0">Contact Details</h3>
															</div>
															<Row className="mt-5">
																<Col>
																	<input className="w-100" type="text" name="month" placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.currentTarget.value })} />
																</Col>
																<Col>
																	<input className="w-100" type="text" name="year" placeholder="Phone Number" value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.currentTarget.value })} />
																</Col>
															</Row>
															{
																this.state.under18 &&
																<Row className="mt-5">
																	<Col>
																		<input className="w-100" type="text" name="month" placeholder="Guardian Email" value={this.state.guardianEmail} onChange={e => this.setState({ guardianEmail: e.currentTarget.value })} />
																	</Col>
																	<Col>
																		<p className="mb-0"><strong>Please note:</strong> Under 18s require a guardian email address</p>
																		<div className="input-wrapper">
																			<input onChange={this.handleChangeAgreement()} hidden id="check1" type="checkbox"></input>
																			<label htmlFor="check1">
																				<span>I agree this email address is valid</span>
																			</label>
																		</div>
																	</Col>
																</Row>
															}
														</form>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="bg-white p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-10 mx-auto">
													<div className="login p-l-55 p-r-55 p-t-65 p-b-50">
														<form className="login100-form validate-form">
															<div className="text-left">
																<span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">Person Details</span>
																<h3 className="m-0">Gender</h3>
															</div>
															<Row className="mt-5">
																<Col>
																	<a tabIndex={0} className={`w-100 ${this.state.gender === Gender.Male ? "bg-success" : "btn-gray-200"} text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center`} type="text" onClick={e => this.setState({ gender: Gender.Male })}>Male</a>
																</Col>
																<Col>
																	<a tabIndex={0} className={`w-100 ${this.state.gender === Gender.Female ? "bg-success" : "btn-gray-200"} text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center`} type="text" onClick={e => this.setState({ gender: Gender.Female })}>Female</a>
																</Col>
															</Row>
														</form>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-10 mx-auto">
													<div className="text-left">
														<span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">Agreements</span>
														<h3 className="m-0 mb-2">Privacy</h3>
														<p>By continuing you are agreeing to {AppCache.tenant.organisation.name}'s <a href="https://protect-eu.mimecast.com/s/SRZuC5WKXiWkK7HyWK77?domain=tabletennisengland.sport80.com" target="_blank">Terms &amp; Conditions</a> and <a href="https://protect-eu.mimecast.com/s/rzk4C60K4sGL9vi5iEZ-?domain=tabletennisengland.sport80.com" target="_blank">Privacy Policy</a></p>
													</div>
													<p>
													</p>
												</div>
											</div>
										</div>
									</section>
									<section className="bg-white p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-10 mx-auto">
													<Row>
														<Col></Col>
														<Col>
															<ProgressButton loading={this.state.submitting} onClick={this.handleRegisterClick()} className="btn bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center">
																<span className="sc_button_text">
																	<span className="sc_button_title">Register</span>
																</span>
															</ProgressButton>
														</Col>
														<Col></Col>
													</Row>
												</div>
											</div>
										</div>
									</section> */}
								</div>
							}
							{
								!AppCache.tenant.organisation.registrationEnabled &&
								<div>
									<section className="bg-white p-5">
										<div className="container">
											<div className="row">
												<div className="col-md-12 mx-auto">
													<Row>
														<Col md={8}>
															<h6 className="m-0">Registration Disabled</h6>
															<p>Registration not enabled, please contact you administrator</p>
														</Col>
														<Col md={4} className="my-auto">
															<a href={`mailto:${AppCache.website.email}`} className="bg-secondary text-white sc_button sc_button_default sc_button_size_normal sc_button_icon_left w-100 text-center">
																<span className="sc_button_text">
																	<span className="sc_button_title">Contact Us</span>
																</span>
															</a>
														</Col>
													</Row>
												</div>
											</div>
										</div>
									</section>
								</div>
							}
						</div>)} />
			</PublicLayout>);
	}
}
