import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AppConfig } from "@config";
import { AppCache } from "@cache";
import * as moment from "moment";
import { ClubService } from "@services/club.service";
import { ContentService } from "@services/content.service";
import { Team, TeamCaptain } from "@models/team";
import { Club, ClubMember } from "@models/club";
import PublicLayout from "@components/layouts/PublicLayout";
import { Content, ContentLayout } from "@models/content";
import "react-owl-carousel2/src/owl.carousel.css";
import "react-owl-carousel2/src/owl.theme.default.css";
import ReactHtmlParser, {
	processNodes,
	convertNodeToElement,
	htmlparser2,
} from "react-html-parser";
import {
	TwitterTimelineEmbed,
	TwitterShareButton,
	TwitterFollowButton,
	TwitterHashtagButton,
	TwitterMentionButton,
	TwitterTweetEmbed,
	TwitterMomentShare,
	TwitterDMButton,
	TwitterVideoEmbed,
	TwitterOnAirButton,
} from "react-twitter-embed";
import { ShowMeMode, ShowMe } from "@components/controls/ShowMe";
import { FacebookProvider, Page, Group } from "react-facebook";
import { GroupMember } from "@models/group";
import { GroupService } from "@services/group.service";
import { Member } from "@models/member";
import { MemberService } from "@services/member.service";
import { TenantService } from "@services/tenant.service";
import { WebsiteImageType, WebsiteImage } from "@models/website";
import $ from "jquery";

interface IContentViewPageProps {
	site?: string;
	match?: any;
}

interface IContentViewPageState {
	loading: boolean;
	nextPathname?: any;
	content?: Content;
	featured: Content[];
	members: GroupMember[];
	captains: TeamCaptain[];
	secretaries: ClubMember[];
}

export class ContentViewPage extends React.Component<
	IContentViewPageProps,
	IContentViewPageState
> {
	private contentId: number;
	private slug: string;

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			featured: [],
			members: [],
			captains: [],
			secretaries: [],
		};
	}

	toggleAll(expanding: boolean) {
		let vaButton = document.getElementsByClassName("view-all")[0];

		let accordionHeaders = document.getElementsByClassName("accordionHeader");

		for (let index = 0; index < accordionHeaders.length; index++) {
			if (expanding && accordionHeaders[index].className.includes("collapsed")) {
				accordionHeaders[index].click();
				//accordionHeaders[index].classList.remove("collapsed");
				//accordionHeaders[index].setAttribute("aria-expanded", "true");
			}
			else if (!expanding && !accordionHeaders[index].className.includes("collapsed")) {
				accordionHeaders[index].click();
				//accordionHeaders[index].classList.add("collapsed");
				//accordionHeaders[index].setAttribute("aria-expanded", "false");
			}
		}

		vaButton.innerText = expanding ? "Hide All" : "Expand All";
	}

	componentDidMount() {
		document.body.addEventListener('click', event => {
			if (event?.srcElement?.className == 'view-all') {
				this.toggleAll(event?.srcElement?.innerText.includes("Expand All"));
			}
		});
		this.contentId = this.props.match.params.contentId as number;
		this.slug = this.props.match.params.slug as string;
		this.load();
	}

	componentDidUpdate(
		props: IContentViewPageProps,
		state: IContentViewPageState
	) {
		this.contentId = this.props.match.params.contentId as number;
		this.slug = this.props.match.params.slug as string;
		if (props.match.params.slug != this.slug) {
			this.load();
		}
	}

	load() {
		const p1 = ContentService.getBySlug(this.slug).then((content) => {
			this.setState({ content });
		});

		const p2 = ContentService.getFeatured(1, 5).then((content) => {
			this.setState({ featured: content });
		});
		Promise.all([p1, p2]).then((result) => {
			this.setState({ members: [], captains: [], secretaries: [] })
			if (this.state.content) {
				if (this.state.content.forwardTo !== "") {
					let url = this.state.content.forwardTo;

					if (!this.state.content.forwardTo.startsWith("http")) {
						url = `http://${url}`;
					}

					window.location.href = url;
				} else {
					if (this.state.content.layout) {
						switch (this.state.content.layout) {
							case ContentLayout.CaptainsList:
								MemberService.getCaptains().then((captains) => {
									this.setState({ loading: false, captains });
								});
								break;
							case ContentLayout.MemberGroup:
								GroupService.getMembers(this.state.content.groupId).then(
									(members) => {
										this.setState({ members, loading: false });
									}
								);
								break;
							case ContentLayout.ClubSecretaries:
								ClubService.getSecretaries().then((secretaries) => {
									this.setState({ secretaries, loading: false });
								});
							default:
								break;
						}
					} else {
						this.setState({ loading: false });
					}
				}
			}
			else {
				this.setState({ loading: false });
			}
		});
	}

	public render() {
		const headerImage: WebsiteImage = AppCache.website.images.find(
			(_) => _.type === WebsiteImageType.Header
		);
		return (
			<PublicLayout
				theme='scheme_default'
				className='post-template-default single single-post postid-136 single-format-standard wp-custom-logo ua_chrome woocommerce-js fcunited_inline_1671874526 body_tag blog_mode_post body_style_wide is_single sidebar_show sidebar_right sidebar_small_screen_below trx_addons_present header_type_custom header_style_header-custom-6 header_position_default menu_style_top thumbnail_type_default post_header_position_under elementor-default desktop_layout'
			>
				<Helmet>
					<title>{this.state.content?.title}</title>
				</Helmet>
				{this.state.content != null || this.state.loading ? <ShowMe
					visible={!this.state.loading}
					mode={ShowMeMode.Full}
					progress={true}
					render={() => (
						<div>
							<title>{this.state.content.title}</title>
							{AppCache.website.config.subHeader ? (
								<div className='elementor elementor-6'>
									<section
										className='d-print-none elementor-element elementor-element-b70eb50 scheme_dark elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section'
										style={
											this.state.content.headerId
												? {
													backgroundImage: `url('${AppConfig.baseUrl}image/${this.state.content.headerId}')`,
												}
												: headerImage
													? {
														backgroundImage: `url(${AppConfig.baseUrl}image/${headerImage.imageId})`,
													}
													: {}
										}
									>
										<div className='elementor-container elementor-column-gap-extended'>
											<div className='elementor-row'>
												<div
													className='elementor-element elementor-element-ed1b682 sc_inner_width_none sc_layouts_column_icons_position_left elementor-column elementor-col-100 elementor-top-column'
													data-id='ed1b682'
													data-element_type='column'
												>
													<div className='elementor-column-wrap  elementor-element-populated'>
														<div className='elementor-widget-wrap'>
															<div
																className='sc_layouts_item elementor-element elementor-element-6850b4b sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='6850b4b'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-b76221f sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='b76221f'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-612f306 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title animated fadeIn'
																data-id='612f306'
																data-element_type='widget'
																data-settings='{"_animation":"fadeIn"}'
																data-widget_type='trx_sc_layouts_title.default'
															>
																<div className='elementor-widget-container'>
																	<div
																		id='trx_sc_layouts_title_1841591666'
																		className='sc_layouts_title with_content without_image without_tint'
																	>
																		<div className='sc_layouts_title_content'>
																			<div className='sc_layouts_title_title'>
																				<h1 className='sc_layouts_title_caption'>
																					{this.state.content.title}
																				</h1>
																				<p className='header--description'>
																					{this.state.content.description}
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div
																className='sc_layouts_item elementor-element elementor-element-01551c2 sc_fly_static elementor-widget elementor-widget-spacer'
																data-id='01551c2'
																data-element_type='widget'
																data-widget_type='spacer.default'
															>
																<div className='elementor-widget-container'>
																	<div className='elementor-spacer'>
																		<div className='elementor-spacer-inner'></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div>
							) : (
								<div className='header_content_wrap mt-5 print-none'>
									<div className='container'>
										<div className='row'>
											<div className='col-12'>
												<h1 className='sc_layouts_title_caption'>
													{this.state.content.title}
												</h1>
												{this.state.content.description ? (
													<p className='header--description'>
														{this.state.content.description}
													</p>
												) : undefined}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className='header_content_wrap mt-5 d-none d-print-block'>
								<div className='container'>
									<div className='row'>
										<div className='col-12'>
											<h1 className='sc_layouts_title_caption'>
												{this.state.content.title}
											</h1>
											{this.state.content.description ? (
												<p className='header--description'>
													{this.state.content.description}
												</p>
											) : undefined}
										</div>
									</div>
								</div>
							</div>
							<div className='page_content_wrap'>
								<div className='container'>
									<div className='row'>
										<div className='col-lg col-md-12 mb-4'>
											<div className='content'>
												<article
													id='post-136'
													className='post_item_single post_type_post post_format_ post-136 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-articles tag-league'
												>
													<div className='header_content_wrap header_align_mc'>
														{this.state.content.featuredId ? (
															<div className='post_featured'>
																<img
																	width='1920'
																	height='1080'
																	src={`${AppConfig.baseUrl}image/${this.state.content.featuredId}`}
																	className='attachment-fcunited-thumb-full size-fcunited-thumb-full wp-post-image'
																	alt=''
																/>
															</div>
														) : undefined}
														<div className='post_header post_header_single entry-header'>
															<div className='post_meta post_meta_other'>
																{this.state.content.category ? (
																	<span className='post_meta_item post_categories'>
																		<Link
																			to={`/articles/category/${this.state.content.category}`}
																		>
																			{this.state.content.category}
																		</Link>
																	</span>
																) : undefined}
																<span className='post_meta_item post_date'>
																	{this.state.content.published
																		? moment(
																			this.state.content.published
																		).format("Do MMMM YYYY")
																		: "Preview Only"}
																</span>{" "}
															</div>
														</div>
													</div>
													<div className='post_content post_content_single entry-content'>
														<div>
															{ReactHtmlParser(this.state.content.body)}
														</div>
														{this.state.members.length == 0 ? undefined : (
															<div>
																{this.state.members.map((m) => {
																	return (
																		<div className='row mb-3'>
																			<div className='col-md-12'>
																				<h5 className='m-0'>{m.name}</h5>
																				{m.mobile ? (
																					<p className='m-0'>{m.mobile}</p>
																				) : undefined}
																				{m.email ? (
																					<p className='m-0'>{m.email}</p>
																				) : undefined}
																			</div>
																		</div>
																	);
																})}
															</div>
														)}
														{this.state.captains.length == 0 ? undefined : (
															<div className='table-responsive captains'>
																<table className='w-100'>
																	<thead>
																		<tr>
																			<th>Team</th>
																			<th>Captain</th>
																			<th>Contacts</th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.state.captains.map((m) => {
																			return (
																				<tr>
																					<td>
																						<p className='nowrap m-0'>
																							<span className='tx-bold d-block'>
																								{m.team}
																							</span>
																							<span className='d-block'>
																								{m.club}
																							</span>
																						</p>
																					</td>
																					<td>
																						<div className='d-block nowrap'>
																							{m.firstName} {m.lastName}
																						</div>
																					</td>
																					<td>
																						{m.email ? (
																							<div className='d-block nowrap'>
																								<i className='fal fa-envelope'></i>{" "}
																								<span>{m.email}</span>
																							</div>
																						) : undefined}
																						{m.mobile ? (
																							<div className='d-block nowrap'>
																								<i className='fal fa-phone'></i>{" "}
																								<span>{m.mobile}</span>
																							</div>
																						) : undefined}
																					</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</table>
															</div>
														)}
														{this.state.secretaries.length == 0 ? undefined : (
															<div className='table-responsive secretaries'>
																<table className='w-100'>
																	<thead>
																		<tr>
																			<th>Secretary</th>
																			<th>Club</th>
																			<th>Contacts</th>
																		</tr>
																	</thead>
																	<tbody>
																		{this.state.secretaries.map((m) => {
																			return (
																				<tr>
																					<td>
																						<div className='d-block nowrap'>
																							{m.firstName} {m.lastName}
																						</div>
																					</td>
																					<td>
																						<div className='d-block nowrap'>
																							{m.club}
																						</div>
																					</td>
																					<td>
																						{m.email ? (
																							<div className='d-block nowrap'>
																								<i className='fal fa-envelope'></i>{" "}
																								<span>{m.email}</span>
																							</div>
																						) : undefined}
																						{m.mobile ? (
																							<div className='d-block nowrap'>
																								<i className='fal fa-phone'></i>{" "}
																								<span>{m.mobile}</span>
																							</div>
																						) : undefined}
																					</td>
																				</tr>
																			);
																		})}
																	</tbody>
																</table>
															</div>
														)}

														<div className='post_meta post_meta_single'>
															{this.state.content.tags.length > 0 ? (
																<span className='post_meta_item post_tags'>
																	<span className='post_meta_label'>Tags:</span>
																	{this.state.content.tags.map((t) => {
																		return (
																			<Link to={`/articles/tag/${t}`} rel='tag'>
																				{t}
																			</Link>
																		);
																	})}
																</span>
															) : undefined}
															{/* <span className="post_meta_item post_share"><span className="share_meta_label">share:</span>
														<div className="socials_share socials_size_tiny socials_type_block socials_dir_horizontal socials_wrap"><span className="social_items inited"><a className="social_item social_item_popup" href="https://twitter.com/intent/tweet?text=The+Excellent+Results+of+Junior+League+Games&amp;url=https%3A%2F%2Ffcunited.azurewebsites.net%2Fthe-excellent-results-of-junior-league-games%2F" data-link="https://twitter.com/intent/tweet?text=The Excellent Results of Junior League Games&amp;url=https%3A%2F%2Ffcunited.azurewebsites.net%2Fthe-excellent-results-of-junior-league-games%2F" data-count="twitter"><span className="social_icon social_icon_twitter sc_icon_type_icons"><span className="trx_addons_icon-twitter"></span></span>
														</a>
															<a className="social_item social_item_popup" href="http://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffcunited.azurewebsites.net%2Fthe-excellent-results-of-junior-league-games%2F" data-link="http://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffcunited.azurewebsites.net%2Fthe-excellent-results-of-junior-league-games%2F" data-count="facebook"><span className="social_icon social_icon_facebook sc_icon_type_icons"><span className="trx_addons_icon-facebook"></span></span></a>
														</span>
														</div>
													</span> */}
														</div>
													</div>
												</article>
											</div>
										</div>
										{
											this.state.featured.length > 0 &&
											<div className='col-lg-4 col-md-12 right mb-4'>
												<div className='sidebar widget_area right sidebar_below d-print-none'>
													<div className='sidebar_inner'>
														<div className='row'>
															<div className='col-12 col-md-6 col-lg-12 mb-4'>
																<aside className='widget widget_recent_posts'>
																	<h5 className='widget_title'>
																		featured content
																	</h5>
																	{this.state.featured.map((a) => {
																		return (
																			<article className='post_item with_thumb'>
																				<div className='post_content'>
																					<h6 className='post_title'>
																						<Link to={`/page/${a.slug}`}>
																							{a.title}
																						</Link>
																					</h6>
																					<div className='post_info'>
																						<span className='post_info_item post_info_posted'>
																							<Link
																								to={`/page/${a.slug}`}
																								className='post_info_date'
																							>
																								{moment(a.published).format(
																									"Do MMMM YYYY"
																								)}
																							</Link>
																						</span>
																						<span className='post_info_item post_info_posted_by'>
																							by{" "}
																							<span className='post_info_author'>
																								admin
																							</span>
																						</span>
																					</div>
																				</div>
																			</article>
																		);
																	})}
																</aside>
															</div>
															{AppCache.website.twitter ? (
																<div className='col-12 col-md-6 col-lg-12 mb-4'>
																	<aside
																		id='text-4'
																		className='widget widget_text'
																	>
																		<h5 className='widget_title'><i className="fab fa-x-twitter"></i></h5>
																		<div>
																			<TwitterTimelineEmbed
																				sourceType='profile'
																				screenName={`${AppCache.website.twitter}`}
																				options={{ height: 400 }}
																			/>
																		</div>
																	</aside>
																</div>
															) : undefined}
															{AppCache.website.facebook ? (
																<div className='col-12 col-md-6 col-lg-12 mb-4'>
																	<aside
																		id='text-4'
																		className='widget widget_text p-20'
																	>
																		<h5 className='widget_title'>facebook</h5>
																		<div>
																			<FacebookProvider appId='412401072952926'>
																				{AppCache.website.facebook.indexOf("groups") >= 0 && <Group href={`https://www.facebook.com/${AppCache.website.facebook}/`} />}
																				{AppCache.website.facebook.indexOf("page") < 0 && <Page href={`https://www.facebook.com/${AppCache.website.facebook}/`} tabs="timeline" />}
																			</FacebookProvider>
																		</div>
																	</aside>
																</div>
															) : undefined}
														</div>
													</div>
												</div>
											</div>
										}
									</div>
								</div>
							</div>
						</div>
					)}
				/> : <p>Content Unavailable</p>}
			</PublicLayout>
		);
	}
}


