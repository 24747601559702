import { TeamMember, Team } from "./team";

export class Entrant {
	public id: number;
	public teamId: number;
	public userId: string;
	public joined: Date;
	public competitionId: number;
	public divisionId: number;
	public fullName: string;
	public name: string;
	public members: TeamMember[] = [];
	public score?: number;
	public ordering: number;
	public type: EntrantType;
	public team: Team;
}

export class CreateEntrant {
	public entrantId: number;
	public teamId: number;
	public userId: string;
	public competitionId: number;
	public divisionId: number;
	public type: EntrantType = EntrantType.Default;
	public name: string = "";
}

export enum EntrantType {
	Default = 1,
	Temporary = 2,
	Bye = 3
}
